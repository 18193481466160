import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { ITaxReturn } from '../../components/common/TaxReturn';
import {
    RequestCompanyAssignmentsAction, ReceiveCompanyAssignmentsAction,
    RequestCompanyAssignmentsPagesAction, ReceiveCompanyAssignmentsPagesAction
} from "./KnownTypes";
import { IAssignmentsState } from "./AssignmentsState";
import { actionTypes } from '../ActionTypes';
import {
    ReceiveTaxDocumentAction, DeleteTaxDocumentAction,
    TaxReturnSource, ResetTaxReturnsAction, ReceiveTaxDocumentsAction
} from '../common/TaxDocumentStore';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { handleResponse } from '../Library';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';

type OwnAction =
    RequestCompanyAssignmentsAction |
    ReceiveCompanyAssignmentsAction;

type KnownAction =
    OwnAction |
    ReceiveTaxDocumentAction |
    ReceiveTaxDocumentsAction |
    RequestCompanyAssignmentsPagesAction |
    ReceiveCompanyAssignmentsPagesAction |
    NotificationAction;

type dispatchAction =
    OwnAction |
    ReceiveTaxDocumentAction |
    ReceiveTaxDocumentAction |
    ResetTaxReturnsAction |
    DeleteTaxDocumentAction;

let companyAssignmentAbortController = new AbortController();

export const actionCreators = {
    requestCompanyAssignments: (query: string, reload: boolean = false, callback?: () => void, reloadOnNoDataFound?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        let state = getState();

        if (reload || query !== state.companyAssignments.query) {
            if (!reload) {
                dispatch({ type: actionTypes.REQUEST_COMPANY_ASSIGNMENTS, query: query });
                return;
            }
            companyAssignmentAbortController.abort();
            companyAssignmentAbortController = new AbortController();

            const fetchTask = fetch(`${API_BASE_URL}api/CompanyAssignments` + query, {
                method: 'GET',
                credentials: 'include',
                signal: companyAssignmentAbortController.signal,
            })
                .then(handleResponse)
                .then(response => response as Promise<{ count: number, documents: ITaxReturn[] }>)
                .then(data => {
                    
                    if (data.count === 0 && reloadOnNoDataFound) {
                        reloadOnNoDataFound();
                    }
                    else {
                        
                    
                        dispatch({ type: actionTypes.RECEIVE_COMPANY_ASSIGNMENTS, query: query, assignments: data.documents, totalRowCount: data.count });
                        dispatch({ type: actionTypes.RECEIVE_COMPANY_ASSIGNMENTS_PAGES, query: query, assignments: data.documents, totalRowCount: data.count });
                        dispatch({
                            type: actionTypes.RECEIVE_TAX_DOCUMENTS, taxDocuments: data.documents,
                            source: TaxReturnSource.CompanyAssignments
                        });
                        if (callback) {
                            callback();
                        }
                    }
                })

                .catch(error => {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });
                    if(error.name!=="AbortError"){
                        logger.trackError(`requestCompanyAssignments failed for query: ${query}, with error ${error.message}`);
                    }
                    if (callback) {
                        callback();
                    }
                });

            addTask(fetchTask); // Ensure server-side prerendering waits for this to complete
            dispatch({ type: actionTypes.REQUEST_COMPANY_ASSIGNMENTS, query: query });
            dispatch({ type: actionTypes.REQUEST_COMPANY_ASSIGNMENTS_PAGES, query: query });
        }
    },
    exportAssignmentsAsExcel: (query: string, callback?: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fetchTask = fetch(`${API_BASE_URL}api/ExportToExcel/ExportExcel` + query, { credentials: 'include' })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.id = "download_link";
                link.target = '_blank';
                link.setAttribute('download', 'Ext In-Process.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                if (callback) {
                    callback();
                }
            });
        addTask(fetchTask); // Ensure server-side prerendering waits for this to complete
    }
};

const unloadedState: IAssignmentsState = {
    assignments: [] as ITaxReturn[],
    query: '',
    isLoading: false,
    totalRowCount: 0
} as IAssignmentsState;

export const reducer: Reducer<IAssignmentsState> = (state: IAssignmentsState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as dispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_COMPANY_ASSIGNMENTS:
            return {
                ...unloadedState,
                query: action.query,
                isLoading: true
            } as IAssignmentsState;
        case actionTypes.RECEIVE_COMPANY_ASSIGNMENTS:
            return {
                query: action.query,
                assignments: action.assignments,
                totalRowCount: action.totalRowCount,
                isLoading: false
            } as IAssignmentsState;

        case actionTypes.RECEIVE_TAX_DOCUMENT:
            if (action.source !== TaxReturnSource.CompanyAssignments) {//Ignore calls originated from this store
                let newList: ITaxReturn[] = [];
                state.assignments.map((item, i) => {
                    newList.push((item.id === action.id) ? action.taxDocument : item);
                });
                return {
                    query: state.query,
                    assignments: newList,
                    totalRowCount: state.totalRowCount,
                    isLoading: false
                } as IAssignmentsState;
            }
            break;
        case actionTypes.DELETE_TAX_DOCUMENT:
        case actionTypes.RESET_TAX_RETURNS:
            //clearing the dictionary on deletion
            return {
                ...unloadedState,
                query: "",
                isLoading: true
            } as IAssignmentsState;
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }
    return state || unloadedState;
};
