var htmlencode = require('htmlencode');
import React from 'react';
import * as bootbox from 'bootbox';
import 'react-select/dist/react-select.css';
import * as Enumerable from 'linq';
import { MessageDetails } from '../../../../Core/ViewModels/CompanySettings/SavedMessageViewModel';
import * as TaxDocument from '../../../common/TaxReturn';
import * as CompanyStore from '../../../../store/company/CompanyStore';
import { ICompanySettings, SignatureFormSelectionType } from '../../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { FormLabel, Row, FormGroup, Button, Col } from 'react-bootstrap';
import ToggleSwitch from '../../../common/ToggleSwitch';
import { ClientTypes, SignatureType, isPartnership, IDocumentSettings, IMarriedJointTaxReturn, DocumentGroups, IEFile, IVoucher, SignatureControlRole, IGroup } from '../../../common/TaxReturn';
import { IUserModel } from 'src/Core/ViewModels/User/UserViewModel';
import { PreparerMessage } from '../PreparerMessage';
import { Link } from 'react-router-dom';
import * as SavedMessageStore from '../../../../store/settings/SavedMessageStore';
import { RadioButtonComponent } from '../../RadioButtonComponent';
import { isBusinessReturn, ReplaceHelperVariableForEditor, ReplaceHelperVariable } from '../../../helper/HelperFunctions';
import { SelectedMailingAddressView } from "../../../common/ProcessReturnModal/SelectedMailingAddressView";
import * as MailingReturnAddressStore from "../../../../store/common/MailingReturnAddressStore";
import { TabContentContainer } from './TabContentContainer/TabContentContainer';
import { DropdownComponent } from '../../../common/controls/DropdownComponent';
import { VenusNotifier } from '../../../helper/VenusNotifier';
import * as Constants from '../../../helper/Constants';
import { container } from '../../../../Startup/inversify.config';
import { TYPES } from "../../../../Startup/types";
import { IHtmlUtilities } from '../../../../Core/Utilities/HtmlUtilities';
import { YearAndMonth } from '../../../common/YearAndMonthComponent';
import { CheckBoxComponent } from '../../CheckBoxComponent';
import { IWatermarkModel } from '../../../../components/settings/GeneralSettingsComponents/WatermarkComponents/WatermarkSetting';
import { IColumnValues, ICustomColumn } from 'src/components/settings/GeneralSettings';
import * as UserSettingStore from '../../../../store/userManagement/UserSettingStore';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { ExtensionCompanySettings } from '../../../../Core/ViewModels/Extension/ExtensionComponentModels';
import { GetEnableEsignatureForBusinessReturns, isReviewExtesnion, isStrictReviewExtesnion, removeAllUserSignature } from '../SignatureControls/SignatureHelper';
import { ProcessReturnConstants } from '../../../helper/Constants';
import { IDocumentData } from '../AdditionalEsignDocuments/AdditionalEsignDocument';
import { DefaultSenderInfoType } from 'src/store/userManagement/ExtensionUserSettingStore';

export interface IDeliveryOptionsViewProps {
    company: CompanyStore.ICompanyData;
    isAssignedToLoggedinUser?: boolean;
    isEsign: boolean;
    taxReturn: TaxDocument.ITaxReturn;
    mailingReturnAddressDictionary: MailingReturnAddressStore.IMailingReturnAddressDictionary;
    generateTaxPayerView: (clientType: ClientTypes) => void;
    savedMessages: SavedMessageStore.ISavedMessageDictionary;
    getAllSavedMessages: (reload: boolean) => void;
    disabledTaxpayerPreview: boolean;
    signatureTypeInDocSettings: string;
    isSignatureoptionsToggledInDeliveryOption: boolean;
    handleSignatureOptionToggle: (event: any) => void;
    //watermarkList: IWatermarkModel[];
    customColumnData: ICustomColumn;
    userSettings: UserSettingStore.UserSettings;
    handleCustomColumnChange: (columnValue: IColumnValues) => void;
    isLoading: boolean;
    extensionCompanySettings: ExtensionCompanySettings;
    pdfLoading: boolean;
    handleEfileAddedToggle: (isFileEdited: boolean) => void;
    extensionUsers: IUserModel[];
    updateTaxDocument: (taxDocument: TaxDocument.ITaxReturn) => void;
}

interface Tabstate {
    selectedOption: string;
    contactPerson: SelectOptions[],
    selectedContactPerson: number,
    sender: SelectOptions[],
    selectedSender: number,
    notifyUser: SelectOptions[],
    selectedNotifyUser: number,
    message: SelectOptions[],
    selectedMessage: number,
    messageDetails: MessageDetails[],
    preparerMessageShow: boolean;
    prepareMessage: string;
    prepareMessageMode: PrepareMessageMode;

    selectedReminder: number,
    reminderOptions: SelectOptions[],

    selectedSingingReminder: number,
    signingReminderOptions: SelectOptions[],

    mailingAddressOptions: SelectOptions[],
    selectedMailingAddress: number,

    isAllowToEdit: boolean;
    //disabledTaxpayerPreview: boolean;

    selectedMessageId: number;
    customPlaceHolder: any;
    retentionDuration: number;
    isAttested: boolean,

    //watermarkOptions: SelectOptions[],
    //selectedWatermark: number,
    customColumnOptions: SelectOptions[],
    selectedCustomColumn: number,

    isSignerDelegationChanged: boolean,
    isDelegateeKBAChanged: boolean,

    paymentNotifyUser: SelectOptions[],
    selectedPaymentNotifyUser: number,
}

const enum PrepareMessageMode {
    Add,
    Update
}

interface SelectOptions {
    value: any,
    label: string
}

const htmlUtilities = container.get<IHtmlUtilities>(TYPES.IHtmlUtilities);

export class TabDeliveryOptions extends React.Component<IDeliveryOptionsViewProps, Tabstate>{
    constructor(props: IDeliveryOptionsViewProps) {
        super(props);
        this.state = {
            selectedOption: '',
            contactPerson: [],
            selectedContactPerson: 0,
            sender: [],
            selectedSender: 0,
            notifyUser: [],
            selectedNotifyUser: 0,
            message: [],
            selectedMessage: 0,
            messageDetails: [],
            reminderOptions: [],
            selectedReminder: 1,
            signingReminderOptions: [],
            selectedSingingReminder: 1,
            mailingAddressOptions: [],
            selectedMailingAddress: 0,
            preparerMessageShow: false,
            prepareMessage: "",
            prepareMessageMode: 0,
            isAllowToEdit: false,
            //disabledTaxpayerPreview: false,
            selectedMessageId: 0,
            customPlaceHolder: "Select...",
            retentionDuration: 2520,
            isAttested: false,

            //watermarkOptions: [],
            //selectedWatermark: 0,
            customColumnOptions: [],
            selectedCustomColumn: 0,
            isSignerDelegationChanged: false,
            isDelegateeKBAChanged: false,
            paymentNotifyUser: [],
            selectedPaymentNotifyUser: 0,
        }
        this.handleContactPersonChange = this.handleContactPersonChange.bind(this);
        this.handleSenderChange = this.handleSenderChange.bind(this);
        this.handleNotifyUserChange = this.handleNotifyUserChange.bind(this);
        this.preparerMessageHide = this.preparerMessageHide.bind(this);
        this.onEditButtonClick = this.onEditButtonClick.bind(this);
        this.onAddButtonClick = this.onAddButtonClick.bind(this);
        this.onChangeTinyMCE = this.onChangeTinyMCE.bind(this);
        this.preparerMessageUpdateButtonClick = this.preparerMessageUpdateButtonClick.bind(this);
        this.savePreparerMessage = this.savePreparerMessage.bind(this);
        this.handleDeliverFirstToChange = this.handleDeliverFirstToChange.bind(this);
        this.setMessageDetailsDropDown = this.setMessageDetailsDropDown.bind(this);
        this.handleRetentionPeriodChange = this.handleRetentionPeriodChange.bind(this);
        //this.handleAttestClientChange = this.handleAttestClientChange.bind(this);
    }


    componentWillMount() {
        this.setMessageDetailsDropDown();
        this.setContactDropdown();
        this.setMailingAddressDropdown();
        this.SetReminderOptions();
        this.excerptRetentionPeriod(this.props.taxReturn);
        //this.setWatermarkDropdown();
        this.setCustomColumnOptions();
    }

    private excerptRetentionPeriod = (taxReturn: TaxDocument.ITaxReturn) => {
        if (TaxDocument.isIndividual(taxReturn) || TaxDocument.isMutual(taxReturn)) {
            this.setState({ retentionDuration: taxReturn.documentSettings.documentRetentionSetting.retentionPeriod, isAttested: taxReturn.taxpayer.additionalInfo.isAttested });
        }

        else if (TaxDocument.isPartnership(taxReturn)) {
            this.setState({ retentionDuration: taxReturn.documentSettings.documentRetentionSetting.retentionPeriod, isAttested: taxReturn.partnership.additionalInfo.isAttested });
        }
    }

    private handleContactPersonChange = (value: any) => {
        this.props.taxReturn.documentSettings.deliverySettings.contactPerson = value
        this.setState({ selectedContactPerson: value });
    }

    private handleSenderChange = (value: any) => {
        if (value == 0) {
            this.props.taxReturn.documentSettings.deliverySettings.sender.senderType = DefaultSenderInfoType.Company
            this.props.taxReturn.documentSettings.deliverySettings.sender.senderId = 0
        }
        else {
            this.props.taxReturn.documentSettings.deliverySettings.sender.senderType = DefaultSenderInfoType.User
            this.props.taxReturn.documentSettings.deliverySettings.sender.senderId = value
        }
        this.setState({ selectedSender: value });
    }

    private handleNotifyUserChange = (value: any) => {
        this.props.taxReturn.documentSettings.deliverySettings.notifyUser = value;
        this.props.taxReturn.documentSettings.documentNotificationSetting.notifyUser = this.props.extensionUsers.find(x => x.id == value);
        this.setState({ selectedNotifyUser: value });
    }

    private handleMessageChange = (value: any) => {
        let preparerMessage = this.props.taxReturn.documentSettings.deliverySettings.preparerMessage;
        if (!value) {
            this.props.taxReturn.documentSettings.deliverySettings.preparerMessage = {
                id: 0,
                name: "",
                body: "",
                isAllowToEdit: false
            };
            this.setState({
                selectedMessage: 0
            });
            return;
        }

        if (value !== preparerMessage.id) {
            preparerMessage.id = value;
            let selectedMessageBody = (preparerMessage && preparerMessage.id == 0) ? preparerMessage.body :
                (this.props.savedMessages[value] ? this.props.savedMessages[value].messageDetails.body : "");
            if (value) {
                preparerMessage.name = this.props.savedMessages[value] ? this.props.savedMessages[value].messageDetails.name : "";
                preparerMessage.body = selectedMessageBody;
                this.setState({
                    selectedMessage: value,
                    isAllowToEdit: (preparerMessage && preparerMessage.id == 0) ? preparerMessage.isAllowToEdit :
                        (this.props.savedMessages[value] ?
                            this.props.savedMessages[value].messageDetails.isAllowToEdit : true),
                    selectedMessageId: value
                });
            }
            this.props.taxReturn.documentSettings.deliverySettings.preparerMessage = preparerMessage;
        }
    }

    private handleDeliverFirstToChange = (event: any) => {
        if (event.target.name == 'TaxPayer') {
            this.props.taxReturn.documentSettings.deliverySettings.deliverTo = ClientTypes.Taxpayer
        }
        else {
            this.props.taxReturn.documentSettings.deliverySettings.deliverTo = ClientTypes.Spouse
        }
        this.forceUpdate()
    }

    private setMessageDetailsDropDown = () => {
        let _messageDetails: SelectOptions[] = [];

        Object.keys(this.props.savedMessages).map((key, index) => {
            _messageDetails.push({
                value: this.props.savedMessages[parseInt(key)].messageDetails.id,
                label: this.props.savedMessages[parseInt(key)].messageDetails.name
            })
        });
        let defaultMessageDetails = this.props.taxReturn.documentSettings.deliverySettings.preparerMessage ?
            this.props.taxReturn.documentSettings.deliverySettings.preparerMessage.id
            : this.props.extensionCompanySettings.defaultSettings.savedMessage;
        let selectedMessageValue: number = 0;
        let isAllowToEdit: boolean = false;
        if (this.props.taxReturn.documentSettings.deliverySettings.preparerMessage &&
            this.props.taxReturn.documentSettings.deliverySettings.preparerMessage.id == 0) {
            selectedMessageValue = this.props.taxReturn.documentSettings.deliverySettings.preparerMessage.id;
            isAllowToEdit = this.props.taxReturn.documentSettings.deliverySettings.preparerMessage.isAllowToEdit;
        }
        else {
            selectedMessageValue = this.props.savedMessages[defaultMessageDetails] === undefined ?
                this.props.savedMessages[parseInt(Object.keys(this.props.savedMessages)[0])].messageDetails.id
                : this.props.savedMessages[defaultMessageDetails].messageDetails.id;
            isAllowToEdit = this.props.savedMessages[defaultMessageDetails] === undefined ?
                this.props.savedMessages[parseInt(Object.keys(this.props.savedMessages)[0])].messageDetails.isAllowToEdit
                : this.props.savedMessages[defaultMessageDetails].messageDetails.isAllowToEdit;
        }

        this.setState({
            message: _messageDetails,
        }, () => {
            this.setState({
                selectedMessage: selectedMessageValue,
                isAllowToEdit: isAllowToEdit
            })
        });
    }

    setContactDropdown() {

        const selectedUser = this.props.extensionUsers.find(x => x.id == this.props.taxReturn.documentSettings.deliverySettings.contactPerson);

        const contactUsers = this.props.extensionUsers.map(x => {
            return {
                value: x.id,
                label: `${x.firstName} ${x.lastName}`
            }
        });

        this.setState({
            contactPerson: contactUsers,
            selectedContactPerson: selectedUser ? selectedUser.id : 0
        }, () => (this.setSenderDropdown()));
    }

    setSenderDropdown() {
        let index: number;
        if (this.props.taxReturn.documentSettings.deliverySettings.sender.senderType == DefaultSenderInfoType.User) {
            if (this.props.taxReturn.documentSettings.deliverySettings.sender.senderId !== null) {
                index = (Enumerable.from(this.props.extensionUsers).indexOf(x => x.id === this.props.taxReturn.documentSettings.deliverySettings.sender.senderId)) + 1
            }
            else {
                index = -1;
            }
        }
        else {
            index = 0;
        }

        const _sender = []

        _sender.push({
            value: 0,
            label: this.props.company.companyProfile.companyInfo.companyName
        })

        const senders = this.props.extensionUsers.map(x => {
            return {
                value: x.id,
                label: `${x.firstName} ${x.lastName}`
            }
        });

        this.setState({
            sender: [..._sender, ...senders]
        }, () => (this.setState({
            selectedSender: index >= 0 ? this.state.sender[index].value : 0
        }), this.setNotifyUser()));
    }

    setNotifyUser() {
        const selectedUser = this.props.extensionUsers.find(x => x.id == this.props.taxReturn.documentSettings.deliverySettings.notifyUser)

        const notifyUsers = this.props.extensionUsers.map(x => {
            return {
                value: x.id,
                label: `${x.firstName} ${x.lastName}`
            }
        });

        this.setState({
            notifyUser: notifyUsers,
            selectedNotifyUser: selectedUser ? selectedUser.id : 0
        }, this.setPaymentNotifyUser);
    }

    setPaymentNotifyUser = () => {
        const _sender = this.props.extensionUsers.map(user => {
            return {
                value: user.id,
                label: `${user.firstName} ${user.lastName}`
            }
        });

        this.setState({
            paymentNotifyUser: _sender,
            selectedPaymentNotifyUser: this.props.taxReturn.documentSettings.documentNotificationSetting.voucherPaymentNotifyUser
        });
    }

    SetReminderOptions() {
        var reminderOptions = [];
        for (var i = 1; i <= 30; i++) {
            reminderOptions.push({ value: i, label: i === 1 ? i + ' day' : i + ' days' });
        }
        let noOfDaysForVoucherReminder: number = this.props.taxReturn.documentSettings.documentNotificationSetting.paymentVoucherNotificationSettingsModel.noOfDaysForVoucherReminder
        let noOfDaysForSigningReminder: number = this.props.taxReturn.documentSettings.documentNotificationSetting.signingReminderNotificationSettingsModel.noOfDaysForSigningReminder
        this.setState({
            reminderOptions: reminderOptions,
            selectedReminder: noOfDaysForVoucherReminder,
            selectedSingingReminder: noOfDaysForSigningReminder,
            signingReminderOptions: reminderOptions
        });
    }



    private handleVoucherToggleSwitchChange = (event: any) => {
        this.props.taxReturn.documentSettings.documentNotificationSetting.paymentVoucherNotificationSettingsModel.enablePaymentVoucherReminder = event.target.checked
        this.forceUpdate()
    }

    private handleSigningToggleSwitchChange = (event: any) => {
        this.props.taxReturn.documentSettings.documentNotificationSetting.signingReminderNotificationSettingsModel.enableSigningReminder = event.target.checked
        this.forceUpdate()
    }

    private handleVoucherReminderChange = (selectedOption: any) => {

        this.props.taxReturn.documentSettings.documentNotificationSetting.paymentVoucherNotificationSettingsModel.noOfDaysForVoucherReminder = selectedOption

        this.setState({
            selectedReminder: selectedOption
        });
    }

    private handleSigningReminderChange = (selectedOption: any) => {
        this.props.taxReturn.documentSettings.documentNotificationSetting.signingReminderNotificationSettingsModel.noOfDaysForSigningReminder = selectedOption
        this.setState({
            selectedSingingReminder: selectedOption
        });
    }

    removeAllSignature = () => {
        this.props.taxReturn.formGroups = removeAllUserSignature(this.props.taxReturn);
    }

    private handleSignatureChange = (event: any, signatureType: SignatureType) => {
        if (signatureType == SignatureType.ReviewOnly) {
            if (this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType == SignatureType.ESignWhenAllIncludedReturnsAllowed ||
                this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType == SignatureType.ManualSign) {
                //all the Signture removal warnign
                bootbox.confirm({
                    title: ProcessReturnConstants.ReviewOnlyThisDocumentTitle,
                    message: ProcessReturnConstants.ReviewSignatureRemoveMessage,
                    buttons: {
                        cancel: {
                            label: '<i class="fas fa-times"></i> Cancel',
                            className: 'btn-white btn-default'
                        },
                        confirm: {
                            label: '<i class="fas fa-check"></i> Confirm',
                            className: 'btn-info'
                        }
                    },
                    callback: (result: boolean) => {
                        if (result) {
                            this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType = SignatureType.ReviewOnly;
                            this.props.handleEfileAddedToggle(false);
                            this.props.handleSignatureOptionToggle(event);

                            this.forceUpdate()
                        } else {
                            this.forceUpdate()
                        }
                    }
                });
            } else {
                this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType = signatureType;
                this.props.handleSignatureOptionToggle(event);
                this.forceUpdate()
            }
        }
        else {
            this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType = signatureType;
            if (signatureType == SignatureType.ManualSign) {
                this.props.handleEfileAddedToggle(false);
            }
            this.props.handleSignatureOptionToggle(event);
            this.forceUpdate()
        }

    }


    private setCustomColumnOptions = () => {
        let _customColOptions: SelectOptions[] = [];
        _customColOptions.push({ value: 0, label: "None" });
        if (this.props.customColumnData != null) {
            let _selectedColumnValue = null;
            if (this.props.customColumnData.customColumnValues != undefined) {
                Object.keys(this.props.customColumnData.customColumnValues).map((key, index) => {
                    let currColValue = this.props.customColumnData.customColumnValues[parseInt(key)];
                    if (currColValue.id == this.props.taxReturn.documentSettings.documentDisplaySetting.customColumn.id) {
                        _selectedColumnValue = currColValue;
                    }
                    _customColOptions.push({
                        value: currColValue.id,
                        label: currColValue.value
                    })
                })
                if (_selectedColumnValue == null) {
                    this.setCustomColumnValueInTaxReturn(0);
                }
                this.setState({
                    customColumnOptions: _customColOptions,
                    selectedCustomColumn: (_selectedColumnValue) ? this.props.taxReturn.documentSettings.documentDisplaySetting.customColumn.id : 0
                });
            }
        }
    }

    private handleCustomColumnChange = (selectedOption: any) => {
        this.setCustomColumnValueInTaxReturn(selectedOption);
        this.setState({
            selectedCustomColumn: selectedOption
        });

    }

    private setCustomColumnValueInTaxReturn = (selectedId: any) => {
        let customColValue: IColumnValues;
        let reqCols = this.props.customColumnData.customColumnValues.filter((i) => i.id == selectedId);
        if (reqCols && reqCols.length > 0) {
            customColValue = reqCols[0];
        }
        else {
            customColValue = { id: 0, value: "", customColumn: 0, inUseCount: 0 }
        }
        this.props.handleCustomColumnChange(customColValue);
    }

    private setMailingAddressDropdown = () => {
        var _mailingAddressesOptions: SelectOptions[] = [];
        Object.keys(this.props.mailingReturnAddressDictionary).map((key, index) => {
            _mailingAddressesOptions.push({
                value: this.props.mailingReturnAddressDictionary[parseInt(key)].id,
                label: this.props.mailingReturnAddressDictionary[parseInt(key)].title
            });
        });

        var manualAddressId = this.props.taxReturn.documentSettings.deliverySettings.manualAddressId;
        this.setState(
            {
                mailingAddressOptions: _mailingAddressesOptions
            },
            () => {
                this.setState({
                    selectedMailingAddress:
                        this.props.mailingReturnAddressDictionary[manualAddressId] === undefined
                            ? Object.values(this.props.mailingReturnAddressDictionary).filter(x=>x.isPrimary==true)[0].id
                            : this.props.mailingReturnAddressDictionary[manualAddressId].id
                },
                () => {
                    this.props.taxReturn.documentSettings.deliverySettings.manualAddressId =
                        this.state.selectedMailingAddress;
                });
            }
        );
    };

    preparerMessageHide() {
        this.setState({
            preparerMessageShow: false
        })
    }
    onEditButtonClick() {
        this.setState({
            preparerMessageShow: true,
            prepareMessageMode: PrepareMessageMode.Update,
            prepareMessage: this.props.taxReturn.documentSettings.deliverySettings.preparerMessage.body
        })
    }
    onAddButtonClick() {
        this.setState({
            preparerMessageShow: true,
            prepareMessageMode: PrepareMessageMode.Add,
            prepareMessage: ""
        })
    }
    onChangeTinyMCE(event: any) {
        this.setState({
            prepareMessage: event.target.getContent()
        })

    }
    preparerMessageUpdateButtonClick(event: any) {
        if (this.state.prepareMessage == "" || this.state.prepareMessage.replace(/\&nbsp;/g, '').replace(/<\/?p[^>]*>/g, '').trim() == "") {
            VenusNotifier.Error(Constants.SavedMessageConstants.ValidateBody, "Error");
            return;
        }
        else if (this.state.prepareMessage != '') {
            this.props.taxReturn.documentSettings.deliverySettings.preparerMessage.body = htmlencode.htmlEncode(this.state.prepareMessage ? this.state.prepareMessage : "");
            this.props.taxReturn.documentSettings.deliverySettings.preparerMessage.name = this.state.prepareMessage ? this.state.prepareMessage : "";
            VenusNotifier.Success(Constants.SavedMessageConstants.UpdateMessageSuccess, "Success");
        }
        this.setState({
            preparerMessageShow: false
        })
    }

    savePreparerMessage(event: any) {
        let newMessage = this.state.prepareMessage;
        if (newMessage == "" || newMessage.replace(/\&nbsp;/g, '').replace(/<\/?p[^>]*>/g, '').trim() == "") {
            VenusNotifier.Error(Constants.SavedMessageConstants.ValidateBody, "Error");
            return;
        }
        else if (newMessage != '') {
            this.props.taxReturn.documentSettings.deliverySettings.preparerMessage = ({
                id: 0, name: newMessage,
                body: htmlencode.htmlEncode(this.state.prepareMessage),
                isAllowToEdit: true
            }) as MessageDetails;
            VenusNotifier.Success(Constants.SavedMessageConstants.SaveMessageSuccess, "Success");
        }
        this.setState({
            preparerMessageShow: false,
            prepareMessage: '',
            selectedMessage: 0,
            customPlaceHolder: "Custom message",
            isAllowToEdit: true
        })
    }

    public handleRetentionPeriodChange(value: number) {

        let taxReturn: TaxDocument.ITaxReturn = this.props.taxReturn;
        taxReturn.documentSettings.documentRetentionSetting.retentionPeriod = value;
        this.setState({ retentionDuration: value });

    }

    handleDelegateeKbaAuthenticationChange = (event: any) => {
        if (this.props.taxReturn.documentSettings.documentSignatureSetting.enableSignerDelegation) {
            this.props.taxReturn.documentSettings.documentSignatureSetting.enableDelegateeKbaAuthentication = event.target.checked;
            this.setState({ isDelegateeKBAChanged: true });
        }
    }

    handleSignerDelegationChange = (event: any) => {
        this.props.taxReturn.documentSettings.documentSignatureSetting.enableSignerDelegation = event.target.checked;

        if (!event.target.checked) {
            this.props.taxReturn.documentSettings.documentSignatureSetting.enableDelegateeKbaAuthentication = false;
        }

        this.setState({ isSignerDelegationChanged: true });
    }

    private handlePaymentNotifyUserChange = (value: any) => {
        this.props.taxReturn.documentSettings.documentNotificationSetting.voucherPaymentNotifyUser = value;
        this.setState({ selectedPaymentNotifyUser: value });
    }

    private handleAttestClientChange = (event: any) => {
        const { extensionCompanySettings } = this.props;
        let { taxReturn } = this.props;
        const retentionPeriod: number = event.target.checked ? extensionCompanySettings.retentionSettingsModel.attestRetentionPeriod : extensionCompanySettings.retentionSettingsModel.retentionPeriod;
        taxReturn.documentSettings.documentRetentionSetting.retentionPeriod = retentionPeriod;
        if (TaxDocument.isIndividual(taxReturn) || TaxDocument.isMutual(taxReturn)) {
            taxReturn.taxpayer.isModified = true;
            taxReturn.taxpayer.additionalInfo.isAttested = event.target.checked;
        }
        else if (TaxDocument.isPartnership(taxReturn)) {
            taxReturn.partnership.isModified = true;
            taxReturn.partnership.additionalInfo.isAttested = event.target.checked;
        }
        this.setState({ retentionDuration: retentionPeriod, isAttested: event.target.checked });
    }

    private handleMailingAddressChange = (value: any) => {
        this.props.taxReturn.documentSettings.deliverySettings.manualAddressId = value;
        this.setState({ selectedMailingAddress: value }, () => {
            this.props.updateTaxDocument(this.props.taxReturn);
        });
    };

    public render() {
        let messageBody: string = this.props.taxReturn.documentSettings.deliverySettings.preparerMessage.body;
        messageBody = htmlencode.htmlDecode(messageBody);

        let clientType = isPartnership(this.props.taxReturn) ? ClientTypes.PartnerShip : ClientTypes.Taxpayer;
        //let iconDisplay = (this.props.manualAddressList[parseInt(Object.keys(this.props.manualAddressList)[0])].id === this.props.taxReturn.documentSettings.deliverySettings.manualAddressId) ? false : true;
        let enableSignatureOption: boolean = true;
        let customColumnPresent: boolean = true;
        let isBusinessRet = isBusinessReturn(this.props.taxReturn);
        let signatureType: any = this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType;
        signatureType = (typeof signatureType == "number") ? SignatureType[signatureType] : signatureType;

        ////No need to hide signature Text
        //if (isBusinessReturn(this.props.taxReturn) &&
        //    !GetEnableEsignatureForBusinessReturns(this.props.extensionCompanySettings) &&
        //    signatureType == SignatureType[SignatureType.ManualSign] &&
        //    !this.props.isSignatureoptionsToggledInDeliveryOption) {
        //    enableSignatureOption = false;
        //}



        let deceasedCount = 0;

        if (this.props.taxReturn && TaxDocument.isMutual(this.props.taxReturn)) {
            if (this.props.taxReturn.taxpayer.isDeceased) {
                deceasedCount += 1;
            }
            if (this.props.taxReturn.spouse && this.props.taxReturn.spouse.isDeceased) {
                deceasedCount += 1;
            }
        }
        let customColumnTitle = "";
        if (this.props.customColumnData == null || this.props.customColumnData.header == "") {
            customColumnPresent = false;
        }
        else {
            customColumnTitle = "Custom Column: " + this.props.customColumnData.header;
        }


        //Conditional disable
        let eSignSelectionDisabled: boolean;
        let manualSelectionDisabled: boolean;
        if (
            (isReviewExtesnion(this.props.extensionCompanySettings, isBusinessRet) &&
                isStrictReviewExtesnion(this.props.extensionCompanySettings, isBusinessRet))) {
            eSignSelectionDisabled = true;
            manualSelectionDisabled = true;
        }
        else {
            eSignSelectionDisabled = !this.props.isAssignedToLoggedinUser ||
                (deceasedCount == 2 || //Both Taxpayer are deceased
                    (TaxDocument.isIndividual(this.props.taxReturn) ? this.props.taxReturn.taxpayer.isDeceased : false)); //isIndividual and deceased

            manualSelectionDisabled = !this.props.isAssignedToLoggedinUser;
        }

        //Conditional disable
        let reviewSelectionDisabled: boolean;
        if (!isReviewExtesnion(this.props.extensionCompanySettings, isBusinessRet)) //Selection is Esign
        {
            reviewSelectionDisabled = true;
        } else {
            reviewSelectionDisabled = !this.props.isAssignedToLoggedinUser;
        }

        return (<TabContentContainer enableScroll={true}>
            <LoadingOverlay style={{ height: '100%' }}>
                <Col sm={12}>
                    <div className="content-box">
                        <Row>
                            <Col sm={12}>
                                <div className="col-sm-3" style={{ display: "inline-flex", marginRight: 5, paddingLeft: 0 }}>
                                    <h4 className="header-delivery-options" style={{ marginRight: 5 }}>
                                        <i className="modalIcon fas fa-user-circle" style={{ marginRight: 7 }}></i>
                                        Taxpayer Dashboard</h4>
                                </div>
                                <Button type="button" style={{ marginTop: 5, marginLeft: 4 }} variant="info"
                                    onClick={() => this.props.generateTaxPayerView(clientType)}
                                    disabled={this.props.disabledTaxpayerPreview || this.props.pdfLoading}
                                    data-test-auto="1BF09CF9-CEB8-45E6-9025-33DA6D26693B">
                                    <i className={this.props.disabledTaxpayerPreview ? 'fas fa-spinner fa-spin text-white bg-transparent' : 'fas fa-eye'} aria-hidden="true"></i>
                                    Taxpayer Preview</Button>
                            </Col>
                            <Col sm={12}>
                                <hr style={{ marginTop: "10px" }} />
                                <div className="form-horizontal">
                                    <Row className="marB15">
                                        <Col className="text-left" sm={3} as={FormLabel}>
                                            Contact Person:
                                        </Col>
                                        <Col sm={4} className={"zeroborderRadius"}>
                                            <DropdownComponent
                                                id="ddlContactPerson"
                                                options={this.state.contactPerson}
                                                onChange={this.handleContactPersonChange}
                                                selectedValue={this.state.selectedContactPerson}
                                                disabled={!this.props.isAssignedToLoggedinUser}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-left" sm={3} as={FormLabel}>
                                            Message From Preparer:
                                        </Col>
                                        <Col sm={4} className={"zeroborderRadius"}>
                                            <DropdownComponent
                                                id="ddlPreparer"
                                                options={this.state.message}
                                                onChange={this.handleMessageChange}
                                                customPlaceHolder={this.state.customPlaceHolder}
                                                selectedValue={this.state.selectedMessage}
                                                disabled={!this.props.isAssignedToLoggedinUser}
                                            />
                                        </Col>
                                        <Col sm={3}>
                                            <Button type="button"
                                                title="Add New Message"
                                                variant="success"
                                                data-test-auto="2599FBD9-2565-45DB-AF1F-7250D3DB45F7"
                                                style={{ paddingRight: 20, width: 76, }}
                                                onClick={this.onAddButtonClick}
                                            ><i className="fa fa-plus" style={{ paddingRight: 10 }}></i>
                                                New
                                            </Button>
                                        </Col>
                                    </Row>

                                    <Row style={{ paddingTop: 10 }}>
                                        <Col sm={3} >
                                        </Col>
                                        <Col sm={4}>
                                            <div dangerouslySetInnerHTML={{ __html: htmlUtilities.sanitize(ReplaceHelperVariable(messageBody)) }} className="selected-message-view" style={{ padding: 9 }}>
                                            </div>
                                        </Col>
                                        <Col sm={3}>
                                            <Button type="button"
                                                title="Edit Message"
                                                variant="info"
                                                style={{ paddingRight: "inherit", width: 76, marginLeft: 2 }}
                                                data-test-auto="2599FBD9-2565-45DB-AF1F-7250D3DB45F7"
                                                onClick={this.onEditButtonClick}
                                            >
                                                <i className="fas fa-edit" aria-hidden="true"></i>
                                                {this.state.isAllowToEdit ? "Edit" : "View"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>

                        </Row>
                        {/*<Row style={{ marginTop: "30px" }}>
                        <Col sm={2} className="new-main-content">
                            <h4><span className="modalIcon watermarkIconwidth fa-stack fa-2x" style={{ fontSize: '12px' }}>
                                <i className="fa fa-file fa-stack-2x"></i>
                                <i className="fa fa-tint fa-stack-1x fa-inverse" style={{ bottom: '-3px' }}></i>
                            </span>
                                Tax Return Watermarks </h4>
                        </Col>
                        <Col sm={12}>
                            <Row>
                                <hr style={{ marginTop: "-4px" }} />
                                <Col className="padT07" sm={3} as={FormLabel}>
                                    Watermark Name
                                </Col>

                                <Col sm={3} className={"zeroborderRadius"}>
                                    <DropdownComponent
                                        id="ddlWatermark"
                                        options={this.state.watermarkOptions}
                                        onChange={this.handleWatermarkChange}
                                        selectedValue={this.state.selectedWatermark}
                                        clearable={true}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>*/}
                        {
                            customColumnPresent ?
                                <Row style={{ marginTop: "30px" }}>
                                    <Col sm={12} className="new-main-content">
                                        <h4><span className="modalIcon">
                                            <i><img src={require("../../../../images/logos/column-select.svg").default} style={{ height: '20px' }} /></i>
                                        </span>
                                            {customColumnTitle} </h4>
                                        <hr style={{ marginTop: -2 }} />
                                    </Col>
                                    <Col sm={12}>
                                        <Row>
                                            <hr style={{ marginTop: "-4px" }} />
                                            <Col className="padT07 text-bold" sm={3} as={FormLabel}>
                                                Column Value
                                            </Col>

                                            <Col sm={4} className={"zeroborderRadius"}>
                                                <DropdownComponent
                                                    id="ddlCustomColumn"
                                                    options={this.state.customColumnOptions}
                                                    onChange={this.handleCustomColumnChange}
                                                    selectedValue={this.state.selectedCustomColumn}
                                                    clearable={true}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                : null

                        }

                        <Row style={{ marginTop: "30px" }}>
                            <Col sm={12} className="new-main-content">
                                <h4 className="header-delivery-options">
                                    <i className="modalIcon fas fa-envelope"></i>
                                    Email Notifications
                                </h4>
                                <hr style={{ marginTop: -2 }} />
                            </Col>
                            <Col sm={12}>
                                <div className="form-horizontal">
                                    {
                                        TaxDocument.isMutual(this.props.taxReturn) &&
                                        <Row>
                                            <Col className="text-left" sm={3} as={FormLabel}>
                                                Deliver First to:
                                            </Col>
                                            <Col sm={3}>
                                                <FormGroup style={{ marginLeft: 5 }}>
                                                    <RadioButtonComponent
                                                        text="Taxpayer"
                                                        style={{ marginRight: 20 }}
                                                        id="TaxPayer"
                                                        name="TaxPayer"
                                                        data-test-auto="97521163-5EE4-42BA-949A-74B885444731"
                                                        checked={this.props.taxReturn.documentSettings.deliverySettings.deliverTo === ClientTypes.Taxpayer}
                                                        onChange={this.handleDeliverFirstToChange}
                                                        disabled={
                                                            (deceasedCount != 2 && this.props.taxReturn.taxpayer.isDeceased) ||
                                                            !this.props.isAssignedToLoggedinUser} />
                                                    <RadioButtonComponent text="Spouse"
                                                        id="Spouse"
                                                        name="Spouse"
                                                        data-test-auto="6182B06E-E58E-4DB6-80E1-FAD1A69FE8AE"
                                                        checked={this.props.taxReturn.documentSettings.deliverySettings.deliverTo === ClientTypes.Spouse}
                                                        onChange={this.handleDeliverFirstToChange}
                                                        disabled={
                                                            (deceasedCount != 2 && this.props.taxReturn.spouse.isDeceased) ||
                                                            !this.props.isAssignedToLoggedinUser} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    }
                                    <Row className="marB15">
                                        <Col className="text-left" sm={3} as={FormLabel}>
                                            Sender Name:
                                        </Col>
                                        <Col sm={4} className={"zeroborderRadius"}>
                                            <DropdownComponent
                                                id="ddlSender"
                                                options={this.state.sender}
                                                onChange={this.handleSenderChange}
                                                selectedValue={this.state.selectedSender}
                                                disabled={!this.props.isAssignedToLoggedinUser}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="marB15">
                                        <Col className="text-left" sm={3} as={FormLabel}>
                                            Notify About Signing Events:
                                        </Col>
                                        <Col sm={4} className={"zeroborderRadius"}>
                                            <DropdownComponent
                                                id="ddlSigningEvents"
                                                options={this.state.notifyUser}
                                                onChange={this.handleNotifyUserChange}
                                                selectedValue={this.state.selectedNotifyUser}
                                                disabled={!this.props.isAssignedToLoggedinUser ||
                                                    signatureType == SignatureType[SignatureType.ReviewOnly]}
                                            />
                                        </Col>
                                    </Row>
                                    {
                                        this.props.extensionCompanySettings.notificationSettingsModel.paymentVoucherNotificationSettings.paymentNotification &&
                                        <Row>
                                            <Col className="text-left" sm={3} as={FormLabel}>
                                                <div style={{ display: "flex" }}>
                                                    Notify About Payment Activity:
                                                    <Link to={'#'}
                                                        className="upload-help"
                                                        data-placement="right"
                                                        data-toggle="tooltip"
                                                        title={Constants.VoucherPaymentNotificationInfo}>
                                                        <i className="fas fa-question-circle help-icon" />
                                                    </Link>
                                                </div>
                                            </Col>
                                            <Col sm={4} className={"zeroborderRadius"}>
                                                <DropdownComponent
                                                    id="ddlPaymentEvents"
                                                    options={this.state.paymentNotifyUser}
                                                    onChange={this.handlePaymentNotifyUserChange}
                                                    selectedValue={this.state.selectedPaymentNotifyUser}
                                                    disabled={!this.props.isAssignedToLoggedinUser}
                                                />
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: "30px" }}>
                            <Col sm={12} style={{ paddingTop: "8px" }} className="new-main-content">
                                <h4><i className="modalIcon fas fa-bell" /> Reminders </h4>
                                <hr style={{ marginTop: -2 }} />
                            </Col>


                            <Col sm={12}>
                                <Row>
                                    <hr style={{ marginTop: "-4px" }} />
                                    <Col className="padT07 text-bold" sm={3} as={FormLabel}>
                                        Send Voucher Reminder before
                                    </Col>

                                    <Col sm={4} className={"zeroborderRadius"}>
                                        <DropdownComponent
                                            id="ddlRemind"
                                            options={this.state.reminderOptions}
                                            onChange={this.handleVoucherReminderChange}
                                            selectedValue={this.state.selectedReminder}
                                            clearable={true}
                                            disabled={!this.props.taxReturn.documentSettings.documentNotificationSetting.paymentVoucherNotificationSettingsModel.enablePaymentVoucherReminder}
                                        />
                                    </Col>

                                    <Col sm={2} className="padT07" as={FormLabel}>
                                        from due date
                                    </Col>
                                    <Col sm={1} style={{ paddingTop: "5px" }}>
                                        <ToggleSwitch switched={this.props.taxReturn.documentSettings.documentNotificationSetting.paymentVoucherNotificationSettingsModel.enablePaymentVoucherReminder}
                                            handleChange={this.handleVoucherToggleSwitchChange} />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col className="padT07 text-bold" sm={3} as={FormLabel}>
                                        Send Signing Reminder after
                                    </Col>

                                    <Col sm={4} className={"zeroborderRadius"}>
                                        <DropdownComponent
                                            id="ddlsigningReminder"
                                            options={this.state.signingReminderOptions}
                                            onChange={this.handleSigningReminderChange}
                                            selectedValue={this.state.selectedSingingReminder}
                                            clearable={true}
                                            disabled={!this.props.taxReturn.documentSettings.documentNotificationSetting.signingReminderNotificationSettingsModel.enableSigningReminder ||
                                                signatureType == SignatureType[SignatureType.ReviewOnly]}
                                        />
                                    </Col>

                                    <Col sm={2} className="padT07" as={FormLabel}>
                                        since last reminder/initial email
                                    </Col>
                                    <Col sm={1} style={{ paddingTop: "5px" }}>
                                        <ToggleSwitch switched={this.props.taxReturn.documentSettings.documentNotificationSetting.signingReminderNotificationSettingsModel.enableSigningReminder
                                            && signatureType != SignatureType[SignatureType.ReviewOnly]}
                                            handleChange={this.handleSigningToggleSwitchChange}
                                            disabled={signatureType == SignatureType[SignatureType.ReviewOnly]} />
                                    </Col>

                                </Row>
                            </Col>

                        </Row>
                        <Row style={{ marginTop: "30px" }}>
                            <Col sm={12} style={{ paddingTop: "8px" }} className="new-main-content">
                                <h4><i className="modalIcon fas fa-trash-alt" /> Modify Retention Period </h4>
                                <hr style={{ marginTop: -2 }} />
                            </Col>

                            <Col sm={12}>
                                <Row>
                                    <hr style={{ marginTop: "-4px" }} />
                                    <Col className="padT07" sm={3} as={FormLabel}>
                                        Please select the retention period for the document
                                    </Col>

                                    <Col sm={3} className={"zeroborderRadius marB15"}>
                                        <YearAndMonth
                                            id="DocRetention"
                                            controlValue={this.state.retentionDuration}
                                            onControlValueChange={this.handleRetentionPeriodChange}
                                            componentEnabled={this.props.extensionCompanySettings.retentionSettingsModel.allowOverride}
                                            years={7}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="padT07" sm={3}>
                                    </Col>
                                    <Col sm={3} className={"zeroborderRadius"}>
                                        <CheckBoxComponent id="chkAttestClient" checked={this.state.isAttested} onChange={this.handleAttestClientChange} text="Attest Client" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {enableSignatureOption ?
                            <Row style={{ marginTop: "30px" }}>
                                <Col sm={12} className="new-main-content">
                                    <h4> <i className="modalIcon fas fa-pen-nib" /> Signature Options</h4>
                                    <hr style={{ marginTop: "-4px" }} />

                                    <Row>
                                        <Col className="padT07 text-bold" sm={3} as={FormLabel} >
                                            Select Signature option for this Return :
                                        </Col>
                                        <Col className="text-left" sm={2} as={FormLabel}>
                                            <RadioButtonComponent text="E-Sign"
                                                id="eSign"
                                                data-test-auto="EA4DE573-44E1-4D8D-9B4E-0BC92A00E06C"
                                                checked={
                                                    signatureType === SignatureType[SignatureType.ESignWhenAllIncludedReturnsAllowed] ||
                                                        (signatureType == SignatureType[SignatureType.ESignWhenAllIncludedReturnsAllowed] &&
                                                            this.props.isEsign == false)
                                                        ? true : false}
                                                onChange={(event) => { this.handleSignatureChange(event, SignatureType.ESignWhenAllIncludedReturnsAllowed) }}
                                                disabled={eSignSelectionDisabled} >
                                            </RadioButtonComponent>
                                        </Col>
                                        <Col className="text-left" sm={2} style={{ width: "16%" }}>

                                            <RadioButtonComponent text="Manual Sign"
                                                id="manualSign"
                                                data-test-auto="6FBE97EF-59D7-414E-B4EC-12C93B75D34C"
                                                checked={signatureType == SignatureType[SignatureType.ManualSign] ||
                                                    (signatureType == SignatureType[SignatureType.ESignWhenAllIncludedReturnsAllowed] && this.props.isEsign == true)
                                                    ? true : false}
                                                //onChange={(event) => { () => this.handleSignatureChange(event, SignatureType.ManualSign) }}
                                                onChange={(event) => { this.handleSignatureChange(event, SignatureType.ManualSign) }}
                                                disabled={manualSelectionDisabled}>
                                            </RadioButtonComponent>
                                        </Col>
                                        <Col className="text-left" sm={3} style={{ width: "16%", paddingLeft: "100px" }}>
                                            <RadioButtonComponent text="Review Extension"
                                                id="reviewExtension"
                                                data-test-auto="19DAFD1A-2737-409A-BD04-13074469EE1E"
                                                checked={(signatureType == SignatureType[SignatureType.ReviewOnly] ||
                                                    (signatureType == SignatureType[SignatureType.ReviewOnly] && this.props.isEsign == false))
                                                    ? true : false}
                                                onChange={(event) => { this.handleSignatureChange(event, SignatureType.ReviewOnly) }}
                                                disabled={reviewSelectionDisabled}>
                                            </RadioButtonComponent>
                                        </Col>
                                    </Row>
                                    <Row style={{ display: 'block', 'marginTop': '1rem' }}>
                                        {(this.props.taxReturn.documentSettings.documentSignatureSetting.enableSignerDelegation
                                            || this.state.isSignerDelegationChanged
                                            || (this.props.extensionCompanySettings.signatureSettingsModel.enableEntityReturnSignerDelegation &&
                                                this.props.taxReturn.engagementType.toString() != TaxDocument.EngagementType[TaxDocument.EngagementType.E1040])
                                            || (this.props.extensionCompanySettings.signatureSettingsModel.enableIndividualReturnSignerDelegation &&
                                                this.props.taxReturn.engagementType.toString() == TaxDocument.EngagementType[TaxDocument.EngagementType.E1040])
                                        ) &&
                                            <Col style={{ paddingLeft: '0px' }}>
                                                <div className="delegation-settings">
                                                    <CheckBoxComponent
                                                        id={"signerDelegation"}
                                                        text={"Allow signer delegation"}
                                                        onChange={this.handleSignerDelegationChange}
                                                        checked={this.props.taxReturn.documentSettings.documentSignatureSetting ?
                                                            (this.props.taxReturn.documentSettings.documentSignatureSetting.enableSignerDelegation &&
                                                                signatureType !== SignatureType[SignatureType.ManualSign]) : false}
                                                        datatestAuto={"0D350954-4A6D-43CB-8F53-B50B455C19F5"}
                                                        disabled={
                                                            signatureType == SignatureType[SignatureType.ManualSign] ||
                                                            signatureType == SignatureType[SignatureType.ReviewOnly]
                                                        }
                                                    />
                                                </div>
                                            </Col>
                                        }
                                        {((this.props.taxReturn.engagementType.toString() == TaxDocument.EngagementType[TaxDocument.EngagementType.E1040] &&
                                            this.props.taxReturn.documentSettings.documentSignatureSetting.enableDelegateeKbaAuthentication)
                                            || (this.props.taxReturn.engagementType.toString() == TaxDocument.EngagementType[TaxDocument.EngagementType.E1040] &&
                                                this.props.extensionCompanySettings.signatureSettingsModel.enableDelegateeKbaAuthentication)
                                            || this.state.isDelegateeKBAChanged) &&
                                            <Col style={{ paddingLeft: '0px' }}>
                                                <div className="delegation-settings">
                                                    <CheckBoxComponent
                                                        id={"delegateeKbaAuthentication"}
                                                        text={"Enable Knowledge-Based Authentication for delegated signers (i.e. children and individuals living abroad)"}
                                                        onChange={this.handleDelegateeKbaAuthenticationChange}
                                                        checked={this.props.taxReturn.documentSettings.documentSignatureSetting ?
                                                            (this.props.taxReturn.documentSettings.documentSignatureSetting.enableDelegateeKbaAuthentication &&
                                                                signatureType !== SignatureType[SignatureType.ManualSign]) : false}
                                                        datatestAuto={"7E573F4C-09BE-4781-AF10-FD789D8C728C"}
                                                        disabled={!this.props.taxReturn.documentSettings.documentSignatureSetting.enableSignerDelegation ||
                                                            signatureType == SignatureType[SignatureType.ManualSign] ||
                                                            signatureType == SignatureType[SignatureType.ReviewOnly]}
                                                    />
                                                </div>
                                            </Col>
                                        }
                                    </Row>

                                </Col>
                            </Row>



                            : null
                        }

                        {this.props.mailingReturnAddressDictionary && (
                            <Row style={{ marginTop: "30px" }}>
                                <Col sm={12} className="new-main-content">
                                    <h4>
                                        {" "}
                                        <i className="modalIcon fas fa-map-marker-alt " /> Mailing Address
                                    </h4>
                                    <hr style={{ marginTop: "-2px" }} />
                                </Col>

                                <Col sm={12}>
                                    <Row>
                                        <Col className="padT07" sm={3} as={FormLabel}>
                                            Address:
                                        </Col>
                                        <Col sm={3} className={"zeroborderRadius"}>
                                            <DropdownComponent
                                                id="ddlAddress"
                                                options={this.state.mailingAddressOptions}
                                                onChange={this.handleMailingAddressChange}
                                                selectedValue={this.state.selectedMailingAddress}
                                                disabled={!this.props.isAssignedToLoggedinUser}
                                            />
                                        </Col>
                                    </Row>

                                    <Row style={{ paddingTop: 10 }}>
                                        <Col sm={3} as={FormLabel}></Col>

                                        <Col sm={3}>
                                            <SelectedMailingAddressView
                                                states={this.props.company.companyProfile.countryStates}
                                                selectedAddressId={this.state.selectedMailingAddress}
                                                mailingReturnAddress={this.props.mailingReturnAddressDictionary[this.state.selectedMailingAddress]}
                                                mailingReturnAddressList={this.props.mailingReturnAddressDictionary}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </div>
                </Col>
                {this.state.prepareMessageMode == PrepareMessageMode.Add ?
                    <PreparerMessage
                        changeStateTinymceBody={this.onChangeTinyMCE}
                        onHide={this.preparerMessageHide}
                        showState={this.state.preparerMessageShow}
                        messageBody={""}
                        isAllowEdit={true}
                        updateButtonClick={this.preparerMessageUpdateButtonClick}
                        saveButtonClick={this.savePreparerMessage}
                        buttonText={"Save"}
                    ></PreparerMessage>
                    :

                    <PreparerMessage
                        changeStateTinymceBody={this.onChangeTinyMCE}
                        onHide={this.preparerMessageHide}
                        showState={this.state.preparerMessageShow}
                        messageBody={ReplaceHelperVariableForEditor(messageBody)}
                        isAllowEdit={this.state.isAllowToEdit}
                        updateButtonClick={this.preparerMessageUpdateButtonClick}
                        saveButtonClick={this.savePreparerMessage}
                        buttonText={"Update"}
                    ></PreparerMessage>
                }
                <Loader loading={this.props.isLoading} />
            </LoadingOverlay>
        </TabContentContainer>)
    }

};
//=============================================================================