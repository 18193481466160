import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { ITaxReturn } from '../../components/common/TaxReturn';
import { RequestCompanyAssignmentsPagesAction, ReceiveCompanyAssignmentsPagesAction } from "./KnownTypes";
import { IAssignmentsState, IAssignmentDictionary } from "./AssignmentsState";
import { actionTypes } from '../ActionTypes';
import { ReceiveTaxDocumentAction, DeleteTaxDocumentAction,ResetTaxReturnsAction } from '../common/TaxDocumentStore';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { handleResponse } from '../Library';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';

type KnownAction = RequestCompanyAssignmentsPagesAction
    | ReceiveCompanyAssignmentsPagesAction |
    NotificationAction;

type DispatchAction =
    RequestCompanyAssignmentsPagesAction | 
    ReceiveCompanyAssignmentsPagesAction |
	ReceiveTaxDocumentAction |
	ResetTaxReturnsAction |
    DeleteTaxDocumentAction;

export const actionCreators = {
    requestCompanyAssignments: (query: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!getState().companyAssignmentsPages[query]) {
            const fetchTask = fetch(`${API_BASE_URL}api/CompanyAssignments` + query, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(response => response.json() as Promise<{ count: number, documents: ITaxReturn[] }>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_COMPANY_ASSIGNMENTS_PAGES, query: query, assignments: data.documents, totalRowCount: data.count });
                })
                .catch(error => {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });
                    logger.trackError(`requestCompanyAssignments failed for query: ${query}, with error ${error.message}`);
                });

            addTask(fetchTask); // Ensure server-side prerendering waits for this to complete
            dispatch({ type: actionTypes.REQUEST_COMPANY_ASSIGNMENTS_PAGES, query: query });
        }
    }
};

const unloadedState: IAssignmentsState = {
    assignments: [] as ITaxReturn[],
    query: '',
    isLoading: false,
    totalRowCount: 0
} as IAssignmentsState;

export const reducer: Reducer<IAssignmentDictionary> = (state: IAssignmentDictionary = {}, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_COMPANY_ASSIGNMENTS_PAGES:
            if (!state[action.query]) {
                let added = { ...unloadedState } as IAssignmentsState;
                added.isLoading = true;
                added.query = action.query;
                let item = {} as IAssignmentDictionary;
                item[action.query] = added;

                return {
                    ...state,
                    ...item
                };
            }
            break;
        case actionTypes.RECEIVE_COMPANY_ASSIGNMENTS_PAGES:
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (state[action.query]) {
                let changed = state[action.query] as IAssignmentsState;
                changed.isLoading = false;
                changed.assignments = action.assignments;
                changed.totalRowCount = action.totalRowCount;
                let item = {} as IAssignmentDictionary;
                item[action.query] = changed;
                return {
                    ...state,
                    ...item
                };
            }
            break;
        case actionTypes.RECEIVE_TAX_DOCUMENT:
            let newState = {} as IAssignmentDictionary;
            for (var query in state) {
                let i = -1;
                state[query].assignments.forEach((value, index) => {
                    if (value.id === action.id) {
                        i = index;
                        return;
                    }
                });
                if (i !== -1) {
                    let newList = [
                        ...state[query].assignments.slice(0, i),
                        action.taxDocument,
                        ...state[query].assignments.slice(i + 1)];
                    newState[query] = {
                        query: query,
                        assignments: [
                            ...newList
                        ],
                        totalRowCount: state[query].totalRowCount,
                        isLoading: false,
                    };
                }
                else {
                    newState[query] = {
                        query: query,
                        assignments: [
                            ...state[query].assignments
                        ],
                        totalRowCount: state[query].totalRowCount,
                        isLoading: false,                    
                    };
                }
            }
            return newState;

        case actionTypes.DELETE_TAX_DOCUMENT:
        case actionTypes.RESET_TAX_RETURNS:
             //clearing the dictionary on deletion
            return {} as IAssignmentDictionary;       
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }
    return state || { "?": unloadedState };
};
