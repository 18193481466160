import React from  'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { VoucherTabConstants, DefaultVoucherDueDateInfo } from '../../helper/Constants';
import { DueDateType, ExtensionCompanySettings } from 'src/Core/ViewModels/Extension/ExtensionComponentModels';

interface VoucherDueDateProps {
    extCompanySettings: ExtensionCompanySettings;
    handleVoucherDueDateChange: (event: any) => void;
}

const VoucherDueDateSetting: React.FC<VoucherDueDateProps> = ({
    extCompanySettings,
    handleVoucherDueDateChange
}) => {
    return <div className="new-main-content">
            <h3>Default Voucher Due Date <Link to={'#'}
                className="help-icon marL05"
                data-placement="right"
                data-toggle="tooltip"
                title={DefaultVoucherDueDateInfo}>
                <i className="fas fa-question-circle"></i>
            </Link></h3>
            <div className="marL20">
            </div>
            <div className="row marT20">
                <div className="col-sm-2 col-md-2 col-lg-2 padT07">Select Default Voucher Due Date from </div>
                <div className="col-sm-3 col-md-3 col-lg-3">
                    <Select
                        name="form-field-name"
                        value={extCompanySettings.displaySettingsModel.voucherDueDateType == 0 ? DueDateType.SSExtDefault : extCompanySettings.displaySettingsModel.voucherDueDateType}
                        onChange={handleVoucherDueDateChange}
                        options={VoucherTabConstants.voucherDueDateTypes}
                        data-test-auto="52A69016-2B50-4B88-84B4-3E82ED02586A"
                        clearable={false}
                    />

                </div>
            </div>
        </div>
}

export default VoucherDueDateSetting;