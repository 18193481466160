import React from  'react';
import { RBACKeys } from 'src/components/helper/RbacConstants';
import { SignatureFormSelectionType } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { ExtensionCompanySettings } from '../../../Core/ViewModels/Extension/ExtensionComponentModels';
import { CheckBoxComponent } from '../../common/CheckBoxComponent';
import { RadioButtonComponent } from '../../common/RadioButtonComponent';
import { SignatureFormTypeConstant } from '../../helper/Constants';

interface IEsignatureForBusinessReturn {
    companySettings: ExtensionCompanySettings;
    disableAllowPlaceSignOnBusiness: boolean;
    OnChangeSignatureFormSelectionTypeForBusiness(signatureFormSelectionType: SignatureFormSelectionType): void;
    handleAllowPlaceSignOnBusiness(checked: boolean):void;
}
export class EsignatureForBusinessReturns extends React.Component<IEsignatureForBusinessReturn> {
    constructor(props: any) {
        super(props);
    }
    handleEsignWhenAllAllowed = (e: any) => {
        if (e.currentTarget.checked) {
            this.props.OnChangeSignatureFormSelectionTypeForBusiness(SignatureFormSelectionType.ESignWhenAllIncludedReturnsAllowed);
        }
    }
    handleReviewOnly = (e: any) => {
        if (e.currentTarget.checked) {
            this.props.OnChangeSignatureFormSelectionTypeForBusiness(SignatureFormSelectionType.ReviewOnly);
        }
    }
    handleAllowPlaceSignOnBusiness = (e: any) => {
        let checked = e.currentTarget.checked;
        this.props.handleAllowPlaceSignOnBusiness(checked);
    }

    public render() {

        const signatureSelection = this.props.companySettings.signatureSettingsModel ?
            this.props.companySettings.signatureSettingsModel.signatureFormSelectionTypeForBusiness :
            "";

        const businessReturn: any =
            <span>
                Enable E-Signatures for Business & Trust Extensions:
                <b> IMPORTANT NOTE </b> : The IRS currently does not allow the use of
                E-Signatures on entity or trust e-file authorization forms.</span>;

        return <div id="divKnowledgeBasedAuthentication" className="new-main-content">
            <h3>Business & Trust Returns</h3>
            <div className="marL20">
                <div className="marB10">
                    <RadioButtonComponent
                        id={"businessReturn"}
                        text={businessReturn}
                        onChange={this.handleEsignWhenAllAllowed}
                        checked={SignatureFormTypeConstant.ESignWhenAllIncludedReturnsAllowed === signatureSelection}
                        dataTestAuto={"E6FA4253-FFB8-4B4A-83C6-05390E055504"}
                        resourceID={RBACKeys.Settings.settings}
                    />
                </div>
                <div className="marB10">
                    <RadioButtonComponent
                        id={"reviewOnlyBusiness"}
                        text={"Review Extension Only"}
                        onChange={this.handleReviewOnly}
                        checked={SignatureFormTypeConstant.ReviewOnly === signatureSelection}
                        dataTestAuto={"7EBE4B14-EE9B-4B39-9DD8-75F66E67EE70"}
                        resourceID={RBACKeys.Settings.settings}
                    />
                </div>
                <div className="marB10 marL30">
                    <CheckBoxComponent
                        id={"allowPlaceSignOnBusiness"}
                        text={"Allow user to place signature control when applicable."}
                        onChange={this.handleAllowPlaceSignOnBusiness}
                        checked={this.props.companySettings.signatureSettingsModel ?
                            this.props.companySettings.signatureSettingsModel.allowUserPlaceSignOnBusiness : false}
                        datatestAuto={"7EBE4B14-EE9B-4B39-9DD8-75F66E67EE70"}
                        disabled={this.props.disableAllowPlaceSignOnBusiness}
                        resourceId={RBACKeys.Settings.settings}
                    />
                </div>
            </div>
        </div>;
    }
}

export default EsignatureForBusinessReturns;
