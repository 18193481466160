let moment = require('moment');
import React from  'react';
import { BootstrapTable, CustomFilter, CustomSelectProps, SelectFilter, TableHeaderColumn, TextFilter } from 'react-bootstrap-table';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import * as RecycleReturnsState from '../../../store/reports/RecycleReturns/RecycleReturnsState';
import { CheckBoxComponent, CheckBoxSize } from "../../common/CheckBoxComponent";
import { CustomMultiSelect } from '../../common/MultipleSelectComponent';
import { DaysRangeOptionsList, EngagementType, IOfficeLocation, SignatureStatus, engagementType } from '../../common/TaxReturn';
import { DeliveredReturnsTableConstants, ReportFilterConstants } from '../../helper/Constants';
import * as Helper from '../../helper/HelperFunctions';
import { CustomDateFilter } from '../DeliveredReturns/CustomDateFilter';
import { EngagementList, SignatureStatusList, SignatureStatusOptionsList } from '../../../Core/Common/Common';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import * as ReactDom from 'react-dom';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Filters, { IFilters, ReportFilterType } from '../Filters';
import { logClientEvent } from 'src/components/helper/LoggerHelper';
import { SessionStore } from '../../helper/HelperFunctions';
import { LogEventConstants } from 'src/components/helper/LogEventConstants';
const isEqual = require("react-fast-compare");

interface TableData {
    recycleReturns: RecycleReturnsState.RecycleReturnsState,   
    onPageChange: any,
    onSortChange: any,
    onFilterChange: any,
    pageNo: number,   
    loadGrid(): void;
    totalRows: number;
    isLoading: boolean,
    pageSize: number,
    userLocation:IOfficeLocation[],
    onRowSelect: (row: any, isSelected: any, e: any) => void;
    onSelectAll: (isSelected: any, rows: any, e: any) => void;
    selectedRows: number[];
    showBulkSelectionMessage: boolean;
    onBulkSelectionChange: (isEnabled: boolean) => void;
}

export class RecycleReturnsTable extends React.Component<TableData, {}> {

    private filterChanged: boolean = false;
    private customStatusMultiSelect: any;
    private customMultiSelect: any;
    private customTaxYearMultiSelect: any;
    private customMultiOfficeSelect:any;
    private taxYearList: any[] = [];
    public isAppliedFilter: boolean = false;
    pageTitle: any;
    constructor(props:any) {
        super(props);
        this.state = {
            isActive: true,
            isHiddenExportExcel: true
        };
        this.onFilterChange = this.onFilterChange.bind(this);
        this.defaultType = this.defaultType.bind(this);
        this.onLoadSelectedFilter = this.onLoadSelectedFilter.bind(this);

    }

    componentDidMount(): void {
        let receiveFilterName = this.getProxyFilterName(ReportFilterType.RecycleReport);
        if (SessionStore.isExists(receiveFilterName)) {
            setTimeout(this.onSetDefaultExtensionFilter, 1000)
        }
    }

    onSetDefaultExtensionFilter = () => {
        let receiveFilterName = this.getProxyFilterName(ReportFilterType.RecycleReport);
        let defaultFilter = JSON.parse(SessionStore.get(receiveFilterName));
        this.onLoadSelectedFilter(defaultFilter);
    }

    componentWillMount() {
        this.taxYearList = Helper.PrepareTaxYear();
    }
    private getProxyFilterName(filterType:ReportFilterType){
        return ReportFilterType[filterType].toUpperCase() + ReportFilterConstants.DefaultFilterBuilder;
    }
    
    shouldComponentUpdate(nextProps: TableData, nextState: {}) {
        return (
            !isEqual(this.props.recycleReturns.recycleReturnTableModel.documents, nextProps.recycleReturns.recycleReturnTableModel.documents)
            || (this.props.isLoading !== nextProps.isLoading)
            || isEqual(this.props.selectedRows, nextProps.selectedRows)
            || !isEqual(this.props.showBulkSelectionMessage, nextProps.showBulkSelectionMessage)
        );
    }

    renderShowsTotal(start: number, to: number, total: number) {
        return (
            <p>
                Showing {start} to {to} of {total} entries
            </p>
        );
    }

    public headerRefStore: any = {
		clientName: null,
		clientId: null,
		taxReturnType: null,
        taxYear: null,
		partner: null,
        signatureStatus: null,
		deletedByFirstName: null,
        deletedOn:null,
        officeLocation:null
	}

    private setNoContent() {
        if (this.props.isLoading) {
            (this.refs.table as BootstrapTable) && (this.refs.table as BootstrapTable).cleanSelected();
            return (<LoadingOverlay style={{ height: '400px' }}>
                <Loader loading={this.props.isLoading} />
            </LoadingOverlay>)
        } else {
            return DeliveredReturnsTableConstants.OtherMessage.NoReturnsFound
        }
    }

    private onFilterChange(dataField: any) {
        if (!this.filterChanged) {
            this.filterChanged = true;
            this.props.onFilterChange(dataField,ReportFilterType.RecycleReport);
            this.filterChanged = false;
        }
    }

    private getStatusMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options: any = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect 
            onRef={(ref: any) => (this.customStatusMultiSelect = ref)} 
            filterHandler={filterHandler} 
            options={options} 
            placeholder={placeholder}
            />
        );
    }

    private getYearMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options:any = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect 
            onRef={(ref: any) => (this.customTaxYearMultiSelect = ref)} 
            filterHandler={filterHandler} 
            options={options} 
            enableAllFilter={enableAllFilter} 
            placeholder={placeholder} 
            />
        );
    }

    private getMultiOfficeSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customMultiOfficeSelect = ref)} 
            filterHandler={filterHandler} 
            options={options} 
            className={"filter select-filter multi-select-widthauto-office-location"}  
            placeholder={placeholder} 
            optionClassName={"office-location-custom-multiselect-status"}/>
        );
    }

    private CustomDateFilter = (filterHandler: any, customFilterParameters: any) => {
        return (<CustomDateFilter 
            onRef={(ref: any) => (this.customMultiSelect = ref)} 
            filterHandler={filterHandler} 
            customFilterParameters={customFilterParameters} 
            calendarContainer={ReactDom.findDOMNode(this.refs.table)} 
            />);
    }

    private defaultType(cell: any, row: any) {
        return <div title={cell} className="ellipsis">{cell}</div>;
    }

    createCustomCheckbox = (props: CustomSelectProps): any => {
        return (<CheckBoxComponent size={CheckBoxSize.sm}
            id={"recycle-returns-checkbox-" + props.rowIndex}
            indeterminate={props.indeterminate}
            checked={props.checked}
            disabled={props.disabled}
            onChange={(e: any) => props.onChange(e, props.rowIndex)}
            ref={(input: any) => {
                if (input) {
                    input.indeterminate = props.indeterminate;
                }
            }}
            text={""} />);
    }
    
    customTypeTooltip = (cell: any, row: any) => {
        return `${row.engagementType}`;
    }

    

    customDownloadedTooltip = (cell: any, row: any) => {
        return `${(cell > 0 && (row.signatureStatusIdType === SignatureStatus[SignatureStatus.ESigned] || row.signatureStatusIdType === SignatureStatus[SignatureStatus.Uploaded] || row.signatureStatusIdType === SignatureStatus[SignatureStatus.SignedAndESigned])) ? 'Downloaded' : 'To Be Downloaded'}`;
    }

    private showBulkAlertMessage() {
        let documentsInPage = this.props.recycleReturns.recycleReturnTableModel.documents.length;
        if (this.props.totalRows > 20 && this.props.showBulkSelectionMessage)
            return (<div className="col-sm-6 col-lg-6" style={{ position: "absolute", display: "inline-block", right: "204px", zIndex: 10, padding: '0px 0px', marginTop: "-60px" }} >
                <Alert variant="warning" style={{ padding: "6px", width: "fit-content", margin: "auto" }}>
                    <span
                        className="fa fa-exclamation-triangle"
                        style={{ marginRight: '5px', paddingLeft: "5px" }}>
                    </span> All {documentsInPage} records on this page are selected. To select the remaining {this.props.totalRows - documentsInPage} filtered records,
                    <Link to={"#"} onClick={() => this.props.onBulkSelectionChange(true)}> click here</Link>
                </Alert>
            </div>);
    }
    
    private signatureStatusIdType = (cell: any, row: any) => {

        let status: any = row.signatureStatusIdType;
        let signatureType: "None" | "ESigned" | "ManuallySigned" | "Uploaded" | "AwaitingESign" | "AwaitingUpload" | "Locked" |
            "MailOrFax" | "SignedAndESigned" | "AutoParsed" | "Processing" | "Delivering" | "Delivered" |
            "DeliveryFailed" | "UploadInProgress" = (typeof status == "number") ? SignatureStatus[status] : status;

        return <span>{SignatureStatusList[signatureType].toUpperCase()}</span>;
    }

    public onLoadSelectedFilter(filter?:IFilters){
        this.isAppliedFilter=true;
        this.onClearFilter();
        if (filter) {
            this.filterChanged = true;
            for (let field of Object.keys(filter.fields)) {
                switch (field) {
                    case 'clientName':
						this.headerRefStore["clientName"]?.applyFilter(filter.fields[field]);
						break;
					case 'clientId':
						this.headerRefStore["clientId"]?.applyFilter(filter.fields[field]);
						break;
					case 'partner':
						this.headerRefStore["partner"]?.applyFilter(filter.fields[field]);
						break;
					case 'deliveredByFirstName':
						this.headerRefStore["deliveredByFirstName"]?.applyFilter(filter.fields[field]);
						break;
                    case 'signatureStatus':
                        this.customStatusMultiSelect?.applyFilter((filter.fields[field]));
                        break;
                    case 'deliveredOn':
                        let deliveredDateFilter: any = filter.fields[field];
                        if (deliveredDateFilter) {
							this.headerRefStore["deliveredOn"]?.applyFilter(filter.fields[field]);
                        }
                        break;
                    case 'taxReturnType':
						this.headerRefStore["taxReturnType"]?.applyFilter(filter.fields[field]);
                        break;
                    case 'deletedByFirstName':
						this.headerRefStore["deletedByFirstName"]?.applyFilter(filter.fields[field]);
                        break;
                    case 'deletedOn':
						this.customMultiSelect?.applyFilter(filter.fields[field]);
                        break;
                    case 'taxYear':
                        this.customTaxYearMultiSelect.applyFilter((filter.fields[field]));
                        break;
                    case 'officeLocation':
                        this.customMultiOfficeSelect?.applyFilter(filter.fields[field]);
                        break;
                }
            }

            this.filterChanged = false;
            this.isAppliedFilter = false;
            this.onFilterChange(filter.fields);
            logClientEvent(`${LogEventConstants.Common.Filter.ApplySpecificFilter} ${this.pageTitle}`, { count: 1, page: filter?.filterType });
            //(this.refs.table as BootstrapTable).handleSearch(filter.searchText);
        }

        
    }

    public onClearFilter() {
        this.filterChanged = true;
        for (var key of Object.keys(this.headerRefStore)) {
			this.headerRefStore[key] !== null ? this.headerRefStore[key]?.cleanFiltered() : "";
		}
        this.customStatusMultiSelect?.cleanFiltered();
        this.filterChanged = false;
        this.customTaxYearMultiSelect?.cleanFiltered();
    }

    public render() {

        const options:any = {
            onSortChange: this.props.onSortChange,
            onPageChange: this.props.onPageChange,
            sizePerPage: this.props.pageSize,
            page: this.props.pageNo,
            paginationShowsTotal: this.renderShowsTotal,
            onRowDoubleClick: function (row: any) {
            },
            onFilterChange: this.onFilterChange,
            noDataText: this.setNoContent(),
            nextPage: <span className="fa fa-angle-right" />,
            prePage: <span className="fa fa-angle-left" />,
            firstPage: <span className="fa fa-angle-double-left" />,
            lastPage: <span className="fa fa-angle-double-right" />
        };

        const pagination = true;
        let selected: number[] = [];
        if (this.props.selectedRows.length > 0) {
            for (var i in this.props.selectedRows) {
                let rowIndex = this.props.selectedRows[i];
                selected.push(((this.props.pageNo - 1) * this.props.pageSize) + rowIndex);
            }
        }

        let selectRowProp: any = {
            mode: 'checkbox',
            clickToSelect: true,
            onSelect: this.props.onRowSelect,
            onSelectAll: this.props.onSelectAll,
            selected: selected,
            className: 'row-selected',
        };
        
        const columns = [
            {
                header: '',
                key: 'index',
                isKey: true,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: true,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'index', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Name',
                key: 'clientName',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'Name', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Client ID',
                key: 'clientId',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'Client ID', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Type',
                key: 'taxReturnType',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: this.customTypeTooltip,
                isHidden: false,
                width: 'auto',
                filter: { type: 'SelectFilter', placeholder: 'Select Type...', options: EngagementList } as SelectFilter
            },
            {
                header: 'Tax Year',
                key: 'taxYear',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                filter: {
                    type: 'CustomFilter', getElement: this.getYearMultiSelectDropDown,
                    customFilterParameters: { options: this.taxYearList, enableAllFilter: true, placeholder: 'Select Year...' } as any
                } as CustomFilter
            },
            {
                header: 'ERO / Signer',
                key: 'partner',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'ERO / Signer', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Office Location',
                key: 'officeLocation',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                width: '130px',
                filter: { type: 'CustomFilter', getElement: this.getMultiOfficeSelectDropDown, customFilterParameters: {
                    options: [...this.props.userLocation.map(location=>{return {"label":location.locationName,"value":location.locationId}}),{"label":"Blanks",value:"-100"}], 
                    enableAllFilter: false, 
                    placeholder: 'Select Office Location...'
                } as any 
                }as CustomFilter
            },
            {
                header: 'Sent By',
                key: 'deliveredByFirstName',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'Sent By', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Delivered Date',
                key: 'deliveredOn',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                width: 'auto',
                filter: { type: 'SelectFilter', placeholder: 'Select Delivered Date Limit', options: DaysRangeOptionsList } as SelectFilter
            },
            {
                header: 'Status',
                key: 'signatureStatus', // String-based value accessors!
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                width: 'auto',
                Placeholder: 'Status',
                filter: {
                    type: 'CustomFilter', getElement: this.getStatusMultiSelectDropDown,
                    customFilterParameters: { options: SignatureStatusOptionsList, placeholder: 'Select Status...' } as any
                } as CustomFilter
            },
            {
                header: 'Deleted By',
                key: 'deletedByFirstName',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'Sent By', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Deletion Date',
                key: 'deletedOn',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                width: 'auto',
                filter: {
                    type: 'CustomFilter',
                    getElement: this.CustomDateFilter

                } as CustomFilter
            },
        ];


        const data = this.props.recycleReturns.recycleReturnTableModel.documents.map((model, index) => {            
            return {
                clientName: model.clientName,
                clientId: model.clientId,
                taxReturnType: engagementType(EngagementType[model.taxReturnType]),
                partner: model.eroSignerFirstName ? model.eroSignerFirstName + " " + model.eroSignerLastName : "",
                deliveredByFirstName: model.deliveredByFirstName ? model.deliveredByFirstName + " " + model.deliveredByLastName : "",
                deliveredOn: moment(model.deliveredOn).format('MM/DD/YYYY'),
                signatureStatus: SignatureStatusList[model.signatureStatus],
                deletedByFirstName: model.deletedByFirstName ? model.deletedByFirstName + " " + model.deletedByLastName : "",
                deletedOn: moment(model.deletedOn).format('MM/DD/YYYY'),
                index: ((this.props.pageNo - 1) * this.props.pageSize) + index,
                documentId: model.documentId,
                taxYear: model.taxYear,
                rowIndex: index,
                id: model.documentId,
                officeLocation:model.locationName
            }
        });

        return <div className="pos-relative">
            {this.showBulkAlertMessage()}
            <div data-test-auto="B510184C-5041-464B-9904-107F0B3C6F60">
                <BootstrapTable
                    ref='table'
                    data={data}
                    remote={true}
                    fetchInfo={{ dataTotalSize: this.props.totalRows }}
                    options={options}
                    striped
                    hover={true}
                    pagination={pagination}
                    selectRow={selectRowProp}>
                    {columns.map((value, index) => {
                        return <TableHeaderColumn
                            key={index}
                            ref={(e)=>this.headerRefStore[value.key]=e}
                            isKey={value.isKey}
                            dataField={value.key}
                            hidden={value.isHidden}
                            columnClassName={value.columnClassName}
                            columnTitle={value.toolTip}
                            filter={value.filter}
                            dataSort={value.dataSort}
                            width={value.width}>{
                                   <span title={value.header} className='table-text-sort' style={{ width: "80%" }}>{value.header}</span>
                            }
                        </TableHeaderColumn>;
                    })}
                </BootstrapTable>
            </div>
        </div >
    }
}

export default RecycleReturnsTable