import React from 'react';

interface IPasswordMaskProps {
    onChange(event: any): void;
}

interface PasswordMaskState {
    hidden: boolean
    value: string
}

export class PasswordMask extends React.Component<IPasswordMaskProps, PasswordMaskState> {
    constructor(props: IPasswordMaskProps) {
        super(props);
        this.state = { value: "", hidden: true }
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.toggleShow = this.toggleShow.bind(this);
    }

    componentDidMount() {
        if (this.props) {
            this.setState({ value: "" });
        }
    }

    handlePasswordChange(event: any) {
        this.setState({ value: event.target.value });
        this.props.onChange(event);
    }

    toggleShow() {
        this.setState({ hidden: !this.state.hidden });
    }

    public render() {
        return (
            <div className="inputinput-mask">
                <input data-testid="password-mask" type={this.state.hidden ? "password" : "text"} autoComplete="new-password" value={this.state.value}
                    onChange={this.handlePasswordChange} className="form-control pos-relative padT05 pull-left" data-lpignore="true" />
                <i data-testid="eye-icon" className={this.state.hidden ? "fas fa-eye-slash pos-absolute pull-right marT10 marL-20" : "fas fa-eye pos-absolute  pull-right marT10 marL-20"} onClick={this.toggleShow}></i>
            </div>
        )
    }
}
export default PasswordMask;