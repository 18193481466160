import React, { useState } from 'react';
import { IModalProps, IPasswordState, PasswordOptions } from './ProfileObjects';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as Bs from 'react-bootstrap';
import PasswordMask from '../../common/PasswordMask';
import { ChangeSecurityConstants, ValidationContants } from 'src/components/helper/Constants';
import { validatePasswordBasedOnPolicy } from 'src/components/helper/Validations';
import { actionCreators as profileActionCreators } from '../../../store/userManagement/UserProfileStore';
import { VenusNotifier } from 'src/components/helper/VenusNotifier';
import * as bootbox from 'bootbox';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { ApplicationState } from 'src/store';
import { WithLogout, WithLogoutProps } from 'src/oidcClient/withLogout';


interface IMyPasswordState {
    alert: string;
    message: string;
    showAlert: boolean;
    saving: boolean;
}

interface IChangePasswordModal extends IModalProps, WithLogoutProps {
    hideProfileModal: () => void
}

const ChangePassword: React.FC<IChangePasswordModal> = ({ showState, onHide, hideProfileModal, onLogout }) => {
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const [myPasswordState, setMyPassWordState] = useState<IMyPasswordState>({
        alert: 'warning',
        message: '',
        showAlert: false,
        saving: false,
    })

    const dispatch = useDispatch();

    const passwordPolicySetting = useSelector((state: ApplicationState) => state.passwordPolicyStore.passwordPolicySetting)

    const onChangeOldPassword = (e) => {
        setOldPassword(e.target.value)
    }

    const onChangeNewPassword = (e) => {
        setNewPassword(e.target.value)
    }

    const onChangeConfirmPassword = (e) => {
        setConfirmPassword(e.target.value)
    }

    const closeModal = () => {
        setMyPassWordState({
            ...myPasswordState,
            message: "",
            alert: "",
            showAlert: false,
        });
        onHide();
    }

    const submitChanges = () => {

        if (!oldPassword || oldPassword.trim() === "") {
            setMyPassWordState({
                ...myPasswordState,
                message: ValidationContants.OldPasswordWarning,
                alert: "warning",
                showAlert: true,
            });
            return false;
        }

        if (!newPassword || newPassword.trim() === "") {
            setMyPassWordState({
                ...myPasswordState,
                message: ValidationContants.NewPasswordWarning,
                alert: "warning",
                showAlert: true,
            });
            return false;
        }

        if (!confirmPassword || confirmPassword.trim() === "") {
            setMyPassWordState({
                ...myPasswordState,
                message: ValidationContants.ConfirmPasswordWarning,
                alert: "warning",
                showAlert: true,
            });
            return false;
        }

        const validationResult = validatePasswordBasedOnPolicy(
            newPassword,
            passwordPolicySetting.passwordSettings
        );
        if (validationResult.isError) {
            setMyPassWordState({
                ...myPasswordState,
                message: validationResult.errorDescription,
                alert: "warning",
                showAlert: true,
            });
            return false;
        }

        if (newPassword.trim() === confirmPassword.trim()) {

            bootbox.confirm({
                message: ChangeSecurityConstants.SecurityChangeLogoutWarning,
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: 'btn-white btn-default'
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> Confirm',
                        className: 'btn-info'
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        handlePasswordConfirmation()
                        return true;
                    }
                }

            });
        } else {
            setMyPassWordState({
                ...myPasswordState,
                message: ValidationContants.PasswordNotMatchedWarning,
                alert: "warning",
                showAlert: true,
            });
            return false;
        }
    }

    const handlePasswordConfirmation = () => {
        setIsLoading(true)
        setMyPassWordState({
            ...myPasswordState,
            alert: "",
            message: "",
            showAlert: false,
            saving: true,
        });

        const passwordstate: IPasswordState = { password: oldPassword, newPassword: newPassword };
        dispatch(
            profileActionCreators.savePassword(passwordstate, (response: any, error: any) => {
                setMyPassWordState({ ...myPasswordState, saving: false });
                setIsLoading(false)
                if (error) {
                    VenusNotifier.Error(error, "");
                } else {
                    if (response === true) {
                        VenusNotifier.Success(ChangeSecurityConstants.SecurityUpdateSuccess, "");
                        setMyPassWordState({
                            ...myPasswordState,
                            alert: "",
                            message: "",
                            showAlert: false,
                        });
                        setOldPassword("")
                        setNewPassword("")
                        setConfirmPassword("")
                        onHide();
                        hideProfileModal();
                        onLogout();
                    } else {
                        setMyPassWordState({
                            ...myPasswordState,
                            message: response,
                            alert: "warning",
                            showAlert: true,
                        });
                    }
                }
            })
        );
    };

    const getPasswordOptionLabel = (option: any): string => {
        switch (option) {
            case PasswordOptions.None:
                return ""
            case PasswordOptions.UpperCase:
                return "Upper Case Letter"
            case PasswordOptions.LowerCase:
                return "Lower Case Letter"
            case PasswordOptions.Numbers:
                return "Numbers"
            case PasswordOptions.SpecialCharacters:
                return "Special Character"
            default:
                return option;
        }
    }

    const verifyPasswordOptions = () => {
        const items: any[] = [];
        const selectedOptions = Object.keys(PasswordOptions)
            .filter(key => !isNaN(Number(key)))
            .map(key => Number(key))
            .filter(option => option > 0 && (option & passwordPolicySetting.passwordSettings.passwordOptions) === option);

        selectedOptions.forEach((option) => {

            const label = getPasswordOptionLabel(option);
            items.push(<li className="padB5"> {label}</li>);
        });

        return (
            <div>
                <div>
                    <ul><li> {items} </li></ul >
                </div>

            </div>
        );
    }
    return <Modal className="overlayPopup change-password-modal" show={showState} onHide={closeModal}>
        <Modal.Header closeButton data-test-auto="7A232988-989E-4DEB-9B10-6EF9BDFBAA37">
            <Modal.Title className='custom-modal-header'>
                <i className='fas fa-user-shield padR05'></i>
                Change Password
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className={myPasswordState.showAlert ?
            'change-password-alert-container' : " "}>
            {myPasswordState.showAlert ?
                <Bs.Alert variant={myPasswordState.alert} className='password-alert'><i className='fas fa-exclamation-triangle' style={{ marginRight: '5px' }}>
                </i>{myPasswordState.message}</Bs.Alert> : " "}
            <LoadingOverlay style={{ height: '100%' }}>
                <div className="row marB10">
                    <div className="col-sm-3 col-md-3 col-xl-3">
                        <div className="marB10 padT07">Old Password:</div>
                    </div>
                    <div className="col-sm-9 col-md-9 col-xl-9">
                        <div className="marB10">
                            <PasswordMask
                                onChange={(e) => { onChangeOldPassword(e) }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row  marB10">
                    <div className="col-sm-3 col-md-3 col-xl-3">
                        <div className="marB10 padT07">New Password:</div>
                    </div>
                    <div className="col-sm-9 col-md-9 col-xl-9">
                        <div className="marB10">
                            <PasswordMask
                                onChange={(e) => { onChangeNewPassword(e) }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row  marB10">
                    <div className="col-sm-3 col-md-3 col-xl-3">
                        <div className="marB10 padT07">Confirm Password:</div>
                    </div>
                    <div className="col-sm-9 col-md-9 col-xl-9">
                        <div className="marB10">
                            <PasswordMask
                                onChange={(e) => { onChangeConfirmPassword(e) }}
                            />
                        </div>
                    </div>
                </div>
                <Loader
                    classNamePrefix="sm"
                    text=""
                    loading={isLoading} />
            </LoadingOverlay>
        </Modal.Body>

        <hr />
        <div style={{ padding: "10px" }}>
            <div className="row marL0 marR0" >
                <div className="col-sm-5 col-md-5 col-xl-5">
                    <div className="password-req-lable " style={{ textDecoration: "none" }}>Password Requirements:</div>
                </div>
            </div>
            <div className="line-space">

                1. Minimum Number of characters: <b> {passwordPolicySetting.passwordSettings.length}</b>
            </div>
            {passwordPolicySetting.passwordSettings.passwordOptions != PasswordOptions.None ?
                <div>
                    <div className="line-space">
                        2. Required character types
                    </div>
                    <div className="line-space" style={{ paddingLeft: "45px" }}>
                        <b> Your password must include one of each of </b>
                    </div>
                    <div style={{ paddingLeft: "45px" }}>
                        <b> the below required character types </b>
                    </div>
                    <div className="line-space" style={{ paddingLeft: "90px" }}>
                        {verifyPasswordOptions()}
                    </div>
                    <br />
                    {(passwordPolicySetting.passwordSettings.passwordOptions & PasswordOptions.SpecialCharacters) === PasswordOptions.SpecialCharacters ?
                        <div className="tooltip list-special-char font-size-14 ">
                            (View list of available special characters)
                            <span className="tooltiptext"> ~ ! @ # $ % ^ & * _  </span>
                        </div>
                        : ""}
                </div> : ""}
        </div>
        <Modal.Footer>
            <Bs.Button
                className="btn-white"
                variant='default'
                onClick={closeModal}
                data-test-auto="4A0C3490-369E-49E9-A36A-3139973D5A81">
                <i className='fas fa-times'></i>Cancel
            </Bs.Button>
            <Bs.Button
                variant='info'
                onClick={submitChanges}
                data-test-auto="3CDE45EA-64A3-49F4-B6F4-A122088BA80B">
                <i className='fas fa-save'></i>  Save
            </Bs.Button>
        </Modal.Footer>
    </Modal>
}

export default WithLogout(ChangePassword);
