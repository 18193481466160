import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { ILoginHistoryResponse, IUserLoginHistory } from '../../components/navigation/profile/ProfileObjects';
import { actionTypes } from '../ActionTypes';
import { handleResponse } from '../Library';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { SUITE_API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';
import * as Helper from '../../components/helper/Constants';

export interface IUserLoginHistoryState {
    loginHistoryCount: number;
    loginHistories: IUserLoginHistory[];
    isLoginHistoryLoaded: boolean;
    loginHistoryPageNo: number;
    deviceId: string;
    lastLoginPrompt:boolean;
    lastLoginPromptLoaded:boolean;
}



interface ReceiveUserLoginHistoryAction {
    type: actionTypes.RECEIVE_USER_LOGIN_HISTORY;
    userLoginHistory: IUserLoginHistory[];
    loginHistoryCount: number;
    loginHistoryPageNo: number;
    deviceId: string;
}
interface RequestUserLoginHistoryAction {
    type: actionTypes.REQUEST_USER_LOGIN_HISTORY;
}

interface RequestUserLastLoginPromptAction{
    type:actionTypes.REQUEST_USER_LAST_LOGIN_PROMPT;
}

interface ReceiveUserLastLoginPromptAction{
    type:actionTypes.RECEIVE_USER_LAST_LOGIN_PROMPT;
    lastLoginPrompt:boolean
}




type KnownAction = DispatchActions| NotificationAction;
type DispatchActions = RequestUserLoginHistoryAction | ReceiveUserLoginHistoryAction|ReceiveUserLastLoginPromptAction|RequestUserLastLoginPromptAction;

export const actionCreators = {

    requestUserLoginHistory: (pageNo: number = 1, pageSize: number = 15): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({type:actionTypes.REQUEST_USER_LOGIN_HISTORY})
        const fetchTask = fetch(`${SUITE_API_BASE_URL}api/login-history/${pageNo}/${pageSize}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then((response: ILoginHistoryResponse) => {
                const data = response
                dispatch({ type: actionTypes.RECEIVE_USER_LOGIN_HISTORY, userLoginHistory: data?.loginHistories?.items || [], loginHistoryCount: data?.loginHistories?.count || 0, loginHistoryPageNo: pageNo, deviceId: data?.deviceId || "" });
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });
                logger.trackError(`requestUserLoginHistory failed with error ${error.message}`);
            });
        addTask(fetchTask);
    },
    requestUserLastLoginPrompt:():AppThunkAction<KnownAction>=>(dispatch)=>{
        dispatch(actionCreators.requestUserLastLoginPrompt)
        const fetchTask = fetch(`${SUITE_API_BASE_URL}api/user-management/last-login-user-settings`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then((response: boolean) => {
                const data = response
                dispatch({ type: actionTypes.RECEIVE_USER_LAST_LOGIN_PROMPT, lastLoginPrompt:data});
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });
                logger.trackError(`requestUserLoginHistory failed with error ${error.message}`);
            });
        addTask(fetchTask);
    },
    saveUserLastLoginPrompt:(lastLoginPrompt:boolean):AppThunkAction<KnownAction>=>(dispatch)=>{
        dispatch({type:actionTypes.REQUEST_USER_LAST_LOGIN_PROMPT})
        const fetchTask = fetch(SUITE_API_BASE_URL + `api/user-management/last-login-user-settings/${lastLoginPrompt}`,{
            method: 'PUT',
            credentials: 'include'
        })
        .then(() => {
            dispatch({type:actionTypes.RECEIVE_USER_LAST_LOGIN_PROMPT, lastLoginPrompt:lastLoginPrompt})

            dispatch({ type: actionTypes.NOTIFICATION, statusMessage: Helper.UserSettings.saveUserSettingsSuccess, statusType: StatusType.Success });
        })
        .catch(() => {
            dispatch({ type: actionTypes.NOTIFICATION, statusMessage: Helper.UserSettings.saveUserSettingsError, statusType: StatusType.Error });
        })
        addTask(fetchTask);
    }
}

const initialLoginHistoryState: IUserLoginHistoryState =
{
    loginHistoryCount: 0,
    loginHistories: [] as IUserLoginHistory[],
    isLoginHistoryLoaded: false,
    loginHistoryPageNo: 0,
    deviceId: "",
    lastLoginPrompt:false,
    lastLoginPromptLoaded:false
}

export const reducer: Reducer<IUserLoginHistoryState> = (state: IUserLoginHistoryState = initialLoginHistoryState, incomingAction: Action) => {
    const action = incomingAction as DispatchActions;
    switch (action.type) {
        case actionTypes.RECEIVE_USER_LOGIN_HISTORY:
            let loginHistory = { ...state };
            loginHistory.loginHistories = [...action.userLoginHistory];
            loginHistory.loginHistoryCount = action.loginHistoryCount;
            loginHistory.loginHistoryPageNo = action.loginHistoryPageNo;
            loginHistory.isLoginHistoryLoaded = true;
            loginHistory.deviceId = action.deviceId;
            return loginHistory;
        case actionTypes.REQUEST_USER_LOGIN_HISTORY:
            let initailLoginHistory = { ...state };
            initailLoginHistory.isLoginHistoryLoaded =false;
            return initailLoginHistory;
        case actionTypes.RECEIVE_USER_LAST_LOGIN_PROMPT:
            let logins={...state};
            logins.lastLoginPrompt=action.lastLoginPrompt;
            logins.lastLoginPromptLoaded=true;
            return logins
        default:
            const exhaustiveCheck: any = action;
    }
    return state || initialLoginHistoryState;
};