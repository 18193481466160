import React, { useState } from 'react'
import { IDragAndDropData } from './DragAndDrop.model';
import Source from './Source/Source';
import Destination from './Destination/Destination';
import ResetIcon from 'src/components/svg/ResetIcon';

interface DragAndDropProps {
    sourceData: IDragAndDropData[];
    setSourceData: (sourceData: IDragAndDropData[]) => void;
    destinationData: IDragAndDropData[];
    setDestinationData: (destinationData: IDragAndDropData[]) => void;
    sourceDataInitial: IDragAndDropData[];
    destinationDataInitial: IDragAndDropData[];
    sourceHeading: string;
    sourceHeadingInfoText: string;
    destinationHeading: string;
    destinationHeadingInfoText: string;
    showResetButton?: boolean;
    sourceWidth: string;
    sourceHeight: string;
    destinationWidth: string;
    destinationHeight: string;
    disableDragAndDrop?: boolean;
    dndUniqueId?: string;
    disableDestinationItems?: number[];
}


const DragAndDrop = ({
    sourceData,
    setSourceData,
    destinationData,
    setDestinationData,
    sourceDataInitial,
    destinationDataInitial,
    sourceHeading,
    sourceHeadingInfoText,
    destinationHeading,
    destinationHeadingInfoText,
    showResetButton,
    sourceWidth,
    sourceHeight,
    destinationWidth,
    destinationHeight,
    disableDragAndDrop,
    dndUniqueId = "dndID",
    disableDestinationItems = []
}:DragAndDropProps) => {

    const [selectedList, setSelectedList] = useState<IDragAndDropData[]>([]);

    const handleReset = ()=>{
        setSourceData(sourceDataInitial)
        setDestinationData(destinationDataInitial)
    }    

    const handleDropChange = (
        destinationList:IDragAndDropData[],
        sourceList:IDragAndDropData[], 
    ) =>{
        const uniqueDestinationList=destinationList.filter((each:any,index:any)=>
            destinationList.findIndex((u:any)=>each.value===u.value)===index
        );
        const uniqueSourceList = sourceList.filter(
            (each: any, index: any) => sourceList.findIndex((u: any) => each.value === u.value) === index
        );
        setDestinationData(uniqueDestinationList)
        setSourceData(uniqueSourceList)
        setSelectedList([]);
    }

    

  return (
        <section className='drag-and-drop-container'>
            <Source
                destinationData={destinationData}
                sourceData={sourceData}
                handleDropChange={handleDropChange}
                sourceHeading={sourceHeading}
                sourceHeadingInfoText={sourceHeadingInfoText}
                sourceWidth={sourceWidth}
                sourceHeight={sourceHeight}
                dndUniqueId={dndUniqueId}
                selectedList={selectedList}
                setSelectedList={setSelectedList}
                disableDragAndDrop={disableDragAndDrop}
            />
            <Destination
                destinationData={destinationData}
                sourceData={sourceData}
                handleDropChange={handleDropChange}
                destinationHeading={destinationHeading}
                destinationHeadingInfoText={destinationHeadingInfoText}
                destinationWidth={destinationWidth}
                destinationHeight={destinationHeight}
                dndUniqueId={dndUniqueId}
                selectedList={selectedList}
                setSelectedList={setSelectedList}
                disableDragAndDrop={disableDragAndDrop}
                disableDestinationItems={disableDestinationItems}

            />
        {showResetButton && (
            <button className='reset-button' onClick={handleReset}>
                <span className='reset-icon'>
                    <ResetIcon/>
                </span>
                <span className="reset-text">Reset</span>
            </button>

        )}
        </section>
    )
}

export default DragAndDrop