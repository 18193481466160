import React from  'react';
import { Modal, Form, Button, Row, Col, Alert, Tab, Table, NavLink } from 'react-bootstrap';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { FaFileSignature, FaDownload } from 'react-icons/fa';
import { GetFormatedDateTime, getDocumentEntityorIndividualName, displayFileName, formatFileName } from '../helper/HelperFunctions';
import { EngagementType, IDownloadableDocuments, ITaxReturn, SignatureType } from '../common/TaxReturn';
import { IDocumentData } from '../common/ProcessReturnModal/AdditionalEsignDocuments/AdditionalEsignDocument';
import * as HelperFunction from '../helper/HelperFunctions'

interface IDownloadEfileFormProps {

    downloadAdditionalEsignDocuments: (
        documentGuid: string,
        documentId: number,
        taxYear: number,
        clientId: string,
        additionalEsignDocuments: IDocumentData[],
        isSigned?: boolean) => void;

    taxReturn: ITaxReturn;

    show: boolean;

    onCancel(event: any): void;

    downloadIndividual(
        documentGuid: string,
        documentId: number,
        taxYear: number,
        fileName: string,
        groupId: number,
        signedDocumentId: number,
        isDownloadReturn: boolean,
        clientId: string,
        isEfileForm: boolean): void;

    downloadAll(
        documentGuid: string,
        documentId: number,
        taxYear: number,
        isEfileForm: boolean): void;
}

export class DownloadEfileForm extends React.Component<IDownloadEfileFormProps, {}> {

    public render() {
        const { taxReturn } = this.props;
        const eFileFound = taxReturn !== undefined && taxReturn.downloadableEfileForms !== undefined;
        const additionalEsignDocumentFound = taxReturn !== undefined &&
            taxReturn.additionalEsignDocuments &&
            taxReturn.additionalEsignDocuments.length > 0 ? true : false;
        const isManualSign = taxReturn !== undefined &&
            taxReturn.documentSettings !== undefined &&
            taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType == SignatureType.ManualSign;
        return (<Modal show={this.props.show} onHide={this.props.onCancel} className="downloadefile-modal">
            <Modal.Header closeButton onClick={this.props.onCancel} data-test-auto="8A258655-8C8D-43FC-8685-0E4793776C2D">
                <Modal.Title>
                    <FaFileSignature className='modalIcon' />
                    Uploaded Documents
                </Modal.Title>
            </Modal.Header>
            <Form>
                <Modal.Body>
                    <LoadingOverlay style={{ minHeight: 100 }}>
                        {
                            eFileFound ? <div style={{ marginTop: 10 }}>
                                <table style={{ fontFamily: 'arial, sans-serif', borderCollapse: 'collapse', width: '100%' }}>
                                    <tr><td className="new-main-content">{isManualSign ? 'Signed Forms' : 'E-sign Forms'}</td></tr>
                                    {
                                        taxReturn.downloadableEfileForms.map((efile, index) => {
                                            const formattedName = this.getFormattedFileNames(efile.fileName);
                                            return (<tr style={{ backgroundColor: '#dddddd' }}>
                                                <td style={{
                                                    border: '1px solid #dddddd', textAlign: 'left', padding: '8px'
                                                }}
                                                    width="310px" className="word-Visible" title={formattedName} >
                                                    {displayFileName(formattedName, 40, 40)}
                                                </td>
                                                <td style={{
                                                    border: '1px solid #dddddd', textAlign: 'left', padding: '8px'
                                                }} >{GetFormatedDateTime(efile.createdOn)}
                                                </td>
                                                <td style={{
                                                    border: '1px solid #dddddd', textAlign: 'left', padding: '8px'
                                                }} >
                                                    <NavLink href='javascript:'
                                                        onClick={() => {
                                                            this.props.downloadIndividual(
                                                                efile.documentGuid,
                                                                efile.documentId,
                                                                efile.taxYear,
                                                                efile.fileName,
                                                                efile.groupId,
                                                                efile.signedDocumentId,
                                                                false,
                                                                taxReturn.clientId,
                                                                true)
                                                        }} data-test-auto="18C06943-6C1E-4EBF-9CEB-34840CB572D7">
                                                        <FaDownload className="marR05" /><span>Download</span>
                                                    </NavLink>
                                                </td>
                                            </tr>)
                                        })}
                                </table>
                            </div> : (taxReturn !== undefined) &&
                            (taxReturn.downloadableEfileForms !== undefined) && !isManualSign &&
                            <Alert variant='warning' >
                                <i className='fas fa-exclamation-triangle' style={{ marginRight: '5px', color: '#666' }}>
                                </i>No e-File forms found!
                                </Alert>
                        }
                        {
                            additionalEsignDocumentFound && !isManualSign &&
                            <div style={{ marginTop: 10 }}>
                                <table style={{ fontFamily: 'arial, sans-serif', borderCollapse: 'collapse', width: '100%' }}>
                                    <tr><td className="new-main-content">Additional E-signed Documents</td></tr>
                                    {taxReturn.additionalEsignDocuments.map((additionalDocument, index) => {
                                        let formattedName = this.getAdditionalFormattedFileName();
                                        return (<tr style={{ backgroundColor: '#dddddd' }}>
                                            <td style={{
                                                border: '1px solid #dddddd', textAlign: 'left', padding: '8px'
                                            }} width="310px" className="word-Visible" title={formattedName} >
                                                {displayFileName(formattedName, 40, 40)}
                                            </td>
                                            <td style={{
                                                border: '1px solid #dddddd', textAlign: 'left', padding: '8px'
                                            }} >
                                                {GetFormatedDateTime(additionalDocument.uploadedOn)}
                                            </td>
                                            <td style={{
                                                border: '1px solid #dddddd', textAlign: 'left', padding: '8px'
                                            }} >

                                                <NavLink href='javascript:'
                                                    onClick={
                                                        () => {
                                                            this.props.downloadAdditionalEsignDocuments(
                                                                taxReturn.documentGuid,
                                                                taxReturn.id,
                                                                taxReturn.taxYear,
                                                                taxReturn.clientId,
                                                                [additionalDocument] as IDocumentData[],
                                                                true)
                                                        }} data-test-auto="18C06943-6C1E-4EBF-9CEB-34840CB572D7">
                                                    <FaDownload className="marR05" /><span>Download</span>
                                                </NavLink>
                                            </td>
                                        </tr>)
                                    })}
                                </table>
                            </div>
                        }
                        <Loader
                            loading={(taxReturn === undefined) || (taxReturn.downloadableEfileForms === undefined)} />
                    </LoadingOverlay>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onCancel}
                        variant="default"
                        className="btn btn-white"
                        data-test-auto="BC3C6FF2-75C2-45AD-8552-8915E4C8EDF0">
                        <i className='fas fa-times'></i>Cancel
                    </Button>
                    <Button onClick={() => {
                        this.props.downloadAll(
                            this.props.taxReturn.documentGuid,
                            this.props.taxReturn.id,
                            this.props.taxReturn.taxYear,
                            true);
                    }}
                        variant="info"
                        disabled={!eFileFound}
                        data-test-auto="BC7DCC23-8799-4DD0-BDC6-30597C7804F1">
                        <i className='fas fa-download'></i>Download All
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>)
    }

    private getFormattedFileNames(value: any) {
        const { taxReturn } = this.props;
        let name = getDocumentEntityorIndividualName(taxReturn);
        if (value == "Signature_Audit_Report.pdf") {
            let fileName = taxReturn.clientId + "_" + name + "_" + taxReturn.taxYear + "_" +
            HelperFunction.getEngagementType(taxReturn.engagementType) + "_Extensions_" + "Signature Audit Report.pdf";
            return formatFileName(fileName);
        } else {
            let fileName = taxReturn.clientId + "_" + name + "_" + taxReturn.taxYear + "_" +
            HelperFunction.getEngagementType(taxReturn.engagementType) + "_" + "Extensions_Signed Filing Forms.pdf";
            return formatFileName(fileName);
        }
    }

    private getAdditionalFormattedFileName() {
        const { taxReturn } = this.props;
        let name = getDocumentEntityorIndividualName(taxReturn);
        let fileName = taxReturn.clientId + "_" + name + "_" + taxReturn.taxYear + "_" +
        HelperFunction.getEngagementType(taxReturn.engagementType) + "_Extensions_Other Signed Documents.pdf";
        return formatFileName(fileName);
    }

}