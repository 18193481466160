import React from  'react';
import { Button } from 'react-bootstrap';
import { UploadDocumentModal } from './../../../common/UploadDocumentModal';
import { IUserModel } from './../../../../Core/ViewModels/User/UserViewModel';
import { ICompanySettings } from './../../../../Core/ViewModels/Company/CompanySettingsViewModel';
import * as CompanyStore from './../../../../store/company/CompanyStore';
import { UploadFabButton } from './../../../../components/common/UploadFabButton';
import { IUserDefaultSettingsStates } from './../../../navigation/profile/MySettings';
import { ITaxReturn } from './../../../common/TaxReturn';
import { GroupAccess, GroupConfigurationStateMultiple, GroupInfo } from '../../../../Core/ViewModels/GroupExtensions/ComponentModels';
import { GroupConfigurationModal } from '../../Common/GroupConfiguration';
import { ITaxSoftwareSettings, TaxSoftwareType } from 'src/Core/ViewModels/Company/SuiteCompanySettings/SuiteTaxSoftwareComponentModel';

interface SendGroupedExtensionsHeaderProps {
    pageTitle: string;
    selectedRows: number[];
    onAssignOpen: (rowIndex: number) => void;
    onDeleteGroupsOpen: (rowIndex: number) => void;
    onSetAccessOpen: (rowIndex: number) => void;
    onUnGroupOpen: (rowIndex: number) => void;
    selectedGroupCount: number;
    extensionUsers: IUserModel[];
    partners: IUserModel[];
    onPageReload: () => void;
    company: CompanyStore.ICompanyData;
    selectedERO: number;
    processTaxReturn: (url: string, callback: () => void) => void;
    getUploadLink: (url: string) => void;
    submitTaxReturn: (url: string, taxData: string, callback: () => void) => void;
    userDefaultSettings: IUserDefaultSettingsStates;
    companySettings: ICompanySettings;
    defaultGroup: string;
    groupInfo: GroupInfo[];
    groupAccess: GroupAccess[];
    selectedDocuments: number[];
    updateGroupInfoState: (reload: boolean) => void;

    groupConfigurationData: GroupConfigurationStateMultiple;
    onGroupConfigurationCancel: () => void;
    onGroupCreateNew: (groupInfo: GroupInfo) => void;
    onAddtoExistingGroup: (groupid: number) => void;
    onGroupConfigurationOpen: (rowIndex: number) => void;
    onUnMapReturn: () => void;

    taxSoftwareData: ITaxSoftwareSettings;
}
interface SendGroupedExtensionsHeaderState {
    show: boolean;
    selectedTaxSoftware: TaxSoftwareType;
}
const NO_INDEX = -1;
export class SendGroupedExtensionsHeader extends React.Component<SendGroupedExtensionsHeaderProps, SendGroupedExtensionsHeaderState> {
    constructor(props: SendGroupedExtensionsHeaderProps) {
        super(props);

        this.state = {
            show: false,
            selectedTaxSoftware: TaxSoftwareType.None,
        };

    }

    private onUploadReturnsOpen = (taxSoftware: TaxSoftwareType) => {
        this.setState({ show: true, selectedTaxSoftware: taxSoftware });
    }

    private HandleClose = () => {
        this.setState({ show: false });
    }

    public render() {

        var btnStyle = {
            width: '105px',
            marginRight: '5px'
        };
        return <div>
            <div className="row" style={{ marginBottom: 5 }}>
                <div className="col-sm-8">
                    <h3 className="page-title">{this.props.pageTitle}</h3>
                </div>

            </div>
            <div className="row">
                <div className="col-sm-10">
                    <Button
                        variant="default"
                        className='btn btn-white'
                        disabled={this.props.selectedDocuments.length === 0}
                        onClick={() => this.props.onAssignOpen(NO_INDEX)}
                        style={btnStyle}
                        data-test-auto="04649AB8-FBA1-4EA9-AC50-B476AA445D6A">
                        <i className='fas fa-user-check'></i> Assign
                    </Button>
                    <Button
                        variant="default"
                        className='btn btn-white'
                        disabled={this.props.selectedRows.length === 0 && this.props.selectedDocuments.length === 0}
                        onClick={() => this.props.onDeleteGroupsOpen(NO_INDEX)}
                        style={btnStyle}
                        data-test-auto="816D0EFE-D8DC-421B-9E67-B0D4312374A4">
                        <i className='fas fa-trash-alt'></i> Delete
                    </Button>
                    <Button
                        variant="default"
                        className='btn btn-white'
                        disabled={this.props.selectedRows.length === 0}
                        onClick={() => this.props.onSetAccessOpen(NO_INDEX)}
                        style={btnStyle}
                        data-test-auto="F12EE931-E07E-4C44-9BB2-F46D7B68FDFF">
                        <i className='fas fa-key'></i> Set Access
                    </Button>
                    <Button
                        variant="default"
                        className='btn btn-white'
                        disabled={this.props.selectedDocuments.length === 0}
                        onClick={() => this.props.onGroupConfigurationOpen(NO_INDEX)}
                        style={{ marginRight: '5px' }}
                        data-test-auto="732B32A7-8EB1-42D4-8937-93289D19754D">
                        <i className="fas fa-user-slash"></i> Change Group
                    </Button>
                </div>
                <div className="col-sm-2 txt-ar">
                    <UploadFabButton
                        companySettings={this.props.companySettings}
                        userDefaultSettings={this.props.userDefaultSettings}
                        onUploadReturnsClick={this.onUploadReturnsOpen}
                        taxSoftwareData={this.props.taxSoftwareData}
                    />
                </div>
            </div>
            <UploadDocumentModal
                onShow={this.state.show}
                onHide={this.HandleClose}
                extensionUsers={this.props.extensionUsers}
                partners={this.props.partners}
                taxSoftware={this.state.selectedTaxSoftware}
                selectedERO={this.props.selectedERO}
                getUploadLink={this.props.getUploadLink}
                processTaxReturn={this.props.processTaxReturn}
                submitTaxReturn={this.props.submitTaxReturn}
                onPageReload={this.props.onPageReload}
                defaultGroup={this.props.defaultGroup}
                groupInfo={this.props.groupInfo}
                groupAccess={this.props.groupAccess}
                updateGroupInfoState={this.props.updateGroupInfoState}
            />
            <GroupConfigurationModal
                showGroupConfiguration={this.props.groupConfigurationData.show}
                onCancel={this.props.onGroupConfigurationCancel}
                selectedAssignments={this.props.groupConfigurationData.selected as ITaxReturn[]}
                count={this.props.groupConfigurationData.selected ? this.props.groupConfigurationData.selected.length : 1}
                groupInfo={this.props.groupInfo}
                onCreateNew={this.props.onGroupCreateNew}
                onAddtoExisting={this.props.onAddtoExistingGroup}
                hideUnGroupReturn={false}
                UnMapGroupedReturns={this.props.onUnMapReturn}
            />
        </div >
    }
}