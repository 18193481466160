import { Action, Reducer } from 'redux';
import { actionTypes } from '../../ActionTypes';
import {
	RecycleGroupedExtensionsDictionary, RecycleGroupedExtensionsState,
	RecycleGroupedExtensionsTableModel, RequestRecycleGroupedReturnsPagesAction,
	ReceiveRecycleGroupedReturnsPagesAction, unloadedRecycleGroupedExtensionsPagesStoreState
}
	from '../../../Core/ViewModels/GroupExtensions/StoreModels';



type DispatchAction = RequestRecycleGroupedReturnsPagesAction
	| ReceiveRecycleGroupedReturnsPagesAction;

export const actionCreators = {};

export const reducer: Reducer<RecycleGroupedExtensionsDictionary> = (state: RecycleGroupedExtensionsDictionary = { "?": unloadedRecycleGroupedExtensionsPagesStoreState }, incomingAction: Action) => {
	const action = incomingAction as DispatchAction;
	switch (action.type) {
		case actionTypes.REQUEST_RECYCLE_GROUPED_EXTENSIONS_PAGES:
			if (!state[action.query]) {
				let added = { ...unloadedRecycleGroupedExtensionsPagesStoreState } as RecycleGroupedExtensionsState;
				added.loading = true;
				added.query = action.query;
				let item = {} as RecycleGroupedExtensionsDictionary;
				item[action.query] = added;

				return {
					...state,
					...item
				};
			}
			break;
		case actionTypes.RECEIVE_RECYCLE_GROUPED_EXTENSIONS_PAGES:
			if (state[action.query]) {
				let changed = state[action.query] as RecycleGroupedExtensionsState;
				changed.loading = false;
				changed.recycleGroupedExtensionsTableModel = action.table;
				changed.totalRowCount = action.totalRowCount;
				let item = {} as RecycleGroupedExtensionsDictionary;
				item[action.query] = changed;
				return {
					...state,
					...item
				};
			}
			break;

		default:
			// The following line guarantees that every action in the KnownAction union has been covered by a case above
			const exhaustiveCheck: never = action;
	}
	return state || { "?": unloadedRecycleGroupedExtensionsPagesStoreState };
};
