export interface IMailingReturnAddress {
    id: number;
    isPrimary: boolean;
    title: string;
    stateId: number;
    city: string;
    zip: string;
    fax: string;
    streetAddress1: string;
    streetAddress2: string;
    streetAddress3: string;
}

export interface IStates {
    id: number;
    name: string;
}

export const initialMailingReturnAddressList = {
    city: "",
    fax: "",
    stateId: 0,
    id: 0,
    streetAddress1: "",
    streetAddress2: "",
    streetAddress3: "",
    title: "",
    zip: "",
    isPrimary: false
}