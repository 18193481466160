import React from  'react';
import { Modal, Button, Col, FormGroup, FormLabel, FormControl, Alert } from 'react-bootstrap';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { IUserModel } from '../../Core/ViewModels/User/UserViewModel';
import { VenusNotifier } from '../helper/VenusNotifier';
import { PrepareUserList } from '../helper/UserHelperFunctions';
import { DropdownComponent } from '../common/controls/DropdownComponent'

interface IAssignModalProps {
    show: boolean;
    count: number;
    users: IUserModel[];
    onAssign(userId: number): void;
    onCancel(): void;
    defaultAssignUser: number;
}

interface AssignState {
    id: number;
    saving: boolean;
}

export class AssignModal extends React.Component<IAssignModalProps, AssignState> {
    constructor(props: IAssignModalProps) {
        super(props);
        this.state = {
            id: 0,
            saving: false
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentWillReceiveProps(nextProps: IAssignModalProps) {
        const saving = !nextProps.show ? false : this.state.saving;
        this.setState({
            id: nextProps.defaultAssignUser,
            saving: saving
        });
    }



    public render() {
        return <Modal
            className="set-access-modal"
            show={this.props.show}
            onHide={this.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <span className='fas fa-user-check' style={{ color: 'grey', marginRight: '5px' }}>
                    </span>Assign Return
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoadingOverlay >
                    <Alert variant="info" ><i className='fas fa-info-circle'>
                    </i>{this.props.count > 1 ? " " + this.props.count + " Returns selected." : " 1 Return selected."}</Alert>
                    {this.state.saving ? false :
                        <div className="row">
                            <Col sm={12}>
                                <FormGroup style={{ marginLeft: 0, marginRight: 0 }}>
                                    <FormLabel>Please select a user to assign the permission.</FormLabel>
                                    <DropdownComponent
                                        id="ddlUserList"
                                        options={PrepareUserList(this.props.users)}
                                        onChange={this.onChange}
                                        selectedValue={this.state.id}
                                        data-test-auto="54F2CEFD-C83E-4AF4-A064-759764DC59C8"
                                    />
                                </FormGroup>
                            </Col>
                        </div>}
                    <Loader loading={this.state.saving} text="Assigning return(s) to user..." />
                </LoadingOverlay>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="default"
                    className="btn-white"
                    onClick={this.onClose}>
                    <i className='fas fa-times'></i>Cancel
                    </Button>
                <Button
                    variant="info"
                    onClick={this.onSubmit}><i className='fas fa-save'></i>Assign</Button>
            </Modal.Footer>
        </Modal>
    }
    private onChange = (value: any) => {
        this.setState({ id: value });
    }
    private onSubmit(e: any) {
        if (this.state.id == 0 || this.state.id == null) { VenusNotifier.Warning("Please select a user", "Null Selection"); }
        else {
            if (!this.state.saving) {
                this.setState({ saving: true }, () => {
                    this.props.onAssign(this.state.id);
                });
            }
        }

    }

    private onClose(e: any) {
        this.setState({ saving: false }, () => { this.props.onCancel(); });
    }
};