import React from 'react'
import { Modal, Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index';
import * as UserLoginHistory from '../store/userManagement/UserLoginHistoryStore';
import { IUserLoginHistory } from './navigation/profile/ProfileObjects';
import moment from 'moment';
import * as Browser from 'bowser';
import { TYPES } from './../Startup/types';
import { container } from './../Startup/inversify.config';
import { ISessionLocalStore } from './../Core/Utilities/SessionStore';
import { CheckBoxComponent } from './common/CheckBoxComponent';
import { LoginHistory } from './svg/CustomGraphics';
import { actionCreators, UserSettings } from 'src/store/userManagement/UserSettingStore';
import { actionCreators as loginHistoryActions } from '../store/userManagement/UserLoginHistoryStore';
const SessionStore = container.get<ISessionLocalStore>(TYPES.ISessionStore);


type LastLoginModalProps = {
    userLoginHistory: UserLoginHistory.IUserLoginHistoryState,
    userSetting: UserSettings
}
    & typeof actionCreators
    & typeof loginHistoryActions
    & RouteComponentProps<{}>;

interface LastLoginModalState {
    lastLogin: IUserLoginHistory;
    openModal: boolean;
    enableLastLogin: boolean;
}

export class LastLoginModal extends React.Component<LastLoginModalProps, LastLoginModalState>{
    constructor(props: any) {
        super(props);
        this.state = {
            lastLogin: {
                userEmail: "",
                clientApp: "",
                customData: "",
                deviceId: "",
                ipAddress: "",
                loggedInOn: "",
                loggedOutOn: ""
            },
            openModal: false,
            enableLastLogin: false
        }
    }
    componentWillReceiveProps(nextProps: LastLoginModalProps, nextContext: any) {
        if (nextProps.userLoginHistory.lastLoginPromptLoaded &&
            nextProps.userLoginHistory.isLoginHistoryLoaded &&
            nextProps.userLoginHistory.lastLoginPrompt &&
            !SessionStore.get("isVisited")
        ) {
            this.setLastLogin(nextProps);
        }
        else if (nextProps.userLoginHistory.lastLoginPromptLoaded &&
            nextProps.userLoginHistory.isLoginHistoryLoaded
        ) {
            SessionStore.set("isVisited", true);
        }
    }

    private onCloseModal = (): void => {
        this.setState({
            openModal: false
        });
    }

    private setLastLogin = (props: LastLoginModalProps): void => {
        SessionStore.set("isVisited", true);
        const { userLoginHistory } = props;
        if (userLoginHistory.loginHistories.length > 1) {
            (userLoginHistory.loginHistories[0].deviceId == userLoginHistory.deviceId) ?
                this.setState({
                    lastLogin: userLoginHistory.loginHistories[1]
                }) :
                this.setState({
                    lastLogin: userLoginHistory.loginHistories[0]
                });
            this.setState({
                enableLastLogin: props.userLoginHistory.lastLoginPrompt,
                openModal: true
            });
        }
    }

    private getBrowserDetails = (userAgent: any) => {
        let browser = Browser.getParser(userAgent);
        return browser.getBrowserName() + "(" + browser.getBrowserVersion() + ")" + " on " + browser.getOSName() + "(" + browser.getOSVersion() + ")";
    }

    private changeLastLogin = (event: any) => {
        this.props.saveUserLastLoginPrompt(!event.target.checked)
        this.setState({
            enableLastLogin: !event.target.checked
        });
    }

    render() {
        const { openModal, lastLogin: { loggedInOn, ipAddress, clientApp } } = this.state;
        let browserDetails: string = "";
        if (clientApp) {
            browserDetails = this.getBrowserDetails(clientApp);
        }
        return (
            <div className="margin-tbl-20">
                <Modal show={openModal} data-testid="last-login-modal" className="last-login" onHide={this.onCloseModal} style={{ fontSize: '14px' }} >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <span className='modalIcon text-secondary fas fa-info-circle'>
                            </span>Information
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Row>
                                <Col lg={3}>
                                    <LoginHistory
                                        width={100}
                                        height={100}
                                        className="svg-shadow" />
                                </Col>
                                <Col lg={9}>
                                    <h2 style={{ color: '#337ab7', margin: '0' }} data-testid="last-login">Last Login</h2>
                                    <Row className="margin-top-10">
                                        <Col lg={3}>
                                            Log In On
                                        </Col>
                                        <Col lg={9} className="beforeContent">
                                            {moment.utc(loggedInOn).local().format('MM/DD/YYYY hh:mm:ss A')}
                                        </Col>
                                    </Row>
                                    <Row className="margin-top-10">
                                        <Col lg={3}>
                                            IP Address
                                        </Col>
                                        <Col lg={9} className="beforeContent">
                                            {ipAddress}
                                        </Col>
                                    </Row>
                                    <Row className="margin-top-10">
                                        <Col lg={3}>
                                            Browser
                                        </Col>
                                        <Col lg={9} className="beforeContent">
                                            {browserDetails}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'initial' }}>
                        <div className="pull-left padT07 div-flex">
                            <CheckBoxComponent
                                data-testid={"last-login-disable"}
                                id={"lastLoginDisable"}
                                text={"Don’t show me this message again"}
                                checked={!this.state.enableLastLogin}
                                onChange={this.changeLastLogin}
                                datatestAuto={"73E7B9C2-E1B6-4E00-80AE-8426EDDE969A"}
                            />
                            <Link to={'#'} className="help-icon marL05" data-placement="right" data-toggle="tooltip" title="To turn back on, see My Settings | Login History">
                                <i className="fas fa-question-circle"></i>
                            </Link>
                        </div>
                        <Button
                            className="btn-white pull-right"
                            variant='default'
                            onClick={this.onCloseModal}
                            data-testid="last-login-close"
                        >
                            <i className='fas fa-times'></i>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({
        userLoginHistory: state.userLoginHistory,
        userSetting: state.userSettings
    }), {
    ...actionCreators,
    ...loginHistoryActions
}
)(LastLoginModal as any); 
