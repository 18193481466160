import { addTask } from 'domain-task';
import { AppThunkAction } from '../';
import { actionTypes } from '../ActionTypes';
import { Reducer, Action } from 'redux';

import { BusinessReturnInstruction } from '../../Core/ViewModels/Common/BusinessReturnInstructionViewModel';
import { BusinessReturnConstants } from '../../components/helper/Constants';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { handleResponse } from '../Library';
import { EngagementType } from '../../components/common/TaxReturn';
import { DisplayDownloadFile } from '../../components/common/DisplayDownloadFile';
import { LoggerFactory } from '../../Logger/LoggerFactory';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';

var equal = require('fast-deep-equal');

export interface IBusinessReturnsState {
    instructions: BusinessReturnInstruction[];
    isLoading: boolean;
    loaderMessage: string;
}

interface RequestBusinessReturnInstructionListAction {
    type: actionTypes.REQUEST_BUSINESS_RETURN_INSTRUCTIONS;
}

interface ReceiveBusinessReturnInstructionListAction {
    type: actionTypes.RECEIVE_BUSINESS_RETURN_INSTRUCTIONS;
    instructions: BusinessReturnInstruction[];
}

interface ReplaceBusinessReturnStartAction {
    type: actionTypes.REPLACE_BUSINESS_RETURN_INSTRUCTION_START;
}

interface ReplaceBusinessReturnStopAction {
    type: actionTypes.REPLACE_BUSINESS_RETURN_INSTRUCTION_FINISH;
    instruction: BusinessReturnInstruction;
    fileName: string;
}

type DispatchAction = RequestBusinessReturnInstructionListAction |
    ReceiveBusinessReturnInstructionListAction |
    ReplaceBusinessReturnStartAction |
    ReplaceBusinessReturnStopAction

type KnownAction = DispatchAction |
    NotificationAction

export const actionCreators = {
    requestPreviewBusinessInstructionLink: (type: string, taxYear: number, callback: (url: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}/api/Download/GetBusinessInstructionLinkAsync/?type=` + type + '&taxYear=' + taxYear, { credentials: 'include' })
            .then(handleResponse)
            .then((data) => {
                callback(data);
            }).catch(function (error) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: BusinessReturnConstants.FetchInstructionErrorMessage,
                    statusType: StatusType.Error
                });
                logger.trackError(`requestPreviewBusinessInstructionLink failed for type: ${type}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },
    requestBusinessInstructionLink: (type: string, taxYear: number, fileName: string, callback?: (url: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}/api/Download/DownloadBusinessInstructionLinkAsync`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                type: type,
                taxYear: taxYear
            })
        }).then(response => {
            const contentDisposition = response.headers.get("content-disposition");
            const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
            if (fileNameMatch && fileNameMatch.length > 1) {
                fileName = fileNameMatch[1];
            }
            return response.blob();
        })
            .then((data: any) => {
                if (callback) {
                    callback(data);
                }
                else {
                    let displayDownloadFile = new DisplayDownloadFile();
                    displayDownloadFile.showFile(data, fileName);
                }
            })
        addTask(fetchTask);
    },

}

const unloadedState: IBusinessReturnsState = {
    instructions: [],
    isLoading: false,
    loaderMessage: "Loading..."
}

export const reducer: Reducer<IBusinessReturnsState> = (prevState: IBusinessReturnsState = unloadedState,
    incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    const newState = { ...prevState };
    switch (action.type) {
        case actionTypes.REQUEST_BUSINESS_RETURN_INSTRUCTIONS:
            newState.isLoading = true;
            newState.loaderMessage = unloadedState.loaderMessage;
            break;
        case actionTypes.RECEIVE_BUSINESS_RETURN_INSTRUCTIONS:
            newState.instructions = action.instructions;
            newState.isLoading = false;
            break;
        case actionTypes.REPLACE_BUSINESS_RETURN_INSTRUCTION_START:
            newState.isLoading = true;
            newState.loaderMessage = BusinessReturnConstants.ReplaceDocumentLoaderMessage;
            break;
        case actionTypes.REPLACE_BUSINESS_RETURN_INSTRUCTION_FINISH:
            newState.instructions.map((ins) => {
                if (equal(action.instruction, ins)) {
                    ins.fileName = action.fileName;
                    return false;
                }
            });
            newState.isLoading = false;
            break;
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }
    return newState;
}