import React from 'react';
import { Button } from 'react-bootstrap';
import { ITaxReturn } from '../common/TaxReturn';
import { IUserDefaultSettingsStates } from '../navigation/profile/MySettings';
import { ICompanySettings } from '../../Core/ViewModels/Company/CompanySettingsViewModel';
import { UploadFabButton } from '../../components/common/UploadFabButton';
import { RBACKeys } from '../helper/RbacConstants';
import { ITaxSoftwareSettings, TaxSoftwareType } from 'src/Core/ViewModels/Company/SuiteCompanySettings/SuiteTaxSoftwareComponentModel';

export interface AssignmentSetActionsProps {
    pageTitle: string;
    taxYear: number;
    selectedRows: number[];
    onAssignOpen: (rowIndex: number) => void;
    onDeleteReturnsOpen: (rowIndex: number) => void;
    onSetAccessOpen: (rowIndex: number) => void;
    onUploadReturnsOpen: (rowIndex: number, taxSoftware: TaxSoftwareType) => void;
    selectedDocuments: ITaxReturn[];
    userDefaultSettings: IUserDefaultSettingsStates,
    companySettings: ICompanySettings,
    onGroupConfigurationOpen: (rowIndex: number) => void;
    taxSoftwareData: ITaxSoftwareSettings
}
const NO_INDEX = -1;

export enum DocumentStatus {
    None = "None",
    UPLOADED = "UPLOADED",
    ERROR = "ERROR",
    READY = "READY",
    PROCESSING = "PROCESSING",
    REVIEW = "REVIEW",
    USERSIGNED = "USERSIGNED",
    DELIVERED = "DELIVERED",
    PREPARINGFORDELIVERY = "PREPARINGFORDELIVERY",
    DELIVERYFAILED = "DELIVERYFAILED",
    NONSUPPORTED = "NONSUPPORTED",
    RECALLED = "RECALLED",
    DELETED = "DELETED",
    APPROVEDFORDELIVERYDESCRIPTION = "APPROVED FOR DELIVERY",
    PREPARINGFORDELIVERYDESCRIPTION = "PREPARING FOR DELIVERY",
    DELIVERYFAILEDDESCRIPTION = "DELIVERY FAILED",
    NONSUPPORTEDDESCRIPTION = "NON SUPPORTED",
    DUPLICATE = "DUPLICATE",
    DOWNLOADPDF = "DOWNLOADPDF",
    DOWNLOADPDFDESCRIPTION = "ALTERNATE DELIVERY",
    DOWNLOADINGFAILED = "DOWNLOADINGFAILED",
    PREPARINGFORPDFDOWNLOAD = "PREPARINGFORPDFDOWNLOAD",
    DOWNLOADINGFAILEDDESCRIPTION = "DOWNLOADING FAILED",
    PREPARINGFORPDFDOWNLOADDESCRIPTION = "PREPARING FOR PDF DOWNLOAD",
    TOBEREPROCESSED = "TOBEREPROCESSED",
    TOBEREPROCESSEDDESCRIPTION = "TO BE REPROCESSED"
}



export class AssignmentsToolbar extends React.Component<AssignmentSetActionsProps, {}> {

    constructor(props: AssignmentSetActionsProps) {
        super(props);
    }

    public render() {
        let isNonSupported = false;
        if (this.props.selectedDocuments[0] !== undefined && this.props.selectedDocuments.find(x => x.documentStatus == DocumentStatus.NONSUPPORTED))
            isNonSupported = true;
        else
            isNonSupported = false;
        var btnStyle = {
            width: '105px',
            marginRight: '5px'
        };

        const setAccessBtnStyle = {
            width: '111px',
            marginRight: '5px'
        };

        const groupConfigurationBtnStyle = {
            width: '150px',
            marginRight: '5px'
        };

        return <div>
            <div className="row marB10">
                <div className="col-md-8">
                    <h3 className="page-title">{this.props.pageTitle}</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-md-8">
                    <Button
                        data-testid="assign-button"
                        variant="default"
                        className='btn btn-white'
                        disabled={this.props.selectedRows.length === 0 || isNonSupported}
                        onClick={(e: any) => this.props.onAssignOpen(NO_INDEX)}
                        style={btnStyle}
                        data-resource-id={RBACKeys.extensionInProgress.assignButton}
                        data-test-auto="E07EA6FC-5CCE-4E3D-99DA-1A184DF5AD74">
                        <i className='fas fa-user-check'></i> Assign
                    </Button>
                    <Button
                        data-testid="delete-button"
                        variant="default"
                        className='btn btn-white'
                        disabled={this.props.selectedRows.length === 0}
                        onClick={(e: any) => this.props.onDeleteReturnsOpen(NO_INDEX)}
                        style={btnStyle}
                        data-resource-id={RBACKeys.extensionInProgress.deleteButton}
                        data-test-auto="A9CAAC18-2E06-4AB2-8653-3AF993D517D0">
                        <i className='fas fa-trash-alt'></i> Delete
                    </Button>
                    <Button
                        data-testid="set-access-button"
                        variant="default"
                        className='btn btn-white'
                        disabled={this.props.selectedRows.length === 0 || isNonSupported}
                        onClick={(e: any) => this.props.onSetAccessOpen(NO_INDEX)}
                        style={setAccessBtnStyle}
                        data-resource-id={RBACKeys.extensionInProgress.setAccessButton}
                        data-test-auto="A077CA6F-CF65-4136-873E-372B0BCB8F12">
                        <i className='fas fa-key'></i> Set Access
                    </Button>
                    {/*<Button*/}
                    {/*	variant='default'*/}
                    {/*                   className='btn btn-white'*/}
                    {/*                   disabled={this.props.selectedRows.length === 0}*/}
                    {/*	onClick={(e: any) => this.props.onGroupConfigurationOpen(NO_INDEX)}*/}
                    {/*                   style={groupConfigurationBtnStyle}*/}
                    {/*                   data-test-auto="A9CAAC18-2E06-4AB2-8653-3AF993D517D0">*/}
                    {/*                   <i className='fas fa-user-check'></i> Group Extension*/}
                    {/*               </Button>*/}
                </div>
                <div className="col-md-4 txt-ar">
                    <UploadFabButton
                        companySettings={this.props.companySettings}
                        userDefaultSettings={this.props.userDefaultSettings}
                        onUploadReturnsClick={(taxSoftware: TaxSoftwareType) => { this.props.onUploadReturnsOpen(NO_INDEX, taxSoftware) }}
                        pageId="extensionInProgress"
                        taxSoftwareData={this.props.taxSoftwareData}
                    />
                </div>
            </div>
        </div>
    }
}
export default AssignmentsToolbar;

