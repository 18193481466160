import React from "react";
import { HeaderConstants, RouteConstants } from "./helper/Constants";
import { IMenuSection, ISideMenuProps } from "@sssuite-component-ui/ss-suite-layout/dist/SideMenu/SideMenu";
import { ISectionData } from "@sssuite-component-ui/ss-suite-layout/dist/Header/NavigationWidget/NavigationWidget";
import { IHeaderProps } from "@sssuite-component-ui/ss-suite-layout/dist/Header/Header";
import { IHeaderModal } from "./LayoutWrapper";

export const initialHeaderConfig: IHeaderProps = {
    productName: 5,
    companyName: "",
    onClickProductLogo: () => null,
    profileDropDown: {
        dropdownHeader: "",
        dropdownHeaderTitle: "",
        onClickLogout: () => null,
        profileDropDownItems: [],
    },
    widgetData: {
        // THE BELOW FUNCTION WILL BE REMOVED ONCE IT IS MADE OPTIONAL
        onBackClick: () => { return; },
        hideBackIcon: true,
        dropDownData: [
            {
                caption: HeaderConstants.SS_SUITE,
                items: [],
            },
            {
                caption: HeaderConstants.TOOLS,
                items: [],
            },
        ],
    },
    companyLogo: null
};

export const headerConfig = (showModal: IHeaderModal,
    setShowModal: React.Dispatch<React.SetStateAction<IHeaderModal>>,
    headerChildren: () => JSX.Element,
    companyName: string,
    firstName: string,
    widgetMenus: ISectionData[],
    suiteUrl: string,
    navigate: (route: string) => void,
    companyLogo: string
): IHeaderProps => {
    const defaultShowOption={
        mySettings: false,
        myDownloads: false,
        reportAProblem: false,
        myLoginHistory: false,
        logOut: false,
        profile: false
    }
    return {
        productName: 5,
        companyName: companyName,
        companyLogo: companyLogo,
        onClickProductLogo: () => navigate(RouteConstants.EXTENSIONINPROCESS),
        children: headerChildren(),
        profileDropDown: {
            dropdownHeader: `Hi, ${firstName?.trim()}!`,
            dropdownHeaderTitle: firstName?.trim(),
            onClickLogout: () => setShowModal({ ...defaultShowOption, logOut: true }),
            profileDropDownItems: [
                {
                    caption:HeaderConstants.PROFILE,
                    onClick:()=>{setShowModal({...defaultShowOption, profile:true})},
                },
                {
                    caption: HeaderConstants.MY_SETTINGS,
                    onClick: () => { setShowModal({...defaultShowOption,mySettings:true}) },
                },
                {
                    caption: HeaderConstants.MY_DOWNLOADS,
                    onClick: () => { setShowModal({...defaultShowOption,myDownloads:true}) },
                },
                {
                    caption:HeaderConstants.LOGIN_HISTORY,
                    onClick:()=>{setShowModal({...defaultShowOption,myLoginHistory:true})}
                }
            ],
        },
        widgetData: {
            onBackClick: () => {
                window.open(suiteUrl, '_blank');
            },
            dropDownData: widgetMenus
        },
    };
};

export const getSideMenu = (
    menuSections: IMenuSection[],
    navigate: (route: string) => void,
    hideSideMenu : boolean): ISideMenuProps => {
    let sideMenuProps: ISideMenuProps = {
        bottomMenuData: [],
        buttonIcon: "",
        buttonText: "",
        hasButton: false,
        onButtonClick: null,
        onNavigate: null,
        topMenuData: [],
        hideSideMenu: hideSideMenu,
        isMenuToggled: null,
        onClickCallback: null,
    };

    if (menuSections && menuSections.length) {

        sideMenuProps.hasButton = false;

        sideMenuProps.bottomMenuData = menuSections.filter(x => x.items.some(y => y.isAtBottomOfLeftMenu));

        sideMenuProps.topMenuData = menuSections.filter(x => x.items.some(y => !y.isAtBottomOfLeftMenu));

        sideMenuProps.onNavigate = navigate;

    }
    return sideMenuProps;
}