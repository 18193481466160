import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as DeliveredReturnsStore from '../../store/reports/DeliveredReturnsStore';
import * as FilterStore from '../../store/reports/FilterStore';
import * as TaxDocumentStore from '../../store/common/TaxDocumentStore';
import * as Component from './DeliveredReturns';
import * as UserStore from '../../store/userManagement/UserStore';
import * as UserSettingStore from '../../store/userManagement/UserSettingStore';
import * as CustomColumnstore from '../../store/common/CustomColumnStore';
import * as CompanyStore from '../../store/company/CompanyStore';
import * as ReminderStore from '../../store/common/ReminderStore';
import * as UploadTaxReturnStore from '../../store/common/uploadTaxReturn';
import * as AttachmentsStore from '../../store/ProcessReturn/AttachmentsStore';
import * as ColumnSettingsStore from './../../store/reports/ColumnSettings/ColumnSettingsStore';
import * as BulkOperationStore from '../../store/common/BulkOperationsStore';
import * as AdditionalEsignDocumentStore from '../../store/ProcessReturn/AdditionalEsignDocuments/AdditionalEsignDocumentStore';
import * as GroupedExtensionsStore from '../../store/groupedExtensions/GroupedExtensionsStore';
import * as ExtensionUserSettingsStore from '../../store/userManagement/ExtensionUserSettingStore';
import * as TaxSoftwareStore from '../../store/SuiteCompany/TaxSoftwareStore';
import * as ExtensionCompanySettingsStore from '../../store/settings/ExtensionCompanySettingsStore';
import * as OfficeLocation from '../../store/common/OfficeLocationStore';
import * as UserOfficeLocation from '../../store/common/UserOfficeLoacationStore';
import * as OnehubStore from '../../store/common/OnehubStore';

export default connect(
    (state: ApplicationState) => ({
        delivered: state.deliveredReturns,
        taxDocuments: state.taxDocuments,
        usersStore: state.usersStore,
        reportFilters: state.reportFilters,
        userSettings: state.userSettings,
        customColumnData: state.customColumn,
        company: state.companyData,
        reminderData: state.reminderData,
        nextSignerDetails: state.nextSignerDetails,
        uploadTaxReturnStore: state.UploadTaxReturnStore,
        attachments: state.attachments,
        isArchived: true,
        pageTitle: 'Archived Extensions',
        columnSettings: state.columnSettingsState,
        userProfile: state.userProfile,
        groupedExtensionsStore: state.groupedExtensionsStore,
        extensionUserSettings: state.extensionUserSettings,
        taxSoftwareData: state.taxSoftwareData,
        extensionCompanyData: state.extensionCompanyData.companySettings,
        officeLocation: state.officeLocation,
        userOfficeLocation: state.userOfficeLocation,
    }),
    {
        ...DeliveredReturnsStore.actionCreators,
        ...TaxDocumentStore.actionCreators,
        ...UserStore.actionCreators,
        ...FilterStore.actionCreators,
        ...UserSettingStore.actionCreators,
        ...CustomColumnstore.actionCreators,
        ...CompanyStore.actionCreators,
        ...ReminderStore.actionCreators,
        ...UploadTaxReturnStore.actionCreators,
        ...AttachmentsStore.actionCreators,
        ...ColumnSettingsStore.actionCreator,
        ...BulkOperationStore.actionCreators,
        ...AdditionalEsignDocumentStore.actionCreators,
        ...GroupedExtensionsStore.actionCreators,
        ...ExtensionUserSettingsStore.actionCreators,
        ...TaxSoftwareStore.actionCreators,
        ...ExtensionCompanySettingsStore.ActionCreators,
        ...OfficeLocation.actionCreators,
        ...UserOfficeLocation.actionCreators,
        ...OnehubStore.actionCreators,
    }
)(Component.DeliveredReturns as any);
