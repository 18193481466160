import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../../';
import { actionTypes } from '../../ActionTypes';
import {
    ReceiveRecycleReturnsAction,
    RequestRecycleReturnsAction,
    ReceiveRecycleReturnsPagesAction,
    RequestRecycleReturnsPagesAction,
    ResetRecycledExtensionsAction
} from "./../KnownTypes";
import { RecycleReturnsState, RecycleReturnsTableModel } from './RecycleReturnsState';
import { StatusType, NotificationAction } from '../../common/NotificationStore';
import { handleResponse } from '../../Library';
import { RecyleReturnsConstants, RestoreReturnConstants } from '../../../components/helper/Constants';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';
import { HideLoader } from '../../../components/helper/Loader';

type KnownAction = RequestRecycleReturnsAction |
    ReceiveRecycleReturnsAction |
    RequestRecycleReturnsPagesAction |
    ReceiveRecycleReturnsPagesAction |
    ResetRecycledExtensionsAction;

type AllKnownAction =
    RequestRecycleReturnsAction |
    ReceiveRecycleReturnsAction |
    RequestRecycleReturnsPagesAction |
    ReceiveRecycleReturnsPagesAction |
    ResetRecycledExtensionsAction |
    NotificationAction;

let recycleReturnsAbortController = new AbortController();

export const actionCreators = {

    requestRecycleReturns: (query: string, reload: boolean = false, callback?: () => void, reloadOnNoDataFound?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        let state = getState();
        if (reload || query !== state.recycleReturns.query) {

            let page = state.recycleReturnsPages[query];
            if (!reload && page) {

                dispatch({ type: actionTypes.REQUEST_RECYCLE_RETURNS, query: query });

                dispatch({
                    type: actionTypes.RECEIVE_RECYCLE_RETURNS,
                    query: query,
                    table: page.recycleReturnTableModel                   
                });

                return;
            }

            recycleReturnsAbortController.abort();
            recycleReturnsAbortController = new AbortController();

            const fetchTask = fetch(`${API_BASE_URL}api/Reports/RecycleDocuments` + query, {
                method: 'GET',
                credentials: 'include',
                signal: recycleReturnsAbortController.signal,
            })
                .then(response => response.json() as Promise<RecycleReturnsTableModel>)
                .then(data => {
                    if (data.count === 0 && reloadOnNoDataFound) {
                        reloadOnNoDataFound();
                    }
                    else {
                        dispatch({ type: actionTypes.RECEIVE_RECYCLE_RETURNS, query: query, table: data });
                        dispatch({
                            type: actionTypes.RECEIVE_RECYCLE_RETURNS_PAGES,
                            query: query,
                            table: data.documents,
                            totalRowCount: data.count
                        });
                        if (callback) {
                            callback();
                        }
                    }
                })
                .catch(function (error) {
                    if(error.name!=="AbortError"){
                        logger.trackError(`requestRecycleReturns failed for query: ${query}, with error ${error.message}`);
                    }
                    if (callback) {
                        callback();
                    }
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_RECYCLE_RETURNS, query: query });
            dispatch({ type: actionTypes.REQUEST_RECYCLE_RETURNS_PAGES, query: query });
        }
    },


    deleteDocuments: (documentid: number[], callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(`${API_BASE_URL}api/Reports/RecycleDocuments/`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(documentid)
           })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: RecyleReturnsConstants.SuccessMessages.Delete,
                    statusType: StatusType.Success
                });

                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: RecyleReturnsConstants.ErrorMessages.Delete,
                    statusType: StatusType.Error
                });
                HideLoader();
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });
                logger.trackError(`deleteDocuments failed for documentid: ${documentid}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    restoreDocuments: (documentids: number[], callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        let fetchTask = fetch(`${API_BASE_URL}api/Reports/RecycleDocuments/RestoreReturn`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain,*/*',
                'Content-Type': 'application/json',
                'traditional': 'true',
            },
            body: JSON.stringify(documentids)
        }).then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: RestoreReturnConstants.SuccessMessages.Restore,
                    statusType: StatusType.Success
                });

                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });
                logger.trackError(`restoreDocuments failed for documentids: ${documentids}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    updateRecycledExtensions: (): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        let state = getState();
        dispatch({ type: actionTypes.RESET_RECYCLED_EXTENSIONS });
        dispatch(actionCreators.requestRecycleReturns(state.recycleReturns.query, true));
    },
};


const unloadedState: RecycleReturnsState = {
    recycleReturnTableModel: {
        documents: [],
        count: 0
    } as RecycleReturnsTableModel,
    loading: true,
    query: '',
    totalRowCount: 0
} as RecycleReturnsState;

export const reducer: Reducer<RecycleReturnsState> = (state: RecycleReturnsState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_RECYCLE_RETURNS:
            return ({
                ...unloadedState,
                query: action.query,
                loading: true
            }) as RecycleReturnsState;
        case actionTypes.RECEIVE_RECYCLE_RETURNS:
            return {
                query: action.query,
                recycleReturnTableModel: action.table,
                totalRowCount: action.table.count,
                loading: false
            } as RecycleReturnsState;
        case actionTypes.RESET_RECYCLED_EXTENSIONS:
            return {
                ...unloadedState,
                query: state.query,
                isLoading: true
            } as RecycleReturnsState;
    }
    return state || unloadedState;
};

