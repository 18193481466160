export enum ClientInfoProperty {
    clientId,
    //Taxpayer
    taxpayerName,
    taxpayerEmail,
    taxpayerDateOfBirth,
    taxpayerAddress,
    taxpayerCity,
    taxpayerState,
    taxpayerZip,
    taxpayerSSN,
    taxpayerDeceased,
    //Spouse
    spouseName,
    spouseEmail,
    spouseDateOfBirth,
    spouseAddress,
    spouseCity,
    spouseState,
    spouseZip,
    spouseSSN,
    spouseDeceased,
    //Partner
    partnerName,
    partnerEmail,
    partnerEin,
    //Taxpayer
    taxpayerMobileNumber,
    taxpayerCountryCode,
    //Spouse
    spouseMobileNumber,
    spouseCountryCode,
    //Partner
    partnerMobileNumber,
    partnerCountryCode,
    //ERO/Signer
    eroPartnerId,
    //OfficeLocation
    officeLocationId
}
export default ClientInfoProperty;