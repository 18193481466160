import React, { useState } from 'react';
import { IModalProps, IUserProfile, initialUserProfile } from './ProfileObjects';
import { Button, Modal } from 'react-bootstrap';
import MyAccountLayout from './MyAccountLayout';

const MyAccount: React.FC<IModalProps> = ({ showState, onHide }) => {

    return <MyAccountLayout onHide={onHide} showState={showState} />
}

export default MyAccount;
