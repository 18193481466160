import React from  'react';
import { Link } from 'react-router-dom';
import { Button, Modal, FormGroup, FormLabel, FormControl, Col, Alert, NavLink } from 'react-bootstrap';
import { IUserModel } from '../../../../Core/ViewModels/User/UserViewModel';
import * as Helper from '../../../helper/HelperFunctions';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { GroupAccess, HeaderGroupAccess } from '../../../../Core/ViewModels/GroupExtensions/ComponentModels';

interface ISetAccessModalProps {
    showEditAccess: boolean;
    selectedGroups: number[];
    count: number;
    users: IUserModel[];
    onApplyAccess(accessingUsers: number[], groupIds: number[]): void;
    onCancel(event: any): void;
    groupAccessList: HeaderGroupAccess[];
    isLoading: boolean;
}

interface ISetAccessModalStates {
    allUsers: IUserModel[];
    groupUsers: IUserModel[];
    leftSelect: IUserModel[];
    lastLeft: number;
    rightSelect: IUserModel[];
    lastRight: number;
    saving: boolean;
    message: string;
    alertMsg: string;
    alertIcon: string;
}

const everyOne = { id: -1, firstName: 'everyone' } as IUserModel;
const msg = {
    loading: 'Loading,Please Wait...',
    saving: 'Submitting the access changes...',

}
export class SetAccessModal extends React.Component<ISetAccessModalProps, ISetAccessModalStates> {

    constructor(props: ISetAccessModalProps) {
        super(props);

        this.state = {
            allUsers: [],
            groupUsers: [],
            leftSelect: [],
            lastLeft: 0,
            rightSelect: [],
            lastRight: 0,
            saving: false,
            message: '',
            alertMsg: '',
            alertIcon: 'fa-info-circle',
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.moveRight = this.moveRight.bind(this);
        this.moveLeft = this.moveLeft.bind(this);
        this.onSelectFromLeft = this.onSelectFromLeft.bind(this);
        this.onSelectFromRight = this.onSelectFromRight.bind(this);
        this.filterLeft = this.filterLeft.bind(this);
        this.onClickAvailableUsers = this.onClickAvailableUsers.bind(this);
        this.onClickUsersWithAccessRights = this.onClickUsersWithAccessRights.bind(this);
    }

    componentWillReceiveProps(nexProps: ISetAccessModalProps) {
        let allUsers: IUserModel[] = [...nexProps.users];
        let groupUsers: IUserModel[] = [];
        let message = msg.loading;

        if (nexProps.selectedGroups != null && nexProps.selectedGroups.length != 0) {
            if (nexProps.users && nexProps.users.length > 0) {
                const arrays = nexProps.groupAccessList.map((groupAccess, j) => {
                    if (groupAccess && groupAccess.userId && groupAccess.userId.length > 0) {
                        return groupAccess.userId;
                    }
                });
                if (arrays && arrays.length > 0 && arrays[0] !== undefined) {
                    const common = Helper.intersection(arrays);

                    groupUsers = common.map((id, i) => {
                        return nexProps.users.find((u) => u.id === id);
                    }) as IUserModel[];

                }
                if (groupUsers.length == 0) {
                    groupUsers.push(everyOne);
                }
                else {
                    allUsers = this.filterLeft(groupUsers);
                }
                message = msg.saving;
            }
            else {
                groupUsers.push(everyOne);
            }
        }
        else {
            groupUsers.push(everyOne);
        }

        if (nexProps.showEditAccess) {
            this.setState({
                allUsers: allUsers,
                groupUsers: groupUsers,
                message: message
            });
        }
        else {
            this.setState({
                saving: false
            });
        }
    }

    public render() {
        return <Modal
            className="set-access-modal"
            show={this.props.showEditAccess}
            onHide={this.props.onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <span className='modalIcon text-secondary fa fa-key'>
                    </span>Set Access
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoadingOverlay style={{ height: '100%' }}>
                    <Alert variant="info" ><i className='fas fa-info-circle'>
                    </i>{this.props.count > 1 ? " " + this.props.count + " Groups selected." : " 1 Group selected."}</Alert>
                    {this.state.alertMsg ?
                        <Alert
                            closeLabel={'remove'}
                            variant="warning"
                            dismissible
                            onClose={() => { this.setState({ alertMsg: '' }) }}>
                            <span
                                className={'fa ' + this.state.alertIcon}
                                style={{ marginRight: '5px' }}>
                            </span>{this.state.alertMsg}
                        </Alert>
                        : false}
                    <SetAccessBody
                        groupUsers={this.state.groupUsers}
                        allUsers={this.state.allUsers}
                        onDoubleClickLeft={this.onClickAvailableUsers}
                        onDoubleClickRight={this.onClickUsersWithAccessRights}
                        onSelectFromLeft={this.onSelectFromLeft}
                        onSelectFromRight={this.onSelectFromRight}
                        moveLeft={this.moveLeft}
                        moveRight={this.moveRight}
                        rightSelect={this.state.rightSelect}
                        leftSelect={this.state.leftSelect}
                    />
                    <Loader loading={this.state.saving || this.props.isLoading} text={this.props.isLoading ? msg.loading : msg.saving} />
                </LoadingOverlay>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="default"
                    className="btn-white"
                    disabled={this.state.saving}
                    onClick={this.props.onCancel}
                ><i className='fas fa-times'></i>Cancel</Button>
                <Button
                    variant="info"
                    disabled={this.state.saving}
                    onClick={this.onSubmit}
                ><i className='fas fa-save'></i>Save</Button>
            </Modal.Footer>
        </Modal>
    }

    private onSubmit(e: any) {
        if (!this.state.saving) {
            this.setState({ saving: true, message: msg.saving }, () => {
                let users = this.state.groupUsers.map((user, id) => {
                    return user.id;
                });
                if (users.some(x => x === -1)) {
                    users = [];
                }
                this.props.onApplyAccess(users, this.props.selectedGroups);
            });
        }
    }
    private onClickAvailableUsers(selected: IUserModel, callback: any) {
        const leftSelect = Helper.handleSelect(selected, event, this.state.leftSelect, this.state.allUsers, this.state.lastLeft);
        this.setState({ leftSelect: leftSelect, lastLeft: selected.id });

        if (callback)
            callback();
    }

    private onClickUsersWithAccessRights(selected: IUserModel, callback: any) {
        const rightSelect = Helper.handleSelect(selected, event, this.state.rightSelect, this.state.groupUsers, this.state.lastRight);
        this.setState({ rightSelect: rightSelect, lastRight: selected.id });

        if (callback)
            callback();
    }
    private onSelectFromLeft(selected: IUserModel, event: any) {
        const leftSelect = Helper.handleSelect(selected, event, this.state.leftSelect, this.state.allUsers, this.state.lastLeft);
        this.setState({ leftSelect: leftSelect, lastLeft: selected.id });
    }

    private onSelectFromRight(selected: IUserModel, event: any) {
        const rightSelect = Helper.handleSelect(selected, event, this.state.rightSelect, this.state.groupUsers, this.state.lastRight);
        this.setState({ rightSelect: rightSelect, lastRight: selected.id });
    }

    private moveRight(event: any) {
        if (this.state.leftSelect.length > 0) {
            let right = [...this.state.groupUsers];
            this.state.leftSelect.map((u, i) => {
                right.push(u);
            });
            right = handleEveryOne(right);
            this.setState({
                allUsers: this.filterLeft(right),
                groupUsers: right,
                leftSelect: [],
                rightSelect: [],
                lastLeft: 0,
                lastRight: 0
            });
        } else {
            this.setState({ alertMsg: 'Please select users to add!', alertIcon: 'fa-exclamation-triangle' });
        }
        event.preventDefault();
    }

    private moveLeft(event: any) {
        if (this.state.rightSelect.length > 0) {

            let right = [...this.state.groupUsers];
            this.state.rightSelect.map((u, i) => {
                Helper.removeItem(u, right);
            });
            right = handleEveryOne(right);
            this.setState({
                allUsers: this.filterLeft(right),
                groupUsers: right,
                leftSelect: [],
                rightSelect: [],
                lastLeft: 0,
                lastRight: 0
            });
        } else {
            this.setState({ alertMsg: 'Please select users to remove!', alertIcon: 'fa-exclamation-triangle' });
        }
        event.preventDefault();
    }

    private filterLeft(right: IUserModel[]) {
        const left = [...this.props.users];
        right.map((u, i) => {
            Helper.removeItem(u, left);
        });
        return left;
    }
};

export function handleEveryOne(right: IUserModel[]) {
    if (right.length > 1) {
        Helper.removeItem(everyOne, right);
    }
    if (right.length === 0) {
        right.push(everyOne);
    }
    return right;
}

interface IBodyProps {
    groupUsers: IUserModel[];
    allUsers: IUserModel[];
    onDoubleClickLeft: (selected: IUserModel, callback: any) => void;
    onDoubleClickRight: (selected: IUserModel, callback: any) => void;
    onSelectFromLeft: (selected: IUserModel, event: any) => void;
    onSelectFromRight: (selected: IUserModel, event: any) => void;
    moveRight: (event: any) => void;
    moveLeft: (event: any) => void;
    rightSelect: IUserModel[];
    leftSelect: IUserModel[];
}

export class SetAccessBody extends React.Component<IBodyProps, {}> {

    public render() {
        return <div>
            <div className="row">
                <Col sm={12} className="disp-flex">
                    <Col sm={5}>
                        <FormGroup className="disp-block">
                            <FormLabel>Available User:</FormLabel>
                            <FormControl id="current-users" style={{ height: '200px', overflowY: 'auto' }} className="group-user" as="div">

                                <ul >
                                    {this.props.allUsers != undefined ? this.props.allUsers.map((user, index) => {
                                        return <li
                                            className={this.props.leftSelect.some((j: IUserModel) => j.id == user.id) ? "active-selected" : ""}
                                            key={index}
                                            value={user.id}
                                            onDoubleClick={() => { this.props.onDoubleClickLeft(user, this.props.moveRight); }}
                                            onClick={this.props.onSelectFromLeft.bind(this, user)}
                                        >{Helper.fullName(user)}</li>;
                                    }) : ""}
                                </ul>

                            </FormControl>
                        </FormGroup>
                    </Col>
                    <Col sm={2} className="nav-button">
                        <Link to={'#'} style={{ cursor: 'default' }}>
                            <span style={{ cursor: 'pointer' }}
                                className="fas fa-chevron-right"
                                onClick={this.props.moveRight}></span>
                        </Link>
                        <Link to={'#'} style={{ cursor: 'default' }}>
                            <span style={{ cursor: 'pointer' }}
                                className="fas fa-chevron-left"
                                onClick={this.props.moveLeft}></span>
                        </Link>
                    </Col>
                    <Col sm={5}>
                        <FormGroup className="disp-block">
                            <FormLabel>User With Access Rights:</FormLabel>
                            <FormControl id="selected-users" style={{ height: '200px', overflowY: 'auto' }} className="group-user" as="div">

                                <ul>
                                    {this.props.groupUsers != undefined ? this.props.groupUsers.map((user, index) => {
                                        return <li
                                            className={this.props.rightSelect.some((j: IUserModel) => j.id == user.id) ? "active-selected" : ""}
                                            key={index}
                                            value={user.id}
                                            onDoubleClick={() => { this.props.onDoubleClickRight(user, this.props.moveLeft); }}
                                            onClick={this.props.onSelectFromRight.bind(this, user)}
                                        >{Helper.fullName(user)}</li>;
                                    }) : ""}
                                </ul>

                            </FormControl>
                        </FormGroup>
                    </Col>
                </Col>
            </div>
        </div>
    }
}
