import { ProductType } from "@sssuite-component-ui/ss-suite-layout";
import { validateError } from "../../components/helper/HelperFunctions";
import { logger } from "../../routes/LoggedIn";
import { SUITE_API_BASE_URL } from "../../utils/contants";
import { handleResponse } from "../Library";
import store from "..";
import { RESET_CACHE_API_CALLED } from "./action-types";
import { removeAutoLogout } from "../../oidcClient/helpers";

export const resetUserCache = async () =>
{
    await fetch(`${SUITE_API_BASE_URL}/api/user-management/reset-user-status-cache/${ProductType.Extensions}`, {
        credentials: 'include',
        method: 'GET',
    }).then(handleResponse)
        .then(data => {
            store.dispatch({ type: RESET_CACHE_API_CALLED });
            removeAutoLogout();
        })
        .catch(error => {
            store.dispatch({ type: RESET_CACHE_API_CALLED });
            removeAutoLogout();
            logger.trackTrace(`SSSuite reset-user-status-cache API failed with error ${validateError(error)}`);
        });
}

export const updateLogoutDate = async () => {
    await fetch(`${SUITE_API_BASE_URL}/api/login-history/update-logout-date`, {
        credentials: 'include',
        method: 'PUT'
    }).then(handleResponse).catch(error => {
        logger.trackTrace(`updateLogoutDate API failed with error ${validateError(error)}`);
    });
}