import React from  'react';
import { Popover, OverlayTrigger, Modal, Button, ListGroup, Row, Col, Table } from 'react-bootstrap';
import 'isomorphic-fetch';
import { IModalProps } from './ProfileObjects'
import moment from "moment";
import { SignatureStatus, EngagementType, DocumentStatusType, engagementType, IOfficeLocation } from '../../../components/common/TaxReturn';
import { ReportFilterType } from '../../reports/Filters';



export interface IDownloadedZipFilesModel {
    Id: number;
    JobId: string;
    CreatedBy: number;
    CreatedUser: number;
    RequestFilters: IBulkRequestFilters;
    FileName: string;
    CreatedOn: any;
    FileSize: any;
    Status: StatusZipDownload;
    UpdatedOn: any;
    TotalDocumentsCount: number;
    DownloadSuccessDocumentsCount: number;
}

export interface IBulkRequestFilters {
    SearchText?: string;
    TaxYear?: string;
    Name?: string;
    ClientId?: string;
    Partner?: string;
    Status?: string;
    DeliveredOn?: any;
    EngagementType: number;
    CustomColumn?: string;
    DownloadedBy?: string;
    LastReminderOn?: any;
    RetentionDate?: any;
    SentBy?: string;
    IsArchived?: string;
    UnSelectedIds?: any[];
        officeLocation?: number[];
}

export enum StatusZipDownload {
    None = 0,
    InProgress = 1,
    Ready = 2,
    Error = 3,
    PartialReady = 4,
    DownloadNow = 5
}

const listStyle = { marginBottom: "4px", listStyleType: "none", borderBottom: " 1px dotted lightgrey", marginTop: "16px" };
const listLastLineStyle = { marginBottom: "4px", listStyleType: "none", marginTop: "16px" };


interface MyDownloadsProps extends IModalProps {
    downloadsList: IDownloadedZipFilesModel[],
    downloadReturnFromPopup: (jobId: string, fileName: string, isDownloadNow?: boolean, callback?: (url: string) => void) => void;
    deleteMyDownloads: (jobId: string) => void;
    clearAllMyDownloads: () => void;
    officeLocation?: any[];
}

interface MyDownloadsState {
    row: IDownloadedZipFilesModel[];
    collapsibleRowId: number;
}

export class MyDownload extends React.Component<MyDownloadsProps, MyDownloadsState> {
    constructor(props: MyDownloadsProps) {
        super(props);
        this.state = {
            collapsibleRowId: -1,
            row: []
        }
    }

    componentWillReceiveProps(newProps: MyDownloadsProps) {
        this.setState({ row: newProps.downloadsList });
    }

    rowToggleOpen = (event: any) => {
        if (event.target.nextElementSibling.style['display'] == "none") {
            event.target.style['display'] = "none";
            event.target.nextElementSibling.style['display'] = "block";
            event.target.parentElement.parentElement.nextElementSibling.style['display'] = 'block'
        } else {
            event.target.style['display'] = "block";
            event.target.nextElementSibling.style['display'] = "none";
            event.target.parentElement.parentElement.nextElementSibling.style['display'] = 'none'
        }
    }

    rowToggleClose = (event: any) => {
        if (event.target.previousElementSibling.style['display'] == "none") {
            event.target.style['display'] = "none";
            event.target.previousElementSibling.style['display'] = "block";
            event.target.parentElement.parentElement.nextElementSibling.style['display'] = 'none'
        } else {
            event.target.style['display'] = "block";
            event.target.previousElementSibling.style['display'] = "none";
            event.target.parentElement.parentElement.nextElementSibling.style['display'] = 'block'
        }
    }

    onDownloadClick(jobId: string, fileName: string) {
        this.props.downloadReturnFromPopup(jobId, fileName);
    }


    formatBytes = (bytes: number, decimals: number = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    deleteSelectedDownload  = (event: any, jobId: string) => {
        let node = event.target.parentElement.previousElementSibling.previousElementSibling;
        if (node.childNodes[0].style["display"] == "none") {
            node.childNodes[1].style["display"] = "none";
            node.childNodes[0].style["display"] = "block";
            node.parentElement.nextElementSibling.style["display"] = "none"
        }
        this.props.deleteMyDownloads(jobId);
    }
    private getFileFormatIcon(fileName: string) {
        if (fileName.includes('.xlsx')) {
            return "fa fa-file-excel fa-3x my-download-file-icon";
        }
        return "fa fa-file-archive fa-3x my-download-file-icon";
    }

    getDownloadList = () => {
        let rowCollection: any = [];
        let filtersApplied: any = [];

        for (var i = 0; i < this.state.row.length; i++) {
            let tmpForm: any = this.state.row[i];

            { tmpForm.requestFilters.name != "" && filtersApplied.push({ 'filtername': "Name", 'value': tmpForm.requestFilters.name }) }
            { tmpForm.requestFilters.clientId != "" && filtersApplied.push({ 'filtername': "Client Id", 'value': tmpForm.requestFilters.clientId }) }
            { tmpForm.requestFilters.engagementType != 0 && filtersApplied.push({ 'filtername': "Return Type", 'value': this.getEngagementType(tmpForm.requestFilters.engagementType) }) }
            { tmpForm.requestFilters.taxYear != "" && filtersApplied.push({ 'filtername': "Tax Year", 'value': this.getTaxYear(tmpForm.requestFilters.taxYear) }) }
            { tmpForm.requestFilters.partner != "" && filtersApplied.push({ 'filtername': "ERO/Signer", 'value': tmpForm.requestFilters.partner }) }
            { tmpForm.requestFilters.sentBy != "" && filtersApplied.push({ 'filtername': "Sent By", 'value': tmpForm.requestFilters.sentBy }) }
            { tmpForm.requestFilters.deliveredOn != null && filtersApplied.push({ 'filtername': "Delivered Date", 'value': moment(tmpForm.requestFilters.deliveredOn).format("MM/DD/YYYY").toString() + " - " + moment.utc(tmpForm.createdOn).local().format('MM/DD/YYYY').toString() }) }
            { tmpForm.requestFilters.status != "" && filtersApplied.push({ 'filtername': "Status", 'value': this.getStatus(tmpForm.requestFilters.status, tmpForm.requestFilters.reportType) }) }
            { tmpForm.requestFilters.lastReminderOn != null && filtersApplied.push({ 'filtername': "Last Reminder", 'value': moment(tmpForm.requestFilters.lastReminderOn).format("MM/DD/YYYY").toString() }) }
            { tmpForm.requestFilters.customColumn != "" && filtersApplied.push({ 'filtername': "Custom Column", 'value': tmpForm.requestFilters.customColumn }) }
            { tmpForm.requestFilters.downloadedBy != "" && filtersApplied.push({ 'filtername': "Downloaded", 'value': this.getDownloadOptions(tmpForm.requestFilters.downloadedBy) }) }
            { tmpForm.requestFilters.retentionPeriod != null && filtersApplied.push({ 'filtername': "Deletion Date", 'value': moment(tmpForm.requestFilters.retentionPeriod).format("MM/DD/YYYY").toString() }) }
            { tmpForm.requestFilters.officeLocation != "" && tmpForm.requestFilters.officeLocation != null && filtersApplied.push({ 'filtername': "Office Location", 'value': this.getLocation(tmpForm.requestFilters.officeLocation).toString() }) }

            { tmpForm.requestFilters.uploadedOn != null && filtersApplied.push({ 'filtername': "Uploaded On", 'value': moment(tmpForm.requestFilters.uploadedOn).format("MM/DD/YYYY").toString() }) }
            { tmpForm.requestFilters.assignedTo && tmpForm.requestFilters.assignedTo != "" && filtersApplied.push({ 'filtername': "Assigned To", 'value': decodeURIComponent(tmpForm.requestFilters.assignedTo) }) }
            { tmpForm.requestFilters.taxSoftware && (tmpForm.requestFilters.taxSoftware !== "" && tmpForm.requestFilters.taxSoftware !== null) && filtersApplied.push({ 'filtername': "Tax Software", 'value': tmpForm.requestFilters.taxSoftware }) }
            { (tmpForm.requestFilters.returnStatus !== "" && tmpForm.requestFilters.returnStatus !== null) && filtersApplied.push({ 'filtername': "Return Status", 'value': this.getReturnStatusOptions(tmpForm.requestFilters.returnStatus) }) }

            rowCollection.push(<li
                style={i == (this.state.row.length - 1) ? listLastLineStyle : listStyle}>
                <Row>
                    <Col lg={2} md={2} sm={2}>
                        <span className={this.getFileFormatIcon(tmpForm.fileName)}></span>
                    </Col>
                    <Col lg={5} md={5} sm={5} className="nopadding">
                        <div>
                            {this.getItems(filtersApplied)}
                            {filtersApplied.length > 3 && this.getMouseHoverItems(filtersApplied)}
                        </div>
                    </Col>
                    <Col lg={2} md={2} sm={2}>
                        <span title="Expand"
                            onClick={this.rowToggleOpen}
                            className="fa fa-chevron-down my-download-row-toggle">
                        </span>
                        <span title="Collapse"
                            onClick={this.rowToggleClose}
                            className="fa fa-chevron-up my-download-row-toggle"
                            style={{ display: 'none' }}>
                        </span>
                    </Col>
                    <Col lg={2} md={2} sm={2}>

                        {this.getDownloadStatus(tmpForm)}

                    </Col>
                    <Col lg={1} md={1} sm={1}>
                        {!(tmpForm.status == StatusZipDownload[StatusZipDownload.InProgress]) ?
                            <span className="my-download-delete fa fa-times" aria-hidden="true"
                                onClick={(event) => this.deleteSelectedDownload(event, tmpForm.jobId)}
                                title="Remove from list"
                                data-test-auto="E2A8DF59-2157-41ED-87FF-9A562368C9F9">
                            </span>
                            : null
                        }
                    </Col>
                </Row>
                <Row id={this.state.collapsibleRowId.toString()}
                    className="marT10"
                    style={{ display: 'none' }}>
                    <Col>
                        <div className="my-download-expand">
                            <span className="text-bold"> {tmpForm.fileName} </span>
                            <span style={{ color: "dark-grey" }}> | </span>

                            <span> Date :</span>
                            <span className="text-bold"> {moment.utc(tmpForm.createdOn).local().format('MM/DD/YYYY HH:mm')} </span>

                            <span style={{ color: "dark-grey" }}> | </span>
                            <span> File Size : </span>
                            <span className="text-bold"> {this.formatBytes(tmpForm.fileSize)} </span>
                        </div>
                    </Col>
                </Row>
                <br />
            </li >)

            filtersApplied = [];
        }

        return rowCollection.length > 0 ? rowCollection : <div className="txt-ac"> No data </div>
    }

    private getItems(filters: any) {
        if (filters.length == 0) {
            return (<div style={{ fontSize: "12px" }}><span className="list-sub-title">No Filter Applied </span> <br /></div>);
        }
        else {
            return (
                <div className="ellipsis">
                    <Table bordered={false} bsPrefix="sm" >
                        <tbody>
                            {filters.map((item: any, _index: any) => {
                                if (_index < 3) {
                                    return (
                                        <tr className="my-download-filter">
                                            <td>
                                                <div className="my-download-filter-row">
                                                    <span>{item.filtername}</span>
                                                    <span title={item.value}
                                                        className="ellipsis text-bold">
                                                        <span> : </span>
                                                        {item.value}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                            })
                            }
                        </tbody>
                    </Table>
                </div>
            )
        }

    }

    private getDownloadStatus = (tmpForm: any) => {
        if (tmpForm.status == StatusZipDownload[StatusZipDownload.Ready]) {
            return (
                <span className="my-download-status-icon fa fa-arrow-circle-down"
                    title="Download"
                    onClick={() => this.onDownloadClick(tmpForm.jobId, tmpForm.fileName)}
                    style={{ cursor:"pointer" }}
                />
            );
        }
        else if (tmpForm.status == StatusZipDownload[StatusZipDownload.PartialReady]) {          
            const tooltip = `Download complete (${tmpForm.downloadSuccessDocumentsCount} out of ${tmpForm.totalDocumentsCount})`;
            return (
                <span className="my-download-status-icon fa fa-arrow-circle-down"
                    title={tooltip}
                    onClick={() => this.onDownloadClick(tmpForm.jobId, tmpForm.fileName)}
                    style={{ cursor: "pointer", color: "orange" }}
                />
            );
        }
        else if (tmpForm.status == StatusZipDownload[StatusZipDownload.InProgress]) {
            return (
                <span title="Loading"
                    className=" my-download-status-icon fa fa-spinner fa-pulse"
                />
            );
        }
        else if (tmpForm.status == StatusZipDownload[StatusZipDownload.DownloadNow]) {
            return (
                <span title="Loading"
                    className=" my-download-status-icon fa fa-spinner fa-pulse"
                />
            );
        }
        else {
            return (
                <span title="Download Failed"
                    className="text-danger my-download-status-icon fa fa-exclamation-circle"
                />
            );
        }
    }


    private getMouseHoverItems(filters: any) {
        return (
            <OverlayTrigger trigger={['hover', 'focus']}
                placement="right"
                overlay={(<Popover id="hover-item"> {this.popOver(filters)} </Popover >)}>
                <span style={{ color: "#88c656" }}>[Read More...]</span>
            </OverlayTrigger>
        )
    }

    private popOver(filters: any) {
        let result = [];
        for (var i = 3; i < filters.length; i++) {
            let item: any = filters[i];
            if (item) {
                result.push(
                    <div className="my-download-popover">
                        <span className="list-sub-title"> {item.filtername}</span>
                        <span className="text-bold"> : {item.value} </span> <br />
                    </div>
                );
            }
        }
        return result;
    }

    getLocation = (location: any) => {
        return [...this.props.officeLocation?.map(location=>{return {"name":location.name,"value":location.value}}),{"name":"Blanks","value":-100}].filter(x => location.split(',').some(y => y==x.value)).map(x => x.name ).join(',');
    }
   

    private getStatus(status: any, filterType: any) {
        let filterStatus: any = [];
        let statuses: any = status.split(",");
        if (filterType == ReportFilterType.SendExtension) {
            for (var i = 0; i < statuses.length; i++) {
                let array: any[] = DocumentStatusType.filter(x => x.value === parseInt(statuses[i]));
                if (array.length > 0) {
                    filterStatus.push(array[0].label);
                }

            }
        } else {
            for (var i = 0; i < statuses.length; i++) {
                filterStatus.push(this.getStatusText(statuses[i]));
            }
        }
        return filterStatus.join(", ");
    }
    //TODO: Use Helper function getstatus
    private getStatusText(value: string): string {
        switch (parseInt(value)) {
            case SignatureStatus.ESigned:
                return "E Signed";
            case SignatureStatus.ManuallySigned:
                return " Manually Signed";
            case SignatureStatus.Uploaded:
                return "Uploaded";
            case SignatureStatus.AwaitingESign:
                return "Awaiting E-Sign";
            case SignatureStatus.AwaitingUpload:
                return "Awaiting Upload";
            case SignatureStatus.Locked:
                return "Locked";
            case SignatureStatus.MailOrFax:
                return "Mail Or Fax";
            case SignatureStatus.SignedAndESigned:
                return "Signed And E-Signed";
            case SignatureStatus.AutoParsed:
                return "Auto Parsed";
            case SignatureStatus.Processing:
                return "Processing";
            case SignatureStatus.Delivering:
                return "Delivering";
            case SignatureStatus.Delivered:
                return "Delivered";
            case SignatureStatus.DeliveryFailed:
                return "Delivery Failed";
            case SignatureStatus.UploadInProgress:
                return "Upload In Progress";
            case SignatureStatus.DeliveredToTaxCaddy:
                return "Delivered To TaxCaddy";
            case SignatureStatus.TaxCaddyDeliveryFailed:
                return "TaxCaddy Delivery Failed";
            case SignatureStatus.AlternateAndUploaded:
                return "Alternate And Uploaded";
            case SignatureStatus.AlternateDelivery:
                return "Alternate Delivery";
            case SignatureStatus.PartiallySigned:
                return "Partially Signed";
            case SignatureStatus.PartiallyReviewed:
                return "Partially Reviewed";
            case SignatureStatus.AwaitingReview:
                return "Awaiting Review";
            case SignatureStatus.Reviewed:
                return "Reviewed";
        }   
        return "";
    }

    private getDownloadOptions(downloadOption: any) {
        switch (downloadOption) {
            case "0":
                return "To Be Downloaded";
            case "1":
                return "Downloaded";
        }
    }

    private getEngagementType(engageType: any) {
        return engagementType((EngagementType[engageType] as any) as EngagementType);
    }

    private getTaxYear(taxYear: string) {
        let taxYearArr: any = taxYear.split(',');
        if (taxYearArr[0] == "0") {
            taxYearArr.shift();
        }
        return taxYearArr.join(", ");
    }

    private isClearAllDisabled = () => {
        //returns true if all files are InProgress
        let isDisabled = this.state.row &&
            this.state.row.find((m: any) => m.status !== StatusZipDownload[StatusZipDownload.InProgress]) ? false : true;

        return isDisabled;
    }

    private getReturnStatusOptions = (returnStatus: any) => {
        switch (returnStatus) {
            case "0":
                return "Return Not Delivered";
            case "1":
                return "Return Delivered";
        }
    }

    public render() {
        return <Modal show={this.props.showState} onHide={this.onPopupClose} className="my-settings" >
            <Modal.Header closeButton className="text-left-align">
                <Modal.Title >
                    <span className='fa fa-download my-download-title '></span>
                    My Downloads
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ minHeight: '100px', maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                <div>
                    <ListGroup>
                        {
                            this.getDownloadList()
                        }
                    </ListGroup>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="btn-white"
                    variant='default'
                    disabled={this.isClearAllDisabled()}
                    onClick={() => this.props.clearAllMyDownloads()}
                >
                    <i className='fas fa-trash-alt'></i>
                    Clear All
                </Button>
                <Button
                    variant="info"
                    onClick={this.onPopupClose}>
                    <i className='fas fa-times'></i>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    }

    onPopupClose = () => {
        this.props.onHide();
    }
}
export default MyDownload;