import React from 'react';
import { CheckBoxComponent } from '../../common/CheckBoxComponent';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { VoucherPaymentNotificationInfo } from 'src/components/helper/Constants';

interface PaymentVoucherNotificationProps {
    enablePaymentVoucherReminder: boolean;
    noOfDaysForVoucherReminder: number;
    paymentNotification: boolean;
    onPaymentVoucherReminderChange: (enablePaymentVoucherReminder: boolean) => void;
    onVoucherReminderDaysChange: (days: number) => void;
    onPaymentNotificationChange: (notification: boolean) => void;
}

const PaymentVoucherNotification: React.FC<PaymentVoucherNotificationProps> = ({
    enablePaymentVoucherReminder,
    onPaymentVoucherReminderChange,
    onVoucherReminderDaysChange,
    noOfDaysForVoucherReminder,
    onPaymentNotificationChange,
    paymentNotification
}) => {

    const buildOptions = () => {
        var arr = [];
        for (let i = 1; i <= 30; i++) {
            if (i == 1) {
                arr.push({
                    value: i,
                    label: i + " day"
                })
            }
            else {
                arr.push({
                    value: i,
                    label: i + " days"
                })
            }
        }
        return arr;
    }

    return <div id="divPaymentVoucherNotifications" className="new-main-content">
        <h3>Payment Vouchers</h3>
        <div className="marL20">
            <CheckBoxComponent
                id={"EnablePaymentVoucherReminder"}
                text={"Enable payment voucher reminders"}
                checked={enablePaymentVoucherReminder}
                onChange={(event: any) => { onPaymentVoucherReminderChange(event.target.checked) }}
                datatestAuto={"8BAE0132-3B3C-492A-8399-B26C6F7582E8"}
            />
            <div className="row marT20" style={{marginLeft: '0'}}>
                <div className="padT07 voucher-value">Set default reminder to</div>
                <div className="select-reminder-control">
                    <Select
                        name="form-field-name"
                        value={noOfDaysForVoucherReminder}
                        onChange={(event: any) => { onVoucherReminderDaysChange(event.value) }}
                        options={buildOptions()}
                        data-test-auto="52A69016-2B50-4B88-84B4-3E82ED02586A"
                        clearable={false}
                    />
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2 padT07">before due date</div>
            </div>
            <div className="marT20" style={{ display: "flex" }}>
                <CheckBoxComponent
                    id={"EnableVoucherPaymentNotification"}
                    text={"Receive an email notification when your client adds payment information"}
                    onChange={(event: any) => { onPaymentNotificationChange(event.target.checked) }}
                    checked={paymentNotification}
                />
                <Link to={'#'}
                    className="help-icon upload-help"
                    data-placement="right"
                    data-toggle="tooltip"
                    title={VoucherPaymentNotificationInfo}>
                    <i className="fas fa-question-circle" />
                </Link>
            </div>
        </div>
    </div>;
}

export default PaymentVoucherNotification;