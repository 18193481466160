import React from 'react'
import { useSelector } from 'react-redux'
import LoadingView from 'src/components/LoadingView';
import { ILogger } from 'src/Logger/Logger';
import { TelemetryLogger } from '../Logger/TelemetryLogger'

import { ApplicationState } from '../store'

export let logger: ILogger;
const LoggedIn: React.FC<{ children: React.ReactNode }> = ({ children }) => {

    const user = useSelector((state: ApplicationState) => state.auth.user)
    const loading = useSelector((state: ApplicationState) => state.rbacStore.extensionLoading)

    logger = user ? TelemetryLogger.getInstance() : null;
    return user ?
        <>{
            loading ?
                <LoadingView /> :
                children
        }</> :
        <></>
}

LoggedIn.displayName = 'LoggedIn'

export default LoggedIn
