import React from  'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { DocumentGroups, EngagementType } from '../../common/TaxReturn';
import { GroupTabConstants } from '../../helper/Constants';
import { confirm } from 'bootbox';


export interface IReGroupPageProps {
    selectedGroup: DocumentGroups[];
    engagementType: EngagementType;
    onRegroup: (destinationGroup: DocumentGroups) => void;
};
const NO_INDEX = -1;
export class ReGroupPage extends React.Component<IReGroupPageProps, {}> {

    constructor(props: IReGroupPageProps) {
        super(props);
    }

    private onDelete = () => {
        let pageThis = this;
        confirm({
            message: GroupTabConstants.Page.DeleteConfirm,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> OK',
                    className: 'btn-success'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    pageThis.props.onRegroup(DocumentGroups.Deleted);
                }
            }
        });
    }


    public render() {
        const { selectedGroup, onRegroup } = this.props;
        const engagementType = ((typeof this.props.engagementType == "number") ? EngagementType[this.props.engagementType] : this.props.engagementType);
        return (
            <div style={{ display: 'inline-block' }}>

                <DropdownButton 
                    variant={"warning"}
                    title="Move To"
                    id="move-to-dropdown" 
                    className="dropdown-arrow"
                    >

                    {selectedGroup.findIndex(x => x === DocumentGroups.Transmittals) == NO_INDEX &&
                        <Dropdown.Item data-test-auto="1F6591BB-D38D-466C-A27D-B27ED31EC0F8" eventKey="1"
                            style={{ fontSize: '14px' }} onClick={() => { onRegroup(DocumentGroups.Transmittals) }} href="#">
                        <i className='fas fa-file-alt ddl-icon'></i>Transmittals</Dropdown.Item>}
                     
                    {selectedGroup.findIndex(x => x === DocumentGroups.EFile) == NO_INDEX &&
                        <Dropdown.Item data-test-auto="58625E2A-F7D4-4CE5-BBA5-C4DA72C3E341" eventKey="3"
                            style={{ fontSize: '14px' }} onClick={() => { onRegroup(DocumentGroups.EFile) }} href="#">
                        <i className='fas fa-pen-nib ddl-icon'></i>Filing Form</Dropdown.Item>}
                    
                    {selectedGroup.findIndex(x => x === DocumentGroups.Deleted) == NO_INDEX &&
                        <Dropdown.Item data-test-auto="9554BC70-D712-4FB3-A5B4-D656EA3727B5" eventKey="7"
                            style={{ fontSize: '14px' }} onClick={() => { this.onDelete() }} href="#">
                        <i className='fas fa-trash ddl-icon'></i>Deleted</Dropdown.Item>}
                </DropdownButton> 

            </div>
        )
    }
};
//=============================================================================
