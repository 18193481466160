import React from 'react';
import { RadioButtonComponent } from '../../common/RadioButtonComponent';
import { ReturnDownloadOptionsConstants } from '../../helper/Constants';
import { ExtensionCompanySettings } from 'src/Core/ViewModels/Extension/ExtensionComponentModels';
import { TransmittalDownloadOption, VoucherDownloadOption } from 'src/Core/ViewModels/Company/CompanySettingsViewModel';

interface IReturnsDownloadOptionsProps {
    companySettings: ExtensionCompanySettings;
    handleIncludeInTaxReturnPDF(event: any): void;
    handleCreateSeperatePDF(event: any): void;
    handleSeperateByQuarterlyDueDates(event: any): void;
    handleGroupInSinglePDF(event: any): void;
}

export const ReturnsDownloadOptions: React.FC<IReturnsDownloadOptionsProps> = props => {

    return (
        <div id="divReturnsDownloadOptions" className="new-main-content">
            <h3>Download Options</h3>
            <div className="marL20">
                <div className="row div-height">
                    <div className="col-sm-3 col-md-3 col-lg-2">Transmittals:</div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                        <div id="divTransmittals" className="row">
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <RadioButtonComponent
                                    id={"IncludeInTheTaxReturnPdf"}
                                    text={ReturnDownloadOptionsConstants.IncludeInTaxReturnPdf}
                                    checked={props.companySettings.deliverySettingsModel.transmittalsDownloadOptions == TransmittalDownloadOption.IncludeTransmittalInTaxReturnPDF}
                                    onChange={props.handleIncludeInTaxReturnPDF}
                                    value={TransmittalDownloadOption.IncludeTransmittalInTaxReturnPDF}
                                    dataTestAuto={"93CF2B83-A6C3-4660-B4D5-5B9DC56E9447"}
                                />
                                
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <RadioButtonComponent
                                    id={"CreateSeperatePdf"}
                                    text={ReturnDownloadOptionsConstants.CreateSeperatePdf}
                                    checked={props.companySettings.deliverySettingsModel.transmittalsDownloadOptions == TransmittalDownloadOption.CreateSeparateTransmittalPDF}
                                    onChange={props.handleCreateSeperatePDF}
                                    value={TransmittalDownloadOption.CreateSeparateTransmittalPDF}
                                    dataTestAuto={"9D537B09-52B0-49DF-9FBD-B86B9575A99C"}
                                />
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-3 col-md-3 col-lg-2">Payment Vouchers:</div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                        <div id="divVouchers" className="row">
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <RadioButtonComponent
                                    id={"B51B7D87-A9B5-45EA-A9E6-E4923986ACC0"}
                                    text={ReturnDownloadOptionsConstants.SeparateByQuarterlyDueDates}
                                    checked={props.companySettings.deliverySettingsModel.vouchersDownloadOptions == VoucherDownloadOption.SeparateByQuarterlyDueDates}
                                    onChange={props.handleSeperateByQuarterlyDueDates}
                                    value={VoucherDownloadOption.SeparateByQuarterlyDueDates}
                                    dataTestAuto={"62FC3332-678C-47EF-8B98-B0AA47E32390"}
                                />
                                
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <RadioButtonComponent
                                    id={"C6B87B46-591F-493A-9FBE-709639042826"}
                                    text={ReturnDownloadOptionsConstants.GroupInSinglePDF}
                                    checked={props.companySettings.deliverySettingsModel.vouchersDownloadOptions == VoucherDownloadOption.GroupInSinglePDF}
                                    onChange={props.handleGroupInSinglePDF}
                                    value={VoucherDownloadOption.GroupInSinglePDF}
                                    dataTestAuto={"CBA2F511-0622-4AA2-B724-65787AE898CE"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
