import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { CheckBoxComponent } from '../../common/CheckBoxComponent';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'src/store';
import { VoucherPaymentNotificationInfo } from 'src/components/helper/Constants';

interface MyNotificationsProps {
    onPaymentNotificationChange: (notification: boolean) => void;
    paymentNotification: boolean;
}

export const MyNotifications: React.FC<MyNotificationsProps> = ({
    onPaymentNotificationChange,
    paymentNotification
}) => {

    const companyPaymentNotificationEnabled = useSelector((state: ApplicationState) =>
        state.extensionCompanyData.companySettings.notificationSettingsModel.paymentVoucherNotificationSettings.paymentNotification);

    return <Accordion defaultActiveKey="0">
        <Card id="collapsible-panel-my-notifications" className="marB20">
            <Accordion.Toggle eventKey="0" as={Card.Header} variant="link">
                <Card.Title>
                    <i className='fa fa-bell'></i>My Notifications
                </Card.Title>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
                <Card.Body>
                    <div className="marB10">
                        <span>Notifications I Receive:</span>
                        <a href="#" className="help-circle" data-toggle="tooltip" title="Messages...">
                            <i className="fa fa-question my-notification-help"></i>
                        </a>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-5 col-xl-5">
                            <div className="marB10">
                                <div style={{ display: "flex" }}>
                                    <CheckBoxComponent
                                        id={"UserPaymentNotification"}
                                        text={"When client adds payment information"}
                                        onChange={(e: any) => onPaymentNotificationChange(e.target.checked)}
                                        checked={paymentNotification}
                                        disabled={!companyPaymentNotificationEnabled}
                                    />
                                    <a href="#" className="help-icon upload-help"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title={VoucherPaymentNotificationInfo}>
                                        <i className="fas fa-question-circle" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    </Accordion>;
}