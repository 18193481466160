import React from 'react';
import { RouteComponentProps } from 'react-router';
import 'isomorphic-fetch';
import { DeliveredReturnsHeader } from './DeliveredReturns/DeliveredReturnsHeader';
import { DeliveredReturnsTable } from './DeliveredReturns/DeliveredReturnsTable';
import { ReportProblemModal } from '../common/ReportProblemModal';
import { CustomColumn } from './DeliveredReturns/CustomColumn';
import { IColumnValues } from '../settings/GeneralSettings';
import { ReportChangeStatusModal } from '../common/ReportsChangeStatusModal';
import { ResendAccessLinkModal } from '../common/ResendAccessLinkModal';
import { IFilters, SortDirections, ReportFilterType } from './Filters';
import { VenusNotifier } from '../helper/VenusNotifier';
import * as Helper from '../helper/HelperFunctions';
import {
    DeliveredReturnsConstants,
    GroupTabConstants,
    ArchiveReturnsConstants,
    Common,
    ReportFilterConstants,
    clearedFilter,
} from '../helper/Constants';
import * as bootbox from 'bootbox';
import { IDeliveredTaxDocument, IScheduleReminderPayload, IVoucherStatus } from '../common/DeliveredTaxReturns';
import {
    SignatureStatus,
    ClientTypes,
    SignatureType,
    ITaxReturn,
    IDocumentAccess,
    ISignerModel,
    DocumentGroups,
    isIndividual,
    isMutual,
    isPartnership,
    DocumentStatus,
    getUnformattedClientName,
    DeliveryMode,
    PaperReturnDeliveryType,
    initialTaxReturnState,
    initialClientInfoViewModel,
    IClientInfoViewModel,
    ClientInfoWithEroCheck,
    IDocumentAccessSaveModel,
} from '../common/TaxReturn';
import { DownloadReturns } from '../common/DownloadReturns';
import { DownloadEfileForm } from '../common/DownloadEfileForms';
import { ClientTrackingModal } from '../common/ClientTracking';
import { DownloadHistory } from '../common/DownloadHistory';
import { UploadForms } from '../common/UploadForms';
import { VoucherReminder } from './DeliveredReturns/VoucherReminder';
import { SignedDetails } from '../common/SignedDetails';
import { SetAccessModal } from '../common/SetAccessModal';
import { ViewAccessCode } from '../common/ViewAccessCodeModel/ViewAccessCode';
import { RetentionComponent } from '../common/RetentionComponent';
import { ClientInfo } from '../common/ClientInfo';
import * as DeliveredReturnsStore from '../../store/reports/DeliveredReturnsStore';
import * as FilterStore from '../../store/reports/FilterStore';
import * as TaxDocumentStore from '../../store/common/TaxDocumentStore';
import * as OnehubStore from '../../store/common/OnehubStore';
import * as DeliveredReturnsState from '../../store/reports/DeliveredReturnsState';
import * as UserStore from '../../store/userManagement/UserStore';
import * as UserSettingStore from '../../store/userManagement/UserSettingStore';
import * as CustomColumnStore from '../../store/common/CustomColumnStore';
import * as CompanyStore from '../../store/company/CompanyStore';
import * as ReminderStore from '../../store/common/ReminderStore';
import { ShowLoader, HideLoader } from '../helper/Loader';
import * as UploadTaxReturnStore from '../../store/common/uploadTaxReturn';
import * as AttachmentsStore from '../../store/ProcessReturn/AttachmentsStore';
import * as AttachmentsState from '../../store/ProcessReturn/AttachmentsState';
import { DeleteTaxReturnConstants } from '../helper/Constants';
import { BulkOperationQueryExt } from '../../Core/ViewModels/Common/BulkOperationQuery';
import * as BulkOperationsStore from '../../store/common/BulkOperationsStore';
import * as ColumnSettingsStore from './../../store/reports/ColumnSettings/ColumnSettingsStore';
import { IColumnSettingsState, ReportType } from './../../store/reports/ColumnSettings/ColumnSettingsState';
import { initialCompanySettings } from '../../Core/ViewModels/Company/CompanySettingsViewModel';
import * as AdditionalEsignDocumentStore from '../../store/ProcessReturn/AdditionalEsignDocuments/AdditionalEsignDocumentStore';
import { IDocumentData } from '../../components/common/ProcessReturnModal/AdditionalEsignDocuments/AdditionalEsignDocument';
import { ScreenShare } from '../common/ScreenShare';
import { IReportProblemDetails, ReportedStep, ReportProblemTypes } from '../common/ReportProblem/ReportProblemModel';
import { IUserProfile } from '../navigation/profile/ProfileObjects';
import * as GroupedExtensionsStore from '../../store/groupedExtensions/GroupedExtensionsStore';
import { GroupedExtensionStoreState } from '../../Core/ViewModels/GroupExtensions/StoreModels';
import { logClientEvent, logger } from '../helper/LoggerHelper';
import { DownloadTypeOption } from './DownloadTypeOption';
import * as ExtensionUserSettingsStore from '../../store/userManagement/ExtensionUserSettingStore';
import { RBACKeys } from 'src/components/helper/RbacConstants';
import ReminderModal from '../common/SendReminder/ReminderModal';
import { DefaultDownloadInfoType } from '../../store/userManagement/ExtensionUserSettingStore';
import { isInValidSignatureStatus } from '../helper/HelperFunctions';
import * as TaxSoftwareStore from '../../store/SuiteCompany/TaxSoftwareStore';
import { SuiteTaxSoftwareSettingsStoreState } from 'src/Core/ViewModels/Company/SuiteCompanySettings/SuiteTaxSoftwareStoreModels';
import { ExtensionCompanySettings } from 'src/Core/ViewModels/Extension/ExtensionComponentModels';
import * as ExtensionCompanySettingsStore from '../../store/settings/ExtensionCompanySettingsStore';
import MismatchModal from '../common/MisMatchModal/MismatchModal';
import {
    IClientInfoComparisonResult,
    IClientInfoMismatchType,
    getNewClientInfo,
    initialClientInfoComparisonResult,
    setNewClientInfoComparisonResult,
} from '../common/MisMatchModal/MismatchModal.model';
import {
    addNewClientInfo,
    compareClientInfo,
    editClientInfo,
    getClientInfoByClientId,
} from '../../store/MisMatchModel/MismatchModal.Api';
import { LogEventConstants } from '../helper/LogEventConstants';
import { IOfficeLocationState } from 'src/store/common/OfficeLocationStore';
import { IUserOfficeLocationState } from 'src/store/common/UserOfficeLoacationStore';
import * as OfficeLocationStore from '../../store/common/OfficeLocationStore';
import * as UserOfficeLocationStore from '../../store/common/UserOfficeLoacationStore';
import LocationMismatchActionModal from '../common/LocationMismatchModal/LocationMimatchActionModal';
import SetAccessCommonModal from '../common/SetAccess/SetAccessCommonModal';
import {
    IAccessingUsersAndUserGroups,
    IUserDataFromDB,
    IUserGroupDataFromDB,
} from '../common/SetAccess/SetAccessCommonModal.model';
import { IOnehubAccessCodeModel } from '../common/OnehubAccessCodeModal';
import UnlockReturnModal from '../common/UnlockReturnModal';

let moment = require('moment');
const pageSize: number = 20;
const NO_INDEX = -1;

export interface INameValue {
    [index: string]: string;
}

const successMsg: INameValue = {
    editClientInfoState: 'Successfully updated client info for the tax-return extension',
    changeStatusState: 'Status has been successfully changed for the Tax Return Extensions',
    reportProblemState: 'Problem on the tax-return extension is successfully reported',
    deleteReturnsState: 'Successfully deleted the selected extension(s)!',
    assignReturnsState: 'Successfully ssigned the document(s) to selected user',
    setAccessState: 'Document Access set successfully for all extensions',
    archiveReturnsState: 'Successfully archived the selected extension(s)!',
    customColumnState: 'Custom column Value(s) has been successfully updated for the Tax Return Extensions',
    customColumnRemoveState: 'Custom column value(s) has been successfully removed for the Tax Return Extensions',
    resendAccessLinkState: 'Access Link has been sent successfully for the selected Tax Return Extensions',
    recallReturnState: 'Extension Recalled successfully.',
    reprocessReturnState: 'Extension Reprocessed successfully.',
    voucherReminderState: 'Successfully updated reminder for the tax-return extension',
    accessCodeState: 'Access Code generated successfully',
    retentionPolicyState: 'Retention policy updated successfully',
    uploadFormsState: 'Successfully uploaded the form for the tax-return extension',
    changeStatusConfirmation: 'You are about to change the status of the document manually. Do you want to proceed?',
    restoreReturnsState: 'Successfully restored the selected extension(s)!',
};

const errorMsg: INameValue = {
    editClientInfoState: 'ClientInfo Edit failed! Error returned from server while updating the client info!',
    changeStatusState: 'Failed to update the changed status!',
    reportProblemState: 'Failed to report the problem!',
    deleteReturnsState: 'Failed to delete the selected document(s)!',
    assignReturnsState: 'Failed to assign the document(s) to user!',
    setAccessState: 'Setting document access for the users failed!',
    archiveReturnsState: 'Failed to archive the selected document(s)!',
    customColumnState: 'Failed to update the Custom column!',
    sendReminderState: 'Failed to send reminder for the selected document(s)!',
    resendAccessLinkState: 'Failed to resend access link for the selected document(s)!',
    recallReturnState: 'Failed to recall the extension!',
    reprocessReturnState: 'Failed to reprocess the extension!',
    voucherReminderState: 'Failed to update reminder for the tax-return extension!s',
    accessCodeState: 'Failed to generate access code for the tax-return extension',
    retentionPolicyState: 'Failed to update retention policy for the tax-return extension',
    uploadFormsState: 'Failed to upload the form for the tax-return extension',
    restoreReturnsState: 'Failed to restore the selected document(s)!',
};

const BulkOperationEndPoint = {
    UpdateCustomColumn: 'SaveCustomColumnAsync',
    RemoveCustomColumn: 'RemoveCustomColumnAsync',
};

type DeliveredReturnsProps = {
    delivered: DeliveredReturnsState.DeliveredReturnsState;
    taxDocuments: TaxDocumentStore.ITaxDocumentDictionary;
    usersStore: UserStore.UsersStore;
    reportFilters: FilterStore.IFilterState;
    userSettings: UserSettingStore.UserSettings;
    customColumnData: CustomColumnStore.ICustomColumnState;
    company: CompanyStore.ICompanyData;
    reminderData: ReminderStore.IReminderData;
    uploadTaxReturnStore: UploadTaxReturnStore.IUploadTaxReturnState;
    attachments: AttachmentsState.AttachmentsState;
    nextSignerDetails: ISignerModel;
    isArchived: boolean;
    pageTitle: string;
    columnSettings: IColumnSettingsState;
    userProfile: IUserProfile;
    groupedExtensionsStore: GroupedExtensionStoreState;
    extensionUserSettings: ExtensionUserSettingsStore.IExtensionUserSettings;
    taxSoftwareData: SuiteTaxSoftwareSettingsStoreState;
    extensionCompanyData: ExtensionCompanySettings;
    officeLocation: IOfficeLocationState;
    userOfficeLocation: IUserOfficeLocationState;
} & typeof DeliveredReturnsStore.actionCreators &
    typeof TaxDocumentStore.actionCreators &
    typeof OnehubStore.actionCreators &
    typeof UserStore.actionCreators &
    typeof FilterStore.actionCreators &
    typeof UserSettingStore.actionCreators &
    typeof CustomColumnStore.actionCreators &
    typeof CompanyStore.actionCreators &
    typeof ReminderStore.actionCreators &
    typeof UploadTaxReturnStore.actionCreators &
    typeof AttachmentsStore.actionCreators &
    typeof BulkOperationsStore.actionCreators &
    typeof ColumnSettingsStore.actionCreator &
    typeof AdditionalEsignDocumentStore.actionCreators &
    typeof GroupedExtensionsStore.actionCreators &
    typeof ExtensionUserSettingsStore.actionCreators &
    typeof TaxSoftwareStore.actionCreators &
    typeof ExtensionCompanySettingsStore.ActionCreators &
    typeof OfficeLocationStore.actionCreators &
    typeof UserOfficeLocationStore.actionCreators &
    RouteComponentProps<{ page: string }>;

export class DeliveredReturns extends React.Component<
    DeliveredReturnsProps,
    DeliveredReturnsState.DeliveredReturnsPageState
> {
    private proxyFilter: any;
    private reportFilterType: any;
    private ssShow = false;
    private ssClientId: string;
    private pageTitle: string = this.props.isArchived ? 'ArchivedReport' : 'DeliveredReport';
    private pageId = this.props.isArchived ? 'ArchivedExtension' : 'ExtensionReport';
    private editClientInfoResourceId: string = this.props.isArchived
        ? RBACKeys.ArchivedExtension.editClientInfo
        : RBACKeys.ExtensionReport.editClientInfo;
    constructor(props: any) {
        super(props);
        this.state = {
            page: 1,
            pageSize: pageSize,
            filter: { ...clearedFilter, fields: { taxYear: (new Date().getFullYear() - 1).toString() } },
            selectedRows: [],
            searchString: '',
            sortName: '',
            sortOrder: 'desc',
            filterTaxYear: new Date().getFullYear() - 1,
            saveFilterShow: false,
            filterClientId: '',
            filterCustomColumn: '',
            filterDeliveredOn: undefined,
            filterDownloadedBy: '',
            filterEngagementType: 0,
            filterLastReminderOn: undefined,
            filterName: '',
            filterPartner: '',
            filterSentBy: '',
            filterStatus: '',
            filterLocation: '',
            hideCustomCulumnLoader: false,
            filterReturnStatus: '',

            signatureStatusList: [],
            selectedStatus: 0,
            selectedColumnValue: 0,

            isBulkSelectionEnabled: false,
            showBulkSelectionMessage: false,
            deSelectedRows: [],
            columnSettings: [],
            isSelectAllChecked: false,
            screenShareOtp: '',
            bulkOpnQuery: {
                sortBy: '',
                sortOrder: '',
                searchText: '',
                assignedTo: '',
                clientId: '',
                customColumn: '',
                engagementType: 0,
                downloadedBy: '',
                isArchived: false,
                name: '',
                partner: '',
                reportType: ReportFilterType.SendExtension,
                sentBy: '',
                status: '',
                taxYear: '',
                unSelectedIds: [],
                documentFilingType: 0,
                quickReportStatus: '',
                groupName: '',
                taxSoftware: '',
                createdBy: '',
                globalSearch: '',
                setAccess: 'None',
                returnStatus: '',
                officeLocation: '',
                isDownloadNow: false,
            },
            downloadTypeOptionState: {
                saveCheckIds: [],
                selected: [],
                show: false,
                remove: false,
            },

            //SetAccess States
            setAccessState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false,
            },

            //EditClientInfo states
            editClientInfoState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },

            //Report a Problem states
            reportProblemState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },

            //Delete Action states
            deleteReturnsState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },

            //Archive Action states
            archiveReturnsState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false,
            },

            //ChangeStatus states
            changeStatusState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false,
            },
            showLoader: false,
            //CustomColumn states
            customColumnState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false,
            },

            //SendRemider states
            sendReminderState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false,
            },

            //Resend Access Link states
            resendAccessLinkState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },

            //Client Tracking states
            clientTrackingState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },

            //Unlock Return State
            unlockReturnState: {
                show: false,
                documentId: 0,
                unlockReturnModelList: [],
            },

            //Recall Return states
            recallReturnState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },
            //Reprocess Return states
            reprocessReturnState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },

            //Voucher Reminder states
            voucherReminderState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },

            //Access Code states
            accessCodeState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },

            onehubAccessCodeState: {
                model: undefined,
            },

            //Retention Policy states
            retentionPolicyState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },

            //Download Return State
            downloadReturnState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },

            //Download Efile Forms State
            downloadEformFilesState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },

            //Upload Forms State
            uploadFormsState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },

            //Signed Details State
            signedDetailsState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },

            //Download History State
            downloadHistoryState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },

            //Client View State
            clientViewState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },

            //Restore Action states
            restoreReturnsState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined,
                remove: false,
            },

            //Screen Share State
            screenShareState: {
                model: undefined,
                show: false,
                saveCheckId: undefined,
            },
            refreshDelay: false,
            defaultGroup: '',

            selectedDownloadType: DefaultDownloadInfoType.PromptMeEveryTime,
            loadSpinner: false,
            selectedDefaultDownloadType: false,
            selectedDocuments: [],
            isFromGrid: false,
            selectedVoucherStatus: undefined,

            showMismatchModal: false,
            clientInfoComparisonResult: initialClientInfoComparisonResult,
            popupInProgress: false,
            clientInfo: initialClientInfoViewModel,
            clientBasicInfoId: 0,
            editClientSaveState: DeliveredReturnsState.intialEditClientSaveState,

            showLocationMismatchActionModel: false,
            loadingMessage: false,

            availableUsers: [],
            selectedUsers: [],
            availableUserGroups: [],
            selectedUserGroups: [],
        };
        this.ssClientId = '';

        this.onPageReload = this.onPageReload.bind(this);

        this.applyDefaultFilter = this.applyDefaultFilter.bind(this);
        this.onFilterSave = this.onFilterSave.bind(this);
        this.onFilterUpdate = this.onFilterUpdate.bind(this);
        this.onFilterDelete = this.onFilterDelete.bind(this);
        this.onSetDefaultFilter = this.onSetDefaultFilter.bind(this);
        this.onSetDefaultFilterSuccess = this.onSetDefaultFilterSuccess.bind(this);
        this.onRemoveDefaultFilter = this.onRemoveDefaultFilter.bind(this);
        this.onRemoveDefaultFilterSuccess = this.onRemoveDefaultFilterSuccess.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onSaveFilterShow = this.onSaveFilterShow.bind(this);
        this.onSaveFilterHide = this.onSaveFilterHide.bind(this);
        this.onRequestUserSettingsSuccess = this.onRequestUserSettingsSuccess.bind(this);
        //

        this.onTaxpayerView = this.onTaxpayerView.bind(this);
        this.onRemoveReport = this.onRemoveReport.bind(this);

        //Grid Operation Handlers
        this.onRowSelect = this.onRowSelect.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onFilterNameChange = this.onFilterNameChange.bind(this);

        //EditClientInfo Event Handlers
        this.onEditClientInfoOpen = this.onEditClientInfoOpen.bind(this);
        this.onEditClientInfoCancel = this.onEditClientInfoCancel.bind(this);
        this.onEditClientInfoSave = this.onEditClientInfoSave.bind(this);
        this.sendMail = this.sendMail.bind(this);

        this.onSetAccessOpen = this.onSetAccessOpen.bind(this);
        this.onSetAccessSave = this.onSetAccessSave.bind(this);
        this.onSetAccessCancel = this.onSetAccessCancel.bind(this);

        this.onReportProblemOpen = this.onReportProblemOpen.bind(this);
        this.onReportProblemCancel = this.onReportProblemCancel.bind(this);
        this.onReportProblemSave = this.onReportProblemSave.bind(this);

        this.onArchiveReturnsOpen = this.onArchiveReturnsOpen.bind(this);
        this.onArchiveReturnsSave = this.onArchiveReturnsSave.bind(this);

        this.onChangeStatusOpen = this.onChangeStatusOpen.bind(this);
        this.onChangeStatusSave = this.onChangeStatusSave.bind(this);
        this.onChangeStatusCancel = this.onChangeStatusCancel.bind(this);

        this.onCustomColumnOpen = this.onCustomColumnOpen.bind(this);
        this.getSelectedCustomColumnValue = this.getSelectedCustomColumnValue.bind(this);
        this.onCustomColumnDropdownChange = this.onCustomColumnDropdownChange.bind(this);
        this.onCustomColumnSave = this.onCustomColumnSave.bind(this);
        this.onCustomColumnRemove = this.onCustomColumnRemove.bind(this);
        this.onCustomColumnCancel = this.onCustomColumnCancel.bind(this);

        this.OnSendReminder = this.OnSendReminder.bind(this);

        this.onResendAccessLinkOpen = this.onResendAccessLinkOpen.bind(this);
        this.onResendAccessLink = this.onResendAccessLink.bind(this);
        this.onResendAccessLinkCancel = this.onResendAccessLinkCancel.bind(this);

        this.onClientTrackingOpen = this.onClientTrackingOpen.bind(this);
        this.onClientTrackingCancel = this.onClientTrackingCancel.bind(this);

        this.onRecallReturnOpen = this.onRecallReturnOpen.bind(this);
        this.onRecallReturnSave = this.onRecallReturnSave.bind(this);

        this.onIndividualReminderOpen = this.onIndividualReminderOpen.bind(this);

        this.onViewAccessCodeOpen = this.onViewAccessCodeOpen.bind(this);
        this.onViewAccessCodeCancel = this.onViewAccessCodeCancel.bind(this);
        this.onGenerateOTP = this.onGenerateOTP.bind(this);
        this.onGenerateOnehubAccessCode = this.onGenerateOnehubAccessCode.bind(this);

        this.onRetentionPolicyOpen = this.onRetentionPolicyOpen.bind(this);
        this.onRetentionPolicySave = this.onRetentionPolicySave.bind(this);
        this.onRetentionPolicyCancel = this.onRetentionPolicyCancel.bind(this);

        this.onDownloadReturnOpen = this.onDownloadReturnOpen.bind(this);
        this.onDownloadReturnCancel = this.onDownloadReturnCancel.bind(this);
        this.onDownloadReturnAll = this.onDownloadReturnAll.bind(this);
        this.onDownloadFile = this.onDownloadFile.bind(this);

        this.onDownloadEfileFormsOpen = this.onDownloadEfileFormsOpen.bind(this);
        this.onDownloadEfileFormsCancel = this.onDownloadEfileFormsCancel.bind(this);
        this.onMultipleDownloadEfileForms = this.onMultipleDownloadEfileForms.bind(this);

        this.onUploadFormsOpen = this.onUploadFormsOpen.bind(this);
        this.onUploadFormsCancel = this.onUploadFormsCancel.bind(this);
        this.onUploadFormsSuccess = this.onUploadFormsSuccess.bind(this);

        this.onSignedDetailsOpen = this.onSignedDetailsOpen.bind(this);
        this.onSignedDetailsCancel = this.onSignedDetailsCancel.bind(this);

        this.onDownloadHistoryOpen = this.onDownloadHistoryOpen.bind(this);
        this.onDownloadHistoryCancel = this.onDownloadHistoryCancel.bind(this);

        this.onTaxPayerViewClick = this.onTaxPayerViewClick.bind(this);
        this.onTaxPayerViewLinkClick = this.onTaxPayerViewLinkClick.bind(this);

        this.onDeleteTaxReturnOpen = this.onDeleteTaxReturnOpen.bind(this);
        this.onDeleteTaxReturnSave = this.onDeleteTaxReturnSave.bind(this);
        this.onDeleteCompletion = this.onDeleteCompletion.bind(this);

        this.onScreenShareViewClick = this.onScreenShareViewClick.bind(this);
        this.onScreenShareOpen = this.onScreenShareOpen.bind(this);
        this.onScreenShareCancel = this.onScreenShareCancel.bind(this);
        this.onScreenShareStart = this.onScreenShareStart.bind(this);

        //Support Methods
        this.selectedDocumentIds = this.selectedDocumentIds.bind(this);
        this.selectedDocumentIdsAndLoadingStatus = this.selectedDocumentIdsAndLoadingStatus.bind(this);
        this.selectedDocuments = this.selectedDocuments.bind(this);
        this.lazyLoadSelected = this.lazyLoadSelected.bind(this);
        this.updateStates = this.updateStates.bind(this);
        this.getStoreItem = this.getStoreItem.bind(this);
        this.onUpdateCompletion = this.onUpdateCompletion.bind(this);
        this.onUpdateCompletionMultiple = this.onUpdateCompletionMultiple.bind(this);
        this.checkForPopupCompletion = this.checkForPopupCompletion.bind(this);
        this.buildQuery = this.buildQuery.bind(this);
        this.fetchDeliveredReturns = this.fetchDeliveredReturns.bind(this);
        this.loadDeliveredReturns = this.loadDeliveredReturns.bind(this);
        this.getSignedDetails = this.getSignedDetails.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.initData();
        this.props.requestAllUsers(true);
        this.props.requestAllPartners(true);
        this.props.requestUserSettings(true, this.onRequestUserSettingsSuccess);
        this.props.requestCustomColumn(true);
        this.props.getAllReportFilter(true, this.reportFilterType);
        this.props.requestDeliveredReturns(
            this.buildQuery(this.state.page, pageSize),
            true,
            undefined,
            this.state.page > 1 ? this.reloadOnNoDataFound : undefined
        );
        this.props.requestTaxingAuthorities();
        this.props.requestColumnSettings(ReportType.Delivered);
        this.props.requestColumnSettings(ReportType.Archived);
        if (this.props.company.isDocumentStoreDirty) {
            this.props.reloadDeliveredReturns();
            this.props.forceRefreshTaxDcoumentStore(false);
            this.onPageReload();
        }
        this.props.requestCompanySettings(false);
        this.props.requestGroupConfiguration(true);
        this.props.requestGroupAccess(true);
        this.props.requestExtensionUserSettings(true);
        //this.props.requestSuiteTaxSoftwareSettings();
    }
    UNSAFE_componentWillReceiveProps(nextProps: DeliveredReturnsProps) {
        this.initData();
        this.updateStates(nextProps);
        this.checkForPopupCompletion(nextProps.taxDocuments);
        nextProps.userSettings.settings &&
            nextProps.userSettings.settings.defaultSettings &&
            nextProps.extensionUserSettings.defaultSettings.download &&
            this.setState({
                selectedDownloadType: nextProps.extensionUserSettings.defaultSettings.download.downloadType,
            });
    }

    onRequestUserSettingsSuccess() {
        if (!this.props.userSettings.settings.defaultSettings.reportFilter) {
            this.loadDeliveredReturns();
        }
    }

    initData = () => {
        this.proxyFilter =
            ReportFilterType[
                this.props.isArchived ? ReportFilterType.ArchivedReport : ReportFilterType.DeliveredReport
            ].toUpperCase() + ReportFilterConstants.DefaultFilterBuilder;

        this.reportFilterType = this.props.isArchived
            ? ReportFilterType.ArchivedReport
            : ReportFilterType.DeliveredReport;
    };

    onTaxpayerView(rowIndex: number) {
        let id = this.props.delivered.deliveredReturnTableModel.documents[rowIndex].document.id;
        this.props.requestTaxDocument(id, true, true);
    }

    onRemoveReport() {}

    getBulkOperationQuery = (): BulkOperationQueryExt => {
        let query: BulkOperationQueryExt = {
            sortBy: this.state.sortName,
            sortOrder: this.state.sortOrder,
            searchText: this.state.searchString,
            clientId: this.state.filterClientId,
            customColumn: this.state.filterCustomColumn,
            downloadedBy: this.state.filterDownloadedBy,
            lastReminderOn: this.state.filterLastReminderOn,
            name: this.state.filterName,
            partner: this.state.filterPartner,
            retentionPeriod: this.state.filterRetentionPeriod,
            deliveredOn: this.state.filterDeliveredOn,
            engagementType: this.state.filterEngagementType,
            sentBy: this.state.filterSentBy,
            status: this.state.filterStatus === '-1' ? '' : this.state.filterStatus,
            officeLocation: this.state.filterLocation === '-1' ? '' : this.state.filterLocation,
            isArchived: this.props.isArchived,
            taxYear: this.state.filterTaxYear === '-1' ? '' : this.state.filterTaxYear,
            unSelectedIds: this.state.deSelectedRows,
            assignedTo: '',
            reportType: this.props.isArchived ? ReportFilterType.ArchivedReport : ReportFilterType.DeliveredReport,
            documentFilingType: 0,
            quickReportStatus: '',
            groupName: '',
            taxSoftware: '',
            createdBy: '',
            globalSearch: '',
            setAccess: 'None',
            returnStatus: this.state.filterReturnStatus,
            isDownloadNow: false,
        };

        return query;
    };

    //======================Grid Operation Starts here===============================

    onExportToExcel = (onExportToExcelComplete: () => void) => {
        let downloadType = this.state.selectedDownloadType || DefaultDownloadInfoType.PromptMeEveryTime;
        if (downloadType == DefaultDownloadInfoType.DownloadNow) {
            let queryString = this.buildQuery(this.state.page, pageSize);
            this.props.exportDeliveredReturnsAsExcel(
                queryString,
                onExportToExcelComplete,
                RBACKeys[this.pageId].exportButton
            );
        } else if (this.state.selectedDownloadType == DefaultDownloadInfoType.DownloadToMyDownloads) {
            this.props.exportToExcelBulk(
                this.getBulkOperationQuery(),
                onExportToExcelComplete,
                RBACKeys[this.pageId].exportButton
            );
        } else {
            onExportToExcelComplete();
            this.setState({
                downloadTypeOptionState: {
                    show: true,
                    selected: undefined,
                    saveCheckIds: undefined,
                    remove: false,
                },
                selectedDownloadType: DefaultDownloadInfoType.DownloadToMyDownloads,
                loadSpinner: true,
                selectedDefaultDownloadType: false,
            });
        }
    };

    private onRowSelect(row: any, isSelected: any, e: any) {
        const newList = [...this.state.selectedRows];
        const deSelectedRows = this.state.deSelectedRows;

        if (
            e.target.tagName !== "svg" &&
            e.target.tagName !== "path" &&
            e.target.tagName !== 'BUTTON' &&
            e.target.tagName !== 'I' &&
            e.target.tagName !== 'SPAN' &&
            e.target.tagName !== 'A'
        ) {
            if (isSelected) {
                newList.push(row.rowIndex);
                if (this.state.isBulkSelectionEnabled) {
                    index = deSelectedRows.indexOf(row.id);
                    deSelectedRows.splice(index, 1);
                }
            } else {
                var index = newList.indexOf(row.rowIndex);
                if (index > -1) {
                    newList.splice(index, 1);
                }
                if (this.state.isBulkSelectionEnabled) {
                    deSelectedRows.push(row.id);
                }
            }
            this.state.isBulkSelectionEnabled
                ? this.setState({ deSelectedRows: deSelectedRows, selectedRows: newList })
                : this.setState({ selectedRows: newList });
        } else {
            return false;
        }
        this.forceUpdate();
    }

    private onSelectAll(isSelected: any, rows: any, e: any) {
        let selectedRows: number[] = [];
        let showBulkSelectionMessage = false;

        if (isSelected) {
            let count = rows && rows.length ? rows.length : 0;
            Array.from(Array(count).keys());
            selectedRows = Array.from(Array(count).keys());

            showBulkSelectionMessage = true;
        }

        this.setState({ isSelectAllChecked: isSelected });

        this.setState({
            selectedRows: selectedRows,
            deSelectedRows: [],
            showBulkSelectionMessage: showBulkSelectionMessage,
            isBulkSelectionEnabled: false,
        });
    }

    private onPageChange(page: number, sizePerPage: number) {
        if (this.state.isBulkSelectionEnabled) {
            this.setState(
                {
                    page: page,
                },
                this.loadDeliveredReturns
            );
        } else {
            this.setState(
                {
                    page: page,
                    selectedRows: [],
                    showBulkSelectionMessage: false,
                },
                this.loadDeliveredReturns
            );
        }
    }

    private onSortChange(sortName: string, sortOrder: string, column: number) {
        let temp: IFilters = { ...this.state.filter };
        temp.sort.column = sortName;
        temp.sort.direction = sortOrder == 'asc' ? SortDirections.Ascending : SortDirections.Descending;
        Helper.SessionStore.set(this.proxyFilter, JSON.stringify(temp));

        this.setState(
            {
                filter: temp,
                sortName: sortName,
                sortOrder: sortOrder == 'asc' ? 'asc' : 'desc',
            },
            () => this.fetchDeliveredReturns(1, pageSize)
        );
    }

    private onSearchChange(searchString: string) {
        let temp: IFilters = { ...this.state.filter };
        temp.searchText = searchString;
        this.clearSelection();
        Helper.SessionStore.set(this.proxyFilter, JSON.stringify(temp));
        this.setState(
            {
                searchString: searchString,
                filter: temp,
            },
            () => this.fetchDeliveredReturns(1, pageSize)
        );
    }

    private onFilterNameChange(event: any) {
        let temp: IFilters = { ...this.state.filter };
        temp.name = event.target.value;
        this.setState({
            filter: temp,
        });
    }

    private onPageReload() {
        this.setState({ refreshDelay: true }, () => {
            let query = this.buildQuery(this.state.page, this.state.pageSize);
            this.props.requestDeliveredReturns(
                query,
                true,
                () => {
                    this.setState({ refreshDelay: false });
                },
                this.state.page > 1 ? this.reloadOnNoDataFound : undefined
            );
            this.clearSelection();
        });
        logClientEvent(`${LogEventConstants.Common.RefreshExtensions} ${this.pageTitle}`, {
            count: 1,
            page: this.pageTitle,
        });
    }

    reloadOnNoDataFound = () => {
        this.setState(
            prevState => ({ page: prevState.page - 1 }),
            () => {
                this.onPageReload();
            }
        );
    };

    private onBulkSelectionChange = (isEnabled: boolean) => {
        let showBulkSelectionMessage = false;

        this.setState({
            isBulkSelectionEnabled: isEnabled,
            showBulkSelectionMessage: showBulkSelectionMessage,
        });
    };

    private clearSelection = () => {
        this.setState({
            selectedRows: [],
            deSelectedRows: [],
            isBulkSelectionEnabled: false,
            showBulkSelectionMessage: false,
        });
    };

    //======================Grid Operation Ends here=================================

    //======================Filter Operation Starts here=================================

    private applyDefaultFilter(filter: IFilters) {
        this.setState({ filter: filter }, () => this.fetchDeliveredReturns(1, pageSize));
    }

    onClearFilter = () => {
        this.setState({ filter: clearedFilter });
    };

    private onFilterChange(dataField: any, filterType: ReportFilterType) {
        let newState: DeliveredReturnsState.DeliveredReturnsPageState = {
            filterName: '',
            filterClientId: '',
            filterPartner: '',
            filterSentBy: '',
            filterDeliveredOn: undefined,
            filterLastReminderOn: undefined,
            filterRetentionPeriod: undefined,
            filterEngagementType: 0,
            filterDownloadedBy: '',
            filterCustomColumn: '',
            filterReturnStatus: '',
        } as DeliveredReturnsState.DeliveredReturnsPageState;
        let filterStatus: any;
        let filterTaxYear: any;
        let filterLocation: any;
        let filterReturnStatus: any;
        let isClearFilter = true;
        var dict: { [columnName: string]: string } = { ...this.state.filter.fields };

        for (let field of Object.keys(dataField)) {
            var data = dataField[field.valueOf()].value ? dataField[field.valueOf()].value : dataField[field.valueOf()];
            if (isClearFilter && data !== '' && data !== '-1') {
                isClearFilter = false;
            }
            dict[field.valueOf().toString()] = data === '-1' ? '' : data;

            switch (field) {
                case 'taxDocumentName':
                    newState.filterName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'clientId':
                    newState.filterClientId = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'partner':
                    newState.filterPartner = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'deliveredBy':
                    newState.filterSentBy = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'status':
                    filterStatus = dataField[field].value ? dataField[field].value : dataField[field];
                    filterStatus = filterStatus === '-1' ? '' : filterStatus;
                    newState.filterStatus = filterStatus;
                    break;
                case 'locationName':
                    let tempLocation = dataField[field];
                    if (tempLocation.value !== undefined) {
                        newState.filterLocation = tempLocation.value;
                    } else {
                        newState.filterLocation = tempLocation;
                    }
                    break;
                case 'deliveredDate':
                    newState.filterDeliveredOn = Helper.GetNumberOfDays(
                        dataField[field].value ? dataField[field].value : dataField[field]
                    );
                    break;
                case 'lastReminder':
                    newState.filterLastReminderOn = moment(
                        dataField[field].value ? dataField[field].value : dataField[field]
                    ).format('MM/DD/YYYY');
                    break;
                case 'engagementType':
                    newState.filterEngagementType = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'downloaded':
                    newState.filterDownloadedBy = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'testColumn':
                    newState.filterCustomColumn = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'retentionPeriod':
                    newState.filterRetentionPeriod = moment(
                        dataField[field].value ? dataField[field].value : dataField[field]
                    ).format('MM/DD/YYYY');
                    break;
                case 'taxYear':
                    filterTaxYear = dataField[field].value ? dataField[field].value : dataField[field];
                    filterTaxYear = filterTaxYear === '-1' ? '' : filterTaxYear;
                    newState.filterTaxYear = filterTaxYear;
                    break;
                case 'returnStatus':
                    filterReturnStatus = dataField[field].value ? dataField[field].value : dataField[field];
                    filterReturnStatus = filterReturnStatus === '-1' ? '' : filterReturnStatus;
                    newState.filterReturnStatus = filterReturnStatus;
                    break;
            }
        }
        let tempfilter: IFilters = { ...this.state.filter };
        tempfilter.fields = dict;
        tempfilter.filterType = filterType;
        if (isClearFilter) {
            Helper.SessionStore.remove(this.proxyFilter);
        } else {
            Helper.SessionStore.set(this.proxyFilter, JSON.stringify(tempfilter));
        }
        if (this.isFilterChanged(newState, filterStatus, filterTaxYear, filterLocation, filterReturnStatus)) {
            this.clearSelection();
            this.setState({ ...newState, filter: tempfilter, page: 1, pageSize: pageSize }, () => {
                let deliveredReturnsTable: any = this.refs.DeliveredReturnsTable;
                if (!deliveredReturnsTable.isAppliedFilter) {
                    const query = this.buildQuery(this.state.page, this.state.pageSize);
                    this.props.requestDeliveredReturns(
                        query,
                        true,
                        undefined,
                        this.state.page > 1 ? this.reloadOnNoDataFound : undefined
                    );
                }
            });
        }
    }

    private isFilterChanged(
        newState: DeliveredReturnsState.DeliveredReturnsPageState,
        filterStatus: any,
        filterTaxYear: any,
        filterLocation: any,
        filterReturnStatus: any
    ): boolean {
        return (
            newState.filterName !== this.state.filterName ||
            newState.filterClientId !== this.state.filterClientId ||
            newState.filterPartner !== this.state.filterPartner ||
            newState.filterSentBy !== this.state.filterSentBy ||
            filterStatus !== this.state.filterStatus ||
            filterLocation !== this.state.filterLocation ||
            newState.filterDeliveredOn !== this.state.filterDeliveredOn ||
            newState.filterLastReminderOn !== this.state.filterLastReminderOn ||
            newState.filterRetentionPeriod !== this.state.filterRetentionPeriod ||
            newState.filterEngagementType !== this.state.filterEngagementType ||
            newState.filterDownloadedBy !== this.state.filterDownloadedBy ||
            newState.filterCustomColumn !== this.state.filterCustomColumn ||
            filterTaxYear !== this.state.filterTaxYear ||
            filterReturnStatus !== this.state.filterReturnStatus
        );
    }

    onFilterSave(onApplyFilter: (filter: IFilters) => void) {
        if (this.state.filter.name.trim() != '') {
            let filter = this.props.reportFilters.filters.find(x => x.name == this.state.filter.name);
            if (filter) {
                VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.FilterNameAlreadyExists, null);
            } else {
                this.props.addReportFilter(this.state.filter.name, this.state.filter, () => {
                    onApplyFilter(this.state.filter);
                    this.onSaveFilterHide();
                    logClientEvent(`${LogEventConstants.Common.Filter.SaveFilter} ${this.pageTitle}`, {
                        count: 1,
                        page: this.pageTitle,
                    });
                });
            }
        } else {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.PleaseEnterFilterName, null);
        }
    }

    onFilterUpdate(name: string) {
        let filter = this.props.reportFilters.filters.find(x => x.name == name);
        if (!filter) {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.FilterDoesNotExists, null);
        } else {
            filter.fields = this.state.filter.fields;
            filter.name = name;
            filter.searchText = this.state.filter.searchText;
            filter.sort = this.state.filter.sort;
            filter.isDefaultFilter = this.state.filter.isDefaultFilter;
            filter.filterType = this.state.filter.filterType;

            this.props.updateReportFilter(name, filter);
            this.onSaveFilterHide();
            logClientEvent(`${LogEventConstants.Common.Filter.UpdateFilter} ${this.pageTitle}`, {
                count: 1,
                page: this.pageTitle,
            });
        }
    }

    onSetDefaultFilterSuccess() {
        VenusNotifier.Success(DeliveredReturnsConstants.StatusMessage.SetDefaultFilterSuccess, null);
    }

    onSetDefaultFilter(name: string, filterType: ReportFilterType) {
        this.props.addDefaultFilter(name, filterType, () => {
            this.onSetDefaultFilterSuccess();
        });
        3;
        logClientEvent(`${LogEventConstants.Common.Filter.SetDefaultFilter} ${this.pageTitle}`, {
            count: 1,
            page: this.pageTitle,
        });
    }

    onRemoveDefaultFilterSuccess() {
        VenusNotifier.Success(DeliveredReturnsConstants.StatusMessage.RemoveDefaultFilterSuccess, null);
    }

    onRemoveDefaultFilter(name: string, filterType: ReportFilterType) {
        this.props.removeDefaultFilter(name, filterType, () => {
            this.onRemoveDefaultFilterSuccess();
        });
        let deliveredReturnsTable: any = this.refs.DeliveredReturnsTable;
        deliveredReturnsTable.refs.Filters?.setState({ activeFilter: '', appliedFilters: [] });
        logClientEvent(`${LogEventConstants.Common.Filter.RemoveDefaultFilter} ${this.pageTitle}`, {
            count: 1,
            page: this.pageTitle,
        });
        this.onPageReload;
    }

    onFilterDelete(filterName: string, filterType: ReportFilterType) {
        this.props.deleteReportFilter(filterName, filterType);
        logClientEvent(`${LogEventConstants.Common.Filter.DeleteFilter} ${this.pageTitle}`, {
            count: 1,
            page: this.pageTitle,
        });
    }

    onSaveFilterHide() {
        this.setState({
            saveFilterShow: false,
        });
    }

    onSaveFilterShow() {
        this.setState({
            saveFilterShow: true,
        });
    }

    //======================Filter Operation Ends here=================================

    //------------------EditClientInfo Starts here---------------------------------

    private getSignedDetails(data: any) {
        let id: number = data.id;
        this.props.requestSignedDetails(id);
        this.props.requestNextSignerDetails(
            id,
            this.props.taxDocuments[id].taxReturn.signatureStatus,
            this.editClientInfoResourceId
        );
    }

    private onEditClientInfoOpen(rowIndex: number) {
        let id = this.props.delivered.deliveredReturnTableModel.documents[rowIndex].document.id;
        let model = this.props.taxDocuments[id].taxReturn;

        this.setState({
            editClientInfoState: {
                show: true,
                model: model,
                saveCheckId: undefined,
            },
        });

        this.props.requestTaxDocument(id, true, undefined, undefined, this.getSignedDetails, undefined);
    }

    private onEditClientInfoCancel(id: number) {
        let item = this.state.editClientInfoState.model as ITaxReturn;
        this.props.requestTaxDocument(item.id, true);
        this.setState({
            editClientInfoState: {
                show: false,
                model: undefined,
                saveCheckId: undefined,
            },
        });
    }

    private onEditClientInfoSave(
        id: number,
        isMailSend: boolean,
        clientGUIDs: string[],
        isEmailUpdated: boolean,
        callback?: any
    ) {
        const taxReturn: ITaxReturn = this.props.taxDocuments[id].taxReturn;
        const deliveredBy = taxReturn.deliveredByUser.userId;
        taxReturn.deliveredBy = deliveredBy;
        if (Helper.validateClientInfo(taxReturn)) {
            this.setState(
                prevState => ({
                    editClientSaveState: {
                        ...prevState,
                        id: id,
                        taxReturn: taxReturn,
                        clientGUIDs: clientGUIDs,
                        isEmailUpdated: isEmailUpdated,
                        isMailSend: isMailSend,
                        callback: callback?.bind(this),
                    },
                }),
                () => {
                    let uid =
                        isIndividual(taxReturn) || isMutual(taxReturn)
                            ? taxReturn.taxpayer.ssn
                            : isPartnership(taxReturn)
                            ? taxReturn.partnership.ssn
                            : '';

                    getClientInfoByClientId(
                        taxReturn.clientId,
                        uid,
                        this.editClientInfoResourceId,
                        this.compareClientInfo
                    );
                }
            );
        }
    }

    private sendMail(id: number, isMailSend: boolean, clientGUIDs: string[]): void {
        if (isMailSend) {
            let taxReturn: any = this.props.taxDocuments[id].taxReturn;
            if (
                taxReturn.signatureStatus == SignatureStatus[SignatureStatus.AwaitingESign] ||
                taxReturn.signatureStatus == SignatureStatus[SignatureStatus.AwaitingUpload] ||
                taxReturn.signatureStatus == SignatureStatus[SignatureStatus.ManuallySigned] ||
                taxReturn.signatureStatus == SignatureStatus[SignatureStatus.PartiallySigned]
            ) {
                this.props.sendClientInfoMail(id, clientGUIDs, this.editClientInfoResourceId);
                this.logSendEmail(id);
            } else if (
                taxReturn.signatureStatus == SignatureStatus[SignatureStatus.ESigned] ||
                taxReturn.signatureStatus == SignatureStatus[SignatureStatus.SignedAndESigned] ||
                taxReturn.signatureStatus == SignatureStatus[SignatureStatus.Uploaded]
            ) {
                this.props.SendDownloadTaxReturnMailOnDeliveredEmailAddressChange(
                    id,
                    clientGUIDs,
                    this.editClientInfoResourceId
                );
                this.logSendEmail(id);
            } else if (taxReturn.signatureStatus == SignatureStatus[SignatureStatus.Reviewed]) {
                this.props.SendDownloadTaxReturnMailOnDeliveredEmailAddressChange(
                    id,
                    clientGUIDs,
                    this.editClientInfoResourceId
                );
                this.logSendEmail(id);
            } else if (
                taxReturn.signatureStatus == SignatureStatus[SignatureStatus.AwaitingReview] ||
                taxReturn.signatureStatus == SignatureStatus[SignatureStatus.PartiallyReviewed]
            ) {
                this.props.sendClientInfoMail(id, clientGUIDs, this.editClientInfoResourceId);
                this.logSendEmail(id);
            }
        }
    }
    private logSendEmail(id: number): void {
        logClientEvent(`${LogEventConstants.Common.SendEmail} ${this.pageTitle}`, {
            count: 1,
            page: this.pageTitle,
            documentId: id,
        });
    }

    private isUserHasAccessToDocumentLocation = () => {
        let item = this.state.editClientInfoState.model as ITaxReturn;
        const taxReturn: ITaxReturn = Object.assign({}, this.props.taxDocuments[item.id]?.taxReturn);
        const index = this.props.userOfficeLocation.userLocation
            .map(x => x.locationId)
            .findIndex(x => x == taxReturn.location.locationId);
        return index != NO_INDEX;
    };

    private setLoadingMessageToInitialState = () => {
        this.setState({ loadingMessage: false });
    };

    private onCloseLocationMismatchActionModal = () => {
        this.setState({ showLocationMismatchActionModel: false, popupInProgress: false, loadingMessage: true });
    };

    private onContinueLocationMismatchActionModal = () => {
        this.setState({ showLocationMismatchActionModel: false }, () => {
            compareClientInfo(
                getNewClientInfo(
                    this.state.editClientSaveState.taxReturn,
                    this.state.clientBasicInfoId,
                    this.state.clientInfo
                ),
                this.editClientInfoResourceId,
                this.setClientInfoComparisonResult,
                this.clientManagementCancel,
                this.newClientCallback,
                this.setClientBasicInfoId
            );
        });
    };

    //-------------------EditClientInfo Ends Here--------------------------------

    //====================SetAccess Starts Here=======================================

    private onSetAccessOpen() {
        let ids = this.selectedDocumentIds();

        this.props.getTaxDocumentsAccessMaps(
            ids,
            true,
            this.props.isArchived
                ? RBACKeys.ArchivedExtension.setAccessButton
                : RBACKeys.ExtensionReport.setAccessButton,
            (result: IDocumentAccess) => {
                this.setState({
                    availableUserGroups: result.availableUserGroups,
                    availableUsers: result.availableUsers,
                    selectedUserGroups: result.selectedUserGroups,
                    selectedUsers: result.selectedUsers,
                });

                let selectedAssignments = ids.map((id, i) => {
                    return this.props.taxDocuments[id]?.taxReturn;
                });
                this.setState({
                    setAccessState: {
                        show: true,
                        selected: selectedAssignments,
                        saveCheckIds: undefined,
                        remove: false,
                    },
                });
            }
        );
    }

    private onSetAccessSave(accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) {
        let ids = this.selectedDocumentIds();
        let accessMaps: IDocumentAccessSaveModel = {
            documents: ids,
            userGroups: accessingUsersAndUserGroups.selectedUserGroups,
            users: accessingUsersAndUserGroups.selectedUsers,
        };

        this.props.setTaxDocumentsAccessMaps(
            accessMaps,
            this.props.isArchived
                ? RBACKeys.ArchivedExtension.setAccessButton
                : RBACKeys.ExtensionReport.setAccessButton,
            this.onPageReload
        );
        logger.trackTrace(
            `Set access saved successfully for documentIds: ${ids?.toString()} and userIds: ${accessingUsersAndUserGroups.selectedUsers?.toString()}`
        );
        this.setState({
            setAccessState: {
                show: true,
                selected: undefined,
                saveCheckIds: ids,
                remove: false,
            },
            selectedRows: [],
            availableUsers: [],
            selectedUsers: [],
            availableUserGroups: [],
            selectedUserGroups: [],
        });
    }

    private onSetAccessCancel() {
        this.setState({
            setAccessState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined,
                remove: false,
            },
        });
    }

    //====================SetAccess Ends Here=======================================

    //====================ReportProblem Starts Here=================================
    private onReportProblemOpen(rowIndex: number) {
        const id = this.props.delivered.deliveredReturnTableModel.documents[rowIndex].document.id;
        if (!Helper.isClientInfoLoaded(this.props.taxDocuments[id].taxReturn)) {
            this.props.requestTaxDocument(
                this.props.taxDocuments[id].taxReturn.id,
                true,
                false,
                undefined,
                undefined,
                true
            );
        }

        this.setState({
            reportProblemState: {
                show: true,
                model: this.getStoreItem(rowIndex).taxReturn,
                saveCheckId: undefined,
            },
        });
    }

    private onReportProblemCancel() {
        this.setState({
            reportProblemState: {
                show: false,
                model: undefined,
                saveCheckId: undefined,
            },
        });
    }

    private onReportProblemSave(rowIndex: number, problemDetails: IReportProblemDetails, callback?: () => void) {
        const id = this.props.delivered.deliveredReturnTableModel.documents.findIndex(x => x.document.id == rowIndex);
        this.props.reportTaxDocumentProblem(problemDetails, RBACKeys[this.pageId].reportAProblem, callback);
        this.setState({
            reportProblemState: {
                show: false,
                model: undefined,
                saveCheckId: rowIndex,
            },
        });
    }
    //====================ReportProblem Ends Here=================================

    //====================ArchiveReturns Starts Here=================================

    private onArchiveReturnsOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);

        if (ids.length == 0) {
            VenusNotifier.Warning('Please select document(s) to archive!', null);
            return;
        }

        let obj = this;
        bootbox.confirm({
            title: '<i className="text-secondary fas fa-archive"></i>Archive Tax Return Extension',
            message: 'Are you sure. Do you want to archive these selected document(s)?',
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default',
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Confirm',
                    className: 'btn-info',
                },
            },
            callback: (result: boolean) => {
                if (result) {
                    this.state.isBulkSelectionEnabled ? this.archiveReturnsBulk() : this.onArchiveReturnsSave(ids);
                }
            },
        });
    }

    private onArchiveReturnsSave(ids: number[]) {
        this.props.archiveTaxDocument(ids, this.clearFilters);
        this.setState({
            archiveReturnsState: {
                show: false,
                selected: undefined,
                saveCheckIds: ids,
                remove: false,
            },
        });

        logClientEvent(`${LogEventConstants.Common.ArchiveExtension} ${this.pageTitle}`, {
            count: ids.length,
            page: this.pageTitle,
        });
    }

    private archiveReturnsBulk = () => {
        let query = this.getBulkOperationQuery();
        let downloadType = this.props.extensionUserSettings.defaultSettings.download.downloadType;
        query.isDownloadNow = downloadType === DefaultDownloadInfoType.DownloadNow ? true : false;

        this.props.archiveDocumentsBulk(query, () => {
            this.setState({
                archiveReturnsState: {
                    show: false,
                    selected: undefined,
                    saveCheckIds: undefined,
                    remove: false,
                },
                deSelectedRows: [],
                selectedRows: [],
                isBulkSelectionEnabled: false,
            });
        });
        logClientEvent(`${LogEventConstants.Common.ArchiveExtension} ${this.pageTitle}`, {
            count: 1,
            page: this.pageTitle,
        });
    };
    //====================ArchiveReturns Ends Here=================================

    //====================RestoreReturns Starts Here=================================

    private onRestoreReturnsOpen = (rowIndex: number = NO_INDEX) => {
        let ids = this.onPopupOpen(rowIndex);

        if (ids.length == 0) {
            VenusNotifier.Warning(ArchiveReturnsConstants.PleaseSelectDocumentToRestore, null);
            return;
        }

        let obj = this;
        bootbox.confirm({
            title: '<i className="text-secondary"></i>Restore Tax Return Extension',
            message: ArchiveReturnsConstants.documentRestoreConfirmation,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default',
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Confirm',
                    className: 'btn-info',
                },
            },
            callback: (result: boolean) => {
                if (result) {
                    this.onRestoreReturnsSave(ids);
                    logClientEvent(`${LogEventConstants.Common.RestoreExtension} ${this.pageTitle}`, {
                        count: ids?.length ?? 0,
                        page: this.pageTitle,
                    });
                }
            },
        });
    };

    private onRestoreReturnsSave = (ids: number[]) => {
        this.props.restoreArchivedTaxDocument(ids, this.clearFilters);
        this.setState({
            restoreReturnsState: {
                show: false,
                selected: undefined,
                saveCheckIds: ids,
                remove: false,
            },
        });
    };

    //====================RestoreReturns Ends Here=================================

    //====================ChangeStatus Starts Here=================================

    private onChangeStatusOpen(rowIndex: number = NO_INDEX) {
        let selectedDocumentIds = this.selectedDocumentIds();
        let selectedDocuments = this.selectedDocuments();
        let _selectedStatus = this.state.selectedStatus;

        if (selectedDocumentIds.length == 0) {
            VenusNotifier.Error('Error: Please select documents to change the status.', null);
        } else {
            selectedDocumentIds.map((id, i) => {
                if (
                    this.props.taxDocuments[id].isFullyLoaded == undefined ||
                    !this.props.taxDocuments[id].isFullyLoaded
                ) {
                    this.props.requestTaxDocument(id, true);
                }
            });

            let _signatureStatusList: DeliveredReturnsState.ISignatureStatusList[] = [];
            let _signatureStatusAwaitingEsign: any = SignatureStatus[SignatureStatus.AwaitingESign];
            let _signatureStatusAwaitingUpload: any = SignatureStatus[SignatureStatus.AwaitingUpload];
            let _signatureStatusManuallySigned: any = SignatureStatus[SignatureStatus.ManuallySigned];
            let _signatureStatusPartiallySigned: any = SignatureStatus[SignatureStatus.PartiallySigned];

            if (
                selectedDocuments.every(i => i.document.signatureStatus == _signatureStatusAwaitingEsign) ||
                selectedDocuments.every(i => i.document.signatureStatus == _signatureStatusAwaitingUpload) ||
                selectedDocuments.every(i => i.document.signatureStatus == _signatureStatusManuallySigned) ||
                selectedDocuments.every(i => i.document.signatureStatus == _signatureStatusPartiallySigned)
            ) {
                if (selectedDocuments.every(i => i.document.signatureStatus == _signatureStatusAwaitingEsign)) {
                    if (selectedDocuments.every(i => i.signedCount == 0)) {
                        _signatureStatusList.push({
                            value: SignatureStatus.AwaitingESign,
                            name: SignatureStatus[SignatureStatus.AwaitingESign],
                        });
                        _signatureStatusList.push({
                            value: SignatureStatus.ManuallySigned,
                            name: SignatureStatus[SignatureStatus.ManuallySigned],
                        });
                        _selectedStatus = SignatureStatus.AwaitingESign;
                    }

                    //Exception condition in case of both type of AwaitingESign Documents are selected together
                    else if (selectedDocuments.some(i => i.signedCount == 0)) {
                        VenusNotifier.Error('Error : Status cannot be changed.', null);
                    } else {
                        _signatureStatusList.push({
                            value: SignatureStatus.AwaitingESign,
                            name: SignatureStatus[SignatureStatus.AwaitingESign],
                        });
                        _signatureStatusList.push({
                            value: SignatureStatus.SignedAndESigned,
                            name: SignatureStatus[SignatureStatus.SignedAndESigned],
                        });
                        _selectedStatus = SignatureStatus.AwaitingESign;
                    }
                } else if (
                    selectedDocuments.every(i => i.document.signatureStatus == _signatureStatusPartiallySigned)
                ) {
                    _signatureStatusList.push({
                        value: SignatureStatus.PartiallySigned,
                        name: SignatureStatus[SignatureStatus.PartiallySigned],
                    });
                    _signatureStatusList.push({
                        value: SignatureStatus.SignedAndESigned,
                        name: SignatureStatus[SignatureStatus.SignedAndESigned],
                    });
                    _selectedStatus = SignatureStatus.PartiallySigned;
                } else if (selectedDocuments.every(i => i.document.signatureStatus == _signatureStatusAwaitingUpload)) {
                    _signatureStatusList.push({
                        value: SignatureStatus.AwaitingUpload,
                        name: SignatureStatus[SignatureStatus.AwaitingUpload],
                    });
                    _signatureStatusList.push({
                        value: SignatureStatus.ManuallySigned,
                        name: SignatureStatus[SignatureStatus.ManuallySigned],
                    });
                    _selectedStatus = SignatureStatus.AwaitingUpload;
                } else if (selectedDocuments.every(i => i.document.signatureStatus == _signatureStatusManuallySigned)) {
                    _signatureStatusList.push({
                        value: SignatureStatus.ManuallySigned,
                        name: SignatureStatus[SignatureStatus.ManuallySigned],
                    });
                    _signatureStatusList.push({
                        value: SignatureStatus.AwaitingUpload,
                        name: SignatureStatus[SignatureStatus.AwaitingUpload],
                    });
                    _signatureStatusList.push({
                        value: SignatureStatus.AwaitingESign,
                        name: SignatureStatus[SignatureStatus.AwaitingESign],
                    });
                    _selectedStatus = SignatureStatus.ManuallySigned;
                }

                // Check if status list is empty before changing state
                if (_signatureStatusList.length > 0) {
                    this.setState({
                        signatureStatusList: _signatureStatusList,
                        selectedStatus: _selectedStatus,
                        changeStatusState: {
                            show: true,
                            selected: undefined,
                            saveCheckIds: undefined,
                            remove: false,
                        },
                        showLoader: false,
                    });
                }
            } else if (
                selectedDocuments.every(
                    i =>
                        i.document.signatureStatus == _signatureStatusAwaitingUpload ||
                        i.document.signatureStatus == _signatureStatusAwaitingEsign
                )
            ) {
                _signatureStatusList.push({
                    value: SignatureStatus.ManuallySigned,
                    name: SignatureStatus[SignatureStatus.ManuallySigned],
                });
                _selectedStatus = SignatureStatus.ManuallySigned;

                this.setState({
                    signatureStatusList: _signatureStatusList,
                    selectedStatus: _selectedStatus,
                    changeStatusState: {
                        show: true,
                        selected: undefined,
                        saveCheckIds: undefined,
                        remove: false,
                    },
                    showLoader: false,
                });
            } else {
                VenusNotifier.Error('Error : Status cannot be changed.', null);
            }
        }
    }

    private onChangeStatusCancel() {
        this.setState({
            changeStatusState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined,
                remove: false,
            },
        });
    }

    private onChangeStatusSave(status: SignatureStatus, reason: string) {
        let ids = this.selectedDocumentIds();
        const changeStatusResourceId = this.props.isArchived
            ? RBACKeys.ArchivedExtension.changeStatus
            : RBACKeys.ExtensionReport.changeStatus;
        ids.forEach((id, i) => {
            let taxDocument = this.props.taxDocuments[id].taxReturn;
            taxDocument.signatureStatus = status;
            taxDocument.deliveredBy = this.props.taxDocuments[id].taxReturn.deliveredByUser.userId;
            taxDocument.isModified = true;
            if (
                taxDocument.signatureStatus == SignatureStatus.AwaitingESign ||
                taxDocument.signatureStatus == SignatureStatus.PartiallySigned
            ) {
                taxDocument.documentSettings.documentSignatureSetting.signatureFormSelectionType =
                    SignatureType.ESignWhenAllIncludedReturnsAllowed;
            }
            if (
                taxDocument.signatureStatus == SignatureStatus.AwaitingUpload ||
                taxDocument.signatureStatus == SignatureStatus.ManuallySigned
            ) {
                taxDocument.documentSettings.documentSignatureSetting.signatureFormSelectionType =
                    SignatureType.ManualSign;
            }
            if (
                taxDocument.documentStatus == DocumentStatus.DELIVERED &&
                (taxDocument.signatureStatus == SignatureStatus.ESigned ||
                    taxDocument.signatureStatus == SignatureStatus.ManuallySigned ||
                    taxDocument.signatureStatus == SignatureStatus.SignedAndESigned)
            ) {
                taxDocument.documentStatus = DocumentStatus.USERSIGNED;
            }

            const changeStatusResourceId = this.props.isArchived
                ? RBACKeys.ArchivedExtension.changeStatus
                : RBACKeys.ExtensionReport.changeStatus;

            this.props.saveTaxDocument(taxDocument, RBACKeys[this.pageId].changeStatus, undefined, data => {
                if (status === SignatureStatus.SignedAndESigned) {
                    this.props.UpdateStatusToSignedAndESigned(taxDocument, reason, changeStatusResourceId);
                }
                if (status == SignatureStatus.ManuallySigned) {
                    this.props.CleanUpSigningOrder(taxDocument, changeStatusResourceId);
                    this.props.LogChangeStatusToManaullySignedTransaction(taxDocument, changeStatusResourceId);
                }
                if (status === SignatureStatus.AwaitingESign || status === SignatureStatus.PartiallySigned) {
                    this.props.deleteProcessInfo(taxDocument.id, RBACKeys[this.pageId].changeStatus);
                }
                let query = this.buildQuery(this.state.page, this.state.pageSize);
                this.props.requestDeliveredReturns(
                    query,
                    true,
                    undefined,
                    this.state.page > 1 ? this.reloadOnNoDataFound : undefined
                );
                this.logExtensionStatusChange(ids.length ?? 0);
            });
        });

        this.setState({
            changeStatusState: {
                show: true,
                selected: undefined,
                saveCheckIds: ids,
                remove: false,
            },
            showLoader: true,
        });
    }

    private logExtensionStatusChange(count: number) {
        logClientEvent(`${LogEventConstants.Common.ExtensionMultiStatusUpdate} ${this.pageTitle}`, {
            count: count,
            page: this.pageTitle,
        });
    }

    //====================ChangeStatus Ends Here=================================

    //====================CustomColumn Starts Here=================================

    private onCustomColumnOpen(rowIndex: number = NO_INDEX) {
        let selectedDocumentIds = this.selectedDocumentIds();
        if (selectedDocumentIds.length == 0) {
            VenusNotifier.Error('Error: Please select one or more documents.', null);
        } else {
            selectedDocumentIds.map((id, i) => {
                if (
                    this.props.taxDocuments[id].isFullyLoaded == undefined ||
                    !this.props.taxDocuments[id].isFullyLoaded
                ) {
                    this.props.requestTaxDocument(id, true);
                }
            });

            this.setState({
                customColumnState: {
                    show: true,
                    selected: undefined,
                    saveCheckIds: undefined,
                    remove: false,
                },
                hideCustomCulumnLoader: false,
                selectedColumnValue: this.getSelectedCustomColumnValue(selectedDocumentIds),
            });
        }
    }

    getSelectedCustomColumnValue(selectedDocumentIds: number[]) {
        // method to fetch selected document custom column value
        if (selectedDocumentIds.length == 1) {
            let customColumn = this.props.delivered.deliveredReturnTableModel.documents.filter(
                x => x.document.id == selectedDocumentIds[0]
            )[0]?.customColumn;
            return customColumn !== '' && customColumn !== undefined
                ? this.props.customColumnData.customColumn.customColumnValues.filter(x => x.value === customColumn)[0]
                      .id
                : 0;
        } else {
            let columnValue: string[] = [];
            selectedDocumentIds.map((id, i) => {
                let customColumn = this.props.delivered.deliveredReturnTableModel.documents.filter(
                    x => x.document.id == id
                )[0]?.customColumn;
                columnValue.push(customColumn);
            });

            let isSame: boolean = false;
            let tempValue: string = '';
            isSame = columnValue.every(x => x == columnValue[0]);

            if (isSame) {
                tempValue = this.props.delivered.deliveredReturnTableModel.documents.filter(
                    x => x.document.id == selectedDocumentIds[0]
                )[0]?.customColumn;
                return tempValue != '' && tempValue != undefined
                    ? this.props.customColumnData.customColumn.customColumnValues.filter(x => x.value === tempValue)[0]
                          .id
                    : 0;
            } else return 0;
        }
    }
    private onCustomColumnDropdownChange(value: any) {
        this.setState({ selectedColumnValue: value });
    }

    private onCustomColumnSave() {
        if (this.state.selectedColumnValue == 0) {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.SelectCustomColumnValue, null);
            return false;
        }
        const ids = this.selectedDocumentIds();
        const customColumn = this.props.customColumnData.customColumn.customColumnValues.filter(
            i => i.id == this.state.selectedColumnValue
        );

        if (customColumn && customColumn.length > 0) {
            ids.forEach((id, i) => {
                const taxDocument = this.props.taxDocuments[id].taxReturn;
                let currentCustomColumnId = 0;
                if (taxDocument.documentSettings.documentDisplaySetting.customColumn != null) {
                    currentCustomColumnId = taxDocument.documentSettings.documentDisplaySetting.customColumn.id;
                    const currentCustomColumn = this.props.customColumnData.customColumn.customColumnValues.filter(
                        i => i.id == currentCustomColumnId
                    );

                    if (currentCustomColumn.length > 0) {
                        currentCustomColumn[0].inUseCount--;
                        this.props.updateCustomColumnValue(
                            currentCustomColumn[0],
                            false,
                            this.props.isArchived
                                ? RBACKeys.ArchivedExtension.setCustomColumn
                                : RBACKeys.ExtensionReport.setCustomColumn
                        );
                    }
                }
                taxDocument.documentSettings.documentDisplaySetting.customColumn = customColumn[0];
                taxDocument.documentSettings.isModified = true;
                customColumn[0].inUseCount++;
                this.props.saveTaxDocument(taxDocument, RBACKeys[this.pageId].setCustomColumn, customColumn[0]);
                this.props.updateTaxDocumentCustomColumnValue(id, customColumn[0].value);
            });

            this.setState({
                customColumnState: {
                    show: true,
                    selected: undefined,
                    saveCheckIds: ids,
                    remove: false,
                },
            });
        }
    }

    private onCustomColumnRemove() {
        let ids = this.selectedDocumentIds();
        let customColumn = this.props.customColumnData.customColumn.customColumnValues.filter(
            i => i.id == this.state.selectedColumnValue
        );
        if (customColumn && customColumn.length > 0) {
            let temp = this.selectedDocuments().filter(p => p.customColumn == customColumn[0].value);
            if (temp.length == 0 && temp.length != ids.length) {
                VenusNotifier.Warning(GroupTabConstants.Page.WarningValueNotMatch, null);
                this.setState({ hideCustomCulumnLoader: true });
            }
            temp.forEach((value, i) => {
                let taxDocument = this.props.taxDocuments[value.document.id].taxReturn;
                taxDocument.documentSettings.documentDisplaySetting.customColumn = {
                    id: 0,
                    value: '',
                    customColumn: 0,
                    inUseCount: 0,
                };
                taxDocument.documentSettings.isModified = true;
                customColumn[0].inUseCount--;
                this.props.saveTaxDocument(taxDocument, RBACKeys[this.pageId].setCustomColumn, customColumn[0]);
                this.props.updateTaxDocumentCustomColumnValue(value.document.id, '');
            });

            this.setState({
                customColumnState: {
                    show: false,
                    selected: undefined,
                    saveCheckIds: ids,
                    remove: true,
                },
            });
        }
    }

    private onCustomColumnCancel() {
        this.setState({
            customColumnState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined,
                remove: false,
            },
        });
    }

    private customColumnUpdateBulk = () => {
        this.customColumnBulk(BulkOperationEndPoint.UpdateCustomColumn);
    };

    private customColumnRemoveBulk = () => {
        this.customColumnBulk(BulkOperationEndPoint.RemoveCustomColumn);
    };

    private customColumnBulk = (apiEndPoint: string) => {
        if (this.state.selectedColumnValue == 0) {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.SelectCustomColumnValue, null);
            return false;
        }

        let customColumn = this.props.customColumnData.customColumn.customColumnValues.filter(
            i => i.id == this.state.selectedColumnValue
        );

        if (customColumn && customColumn.length > 0) {
            let query = this.getBulkOperationQuery();

            this.props.customcolumnUpdateBulk(query, customColumn[0], apiEndPoint, () => {
                this.setState({
                    customColumnState: {
                        show: false,
                        selected: undefined,
                        saveCheckIds: undefined,
                        remove: true,
                    },
                    deSelectedRows: [],
                    selectedRows: [],
                    isBulkSelectionEnabled: false,
                });
            });
        }
    };

    //====================CustomColumn Ends Here=================================

    //====================SendReminder Starts Here===============================

    async OnSendReminder(selectedTaxDocuments?: IDeliveredTaxDocument[]) {
        const automatedRemindersResourceId = this.props.isArchived
            ? RBACKeys.ArchivedExtension.automatedReminders
            : RBACKeys.ExtensionReport.automatedReminders;

        let selectedDocuments = !selectedTaxDocuments.length ? this.selectedDocuments() : selectedTaxDocuments;
        let ids = this.selectedDocumentIds();
        this.setState({ selectedDocuments });
        if (selectedDocuments.length == 0) {
            alert('Error: Please select one or more documents');
        } else if (this.state.isBulkSelectionEnabled) {
            HideLoader();
            this.setState({
                sendReminderState: {
                    show: true,
                    selected: [],
                    saveCheckIds: ids,
                    remove: false,
                },
            });
        } else {
            let isValid: boolean = true;
            let i: number = 0;
            let isInValid: boolean = false;
            if (selectedDocuments.length === 1) {
                const id: number = selectedDocuments[0].document.id;
                const signatureStatus: string = selectedDocuments[0].document.signatureStatus.toString();
                if (
                    this.props.taxDocuments[id].isFullyLoaded == undefined ||
                    !this.props.taxDocuments[id].isFullyLoaded
                ) {
                    this.props.requestTaxDocument(id, true, undefined, undefined, () =>
                        this.OnSendReminder(selectedTaxDocuments)
                    );
                    ShowLoader(Common.OverlayMessages.Processing);
                    return;
                }
                this.props.requestDocumentVoucherStatus(id, automatedRemindersResourceId, status => {
                    isInValid =
                        signatureStatus === SignatureStatus[SignatureStatus.AlternateDelivery] ||
                        (isInValidSignatureStatus(signatureStatus) && status !== IVoucherStatus.Valid);
                    this.setState({ selectedVoucherStatus: status });
                    if (isInValid) {
                        HideLoader();
                        isValid = false;
                        VenusNotifier.Error(
                            `Error : We cannot send reminder email notification for ${
                                selectedDocuments.length === 1 ? 'this document.' : 'these documents.'
                            }`,
                            null
                        );
                        this.setState({
                            selectedDocuments: [],
                            selectedVoucherStatus: undefined,
                        });
                        return;
                    } else {
                        HideLoader();
                        this.setState({
                            sendReminderState: {
                                show: true,
                                selected: [],
                                saveCheckIds: ids,
                                remove: false,
                            },
                        });
                    }
                });
            } else {
                if (isValid) {
                    for (i = 0; i < selectedDocuments.length; i++) {
                        let documentId: number = selectedDocuments[i].document.id;
                        if (
                            this.props.taxDocuments[documentId].isFullyLoaded == undefined ||
                            !this.props.taxDocuments[documentId].isFullyLoaded
                        ) {
                            this.props.requestTaxDocument(documentId, true, undefined, undefined, () =>
                                this.OnSendReminder(selectedTaxDocuments)
                            );
                            ShowLoader(Common.OverlayMessages.Processing);
                            return;
                        }
                    }
                }

                if (!isValid) {
                    HideLoader();
                    VenusNotifier.Error(
                        `Error : We cannot send reminder email notification for ${
                            selectedDocuments.length === 1 ? 'this document.' : 'these documents.'
                        }`,
                        null
                    );
                    this.setState({
                        selectedDocuments: [],
                        selectedVoucherStatus: undefined,
                    });
                } else if (isValid) {
                    HideLoader();
                    this.setState({
                        sendReminderState: {
                            show: true,
                            selected: [],
                            saveCheckIds: ids,
                            remove: false,
                        },
                    });
                } else {
                    HideLoader();
                    this.setState({
                        sendReminderState: {
                            show: true,
                            selected: [],
                            saveCheckIds: ids,
                            remove: false,
                        },
                    });
                }
            }
        }
    }

    private onHideReminderModal = () => {
        this.setState({
            sendReminderState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined,
                remove: false,
            },
            selectedDocuments: [],
            selectedVoucherStatus: undefined,
        });
    };

    private sendReminderConfirm = (ids: number[], scheduleReminderPayload?: IScheduleReminderPayload) => {
        const RBACKey = this.state.isFromGrid
            ? RBACKeys[this.pageId].automatedReminders
            : RBACKeys[this.pageId].sendReminder;
        if (!scheduleReminderPayload) {
            this.props.sendReminder(ids, RBACKey, () =>
                this.props.requestDeliveredReturns(
                    this.buildQuery(this.state.page, pageSize),
                    true,
                    undefined,
                    this.state.page > 1 ? this.reloadOnNoDataFound : undefined
                )
            );
        } else {
            this.props.sendScheduledReminder(ids, RBACKey, scheduleReminderPayload, () =>
                this.props.requestDeliveredReturns(
                    this.buildQuery(this.state.page, pageSize),
                    true,
                    undefined,
                    this.state.page > 1 ? this.reloadOnNoDataFound : undefined
                )
            );
        }
        this.setState({
            sendReminderState: {
                show: false,
                selected: [],
                saveCheckIds: ids,
                remove: false,
            },
            selectedDocuments: [],
            selectedVoucherStatus: undefined,
        });

        logClientEvent(`${LogEventConstants.Common.SendMultiReminder} ${this.pageTitle}`, {
            count: ids.length,
            page: this.pageTitle,
        });
    };
    private sendBulkReminders = (scheduleReminderPayload?: IScheduleReminderPayload) => {
        ShowLoader(Common.OverlayMessages.Processing);
        if (!scheduleReminderPayload) {
            this.props.sendRemindersBulk(this.getBulkOperationQuery(), RBACKeys[this.pageId].sendReminder, () => {
                HideLoader();
                this.setState({
                    sendReminderState: {
                        selected: undefined,
                        show: false,
                        saveCheckIds: undefined,
                        remove: false,
                    },
                    deSelectedRows: [],
                    selectedRows: [],
                    isBulkSelectionEnabled: false,
                });
            });
        } else {
            this.props.sendScheduledRemindersBulk(
                this.getBulkOperationQuery(),
                RBACKeys[this.pageId].sendReminder,
                scheduleReminderPayload,
                () => {
                    HideLoader();
                    this.setState({
                        sendReminderState: {
                            selected: undefined,
                            show: false,
                            saveCheckIds: undefined,
                            remove: false,
                        },
                        deSelectedRows: [],
                        selectedRows: [],
                        isBulkSelectionEnabled: false,
                    });
                }
            );
        }
    };

    //====================SendReminder Ends Here=================================

    //====================Resend Access Link Starts Here===============================

    private onResendAccessLinkOpen(rowIndex: number = NO_INDEX) {
        const resendAccessLinkResourceId = this.props.isArchived
            ? RBACKeys.ArchivedExtension.resendAccessLink
            : RBACKeys.ExtensionReport.resendAccessLink;

        let ids = this.onPopupOpen(rowIndex);
        ids.map((id, i) => {
            this.props.requestSignedDetails(id, () => {
                this.props.requestTaxDocumentClientTracking(id, RBACKeys[this.pageId].clientTracking, () => {
                    this.props.isSSRExtensionDocumentExist(id, resendAccessLinkResourceId);
                });
            });
        });
        this.setState({
            resendAccessLinkState: {
                show: true,
                model: this.getStoreItem(rowIndex).taxReturn,
                saveCheckId: undefined,
            },
        });
    }

    private onResendAccessLink(id: number, model: ISignerModel) {
        this.props.resendAccessLink(id, model, RBACKeys[this.pageId].resendAccessLink);
        this.setState({
            resendAccessLinkState: {
                show: true,
                model: undefined,
                saveCheckId: id,
            },
        });

        logClientEvent(`${LogEventConstants.Common.ResendAccessLink} ${this.pageTitle}`, {
            count: 1,
            page: this.pageTitle,
            documentId: id,
        });
    }

    private onResendAccessLinkCancel() {
        this.setState({
            resendAccessLinkState: {
                show: false,
                model: undefined,
                saveCheckId: undefined,
            },
        });
    }
    //====================Resend Access Link Ends Here===============================

    //====================Unlock Return==========================================

    private onUnlockReturnOpen = (rowIndex: number) => {
        const deliveredReturn = this.props.delivered.deliveredReturnTableModel.documents[rowIndex];
        this.setState({
            unlockReturnState: {
                show: true,
                documentId: deliveredReturn.document.id,
                unlockReturnModelList: deliveredReturn.unlockReturnModelList,
            },
        });
    };

    private onUnlockReturn = (documentId: number) => {
        this.props.unlockDocument(documentId, this.reloadTableData);
        this.onUnlockReturnClose();
    };

    private reloadTableData = () => {
        this.setState({ selectedRows: [] }, this.onPageReload);
    };

    private unlockOneHubAccount = (emailAddress: string) => {
        this.props.unlockOneHubAccount(emailAddress, this.reloadTableData);
        this.onUnlockReturnClose();
    };

    private onUnlockReturnClose = () => {
        this.setState({
            unlockReturnState: {
                show: false,
                documentId: 0,
                unlockReturnModelList: [],
            },
        });
    };

    //====================Client Tracking Starts Here===============================

    private onClientTrackingOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);
        ids.map((id, i) => {
            this.props.requestTaxDocumentClientTracking(id, RBACKeys[this.pageId].clientTracking);
        });

        this.setState({
            clientTrackingState: {
                show: true,
                model: this.getStoreItem(rowIndex).taxReturn,
                saveCheckId: undefined,
            },
        });

        logClientEvent(` ${LogEventConstants.Common.RequestClientTracking} ${this.pageTitle}`, {
            count: ids.length ?? 0,
            page: this.pageTitle,
        });
    }

    private onClientTrackingCancel() {
        this.setState({
            clientTrackingState: {
                show: false,
                model: undefined,
                saveCheckId: undefined,
            },
        });
    }
    //=====================Client Tracking Ends Here================================

    //====================RecallReturn Starts Here=================================

    private onRecallReturnOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);

        let name: any;
        let taxReturn: any = this.props.taxDocuments[ids[0]].taxReturn;
        if (isPartnership(taxReturn)) {
            name = taxReturn.partnership.name;
        } else {
            if (isIndividual(taxReturn) || isMutual(taxReturn)) {
                name = taxReturn.taxpayer.name;
            }
        }
        let clientId = taxReturn.clientId;
        let type = taxReturn.engagementType.toString().slice(1, 10);
        let year = taxReturn.taxYear;

        if (ids.length == 0) {
            VenusNotifier.Warning('Please select document(s) to recall!', null);
            return;
        }

        let obj = this;

        let confirmationString: string =
            taxReturn.documentStatus === DocumentStatus.DOWNLOADPDF
                ? 'Are you sure you want to Reprocess this return?'
                : 'Are you sure you want to Recall this return?';

        let message: string =
            confirmationString +
            ' <br/><br/>' +
            '<table class="wrapper recall-return-table"> <tr><th style="width:40%">Client Name</th> <th style="width:40%">Client ID</th> <th style="width:10%">Type</th> <th style="width:10%">Tax Year</th></tr> <tr><td><div class="ellipsis" title="' +
            name +
            '">' +
            name +
            '</div></td> <td><div class="ellipsis" title="' +
            clientId +
            '">' +
            clientId +
            '</div></td><td>' +
            type +
            ' </td><td>' +
            year +
            ' </td></tr></table>';

        let title: string =
            taxReturn.documentStatus === DocumentStatus.DOWNLOADPDF ? 'Reprocess Return' : 'Recall Return';

        bootbox.confirm({
            title: title,
            message: message,
            className: 'recall-return-modal',
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default',
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Confirm',
                    className: 'btn-info',
                },
            },
            callback: (result: boolean) => {
                if (result) {
                    ids.map((id, i) => {
                        obj.onRecallReturnSave(id);
                    });
                }
            },
        });
    }

    private onRecallReturnSave(id: number) {
        let taxReturn = this.props.taxDocuments[id].taxReturn;
        this.props.recallTaxDocument(taxReturn, this.clearFilters, RBACKeys[this.pageId].recallReturn);

        if (taxReturn.documentStatus === DocumentStatus.DOWNLOADPDF) {
            this.setState({
                reprocessReturnState: {
                    show: false,
                    model: undefined,
                    saveCheckId: id,
                },
            });
        } else {
            this.setState({
                recallReturnState: {
                    show: false,
                    model: undefined,
                    saveCheckId: id,
                },
            });
        }

        logClientEvent(`${LogEventConstants.Common.RecallExtension} ${this.pageTitle}`, {
            count: 1,
            page: this.pageTitle,
            documentId: id,
        });
    }

    //====================RecallReturn Ends Here=================================

    //====================VoucherReminder Starts Here=================================

    private onIndividualReminderOpen(rowIndex: number) {
        let ids: number[] = this.onPopupOpen(rowIndex);
        this.setState({ isFromGrid: true });

        if (ids.length == 0) {
            VenusNotifier.Warning('Please select document(s) to update voucher reminder!', null);
            return;
        }
        // THIS MAY BE ADDED LATER
        // ids.map((id, i) => {
        //     this.props.isSSRExtensionDocumentExist(id);
        //     if (this.props.taxDocuments[id].isFullyLoaded == undefined || !this.props.taxDocuments[id].isFullyLoaded) {
        //         this.props.requestTaxDocument(id, true, null, null);
        //     }
        // });
        const documents = this.props.delivered.deliveredReturnTableModel.documents;
        const selectedDocuments = documents.filter(doc => ids.includes(doc.document.id));
        this.setState({ selectedDocuments });
        this.OnSendReminder(selectedDocuments);
    }

    private onTaxDocumentUpdated = (taxDocument: ITaxReturn) => {
        this.props.setVoucherDueDateReminder(
            taxDocument,
            RBACKeys[this.pageId].automatedReminders,
            this.onSetVoucherReminderCompleted
        );
    };
    private onSetVoucherReminderCompleted = (reminderData: ReminderStore.IReminderData) => {
        if (reminderData.error === true) {
            VenusNotifier.Error(errorMsg.voucherDueDateReminderState, 'voucherDueDateReminderState');
        }

        this.onUpdateCompletion(this.props.taxDocuments, 'voucherReminderState', this.state.voucherReminderState);
    };
    //====================VoucherReminder Ends Here=================================

    //====================View Access Code Starts Here===============================

    private onViewAccessCodeOpen(rowIndex: number = NO_INDEX) {
        const taxReturn: IDeliveredTaxDocument = this.props.delivered.deliveredReturnTableModel.documents[rowIndex];
        let id = this.props.delivered.deliveredReturnTableModel.documents[rowIndex].document.id;
        let taxReturnDocument = this.props.taxDocuments[id]?.taxReturn;

        if (!this.props.taxDocuments[taxReturn.document.id].isFullyLoaded) {
            this.props.requestTaxDocument(taxReturn.document.id, true, undefined, undefined, () => {
                this.props.requestSignedDetails(taxReturn.document.id, () => {
                    this.props.requestTaxDocumentAccessCode(
                        taxReturn.document.id,
                        RBACKeys[this.pageId].viewAccessCode,
                        true,
                        () => {
                            this.setState({
                                accessCodeState: {
                                    show: true,
                                    model: this.getStoreItem(rowIndex).taxReturn,
                                    saveCheckId: undefined,
                                },
                            });
                        }
                    );
                });
            });
        } else {
            this.props.requestTaxDocumentAccessCode(
                taxReturn.document.id,
                RBACKeys[this.pageId].viewAccessCode,
                true,
                () => {
                    this.setState({
                        accessCodeState: {
                            show: true,
                            model: this.getStoreItem(rowIndex).taxReturn,
                            saveCheckId: undefined,
                        },
                    });
                }
            );
        }

        let primaryEmailIds: string[] = [];
        if (taxReturnDocument.taxpayerEmail !== null) {
            primaryEmailIds.push(taxReturnDocument.taxpayerEmail);
        }
        if (taxReturnDocument.spouseEmail !== null) {
            primaryEmailIds.push(taxReturnDocument.spouseEmail);
        }

        this.props.requestOnehubAccessCode(primaryEmailIds, response => {
            this.setState({
                onehubAccessCodeState: {
                    model: response,
                },
            });
        });

        this.setState({
            accessCodeState: {
                show: true,
                model: undefined,
                saveCheckId: undefined,
            },
        });

        logClientEvent(`${LogEventConstants.Common.ViewAccessCode} ${this.pageTitle}`, {
            count: 1,
            page: this.pageTitle,
            documentId: taxReturn?.document?.id ?? 0,
        });
    }

    private onGenerateOTP(documentId: number, clientType: ClientTypes, clientGUID: string, callBack: () => void) {
        this.props.generateOTP(documentId, clientType, clientGUID, RBACKeys[this.pageId].viewAccessCode, callBack);
        this.setState({
            accessCodeState: {
                show: true,
                model: this.props.taxDocuments[documentId].taxReturn,
                saveCheckId: documentId,
            },
        });
    }

    private onGenerateOnehubAccessCode(primaryEmailAddress: string) {
        this.props.generateOnehubAccessCode(primaryEmailAddress, (response: IOnehubAccessCodeModel) => {
            this.setState(prevState => {
                let previousState = Object.assign({}, prevState.onehubAccessCodeState.model);
                var index = Object.values(previousState).findIndex(x => x.clientEmail == response.clientEmail);
                if (index != -1) {
                    previousState[index].accessCode = response.accessCode;
                    previousState[index].accessCodeStatus = response.accessCodeStatus;
                    previousState[index].otpDate = response.otpDate;
                }
                return { onehubAccessCodeState: { model: Object.values(previousState) } };
            });
        });
    }

    private onViewAccessCodeCancel() {
        this.setState({
            accessCodeState: {
                show: false,
                model: undefined,
                saveCheckId: undefined,
            },
        });
    }
    //=====================View Access Code Ends Here================================

    //====================Retention Policy Starts Here===============================
    private onRetentionPolicyOpen(rowIndex: number = NO_INDEX) {
        let ids: number[] = this.onPopupOpen(rowIndex);
        this.props.requestCompanySettings(true);
        if (
            this.props.taxDocuments[ids[0]].isFullyLoaded == undefined ||
            !this.props.taxDocuments[ids[0]].isFullyLoaded
        ) {
            this.props.requestTaxDocument(ids[0], true, undefined, undefined, (newData: ITaxReturn) => {
                if (newData.documentSettings.documentRetentionSetting.retentionPeriod === 0) {
                    let retentionPeriod: number = 0;
                    if (this.props.company.companySettings) {
                        if (isIndividual(newData) || isMutual(newData)) {
                            if (newData.taxpayer.additionalInfo.isAttested) {
                                retentionPeriod =
                                    this.props.extensionCompanyData.retentionSettingsModel.attestRetentionPeriod;
                            } else {
                                retentionPeriod =
                                    this.props.extensionCompanyData.retentionSettingsModel.retentionPeriod;
                            }
                        }

                        if (isPartnership(newData)) {
                            if (newData.partnership.additionalInfo.isAttested) {
                                retentionPeriod =
                                    this.props.extensionCompanyData.retentionSettingsModel.attestRetentionPeriod;
                            } else {
                                retentionPeriod =
                                    this.props.extensionCompanyData.retentionSettingsModel.retentionPeriod;
                            }
                        }
                    }
                    newData.documentSettings.documentRetentionSetting.retentionPeriod = retentionPeriod;
                }

                this.setState({
                    retentionPolicyState: {
                        show: true,
                        model: newData,
                        saveCheckId: undefined,
                    },
                });
            });
        }

        this.setState({
            retentionPolicyState: {
                show: true,
                model: this.getStoreItem(rowIndex).taxReturn,
                saveCheckId: undefined,
            },
        });
    }

    private onRetentionPolicySave(id: number, isAttested: boolean, retentionPeriod: number) {
        let customColumn: IColumnValues | undefined;
        let taxDocument = this.props.taxDocuments[id].taxReturn;
        taxDocument.documentSettings.documentRetentionSetting.retentionPeriod = retentionPeriod;
        taxDocument.documentSettings.isModified = true;

        if (isIndividual(taxDocument) || isMutual(taxDocument)) {
            taxDocument.taxpayer.isModified = true;
            taxDocument.taxpayer.additionalInfo.isAttested = isAttested;
        } else if (isPartnership(taxDocument)) {
            taxDocument.partnership.isModified = true;
            taxDocument.partnership.additionalInfo.isAttested = isAttested;
        }

        this.props.saveTaxDocument(taxDocument, RBACKeys[this.pageId].retentionPolicy, customColumn, data => {
            let query = this.buildQuery(this.state.page, this.state.pageSize);
            this.props.requestDeliveredReturns(query, true);
        });

        this.setState({
            retentionPolicyState: {
                show: true,
                model: undefined,
                saveCheckId: id,
            },
        });

        logClientEvent(`${LogEventConstants.Common.UpdateExtensionRetention} ${this.pageTitle}`, {
            count: 1,
            page: this.pageTitle,
            documentId: id,
        });
    }

    private onRetentionPolicyCancel(event: any) {
        this.setState({
            retentionPolicyState: {
                show: false,
                model: undefined,
                saveCheckId: undefined,
            },
        });
    }
    //====================Retention Policy Ends Here===============================

    //====================Download Returns Starts Here===============================

    private onDownloadReturnOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);
        ids.map((id, i) => {
            this.props.requestTaxDocument(id, true, undefined, undefined, () => {
                this.props.requestDownloadableReturns(id, RBACKeys[this.pageId].downloadReturns);
                this.setState({
                    downloadReturnState: {
                        show: true,
                        model: this.getStoreItem(rowIndex).taxReturn,
                        saveCheckId: undefined,
                    },
                });
            });
        });

        logClientEvent(`${LogEventConstants.Common.DownloadExtensions} ${this.pageTitle}`, {
            count: ids?.length ?? 0,
            page: this.pageTitle,
        });
    }

    private onDownloadReturnAll(documentGuid: string, documentId: number, taxYear: number, isEfileForm: boolean) {
        ShowLoader(Common.OverlayMessages.Downloading);
        const model = this.props.taxDocuments[documentId].taxReturn;
        const isEsignedOrManuallySigned =
            model.signatureStatus.toString() == SignatureStatus[SignatureStatus.AwaitingESign] ||
            model.signatureStatus.toString() == SignatureStatus[SignatureStatus.AwaitingUpload] ||
            model.signatureStatus.toString() == SignatureStatus[SignatureStatus.ManuallySigned] ||
            model.signatureStatus.toString() == SignatureStatus[SignatureStatus.PartiallySigned]
                ? false
                : true;

        this.props.downloadAllReturn(
            documentGuid,
            documentId,
            taxYear,
            isEfileForm,
            model.clientId,
            model.engagementType,
            getUnformattedClientName(model),
            isEfileForm ? RBACKeys[this.pageId].downloadEFileFormsGrid : RBACKeys[this.pageId].downloadReturns,
            isEsignedOrManuallySigned
        );
        if (isEfileForm) {
            let state = { ...this.state.downloadEformFilesState };
            state.show = false;
            this.setState({ downloadEformFilesState: state });
        } else {
            let state = { ...this.state.downloadReturnState };
            state.show = false;
            this.setState({ downloadReturnState: state });
        }
    }

    private onDownloadFile(
        documentGuid: string,
        documentId: number,
        taxYear: number,
        fileName: string,
        groupId: number,
        signedDocumentId: number,
        isDownloadReturn: boolean = false,
        clientId: string,
        isEfileForm: boolean,
        isIndividualFileDownload: boolean = false
    ) {
        let isUpdateDocTransaction = false;
        const model = this.props.taxDocuments[documentId].taxReturn;
        let isSinglePrinted = false;
        if (!isIndividualFileDownload && model.documentSettings) {
            if (model.documentSettings.deliverySettings) {
                const deliveryMode = model.documentSettings.deliverySettings.deliveryMode.toString();
                if (
                    deliveryMode === DeliveryMode[DeliveryMode.PaperFiled] ||
                    deliveryMode === DeliveryMode.PaperFiled.toString()
                ) {
                    const deliveryType =
                        model.documentSettings.deliverySettings.paperReturnSettings.deliveryType.toString();
                    if (
                        deliveryType === PaperReturnDeliveryType[PaperReturnDeliveryType.SingleFile] ||
                        deliveryType === PaperReturnDeliveryType.SingleFile.toString()
                    ) {
                        isSinglePrinted = true;
                    }
                }
            }
        }
        if (groupId == DocumentGroups.EFile && !isDownloadReturn) {
            isUpdateDocTransaction = true;
        }
        this.props.downloadReturn(
            documentGuid,
            documentId,
            taxYear,
            fileName,
            groupId,
            signedDocumentId,
            clientId,
            getUnformattedClientName(model),
            model.engagementType,
            isSinglePrinted,
            isEfileForm ? RBACKeys[this.pageId].downloadEFileFormsGrid : RBACKeys[this.pageId].downloadReturns,
            this.onPageReload,
            isUpdateDocTransaction
        );
    }

    private onDownloadAdditionalDocuments = (
        documentGuid: string,
        documentId: number,
        taxYear: number,
        clientId: string,
        additionalEsignDocuments: IDocumentData[],
        isSigned?: boolean
    ) => {
        let name: any;
        let taxReturn: any = this.props.taxDocuments[documentId].taxReturn;

        if (isPartnership(taxReturn)) {
            name = taxReturn.partnership.name;
        } else {
            if (isIndividual(taxReturn) || isMutual(taxReturn)) {
                name = taxReturn.taxpayer.name;
            }
        }
        this.props.downloadAdditionalEsignDocuments(
            documentGuid,
            documentId,
            taxYear,
            clientId,
            name,
            taxReturn.engagementType,
            additionalEsignDocuments,
            RBACKeys[this.pageId].downloadReturns,
            isSigned,
            this.onPageReload
        );

        logClientEvent(`${LogEventConstants.Common.DownloadEsign} ${this.pageTitle}`, {
            count: 1,
            page: this.pageTitle,
        });
    };

    private onDownloadReturnCancel() {
        this.setState({
            downloadReturnState: {
                show: false,
                model: undefined,
                saveCheckId: undefined,
            },
        });
    }

    //====================Download Returns End Here===============================

    //====================Download Efile Forms Starts Here===============================

    private onDownloadEfileFormsOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);
        ids.map((id, i) => {
            if (this.props.taxDocuments[id].isFullyLoaded == undefined || !this.props.taxDocuments[id].isFullyLoaded) {
                this.loadFullTaxDocument(id).then(() => {
                    this.props.requestDownloadableEfileForms(id, RBACKeys[this.pageId].downloadEFileFormsGrid);
                    this.setState({
                        downloadEformFilesState: {
                            show: true,
                            model: this.getStoreItem(rowIndex).taxReturn,
                            saveCheckId: undefined,
                        },
                    });
                });
            } else {
                this.props.requestDownloadableEfileForms(id, RBACKeys[this.pageId].downloadEFileFormsGrid);
            }
        });
        this.setState({
            downloadEformFilesState: {
                show: true,
                model: this.getStoreItem(rowIndex).taxReturn,
                saveCheckId: undefined,
            },
        });
    }

    private loadFullTaxDocument = (id: number): Promise<any> => {
        const promise = new Promise(resolve => {
            this.props.requestTaxDocument(id, true, undefined, undefined, data => {
                resolve(data);
            });
        });
        return promise;
    };
    isSelectedFileVaildForEfileDownload = () => {
        const selectedDocuments = this.selectedDocuments();
        let isValid = true;
        let i = 0;
        for (i = 0; i < selectedDocuments.length; i++) {
            const signatureStatus: string = selectedDocuments[i].document.signatureStatus.toString();
            if (
                signatureStatus === SignatureStatus[SignatureStatus.ManuallySigned] ||
                signatureStatus === SignatureStatus[SignatureStatus.AwaitingESign] ||
                signatureStatus === SignatureStatus[SignatureStatus.AwaitingUpload] ||
                signatureStatus === SignatureStatus[SignatureStatus.DeliveredToTaxCaddy] ||
                signatureStatus === SignatureStatus[SignatureStatus.AlternateDelivery] ||
                signatureStatus === SignatureStatus[SignatureStatus.DownloadingFailed] ||
                signatureStatus === SignatureStatus[SignatureStatus.AwaitingReview] ||
                signatureStatus === SignatureStatus[SignatureStatus.PartiallyReviewed] ||
                signatureStatus === SignatureStatus[SignatureStatus.Reviewed] ||
                signatureStatus === SignatureStatus[SignatureStatus.PartiallySigned]
            ) {
                isValid = false;
                break;
            }
        }
        return isValid;
    };
    private onMultipleDownloadEfileForms() {
        const ids = this.selectedDocumentIds();
        if (ids.length === 0) {
            VenusNotifier.Warning('Please select one or more documents', null);
            return;
        }
        let isValid = this.isSelectedFileVaildForEfileDownload();
        if (isValid) {
            if (this.state.isBulkSelectionEnabled) {
                this.multipleDownloadEfileFormsBulk(ids);
            } else {
                if (ids.length > 1) {
                    if (
                        this.props.extensionUserSettings.defaultSettings.download.downloadType ==
                        DefaultDownloadInfoType.PromptMeEveryTime
                    ) {
                        this.setState({
                            selectedDownloadType: DefaultDownloadInfoType.DownloadToMyDownloads,
                            downloadTypeOptionState: {
                                show: true,
                                selected: undefined,
                                saveCheckIds: undefined,
                                remove: false,
                            },
                        });
                    } else {
                        this.onEfileBulkSelectionConfirm(ids);
                    }
                } else {
                    ShowLoader(Common.OverlayMessages.Downloading);
                    this.props.downloadEfileForm(
                        ids,
                        RBACKeys[this.pageId].downloadEFileFormsGrid,
                        this.state.isBulkSelectionEnabled ? this.onPageReload : this.clearSelection
                    );
                }
            }
        } else {
            VenusNotifier.Error(
                DeliveredReturnsConstants.ErrorMessage.UploadedESignedEfileFormsCanOnlyDownloadable,
                null
            );
        }
    }

    private multipleDownloadEfileFormsBulk = (ids: number[]) => {
        HideLoader();
        bootbox.confirm({
            message: DeliveredReturnsConstants.BulkOperationMessage.downloadEFileWarning,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default',
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Yes',
                    className: 'btn-info',
                },
            },
            callback: (result: boolean) => {
                if (result == true) {
                    this.onEfileBulkSelectionConfirm(ids);
                }
            },
        });
    };

    private onEfileBulkSelectionConfirm = (ids: number[]) => {
        let query = this.getBulkOperationQuery();
        let downloadType = this.state.selectedDownloadType;
        query.isDownloadNow = downloadType === DefaultDownloadInfoType.DownloadNow ? true : false;
        this.props.downloadEFileFormsBulk(
            ids,
            this.state.isBulkSelectionEnabled,
            query,
            this.state.selectedDownloadType,
            RBACKeys[this.pageId].downloadEFileFormsHeader,
            this.clearSelection
        );
    };

    private onDownloadEfileFormsCancel() {
        this.setState({
            downloadEformFilesState: {
                show: false,
                model: undefined,
                saveCheckId: undefined,
            },
        });
    }

    //====================Download Efile Forms End Here===============================

    //====================Upload Forms Starts Here=============================

    private onUploadFormsOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);
        ids.map((id, i) => {
            if (this.props.taxDocuments[id].isFullyLoaded == undefined || !this.props.taxDocuments[id].isFullyLoaded) {
                this.props.requestTaxDocument(id, true);
            }
        });

        this.setState({
            uploadFormsState: {
                show: true,
                model: this.getStoreItem(rowIndex).taxReturn,
                saveCheckId: undefined,
            },
        });
    }

    private onUploadFormsSuccess(id: number, files: string[]) {
        let taxDocument = this.props.taxDocuments[id].taxReturn;
        if (taxDocument.documentStatus === DocumentStatus.DOWNLOADPDF) {
            taxDocument.signatureStatus = SignatureStatus.AlternateAndUploaded;
        } else {
            taxDocument.signatureStatus = SignatureStatus.Uploaded;
        }
        taxDocument.isModified = true;

        this.props.submitUploadForms(taxDocument, files, RBACKeys[this.pageId].uploadForms);

        this.setState({
            uploadFormsState: {
                show: true,
                model: undefined,
                saveCheckId: id,
            },
        });
    }

    private onUploadFormsCancel() {
        this.setState({
            uploadFormsState: {
                show: false,
                model: undefined,
                saveCheckId: undefined,
            },
        });
    }

    //====================Upload Forms Ends Here===============================

    //====================Signed Details Starts Here=============================

    private onSignedDetailsOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);
        ids.map((id, i) => {
            this.props.requestSignedDetails(id);
        });

        this.setState({
            signedDetailsState: {
                show: true,
                model: this.getStoreItem(rowIndex).taxReturn,
                saveCheckId: undefined,
            },
        });
    }

    private onSignedDetailsCancel() {
        this.setState({
            signedDetailsState: {
                show: false,
                model: undefined,
                saveCheckId: undefined,
            },
        });
    }

    //====================Signed Details Ends Here===============================

    //====================Download History Starts Here=============================

    private onDownloadHistoryOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);
        ids.map((id, i) => {
            this.props.requestTaxDocumentDownloadHistory(id);
        });

        this.setState({
            downloadHistoryState: {
                show: true,
                model: this.getStoreItem(rowIndex).taxReturn,
                saveCheckId: undefined,
            },
        });
    }

    private onDownloadHistoryCancel() {
        this.setState({
            downloadHistoryState: {
                show: false,
                model: undefined,
                saveCheckId: undefined,
            },
        });
    }

    //====================Download History Ends Here===============================

    //==================TaxPayerView ==============================================

    private onTaxPayerViewClick(rowIndex: number) {
        const id = this.props.delivered.deliveredReturnTableModel.documents[rowIndex].document.id;
        this.props.requestTaxDocument(id, true, undefined, undefined, data => {
            this.setState({
                clientViewState: {
                    model: data,
                    saveCheckId: undefined,
                    show: true,
                },
            });
        });
    }

    private onTaxPayerViewLinkClick(rowIndex: number, clientType: ClientTypes) {
        let id = this.props.delivered.deliveredReturnTableModel.documents[rowIndex].document.id;
        ShowLoader();
        this.props.requestTaxDocument(id, true, true, clientType);

        logClientEvent(`${LogEventConstants.Common.OpenClientView} ${this.pageTitle}`, {
            count: 1,
            page: this.pageTitle,
            documentId: id,
        });
    }

    //==================TaxpayerView End===========================================

    //==================Delete Tax Return Start ==============================================
    private onDeleteTaxReturnOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);

        if (ids.length == 0) {
            VenusNotifier.Warning(DeleteTaxReturnConstants.SelectDocumentWarning, null);
            return;
        }

        let obj = this;
        bootbox.confirm({
            title: DeleteTaxReturnConstants.Title,
            message: DeleteTaxReturnConstants.ConfirmDeletion,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default',
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Confirm',
                    className: 'btn-info',
                },
            },
            callback: (result: boolean) => {
                if (result) {
                    obj.state.isBulkSelectionEnabled
                        ? obj.onBulkDeleteDeliveredReturns()
                        : obj.onDeleteTaxReturnSave(ids);

                    logClientEvent(`${LogEventConstants.Common.DeleteExtension} ${this.pageTitle}`, {
                        count: ids?.length ?? 0,
                        page: this.pageTitle,
                    });
                }
            },
        });
    }

    private onDeleteTaxReturnSave(ids: number[]) {
        ShowLoader(Common.OverlayMessages.Deleting);
        this.props.deleteDeliveredTaxDocument(ids, this.onDeleteCompletion, RBACKeys[this.pageId].deleteButton);
        logClientEvent(`${LogEventConstants.Common.DeleteMultiExtensions} ${this.pageTitle}`, {
            count: ids.length,
            page: this.pageTitle,
        });
        this.setState({
            deleteReturnsState: {
                show: false,
                model: undefined,
                saveCheckId: ids[0],
            },
        });
    }

    private onBulkDeleteDeliveredReturns = () => {
        let query = this.getBulkOperationQuery();
        this.props.deleteDocumentsBulk(query, RBACKeys[this.pageId].deleteButton, () => {
            this.setState({
                deleteReturnsState: {
                    show: false,
                    model: undefined,
                    saveCheckId: undefined,
                },
            });
        });
        this.clearSelection();
    };

    private onDeleteCompletion() {
        HideLoader();
        this.onPageReload();
    }
    //==================Delete Tax Return End ==============================================

    //==================Screen Share Start=============================================
    private onScreenShareViewClick(rowIndex: number) {
        const id = this.props.delivered.deliveredReturnTableModel.documents[rowIndex].document.id;
        const model = this.props.taxDocuments[id].taxReturn;
        if (!Helper.isClientInfoLoaded(model)) {
            this.props.requestTaxDocument(id, true);
        }
        this.setState({
            screenShareState: {
                show: false,
                model: this.getStoreItem(rowIndex).taxReturn,
                saveCheckId: undefined,
            },
            screenShareOtp: '',
        });
        this.ssShow = true;
    }

    private onScreenShareOpen(clientId: string, rowIndex: number = NO_INDEX) {
        this.props.generateTimeBasedOTP(clientId, rowIndex, this.otpCallback);
        this.setState({
            screenShareState: {
                show: true,
                model: this.getStoreItem(rowIndex).taxReturn,
                saveCheckId: undefined,
            },
            screenShareOtp: '',
        });
        this.ssShow = false;
        this.ssClientId = clientId;
    }

    private otpCallback = (rowIndex: number, otp: string) => {
        this.setState({
            screenShareOtp: otp,
        });
    };

    private onScreenShareStart(clientGuid: string) {
        ShowLoader(Common.OverlayMessages.ScreenSharing);
        this.props.requestScreenShareUrl(clientGuid);
        this.setState(prevState => ({ screenShareState: { ...prevState.screenShareState, show: false } }));
    }

    private onScreenShareCancel() {
        this.setState({
            screenShareState: {
                show: false,
                model: undefined,
                saveCheckId: undefined,
            },
        });
    }

    //==================Screen Share End===============================================
    clearFilters = () => {
        this.setState({ selectedRows: [] }, this.onPageReload);
        let deliveredReturnsTable: any = this.refs.DeliveredReturnsTable;
        deliveredReturnsTable.refs.Filters?.setState({ activeFilter: '', appliedFilters: [] });
    };

    buildQuery(page: number, pageSize: number) {
        return (
            '?PageNo=' +
            page +
            '&SortBy=' +
            this.state.sortName +
            '&SortOrder=' +
            this.state.sortOrder +
            '&SearchString=' +
            this.state.searchString +
            '&selectedTaxYear=' +
            this.state.filterTaxYear +
            '&PageSize=' +
            pageSize +
            '&filterName=' +
            this.state.filterName +
            '&filterClientId=' +
            this.state.filterClientId +
            '&filterPartner=' +
            this.state.filterPartner +
            '&filterStatus=' +
            this.state.filterStatus +
            '&filterLocation=' +
            (this.state.filterLocation === '-1' ? '' : this.state.filterLocation) +
            '&filterDeliveredOn=' +
            (this.state.filterDeliveredOn || '') +
            '&filterEngagementType=' +
            this.state.filterEngagementType +
            '&filterCustomColumn=' +
            this.state.filterCustomColumn +
            '&filterDownloadedBy=' +
            this.state.filterDownloadedBy +
            '&filterLastReminderOn=' +
            (this.state.filterLastReminderOn || '') +
            '&filterRetentionPeriod=' +
            (this.state.filterRetentionPeriod || '') +
            '&filterSentBy=' +
            (this.state.filterSentBy === '-1' ? '' : this.state.filterSentBy) +
            '&isArchived=' +
            this.props.isArchived +
            '&filterReturnStatus=' +
            this.state.filterReturnStatus
        );
    }

    loadDeliveredReturns() {
        let queryString = this.buildQuery(this.state.page, this.state.pageSize);
        this.props.requestDeliveredReturns(
            queryString,
            undefined,
            undefined,
            this.state.page > 1 ? this.reloadOnNoDataFound : undefined
        );
    }

    fetchDeliveredReturns(page: number, sizePerPage: number) {
        let queryString = this.buildQuery(page, sizePerPage);
        this.setState({ page: page, pageSize: sizePerPage }, () => {
            this.props.requestDeliveredReturns(
                queryString,
                undefined,
                undefined,
                this.state.page > 1 ? this.reloadOnNoDataFound : undefined
            );
        });
    }

    private selectedDocumentIds() {
        let ids: number[] = [];
        this.state.selectedRows.map((row, i) => {
            let selectedDocument = this.props.delivered.deliveredReturnTableModel.documents[row];
            if (selectedDocument) {
                ids.push(selectedDocument.document.id);
            }
        });
        return ids;
    }

    private selectedDocumentIdsAndLoadingStatus(): DeliveredReturnsState.ISelectedDocument[] {
        const selectedDocuments: DeliveredReturnsState.ISelectedDocument[] = [];
        const ids = this.selectedDocumentIds();
        ids.map((id, index) => {
            selectedDocuments.push({ id: id, isfullyLoaded: this.props.taxDocuments[id].isFullyLoaded ? true : false });
        });
        return selectedDocuments;
    }

    private selectedDocuments() {
        const docs: IDeliveredTaxDocument[] = [];
        for (const i in this.state.selectedRows) {
            let row = this.state.selectedRows[i];
            let selectedDocument = this.props.delivered.deliveredReturnTableModel.documents[row];
            if (selectedDocument) {
                docs.push(selectedDocument);
            }
        }
        return docs;
    }

    private lazyLoadSelected(ids: number[]) {
        ids.forEach((id, i) => {
            this.props.requestTaxDocument(id, false);
        });
    }

    private isLoaded(allReturns: TaxDocumentStore.ITaxDocumentDictionary, ids: number[]) {
        for (const i in ids) {
            if (allReturns[ids[i]] && allReturns[ids[i]].isLoading) {
                return false;
            }
        }
        return true;
    }

    private onPopupOpen(rowIndex: number) {
        if (rowIndex !== NO_INDEX) {
            return [this.props.delivered.deliveredReturnTableModel.documents[rowIndex].document.id];
        }
        if (this.state.selectedRows.length === 0) {
            return [];
        }
        return this.selectedDocumentIds();
    }

    private getStoreItem(index: number) {
        const id = this.props.delivered.deliveredReturnTableModel.documents[index].document.id;
        return this.props.taxDocuments[id];
    }

    private onUpdateCompletion(
        taxDocuments: TaxDocumentStore.ITaxDocumentDictionary,
        stateKey: string,
        state: DeliveredReturnsState.IPopupStateSingle
    ) {
        if (state.saveCheckId && this.isLoaded(taxDocuments, [state.saveCheckId])) {
            if (stateKey != 'reportProblemState') {
                if (taxDocuments[state.saveCheckId] && taxDocuments[state.saveCheckId].error) {
                    VenusNotifier.Error(errorMsg[stateKey], stateKey);
                    this.props.requestTaxDocument(state.saveCheckId, true);
                } else {
                    if (
                        stateKey == 'resendAccessLinkState' ||
                        stateKey == 'retentionPolicyState' ||
                        stateKey == 'voucherReminderState'
                    ) {
                        VenusNotifier.Success(successMsg[stateKey], stateKey);
                    } else {
                        stateKey !== 'sendReminderState' && VenusNotifier.Success(successMsg[stateKey], stateKey);
                        this.setState({ selectedRows: [], showBulkSelectionMessage: false });
                    }
                }
            }
            this.setState(prevState => ({
                ...prevState,
                [stateKey]: {
                    show: false,
                    model: undefined,
                    saveCheckId: undefined,
                },
            }));
        }
    }

    private onUpdateCompletionMultiple(
        taxDocuments: TaxDocumentStore.ITaxDocumentDictionary,
        stateKey: string,
        state: DeliveredReturnsState.IPopupStateMultiple
    ) {
        if (state.saveCheckIds && this.isLoaded(taxDocuments, state.saveCheckIds)) {
            let err = false;
            state.saveCheckIds.map((id, i) => {
                if (taxDocuments[id] && taxDocuments[id].error) {
                    this.props.requestTaxDocument(id, true);
                    err = true;
                }
            });
            if (err) {
                VenusNotifier.Error(errorMsg[stateKey], stateKey);
            } else if (state.remove) {
                VenusNotifier.Success(successMsg['customColumnRemoveState'], stateKey);
                this.setState({ selectedRows: [], showBulkSelectionMessage: false });
            } else {
                VenusNotifier.Success(successMsg[stateKey], stateKey);
                this.setState({ selectedRows: [], showBulkSelectionMessage: false });
            }

            this.setState(prevState => ({
                ...prevState,
                [stateKey]: {
                    show: false,
                    model: undefined,
                    saveCheckId: undefined,
                },
            }));
        }
    }
    private isTaxReturnNull = (taxDocuments: TaxDocumentStore.ITaxDocumentDictionary, id: number): boolean => {
        if (taxDocuments[id] && taxDocuments[id].taxReturn != undefined) {
            return true;
        }
        return false;
    };
    private checkForPopupCompletion(taxDocuments: TaxDocumentStore.ITaxDocumentDictionary) {
        this.onUpdateCompletion(taxDocuments, 'editClientInfoState', this.state.editClientInfoState);
        this.onUpdateCompletionMultiple(taxDocuments, 'changeStatusState', this.state.changeStatusState);
        this.onUpdateCompletion(taxDocuments, 'reportProblemState', this.state.reportProblemState);
        this.onUpdateCompletionMultiple(taxDocuments, 'customColumnState', this.state.customColumnState);
        this.onUpdateCompletionMultiple(taxDocuments, 'archiveReturnsState', this.state.archiveReturnsState);
        this.onUpdateCompletionMultiple(taxDocuments, 'setAccessState', this.state.setAccessState);
        this.onUpdateCompletionMultiple(taxDocuments, 'sendReminderState', this.state.sendReminderState);
        this.onUpdateCompletion(taxDocuments, 'resendAccessLinkState', this.state.resendAccessLinkState);
        this.onUpdateCompletion(taxDocuments, 'recallReturnState', this.state.recallReturnState);
        this.onUpdateCompletion(taxDocuments, 'uploadFormsState', this.state.uploadFormsState);
        this.onUpdateCompletion(taxDocuments, 'clientViewState', this.state.clientViewState);
        this.onUpdateCompletionMultiple(taxDocuments, 'restoreReturnsState', this.state.restoreReturnsState);
        this.onUpdateCompletion(taxDocuments, 'deleteReturnsState', this.state.deleteReturnsState);
        this.onUpdateCompletion(taxDocuments, 'retentionPolicyState', this.state.retentionPolicyState);
    }

    private updateStates(props: DeliveredReturnsProps) {
        if (
            this.state.editClientInfoState.model &&
            this.isTaxReturnNull(props.taxDocuments, this.state.editClientInfoState.model.id)
        ) {
            this.setState({
                editClientInfoState: {
                    ...this.state.editClientInfoState,
                    model: props.taxDocuments[this.state.editClientInfoState.model.id].taxReturn,
                },
            });
        }

        if (
            this.state.reportProblemState.model &&
            this.isTaxReturnNull(props.taxDocuments, this.state.reportProblemState.model.id)
        ) {
            this.setState({
                reportProblemState: {
                    ...this.state.reportProblemState,
                    model: props.taxDocuments[this.state.reportProblemState.model.id].taxReturn,
                },
            });
        }

        if (
            this.state.voucherReminderState.model &&
            this.isTaxReturnNull(props.taxDocuments, this.state.voucherReminderState.model.id)
        ) {
            this.setState({
                voucherReminderState: {
                    ...this.state.voucherReminderState,
                    model: props.taxDocuments[this.state.voucherReminderState.model.id].taxReturn,
                },
            });
        }

        if (
            this.state.retentionPolicyState.model &&
            this.isTaxReturnNull(props.taxDocuments, this.state.retentionPolicyState.model.id)
        ) {
            this.setState({
                retentionPolicyState: {
                    ...this.state.retentionPolicyState,
                    model: props.taxDocuments[this.state.retentionPolicyState.model.id].taxReturn,
                },
            });
        }

        if (
            this.state.clientViewState.model &&
            this.isTaxReturnNull(props.taxDocuments, this.state.clientViewState.model.id)
        ) {
            this.setState({
                clientViewState: {
                    ...this.state.clientViewState,
                    model: props.taxDocuments[this.state.clientViewState.model.id].taxReturn,
                },
            });
        }

        if (this.state.isBulkSelectionEnabled) {
            let selectedRows: number[] = [];
            props.delivered.deliveredReturnTableModel.documents.forEach((value, index) => {
                this.state.deSelectedRows.find(m => m === value.document.id) === undefined && selectedRows.push(index);
            });

            this.setState({ selectedRows: selectedRows });
        }

        if (props.columnSettings) {
            if (props.columnSettings.DeliveredReturnsColumnSettings && !props.isArchived) {
                this.setState({
                    columnSettings: props.columnSettings.DeliveredReturnsColumnSettings,
                });
            }
            if (props.columnSettings.ArchivedReturnsColumnSettings && props.isArchived) {
                this.setState({
                    columnSettings: props.columnSettings.ArchivedReturnsColumnSettings,
                });
            }
        }
        if (this.state.selectedDocuments.length === 1) {
            const selectedID = this.state.selectedDocuments[0].document.id;
            const selectedDocument = props.delivered.deliveredReturnTableModel.documents.find(
                doc => doc.document.id === selectedID
            );
            this.setState({ selectedDocuments: [selectedDocument] });
        }
    }
    private getDefaultFilter = (filters: IFilters[]): string | undefined => {
        let existsMasterFilter = filters.findIndex(x => x.isMasterFilter) > -1 ? true : false;
        let existsDefaultFilter = filters.findIndex(x => x.isDefaultFilter) > -1 ? true : false;

        if (existsMasterFilter) {
            let mName = filters.find(x => x.isMasterFilter);
            return mName ? mName.name : undefined;
        } else if (existsDefaultFilter) {
            let dName = filters.find(x => x.isDefaultFilter);
            return dName ? dName.name : undefined;
        }
        return undefined;
    };

    private getTaxReturnFromState = (): ITaxReturn => {
        return (this.state.clientViewState.model || this.state.screenShareState.model) as ITaxReturn;
    };
    onDownloadTypeCancel = () => {
        if (this.state.loadSpinner) {
            let downloadType =
                this.props.extensionUserSettings.defaultSettings.download.downloadType ||
                DefaultDownloadInfoType.PromptMeEveryTime;
            this.setState({
                selectedDownloadType: downloadType,
            });
        }
        this.setState({
            downloadTypeOptionState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined,
                remove: false,
            },
            loadSpinner: false,
        });
    };

    onDownloadOptionChange = (value: any) => {
        if (this.state.selectedDownloadType == DefaultDownloadInfoType.DownloadNow) {
            this.setState({ selectedDownloadType: DefaultDownloadInfoType.DownloadToMyDownloads });
        } else {
            this.setState({ selectedDownloadType: DefaultDownloadInfoType.DownloadNow });
        }
    };

    onDownloadOptionSave = (value: any) => {
        var settings = this.props.extensionUserSettings;
        if (this.state.selectedDefaultDownloadType == true) {
            settings.defaultSettings.download.rememberDefaultDownload = true;
            settings.defaultSettings.download.downloadType = this.state.selectedDownloadType;
            this.props.saveExtensionUserSettings(settings);
        }

        if (this.state.loadSpinner) {
            let downloadType =
                this.props.extensionUserSettings.defaultSettings.download.downloadType ||
                DefaultDownloadInfoType.PromptMeEveryTime;

            this.onExportToExcel(() => {
                this.setState({
                    loadSpinner: false,
                    selectedDownloadType: downloadType,
                });
            });
        } else {
            let ids = this.selectedDocumentIds();
            if (ids.length > 0) {
                this.onEfileBulkSelectionConfirm(ids);
            } else {
                ShowLoader(Common.OverlayMessages.Downloading);
                this.props.downloadEfileForm(
                    ids,
                    RBACKeys[this.pageId].downloadEFileFormsGrid,
                    this.state.isBulkSelectionEnabled ? this.onPageReload : this.clearSelection
                );
            }
        }

        this.setState({
            downloadTypeOptionState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined,
                remove: false,
            },
        });

        let downloadType =
            this.props.extensionUserSettings?.defaultSettings?.download?.downloadType ||
            DefaultDownloadInfoType.PromptMeEveryTime;
    };
    useDefaultDownloadType = (event: any) => {
        if (this.state.selectedDefaultDownloadType == false) {
            this.setState({ selectedDefaultDownloadType: true });
        } else {
            this.setState({ selectedDefaultDownloadType: false });
        }
    };

    //==================Client management Start===============================================
    private onSaveTaxDocument = () => {
        let taxReturn: ITaxReturn = this.state.editClientSaveState.taxReturn;
        logger.trackTrace(
            `Edit CLient Info in Delivered Reports Screen for DocumentId: ${taxReturn.id?.toString()} and DocumentGuid: ${
                taxReturn.documentGuid
            }`
        );

        this.props.saveTaxDocument(
            taxReturn,
            RBACKeys[this.pageId].editClientInfo,
            undefined,
            data => {
                this.state.editClientSaveState.callback && this.state.editClientSaveState.callback();
                if (this.state.editClientSaveState.isEmailUpdated) {
                    this.onTaxDocumentUpdated(taxReturn);
                }
                let query = this.buildQuery(this.state.page, this.state.pageSize);
                this.props.requestDeliveredReturns(
                    query,
                    true,
                    undefined,
                    this.state.page > 1 ? this.reloadOnNoDataFound : undefined
                );
                this.setState({
                    editClientSaveState: DeliveredReturnsState.intialEditClientSaveState,
                });
            },
            this.sendMail,
            this.state.editClientSaveState.isMailSend,
            this.state.editClientSaveState.clientGUIDs,
            true
        );

        logClientEvent(`${LogEventConstants.Common.EditClientInfo} ${this.pageTitle}`, {
            count: 1,
            page: this.pageTitle,
            documentId: taxReturn.id,
        });
    };

    private compareClientInfo = (clientInfo: ClientInfoWithEroCheck) => {
        this.setState({ clientInfo: clientInfo.clientInfo });
        if (this.isUserHasAccessToDocumentLocation()) {
            compareClientInfo(
                getNewClientInfo(
                    this.state.editClientSaveState.taxReturn,
                    this.state.clientBasicInfoId,
                    this.state.clientInfo
                ),
                this.editClientInfoResourceId,
                this.setClientInfoComparisonResult,
                this.clientManagementCancel,
                this.newClientCallback,
                this.setClientBasicInfoId
            );
        } else {
            this.setState({ showLocationMismatchActionModel: true });
        }
    };

    //#region ClientInfo Comparision
    private setClientInfoComparisonResult = (clientInfoComparisonResult: IClientInfoComparisonResult) => {
        this.setState({
            editClientInfoState: {
                show: false,
                model: undefined,
                saveCheckId: this.state.editClientSaveState.id,
            },
        });
        this.setState({ clientInfoComparisonResult }, () => {
            this.setState({ showMismatchModal: true, popupInProgress: false });
        });
    };

    private setClientBasicInfoId = (id: number) => {
        this.setState({ clientBasicInfoId: id });
    };
    private clientManagementSave = () => {
        if (this.state.clientInfoComparisonResult.comparisonStatus === IClientInfoMismatchType.NewClient) {
            this.addNewClientInCM();
        } else {
            this.editClientInfoInCM();
        }

        this.setState({ showMismatchModal: false, popupInProgress: true });
        this.setState({
            editClientInfoState: {
                show: true,
                model: undefined,
                saveCheckId: this.state.editClientSaveState.id,
            },
        });
    };

    private addNewClientInCM = () => {
        const clientInfo = getNewClientInfo(
            this.state.editClientSaveState.taxReturn,
            this.state.clientBasicInfoId,
            this.state.clientInfo
        );
        addNewClientInfo(clientInfo, this.editClientInfoResourceId, this.onSaveTaxDocument);
    };

    private editClientInfoInCM = () => {
        const clientInfo = getNewClientInfo(
            this.state.editClientSaveState.taxReturn,
            this.state.clientBasicInfoId,
            this.state.clientInfo
        );
        editClientInfo(clientInfo, this.editClientInfoResourceId, this.onSaveTaxDocument);
    };

    private clientManagementCancel = () => {
        this.setState({
            showMismatchModal: false,
            popupInProgress: false,
        });
        this.onSaveTaxDocument();
        this.setState({
            editClientInfoState: {
                show: true,
                model: undefined,
                saveCheckId: this.state.editClientSaveState.id,
            },
        });
    };

    private newClientCallback = (clientInfo: IClientInfoViewModel) => {
        this.setClientInfoComparisonResult(setNewClientInfoComparisonResult(clientInfo));
    };

    private closeMismatchModal = () => {
        this.state.editClientSaveState.callback && this.state.editClientSaveState.callback();
        this.setState({
            editClientInfoState: {
                show: false,
                model: undefined,
                saveCheckId: 0,
            },
        });
        this.setState({ showMismatchModal: false, popupInProgress: false });
    };
    //==================Client management END===============================================

    public render() {
        const customColumnHeader = this.props.customColumnData.customColumn
            ? this.props.customColumnData.customColumn.header
            : '';

        return (
            <div className="user-assignment-content devilvered-archived-screen">
                <DeliveredReturnsHeader
                    pageId={this.pageId}
                    pageTitle={this.props.pageTitle}
                    onSetAccessOpen={this.onSetAccessOpen}
                    onArchiveReturnsOpen={this.onArchiveReturnsOpen}
                    onChangeStatusOpen={this.onChangeStatusOpen}
                    onCustomColumnOpen={this.onCustomColumnOpen}
                    onMultipleDownloadEfileForms={this.onMultipleDownloadEfileForms}
                    onSendReminder={this.OnSendReminder}
                    selectedDocumentCount={this.state.selectedRows.length}
                    customColumnTitle={
                        this.props.customColumnData.customColumn ? this.props.customColumnData.customColumn.header : ''
                    }
                    extensionUsers={this.props.usersStore.extensionUsers}
                    partners={this.props.usersStore.partners}
                    onPageReload={this.onPageReload}
                    company={this.props.company}
                    selectedERO={this.props.extensionUserSettings.defaultSettings.eroUser}
                    uploadTaxReturn={this.props.uploadTaxReturnStore}
                    getUploadLink={(url: string, callback: () => void) => {
                        this.props.getUploadLink(url, RBACKeys[this.pageId].uploadButton, callback);
                    }}
                    processTaxReturn={this.props.processTaxReturn}
                    submitTaxReturn={(url: string, taxData: string, callback: () => void) => {
                        this.props.submitTaxReturn(url, taxData, RBACKeys[this.pageId].uploadButton, callback);
                    }}
                    isArchived={this.props.isArchived}
                    onRestoreReturnOpen={this.onRestoreReturnsOpen}
                    isBulkSelectionEnabled={this.state.isBulkSelectionEnabled}
                    onDeleteTaxReturnOpen={this.onDeleteTaxReturnOpen}
                    companySettings={
                        this.props.company.companySettings ? this.props.company.companySettings : initialCompanySettings
                    }
                    userDefaultSettings={this.props.userSettings.settings}
                    groupInfo={this.props.groupedExtensionsStore.groupInfo}
                    updateGroupInfoState={this.props.requestGroupConfiguration}
                    defaultGroup={this.state.defaultGroup ? this.state.defaultGroup : ''}
                    groupAccess={this.props.groupedExtensionsStore.groupAccess}
                    loadExcelSpinner={this.state.loadSpinner}
                    taxSoftwareData={this.props.taxSoftwareData.taxSoftwareSettings}
                    getTaxDocumentsAccessMaps={(ids: number[], callback?: (result: IDocumentAccess) => void) => {
                        this.props.getTaxDocumentsAccessMaps(
                            ids,
                            true,
                            this.props.isArchived
                                ? RBACKeys.ArchivedExtension.uploadButton
                                : RBACKeys.ExtensionReport.uploadButton,
                            callback
                        );
                    }}
                />
                <br />
                {this.props.delivered.deliveredReturnTableModel.documents &&
                    this.props.userOfficeLocation.userLocation.length > 0 && (
                        <DeliveredReturnsTable
                            pageId={this.pageId}
                            ref="DeliveredReturnsTable"
                            onPageChange={this.onPageChange}
                            onSortChange={this.onSortChange}
                            onSearchChange={this.onSearchChange}
                            onFilterChange={this.onFilterChange}
                            pageNo={this.state.page}
                            onExportToExcel={this.onExportToExcel}
                            onFilterNameChange={this.onFilterNameChange}
                            onFilterSave={this.onFilterSave}
                            onFilterUpdate={this.onFilterUpdate}
                            onFilterDelete={this.onFilterDelete}
                            filterList={this.props.reportFilters.filters}
                            currentFilter={this.state.filter}
                            onSetDefaultFilter={this.onSetDefaultFilter}
                            onRemoveDefaultFilter={this.onRemoveDefaultFilter}
                            saveFilterShow={this.state.saveFilterShow}
                            onSaveFilterHide={this.onSaveFilterHide}
                            onSaveFilterShow={this.onSaveFilterShow}
                            totalRows={this.props.delivered.totalRowCount}
                            onClientTrackingOpen={this.onClientTrackingOpen}
                            onDownloadEfileFormsOpen={this.onDownloadEfileFormsOpen}
                            onDownloadReturnOpen={this.onDownloadReturnOpen}
                            onEditClientInfoOpen={this.onEditClientInfoOpen}
                            onRecallReturnOpen={this.onRecallReturnOpen}
                            onRemoveReport={this.onRemoveReport}
                            onReportProblemOpen={this.onReportProblemOpen}
                            onDeleteTaxReturnOpen={this.onDeleteTaxReturnOpen}
                            onResendAccessLinkOpen={this.onResendAccessLinkOpen}
                            onTaxpayerView={this.onTaxpayerView}
                            onUnlockReturnOpen={this.onUnlockReturnOpen}
                            onViewAccessCodeOpen={this.onViewAccessCodeOpen}
                            onRetentionPolicyOpen={this.onRetentionPolicyOpen}
                            onVoucherReminderOpen={this.onIndividualReminderOpen}
                            onSignedDetailsOpen={this.onSignedDetailsOpen}
                            onDownloadHistoryOpen={this.onDownloadHistoryOpen}
                            onUploadFormsOpen={this.onUploadFormsOpen}
                            onScreenShareViewClick={this.onScreenShareViewClick}
                            onScreenShareOpen={this.onScreenShareOpen}
                            showScreenShareSubMenu={this.ssShow}
                            isLoading={this.props.delivered.loading}
                            pageSize={pageSize}
                            onRestoreReturnOpen={this.onRestoreReturnsOpen}
                            isArchived={this.props.isArchived}
                            customColumnHeader={customColumnHeader}
                            onRowSelect={this.onRowSelect}
                            onSelectAll={this.onSelectAll}
                            selectedRows={this.state.selectedRows}
                            delivered={this.props.delivered}
                            defaultFilter={this.getDefaultFilter(this.props.reportFilters.filters)}
                            loadGrid={this.loadDeliveredReturns}
                            onClientViewClick={this.onTaxPayerViewClick}
                            onClientViewLinkClick={this.onTaxPayerViewLinkClick}
                            taxReturn={this.getTaxReturnFromState()}
                            showBulkSelectionMessage={this.state.showBulkSelectionMessage}
                            onBulkSelectionChange={this.onBulkSelectionChange}
                            clearSelection={this.clearSelection}
                            columnSettings={this.state.columnSettings}
                            saveColumnSettings={this.props.saveColumnSettings}
                            proxyFilter={this.proxyFilter}
                            filterType={this.reportFilterType}
                            onPageReload={this.onPageReload}
                            refreshDelay={this.state.refreshDelay}
                            loadSpinner={this.state.loadSpinner}
                            clientInfo={this.state.clientViewState.model}
                            userLocation={this.props.userOfficeLocation.userLocation}
                        onClearFilter={this.onClearFilter}
                        getLinkedMergeDetails={this.props.getLinkedMergeDetails}
                        />
                    )}

                <CustomColumn
                    show={this.state.customColumnState.show}
                    onCancel={this.onCustomColumnCancel}
                    onCustomColumnSave={
                        this.state.isBulkSelectionEnabled ? this.customColumnUpdateBulk : this.onCustomColumnSave
                    }
                    onCustomColumnRemove={
                        this.state.isBulkSelectionEnabled ? this.customColumnRemoveBulk : this.onCustomColumnRemove
                    }
                    customColumnDropdownValues={this.props.customColumnData.customColumn}
                    selectedDocuments={this.selectedDocumentIdsAndLoadingStatus()}
                    selectedColumnValue={this.state.selectedColumnValue}
                    onCustomColumnDropdownChange={this.onCustomColumnDropdownChange}
                    hideLoader={this.state.hideCustomCulumnLoader}
                />

                <DownloadReturns
                    show={this.state.downloadReturnState.show}
                    onCancel={this.onDownloadReturnCancel}
                    taxReturn={this.state.downloadReturnState.model as ITaxReturn}
                    downloadIndividual={this.onDownloadFile}
                    downloadAll={this.onDownloadReturnAll}
                    downloadAllAttachments={this.props.downloadAllAttachments}
                    isArchivedReturn={false}
                    downloadAdditionalEsignDocuments={this.onDownloadAdditionalDocuments}
                />

                <DownloadEfileForm
                    show={this.state.downloadEformFilesState.show}
                    onCancel={this.onDownloadEfileFormsCancel}
                    taxReturn={this.state.downloadEformFilesState.model as ITaxReturn}
                    downloadIndividual={this.onDownloadFile}
                    downloadAll={this.onDownloadReturnAll}
                    downloadAdditionalEsignDocuments={this.onDownloadAdditionalDocuments}
                />

                <ReportProblemModal
                    show={this.state.reportProblemState.show}
                    onCancel={this.onReportProblemCancel}
                    onReportProblemSave={this.onReportProblemSave}
                    model={this.state.reportProblemState.model as ITaxReturn}
                    loggedInCPA={this.props.userProfile}
                    companyName={this.props.company.companyProfile.companyInfo.companyName}
                    reportProblemType={ReportProblemTypes.ReturnSpecific}
                    problemStep={ReportedStep.Report}
                />

                <ClientTrackingModal
                    show={this.state.clientTrackingState.show}
                    onCancel={this.onClientTrackingCancel}
                    taxReturn={this.state.clientTrackingState.model as ITaxReturn}
                />

                <DownloadHistory
                    show={this.state.downloadHistoryState.show}
                    onCancel={this.onDownloadHistoryCancel}
                    taxReturn={this.state.downloadHistoryState.model as ITaxReturn}
                />

                <UploadForms
                    show={this.state.uploadFormsState.show}
                    onCancel={this.onUploadFormsCancel}
                    taxReturn={this.state.uploadFormsState.model as ITaxReturn}
                    onUploadFormsSuccess={this.onUploadFormsSuccess}
                    isArchived={this.props.isArchived}
                />

                {/* <VoucherReminder
                show={this.state.voucherReminderState.show}
                onCancel={this.onVoucherReminderCancel}
                onSave={this.onNotificationReminderSave}
                taxReturn={this.state.voucherReminderState.model as ITaxReturn}
            /> */}

                <SignedDetails
                    taxReturn={this.state.signedDetailsState.model as ITaxReturn}
                    onCancel={this.onSignedDetailsCancel}
                    show={this.state.signedDetailsState.show}
                />

                {this.state.setAccessState.show && (
                    <SetAccessCommonModal
                        onCancel={this.onSetAccessCancel}
                        onApplyAccess={this.onSetAccessSave}
                        returnAccessSettings={this.props.company.companySettings.returnAccessSettings}
                        availableUsers={this.state.availableUsers}
                        selectedUsers={this.state.selectedUsers}
                        availableUserGroups={this.state.availableUserGroups}
                        selectedUserGroups={this.state.selectedUserGroups}
                        isDragAndDropDisabled={false}
                        hide={this.onSetAccessCancel}
                    />
                )}
                <ViewAccessCode
                    show={this.state.accessCodeState.show}
                    taxReturn={this.state.accessCodeState.model as ITaxReturn}
                    oneHubAccessModel={this.state.onehubAccessCodeState.model as IOnehubAccessCodeModel[]}
                    onCancel={this.onViewAccessCodeCancel}
                    onGenerateOTP={this.onGenerateOTP}
                    onGenerateOnehubAccessCode={this.onGenerateOnehubAccessCode}
                />

                <RetentionComponent
                    show={this.state.retentionPolicyState.show}
                    taxReturn={this.state.retentionPolicyState.model as ITaxReturn}
                    onCancel={this.onRetentionPolicyCancel}
                    companySettings={this.props.extensionCompanyData}
                    onSave={this.onRetentionPolicySave}
                />

                <ClientInfo
                    show={this.state.editClientInfoState.show}
                    model={this.state.editClientInfoState.model as ITaxReturn}
                    onCancel={this.onEditClientInfoCancel}
                    onSave={this.onEditClientInfoSave}
                    updateTaxDocument={this.props.updateTaxDocument}
                    isDeliveredReturn={true}
                    nextSignerDetails={this.props.nextSignerDetails}
                    states={this.props.company.companyProfile.countryStates}
                    locationDropdown={this.props.officeLocation.locationDropdown}
                    loadingMessage={this.state.loadingMessage}
                    setLoadingMessageToInitialState={this.setLoadingMessageToInitialState}
                />

                <ReportChangeStatusModal
                    show={this.state.changeStatusState.show}
                    selectedReturnStatus={this.state.selectedStatus}
                    onChangeStatusSave={this.onChangeStatusSave}
                    selectedDocuments={this.selectedDocumentIdsAndLoadingStatus()}
                    signatureList={this.state.signatureStatusList}
                    onCancel={this.onChangeStatusCancel}
                    showLoader={this.state.showLoader}
                />

                <ResendAccessLinkModal
                    show={this.state.resendAccessLinkState.show}
                    model={this.state.resendAccessLinkState.model as ITaxReturn}
                    onResendAccessLink={this.onResendAccessLink}
                    onCancel={this.onResendAccessLinkCancel}
                />

                <ScreenShare
                    show={this.state.screenShareState.show}
                    onCancel={this.onScreenShareCancel}
                    clientId={this.ssClientId}
                    taxReturn={this.state.screenShareState.model as ITaxReturn}
                    startScreenShare={this.onScreenShareStart}
                    otp={this.state.screenShareOtp}
                />
                <DownloadTypeOption
                    show={this.state.downloadTypeOptionState.show}
                    onCancel={this.onDownloadTypeCancel}
                    selectedDocuments={this.selectedDocumentIdsAndLoadingStatus()}
                    hideLoader={this.state.hideCustomCulumnLoader}
                    selectedDownloadType={this.state.selectedDownloadType}
                    onDownloadOptionChange={this.onDownloadOptionChange}
                    onDownloadOptionSave={this.onDownloadOptionSave}
                    useDefaultDownloadType={this.useDefaultDownloadType}
                    selectedDefaultDownloadType={this.state.selectedDefaultDownloadType}
                />
                <ReminderModal
                    selectedDocuments={this.state.selectedDocuments}
                    show={this.state.sendReminderState.show}
                    onHide={this.onHideReminderModal}
                    totalCount={
                        this.state.isBulkSelectionEnabled
                            ? this.props.delivered.totalRowCount - this.state.deSelectedRows.length
                            : this.state.selectedDocuments.length
                    }
                    onSendReminderNow={ids =>
                        this.state.isBulkSelectionEnabled ? this.sendBulkReminders() : this.sendReminderConfirm(ids)
                    }
                    onSendScheduledReminder={(ids, scheduleReminderPayload) =>
                        this.state.isBulkSelectionEnabled
                            ? this.sendBulkReminders(scheduleReminderPayload)
                            : this.sendReminderConfirm(ids, scheduleReminderPayload)
                    }
                    selectedVoucherStatus={this.state.selectedVoucherStatus}
                />

                {this.state.showMismatchModal && (
                    <MismatchModal
                        showMismatchModal={this.state.showMismatchModal}
                        clientInfoComparisonResult={this.state.clientInfoComparisonResult}
                        clientManagementSave={this.clientManagementSave}
                        clientManagementCancel={this.clientManagementCancel}
                        closeMismatchModal={this.closeMismatchModal}
                        taxReturnType={Helper.GetTaxReturnType(this.state.editClientSaveState.taxReturn)}
                        popupInProgress={this.state.popupInProgress}
                    />
                )}
                {this.state.showLocationMismatchActionModel && (
                    <LocationMismatchActionModal
                        onCancel={this.onCloseLocationMismatchActionModal}
                        onContinue={this.onContinueLocationMismatchActionModal}
                        show={this.state.showLocationMismatchActionModel}
                        location={this.state.editClientInfoState.model.location.locationName}
                    />
                )}
                <UnlockReturnModal
                    show={this.state.unlockReturnState.show}
                    documentId={this.state.unlockReturnState.documentId}
                    unlockReturnModelList={this.state.unlockReturnState.unlockReturnModelList}
                    onUnlockReturn={this.onUnlockReturn}
                    onUnlockOneHub={this.unlockOneHubAccount}
                    onCancel={this.onUnlockReturnClose}
                />
            </div>
        );
    }
}
