import React, { FC } from 'react';
import { CheckBoxComponent } from '../CheckBoxComponent';
import { Alert } from 'react-bootstrap';

interface ISendReminderNowProps {
    selectedDocumentIds: number[];
    disabledSigningReminder: boolean;
    totalCount: number;
    showReminderText: boolean;
    signingReminder: boolean;
    setSigningReminder: React.Dispatch<React.SetStateAction<boolean>>;
}

const SendReminderNow: FC<ISendReminderNowProps> = ({
    selectedDocumentIds,
    disabledSigningReminder,
    totalCount, showReminderText,
    signingReminder,
    setSigningReminder }) => {

    const isMultiple = selectedDocumentIds.length > 1;

    return <div className="py-4">
        {isMultiple && <Alert variant='primary'>
            <i className="fas fa-info-circle mr-2"></i>
            {totalCount} Extensions selected.
        </Alert>}
        <div className="px-4">
            {/* THIS WILL BE IMPLEMENTED LATER */}
            {/* <div className="mb-4">
                <CheckBoxComponent
                    id="voucher_reminder"
                    text="Send Voucher Reminder"
                    checked={voucherReminder}
                    onChange={() => setVoucherReminder((prev) => !prev)}
                />
            </div> */}
            <div>
                <CheckBoxComponent
                    id="signing_reminder"
                    text="Send Signing Reminder"
                    checked={signingReminder}
                    onChange={() => setSigningReminder((prev) => !prev)}
                    disabled={disabledSigningReminder}
                />
            </div>
            {!isMultiple && !disabledSigningReminder && showReminderText && <div className='text-danger mt-4'>Automatic reminders are enabled for the selected Extension!</div>}
            {/* The below message will be changed as dynamic */}
            {isMultiple && <div className="mt-4">
                <div className="text-danger mb-3">Please Note!</div>
                <div>
                    Signing Reminder will be sent for statuses: “Awaiting E-Sign”, “Awaiting Upload”, “Partially Signed”.
                </div>
            </div>}
        </div>
    </div>
};

export default SendReminderNow;