import React from  'react';
import { ITaxingAuthority, ITaxReturn, VoucherProperty, IEFileProperty, IGroupFormBase, IVoucher, DocumentGroups, VoucherMode, IFormBase, SignatureType } from '../../common/TaxReturn';
import { IPageItem, IVoucherPageItem, ProcessReturnTabs, IEFilePageItem } from './ProcessReturnModels';
import { FilingForms } from '../../common/ProcessReturnModal/FilingForms';
import { BookmarkIcon } from '../BookmarkIcon';
import { IVoucherState } from '../../../store/ProcessReturn/voucher/VoucherStore';
import { ExtensionCompanySettings } from './../../../Core/ViewModels/Extension/ExtensionComponentModels';
import { isReviewExtesnion, isStrictReviewExtesnion } from './SignatureControls/SignatureHelper';
import { isBusinessReturn } from '../../helper/HelperFunctions';

export interface IPageListViewProps {
    pages: IPageItem[];
    onPageSelect: (pageNo: number, voucherNo: number, isViewClientInfo?: boolean) => void;
    onPageDelete?: (pageNo: number) => void;
    getTitle?: (pageTitle: string, id: number) => void;
    tabType?: number;
    getAllTaxingAuthorities: ITaxingAuthority[];
    onUpdateFilingForm?: (callback?: () => void) => void;
    taxReturn?: ITaxReturn;
    voucherStore?: IVoucherState;
    matchedForm?: IGroupFormBase;
    onEditFilingForm?: (pageNo: number) => void;
    handleSignatureChange?: (status: boolean, pageNumber: number) => void;
    handleVoucherChange?: (status: boolean, pageNumber: number) => void;
    onFilingEFileDataUpdate?: (propertyType: IEFileProperty, value: any, pageNumber: number) => void;
    onFilingVoucherDataUpdate?: (propertyType: VoucherProperty, value: any, pageNumber: number) => void;
    onResetFilingEFileData?: () => void;
    extensionCompanySettings?: ExtensionCompanySettings;
    removeDuplicateForm?: (duplicateForm: IFormBase) => void;
    duplicatePages: number[];
}

export interface IPagesState {
    pageNo: number;
    enableLeftPanelScroll: boolean;
    showFilingFormView: boolean;
    isOpen: boolean;
    disableLink: boolean;
}

export class PageListView extends React.Component<IPageListViewProps, IPagesState> {
    private filingFormTarget: any;
    constructor(props: IPageListViewProps) {
        super(props);
        this.state = {
            pageNo: -1,
            enableLeftPanelScroll: true,
            showFilingFormView: false,
            isOpen: false,
            disableLink: false,
        };

        this.onPageDelete = this.onPageDelete.bind(this);
    }

    public closeDeleteConfirmPopHover() {
        document.body.click();
    }

    enableScroll = () => {
        if (!this.state.enableLeftPanelScroll) {
            const scrollDiv = (document.querySelector(".scroll div div") as HTMLElement);
            scrollDiv.style.overflow = "scroll";
            scrollDiv.style.marginRight = "-17px";
            ((document.querySelector(".scroll div") as HTMLElement).children[2] as HTMLElement).style.display = "block";
            this.setState({ enableLeftPanelScroll: true });
        }

    }
    stopScroll = () => {
        if (this.state.enableLeftPanelScroll) {
            const scrollDiv = (document.querySelector(".scroll div div") as HTMLElement);
            const top: number = scrollDiv.scrollTop;
            scrollDiv.style.overflow = "hidden";
            scrollDiv.style.marginRight = "0px";
            scrollDiv.scrollTop = top;
            ((document.querySelector(".scroll div") as HTMLElement).children[2] as HTMLElement).style.display = "none";
            this.setState({ enableLeftPanelScroll: false });
        }

    }
    onPageDelete() {
        this.props.onPageDelete ? this.props.onPageDelete(this.state.pageNo) : undefined
        document.body.click();
    }

    onEditFilingForm = (pageNo: number) => {
        if (!this.state.isOpen) {
            this.props.onEditFilingForm && this.props.onEditFilingForm(pageNo);
        }
    }

    onChangeClick = (open: boolean, disable: boolean) => {
        this.setState({
            isOpen: open,
            disableLink: disable
        })
    }

    getAuthorityName = (pageNo: number) => {

        const { tabType, taxReturn, getAllTaxingAuthorities } = this.props;

        if (tabType == ProcessReturnTabs.EFile) {

            if (taxReturn) {
                const voucherindex = taxReturn.formGroups.findIndex(x => x.group == DocumentGroups.Vouchers);

                if (voucherindex !== -1) {
                    let voucher = taxReturn.formGroups[voucherindex].forms as IVoucher[];

                    let result = voucher.find(x => x.pageNo[0] == pageNo && x.vocherMode != VoucherMode.CustomVoucher);

                    const authorityName = getAllTaxingAuthorities.find(x => x.Id == (result && result.authorityID))

                    return authorityName ? `${authorityName.AuthorityName} ` : "";
                }
            }
        }
        return "";
    }

    getDisabledClassName = (disable: boolean): string => {
        return disable ? 'list-disabled' : '';
    }

    public render() {

        const {
            pages,
            duplicatePages,
            tabType,
            onPageSelect,
            getTitle,
            removeDuplicateForm,
            getAllTaxingAuthorities,
            taxReturn,
            voucherStore,
            matchedForm,
            handleSignatureChange,
            handleVoucherChange,
            onFilingEFileDataUpdate,
            onUpdateFilingForm,
            onFilingVoucherDataUpdate,
            onResetFilingEFileData,
            extensionCompanySettings,
        } = this.props;

        const disable = this.getDisabledClassName(this.state.disableLink);

        let isStrictReview = false;

        if (taxReturn && extensionCompanySettings) {
            let isBusinessRet = isBusinessReturn(taxReturn);
            isStrictReview = isReviewExtesnion(extensionCompanySettings, isBusinessRet) && isStrictReviewExtesnion(extensionCompanySettings, isBusinessRet);
        }

        return <div className="pageListView">
            {pages.length === 0 ? <div className="textalign-center">No Content</div> :
                (pages.map((form, i) => {
                    const rowId = "deletePage" + form.pageNo;
                    const parts = form.pageTitle ? form.pageTitle.split("###") : [];

                    const authority = this.getAuthorityName(form.pageNo);

                    const formName = (authority && parts[0].startsWith(authority)) ? parts[0] : `${this.getAuthorityName(form.pageNo)}${parts[0]}`;
                    return <div
                        key={i}
                        className={!isStrictReview && tabType == ProcessReturnTabs.EFile && (
                            (form as IEFilePageItem).isSignatureRequired ||
                            (form as IVoucherPageItem).isSignatureRequired
                        ) ? 'custom-list-group-item bookmark-item highlight-row'
                            : 'custom-list-group-item bookmark-item'} >

                        <div className="bookmark-row" id={rowId}>

                            <BookmarkIcon {...form} />

                            <div title={formName}
                                className={"bookmark-name wordbreak ellipsis cursor-pointer " + (form.className || "")}
                                onClick={() => {
                                    let f = form as IPageItem; onPageSelect(f.pageNo, -1, f.isClientInfo);
                                    getTitle ? getTitle(form.pageTitle, form.id) : undefined;
                                }}>
                                {formName}
                            </div>

                            <div className="bookmark-right" style={{ minWidth: '40px' }}>
                                {
                                    form.form ? duplicatePages.find(x => form.form.pageNo[0] === x) ?
                                        <span onClick={() => {
                                            removeDuplicateForm &&
                                                removeDuplicateForm(form.form)
                                        }}> <i className='fas fa-trash ddl-icon'
                                            style={{ marginLeft: '0px', color: "red" }}>
                                            </i></span> : "" : ""
                                }
                                {
                                    tabType == ProcessReturnTabs.EFile &&
                                    <span className={`pull-right ${disable} ${this.getDisabledClassName(form.form ? duplicatePages.find(x => form.form.pageNo[0] === x) ? true : false : false)}`}
                                        onClick={() => {
                                            let f = form as IPageItem;
                                            this.onEditFilingForm(f.pageNo);
                                        }}>
                                        {
                                            <FilingForms
                                                taxingAuthorities={getAllTaxingAuthorities}
                                                taxReturn={taxReturn}
                                                voucherStore={voucherStore}
                                                pageNo={form.pageNo}
                                                matchedForm={matchedForm}
                                                handleSignatureChange={handleSignatureChange}
                                                handleVoucherChange={handleVoucherChange}
                                                onChangeClick={this.onChangeClick}
                                                onFilingEFileDataUpdate={onFilingEFileDataUpdate}
                                                onUpdateFilingForm={onUpdateFilingForm}
                                                onFilingVoucherDataUpdate={onFilingVoucherDataUpdate}
                                                onResetFilingEFileData={onResetFilingEFileData}
                                                extensionCompanySettings={extensionCompanySettings}
                                            />
                                        }
                                    </span>
                                }
                            </div>
                        </div>
                    </div>;
                }))
            }
        </div>;
    }
}
