import React from  'react';
import { Col, Accordion, Card, Row } from 'react-bootstrap';
import Select from 'react-select';
import { SignatureControlRole, SignatureControlTypes, SignatureGroupType } from '../../../common/TaxReturn';
import { EfileSignControl } from './EfileSignControl';

export interface IEfileSignaturePanelProps {
    title: string;
    selectedSigner: ISigner,
    signer: ISigner[],
    signerChangeHandler(selectedValue: any): void,
    isAssignedToLoggedinUser?: boolean,
    ControlBoxItems: IEfileSignControlBoxItem[],
    onSignatureControlFocus(focusedGroup: SignatureGroupType): void;
    dragStart(event: any, controlType: SignatureControlTypes, signatureRole: SignatureControlRole): void;
    dragEnd?: (event: any) => void;
    keyPrefix: string;
}

export interface IEfileSignaturePanelState {
    open: boolean;
}

export interface IEsignControlBoxItem {
    caption: string,
    icon: string,
    controlType: SignatureControlTypes
}

export interface ISigner {
    value: number;
    label: string;
    signatureRole: SignatureControlRole;
    disabled?: boolean;
}

interface IEfileSignControlBoxItem {
    caption: string,
    icon: string,
    controlType: SignatureControlTypes,
    helpText: string
}

export class EfileSignatureControlPanel extends React.Component<IEfileSignaturePanelProps, IEfileSignaturePanelState>{

    constructor(props: IEfileSignaturePanelProps) {
        super(props);
        this.state = {
            open: true
        };
    }

    toggleExpand = () => {
        this.setState({ open: !this.state.open })
    }

    public render() {
		const { keyPrefix } = this.props;
        const esignControls = this.props.ControlBoxItems.map((controlBoxItem, Index) => {
            return <EfileSignControl
                key={keyPrefix + Index}
                icon={controlBoxItem.icon}
                caption={controlBoxItem.caption}
                controlType={controlBoxItem.controlType}
                helpText={controlBoxItem.helpText}
                dragStart={(event, controlType) => { this.props.dragStart(event, controlType, this.props.selectedSigner.signatureRole) }}
                dragEnd={this.props.dragEnd}
            />
        });

        let icon = this.state.open ? "glyphicon glyphicon-minus" : "glyphicon glyphicon-plus";
        return <div>
            {
                <Accordion defaultActiveKey="0" style={{ marginRight: "1px", marginTop: "10px" }}>
                    <Card>
                        <Accordion.Toggle
                            style={{ padding: "10px" }}
                            as={Card.Header}
                            variant="link"
                            eventKey="0"
                            onClick={() => this.toggleExpand()}>
                            <Card.Title as="h4">
                                <i className={icon}></i> {this.props.title}
                            </Card.Title>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body style={{ padding: "10px" }}>
                                <div>
                                    <div className="form-horizontal">
                                        <Row className="marB15">
                                            <Col sm={12}>
                                                <Select
                                                    name="form-field-name"
                                                    value={this.props.selectedSigner}
                                                    onChange={this.props.signerChangeHandler}
                                                    options={this.props.signer}
                                                    data-test-auto="32C60802-06ED-42F6-80E2-244747E4F8F9"
                                                    onFocus={() => { this.props.onSignatureControlFocus(SignatureGroupType.Esign) }}
                                                    clearable={false}
                                                />
                                            </Col>

                                        </Row>
                                    </div>
                                    <div className="form-horizontal">
                                        {esignControls}
                                    </div>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>

                    </Card>
                </Accordion>                
            }
        </div>
    };
};