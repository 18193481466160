import { IPaymentVoucherNotificationSettings, ISigningReminderNotificationSettings, SignatureFormSelectionType, TaxpayerAuthenticationType, TransmittalDownloadOption, VoucherDownloadOption } from '../Company/CompanySettingsViewModel';

export interface ExtensionCompanySettings {
    defaultSettings: ExtensionDefaultSettings;
    deliverySettingsModel: IExtensionDeliverySettingsModel;
    defaultGroupSettings: ExtensionDefaultGroupSettings;
    signatureSettingsModel: SignatureSettingsModel;
    notificationSettingsModel: NotificationSettingsModel;
    displaySettingsModel: ExtensionDisplaySettingsModel;
    retentionSettingsModel: IRetentionSettings;
}

export interface IRetentionSettings {
    retentionPeriod: number;
    attestRetentionPeriod: number;
    allowOverride: boolean
}

export interface ExtensionDisplaySettingsModel{
    voucherDueDateType: DueDateType;
}

export enum DueDateType {
    None = 0,
    SSExtDefault = 1,
    Printed = 2
}

export interface IExtensionDeliverySettingsModel {
    transmittalsDownloadOptions: TransmittalDownloadOption;
    vouchersDownloadOptions: VoucherDownloadOption;
    taxpayerAuthentication: TaxpayerAuthenticationType;
    isPrintToPDFEnabled: boolean;
}

export interface NotificationSettingsModel {
    signingReminderNotificationSettings: ISigningReminderNotificationSettings;
    paymentVoucherNotificationSettings: IPaymentVoucherNotificationSettings;
}

export interface ExtensionDefaultSettings {
    downloadSignedForms: number;
    initialEmail: number;
    paymentInstruction: number;
    signingReminder: number;
    voucherReminders: number;
    savedMessage: number;
    recalledReturnMail: number;
}

export interface ExtensionDefaultGroupSettings {
    groupInitialEmail: number;
    groupSigningReminder: number;
    groupVoucherReminders: number;
    groupDownloadSignedForms: number;
    groupPaymentInstruction: number;
}

export interface SignatureSettingsModel {
    signatureFormSelectionTypeFor1040: SignatureFormSelectionType;
    signatureFormSelectionTypeForBusiness: SignatureFormSelectionType;
    
    knowledgeBasedAuthentication: boolean;
    useSignatureStamp: boolean;
    
    isDoNotDisplayDatesEnabled: boolean;
    enableDelegateeKbaAuthentication: boolean;
    enableEntityReturnSignerDelegation: boolean;
    enableIndividualReturnSignerDelegation: boolean;
    allowUserPlaceSignOn1040: boolean;
    allowUserPlaceSignOnBusiness: boolean;
    isDoNotDisplayNameAndTitleEnabled: boolean;
}

export interface EmailMessage {
    id: number;
    name: string;
    text: string;
    subject: string;
    type: EmailMessageType;
    companyId: number;
}

export enum EmailMessageType {
    None = 0,
    ExtensionInitialEmail = 53,
    ExtensionSigningReminder = 54,
    ExtensionVoucherReminders = 55,
    DownloadExtensionSignedForms = 56,
    DownloadExtensionDocuments = 57,
    DownloadExtensionInstructions = 58,
    DownloadExtensionPaymentInstructions = 59,
}

export interface PreparerMessage {
    id: number;
    name: string;
    body: string;
    isAllowToEdit: boolean;
}

export const initialEmailMessage: EmailMessage = {
    id: 0,
    name: "",
    text: "",
    subject: "",
    type: EmailMessageType.None,
    companyId: 0,
};

export const initialPreparerMessage: PreparerMessage = {
    id: 0,
    name: "",
    body: "",
    isAllowToEdit: false,
};

export const initialExtensionCompanySettings: ExtensionCompanySettings = {
    defaultSettings: {
        downloadSignedForms: 0,
        initialEmail: 0,
        paymentInstruction: 0,
        signingReminder: 0,
        voucherReminders: 0,
        savedMessage: 0,
        recalledReturnMail :0
    },
    defaultGroupSettings: {
        groupInitialEmail: 0,
        groupSigningReminder: 0,
        groupVoucherReminders: 0,
        groupDownloadSignedForms: 0,
        groupPaymentInstruction: 0,
    },
    deliverySettingsModel : {
        transmittalsDownloadOptions: TransmittalDownloadOption.None,
        vouchersDownloadOptions: VoucherDownloadOption.None,
        taxpayerAuthentication: TaxpayerAuthenticationType.Last4ofSSN,
        isPrintToPDFEnabled: false
    },
    signatureSettingsModel: {
        signatureFormSelectionTypeFor1040: SignatureFormSelectionType.None,
        signatureFormSelectionTypeForBusiness: SignatureFormSelectionType.None,
        
        knowledgeBasedAuthentication: true,
        
        useSignatureStamp: false,
        isDoNotDisplayDatesEnabled: true,
        enableEntityReturnSignerDelegation: true,
        enableIndividualReturnSignerDelegation: false,
        enableDelegateeKbaAuthentication: false,
        allowUserPlaceSignOn1040: false,
        allowUserPlaceSignOnBusiness: false,
        isDoNotDisplayNameAndTitleEnabled: false
    },
    notificationSettingsModel: {
        signingReminderNotificationSettings: {
            enableSigningReminder: true,
            noOfDaysForSigningReminder: 1,
        },
        paymentVoucherNotificationSettings:{
            enablePaymentVoucherReminder: false,
            noOfDaysForVoucherReminder: 0,
            paymentNotification: false,
            disabledVoucherReminderFromTP: false
        }
    },
    displaySettingsModel: {
        voucherDueDateType: DueDateType.SSExtDefault
    },
    retentionSettingsModel: {
        retentionPeriod: 2520,
        attestRetentionPeriod: 2520,
        allowOverride: false
    },
};
