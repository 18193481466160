import React, { useEffect, useState } from 'react'
import { IModalProps } from './ProfileObjects';
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'src/store';
import { CheckBoxComponent } from '../../common/CheckBoxComponent';
import { Link } from 'react-router-dom';
import { actionCreators as loginHistoryAction } from 'src/store/userManagement/UserLoginHistoryStore';
import MyLoginHistoryTable from './MyLoginHistoryTable';

export const MyLoginHistory: React.FC<IModalProps> = ({
    showState,
    onHide
}) => {
    
    const dispatch = useDispatch();
    const lastLoginPrompt = useSelector((state: ApplicationState) => state.userLoginHistory.lastLoginPrompt);

    const [enableLastLogin,setEnableLastLogin]=useState(false);
    
    useEffect(() => {
        dispatch(loginHistoryAction.requestUserLastLoginPrompt())
        dispatch(loginHistoryAction.requestUserLoginHistory(1))
    }, [showState])

    useEffect(()=>{
        setEnableLastLogin(lastLoginPrompt)
    },[lastLoginPrompt])
    
    const onPopupClose = () => {
        onHide();
    }

    const changeLastLogin = () => {
        setEnableLastLogin(!enableLastLogin);
        dispatch(loginHistoryAction.saveUserLastLoginPrompt(!enableLastLogin));
    };
    return <Modal show={showState} onHide={onHide} className="my-login-history" size="lg" >
        <Modal.Header closeButton>
            <Modal.Title className="custom-modal-header">
                <span className='fa fa-history' style={{ color: 'grey', marginRight: '5px' }}></span>
                My Login History
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <MyLoginHistoryTable/>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-between' >
            <div className="modal-footer-left float-left padT07 div-flex">
                <CheckBoxComponent
                    id={"lastLoginEnable"}
                    text={"Enable Last Login Prompt"}
                    checked={enableLastLogin}
                    onChange={changeLastLogin}
                    datatestAuto={"989E572E-F52B-466D-B675-D99B6193D66B"}
                />
                <Link to={'#'} className="help-icon marL05" data-placement="right" data-toggle="tooltip" title="If enabled, you will see the last login notification every time you login">
                    <i className="fas fa-question-circle"></i>
                </Link>
            </div>
            <div className='float-right'>
            <Button
                className="btn-white"
                variant='default'
                onClick={onPopupClose}
            >
                <i className='fas fa-times'></i>
                Close
            </Button>
            </div>
        </Modal.Footer>
    </Modal>
}
