import React from  'react';
import { Link } from 'react-router-dom';
import { CheckBoxComponent } from '../../common/CheckBoxComponent';
import { ExtensionCompanySettings } from '../../../Core/ViewModels/Extension/ExtensionComponentModels';
import { RBACKeys } from 'src/components/helper/RbacConstants';

interface IEROSignature {
    companySettings: ExtensionCompanySettings;
    handleSignerDeligation(event: any): void;
    handleUseSignatureStamp(event: any): void;
}

export class EROSignatures extends React.Component<IEROSignature, {}> {
    constructor(props: any) {
        super(props);
    }

    public render() {
        return <div id="divEROSignature" className="new-main-content" >
            <h3>Allow Signature Stamps</h3>
            <div className="marL20">
                <div className="marB10" style={{ display: "flex" }}>
                    <CheckBoxComponent
                        id={"UseSignatureStamps"}
                        text={"Use Signature Stamps"}
                        checked={
                            this.props.companySettings.signatureSettingsModel ?
                                this.props.companySettings.signatureSettingsModel.useSignatureStamp :
                                false
                        }
                        onChange={this.props.handleUseSignatureStamp}
                        datatestAuto={"B06AE671-061D-4E1C-905C-DB09E5AAD89C"}
                        resourceId={RBACKeys.Settings.settings}
                    />

                    <Link to={'#'}
                        className="marL05"
                        data-placement="right"
                        data-toggle="tooltip"
                        title="Signature images can be stamped on e-file authorization forms prior to delivery">

                        <i className="help-icon fas fa-question-circle"></i>
                    </Link>
                </div>
                {/*<div className="marL30 marB10" style={{ display: "flex" }}>*/}
                {/*    <CheckBoxComponent*/}
                {/*        id={"EnableSignerDelegation"}*/}
                {/*        text={"Enable Signer Delegation"}*/}
                {/*        checked={this.props.companySettings.signatureSettingsModel ?*/}
                {/*            this.props.companySettings.signatureSettingsModel.enableSignerDeligation : false}*/}
                {/*        onChange={this.props.handleSignerDeligation}*/}
                {/*        datatestAuto={"DBE42651-56E3-41C7-A402-381CAE1F2AFA"}*/}
                {/*    />*/}

                {/*    <Link to={'#'}*/}
                {/*        className="marL05"*/}
                {/*        data-placement="right"*/}
                {/*        data-toggle="tooltip"*/}
                {/*        title="Allows delegated users to apply the signature stamps of the authorized user">*/}

                {/*        <i className="help-icon fas fa-question-circle"></i>*/}
                {/*    </Link>*/}
                {/*</div>*/}
            </div>
        </div>;
    }
}

export default EROSignatures;
