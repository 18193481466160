import { actionTypes } from '../ActionTypes';
import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { handleResponse } from '../Library';
import * as Notification from '../common/NotificationStore';
import { NotificationAction } from '../common/NotificationStore';
import * as Constants from '../../components/helper/Constants';
import { GroupAccess, GroupInfo, initialGroupInfo } from '../../Core/ViewModels/GroupExtensions/ComponentModels';
import { DeleteGroupInfoAction, ErrorGroupAccessAction, ErrorGroupNameAction, GroupedExtensionStoreState, ReceiveAddedGroupNameAction, ReceiveGroupAccessAction, ReceiveGroupConfigurationAction, ReceiveGroupInfoAction, RequestAddGroupNameAction, RequestGroupAccessAction, RequestGroupConfigurationAction, RequestGroupInfoAction } from '../../Core/ViewModels/GroupExtensions/StoreModels';
import { LoggerFactory } from '../../Logger/LoggerFactory';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';

type KnownAction =
    ReceiveGroupConfigurationAction |
    RequestGroupConfigurationAction |
    RequestAddGroupNameAction |
    ReceiveAddedGroupNameAction |
    ErrorGroupNameAction |
    ReceiveGroupAccessAction |
    ErrorGroupAccessAction |
    RequestGroupAccessAction |
    RequestGroupInfoAction |
    ReceiveGroupInfoAction |
    DeleteGroupInfoAction |
    NotificationAction;

type DispatchAction =
    ReceiveGroupConfigurationAction |
    RequestAddGroupNameAction |
    ReceiveAddedGroupNameAction |
    ErrorGroupNameAction |
    DeleteGroupInfoAction |
    RequestGroupConfigurationAction;

export const actionCreators = {
    requestGroupConfiguration: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (reload || (getState().groupedExtensionsStore.groupInfo.length == 0)) {
            const fetchTask = fetch(`${API_BASE_URL}api/GroupedExtensions/GetGroupInfo`, {
                method: 'GET',
                credentials: 'include'
            })
                .then(response => response.json() as Promise<GroupInfo[]>)
                .then(data => {
                    dispatch({
                        type: actionTypes.RECEIVE_GROUP_NAME,
                        groupInfo: data
                    });
                })
                .catch(function (error) {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error.statusText, statusType: Notification.StatusType.Error })
                    dispatch({ type: actionTypes.ERROR_GROUP_NAME, reason: error.message })
                    logger.trackError(`requestGroupConfiguration failed with error ${error.message}`);
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_GROUP_NAME, message: '' });
        }
    },

    addGroup: (groupInfo: GroupInfo, callback?: (id: number) => void, failurecallback?: () => void, disableSuccessNotification?: boolean):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            const fetchTask = fetch(`${API_BASE_URL}api/GroupedExtensions/AddGroupInfo`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify(groupInfo)
            })
                .then(handleResponse)
                .then((id) => {
                    groupInfo.id = id;
                    dispatch({
                        type: actionTypes.RECEIVE_ADDED_GROUP_NAME,
                        groupInfo: groupInfo
                    })
                    if (!disableSuccessNotification) {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: Constants.GroupExtensionConstants.StatusMessage.GroupConfigurationAddSuccess,
                            statusType: Notification.StatusType.Success
                        })
                    }
                    if (callback) {
                        callback(id);
                    }
                })
                .catch(function (error) {
                    if (failurecallback) {
                        failurecallback();
                    }
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.GroupExtensionConstants.StatusMessage.GroupConfigurationAddError,
                        statusType: Notification.StatusType.Error
                    });
                    dispatch({
                        type: actionTypes.ERROR_GROUP_NAME,
                        reason: error.message
                    })
                    logger.trackError(`addGroup failed for groupInfo: ${groupInfo}, with error ${error.message}`);

                })
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_GROUP_NAME, message: '' });
        },

    mapExtensionstoGroup: (ids: number[], groupId: number, callback?: () => void, failurecallback?: () => void, combineMultipleNotification?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/GroupedExtensions/MapExtensiontoGroup/` + groupId, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(ids)
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: combineMultipleNotification ? Constants.GroupExtensionConstants.StatusMessage.GroupConfigurationAndMapExtensionToGroupSuccess
                        : Constants.GroupExtensionConstants.StatusMessage.MapExtensionToGroupSuccess,
                    statusType: Notification.StatusType.Success
                })
                if (callback) {
                    callback();
                }
            })
            .catch(function (error) {
                if (failurecallback) {
                    failurecallback();
                }
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.GroupExtensionConstants.StatusMessage.MapExtensionToGroupError,
                    statusType: Notification.StatusType.Error
                });
                dispatch({
                    type: actionTypes.ERROR_GROUP_NAME,
                    reason: error.message
                })
                logger.trackError(`mapExtensionstoGroup failed for ids: ${ids}, with error ${error.message}`);

            })
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_GROUP_NAME, message: '' });
    },

    requestGroupInfo: (id: number, callback?: (data: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(`${API_BASE_URL}api/GroupedExtensions/GetGroupInfoAsync/` + id, {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => response.json() as Promise<GroupInfo>)
            .then(data => {
                dispatch({
                    type: actionTypes.RECEIVE_GROUP_INFO,
                    groupData: data
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(function (error) {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error.statusText, statusType: Notification.StatusType.Error })
                dispatch({ type: actionTypes.ERROR_GROUP_NAME, reason: error.message })
                logger.trackError(`requestGroupInfo failed for id: ${id}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },
    requestGroupAccess: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (reload || (getState().groupedExtensionsStore.groupAccess.length == 0)) {
            const fetchTask = fetch(`${API_BASE_URL}api/GroupedExtensions/GetGroupAccess`, {
                method: 'GET',
                credentials: 'include'
            })
                .then(response => response.json() as Promise<GroupAccess[]>)
                .then(data => {
                    dispatch({
                        type: actionTypes.RECEIVE_GROUP_ACCESS,
                        groupAccess: data
                    });
                })
                .catch(function (error) {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error.statusText, statusType: Notification.StatusType.Error })
                    dispatch({ type: actionTypes.ERROR_GROUP_ACCESS, reason: error.message })
                    logger.trackError(`requestGroupAccess failed with error ${error.message}`);
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_GROUP_ACCESS, message: '' });
        }
    },
    updateGroup: (groupInfo: GroupInfo, callback?: () => void, failurecallback?: () => void, disableSuccessNotification?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/GroupedExtensions/UpdateGroupInfo`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(groupInfo)
        })
            .then(handleResponse)
            .then(() => {
                if (!disableSuccessNotification) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.GroupExtensionConstants.StatusMessage.GroupConfigurationUpdateSuccess,
                        statusType: Notification.StatusType.Success
                    })
                }
                if (callback) {
                    callback();
                }
            })
            .catch(function (error) {
                if (failurecallback) {
                    failurecallback();
                }
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.GroupExtensionConstants.StatusMessage.GroupConfigurationUpdateError,
                    statusType: Notification.StatusType.Error
                });
                dispatch({
                    type: actionTypes.ERROR_GROUP_NAME,
                    reason: error.message
                })
                logger.trackError(`updateGroup failed for groupInfo: ${groupInfo}, with error ${error.message}`);

            })
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_GROUP_NAME, message: '' });
    },
};

const unloadedState: GroupedExtensionStoreState = {
    groupInfo: [],
    isLoading: false, message: "",
    groupAccess: [],
    groupData: initialGroupInfo,
} as GroupedExtensionStoreState;

export const reducer: Reducer<GroupedExtensionStoreState> = (state: GroupedExtensionStoreState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_GROUP_NAME:
            const groupConfiguration: GroupedExtensionStoreState = { ...state };
            groupConfiguration.isLoading = true;
            groupConfiguration.message = action.message;
            return groupConfiguration;

        case actionTypes.RECEIVE_GROUP_NAME:
            return {
                ...state,
                groupInfo: action.groupInfo,
                isLoading: false,
                error: false,
                message: "",
            } as GroupedExtensionStoreState;

        case actionTypes.RECEIVE_ADDED_GROUP_NAME:
            const added: GroupedExtensionStoreState = { ...state }
            added.groupInfo.push(action.groupInfo);
            added.isLoading = false;
            return added;

        case actionTypes.DELETE_GROUP_INFO:
            const data: GroupedExtensionStoreState = { ...state }
            for (let i = 0; i < action.groupIds.length; i++) {
                var index = data.groupInfo.findIndex(group => group.id == action.groupIds[i]);
                if (index != -1) {
                    data.groupInfo.splice(index, 1);
                }
            }
            return data;
    }
    return state || unloadedState;
};