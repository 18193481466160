import { injectable } from "inversify";
import { ILocalStore } from './LocalStore';

export interface ISessionLocalStore extends ILocalStore {

}

@injectable()
export class SessionStorageStore implements ISessionLocalStore {

    constructor() {
        
    }

    get(key: string) {
        return sessionStorage.getItem(key);
    }
    set(key: string, value: any): void {
        sessionStorage.setItem(key, value);
    }
    remove(key: string): void {
        sessionStorage.removeItem(key);
    }
    isExists(key: string): boolean {
        return sessionStorage.getItem(key) === null ? false : true;
    }
}