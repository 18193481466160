import moment from "moment";
import React from  'react';
import * as Bs from 'react-bootstrap';
import { IVoucherState } from '../../../../store/ProcessReturn/voucher/VoucherStore';
import { CustomType, getAllStateImages, getVoucherTypes, ITaxingAuthority, ITaxReturn, IVoucher, StateUSA, VoucherNo, VoucherMode, VoucherTypes, EngagementType, SelectOptions, DocumentGroups, TaxFormType } from '../../../common/TaxReturn';
import { VoucherTabConstants, customAuthority } from '../../../helper/Constants';
import { formatCurency } from '.././../../helper/HelperFunctions';
import { CustomDatePicker } from '../../controls/CustomDatePicker';
import { ValidateWebsite, ConvertDateToUtc } from '../../../helper/Validations';
import { RadioButtonComponent } from '../../../common/RadioButtonComponent';
import { CurrencyTextBox } from '../../../common/controls/CurrencyTextBox';
import { OverlayLoader } from '../../../helper/OverlayLoader';
import { IVoucherTaxFilingDate } from '../../../../Core/ViewModels/Voucher/VoucherDefaultDateViewModel';
import { AutoSuggestTextbox } from "../../../common/controls/AutoSuggestTextbox";
import { DueDateType } from "src/Core/ViewModels/Extension/ExtensionComponentModels";
import { filterTaxFilingDates } from "src/components/helper/VoucherNameFilter";

export interface IAddMultiplePaymentProps {
    taxReturn?: ITaxReturn;

    onAddVoucher?: (voucher: IVoucher) => void;
    populateVoucherPanel?: (voucher: IVoucher) => void;
    voucherStore?: IVoucherState;
    getAllTaxingAuthorities: ITaxingAuthority[];
    onAddMultipeVouchers?: (vouchers: IVoucher[]) => void;
}
export interface IAddMultiplePaymentState {
    vouchers: IVoucher[];
    showAddVoucherView: boolean;
    popoverTarget: any;
    showAlert: boolean;
    message: string;
    isPaymentUrlSame: boolean[];
    masterPaymentUrl: string;
    isMasterUrl: boolean[];
    currentAuthority: ITaxingAuthority;
    showUrlColumn: boolean;
    showCustomColumn: boolean;
    alertType: string;
    selectedPaymentUrl: string[];
    paymentUrls: SelectOptions[];
    estimatedUrls: SelectOptions[];
    isFirstClick: boolean;
    paymentFormNames: SelectOptions[];
    estimatedFormNames: SelectOptions[];
    selectedFormName: string[];
}

let initialVoucher: IVoucher = {
    authorityID: 0,
    dueDate: undefined,
    amount: 0,
    voucherNo: VoucherNo.None,
    paymentType: VoucherTypes.None,
    customType: CustomType.None,
    onlinePaymentUri: "",
    customText: "",
    authorityImage: "",
    authorityName: "",
    groupId: undefined,
    formName: "",
    bookmark: "",
    pageNo: [],
    isMasterPaymentUrl: false,
    vocherMode: VoucherMode.None,
    isFormNameUpdated: false,
    formType: TaxFormType.Voucher,
    signatureControls: [],
    signatureEnable: false,
    voucherEnable: true,
    printedDueDate: new Date(),
    voucherDueDateType: DueDateType.SSExtDefault,
    id: 0
}

const initialAuthority: Readonly<ITaxingAuthority> = {
    Id: 0,
    Active: false,
    Abbreviation: "",
    AuthorityName: "",
    CustomAuthority: false,
    InUse: 0,
    isEsignEnabled: false,
    PaymentURL: "",
    PrefixStateCode: false,
    StateImage: ""
}

export class AddMultiplePayment extends React.Component<IAddMultiplePaymentProps, IAddMultiplePaymentState>
{
    constructor(props: IAddMultiplePaymentProps) {
        super(props);
        this.state = {
            vouchers: [],
            showAddVoucherView: false,
            popoverTarget: {},
            showAlert: true,
            message: '',
            isPaymentUrlSame: [],
            masterPaymentUrl: "",
            isMasterUrl: [],
            currentAuthority: initialAuthority,
            showCustomColumn: false,
            showUrlColumn: false,
            alertType: "",
            selectedPaymentUrl: [],
            paymentUrls: [],
            estimatedUrls: [],
            isFirstClick: true,
            paymentFormNames: [],
            estimatedFormNames: [],
            selectedFormName: []
        };
    }

    componentDidMount() {
        this.loadInitialVouchers();
    }

    private loadInitialVouchers = (authority: string = "") => {
        if (this.state.vouchers.length > 0) {
            this.setState({ vouchers: [] }, () => {
                this.loadVouchers();
                this.setState({ showAlert: true, currentAuthority: initialAuthority, isFirstClick: true, alertType: "success", message: VoucherTabConstants.AddVoucher.voucherAddSuccess + authority });
            })
        }
        else {
            this.loadVouchers();
        }
        this.clearPaymentUrlSameState();
        this.clearSelectedFormNameState();
        this.clearPaymentFormNameState();
    }

    private loadVouchers = () => {
        VoucherTabConstants.PaymentMethods.map((voucher, index) => {
            let voucherObj: IVoucher = JSON.parse(JSON.stringify(initialVoucher));
            voucherObj.dueDate = moment().toDate();
            voucherObj.paymentType = (index === 0) ? VoucherTypes.PaymentVoucher : VoucherTypes.EstimatedVoucher;
            voucherObj.voucherNo = index + 1;
            this.state.vouchers.push(voucherObj);
        });
    }

    private clearPaymentUrlSameState = () => {
        this.state.isPaymentUrlSame.length = 0;
        VoucherTabConstants.PaymentMethods.map((voucher, index) => {
            this.state.isPaymentUrlSame.push(true);
        });
        this.state.selectedPaymentUrl.length = 0;
        this.state.isMasterUrl.length = 0;
        this.state.paymentUrls.length = 0;
        this.state.estimatedUrls.length = 0;
    }

    private clearSelectedFormNameState = () => {
        this.state.selectedFormName.length = 0;
    }

    private clearPaymentFormNameState = () => {
        this.state.paymentFormNames.length = 0;
        this.state.estimatedFormNames.length = 0;
    }

    public closeDeleteConfirmPopHover = () => {
        document.body.click();
    }

    private setPaymentUrl(taxingAuthority: ITaxingAuthority | undefined, tempVoucher: IVoucher, index: number) {
        let editedPaymentUrl: string = '';
        let customPaymentUrl: string = '';
        let masterPaymentUrl: string = '';
        let isMasterUrl: boolean = true;

        if (taxingAuthority && tempVoucher.customType === CustomType.OnlinePaymentRequired && tempVoucher.voucherNo > 0) {
            editedPaymentUrl = this.getPaymentUrl(tempVoucher);
            masterPaymentUrl = this.getMasterPaymentUrl(tempVoucher);
            customPaymentUrl = taxingAuthority.PaymentURL;
            if (taxingAuthority.CustomAuthority) {
                tempVoucher.onlinePaymentUri = customPaymentUrl;
                isMasterUrl = false;
                this.state.selectedPaymentUrl[index] = customPaymentUrl;
            }
            else if (masterPaymentUrl === null || masterPaymentUrl === '') {
                tempVoucher.onlinePaymentUri = editedPaymentUrl;
                isMasterUrl = false;
                this.state.selectedPaymentUrl[index] = editedPaymentUrl;
            }
            else if (editedPaymentUrl != null && editedPaymentUrl != '') {
                if (editedPaymentUrl.trim().substr(-1) === '/') {
                    editedPaymentUrl = editedPaymentUrl.trim().slice(0, -1);
                }
                if (masterPaymentUrl.trim().substr(-1) === '/') {
                    masterPaymentUrl = masterPaymentUrl.trim().slice(0, -1);
                }
                this.state.isPaymentUrlSame[index] = editedPaymentUrl === masterPaymentUrl;
                isMasterUrl = !this.state.isPaymentUrlSame[index];
                tempVoucher.onlinePaymentUri = editedPaymentUrl;
                this.state.selectedPaymentUrl[index] = editedPaymentUrl;
            }
            else {
                tempVoucher.onlinePaymentUri = masterPaymentUrl;
                isMasterUrl = false;
                this.state.selectedPaymentUrl[index] = masterPaymentUrl;
            }
            this.state.isMasterUrl[index] = isMasterUrl;
            this.setPaymentDropdownValues(tempVoucher.paymentType, index, masterPaymentUrl, editedPaymentUrl, customPaymentUrl);
            this.setState({
                masterPaymentUrl: masterPaymentUrl
            });
        }
    }

    private setPaymentDropdownValues(type: VoucherTypes, index: number, masterPaymentUrl: string, editedPaymentUrl: string, customPaymentUrl: string) {
        let ddIndex: number = 0;
        if (masterPaymentUrl) {
            this.manageUrlState(type, ddIndex, masterPaymentUrl);
            ddIndex++;
        }
        if (editedPaymentUrl) {
            this.manageUrlState(type, ddIndex, editedPaymentUrl);
            ddIndex++;
        }
        if (customPaymentUrl) {
            this.manageUrlState(type, ddIndex, customPaymentUrl);
        }
    }

    private manageUrlState = (type: VoucherTypes, ddIndex: number, paymentUrl: string) => {
        if (type === VoucherTypes.PaymentVoucher && this.state.paymentUrls.findIndex(x => x.label === paymentUrl) < 0) {
            this.state.paymentUrls.push({ value: ddIndex.toString(), label: paymentUrl })
        }
        else {
            if (this.state.estimatedUrls.findIndex(x => x.label === paymentUrl) < 0) {
                this.state.estimatedUrls.push({ value: ddIndex.toString(), label: paymentUrl });
            }
        }
    }

    private onAuthorityChange = (event: any) => {
        const tempVouchers: IVoucher[] = [...this.state.vouchers];
        let taxingAuthority: ITaxingAuthority | undefined = this.props.getAllTaxingAuthorities.find(x => x.Id == parseInt(event.target.value));
        this.clearPaymentUrlSameState();
        if (taxingAuthority && taxingAuthority.Id < customAuthority.customAuthorityId) {
            this.clearPaymentFormNameState();
            this.clearSelectedFormNameState();
        }
        else {
            this.clearPaymentFormNameState();
        }
        if (event.target.selectedIndex == 0) {
            this.setState({
                isFirstClick: true
            });
        }
        VoucherTabConstants.PaymentMethods.map((voucher, index) => {
            let selectedStateImages: { imageName: string, stateUSA: StateUSA } | undefined;
            selectedStateImages = getAllStateImages().find(img => img.stateUSA ==
                StateUSA[event.target[event.target.selectedIndex].getAttribute('data-abbreviation')]);
            const id: any = event.target[event.target.selectedIndex].getAttribute('value');

            tempVouchers[index].authorityImage = selectedStateImages !== undefined ? selectedStateImages.imageName
                : "no-image.png";
            tempVouchers[index].authorityID = parseInt(event.target.value);
            tempVouchers[index].authorityName = event.nativeEvent.target[event.nativeEvent.target.selectedIndex].text;
            if (taxingAuthority && taxingAuthority.Id < customAuthority.customAuthorityId) {
                this.setVoucherFormName(tempVouchers[index], index);
            }
            this.setPaymentUrl(taxingAuthority, tempVouchers[index], index);
            this.setDueDate(tempVouchers[index]);
        });
        this.setState({
            vouchers: tempVouchers, currentAuthority: taxingAuthority ? taxingAuthority : initialAuthority,
            showUrlColumn: taxingAuthority ? taxingAuthority.PaymentURL ? true : false : false
        });
    }

    private setDueDate = (voucher: IVoucher) => {
        var dueDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        voucher.dueDate = ConvertDateToUtc(dueDate);
        if (voucher.authorityID !== initialVoucher.authorityID
            && voucher.voucherNo !== initialVoucher.voucherNo) {
            if (this.props.voucherStore != undefined) {
                const voucherDueDate: any = this.props.voucherStore.voucherDefaultDates
                    .find(x => x.authorityId === voucher.authorityID);
                if (voucherDueDate) {
                    switch (voucher.voucherNo) {
                        case VoucherNo.PaymentVoucher:
                            voucher.dueDate = voucherDueDate.taxFilingDate ? voucherDueDate.taxFilingDate : voucher.dueDate;
                            break;
                        case VoucherNo.Q1:
                            voucher.dueDate = voucherDueDate.q1;
                            break;
                        case VoucherNo.Q2:
                            voucher.dueDate = voucherDueDate.q2;
                            break;
                        case VoucherNo.Q3:
                            voucher.dueDate = voucherDueDate.q3;
                            break;
                        case VoucherNo.Q4:
                            voucher.dueDate = voucherDueDate.q4;
                            break;
                    }
                }
            }
        }
    }

    private setVoucherFormName(tempVoucher: IVoucher, index: number) {
        let voucherFormName: string = '';
        let masterFormName: string = '';
        if (tempVoucher.voucherNo > 0) {
            voucherFormName = this.getTenantFormName(tempVoucher);
            masterFormName = this.getMasterFormName(tempVoucher);
            if (masterFormName === null || masterFormName === '') {
                tempVoucher.formName = voucherFormName;
                this.state.selectedFormName[index] = voucherFormName;
            }
            else if (voucherFormName != null && voucherFormName != '') {
                tempVoucher.formName = voucherFormName;
                this.state.selectedFormName[index] = voucherFormName;
            }
            else {
                tempVoucher.formName = masterFormName;
                this.state.selectedFormName[index] = masterFormName;
            }
            this.setFormNameDropdownValues(tempVoucher.paymentType, masterFormName, voucherFormName);
        }
    }

    private getMasterFormName = (voucher: IVoucher): string => {
        let formName: string = '';
        if (voucher.authorityID !== initialVoucher.authorityID) {
            if (this.props.voucherStore != undefined && this.props.taxReturn != undefined) {
                const masterFormName: any = this.props.voucherStore.voucherDefaultDates
                    .find(x => x.authorityId === voucher.authorityID &&
                        x.taxYear === this.props.taxReturn!.taxYear &&
                        x.returnType === Number(EngagementType[this.props.taxReturn!.engagementType]));
                if (masterFormName) {
                    switch (voucher.voucherNo) {
                        case VoucherNo.PaymentVoucher:
                            formName = masterFormName.voucherName ? masterFormName.voucherName : "";
                            break;
                        case VoucherNo.Q1:
                        case VoucherNo.Q2:
                        case VoucherNo.Q3:
                        case VoucherNo.Q4:
                            formName = masterFormName.esVoucherName ? masterFormName.esVoucherName : "";
                            break;
                        default:
                            formName = '';
                            break;
                    }
                }
            }
        }
        return formName;
    }

    private getTenantFormName = (voucher: IVoucher): string => {
        let formName: string = '';
        if (voucher.authorityID !== initialVoucher.authorityID) {
            if (this.props.voucherStore != undefined && this.props.voucherStore.voucherFormNames.length > 0) {
                const tenantFormName: any = this.props.voucherStore.voucherFormNames
                    .find(x => x.authorityId === voucher.authorityID
                        && x.engagementType === Number(EngagementType[this.props.taxReturn!.engagementType])
                        && x.voucherType === voucher.paymentType
                    );
                if (tenantFormName) {
                    formName = tenantFormName.formName;
                }
            }
        }
        return formName;
    }

    private setFormNameDropdownValues(type: VoucherTypes, masterFormName: string, voucherFormName: string) {
        let ddIndex: number = 0;
        if (masterFormName) {
            this.manageFormNameState(type, ddIndex, masterFormName);
            ddIndex++;
        }
        if (voucherFormName) {
            this.manageFormNameState(type, ddIndex, voucherFormName);
            ddIndex++;
        }
    }

    private manageFormNameState = (type: VoucherTypes, ddIndex: number, formName: string) => {
        if (type === VoucherTypes.PaymentVoucher && this.state.paymentFormNames.findIndex(x => x.label === formName) < 0) {
            this.state.paymentFormNames.push({ value: ddIndex.toString(), label: formName })
        }
        else {  
            if (type === VoucherTypes.EstimatedVoucher && this.state.estimatedFormNames.findIndex(x => x.label === formName) < 0) {
                this.state.estimatedFormNames.push({ value: ddIndex.toString(), label: formName });
            }
        }
    }

    private getPaymentUrl = (voucher: IVoucher): string => {
        let paymentUrl: string = '';
        if (voucher.authorityID !== initialVoucher.authorityID &&
            voucher.customType === CustomType.OnlinePaymentRequired) {
            if (this.props.voucherStore != undefined && this.props.voucherStore.paymentUrls.length > 0) {
                const paymentUrlInfo: any = this.props.voucherStore.paymentUrls
                    .find(x => x.authorityId === voucher.authorityID
                        && x.engagementType === Number(EngagementType[this.props.taxReturn!.engagementType])
                        && x.voucherType === voucher.paymentType
                    );
                if (paymentUrlInfo) {
                    paymentUrl = paymentUrlInfo.onlinePaymentUrl;
                }
            }
        }
        return paymentUrl;
    }

    private getMasterPaymentUrl = (voucher: IVoucher): string => {
        let paymentUrl: string = '';
        if (voucher.authorityID !== initialVoucher.authorityID &&
            voucher.customType === CustomType.OnlinePaymentRequired) {
            if (this.props.voucherStore != undefined && this.props.taxReturn != undefined) {
                const voucherDefaultDates: any = this.props.voucherStore.voucherDefaultDates
                    .filter(x => x.authorityId === voucher.authorityID &&
                        x.taxYear === this.props.taxReturn!.taxYear &&
                        x.returnType === Number(EngagementType[this.props.taxReturn!.engagementType]));
                const paymentUrlInfo: any = filterTaxFilingDates(voucherDefaultDates, voucher.formName, voucher.paymentType)
                if (paymentUrlInfo) {
                    switch (voucher.voucherNo) {
                        case VoucherNo.PaymentVoucher:
                            paymentUrl = paymentUrlInfo.paymentURL;
                            break;
                        case VoucherNo.Q1:
                        case VoucherNo.Q2:
                        case VoucherNo.Q3:
                        case VoucherNo.Q4:
                            paymentUrl = paymentUrlInfo.esPaymentURL;
                            break;
                        default:
                            paymentUrl = '';
                            break;
                    }
                }
            }
        }
        return paymentUrl;
    }

    private onAmountBlur = (val: number, index: number) => {
        let tempVouchers: IVoucher[] = [...this.state.vouchers];
        let tempVoucher: IVoucher = Object.assign({}, tempVouchers[index]);
        tempVoucher.amount = val;
        this.setState({ vouchers: this.state.vouchers.map((el, idx) => (el.voucherNo === index + 1 ? Object.assign({}, tempVoucher) : el)) });
    }

    private onPaymentUrlChange = (event: any, index: number) => {
        let tempVouchers: IVoucher[] = [...this.state.vouchers];
        let tempVoucher: IVoucher = Object.assign({}, tempVouchers[index]);
        tempVoucher.onlinePaymentUri = event.target.value;
        this.setState({ vouchers: this.state.vouchers.map((el, idx) => (el.voucherNo === index + 1 ? Object.assign({}, tempVoucher) : el)) });
    }

    private onPaymentUrlChangeDropdown = (value: any, index: number) => {
        this.state.selectedPaymentUrl[index] = value;
        let tempVouchers: IVoucher[] = [...this.state.vouchers];
        let tempVoucher: IVoucher = Object.assign({}, tempVouchers[index]);
        tempVoucher.onlinePaymentUri = value;
        this.state.isMasterUrl[index] = value === this.state.masterPaymentUrl;
        this.setState({ vouchers: this.state.vouchers.map((el, idx) => (el.voucherNo === index + 1 ? Object.assign({}, tempVoucher) : el)) });
    }

    private onCancelAddVoucherPopover = (event: any) => {
        if (event.target.closest('.e-datepicker') || event.target.closest('.e-zoomin') || event.target.closest('.e-other-month'))
            return;
        this.setState({
            showAddVoucherView: false, vouchers: [], currentAuthority: initialAuthority, showAlert: false, message: 'Note:  Zero dollar ($0) vouchers will not be created.',
            alertType: "info", isFirstClick: true
        }, () => this.loadInitialVouchers());
    }

    private onSubmitVoucher = (event: any) => {
        let tempVouchers: IVoucher[] = [...this.state.vouchers];
        let errorMessage: string = VoucherTabConstants.AddedVoucher.OneVoucherWarning;
        const taxingAuthority: any = this.props.getAllTaxingAuthorities.find(x => x.Id == this.state.currentAuthority.Id);
        tempVouchers.every((tempVoucher, index) => {
            let isValid = false;
            if (taxingAuthority)
                tempVoucher.isMasterPaymentUrl = !taxingAuthority.CustomAuthority && this.state.masterPaymentUrl === tempVoucher.onlinePaymentUri ? true : false;
            if (tempVoucher.authorityID === 0) {
                errorMessage = VoucherTabConstants.AddedVoucher.AuthorityWarning;
                return isValid;
            }
            if (tempVoucher.amount > 0) {
                if (tempVoucher.paymentType === VoucherTypes.None) {
                    errorMessage = VoucherTabConstants.AddedVoucher.PaymentTypeWarning;
                    return isValid;
                }
                if (tempVoucher.formName == undefined || tempVoucher.formName.trim() === '') {
                    errorMessage = VoucherTabConstants.AddedVoucher.FormNameWarning;
                    return isValid;
                }
                if (!tempVoucher.dueDate || !moment(tempVoucher.dueDate).isValid()) {
                    errorMessage = VoucherTabConstants.AddedVoucher.DueDateWarning;
                    return isValid;
                }
                if (Number.isNaN(tempVoucher.amount)) {
                    errorMessage = VoucherTabConstants.AddedVoucher.AmountEmptyWarning;
                    return isValid;
                }
                if (tempVoucher.customType === CustomType.None || tempVoucher.customType === CustomType.Select) {
                    errorMessage = VoucherTabConstants.AddedVoucher.CustomTypeWarning;
                    return isValid;
                }
                if (tempVoucher.customType && tempVoucher.customType == CustomType.OnlinePaymentRequired) {
                    if (!tempVoucher.onlinePaymentUri || tempVoucher.onlinePaymentUri === "") {
                        errorMessage = VoucherTabConstants.AddedVoucher.OnlinePaymentUrlWarning;
                        return isValid;
                    }
                    else if (!ValidateWebsite(tempVoucher.onlinePaymentUri.toLowerCase().trim())) {
                        errorMessage = VoucherTabConstants.AddedVoucher.OnlinePaymentValidUrlWarning;
                        return isValid;
                    }
                }
                if (tempVoucher.customType && tempVoucher.customType === CustomType.AddCustom) {
                    if (!tempVoucher.customText || tempVoucher.customText.trim() === "") {
                        errorMessage = VoucherTabConstants.AddedVoucher.CustomTextWarning;
                        return isValid;
                    }
                }
                tempVoucher.vocherMode = VoucherMode.CustomVoucher;
                isValid = true;
                errorMessage = "";
            }
            else {
                isValid = true;
            }
            return isValid;
        });
        if ((errorMessage == "")) {
            if (this.props.onAddMultipeVouchers) {
                this.props.onAddMultipeVouchers(tempVouchers);
                this.loadInitialVouchers(this.state.currentAuthority.AuthorityName);
            }
        } else {
            this.setState({ showAlert: true, alertType: "danger", message: errorMessage });
        }

    }

    private onDueDateChange = (val: any, index: number) => {
        let tempVouchers: IVoucher[] = [...this.state.vouchers];
        let tempVoucher: IVoucher = Object.assign({}, tempVouchers[index]);
        var date = moment(val, 'MM-DD-YYYY').toDate();
        tempVoucher.dueDate = date;
        this.setState({ vouchers: this.state.vouchers.map((el, idx) => (el.voucherNo === index + 1 ? Object.assign({}, tempVoucher) : el)) });
        //Please don't simple convert this to UTC again with out knowing the issues.
        //Converting UTC will change the time by one day in uS time zone.
        //We will feel like it working fine indian time zone  
    }

    private onFormNameChange = (val: any, index: number) => {
        let tempVouchers: IVoucher[] = [...this.state.vouchers];
        let tempVoucher: IVoucher = Object.assign({}, tempVouchers[index]);
        tempVoucher.formName = val;
        tempVoucher.bookmark = tempVoucher.authorityName + ' ' + tempVoucher.formName;
        this.setState({ vouchers: this.state.vouchers.map((el, idx) => (el.voucherNo === index + 1 ? Object.assign({}, tempVoucher) : el)) });
    }

    private onFormNameChangeDropdown = (value: any, index: number) => {
        this.state.selectedFormName[index] = value;
        let tempVouchers: IVoucher[] = [...this.state.vouchers];
        let tempVoucher: IVoucher = Object.assign({}, tempVouchers[index]);
        const paymentType: VoucherTypes = tempVoucher.voucherNo > 1 ? VoucherTypes.EstimatedVoucher : VoucherTypes.PaymentVoucher;
        if (this.props.taxReturn) {
            let voucherIndex = this.props.taxReturn.formGroups
                .findIndex(x => x.group == DocumentGroups.Vouchers);
            if (voucherIndex > 0) {
                let voucherForms: IVoucher[] = this.props.taxReturn.formGroups[voucherIndex].forms as IVoucher[];
                voucherForms.filter(x => x.authorityID === tempVoucher.authorityID && x.paymentType === paymentType).map((voucher, i) => {
                    voucher.isFormNameUpdated = false
                });
            }
        }
        tempVoucher.formName = value;
        tempVoucher.bookmark = tempVoucher.authorityName + ' ' + tempVoucher.formName;
        tempVoucher.isFormNameUpdated = true;
        this.setState({ vouchers: this.state.vouchers.map((el, idx) => (el.voucherNo === index + 1 ? Object.assign({}, tempVoucher) : el)) });
    }

    private onPaymentMethodChange = (val: any, index: number) => {
        let tempVouchers: IVoucher[] = [...this.state.vouchers];
        if (this.state.isFirstClick) {
            let updatedVouchers: IVoucher[] = tempVouchers.map((tempVoucher, idx) => {
                tempVoucher.customType = parseInt(val);
                this.setPaymentUrl(this.state.currentAuthority, tempVoucher, idx);
                return tempVoucher;
            });
            this.setState({ vouchers: updatedVouchers, isFirstClick: false });
        }
        else {
            let tempVoucher: IVoucher = Object.assign({}, tempVouchers[index]);
            tempVoucher.customType = parseInt(val);
            this.setPaymentUrl(this.state.currentAuthority, tempVoucher, index);
            this.setState({ vouchers: this.state.vouchers.map((el, idx) => (el.voucherNo === index + 1 ? Object.assign({}, tempVoucher) : el)) });
        }
    }

    private onCustomTextChange = (event: any, index: number) => {
        let tempVouchers: IVoucher[] = [...this.state.vouchers];
        let tempVoucher: IVoucher = Object.assign({}, tempVouchers[index]);
        tempVoucher.customText = event.target.value;
        this.setState({ vouchers: this.state.vouchers.map((el, idx) => (el.voucherNo === index + 1 ? Object.assign({}, tempVoucher) : el)) });
    }

    private handleAddVoucherBtnClick = (event: any) => {
        this.setState(s => ({ showAddVoucherView: !s.showAddVoucherView, alertType: !s.showAddVoucherView ? "info" : s.alertType, message: 'Note:  Zero dollar ($0) vouchers will not be created.' }));
    }

    private attachAddPopoverRef = (target: any) => { this.setState({ popoverTarget: target }) };

    private onAddRowChange = (event: any, filterType: string, index: number) => {
        let value = event && event.target ? event.target.value : event;
        switch (filterType) {
            case 'dueDate':
                this.onDueDateChange(value, index);
                break;
            case 'formName':
                this.onFormNameChange(value, index);
                break;
            case 'amount':
                this.onAmountBlur(value, index);
                break;
            case 'watermark':
                this.onPaymentMethodChange(value, index);
                break;
        }
    }

    private renderAlert = (type: string) => {
        switch (type) {
            case "danger":
                return <i className='fas fa-times-circle' style={{ marginRight: '5px' }} />;
            case "warning":
                return <i className='fas fa-exclamation-circle' style={{ marginRight: '5px' }} />;
            case "info":
                return <i className='fas fa-info-circle' style={{ marginRight: '5px' }} />;
            case "success":
                return <i className='fas fa-check-circle' style={{ marginRight: '5px' }} />;
            default:
                return <i className='fas' />;
        }
    }

    public render() {
        let authorities: ITaxingAuthority[] = this.props.getAllTaxingAuthorities;
        var currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        const addPopover = (<Bs.Popover id="popover-voucher-add" style={{ maxWidth: 1000 }}>
            <Bs.PopoverTitle>
                <span style={{ fontSize: "16px" }}>{VoucherTabConstants.AddVoucher.AddVoucher}</span>
            </Bs.PopoverTitle>
            <Bs.Alert variant={this.state.alertType} style={{ margin: '0', padding: '0' }} >
                {
                    this.renderAlert(this.state.alertType)
                }
                <span style={{ fontSize: "11px" }}>{this.state.message}</span>
            </Bs.Alert>
            <div className="new-main-content">
                <div>
                    <Bs.Row style={{ marginBottom: "10px" }}>
                        <Bs.Col sm={4} md={3} lg={2} as={Bs.FormLabel} style={{ fontSize: "medium", textAlign: "left", paddingTop: "7px" }}>
                            Authority
                        </Bs.Col>
                        <Bs.Col sm={6} md={5} lg={3} style={{ width: "30%" }}>
                            <select className="form-control"
                                id="add-dd-authority"
                                value={this.state.currentAuthority.Id}
                                onChange={this.onAuthorityChange}>
                                <option value={0} data-abbreviation=''>Select</option>
                                {authorities.map((authority, index) => {
                                    return <option key={index} value={authority.Id} data-abbreviation={authority.Abbreviation}>
                                        {authority.AuthorityName}
                                    </option>
                                })}
                            </select>
                        </Bs.Col>
                    </Bs.Row>
                </div>
                <Bs.Table striped bordered hover>
                    <thead>
                        <tr>
                            <th className="txt-al">
                                Payment Type
                            </th>
                            <th className="txt-al">
                                Form Name
                            </th>
                            <th className="txt-al">
                                Due Date
                            </th>
                            <th className="txt-al">
                                Amount ($)
                            </th>
                            <th colSpan={2} className="txt-al">
                                Watermark
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (this.state.vouchers.length > 0) &&
                            VoucherTabConstants.PaymentMethods.map((voucher, index) => {
                                return (
                                    <tr>
                                        <td>
                                            <Bs.FormControl type="text" disabled={true} value={voucher.label} />
                                        </td>
                                        <td>
                                            <AutoSuggestTextbox id={"formName-" + index}
                                                options={this.state.vouchers[index].paymentType == VoucherTypes.PaymentVoucher ? this.state.paymentFormNames : this.state.estimatedFormNames}
                                                onChange={(event) => this.onFormNameChangeDropdown(event, index)}
                                                selectedValue={this.state.selectedFormName[index]}
                                                formMenuClass="formNameList"
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker value={this.state.vouchers[index].dueDate || currentDate} onChange={(event) => this.onAddRowChange(event, "dueDate", index)} />
                                        </td>
                                        <td>
                                            <CurrencyTextBox value={this.state.vouchers[index].amount} placeholder="Amount"
                                                onBlur={(event) => this.onAddRowChange(event, "amount", index)}
                                                decimalPlaces={0} />
                                        </td>
                                        <td style={{ width: '150px' }}>
                                            <select className="form-control"
                                                name="dd-payment-methods"
                                                value={this.state.vouchers[index].customType}
                                                onChange={(event) => this.onAddRowChange(event, "watermark", index)}>
                                                {VoucherTabConstants.AddVoucher.paymentMethods.map((method, index) => {
                                                    return <option key={index} value={method.value}>{method.label}</option>
                                                })}
                                            </select>
                                        </td>
                                        {
                                            this.state.vouchers[index].customType == CustomType.AddCustom ?
                                                <td >
                                                    <Bs.FormControl style={{ height: '33px' }} className="resize-vertical" as="textarea" name="customText" maxLength={50}
                                                        onChange={(event: any) => this.onCustomTextChange(event, index)} value={this.state.vouchers[index].customText} />
                                                </td> :
                                                this.state.vouchers[index].customType == CustomType.OnlinePaymentRequired ? this.state.isPaymentUrlSame[index] ?
                                                    <td style={{ minWidth: 100 }}>
                                                        <Bs.FormControl type="url" value={this.state.vouchers[index].onlinePaymentUri} name="paymentUrl"
                                                            onChange={(event: any) => this.onPaymentUrlChange(event, index)} />
                                                    </td> :
                                                    <td style={{ minWidth: 100 }}>
                                                        <div>
                                                            <AutoSuggestTextbox
                                                                id={"voucher-" + index}
                                                                options={this.state.vouchers[index].paymentType == VoucherTypes.PaymentVoucher ? this.state.paymentUrls : this.state.estimatedUrls}
                                                                onChange={(event) => this.onPaymentUrlChangeDropdown(event, index)}
                                                                selectedValue={this.state.selectedPaymentUrl[index]}
                                                                formMenuClass="optionlist"
                                                            />
                                                        </div>
                                                    </td> :
                                                    <td style={{ minWidth: 100 }}>
                                                    </td>
                                        }
                                    </tr>)
                            }, this)}
                    </tbody>
                </Bs.Table>
            </div>

            <div className="modal-footer">

                <Bs.Button
                    variant="light"
                    onClick={this.onCancelAddVoucherPopover}
                ><i className="fas fa-times"></i>
                    Cancel
                </Bs.Button>

                <Bs.Button
                    variant='info'
                    onClick={this.onSubmitVoucher}
                > <i className='fas fa-plus'></i>
                    {VoucherTabConstants.AddVoucher.btnAddVoucherText}
                </Bs.Button>
            </div>
        </Bs.Popover>
        )



        return <div>
            <span className="pull-right">
                <button
                    className="btn-plus btn-success fa fa-plus"
                    title="Add Multiple Voucher"
                    onClick={this.handleAddVoucherBtnClick}
                    ref={this.attachAddPopoverRef}
                    data-test-auto="C60C7E71-7470-4F14-BAE7-01E924C0A46B"
                >
                </button>
            </span>
            <Bs.Overlay
                rootClose={true} onHide={this.onCancelAddVoucherPopover}
                target={this.state.popoverTarget} containerPadding={20}
                placement="right" show={this.state.showAddVoucherView}>
                {addPopover}
            </Bs.Overlay>
        </div >
    }
}; 