import React from  'react';
import { /*Panel,*/ Row, Col, Button, Card, Accordion} from 'react-bootstrap';
import { RBACKeys } from 'src/components/helper/RbacConstants';
import * as TaxDocument from '../../../common/TaxReturn';
import { DocumentGroups, IEFile, IVoucher, SignatureControlRole, SignatureType } from '../../../common/TaxReturn';
import { ProcessReturnConstants } from '../../../helper/Constants';
import { VenusNotifier } from '../../../helper/VenusNotifier';
import { IAdditionalESignDocumentControl, IDocumentData } from '../AdditionalEsignDocuments/AdditionalEsignDocument';
import { removeAllUserSignature } from '../SignatureControls/SignatureHelper';

interface IDeliverToCompany {
    taxreturn: TaxDocument.ITaxReturn;
    cancelFinish: () => void;
    deliverToClient: () => void;
}

export class DeliverToCompany extends React.Component<IDeliverToCompany, {}> {

    constructor(props: IDeliverToCompany) {
        super(props);
    }

    public onDeliverToClient = () => {

        if (this.props.taxreturn.documentSettings.documentSignatureSetting.signatureFormSelectionType == SignatureType.ESignWhenAllIncludedReturnsAllowed) {
            if (!this.validateSignature()) {
                return false;
            }
        } else if (this.props.taxreturn.documentSettings.documentSignatureSetting.signatureFormSelectionType === SignatureType.ReviewOnly) {
            this.removeAllSignature();
        }

        if (this.props.taxreturn.documentSettings.documentSignatureSetting.signatureFormSelectionType === SignatureType.ReviewOnly) {

            let tempTaxReturn: any = { ...this.props.taxreturn };

            if (TaxDocument.isPartnership(tempTaxReturn)) {

                let tempFormsGroup: TaxDocument.ICorporateTaxReturn = { ...this.props.taxreturn as TaxDocument.ICorporateTaxReturn };
                let formIndex = tempFormsGroup.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
                let voucherIndex = tempFormsGroup.formGroups.findIndex(x => x.group == DocumentGroups.Vouchers);
                let additionalIndex = tempFormsGroup.additionalEsignDocuments.length;

                if (formIndex > -1 || voucherIndex > -1 || additionalIndex > 0) {
                    if (formIndex > -1) {
                        let efileForms: IEFile[] = tempFormsGroup.formGroups[formIndex].forms as IEFile[];
                        efileForms.forEach(efileForm => {
                            efileForm.signatureControls = efileForm.signatureControls.filter(x => x.signatureControlRole != SignatureControlRole.PartnerShip);
                            efileForm.signatureControls = efileForm.signatureControls.filter(x => x.signatureControlRole != SignatureControlRole.ERO); 
                        })
                        tempFormsGroup.formGroups[formIndex].forms = efileForms;
                    }
                    if (voucherIndex > -1) {
                        let voucherForms: IVoucher[] = tempFormsGroup.formGroups[voucherIndex].forms as IVoucher[];
                        voucherForms.forEach(voucherForm => {
                            voucherForm.signatureControls = voucherForm.signatureControls.filter(x => x.signatureControlRole != SignatureControlRole.PartnerShip);
                            voucherForm.signatureControls = voucherForm.signatureControls.filter(x => x.signatureControlRole != SignatureControlRole.ERO); 
                        })
                        tempFormsGroup.formGroups[voucherIndex].forms = voucherForms;
                    }
                    if (additionalIndex > 0) {
                        let additionalForms: IDocumentData[] = tempFormsGroup.additionalEsignDocuments as IDocumentData[];
                        additionalForms.forEach(form => form.documentControls.filter(documentControl => {
                            documentControl.controls = documentControl.controls.filter(control => control.signatureControlRole != SignatureControlRole.PartnerShip);
                            documentControl.controls = documentControl.controls.filter(control => control.signatureControlRole != SignatureControlRole.ERO); 
                        }))
                        tempFormsGroup.additionalEsignDocuments = additionalForms;
                    }
                }
            }
        }
        this.props.deliverToClient();

    }
    validateSignature = () => {
        let tempFormsGroup = { ...this.props.taxreturn };
        let formIndex = tempFormsGroup.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
        let voucherIndex = tempFormsGroup.formGroups.findIndex(x => x.group == DocumentGroups.Vouchers);
        let hasEsign: boolean = false;

        if (formIndex > -1) {
            let efileForms: IEFile[] = tempFormsGroup.formGroups[formIndex].forms as IEFile[];
            efileForms.forEach(efileForm => {
                let controls: TaxDocument.ISignatureControl[] = efileForm.signatureControls;
                controls = controls.filter(x => x.signatureControlRole === SignatureControlRole.Taxpayer ||
                    x.signatureControlRole === SignatureControlRole.Spouse ||
                    x.signatureControlRole === SignatureControlRole.PartnerShip);

                if (controls?.length > 0) { hasEsign = true; }
            });
        }
        if (voucherIndex > -1) {
            let voucherForms: IVoucher[] = tempFormsGroup.formGroups[voucherIndex].forms as IVoucher[];
            voucherForms.forEach(voucherForm => {
                let controls: TaxDocument.ISignatureControl[] = (voucherForm.signatureControls);
                controls = controls.filter(x => x.signatureControlRole === SignatureControlRole.Taxpayer ||
                    x.signatureControlRole === SignatureControlRole.Spouse ||
                    x.signatureControlRole === SignatureControlRole.PartnerShip);
                if (controls?.length > 0) { hasEsign = true; }
            });
        }

        let additionalForms: IDocumentData[] = tempFormsGroup.additionalEsignDocuments as IDocumentData[];
        if (additionalForms && additionalForms.length) {
            additionalForms.forEach(additionalForm => {
                additionalForm.documentControls.forEach(y => {
                    let controls: IAdditionalESignDocumentControl[] = y.controls;
                    controls = controls.filter(x => x.signatureControlRole === SignatureControlRole.Taxpayer ||
                        x.signatureControlRole === SignatureControlRole.Spouse ||
                        x.signatureControlRole === SignatureControlRole.PartnerShip);
                    if (controls?.length > 0) { hasEsign = true; }
                });
            });
        }
        if (!hasEsign) {
            VenusNotifier.Warning(ProcessReturnConstants.NoSignatureMessage, "");
            return false;
        }
        return true;
    }
    removeAllSignature = () => {
        this.props.taxreturn.formGroups = removeAllUserSignature(this.props.taxreturn);
        this.forceUpdate();
    }

    public render() {
        return <div className="padT04">
            {
                <Accordion defaultActiveKey={"0"}>
                    <Card className="marR20 border-radius-04">
                        <Card.Body>
                        {
                            TaxDocument.isPartnership(this.props.taxreturn) ?
                                <div>
                                    <Row className="marB15">
                                        <Col className="nopadding-right" sm={3} as={Col}>
                                            Company Name
                                </Col>
                                        <Col className="nopadding-left" sm={9} as={Col}>
                                            {this.props.taxreturn.partnership.name}
                                        </Col>

                                    </Row>
                                    <Row className="marB15">
                                        <Col className="nopadding-right" sm={3} as={Col}>
                                            Company EIN
                                </Col>
                                        <Col className="nopadding-left" sm={9} as={Col}>
                                            {this.props.taxreturn.partnership.ssn}
                                        </Col>


                                    </Row>
                                    <Row className="marB15">
                                        <Col className="nopadding-right" sm={3} as={Col}>
                                            Company Email
                                </Col>
                                        <Col className="nopadding-left" sm={9} as={Col}>
                                            {this.props.taxreturn.partnership.email}
                                        </Col>


                                    </Row>
                                </div>
                                :
                                ""
                        }
                        <div>
                            <Col sm={12}>
                                <Button type="button" variant="info" className="pull-right" data-test-auto="DD34A32B-935B-4D21-AF90-0989DE8BFD3E"
                                        onClick={this.onDeliverToClient}
                                ><i className="fa fa-paper-plane text-secondary" style={{ paddingRight: "10px" }}></i>Send</Button>
                                    <Button type="button" variant="light" className="pull-right marR05" data-test-auto="EAB6FEF8-0152-495A-ACAC-F07B48F6BF09"
                                    onClick={this.props.cancelFinish}
                                ><i className="glyphicon glyphicon-remove" style={{ paddingRight: "10px" }}></i>Cancel</Button>
                            </Col>
                        </div>
                            </Card.Body>
                        </Card>
                </Accordion>
            }</div>
    }
}

export default DeliverToCompany;