import React from  'react';
import { ProgressBar, FormControl, Modal, Button, Alert, NavLink } from 'react-bootstrap';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { DocumentUploadDropzoneComponent } from '../../../common/UploadDocument/DocumentUploadDropzoneComponent';
import { UploadedDocumentsTable } from '../../../common/UploadDocument/UploadedDocumentsTable';
import { getFileExtension, validatePdfFile } from '../../../helper/Validations';
import { UploadtaxReturnConstants } from '../../../helper/Constants';
import { getFileSize, GetFileMagicNumber } from '../../../helper/HelperFunctions';
import { ShowLoader, HideLoader } from '../../../helper/Loader';
import * as Validation from '../../../helper/Validations';
import * as bootbox from 'bootbox';
import { IUserModel } from 'src/Core/ViewModels/User/UserViewModel';
import { IAssignmentsState } from '../../../../store/assignments/AssignmentsState';
import { Redirect } from 'react-router-dom';
import { ITaxReturn, initialTaxReturnState } from '../../TaxReturn';
import * as UploadTaxReturnStore from '../../../../store/common/uploadTaxReturn';
import { ButtonFormatter } from './UploadCommonFormatters';
import { SasContainer, TaxReturnUploadData, UploadStatus } from '../../../../Core/ViewModels/Common/UploadDocumentViewModel';
import { UploadCorrectedReturnWarning } from '../../../helper/Constants';
import { initialGroupInfo } from '../../../../Core/ViewModels/GroupExtensions/ComponentModels';
import { logClientEvent } from '../../../helper/LoggerHelper';
import { API_BASE_URL } from 'src/utils/contants';
import { UploadFunctions, isFileExist } from "@sssuite-js-packages/file-utility";
import { LogEventConstants } from 'src/components/helper/LogEventConstants';
import { IReturnAccessSettingsModel } from 'src/Core/ViewModels/Company/CompanySettingsViewModel';
import { IAccessingUsersAndUserGroups, IUserDataFromDB, IUserGroupDataFromDB } from '../../SetAccess/SetAccessCommonModal.model';
import SetAccessCommonModal from '../../SetAccess/SetAccessCommonModal';

interface ProSystemUploadRecallModalProps {
    documentKey?: string;
    onShow: boolean;
    onHide(): void;
    EROs: IUserModel[];
    routing?: boolean;
    getUploadLink: (url: string, callback?: (data?: UploadTaxReturnStore.IUploadTaxReturnState) => void) => void;
    processTaxReturn: (url: string, callback: () => void) => void;
    submitTaxReturn: (url: string, taxData: string, callback: () => void) => void;
    uploadConfirmation: (documentKey: string, clientId: string) => void;
    isRecalled?: boolean;
    returnAccessSettings: IReturnAccessSettingsModel;
    availableUsers: IUserDataFromDB[];
    selectedUsers: IUserDataFromDB[];
    availableUserGroups: IUserGroupDataFromDB[];
    selectedUserGroups: IUserGroupDataFromDB[];
}

export interface ProSystemUploadRecallModalState {
    process: number;
    fileName: string;
    fileSize: number;
    clientId: string;
    TaxReturnUploadData: TaxReturnUploadData[];
    gridColumnDisable: boolean;
    showUpload: boolean;
    showSetAccess: boolean;
    EROs: IUserModel[];
    sasContainer: SasContainer[];
    config: {
        dropzoneSelector: string;
        iconFiletypes: ['.pdf'];
        showFiletypeIcon: boolean;
        postUrl: string;
    };
    setAccessAccessingUsers: IUserModel[];
    setAccessCurrentUsers: IUserModel[];
    taxReturnIndex: number;
    abortRequests: boolean;
    fileAdded: boolean;
    companyAssignmentsRedirect: boolean;
    assignments: IAssignmentsState;
    taxReturns: ITaxReturn;
    availableUsers: IUserDataFromDB[];
    selectedUsers: IUserDataFromDB[];
    availableUserGroups: IUserGroupDataFromDB[];
    selectedUserGroups: IUserGroupDataFromDB[];
}

export class ProSystemUploadRecalledDocumentModal extends React.Component<ProSystemUploadRecallModalProps, ProSystemUploadRecallModalState> {

    constructor(props: ProSystemUploadRecallModalProps) {
        super(props);
        this.state = {
            process: 0,
            fileName: "",
            fileSize: 0,
            clientId: "",
            TaxReturnUploadData: [],
            gridColumnDisable: true,
            showUpload: false,
            showSetAccess: false,
            EROs: [],
            sasContainer: [],
            config: {
                dropzoneSelector: 'div.filepicker',
                iconFiletypes: ['.pdf'],
                showFiletypeIcon: true,
                postUrl: '/api/'
            },
            setAccessAccessingUsers: [],
            setAccessCurrentUsers: [],
            taxReturnIndex: 0,
            abortRequests: false,
            fileAdded: false,
            companyAssignmentsRedirect: false,
            assignments: {
                assignments: [],
                isLoading: true,
                query: '',
                totalRowCount: 0
            },
            taxReturns: initialTaxReturnState,
            availableUsers: [],
            selectedUsers: [],
            availableUserGroups: [],
            selectedUserGroups: []
        }
        this.deletingReturn = this.deletingReturn.bind(this);
        this.deleteReturnRow = this.deleteReturnRow.bind(this);
        this.onSetAccess = this.onSetAccess.bind(this);
        this.onSetAccessHide = this.onSetAccessHide.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.setAcessShow = this.setAcessShow.bind(this);
        this.onSetAccessSave = this.onSetAccessSave.bind(this);
        this.closeUploadWindow = this.closeUploadWindow.bind(this);
        this.uploadProgressCallback = this.uploadProgressCallback.bind(this);
    }

    componentWillReceiveProps(nextProps: ProSystemUploadRecallModalProps) {

        if (nextProps.EROs && nextProps.EROs.length > 0) {
            this.setState({
                EROs: this.props.EROs
            });
        }
        if(nextProps.availableUsers.length>0){
            const { availableUsers, selectedUsers, availableUserGroups, selectedUserGroups } = nextProps;
            this.setState({
                availableUsers,
                selectedUsers,
                availableUserGroups,
                selectedUserGroups
            });
        }


    }

    componentDidMount() {
    }

    //===========================================================================
    // Bootstrap Table Formatters start
    //===========================================================================

    defaultType(cell: any, row: any) {
        return cell;
    }

    statusFormatter(cell: any, row: any) {
        return (<span>{UploadStatus[cell].toString()}</span>);
    }

    buttonFunction(cell: any, row: any) {
        return (
            <ButtonFormatter deleteReturn={() => this.deletingReturn(row)} disabled={(row.progressBar != undefined) && (row.progressBar != 100) ? true : false} data-test-auto="B07622E7-FEB7-4759-BA19-4DF7D79BE7E9" />
        );
    }

    progressbar(cell: any, row: any) {
        return <ProgressBar striped variant={(cell != undefined) && (cell != 100) ? "warning" : "success"} now={cell} />
    }

    textBox(cell: any, row: any) {
        return <FormControl type="text" disabled={row.gridRowDisable}
            defaultValue={cell}
            onChange={this.onClientIdChange.bind(this, row)}
            data-test-auto="8E584D37-7EF3-4D2A-8CC8-E21FA295F6EB" />
    }


    linkFormat(cell: any, row: any) {
        return <NavLink href="javascript:" style={{ textDecoration: "underline" }} onClick={this.onSetAccess.bind(this, row)} data-test-auto="7FDF51B6-41F6-4E58-9A03-C52D36983AF0">{cell}</NavLink>
    }

    //===========================================================================
    // Bootstrap Table Formatters End
    //===========================================================================

    djsConfig = {
        uploadMultiple: false,
        parallelUploads: 1,
        maxFiles: 1,
        addRemoveLinks: true,
        acceptedFiles: "application/pdf",
        headers: { 'Access-Control-Allow-Origin': '*', "x-ms-blob-type": "BlockBlob" },
        method: 'PUT',
        previewsContainer: false
    }

    eventHandlers = {
        init: () => {
            this.reset();
        },
        addedfiles: (files: any) => {
            bootbox.hideAll();
            let _self = this;
            let tempGridData = _self.state.TaxReturnUploadData;
            let uploadHelperFunctions = new UploadFunctions();

            let rowCount = tempGridData.length;
            if (rowCount > 0) {
                bootbox.alert(UploadtaxReturnConstants.Recall.FileAddingWarning);
                return;
            }
            if (files.length > 1) {

                bootbox.alert(UploadtaxReturnConstants.Recall.MultipleFileAddingWarning);
                return false;
            }

            let file = files[0];
            if (Validation.validateFileSize(file)) {

                this.props.getUploadLink(`${API_BASE_URL}/api/Upload/ReplaceGetTaxReturnUploadLinkAsync?documenGUID=` + this.props.documentKey,
                    (data?: UploadTaxReturnStore.IUploadTaxReturnState) => {
                        _self.setState({ gridColumnDisable: true, fileAdded: true })
                        let fileName = file.name;
                        let documentKey = _self.props.documentKey;
                        let fileExtension = getFileExtension(fileName);
                        GetFileMagicNumber(file).then((result) => {
                            if (validatePdfFile(fileExtension, result)) {
                                let _fetchThis = this;
                                _self.setState({ gridColumnDisable: true });
                                let filecount = 1;
                                while (isFileExist(fileName, tempGridData)) {
                                    fileName = file.name;
                                    fileName = fileName.replace("." + fileExtension, '');
                                    fileName = fileName + " - Copy (" + filecount + ")." + fileExtension;
                                    filecount++;
                                }
                                tempGridData.push({
                                    name: fileName, clientName: "clname", clientId: fileName.replace("." + fileExtension, ''), number: tempGridData.length + 1,
                                    progressBar: _self.state.process, 
                                    setAccess: !(
                                        this.props.returnAccessSettings != undefined &&
                                        this.props.returnAccessSettings!.isSetAccessToEveryone
                                    )
                                        ? "Restricted"
                                        : "Everyone",
                                    size: getFileSize(file.size),
                                    status: UploadStatus.Uploading, sasGuid: documentKey, gridRowDisable: true, 
                                    documentAccess: { documents: [], 
                                        userGroups: this.state.selectedUserGroups.map((group) => {
                                            return group.id;
                                        }),
                                        users: this.state.selectedUsers.map((user) => {
                                            return user.id;
                                        })
                                    },
                                    groupInfo: initialGroupInfo
                                });
                                _self.setState({ TaxReturnUploadData: tempGridData });

                                _self.setState(() => uploadHelperFunctions.uploadFile(file, data, fileName, _fetchThis.uploadProgressCallback, _fetchThis.uploadCommittCallBack, _self.uploadFailureCallback));
                            }
                        });
                    });
            }
        }
    }

    public uploadProgressCallback(percent: number, fileToUpload: any) {
        let tempGridData = this.state.TaxReturnUploadData;
        tempGridData.map((tempGridDataValue, index) => {
            if (tempGridDataValue.sasGuid == fileToUpload.fileGUID) {
                tempGridDataValue.progressBar = percent - 10;
            }
        });
        this.setState({
            TaxReturnUploadData: tempGridData
        });
    }

    uploadCommittCallBack = (fileToUpload: any) => {
        if (this.state.TaxReturnUploadData.length > 0) {
            let tempGridData = this.state.TaxReturnUploadData;
            tempGridData.map((tempGridDataValue, index) => {
                if (tempGridDataValue.name == fileToUpload.fileName) {
                    tempGridDataValue.progressBar = 100;
                    tempGridDataValue.status = UploadStatus.Uploaded;
                    tempGridDataValue.gridRowDisable = false;
                }
            });
            this.setState({
                TaxReturnUploadData: tempGridData
            }, () => {
                if (!this.isUploadOnGoing()) {
                    this.setState({ gridColumnDisable: false });
                }
            });
        }
    }

    uploadFailureCallback = (fileToUpload: any) => {
    // On failure of retry , Error view and pop up notification need to be discussed with team, and will be implemented in next sprit.
    }

    private isUploadOnGoing = (): boolean => {
        let isuploading: boolean = false;
        this.state.TaxReturnUploadData.map((value) => {
            if (value.progressBar == undefined ? 0 : value.progressBar < 100) {
                isuploading = true;
                return;
            }
        });
        return isuploading;
    }

    deletingReturn(row: any) {
        let _self = this;
        bootbox.confirm(UploadtaxReturnConstants.DeleteFileMessage, function (result) {
            if (result) {
                _self.deleteReturnRow(row);
            }
        });
    }

    deleteReturnRow(row: any) {
        let _self = this;
        var _gridData = [..._self.state.TaxReturnUploadData];
        _gridData = _gridData.filter(i => i.number != row.number);
        let _tempgridData = _self.state.TaxReturnUploadData;
        _tempgridData = _gridData;

        let gridColumnDisable = this.state.gridColumnDisable;
        if (_gridData.length == 0) {
            gridColumnDisable = true;
        }

        let _uploadData = [..._self.state.sasContainer];
        _uploadData = _uploadData.filter(i => i.sasGuid != row.sasGuid);

        let _tempUploadData = _self.state.sasContainer;
        _tempUploadData = _uploadData;

        _self.setState({
            TaxReturnUploadData: _tempgridData,
            sasContainer: _tempUploadData,
            fileAdded: false,
            gridColumnDisable: gridColumnDisable
        });
    }


    private onClientIdChange(row: any, event: any) {
        var _gridData = [...this.state.TaxReturnUploadData];
        _gridData.map((value, index) => {
            if (value.number == row.number) {
                value.clientId = event.target.value
            }
        })
    }

    //===========================================================================
    // Set Access End 
    //===========================================================================

    setAcessShow() {
        this.setState({ showSetAccess: true })
    }

    onSetAccess(taxReturn: any, event: any) {
        let currentDoc = this.state.TaxReturnUploadData.find(x=>x.rowIndex==taxReturn.rowIndex)
        
        const allUsers = [...this.state.selectedUsers, ...this.state.availableUsers];
        let selectedUser: IUserDataFromDB[] = [];
        currentDoc.documentAccess.users.forEach((userId) => {
            let user = allUsers.find((x) => x.id == userId);
            if (user) {
                selectedUser.push(user);
            }
        });
        let availableUsers: IUserDataFromDB[] = [];
        allUsers.forEach((user) => {
            let isSelectedUser = selectedUser.find((x) => x.id == user.id);
            if (!isSelectedUser) {
                availableUsers.push(user);
            }
        });
        const allUserGroups = [...this.state.selectedUserGroups, ...this.state.availableUserGroups];
        let selectedUserGroups: IUserGroupDataFromDB[] = [];
        currentDoc.documentAccess.userGroups.forEach((userGroupId) => {
            let userGroup = allUserGroups.find((x) => x.id == userGroupId);
            if (userGroup) {
                selectedUserGroups.push(userGroup);
            }
        });
        let availableUserGroups: IUserGroupDataFromDB[] = [];
        allUserGroups.forEach((userGroup) => {
            let isSelectedUserGroup = selectedUserGroups.find((x) => x.id == userGroup.id);
            if (!isSelectedUserGroup) {
                availableUserGroups.push(userGroup);
            }
        });
        this.setState({
            taxReturnIndex: taxReturn.number,
            showSetAccess: true,
            selectedUsers: selectedUser,
            selectedUserGroups: selectedUserGroups,
            availableUsers: availableUsers,
            availableUserGroups:availableUserGroups
        });
    }

    onSetAccessHide() {
        this.setState({
            showSetAccess: false
        })
    }

    private onSetAccessSave = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const index = this.state.taxReturnIndex;
        const { selectedUsers, selectedUserGroups, availableUsers, availableUserGroups } = accessingUsersAndUserGroups;
        this.state.TaxReturnUploadData.map((value) => {
            if (value.rowIndex == index) {
                let isAllUsers = false;
                let isAllUserGroups = false;
                if (
                    (selectedUsers.length > 0 && selectedUsers.indexOf(0) !== -1) ||
                    availableUsers.length === 0 ||
                    (availableUsers.length === 1 && availableUsers[0] === 0)
                ) {
                    isAllUsers = true;
                }

                if (
                    (selectedUserGroups.length > 0 && selectedUserGroups.indexOf(0) !== -1) ||
                    availableUserGroups.length === 0 ||
                    (availableUserGroups.length === 1 && availableUserGroups[0] === 0)
                ) {
                    isAllUserGroups = true;
                }

                if (isAllUsers && isAllUserGroups) {
                    value.setAccess = "Everyone";
                } else {
                    value.setAccess = "Restricted";
                }

                value.documentAccess = {
                    documents: [],
                    userGroups: accessingUsersAndUserGroups.selectedUserGroups,
                    users: accessingUsersAndUserGroups.selectedUsers
                };
            }
        });
        this.setState({
            showSetAccess: false,
            selectedUsers: this.getSelectedUser(accessingUsersAndUserGroups),
            availableUsers: this.getAvailableUser(accessingUsersAndUserGroups),
            selectedUserGroups: this.getSelectedUserGroups(accessingUsersAndUserGroups),
            availableUserGroups: this.getAvailableUserGroups(accessingUsersAndUserGroups)
        });
    }

    getSelectedUser = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUsers = [...this.state.selectedUsers, ...this.state.availableUsers];
        let selectedUser: IUserDataFromDB[] = [];
        accessingUsersAndUserGroups.selectedUsers.forEach((userId) => {
            let user = allUsers.find((x) => x.id == userId);
            if (user) {
                selectedUser.push(user);
            }
        });
        return selectedUser;
    };

    getAvailableUser = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUsers = [...this.state.selectedUsers, ...this.state.availableUsers];
        let availableUser: IUserDataFromDB[] = [];
        accessingUsersAndUserGroups.availableUsers.forEach((userId) => {
            let user = allUsers.find((x) => x.id == userId);
            if (user) {
                availableUser.push(user);
            }
        });
        return availableUser;
    };

    getSelectedUserGroups = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUserGroups = [...this.state.selectedUserGroups, ...this.state.availableUserGroups];
        let selectedUserGroups: IUserGroupDataFromDB[] = [];
        accessingUsersAndUserGroups.selectedUserGroups.forEach((userGroupId) => {
            let userGroup = allUserGroups.find((x) => x.id == userGroupId);
            if (userGroup) {
                selectedUserGroups.push(userGroup);
            }
        });
        return selectedUserGroups;
    };

    getAvailableUserGroups = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUserGroups = [...this.state.selectedUserGroups, ...this.state.availableUserGroups];
        let availableUserGroups: IUserGroupDataFromDB[] = [];
        accessingUsersAndUserGroups.availableUserGroups.forEach((userGroupId) => {
            let userGroup = allUserGroups.find((x) => x.id == userGroupId);
            if (userGroup) {
                availableUserGroups.push(userGroup);
            }
        });
        return availableUserGroups;
    };

    //===========================================================================
    // Set Access End 
    //===========================================================================

    private reset = () => {
        let tempUploadData: SasContainer[] = [];
        let tempGridData: TaxReturnUploadData[] = [];
        this.state.sasContainer.slice(0, this.state.sasContainer.length);
        this.setState({
            sasContainer: tempUploadData,
            TaxReturnUploadData: tempGridData,
            taxReturns: initialTaxReturnState,
            gridColumnDisable: true
        });
    }

    private handleClose() {
        let _self = this;
        let rowCount = this.state.TaxReturnUploadData.length;
        //If file is uploading
        if (this.isUploadOnGoing()) {
            bootbox.alert(UploadtaxReturnConstants.AbortingUploadWarning);
            return false;
        }
        //If there is an uploaded file        
        if (rowCount > 0) {
            bootbox.confirm({
                message: UploadtaxReturnConstants.CloseConfirmationMessage,
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: 'btn-white btn-default'
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> OK',
                        className: 'btn-info'
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        _self.closeUploadWindow();
                    }
                }
            });
        }
        else {

            this.closeUploadWindow();
        }

    }

    private closeUploadWindow() {
        this.reset();
        //Request.
        this.setState({ abortRequests: true });
        this.props.onHide();
        this.setState({
            showSetAccess: false
        })
    }


    onSubmitTaxreturns = () => {

        ShowLoader();
        const uploadData = this.state.TaxReturnUploadData;
        const docKey = this.props.documentKey;
        this.props.submitTaxReturn(`${API_BASE_URL}/api/Upload/SubmitRecallTaxReturnsAync`, JSON.stringify(uploadData), () => {
            this.props.uploadConfirmation(docKey || "", uploadData[0].clientId || "");
            this.uploadConfirmation();
            this.closeUploadWindow();
            HideLoader();
        });
        logClientEvent(`ProSystem ${LogEventConstants.Common.RecallUpload}`,
                { count: uploadData.length }
            );
    }

    private uploadConfirmation() {
        let message: string = UploadtaxReturnConstants.SubmitTaxReturnSuccess + ": <br/><ul class='padding-left-6'>";
        this.state.TaxReturnUploadData.map((value, index) => {
            message = message + "<li>" + value.clientId + "</li>";
        });
        message = message + "</ul>";
        bootbox.alert({
            message,
            buttons: {
                ok: {
                    label: '<i class="fas fa-check"></i> OK',
                    className: 'btn-info'
                }
              }
        })
        if (this.props.routing) { this.redirect(); }
    }

    private redirect() {
        this.setState({
            assignments: {
                assignments: [] as ITaxReturn[],
                query: '',
                isLoading: false,
                totalRowCount: 0
            },
            companyAssignmentsRedirect: true
        })
    }

    public render() {

        const columns = [
            {
                header: '#',
                key: 'number',
                isKey: true,
                dataFormat: this.defaultType,
                width: '30px',
                columnClassName: ''
            },
            {
                header: 'Name',
                key: 'name',
                isKey: false,
                dataFormat: this.defaultType,
                width: 'auto',
                columnClassName: 'word-Visible'
            },
            {
                header: 'Upload Progress',
                key: 'progressBar',
                isKey: false,
                dataFormat: this.progressbar,
                width: 'auto',
                columnClassName: ''
            },
            {
                header: 'Size',
                key: 'size',
                isKey: false,
                dataFormat: this.defaultType,
                width: 'auto',
                columnClassName: ''
            },
            {
                header: 'Status',
                key: 'status',
                isKey: false,
                dataFormat: this.statusFormatter,
                width: 'auto',
                columnClassName: ''
            },
            {
                header: <span>Client ID<span className='asterisk-error'>*</span></span>,
                key: 'clientId',
                isKey: false,
                dataFormat: this.textBox.bind(this),
                width: '240px',
                columnClassName: ''
            },
            {
                header: 'Set Access',
                key: 'setAccess',
                isKey: false,
                dataFormat: this.linkFormat.bind(this),
                width: 'auto',
                columnClassName: ''
            },
            {
                header: 'Remove',
                key: 'button',
                isKey: false,
                dataFormat: this.buttonFunction.bind(this),
                width: 'auto',
                columnClassName: ''
            }
        ];

        if (this.state.companyAssignmentsRedirect) {
            return <Redirect to='/CompanyAssignments' />;
        }

        return <div>
            <Modal className="upload-doc-modal in" show={this.props.onShow} onHide={this.handleClose} keyboard={false} backdrop={false} enforceFocus={false}>
                <Modal.Header closeButton data-test-auto="9DFD99E6-5A5C-4A9A-B40F-975AB2D109B5">
                    <Modal.Title> Upload Document </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-2 text-center">
                            <DocumentUploadDropzoneComponent
                                componentConfig={this.state.config}
                                djsConfig={this.djsConfig}
                                eventHandlers={this.eventHandlers}
                                autoTestId={"87079975-3E83-4449-863F-0019EC15A9AF"}
                            />
                        </div>
                        <div className="col-sm-10" id="uploadDocument">
                            <UploadedDocumentsTable
                                column={columns}
                                data={this.state.TaxReturnUploadData}
                                bodyContainerClass={"overflowVisible"}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        this.props.isRecalled ? <Alert variant="warning" style={{ width: "100%", textAlign: "left" }}><i className='fas fa-info-circle'>
                        </i><span style={{ paddingLeft: "5px" }}>{UploadCorrectedReturnWarning.message}</span></Alert> : ""
                    }
                    <Button variant="default" onClick={this.handleClose} data-test-auto="B1C823D3-8D5E-4B9B-9D59-11F3A0D9BEED">
                        <i className='fas fa-times'></i>Close</Button>
                    <Button variant="info" onClick={this.onSubmitTaxreturns} disabled={this.state.gridColumnDisable}
                        data-test-auto="5D0B9B03-68DE-47E7-8681-6EDBD54E005B">
                        <i className='fas fa-save'></i>Submit</Button>
                </Modal.Footer>
            </Modal>

            {this.state.showSetAccess &&
                (
                    <SetAccessCommonModal
                        onCancel={this.onSetAccessHide}
                        onApplyAccess={this.onSetAccessSave}
                        availableUsers={this.state.availableUsers}
                        selectedUsers={this.state.selectedUsers}
                        availableUserGroups={this.state.availableUserGroups}
                        selectedUserGroups={this.state.selectedUserGroups}
                        returnAccessSettings={this.props.returnAccessSettings}
                        isDragAndDropDisabled={true}
                        hide={this.onSetAccessHide}
                    />
                )}
        </div>
    }
}
