import { formatEIN, formatSSN } from "../../../components/helper/HelperFunctions";
import { ITaxpayer } from "../TaxClient";
import {
    CMClientType,
    IClientInfoViewModel,
    ICorporateTaxReturn,
    IIndividualTaxReturn,
    IMarriedJointTaxReturn,
    ITaxReturn,
    initialClientInfoViewModel,
    isIndividual,
    isMutual,
    isPartnership
} from "../TaxReturn";

export enum IClientInfoMismatchType {
    NoDataMismatch = 0,
    DataMismatch = 1,
    SSNMismatch = 2,
    ClientIdMismatch = 3,
    NewClient = 4
}

export const mismatchModalTitle = {
    reviewAndConfirm: "Review and Confirm Client Information",
    newClient: "Add New Client Information"
};

export interface IComparisonMismatchViewModel {
    hadDifference: boolean;
    taxReturnValue?: string;
    clientManagementValue?: string;
}

export interface IClientInfoMismatchViewModel {
    clientId: IComparisonMismatchViewModel;
    name: IComparisonMismatchViewModel;
    uid?: IComparisonMismatchViewModel;
    emailAddress?: IComparisonMismatchViewModel;
    countryCode?: IComparisonMismatchViewModel;
    mobileNumber?: IComparisonMismatchViewModel;
    ero?: IComparisonMismatchViewModel;
    isDeceased?: IComparisonMismatchViewModel;
    dob?: IComparisonMismatchViewModel;
    spouseSSN?: IComparisonMismatchViewModel;
    spouseName?: IComparisonMismatchViewModel;
    spouseEmailAddress?: IComparisonMismatchViewModel;
    spouseCountryCode?: IComparisonMismatchViewModel;
    spouseMobileNumber?: IComparisonMismatchViewModel;
    spouseDOB?: IComparisonMismatchViewModel;
    spouseIsDeceased?: IComparisonMismatchViewModel;
    locationName?:IComparisonMismatchViewModel;
}

export interface IClientInfoComparisonResult {
    comparisonStatus: IClientInfoMismatchType;
    clientInfoResultData: IClientInfoMismatchViewModel;
}

export const initialClientInfoComparisonResult: IClientInfoComparisonResult = {
    comparisonStatus: 0,
    clientInfoResultData: {
        clientId: {
            hadDifference: false
        },
        name: {
            hadDifference: false
        }
    }
};

export const columnNames = {
    clientInformation: "Client Information",
    taxReturn: "Tax Extension",
    clientManagement: "Client Management"
};

export const clientRows = {
    clientId: "Client ID",
    name: "Name",
    locationName:"Office",
    emailAddress: "Email Address",
    dob: "DOB",
    countryCode: "Country Code",
    mobileNumber: "Mobile Number",
    isDeceased: "Deceased",
    uid: "SSN",
    ero: "ERO",
    spouseName: "Spouse Name",
    spouseEmailAddress: "Spouse Email Address",
    spouseSSN: "Spouse SSN",
    spouseDOB: "Spouse DOB",
    spouseCountryCode: "Spouse Country Code",
    spouseMobileNumber: "Spouse Mobile Number",
    spouseIsDeceased: "Spouse Deceased"
};

export const getNewClientInfo = (taxReturn: ITaxReturn, clientBasicInfoId: number, clientInfoFromCM: IClientInfoViewModel) => {
    let clientInfo: IClientInfoViewModel;
    let isPartnerShipReturn = isPartnership(taxReturn);
    let isMutualReturn = isMutual(taxReturn);
    let isIndividualReturn = isIndividual(taxReturn);

    if(!isPartnerShipReturn && !isMutualReturn && !isIndividualReturn){
        clientInfo = initialClientInfoViewModel;
        return clientInfo;
    }
    const taxPayer = isPartnerShipReturn ? (taxReturn as ICorporateTaxReturn).partnership : isMutualReturn || isIndividualReturn ? (taxReturn as IIndividualTaxReturn).taxpayer : null;
    const spouse = isMutualReturn ? (taxReturn as IMarriedJointTaxReturn).spouse : null;

    clientInfo = {
        clientBasicInfoId: clientBasicInfoId ?? 0,
        clientId: taxReturn.clientId,
        name: taxPayer.name,
        emailAddress: taxPayer.email,
        countryCode: taxPayer.countryCode,
        mobileNumber: taxPayer.mobileNumber,
        type: isPartnerShipReturn ? CMClientType.EIN : isMutualReturn || isIndividualReturn ? CMClientType.SSN : initialClientInfoViewModel.type,
        uid: isPartnerShipReturn ?  formatEIN(taxPayer.ssn) : formatSSN(taxPayer.ssn),
        ero: `${taxReturn.partnerId}`,
        dob: isMutualReturn || isIndividualReturn ? (taxPayer as ITaxpayer).dob : null,
        isDeceased: isMutualReturn || isIndividualReturn ? (taxPayer as ITaxpayer).isDeceased : false,
        spouseName: spouse?.name,
        spouseSsn: spouse ? spouse.ssn : "",
        spouseEmailAddress: spouse ? spouse.email : "",
        spouseDob: spouse?.dob,
        spouseCountryCode: spouse ? spouse.countryCode : "",
        spouseMobileNumber: spouse ? spouse.mobileNumber : "",
        spouseIsDeceased: spouse ? spouse.isDeceased : false,
        mfj: isMutualReturn ? true : false,
        defaultFirstSigner: isMutualReturn ? clientInfoFromCM.defaultFirstSigner : false,
        totalCount: 0,
        eroFirstName: "",
        eroLastName: "",
        locationName:taxReturn.location.locationName?taxReturn.location.locationName:"",
        locationId:taxReturn.location.locationId
    };
    return clientInfo;
};

export const setNewClientInfoComparisonResult = (clientInfo: IClientInfoViewModel) => {
    const data: IClientInfoComparisonResult = {
        comparisonStatus: IClientInfoMismatchType.NewClient,
        clientInfoResultData: {
            clientId: {
                hadDifference: !!clientInfo.clientId,
                taxReturnValue: clientInfo.clientId
            },
            name: {
                hadDifference: !!clientInfo.name,
                taxReturnValue: clientInfo.name
            },
            uid: {
                hadDifference: !!clientInfo.uid,
                taxReturnValue: clientInfo.uid
            },
            emailAddress: {
                hadDifference: !!clientInfo.emailAddress,
                taxReturnValue: clientInfo.emailAddress
            },
            countryCode: {
                hadDifference: !!clientInfo.countryCode,
                taxReturnValue: clientInfo.countryCode
            },
            mobileNumber: {
                hadDifference: !!clientInfo.mobileNumber,
                taxReturnValue: clientInfo.mobileNumber
            },
            ero: {
                hadDifference: !!clientInfo.ero,
                taxReturnValue: clientInfo.ero
            },
            isDeceased: {
                hadDifference: !!clientInfo.isDeceased,
                taxReturnValue: `${clientInfo.isDeceased}`
            },
            dob: {
                hadDifference: !!clientInfo.dob,
                taxReturnValue: `${clientInfo.dob}`
            },
            spouseSSN: {
                hadDifference: !!clientInfo.spouseSsn,
                taxReturnValue: clientInfo.spouseSsn
            },
            spouseName: {
                hadDifference: !!clientInfo.spouseName ,
                taxReturnValue: clientInfo.spouseName
            },
            spouseEmailAddress: {
                hadDifference: !!clientInfo.spouseEmailAddress,
                taxReturnValue: clientInfo.spouseEmailAddress
            },
            spouseCountryCode: {
                hadDifference: !!clientInfo.spouseCountryCode ,
                taxReturnValue: clientInfo.spouseCountryCode
            },
            spouseMobileNumber: {
                hadDifference: !!clientInfo.spouseMobileNumber,
                taxReturnValue: clientInfo.spouseMobileNumber
            },
            spouseDOB: {
                hadDifference: !!clientInfo.spouseDob,
                taxReturnValue: `${clientInfo.spouseDob}`
            },
            spouseIsDeceased: {
                hadDifference: !!clientInfo.spouseIsDeceased,
                taxReturnValue: `${clientInfo.spouseIsDeceased}`
            },
            locationName: {
                hadDifference:!!clientInfo.locationName,
                taxReturnValue:`${clientInfo.locationName}`
            },
        }
    };
    return data;
};

export const defaultOrder = [
    "clientId",
    "name",
    "locationName",
    "emailAddress",
    "countryCode",
    "mobileNumber",
    "dob",
    "uid",
    "ero",
    "isDeceased",
    "spouseName",
    "spouseEmailAddress",
    "spouseCountryCode",
    "spouseMobileNumber",
    "spouseDOB",
    "spouseSSN",
    "spouseIsDeceased"
];

export const customOrder = [
    "clientId",
    "name",
    "locationName",
    "uid",
    "emailAddress",
    "countryCode",
    "mobileNumber",
    "dob",
    "ero",
    "isDeceased",
    "spouseName",
    "spouseEmailAddress",
    "spouseCountryCode",
    "spouseMobileNumber",
    "spouseDOB",
    "spouseSSN",
    "spouseIsDeceased"
];

export const sortRows = (rows: string[], mismatchType: IClientInfoMismatchType) => {
    const { ClientIdMismatch, SSNMismatch } = IClientInfoMismatchType;
    const order = [ClientIdMismatch, SSNMismatch].includes(mismatchType) ? customOrder : defaultOrder;
    const orderedArr = order.filter((x) => rows.includes(x));
    return orderedArr;
};
