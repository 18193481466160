import React from  'react';
import {
    FormGroup, FormLabel, FormControl,
    Col,/*, Panel*/
    Accordion,
    Card
} from 'react-bootstrap';
import { ClientInfoProperty } from '../../ClientInfoEnum';
import { ITaxClient } from '../../TaxClient';
import { ClientInfoRightSidePanelType } from '../Tabs/TabClientInfo';
import { FilterSSN, GetCountryCode } from '../../../helper/HelperFunctions';
import { TaxIdentificationNumber } from '../../../common/controls/TaxIdentificationNumber';
import Select from 'react-select';
import { PhoneNumberComponent } from '../../../common/PhoneNumberComponent';
import { CompanySettingsConstants } from '../../../helper/Constants';
import { isEqual } from 'lodash';
import { IPrefilledClientInfo } from '../../TaxReturn';

export interface IPartnershipProps {
    clientId: string,
    model: ITaxClient,
    handleValueChange(item: ClientInfoProperty, value: string): void,
    isAssignedToLoggedinUser?: boolean,
    expandPartnershipPanel: boolean,
    onPanelToggle: (panelType: ClientInfoRightSidePanelType) => void,
    prefilledClientInfo?: IPrefilledClientInfo;
    resetPrefillInfo?: (clientInfoProperty: ClientInfoProperty) => void;
    getPrefilledClassName: (clientInfoProperty: ClientInfoProperty) => string;
}

export interface IPartnershipState {
    id: number;
    name: string;
    email: string;
    eIN: string;
    mobileNumber: string;
    countryCode: string;
}

export class PartnershipSidebar extends React.Component<IPartnershipProps, IPartnershipState> {
    constructor(props: IPartnershipProps) {
        super(props);
        this.state = {
            name: props.model.name !== undefined ? props.model.name : "",
            id: props.model.id !== undefined ? props.model.id : 0,
            email: props.model.email !== undefined ? props.model.email : "",
            eIN: props.model.ssn !== undefined ? props.model.ssn : "",
            mobileNumber: props.model.mobileNumber !== undefined ? props.model.mobileNumber : "",
            countryCode: props.model.countryCode !== undefined ? props.model.countryCode : "+1",
        };
        this.handleChangeId = this.handleChangeId.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleBlurName = this.handleBlurName.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangeEIN = this.handleChangeEIN.bind(this);
        this.handleBlurEmail = this.handleBlurEmail.bind(this);
        this.handleChangeMobileNumber = this.handleChangeMobileNumber.bind(this);
    }

    componentWillReceiveProps(nextProps: IPartnershipProps) {
        if (nextProps.model && isEqual(this.props.prefilledClientInfo, nextProps.prefilledClientInfo)) {
            this.state = {
                name: nextProps.model.name,
                id: nextProps.model.id,
                email: nextProps.model.email,
                eIN: nextProps.model.ssn,
                mobileNumber: nextProps.model.mobileNumber,
                countryCode: nextProps.model.countryCode,
            };
        }
    }

    handleChangeId(event: any) {
        this.props.handleValueChange(ClientInfoProperty.clientId, event.target.value);
    }

    handleChangeName(event: any) {
        this.setState({ name: event.target.value });
        this.handleResetPrefillInfo(ClientInfoProperty.partnerName);
    }
    handleChangeEmail(event: any) {
        this.setState({ email: event.target.value });
        this.handleResetPrefillInfo(ClientInfoProperty.partnerEmail);
    }
    handleChangeEIN(value: string) {
        this.setState({ eIN: value });
        this.handleResetPrefillInfo(ClientInfoProperty.partnerEin);
    }

    handleChangeMobileNumber = (value: any) => {
        this.setState({ mobileNumber: value });
        this.handleResetPrefillInfo(ClientInfoProperty.partnerMobileNumber);
    }

    onChangeCountryCode = (event: any) => {
        let value = event ? event.value : "";
        this.setState({ countryCode: value });
        this.handleResetPrefillInfo(ClientInfoProperty.partnerCountryCode);
    }

    handleBlurName(event: any) {
        this.props.handleValueChange(ClientInfoProperty.partnerName, event.target.value);
    }
    
    handleBlurEIN = (value: string) => this.props.handleValueChange(ClientInfoProperty.partnerEin, value);
    
    handleBlurEmail(event: any) {
        this.props.handleValueChange(ClientInfoProperty.partnerEmail, event.target.value);
    }
    handleBlurMobileNumber = (event: any) => {
        this.props.handleValueChange(ClientInfoProperty.partnerMobileNumber, this.state.mobileNumber);
    }

    handleBlurCountryCode = (event: any) => {
        this.props.handleValueChange(ClientInfoProperty.partnerCountryCode, this.state.countryCode);
    }

    handleResetPrefillInfo = (item: ClientInfoProperty) => {
        this.props.resetPrefillInfo && this.props.resetPrefillInfo(item);
    }

    public render() {
        let icon = this.props.expandPartnershipPanel ? "glyphicon glyphicon-minus" : "glyphicon glyphicon-plus";
        return <div>

            <Accordion defaultActiveKey="0" style={{ marginRight: "1px", marginTop: "10px" }}>
                <Card>
                    <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
                        <Card.Title as="h4"
                            onClick={() => {
                                this.props.onPanelToggle(ClientInfoRightSidePanelType.PartnershipInformationPanel)
                            }}>
                            <i className={icon}></i> Company
                        </Card.Title>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body style={{ padding: "10px" }}>
                            <FormGroup className="row">
                                <label className="text-left padT08 col-sm-4" >Name</label>
                                <Col sm={8}>
                                    <FormControl
                                        type="text"
                                        className={this.props.getPrefilledClassName(ClientInfoProperty.partnerName)}
                                        value={this.state.name}
                                        onBlur={this.handleBlurName}
                                        onChange={this.handleChangeName}
                                        placeholder="Company Name"
                                        data-test-auto="9F18CE2E-7161-4242-B7FB-261F6FA3C5B5"
                                        disabled={!this.props.isAssignedToLoggedinUser}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <label className="text-left padT08 col-sm-4">
                                    EIN
                                </label>
                                <Col sm={8}>
                                    <TaxIdentificationNumber
                                        onBlur={this.handleBlurEIN}
                                        onChange={this.handleChangeEIN}
                                        className={this.props.getPrefilledClassName(ClientInfoProperty.partnerEin)}
                                        value={FilterSSN(this.state.eIN)}
                                        placeholder="Company EIN"
                                        mask='00-0000000'
                                        disabled={!this.props.isAssignedToLoggedinUser}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <label className="text-left col-sm-4" style={{ paddingTop: "8px", paddingLeft: "15px", paddingBottom: "0px", paddingRight: "0px" }}>
                                    <Col sm={12} className="padL00 padR00">
                                        Mobile
                                        <i
                                            className="help-icon fas fa-question-circle padL15 padR00 fontS15"
                                            data-toggle="tooltip"
                                            title={CompanySettingsConstants.MFASettings.HelpText}>
                                        </i>
                                    </Col>
                                </label>
                                <Col sm={8}>
                                    <Col sm={6} className="padL00 padR00" style={{ float: "left"}}>
                                        <Select
                                            name="form-field-country-code"
                                            value={this.state.countryCode}
                                            className={this.props.getPrefilledClassName(ClientInfoProperty.partnerCountryCode)}
                                            onChange={this.onChangeCountryCode}
                                            onBlur={this.handleBlurCountryCode}
                                            options={GetCountryCode()}
                                            data-test-auto="472A233B-881E-4538-B1AE-357FE297CD79"
                                            disabled={!this.props.isAssignedToLoggedinUser}
                                            clearable={true}
                                            searchable={true}
                                        >
                                        </Select>
                                    </Col>
                                    <Col sm={6} className="pad00 padL00 padR00" style={{ float: "right" }}>
                                        <PhoneNumberComponent
                                            phoneNumber={this.state.mobileNumber ? this.state.mobileNumber : ""}
                                            handleChangePhoneNumber={this.handleChangeMobileNumber}
                                            className={this.props.getPrefilledClassName(ClientInfoProperty.partnerMobileNumber)}
                                            onBlur={this.handleBlurMobileNumber}
                                            disabled={!this.props.isAssignedToLoggedinUser}
                                        >
                                        </PhoneNumberComponent>


                                    </Col>
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <label className="text-left padT08 col-sm-4" >
                                    Email
                                </label>
                                <Col sm={8}>
                                    <FormControl
                                        type="email"
                                        value={this.state.email}
                                        className={this.props.getPrefilledClassName(ClientInfoProperty.partnerEmail)}
                                        onChange={this.handleChangeEmail}
                                        onBlur={this.handleBlurEmail}
                                        placeholder="Company Email"
                                        data-test-auto="42FD5F59-7326-457F-B929-85246CAB6B56"
                                        disabled={!this.props.isAssignedToLoggedinUser}
                                    />
                                </Col>
                            </FormGroup>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>;
    }
}
export default PartnershipSidebar;