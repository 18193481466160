import React from 'react';
import * as Bootstrap from 'react-bootstrap';
import { UploadDocumentModal } from '../../common/UploadDocumentModal';
import { Link } from 'react-router-dom';
import { ReportChangeStatusModal } from '../../common/ReportsChangeStatusModal';
import { IDocumentAccess, SignatureStatus } from '../../common/TaxReturn';
import { IDropzoneConfigState } from '../../common/UploadDocument/DropzoneConfigModel';
import { IUserModel } from 'src/Core/ViewModels/User/UserViewModel';
import { ICompanySettings } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import * as CompanyStore from '../../../store/company/CompanyStore';
import Select from 'react-select';
import { PrepareTaxYear } from '../../helper/HelperFunctions';
import * as UploadTaxReturnStore from '../../../store/common/uploadTaxReturn';
import { UploadFabButton } from '../../../components/common/UploadFabButton';
import { IUserDefaultSettingsStates } from '../../navigation/profile/MySettings';
import { GroupAccess, GroupInfo } from '../../../Core/ViewModels/GroupExtensions/ComponentModels';
import { RBACKeys } from 'src/components/helper/RbacConstants';
import {
    ITaxSoftwareSettings,
    TaxSoftwareType,
} from 'src/Core/ViewModels/Company/SuiteCompanySettings/SuiteTaxSoftwareComponentModel';

interface DeliveredReturnsHeaders {
    isArchived: boolean;
    pageId: string;
    pageTitle: string;
    onSetAccessOpen: (rowIndex: number) => void;
    onChangeStatusOpen: (rowIndex: number) => void;
    onCustomColumnOpen: (rowIndex: number) => void;
    onSendReminder: () => void;
    onArchiveReturnsOpen: (rowIndex: number) => void;
    onMultipleDownloadEfileForms: () => void;
    selectedDocumentCount: number;
    customColumnTitle: string;
    extensionUsers: IUserModel[];
    partners: IUserModel[];
    onPageReload: () => void;
    company: CompanyStore.ICompanyData;
    selectedERO: number;
    uploadTaxReturn: UploadTaxReturnStore.IUploadTaxReturnState;
    processTaxReturn: (url: string, callback: () => void) => void;
    getUploadLink: (url: string, callback: () => void) => void;
    submitTaxReturn: (url: string, taxData: string, callback: () => void) => void;
    onRestoreReturnOpen: (rowIndex: number) => void;
    isBulkSelectionEnabled: boolean;
    onDeleteTaxReturnOpen: (rowIndex: number) => void;
    userDefaultSettings: IUserDefaultSettingsStates;
    companySettings: ICompanySettings;
    updateGroupInfoState: (reload: boolean) => void;
    groupInfo: GroupInfo[];
    groupAccess: GroupAccess[];
    defaultGroup: string;
    loadExcelSpinner: boolean;
    taxSoftwareData: ITaxSoftwareSettings;
    getTaxDocumentsAccessMaps: (ids: number[], callback?: (result: IDocumentAccess) => void) => void;
}
interface DeliveredReturnsHeadersState {
    show: boolean;
    selectedTaxSoftware: TaxSoftwareType;
}
const NO_INDEX = -1;
export class DeliveredReturnsHeader extends React.Component<DeliveredReturnsHeaders, DeliveredReturnsHeadersState> {
    constructor(props: DeliveredReturnsHeaders) {
        super(props);
        this.onUploadReturnsOpen = this.onUploadReturnsOpen.bind(this);
        this.HandleClose = this.HandleClose.bind(this);
        this.state = {
            show: false,
            selectedTaxSoftware: TaxSoftwareType.None,
        };
    }
    public render() {
        const btnStyle = {
            minWidth: '104px',
            marginRight: '5px',
        };

        const isDisabledBulk = this.props.isBulkSelectionEnabled ? false : this.props.selectedDocumentCount === 0;
        return (
            <div>
                <div className="row" style={{ marginBottom: 5 }}>
                    <div className="col-md-8">
                        <h3 className="page-title">{this.props.pageTitle}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-9 header-action-buttons">
                        <Bootstrap.Button
                            style={btnStyle}
                            variant="default"
                            className="btn btn-white"
                            data-test-auto="FF737AE4-41B0-4051-AEDC-9845EFD31E06"
                            data-resource-id={RBACKeys[this.props.pageId].setAccessButton}
                            disabled={this.props.selectedDocumentCount === 0 || this.props.isBulkSelectionEnabled}
                            onClick={(e: any) => this.props.onSetAccessOpen(NO_INDEX)}
                        >
                            <i className="fas fa-key"></i>Set Access
                        </Bootstrap.Button>
                        <Bootstrap.Button
                            style={btnStyle}
                            variant="default"
                            className="btn btn-white"
                            data-test-auto="32E4B7C3-3C20-4BC0-9DB8-270A6C877DD7"
                            data-resource-id={RBACKeys[this.props.pageId].changeStatus}
                            disabled={this.props.selectedDocumentCount === 0 || this.props.isBulkSelectionEnabled}
                            onClick={() => this.props.onChangeStatusOpen(NO_INDEX)}
                        >
                            <i className="fas fa-random"></i> Change Status
                        </Bootstrap.Button>
                        {this.props.customColumnTitle ? (
                            <Bootstrap.Button
                                style={btnStyle}
                                variant="default"
                                className="btn btn-white"
                                data-test-auto="C39CFC1B-36FE-4287-B529-8F88E341495F"
                                data-resource-id={RBACKeys[this.props.pageId].setCustomColumn}
                                disabled={
                                    this.props.isBulkSelectionEnabled ? false : this.props.selectedDocumentCount === 0
                                }
                                onClick={() => this.props.onCustomColumnOpen(NO_INDEX)}
                            >
                                <i className="fas fa-tags"></i>
                                {'Change ' + this.props.customColumnTitle}
                            </Bootstrap.Button>
                        ) : (
                            ''
                        )}
                        <Bootstrap.Button
                            style={btnStyle}
                            variant="default"
                            className="btn btn-white"
                            data-test-auto="04CE0584-4E83-4706-AF86-2D90BC9599AB"
                            data-resource-id={RBACKeys[this.props.pageId].sendReminder}
                            disabled={
                                this.props.isBulkSelectionEnabled ? false : this.props.selectedDocumentCount === 0
                            }
                            onClick={this.props.onSendReminder}
                        >
                            <i className="fas fa-paper-plane"></i>Send Reminder
                        </Bootstrap.Button>
                        {this.props.isArchived ? (
                            <Bootstrap.Button
                                style={btnStyle}
                                variant="default"
                                className="btn btn-white"
                                data-test-auto="60BA7F81-48F2-40A2-B704-1930B193090A"
                                disabled={this.props.selectedDocumentCount === 0}
                                data-resource-id={RBACKeys.ArchivedExtension.restoreReturn}
                                onClick={(e: any) => this.props.onRestoreReturnOpen(NO_INDEX)}
                            >
                                <i className="fas fa-sync-alt"></i>Restore
                            </Bootstrap.Button>
                        ) : (
                            <Bootstrap.Button
                                style={btnStyle}
                                variant="default"
                                className="btn btn-white"
                                data-test-auto="60BA7F81-48F2-40A2-B704-1930B193090A"
                                data-resource-id={RBACKeys.ExtensionReport.archiveButton}
                                disabled={
                                    this.props.isBulkSelectionEnabled ? false : this.props.selectedDocumentCount === 0
                                }
                                onClick={(e: any) => this.props.onArchiveReturnsOpen(NO_INDEX)}
                            >
                                <i className="fas fa-archive"></i>Archive
                            </Bootstrap.Button>
                        )}
                        <Bootstrap.Button
                            variant="default"
                            className="btn btn-white"
                            style={btnStyle}
                            data-test-auto="03F15B8A-E226-4A43-BC66-6B2FF78F211E"
                            data-resource-id={RBACKeys[this.props.pageId].downloadEFileFormsHeader}
                            disabled={this.props.loadExcelSpinner ? true : isDisabledBulk}
                            onClick={this.props.onMultipleDownloadEfileForms}
                        >
                            <i className="fas fa-file-signature"></i>Download e-file Forms
                        </Bootstrap.Button>
                        <Bootstrap.Button
                            style={btnStyle}
                            variant="default"
                            className="btn btn-white"
                            data-test-auto="4880EC1B-ABC7-4540-9F6B-8BCAEB65D004"
                            data-resource-id={RBACKeys[this.props.pageId].deleteButton}
                            disabled={
                                this.props.isBulkSelectionEnabled ? false : this.props.selectedDocumentCount === 0
                            }
                            onClick={(e: any) => this.props.onDeleteTaxReturnOpen(NO_INDEX)}
                        >
                            <i className="fas fa-trash-alt"></i>Delete
                        </Bootstrap.Button>
                    </div>
                    <div className="col-md-3 txt-ar">
                        <UploadFabButton
                            companySettings={this.props.companySettings}
                            userDefaultSettings={this.props.userDefaultSettings}
                            onUploadReturnsClick={this.onUploadReturnsOpen}
                            pageId={this.props.pageId}
                            taxSoftwareData={this.props.taxSoftwareData}
                        />
                    </div>
                </div>
                <UploadDocumentModal
                    onShow={this.state.show}
                    onHide={this.HandleClose}
                    extensionUsers={this.props.extensionUsers}
                    partners={this.props.partners}
                    taxSoftware={this.state.selectedTaxSoftware}
                    selectedERO={this.props.selectedERO}
                    getUploadLink={this.props.getUploadLink}
                    processTaxReturn={this.props.processTaxReturn}
                    submitTaxReturn={this.props.submitTaxReturn}
                    onPageReload={this.props.onPageReload}
                    defaultGroup={this.props.defaultGroup}
                    groupInfo={this.props.groupInfo}
                    groupAccess={this.props.groupAccess}
                    updateGroupInfoState={this.props.updateGroupInfoState}
                    returnAccessSettings={this.props.company.companySettings.returnAccessSettings}
                    getTaxDocumentsAccessMaps={this.props.getTaxDocumentsAccessMaps}
                />
            </div>
        );
    }

    private onUploadReturnsOpen(taxSoftware: TaxSoftwareType) {
        this.setState({ show: true, selectedTaxSoftware: taxSoftware });
    }

    private HandleClose() {
        this.setState({ show: false });
    }
}
