import 'isomorphic-fetch';
import React from 'react';
import moment from 'moment';
import { ButtonToolbar, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { DocumentStatus, ITaxReturn } from '../common/TaxReturn';
import * as Helper from '../helper/HelperFunctions';
import { RBACKeys, RBACPermission } from '../helper/RbacConstants';
import { validator } from 'src/store/common/RBACStore';

export interface dropdownItem {
    title: any;
    taxReturn: ITaxReturn;
    rowIndex: number;
    onProcessReturnOpen: (rowIndex: number) => void;
    onEditClientInfoOpen: (rowIndex: number) => void;
    onSetAccessOpen: (rowIndex: number) => void;
    onChangeStatusOpen: (rowIndex: number) => void;
    onReportProblemOpen: (id: number) => void;
    onDeleteTaxReturnOpen: (rowIndex: number) => void;
    onRecallReturnOpen: (rowIndex: number) => void;
    onClientTrackingOpen: (rowIndex: number) => void;
    onReprocessReturnOpen: (rowIndex: number) => void;
}

export class AssignmentAction extends React.Component<dropdownItem, {}> {

    constructor(props: dropdownItem) {
        super(props);
    }
    private createDeleteAction = () => {
        const elements = [];
        elements.push(<button data-testid="error-delete-return" title={"Delete Return"} type="button" className="btn-white btn-xs"
            data-resource-id={RBACKeys.extensionInProgress.deleteReturnButton}
            onClick={(e: any) => this.props.onDeleteTaxReturnOpen(this.props.rowIndex)}>
            <i className="text-danger fas fa-times" />
        </button>);
        return elements;
    }


    private createPrimaryActions = () => {

        const canProcess = validator && validator.someResourcesExists([
            RBACKeys.extensionInProgress.processReturnButton, 
            RBACKeys.extensionInProgress.deliveryButton, 
            RBACKeys.extensionInProgress.internalRouting]);

        const reprocessingSpanStyle = { verticalAlign: "initial" };
        const reprocessingCircleStyle = {
            fontSize: "2.5em",
            padding: "0px",
        };
        const reprocessingRedoStyle = {
            fontSize: "1.5em",
            padding: "0px",
        };

        const elements = [];
        if (this.props.taxReturn.documentStatus == DocumentStatus.REPROCESSING) {
            elements.push(<div key="ReprocessingActions">
                <button title={"Reprocess Return"} type="button" className="btn-white btn-xs">
                    <span data-testid="reprocess-return-spinner" className="fa-stack fas fa-spinner fa-spin text-white bg-transparent"
                        style={reprocessingSpanStyle}
                    >
                        <i className="fas fa-circle fa-stack-2x text-violet"
                            style={reprocessingCircleStyle}></i>
                        <i className="fas fa-redo-alt fa-inverse fa-stack-1x"
                            style={reprocessingRedoStyle} data-fa-transform="shrink-4"></i>
                    </span>
                </button></div>);
        } else if (
            this.props.taxReturn.documentStatus === DocumentStatus.RECALLED ||
            this.props.taxReturn.documentStatus === DocumentStatus.TOBEREPROCESSED
        ) {

            elements.push(<div key="RecalledActions">
                <button data-testid="reprocess-return" title={"Reprocess Return"} type="button" className="btn-white btn-xs"
                    data-resource-id={RBACKeys.extensionInProgress.reprocessButton}
                    onClick={(e: any) => this.props.onReprocessReturnOpen(this.props.rowIndex)}>
                    <span className="fa-stack" style={reprocessingSpanStyle}>
                        <i className="fas fa-circle fa-stack-2x text-violet"
                            style={reprocessingCircleStyle}></i>
                        <i className="fas fa-redo-alt fa-inverse fa-stack-1x"
                            style={reprocessingRedoStyle}
                            data-fa-transform="shrink-4"></i>
                    </span>
                </button>

                <button data-testid="reprocess-recall-return" title={"Upload Corrected Return"} type="button" className="btn-white btn-xs"
                    data-resource-id={RBACKeys.extensionInProgress.reprocessButton}
                    onClick={(e: any) => this.props.onRecallReturnOpen(this.props.rowIndex)}>
                    <i className='fas fa-sync-alt text-grey'></i>
                </button>

                <button data-testid="reprocess-client-tracking" title={"Client Tracking"} type="button" className="btn-white btn-xs"
                data-resource-id={RBACKeys.extensionInProgress.reprocessButton}
                    onClick={(e: any) => this.props.onClientTrackingOpen(this.props.rowIndex)}>
                    <i className='fas fa-history text-warning'></i>
                </button>
                <button type="button" className="btn-white btn-xs"
                    data-resource-id={RBACKeys.extensionInProgress.deleteReturnButton}
                    onClick={(e: any) => this.props.onDeleteTaxReturnOpen(this.props.rowIndex)}>
                    <i className="text-danger fas fa-times" />
                </button></div>);
        } else {
            elements.push(<div key="AssignmentsAction">
                <button data-testid="process-return" title={canProcess ? "Process Return" : RBACPermission.operation} type="button"
                    className={`${!canProcess ? 'disabled-rbac': '' } btn-white btn-xs`}
                    onClick={(e: any) => canProcess && this.props.onProcessReturnOpen(this.props.rowIndex)}>
                    <i className='fa fa-rocket text-violet'></i>
                </button>
                <button data-testid="client-info" title={"Edit Client Info"} type="button" className="btn-white btn-xs"
                    data-resource-id={RBACKeys.extensionInProgress.editButton}
                    onClick={(e: any) => this.props.onEditClientInfoOpen(this.props.rowIndex)}>
                    <i className='fas fa-address-card text-success'></i>
                </button>
                <button data-testid="delete-return" title={"Delete Return"} type="button" className="btn-white btn-xs"
                    data-resource-id={RBACKeys.extensionInProgress.deleteReturnButton}
                    onClick={(e: any) => this.props.onDeleteTaxReturnOpen(this.props.rowIndex)}>
                    <i className="text-danger fas fa-times" />
                </button>
            </div>);
        }

        return elements;
    }

    private isOneDayOldAndDeletable = (): boolean => {
        const deletableDocumentStatus = [
            DocumentStatus.PREPARINGFORDELIVERY,
            DocumentStatus.PREPARINGFORDELIVERYDESCRIPTION,
            DocumentStatus.UPLOADED,
            DocumentStatus.DELIVERYFAILED,
            DocumentStatus.DELIVERYFAILEDDESCRIPTION,
            DocumentStatus.PREPARINGFORPDFDOWNLOAD,
            DocumentStatus.PREPARINGFORPDFDOWNLOADDESCRIPTION,
            DocumentStatus.DOWNLOADPDF,
        ];
        const hours = moment
            .duration(moment(new Date(), 'YYYY/MM/DD HH:mm')
                .diff(moment(this.props.taxReturn.uploadedOn, 'YYYY/MM/DD HH:mm')),
            ).asHours();
        return (deletableDocumentStatus.indexOf(this.props.taxReturn.documentStatus) > -1 && hours > 24);
    }


    public render() {
        let disabled = Helper.isLocked(this.props.taxReturn);
        const { taxReturn: { documentStatus } } = this.props;
        return (<div className="button-group" data-testid="assignment-action">
                {((documentStatus === DocumentStatus.NONSUPPORTED ||
                    documentStatus === DocumentStatus.ERROR ||
                    documentStatus === DocumentStatus.NONSUPPORTEDDESCRIPTION) || this.isOneDayOldAndDeletable()) ?
                    this.createDeleteAction() :
                    documentStatus === DocumentStatus.REPROCESSING ?
                        this.createPrimaryActions()
                        : (!disabled && this.createPrimaryActions())}
            </div>);
    }
}
export default AssignmentAction;
