import React from 'react';
import { RouteComponentProps } from 'react-router';
import Select from 'react-select';
import { /*Panel*/ FormGroup, Col, FormControl, FormLabel, Card, Row } from 'react-bootstrap';
import { ClientInfoProperty } from './ClientInfoEnum';
import { ITaxClient } from './TaxClient';
import { FilterSSN, GetCountryCode } from '../helper/HelperFunctions';
import { TaxIdentificationNumber } from '../common/controls/TaxIdentificationNumber';
import { ITaxReturn, DocumentStatus } from './TaxReturn';
import { PhoneNumberComponent } from '../common/PhoneNumberComponent';
import { IDropdown } from 'src/Core/Common/Dropdown';
import DropdownComponent from './controls/DropdownComponent';

export interface IPartnershipProps {
    clientId: string,
    model: ITaxClient,
    taxReturn: ITaxReturn,
    handleValueChange(item: ClientInfoProperty, value: string): void,
    locationDropdown: IDropdown[],
    isDeliveredReturn: boolean;
}

export interface IPartnershipState {
    isDisabled: boolean;
    locationId: number,
}

export class Partnership extends React.Component<IPartnershipProps, IPartnershipState> {

    constructor(props: IPartnershipProps) {
        super(props);
        this.state = {
            isDisabled: false,
            locationId: 0
        }
    }


    componentWillReceiveProps(nextprops: IPartnershipProps) {
        if (nextprops.taxReturn != undefined) {

            let isDisabled: boolean = (nextprops.taxReturn.documentStatus === DocumentStatus.DELIVEREDTOTAXCADDY);
            this.setState({ isDisabled: isDisabled });
        }
    }

    onOfficeLocationChange = (event: any) => {
        this.props.handleValueChange(ClientInfoProperty.officeLocationId, event);
    };

    prepareLocation = () => {
        return this.props.locationDropdown?.map(item => {
            return {
                value: item.value.toString(),
                label: item.name
            }
        });
    }


    public render() {
        return <div className='col-lg-12' >

            <Card className="margin-bottom-20">
                <Card.Header>
                    <Card.Title as="h4"><b>Company</b></Card.Title>
                </Card.Header>
            </Card>

            <Row>
                <Col className="text-left padT07 tpLabelFont" sm={2} as={FormLabel}>
                    Client ID <span className="fas fa-asterisk compulsory-star"></span>
                </Col>
                <Col sm={10}>
                    <FormControl
                        data-testid="client-id"
                        type="text"
                        style={{ marginBottom: '10px' }}
                        disabled={this.state.isDisabled}
                        value={this.props.clientId}
                        onChange={(event: any) => { this.props.handleValueChange(ClientInfoProperty.clientId, event.target.value) }}
                        placeholder="Client ID"
                        data-test-auto="E21E3F14-D51E-4336-BAA5-A5D9E210172A"
                    />
                </Col>
            </Row>
            <Row>
                <Col className="text-left padT07 tpLabelFont" sm={2} as={FormLabel}>
                    Name<span className="fas fa-asterisk compulsory-star"></span>
                </Col>
                <Col sm={10}>
                    <FormControl
                        data-testid="company-name"
                        type="text"
                        style={{ marginBottom: '10px' }}
                        disabled={this.state.isDisabled}
                        value={this.props.model.name}
                        onChange={(event: any) => { this.props.handleValueChange(ClientInfoProperty.partnerName, event.target.value) }}
                        placeholder="Company Name"
                        data-test-auto="E47AB592-4B96-4161-8A03-440C85250742"
                    />
                </Col>
            </Row>
            <Row>
                <Col className="text-left padT07 tpLabelFont" sm={2} as={FormLabel}>
                    Email<span className={`fas ${(this.props.taxReturn.documentStatus == DocumentStatus.DOWNLOADPDF) ? "" : "fa-asterisk compulsory-star"}`}></span>
                </Col>
                <Col sm={10}>
                    <FormControl
                        data-testid="partnership-email"
                        type="email"
                        style={{ marginBottom: '10px' }}
                        disabled={this.state.isDisabled}
                        value={this.props.model.email}
                        onChange={(event: any) => { this.props.handleValueChange(ClientInfoProperty.partnerEmail, event.target.value) }}
                        placeholder="Company Email"
                        data-test-auto="A5D4525B-9982-445A-B9AC-D206FDD84947"
                    />
                </Col>
            </Row>
            {this.props.isDeliveredReturn && <Row>
                <Col className="text-left padT07" sm={2} as={FormLabel}>
                    Office <span className="fas fa-asterisk compulsory-star"></span>
                </Col>
                <Col sm={10}>
                    <DropdownComponent
                        id="form-field-edit-info-state-taxpayer"
                        className='font12'
                        selectedValue={this.props.taxReturn?.location.locationId}
                        onChange={(event: any) => {
                            this.onOfficeLocationChange(event)
                        }}
                        options={this.prepareLocation()}
                        data-test-auto="894C3CEB-4671-4529-A835-ECA0CCC9DD91"
                        clearable={false}
                    />
                </Col>
            </Row>}
            <Row>
                <Col className="text-left padT07 tpLabelFont" sm={2} as={FormLabel} >
                    Country Code
                </Col>
                <Col sm={4} className='padT07'>
                    <Select
                        name="form-field-edit-info-state-partnership"
                        value={this.props.model.countryCode}
                        onChange={(event: any) => { this.props.handleValueChange(ClientInfoProperty.partnerCountryCode, event ? event.value : "") }}
                        options={GetCountryCode()}
                        data-test-auto="1F29729F-075F-422F-92F9-EC51033A44F5"
                        style={{ marginBottom: '10px' }}
                        clearable={true}
                        disabled={this.state.isDisabled}
                        searchable={true}
                    >
                    </Select>

                </Col>
                <Col className="text-left padT07 tpLabelFont" sm={2} as={FormLabel} >
                    Mobile
                </Col>
                <Col sm={4} className='padT07'>
                    <PhoneNumberComponent
                        phoneNumber={this.props.model.mobileNumber ? this.props.model.mobileNumber : ""}
                        handleChangePhoneNumber={(value: string) => { this.props.handleValueChange(ClientInfoProperty.partnerMobileNumber, value) }}
                        disabled={this.state.isDisabled}
                    >

                    </PhoneNumberComponent>

                </Col>
            </Row>
            <Row className="justify-flex-end">
                <Col className="text-left tpLabelFont" sm={2} as={FormLabel}>
                    EIN
                </Col>
                <Col sm={4} className={"zeroborderRadius"}>
                    <TaxIdentificationNumber
                        value={FilterSSN(this.props.model.ssn)}
                        onChange={(value: string) => { this.props.handleValueChange(ClientInfoProperty.partnerEin, value) }}
                        placeholder="Company EIN"
                        mask='00-0000000'
                        disabled={this.state.isDisabled}
                    />
                </Col>
            </Row>
        </div>;
    }
}
export default Partnership;
