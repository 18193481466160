import { IDocumentAccessSaveModel } from "src/components/common/TaxReturn";
import { TaxSoftwareType } from "../Company/SuiteCompanySettings/SuiteTaxSoftwareComponentModel";
import { GroupExtenionsUploadData } from "../GroupExtensions/ComponentModels";

export interface SasContainer {
    sasGuid: string;
}

export enum UploadStatus {
    Wait = 0,
    Initiating = 1,
    Uploading = 2,
    Uploaded = 3,
    Error = 4,
    Converting = 5,
}

export interface TaxReturnUploadData extends GroupExtenionsUploadData {
    number?: number;
    clientName?: string;
    name?: string;
    progressBar?: number;
    size?: string;
    status?: UploadStatus;
    clientId?: string;
    partnerId?: number;
    setAccess?: string;
    uuid?: string;
    sasGuid?: string;
    gridRowDisable: boolean;
    documentAccess: IDocumentAccessSaveModel;
    rowIndex?: number;
    file?: any;
}

export interface MultiFileTaxReturnUploadData extends GroupExtenionsUploadData {
    clientId: string;
    sasGuid: string;
    setAccess: string;
    clientName: string;
    partnerId: number;
    documentAccess: IDocumentAccessSaveModel;
    taxSoftware: TaxSoftwareType;
    subDocumentsUploadData: subDocumentUploadData[];
}

export interface subDocumentUploadData {
    number?: number;
    name?: string;
    progressBar?: number;
    size?: string;
    subDocumentGuid: string;
    status: UploadStatus;
    file?: any;
}
