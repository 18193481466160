import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../ActionTypes';
import { IClientMessage, clientInstructions } from '../../Core/ViewModels/CompanySettings/ClientInstructionViewModel';
import { handleResponse } from '../Library';
import * as Constants from '../../../src/components/helper/Constants';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { ExtensionCompanySettings } from '../../Core/ViewModels/Extension/ExtensionComponentModels';
import * as ExtensionCompanySettingsStore from '../../store/settings/ExtensionCompanySettingsStore';
import { LoggerFactory } from '../../Logger/LoggerFactory';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';

export interface IClientMessageStoreState {
    data: IClientMessageDictionary;
    isLoading: boolean;
}

export interface IClientMessageDictionary {
    [index: number]: IClientMessageState
}

export interface IClientMessageState {
    clientMessage: IClientMessage;
}

interface RequestClientMessasgeListAction {
    type: actionTypes.REQUEST_CLIENT_MESSAGES;
}

interface ReceiveClientMessageListAction {
    type: actionTypes.RECEIVE_CLIENT_MESSAGES;
    messageDetails: IClientMessage;
    clientMessageId: number;
}

interface DeleteClientMessageDetail {
    type: actionTypes.DELETE_CLIENT_MESSAGE;
    messageDetail: IClientMessage;
}

type KnownAction =
    RequestClientMessasgeListAction |
    ReceiveClientMessageListAction |
    DeleteClientMessageDetail |
    NotificationAction

export const ActionCreators = {
    requestClientMessages: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/ClientMessage/GetClientMessage`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => response.json() as Promise<IClientMessage[]>)
            .then((data) => {
                data.map((value, index) => {
                    dispatch({
                        type: actionTypes.RECEIVE_CLIENT_MESSAGES, messageDetails: value,
                        clientMessageId: value.id
                    });
                });
            }).catch(function (error) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.ClientInstructionConstants.FetchMessageError, statusType: StatusType.Error
                });
                logger.trackError(`requestClientMessages failed with error ${error.message}`);
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_CLIENT_MESSAGES });
    },

    saveClientMessageDetail: (message: IClientMessage, companySettings: ExtensionCompanySettings, isSetDefault: boolean, typeId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/ClientMessage`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(message),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(handleResponse)
            .then((newId) => {
                let addedMessage: IClientMessage = Object.assign({}, message);
                addedMessage.id = newId;
                if (isSetDefault) {
                     if (typeId == clientInstructions.paymentInstruction)
                        companySettings.defaultSettings.paymentInstruction = newId

                    let action: any = ExtensionCompanySettingsStore.ActionCreators.updateExtensionCompanySettings(companySettings)
                    dispatch(action);
                    dispatch({
                        type: actionTypes.RECEIVE_CLIENT_MESSAGES, messageDetails: addedMessage,
                        clientMessageId: newId
                    });
                }
                else {
                    dispatch({
                        type: actionTypes.RECEIVE_CLIENT_MESSAGES, messageDetails: addedMessage,
                        clientMessageId: newId
                    });
                }

                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.ClientInstructionConstants.SaveMessageSuccess,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.ClientInstructionConstants.SaveMessageError, statusType: StatusType.Error
                });
                logger.trackError(`saveClientMessageDetail failed for message: ${message}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    saveGroupClientMessageDetail: (message: IClientMessage, companySettings: ExtensionCompanySettings, isSetDefault: boolean, typeId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/ClientMessage`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(message),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(handleResponse)
            .then((newId) => {
                let addedMessage: IClientMessage = Object.assign({}, message);
                addedMessage.id = newId;
                if (isSetDefault) {
                    if (typeId == clientInstructions.groupPaymentInstruction)
                        companySettings.defaultGroupSettings.groupPaymentInstruction = newId

                    let action: any = ExtensionCompanySettingsStore.ActionCreators.updateExtensionCompanySettings(companySettings)
                    dispatch(action);
                    dispatch({
                        type: actionTypes.RECEIVE_CLIENT_MESSAGES, messageDetails: addedMessage,
                        clientMessageId: newId
                    });
                }
                else {
                    dispatch({
                        type: actionTypes.RECEIVE_CLIENT_MESSAGES, messageDetails: addedMessage,
                        clientMessageId: newId
                    });
                }

                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.ClientInstructionConstants.SaveMessageSuccess,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.ClientInstructionConstants.SaveMessageError, statusType: StatusType.Error
                });
                logger.trackError(`saveGroupClientMessageDetail failed for message: ${message}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    updateClientMessageDetail: (message: IClientMessage): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/ClientMessage`, {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(message),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.RECEIVE_CLIENT_MESSAGES,
                    messageDetails: message, clientMessageId: message.id
                });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.ClientInstructionConstants.UpdateMessageSuccess,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.ClientInstructionConstants.UpdateMessageError, statusType: StatusType.Error
                });
                logger.trackError(`updateClientMessageDetail failed for message: ${message}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    deleteClientMessageDetail: (message: IClientMessage): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/ClientMessage`, {
            method: 'DELETE',
            credentials: 'include',
            body: JSON.stringify(message),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.DELETE_CLIENT_MESSAGE,
                    messageDetail: message
                });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.ClientInstructionConstants.DeleteMessageSuccess,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.ClientInstructionConstants.DeleteMessageError, statusType: StatusType.Error
                });
                logger.trackError(`deleteClientMessageDetail failed for message: ${message}, with error ${error.message}`);
            });
        addTask(fetchTask);
    }
}
const clientMessageDic: IClientMessageDictionary = [];

const unloadedState: IClientMessageStoreState =
    {
        data: { ...clientMessageDic },
        isLoading: true
    } as IClientMessageStoreState;

export const reducer: Reducer<IClientMessageStoreState> = (state: IClientMessageStoreState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_CLIENT_MESSAGES:
            return {
                ...unloadedState
            } as IClientMessageStoreState;
        case actionTypes.RECEIVE_CLIENT_MESSAGES:
            let received: IClientMessageStoreState = { ...state };
            received.data[action.clientMessageId] = { clientMessage: action.messageDetails };
            received.isLoading = false;
            return received;
        case actionTypes.DELETE_CLIENT_MESSAGE:
            let deleteMessage: IClientMessageStoreState = { ...state }
            if (deleteMessage.data[action.messageDetail.id]) {
                delete deleteMessage.data[action.messageDetail.id];
                deleteMessage.isLoading = false;
            }
            return deleteMessage;
    }
    return state || unloadedState;
};