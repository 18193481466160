import React from  'react';
import 'isomorphic-fetch';
import { Link } from 'react-router-dom';
import { ButtonGroup, DropdownButton } from 'react-bootstrap';
import { DocumentStatus, ITaxReturn } from './../../../common/TaxReturn';
import * as Helper from './../../../helper/HelperFunctions';
import moment from 'moment';

export interface ExtensionsActionPros {
    rowIndex: number;
    onEditClientInfoOpen: (taxDocumentId: number) => void;
    onDeleteTaxReturnOpen: (taxDocumentId: number) => void;
    onChangeStatusOpen: (taxDocumentId: number) => void;
    onReportProblemOpen: (taxDocumentId: number) => void;
    onReprocessReturnOpen: (taxDocumentId: number) => void;
    onRecallReturnOpen: (taxDocumentId: number) => void;
    taxDocumentId: number;
    groupId: number;
    onProcessReturnOpen: (taxDocumentId: number, groupId: number) => void;
    documentStatus: DocumentStatus;
    uploadedOn: Date;
}

export class ExtensionsActions extends React.Component<ExtensionsActionPros, {}> {
    private createDeleteAction = () => {
        const elements: any[] = [];
        elements.push(<button title={"Delete Return"} type="button" className="btn-white"
            onClick={(e) => this.props.onDeleteTaxReturnOpen(this.props.taxDocumentId)}>
            <i className="text-danger fas fa-times" />
        </button>);
        return elements;
    }


    private createPrimaryActions = () => {

        const elements: any[] = [];
        if (this.props.documentStatus == DocumentStatus.REPROCESSING) {
            elements.push(<div key="ReprocessingActions">
                <button title={"Reprocess Return"} type="button" className="btn-white" style={{ paddingLeft: '2px', paddingRight: '2px' }}>
                    <span className="fa-stack fas fa-spinner fa-spin text-white bg-transparent">
                        <i className="fas fa-circle fa-stack-2x text-violet" style={{ padding: '0px' }}></i>
                        <i className="fas fa-redo-alt fa-inverse fa-stack-1x" style={{ padding: '0px' }} data-fa-transform="shrink-4"></i>
                    </span>
                </button></div>);
        }
        else if (this.props.documentStatus === DocumentStatus.RECALLED || this.props.documentStatus === DocumentStatus.TOBEREPROCESSED) {

            elements.push(<div key="RecalledActions">
                <button title={"Reprocess Return"} type="button" className="btn-white" style={{ paddingLeft: '2px', paddingRight: '2px' }}
                    onClick={(e) => { this.props.onReprocessReturnOpen(this.props.taxDocumentId) }}>
                    <span className="fa-stack">
                        <i className="fas fa-circle fa-stack-2x text-violet" style={{ padding: '0px' }}></i>
                        <i className="fas fa-redo-alt fa-inverse fa-stack-1x" style={{ padding: '0px' }} data-fa-transform="shrink-4"></i>
                    </span>
                </button>

                <button title={"Upload Corrected Return"} type="button" className="btn-white"
                    onClick={(e) => { this.props.onRecallReturnOpen(this.props.taxDocumentId) }}>
                    <i className='fas fa-sync-alt text-grey'></i>
                </button>

                <button title={"Delete Return"} type="button" className="btn-white"
                    onClick={(e) => this.props.onDeleteTaxReturnOpen(this.props.taxDocumentId)}>
                    <i className="text-danger fas fa-times" />
                </button> {this.createSecondaryActions()} </div>);
        }
        else {
            elements.push(<div key="AssignmentsAction"><button title={"Process Return"} type="button" className="btn-white"
                onClick={(e) => this.props.onProcessReturnOpen(this.props.taxDocumentId, this.props.groupId)}>
                <i className='fa fa-rocket text-violet'></i>
            </button>
                <button title={"Edit Client Info"} type="button" className="btn-white"
                    onClick={(e) => this.props.onEditClientInfoOpen(this.props.taxDocumentId)}>
                    <i className='fas fa-address-card text-success'></i>
                </button>
                <button title={"Delete Return"} type="button" className="btn-white"
                    onClick={(e) => this.props.onDeleteTaxReturnOpen(this.props.taxDocumentId)}>
                    <i className="text-danger fas fa-times" />
                </button> {this.createSecondaryActions()} </div>);
        }

        return elements;
    }

    private createSecondaryActions = () => {

        return (<ButtonGroup className="btn-group-vertical" title={"More.."}>
            <DropdownButton
                size="sm"
                title={<span className="fa fa-ellipsis-h" style={{ verticalAlign: "inherit" }}></span>}
                id="process-return-dropdown"
                alignRight data-test-auto="2d0d51d7-5f0f-43b2-96bb-13f1fd93d09d">
                <li key="ChangeStatus" hidden={true} title="Change Status">
                    <Link to={"#"} onClick={(e: any) => this.props.onChangeStatusOpen(this.props.taxDocumentId)}
                        data-test-auto="A5B3F389-F367-4E0D-9BC5-23BCD15EF3F8">
                        <i className='fas fa-random ddl-icon' ></i>Change Status</Link>
                </li>
                <li key="ReportProblem" title="Report a Problem">
                    <Link to={"#"} onClick={(e: any) => this.props.onReportProblemOpen(this.props.taxDocumentId)}
                        data-test-auto="5E72448C-C121-4B50-83C1-878BBC0C41D4">
                        <i className='fas fa-bullhorn ddl-icon'></i>Report a Problem</Link>
                </li>
            </DropdownButton>
        </ButtonGroup>);
    }


    private isOneDayOldAndDeletable = (): boolean => {
        const deletableDocumentStatus = [
            DocumentStatus.PREPARINGFORDELIVERY,
            DocumentStatus.PREPARINGFORDELIVERYDESCRIPTION,
            DocumentStatus.UPLOADED,
            DocumentStatus.DELIVERYFAILED,
            DocumentStatus.DELIVERYFAILEDDESCRIPTION,
            DocumentStatus.PREPARINGFORPDFDOWNLOAD,
            DocumentStatus.PREPARINGFORPDFDOWNLOADDESCRIPTION,
            DocumentStatus.DOWNLOADPDF
        ];
        const hours = moment
            .duration(moment(new Date(), 'YYYY/MM/DD HH:mm')
                .diff(moment(this.props.uploadedOn, 'YYYY/MM/DD HH:mm'))
            ).asHours();
        return (deletableDocumentStatus.indexOf(this.props.documentStatus) > -1 && hours > 24);
    }


    public render() {
        const taxReturn = {} as ITaxReturn;
        taxReturn.id = this.props.taxDocumentId;
        taxReturn.documentStatus = this.props.documentStatus;

        const disabled = Helper.isLocked(taxReturn);
        const documentStatus = this.props.documentStatus;
        return (<div className="button-group">
            {((documentStatus === DocumentStatus.NONSUPPORTED ||
                documentStatus === DocumentStatus.ERROR ||
                documentStatus === DocumentStatus.NONSUPPORTEDDESCRIPTION) || this.isOneDayOldAndDeletable()) ?
                this.createDeleteAction() :
                documentStatus === DocumentStatus.REPROCESSING ?
                    this.createPrimaryActions()
                    : (!disabled && this.createPrimaryActions())}
        </div>);
    }
}