import * as React from 'react';
import { PdfViewer } from 'awesome-pdf-viewer';
import ControlBase, { PDF_VIEWER_BASE_HEIGHT, PDF_VIEWER_BASE_WIDTH } from 'awesome-pdf-viewer/dist/Controls/ControlBase';
import { PlaceholderControl } from 'awesome-pdf-viewer/dist/Controls/PlaceholderControl/PlaceholderControl';
import ControlLayer from 'awesome-pdf-viewer/dist/Layers/ControlLayer';
import { Header } from 'awesome-pdf-viewer/dist/layout/Header';
import { LeftPanel } from 'awesome-pdf-viewer/dist/layout/LeftPanel';
import { RightPanel } from 'awesome-pdf-viewer/dist/layout/RightPanel';
import ViewPanel from 'awesome-pdf-viewer/dist/layout/ViewPanel';
import { Toolbar } from 'awesome-pdf-viewer/dist/toolbar/Toolbar';
import { PageMode, PdfJSSource } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import Zoom from 'awesome-pdf-viewer/dist/toolbar/Zoom';
import { CustomPagination, INVALID_PAGE } from '../CustomPagination/CustomPagination';
import { IPdfDocumentFacade } from 'src/Core/Utilities/PdfDocumentFacade';
import { IEROSigner, ISigner, PdfPageSignatureControls } from '../ProcessReturnModels';
import { ISignatureControl, SignatureControlRole, SignatureControlTypes, SignatureGroupType } from '../../TaxReturn';
import { getSignatureControlStyle, getTopAdjustmentSignatureControlValue } from '../SignatureControls/SignatureHelper';
import { AwesomePdfViewerText, SignatureControlConstants } from 'src/components/helper/Constants';
import { SignatureControlPlaceholder } from '../SignatureControls/SignatureControlPlaceholder';
import Scrollbars from 'react-custom-scrollbars';
import 'awesome-pdf-viewer/dist/index.css';
import "src/assets/custom/pdfViewer.css";
import { PageSize } from 'awesome-pdf-viewer/dist/layout/LayoutBase';
import { PdfProperties } from './PdfHelper';
import { LoadingOverlay, Loader } from 'react-overlay-loader';

export interface AwesomePdfViewerProps {
    pdfDocument?: IPdfDocumentFacade | undefined;
    leftPanel: any;
    rightPanel: any;
    currentPage: number;
    pages: number[];
    downloadUrl?: string;
    enableMoveTo?: boolean;
    moveToElement?: any;
    signatureControls?: PdfPageSignatureControls;
    isAssignedToLoggedinUser?: boolean;//used only with signatureControls attribute
    keyPrefix?: string;//used only with signatureControls attribute
    isIndividual?: boolean;//used only with signatureControls attribute
    onControlRemove?(control: ISignatureControl): void;
    onControlUpdate?(oldControl: ISignatureControl, newControl: ISignatureControl): void;
    scale?: number;
    onScaleChange?(scale: number): void;
    onCurrentPageChange(page: number): void;
    pdfUrl?: string;
    showControlPropertyPopup?(control: ISignatureControl): void;
    controlType?: SignatureControlTypes;
    onControlLoad?(control: any, pageElement: any): void;
    displayViewPanelText?: string;
    isUrl?: boolean;
     pageVisited:boolean;
}

export interface AwesomePdfViewerState {
    currentPage: number;
    facadePageNumber: number;
    pdfData: string;
    documentId: string;
}

export class AwesomePdfViewer extends React.Component<AwesomePdfViewerProps, AwesomePdfViewerState> {
    private _viewPanel: any;
    private _toolbar: any;
    private _controlLayer: any;
    private _controlList: any[] = [];
    private _controlDisplayPanel: any;
    private _customPagination: any;

    constructor(props: AwesomePdfViewerProps) {
        super(props);
        this.state = {
            currentPage: props.currentPage,
            facadePageNumber: props.currentPage,
            pdfData: '',
            documentId: ''
        };
    }

    componentDidMount() {
        this.setReferences();
    }

    componentDidUpdate() {
        this.setReferences();
    }

    componentWillReceiveProps(nextProps: AwesomePdfViewerProps) {
        if (this.state.pdfData === '' || this.state.pdfData === undefined || (nextProps.pages && nextProps.pages.length > 0 && nextProps.currentPage !== this.state.currentPage && nextProps.currentPage !== INVALID_PAGE)) {
            this.changeCurrentPage(nextProps);
        }
        if (this.props.pages.length !== nextProps.pages.length) {
            this._customPagination?.onTotalPageChanges(nextProps.pages.length);
        }
        if (this.props.isUrl) {
            if (nextProps.pages && nextProps.pages.length > 0 && nextProps.currentPage !== this.state.currentPage && nextProps.currentPage !== INVALID_PAGE) {
                this.changeCurrentPage(nextProps);
            }
        } else {
            if (this.props.currentPage !== nextProps.currentPage) {
                this._customPagination?.onActivePageChange(nextProps.pages.indexOf(nextProps.currentPage) + 1);
            }
        }
    }
    
    setReferences = () => {
        this._toolbar && this._toolbar.setViewerReference(this._viewPanel);
        this._viewPanel && this._viewPanel.setToolbarReference(this._toolbar);
        this._viewPanel && this._viewPanel.setControlsReference(this._controlList);
        this._viewPanel && this._viewPanel.setControlLayerReference(this._controlLayer);
        this._controlLayer && this._controlLayer.setControlsReference(this._controlList);
        this._controlLayer && this._controlLayer.setcontrolDisplayPanelReference(this._controlDisplayPanel);
        this._controlDisplayPanel && this._controlDisplayPanel.setControlsReference(this._controlList);
        this._controlDisplayPanel && this._controlDisplayPanel.setViewerReference(this._viewPanel);
    }

    onGotoPage = (pageNo: number) => {
        if (this.props.pdfUrl) {
            this._viewPanel._document && this._viewPanel.gotoPage(this.props.pages[pageNo - 1]);
        }
        this.props.onCurrentPageChange(this.props.pages[pageNo - 1]);
    }

    onDragStop = (control: ISignatureControl, top: number, left: number) => {
        let newControl = { ...control };
        newControl.left = ControlBase.getBackendControlLeftPosition(left);
        newControl.top = ControlBase.getBackendControlTopPosition(PDF_VIEWER_BASE_HEIGHT,
            top + getTopAdjustmentSignatureControlValue(newControl.type));

        if (this.props.onControlUpdate) {
            this.props.onControlUpdate(control, newControl);
        }
    }

    onControlRemove = (control: ISignatureControl) => {
        this._controlLayer.removeControl("control_" + control.controlGuid);
        if (this.props.onControlRemove) {
            this.props.onControlRemove(control);
        }
    }

    showControlPropertyPopup = (control: ISignatureControl) => {
        if (this.props.showControlPropertyPopup) {
            this.props.showControlPropertyPopup(control);
        }
    }

    onControlLoad = (control: any, pageElement: any) => {
        this.props.onControlLoad && this.props.onControlLoad(control, pageElement);
    }

    changeCurrentPage = (props: AwesomePdfViewerProps) => {
        if (this.props.pdfUrl === undefined) {
            const pageNo = props.currentPage;
            const subDocument = props.pdfDocument?.getSubDocument(pageNo);
            if (subDocument && pageNo != -1) {

                const pageNumber: number = props.pdfDocument?.getSubDocumentOriginalPageNum(
                    subDocument,
                    pageNo);

                if (subDocument.fileName == this.state.documentId && this.state.pdfData != undefined && this.state.pdfData != "") { // current tab - only page change

                    this.setState({ currentPage: pageNumber, facadePageNumber: pageNo }, () => {
                        this._viewPanel._document && this._viewPanel.gotoPage(pageNumber);
                    });
                } else {
                    this.setState({
                        pdfData: subDocument.base64Data,
                        currentPage: pageNumber,
                        facadePageNumber: pageNo,
                        documentId: subDocument.fileName
                    });
                }
            }
        }
    }

    createControls = () => {
        if (this.props.signatureControls === undefined || this.props.signatureControls.signatureControls === undefined) {
            return <></>;
        }
        const controlCollection: any[] = [];
        this._controlList = [];
        let controls = this.props.signatureControls;

        if (controls && this.state.currentPage && controls.signatureControls) {
            let tempSignatureControls: any[] = JSON.parse(JSON.stringify(controls.signatureControls));
            if (this.props.isIndividual !== null && this.props.isIndividual) {
                let i = tempSignatureControls.length;
                while (i--) {
                    if (tempSignatureControls[i].signatureControlRole == SignatureControlRole.Spouse) {
                        tempSignatureControls.splice(i, 1);
                    }
                }
            }

            if (tempSignatureControls) {
                const subDocument = this.props.pdfDocument?.getSubDocument(this.state.facadePageNumber);
                const pageNo = this.props.pdfUrl
                    ? this.props.currentPage
                    : this.props.pdfDocument?.getSubDocumentOriginalPageNum(subDocument, this.state.facadePageNumber)

                tempSignatureControls.map((control, index) => {
                    const pageSize: PageSize = this.getCurrentPageSize();

                    const pdfViewerTop = !this.props.isUrl ? ControlBase.DEPRECATED_getControlTopProportionToPage(null, control.top, 1) - getTopAdjustmentSignatureControlValue(control.type) :
                        ControlBase.getPdfViewerControlTopPosition(pageSize.height, control.top) - getTopAdjustmentSignatureControlValue(control.type);
                    const pdfViewerLeft = !this.props.isUrl ? ControlBase.DEPRECATED_getControlLeftProportionToPage(null, control.left, 1)
                        : ControlBase.getPdfViewerControlLeftPosition(control.left);

                    let height = 30;
                    let width = 160;
                    // May be implemented later
                    // if (control.type === SignatureControlTypes.RadioButton
                    //     || control.type === SignatureControlTypes.CheckBox) {
                    //     height = control.height;
                    //     width = control.width;
                    // }

                    controlCollection.push(
                        <PlaceholderControl
                            height={(height * this.props.scale)}
                            id={SignatureControlConstants.ControlIdPrefix + control.controlGuid}
                            key={"control_key_" + control.controlGuid}
                            ref={(ref) => {
                                this._controlList.push(ref)
                            }}
                            page={pageNo}
                            width={(width * this.props.scale)}
                            top={pdfViewerTop}
                            left={pdfViewerLeft}
                            helptext={""}
                            disabled={false}
                            isRequired={false}
                            onLoad={this.onControlLoad}
                            draggable={this.props.scale === PdfProperties.DefaultScale}
                            onDragStop={(top: number, left: number) => { this.onDragStop(control, top, left) }}
                        >
                            <SignatureControlPlaceholder
                                key={this.props.keyPrefix + index}
                                isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
                                control={control}
                                showControlPropertyPopup={this.showControlPropertyPopup}
                                onControlRemove={this.onControlRemove}
                                selectedSigner={controls ? controls.signer : {} as ISigner}
                                selectedEROSigner={controls ? controls.eroSigner : {} as IEROSigner}
                                style={getSignatureControlStyle(control, this.props.scale)}
                                focusedGroup={controls ? controls.focusedGroup : SignatureGroupType.None}
                            />
                        </PlaceholderControl>)
                });
            }
            return (controlCollection);
        }
    }

    getCurrentPageSize = (): PageSize => {
        const { currentPage } = this.state;
        if (this._viewPanel) {
            return this._viewPanel.getPageSize(currentPage, 1);
        }
        else {
            return PageSize.create(PDF_VIEWER_BASE_HEIGHT, PDF_VIEWER_BASE_WIDTH);
        }
    }

    public render() {
        return (
            <div className="awesome-pdf-viewer">
                <PdfViewer>
                    {this.props.isUrl ? this.headerOptionsUrlView() : this.headerOptions()}
                    <div className="main"
                        style={{
                            height: this.props.pages && this.props.pages.length > 0 ? 'calc(100vh - 250px)' : 'calc(100vh - 205px)',
                            fontSize: '14px'
                        }}>
                        {this.leftPanel()}
                        {this.controlLayer()}
                        {this.props.pages && this.props.pages.length > 0 && this.props.pdfUrl === undefined ? this.base64BasedViewPanel() : <></>}
                        {this.props.pages && this.props.pages.length > 0 && this.props.pdfUrl !== undefined ? this.pdfUrlBasedViewPanel() : <></>}
                        {this.props.pages && this.props.pages.length === 0 &&!this.props.pageVisited ? this.displayMessage() : ""}
                        {this.props.pages && this.props.pages.length===0 && this.props.pageVisited?this.displayLoader():""}            
                        {this.rightPanel()}
                    </div>
                </PdfViewer>
            </div>);
    }

    headerOptions = () => {
        return (
            this.props.pages && this.props.pages.length > 0 &&
            <Header>
                <Toolbar
                    ref={(ref: any) => this._toolbar = ref}
                    customToolbarPosition={'left'}
                    hideReadOnly={true}
                    hideLeftPanel={screen.width < 1224 ? false : true}
                    hideRightPanel={screen.width < 1224 ? false : true}
                >
                    <Zoom></Zoom>
                    {this.customToolbarOptions()}
                </Toolbar>
            </Header>
        )
    }

    headerOptionsUrlView = () => {
        return (
            this.props.pages && screen.width < 1224 ?
                <Header>
                    <Toolbar
                        ref={(ref: any) => this._toolbar = ref}
                        customToolbarPosition={'left'}
                        hideReadOnly={true}
                        hideLeftPanel={screen.width < 1224 ? false : true}
                        hideRightPanel={screen.width < 1224 ? false : true}
                        key={this.props.pages.length}
                    >
                        {this.props.pages.length ? <Zoom></Zoom> : <></>}
                        {this.props.pages.length ? this.customToolbarOptions() : <></>}
                    </Toolbar>
                </Header> :
                this.props.pages.length > 0 &&
                <Header>
                    <Toolbar
                        ref={(ref: any) => this._toolbar = ref}
                        customToolbarPosition={'left'}
                        hideReadOnly={true}
                        hideLeftPanel={true}
                        hideRightPanel={true}
                        key={this.props.pages.length}
                    >
                        <Zoom></Zoom>
                        {this.customToolbarOptions()}
                    </Toolbar>
                </Header>
        )
    }

    customToolbarOptions = () => {

        const pageNumber = this.props.pages.indexOf(this.props.currentPage) + 1;

        return (<>
            <CustomPagination
                ref={(ref: any) => this._customPagination = ref}
                paginationLabelColor={"black"}
                activePage={pageNumber}
                onPageChange={this.onGotoPage}
                totalPages={this.props.pages.length} />
            {this.props.enableMoveTo
                ? (<><a className="divider"></a> {this.props.moveToElement} </>)
                : null}
        </>);
    }

    leftPanel = () => {
        return (
            <LeftPanel>
                <div className={"scroll"}>
                    <Scrollbars>
                        {this.props.leftPanel}
                    </Scrollbars>
                </div>
            </LeftPanel>
        );
    }

    rightPanel = () => {
        return (
            <div style={{ marginLeft: this.props.pages && this.props.pages.length > 0 ? '' : 'auto' }}>
                <RightPanel>
                    <div className={"scroll"}>
                        <Scrollbars>
                            {this.props.rightPanel}
                        </Scrollbars>
                    </div>
                </RightPanel>
            </div>
        );
    }

    controlLayer = () => {
        return (
            this.props.pages && this.props.pages.length > 0 &&
            <ControlLayer enableControlsLazyLoad={false} ref={(ref: any) => { this._controlLayer = ref; }} useDefaultNavigationStartControl={false} >
                {
                    this.createControls()
                }
            </ControlLayer>
        );
    }

    base64BasedViewPanel = () => {
        const { pdfDocument, currentPage } = this.props;
        const subDocument = pdfDocument?.getSubDocumentPDF(currentPage);
        const pageNo = this.props.pdfDocument?.getSubDocumentOriginalPageNum(
            this.props.pdfDocument?.getSubDocument(this.props.currentPage),
            this.props.currentPage);
        return (
            <ViewPanel
                ref={(ref: any) => this._viewPanel = ref}
                onScaleChanged={(scale: number) => { this.props.onScaleChange(scale) }}
                pageMode={PageMode.Classic}
                defaultPage={this.props.isUrl ? this.state.currentPage : pageNo}
                pdfSource={PdfJSSource.createPDFDocumentProxy(subDocument)}
                disableTextLayer={true}
                renderInteractiveForms={false}
                onPageChanged={this.handlePageChanges}
            >
            </ViewPanel>
        )
    }

    pdfUrlBasedViewPanel = () => {
        return (
            <ViewPanel
                ref={(ref: any) => this._viewPanel = ref}
                onScaleChanged={(scale: number) => { this.props.onScaleChange(scale) }}
                pageMode={PageMode.Classic}
                defaultPage={this.props.currentPage}
                pdfSource={PdfJSSource.createFromUrl(this.props.pdfUrl)}
                disableTextLayer={true}
                renderInteractiveForms={false}
                onDocumentLoad={this.props.isUrl ? this.handlePageChanges : () => { }}
            >
            </ViewPanel>
        )
    }

    displayMessage = () => {        
        return (
            <div style={{ padding: "15%" }}>
                <h1>                    
                    {this.props.displayViewPanelText 
                        ? this.props.displayViewPanelText
                        : AwesomePdfViewerText.NoFormPageAvailable}
                </h1>
            </div>)
    }
    displayLoader = () => {        
        return (
            <div style={{ padding: "25%" }}>
                <LoadingOverlay>
                    <Loader classNamePrefix="sm"
                        text=""
                        loading={true}/>
                </LoadingOverlay>
            </div>)
    }

    private handlePageChanges = () => {
        this._toolbar && this._toolbar.handleZoomChange(this.props.scale);
    }
}

export default React.memo(AwesomePdfViewer);
