import { connect } from 'react-redux';
import * as SavedMessageStore from '../../../store/settings/SavedMessageStore';
import * as CompanyStore from '../../../store/company/CompanyStore';
import * as Component from '../SavedMessages';
import { ApplicationState } from '../../../store';
import * as UserSettingsStore from '../../../store/userManagement/UserSettingStore';
import * as ExtensionCompanySettingsStore from '../../../store/settings/ExtensionCompanySettingsStore';


export default connect(
    (state: ApplicationState) => ({
        savedMessageList: state.savedMessage,
        extensionCompanyData: state.extensionCompanyData,
    }),
    {
        ...SavedMessageStore.actionCreators,
        ...UserSettingsStore.actionCreators,
        ...ExtensionCompanySettingsStore.ActionCreators,
    }
)(Component.SavedMessages);