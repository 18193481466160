import { IUserGroup } from '../User/UserViewModel'

export interface ICompanySettings {
    isDefault: boolean;
    deliverySettingsModel: IDeliverySettingsModel;
    displaySettingsModel: IDisplaySettingsModel;
    signatureSettingsModel: ISignatureSettingsModel;
    userPrivilegeSettingsModel: IUserPrivilegeSettingsModel;
    taxpayerPaymentSettings: ITaxpayerPaymentSettings;
    returnAccessSettings: IReturnAccessSettingsModel;
}
export interface IDefaultMessage {
    initialEmail: number;
    voucherReminders: number;
    downloadTaxDocuments: number;
    downloadSignedForms: number;
    k1Distribution: number;
    welcomeMessage: number;
    k1WelcomeMessage: number;
    downloadInstruction: number;
    paymentInstruction: number;
    savedMessage: number;
    signingReminder: number;
    k1PartnerInfoUpdate: number;
    k1ClientInfoUpdate: number;
    k1PartnerWelcomeMessage: number;
    k1ClientWelcomeMessage: number;
    k1PartnerUpdatePageMessage: number;
    attachmentInstruction: number;
    invoicePaymentSuccessMail: number;
}
export interface IDeliverySettingsModel {
    isDirectDeliveryToTaxCaddy: boolean;
    isByPassManualVerification: boolean;
    isFooterEnabled: boolean;
}
export interface IDisplaySettingsModel {
    onlinePaymentUrl: string;
    isEnableInvoice: boolean;
}

export interface IPendoData {
    isTestCompany: boolean;
    subscription: string;
}

export interface ISignatureSettingsModel {
    enableSignerDeligation: boolean;
    manualAddressId: number;
}
export interface IPaymentVoucherNotificationSettings {
    enablePaymentVoucherReminder: boolean;
    noOfDaysForVoucherReminder: number;
    paymentNotification: boolean;
    disabledVoucherReminderFromTP: boolean;
}

export interface ISigningReminderNotificationSettings {
    enableSigningReminder: boolean;
    noOfDaysForSigningReminder: number;
}
export interface IK1InfoSettings {
    noOfDays: number,
    k1MailCycle: number
}

export interface IUserPrivilegeSettingsModel {
    allowAnyUsertoSendDelivary: boolean,
    allowUserToReplaceInvoice: boolean,
    allowUserToSendwithoutInvoice: boolean,
    allowUserToReplaceDistributedInstructionsDocument: boolean,
    allowUserToDistributeK1: boolean
}
export interface IReportingChannelSettings {
    reportingEngine: string;
    enabled: boolean;
    settings: IReportingEngineSettings;
}
export interface IReportingEngineSettings {

}

export interface IOrganizerSettings {
    enabled: boolean;
}

export interface IPrintToPdfSettings {
    enabled: boolean;
}

export interface ITaxCaddySettings extends IReportingEngineSettings {
    isSkipManualVerification: boolean;
    firmIds: string[];
}
export interface IPasswordPolicySettings {
    passwordPolicy: PasswordPolicyType;
    passwordSettings: IPasswordSettings;
    modifiedDate: Date;
}

export interface IK1Settings {
    k1ClientInfoSettings: IK1InfoSettings;
    k1PartnerInfoSettings: IK1InfoSettings;
}
export interface IPasswordSettings {
    passwordOptions: PasswordOptions
    length: number;
    passwordAge: number;
}
export interface IRetentionSettings {
    retentionPeriod: number;
    attestRetentionPeriod: number;
    allowOverride: boolean
}

export interface IExternalAuthenticationSettings {
    provider: AuthenticationProvider,
    enabled: boolean,
    authorisationType: AuthorisationMode[],
    data: IExternalAuthenticationSettingData[],
    isModified: boolean;
}

export interface IExternalAuthenticationSettingData {
    key: string,
    roles: IUserGroup[]
}

export interface ITaxpayerPaymentSettings {
    companyConnectedAccountId: string;
    applicationFeesAmount: number;
    enableStripe: boolean;
    priortoReview: boolean;
    allowUserToSendReturnWithoutPayment: boolean;
}

export interface IReturnAccessSettingsModel {
    isSetAccessToEveryone: boolean;
    isSetAccessToIndividual: boolean;
    isSetAccessToOtherUsers: boolean;
    isSetAccessToUserGroups: boolean;
}

export enum AuthenticationProvider {
    None,
    AzureAD,
    SAML,
    Google
}

export enum AuthorisationMode {
    None,
    AzureAdGroups,
    SSRUserList
}

export enum TransmittalDownloadOption {
    None = "None",
    IncludeTransmittalInTaxReturnPDF = "IncludeTransmittalInTaxReturnPDF",
    CreateSeparateTransmittalPDF = "CreateSeparateTransmittalPDF"
}
export enum VoucherDownloadOption {
    None = "None",
    SeparateByQuarterlyDueDates = "SeparateByQuarterlyDueDates",
    GroupInSinglePDF = "GroupInSinglePDF"
}
export enum TaxpayerAuthenticationType {
    None = "None",
    Last4ofSSN = "Last4ofSSN",
    First4ofSSN = "First4ofSSN"
}
export enum SignatureFormSelectionType {
    None = "None",
    ESignWhenAllIncludedReturnsAllowed = "ESignWhenAllIncludedReturnsAllowed",
    ManualSign = "ManualSign",
    ReviewOnly = "ReviewOnly"
}

export enum ReportingEngineType {
    TaxCaddy = "TaxCaddy"
}

export enum PasswordPolicyType {
    NoPolicy = "NoPolicy",
    SSRDefault = "SSRDefault",
    FirmDefinedPolicy = "FirmDefinedPolicy"
}

export enum PasswordOptions {
    None = 0,
    UpperCase = 1,
    LowerCase = 2,
    Numbers = 4,
    SpecialCharacters = 8
}

export enum IconColor {
    Green = "#5CB85C",
    Yellow = "#FFD700"
}

export enum BrandingColors {
    LayoutBackgroundColor = 0,
    LayoutForegroundColor = 1,
    CoverPageBackGroundColor = 2,
    CoverPageForeGroundColor = 3
}

export const initialCompanySettings: ICompanySettings = {
    isDefault: true,
    deliverySettingsModel: {
        isDirectDeliveryToTaxCaddy: false,
        isByPassManualVerification: false,
        isFooterEnabled: false
    },
    displaySettingsModel: {
        isEnableInvoice: true,
        onlinePaymentUrl: ""
    },
    signatureSettingsModel: {
        enableSignerDeligation: true,
        manualAddressId: 0,
    },
    userPrivilegeSettingsModel: {
        allowAnyUsertoSendDelivary: true,
        allowUserToReplaceInvoice: true,
        allowUserToSendwithoutInvoice: true,
        allowUserToReplaceDistributedInstructionsDocument: false,
        allowUserToDistributeK1: false
    },
    taxpayerPaymentSettings: {
        companyConnectedAccountId: "",
        allowUserToSendReturnWithoutPayment: false,
        applicationFeesAmount: 0,
        enableStripe: false,
        priortoReview: false
    },
    returnAccessSettings:{
        isSetAccessToEveryone: false,
        isSetAccessToIndividual: false,
        isSetAccessToOtherUsers: false,
        isSetAccessToUserGroups: false
    }
}

export interface ISuiteMFASetting {
    isMfaEnabled: boolean;
    trustedDeviceExpiryDays: number;
    isAllowUsertoRememberTrustedDevice: boolean;
    skipMfaForIP: boolean;
    iSTPMfaEnabled: boolean;
}

export const initialMFASetting: ISuiteMFASetting = {
    isAllowUsertoRememberTrustedDevice: false,
    isMfaEnabled: false,
    iSTPMfaEnabled: false,
    skipMfaForIP: false,
    trustedDeviceExpiryDays: 0
}