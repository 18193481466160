import { validator } from "src/store/common/RBACStore";

export const RBACKeys = {
    extensionInProgress: {
        assignButton: "EXTINPRO_H_ASSIGN",
        deleteButton: "EXTINPRO_H_DEL",
        setAccessButton: "EXTINPRO_H_SETACS",
        uploadButton: "EXTINPRO_H_UPLOAD",
        exportButton: "EXTINPRO_G_EXPORT",
        editButton: "EXTINPRO_G_EDIT",
        processReturnButton: "EXTINPRO_G_PROCRTN",
        deleteReturnButton: "EXTINPRO_G_DEL",
        reprocessButton: "EXTINPRO_G_REPROC",
        deliveryButton: "EXTINPRO_G_DLVRY",
        reportAProblem: "EXTINPRO_H_RPTPRBLM",
        internalRouting: "EXTINPRO_G_INTROUT"
    },
    lockedExtension: {
        unlockButton: "EXTLK_H_UNLOCK",
        uploadButton: "EXTLK_H_UPLOAD"
    },
    RecycleBin: {
        restoreButton: "EXTBIN_H_RESTORE",
        deleteButton: "EXTBIN_H_DEL",
        uploadButton: "EXTBIN_H_UPLOAD"
    },
    ExtensionReport: {
        archiveButton: "EXTRPT_H_ARCHIVE",
        deleteButton: "EXTRPT_H_DEL",
        setAccessButton: "EXTRPT_H_SETACS",
        uploadButton: "EXTRPT_H_UPLOAD",
        changeStatus: "EXTRPT_H_CHGSTS",
        sendReminder: "EXTRPT_H_REMINDER",
        clientView: "EXTRPT_G_CLIVIEW",
        setCustomColumn: "EXTRPT_H_CHGCUSTCOL",
        downloadEFileFormsHeader: "EXTRPT_H_DNLDFORM",
        resendAccessLink: "EXTRPT_G_REACSLNK",
        reportAProblem: "EXTRPT_G_RPTPRBLM",
        downloadEFileFormsGrid: "EXTRPT_G_DNLDFORM",
        downloadReturns: "EXTRPT_G_DNLDRTN",
        recallReturn: "EXTRPT_G_RECALLRTN",
        viewAccessCode: "EXTRPT_G_VIEWACSCD",
        clientTracking: "EXTRPT_G_CLITRK",
        retentionPolicy: "EXTRPT_G_RETNPLCY",
        uploadForms: "EXTRPT_G_UPLOADFORMS",
        automatedReminders: "EXTRPT_G_REMINDER",
        deleteReturn: "EXTRPT_G_DEL",
        editClientInfo: "EXTRPT_G_EDIT",
        exportButton: "EXTRPT_G_EXPORT",
    },
    ArchivedExtension: {
        restoreReturn: "EXTAR_H_RESTORE",
        deleteButton: "EXTAR_H_DEL",
        setAccessButton: "EXTAR_H_SETACS",
        uploadButton: "EXTAR_H_UPLOAD",
        changeStatus: "EXTAR_H_CHGSTS",
        sendReminder: "EXTAR_H_REMINDER",
        setCustomColumn: "EXTAR_H_CHGCUSTCOL",
        downloadEFileFormsHeader: "EXTAR_H_DNLDFORM",
        resendAccessLink: "EXTAR_G_REACSLNK",
        reportAProblem: "EXTAR_G_RPTPRBLM",
        downloadEFileFormsGrid: "EXTAR_G_DNLDFORM",
        clientView: "EXTAR_G_CLIVIEW",
        downloadReturns: "EXTAR_G_DNLDRTN",
        recallReturn: "EXTAR_G_RECALLRTN",
        viewAccessCode: "EXTAR_G_VIEWACSCD",
        clientTracking: "EXTAR_G_CLITRK",
        retentionPolicy: "EXTAR_G_RETNPLCY",
        uploadForms: "EXTAR_G_UPLOADFORMS",
        automatedReminders: "EXTAR_G_REMINDER",
        deleteReturn: "EXTAR_G_DEL",
        restoreReturnGrid: "EXTAR_G_RESTORE",
        editClientInfo: "EXTAR_G_EDIT",
        exportButton: "EXTAR_G_EXPORT",
    },
    Settings: {
        settings: "EXTSETTING"
    },
    Common: {
        reportAProblem: "EXTH_H_RPTPRBLM"
    }
};

export const RBACPermission = {
    operation: "You do not have permission to perform this operation,\nplease contact your system administrator for more\ninformation.",
    page: "You do not have permissions to access this page,\nplease contact your system administrator for more\ninformation."
};

export const getProcessResourceId = () => {
    if (validator) {
        if (!validator.someResourcesExists([RBACKeys.extensionInProgress.processReturnButton])) {
            if (validator.someResourcesExists([RBACKeys.extensionInProgress.deliveryButton])) {
                return RBACKeys.extensionInProgress.deliveryButton;
            } else {
                return RBACKeys.extensionInProgress.internalRouting;
            }
        }else{
            return RBACKeys.extensionInProgress.processReturnButton;
        }
    }
}