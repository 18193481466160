import React from  'react';
import { RBACKeys } from '../helper/RbacConstants';

interface IToggleSwitchProps {
    switched?: boolean;
    handleChange(event: any): void;
    title?: string;
    disabled?: boolean;
    resourceId?: string
}


export default class ToggleSwitch extends React.Component<IToggleSwitchProps, {}> {
    constructor(props: IToggleSwitchProps) {
        super(props);       
    }
    
    public render() {
        let resourceAttribute = {};
        if (this.props.resourceId) {
            resourceAttribute = { 'data-resource-id': this.props.resourceId }
        }
        return (
            <div {...resourceAttribute}>
            <label className="custom-toggle-switch dis-inline" title={this.props.title}>
                <input className="custom-toggle-switch-input" type="checkbox" onChange={this.props.handleChange} checked={this.props.switched} disabled={this.props.disabled} />
                <span className="custom-toggle-switch-label" data-on="On" data-off="Off"></span>
                <span className="custom-toggle-switch-handle"></span> 
            </label>
            </div>
        );
    }
}