import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './../../store';
import { handleResponse } from './../../store/Library';
import { actionTypes } from './../../store/ActionTypes'
import { StatusType, NotificationAction } from './../../store/common/NotificationStore';
import {
    ExtensionSettingsStoreState,
    unloadedExtensionSettingsStoreState,
    ExtensionCompanySettingsLoaderAction,
    ReceiveExtensionCompanySettings,
    UpdateExtensionCompanySettings
} from './../../Core/ViewModels/Extension/ExtensionStoreModels';
import { ExtensionCompanySettings } from './../../Core/ViewModels/Extension/ExtensionComponentModels';
import { StoreConstants } from './../../components/helper/Constants';
import { LoggerFactory } from '../../Logger/LoggerFactory';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';


type KnownActions =
    ReceiveExtensionCompanySettings |
    UpdateExtensionCompanySettings |
    ExtensionCompanySettingsLoaderAction |
    NotificationAction


export const ActionCreators = {
    requestExtensionCompanySettings: (): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.EXTENSION_COMPANY_SETTINGS_LOADER,
            loading: true
        })
        const fetchTask = fetch(`${API_BASE_URL}api/Extension/ExtensionCompanySettings/GetExtensionCompanySettings`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => response.json() as Promise<ExtensionCompanySettings>)
            .then((data) => {
                dispatch({
                    type: actionTypes.RECEIVE_EXTENSION_COMPANY_SETTINGS,
                    data: data
                });
                dispatch({
                    type: actionTypes.EXTENSION_COMPANY_SETTINGS_LOADER,
                    loading: false
                })
            }).catch((error) => { 
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.CompanySettings,
                    statusType: StatusType.Error
                });
                logger.trackError(`requestExtensionCompanySettings failed with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    updateExtensionCompanySettings: (companySettings: ExtensionCompanySettings): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.EXTENSION_COMPANY_SETTINGS_LOADER,
            loading: true
        })
        const fetchTask = fetch(`${API_BASE_URL}api/Extension/ExtensionCompanySettings`, {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(companySettings),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.UPDATE_EXTENSION_COMPANY_SETTINGS,
                    update: companySettings
                });
                dispatch({
                    type: actionTypes.EXTENSION_COMPANY_SETTINGS_LOADER,
                    loading: false
                });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Success.UpdateCompanySettings,
                    statusType: StatusType.Success
                });
            }).catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.UpdateCompanySettings,
                    statusType: StatusType.Error
                });
                logger.trackError(`updateExtensionCompanySettings failed for companySettings: ${companySettings}, with error ${error.message}`);
            });
        addTask(fetchTask);
    }
}

export const reducer: Reducer<ExtensionSettingsStoreState> = (state: ExtensionSettingsStoreState = unloadedExtensionSettingsStoreState, incomingAction: Action) => {
    const action = incomingAction as KnownActions;
    switch (action.type) {

        case actionTypes.RECEIVE_EXTENSION_COMPANY_SETTINGS:
            const companySettings = { ...state };
            companySettings.companySettings = action.data;
            return companySettings;

        case actionTypes.UPDATE_EXTENSION_COMPANY_SETTINGS:
            const updateCompanySettings = { ...state };
            updateCompanySettings.companySettings = action.update;
            return updateCompanySettings;

        case actionTypes.EXTENSION_COMPANY_SETTINGS_LOADER:
            return {
                ...state,
                loading: action.loading
            };

        default: return state || unloadedExtensionSettingsStoreState;
    }
};
