//<reference path="../../reports/filters.tsx" />
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import 'isomorphic-fetch';
import { IModalProps } from './ProfileObjects';
import { logClientEvent } from '../../helper/LoggerHelper';
import { WithLogout, WithLogoutProps } from 'src/oidcClient/withLogout';
import { LogEventConstants } from 'src/components/helper/LogEventConstants';


interface LogoutState {
    saving: boolean;
}

export class Logout extends React.Component<IModalProps & WithLogoutProps, LogoutState> {
    constructor(props: any) {
        super(props);
        this.state = {
            saving: false
        };
    }
    private onLogout = (e: React.SyntheticEvent<EventTarget>) => {
        e.preventDefault();
        this.setState({
            saving: true
        });

        logClientEvent(
            `${LogEventConstants.Common.UserLogout}`,
            { count: 1 }
        );

        this.props.onLogout();
    }

    public render() {
        return <Modal
            show={this.props.showState}
            contentClassName="border-radius-5"
            dialogClassName="common-modal">

            <Modal.Header className="common-modal-header">
                <span className='common-modal-header-text'>
                    Log Out
                </span>
            </Modal.Header>
            <Modal.Body className="common-modal-content">
                <LoadingOverlay className='full-height'>
                    Are you sure you want to log out? You will be logged out of all instances of the SafeSend Suite.
                    <Loader loading={this.state.saving} text="Log Out in progress..." />
                </LoadingOverlay>
            </Modal.Body>
            <Modal.Footer className="common-modal-footer">
                <button
                    className="modal-secondary-button secondary-btn-bg"
                    onClick={this.props.onHide}
                    data-test-auto="31DF48DE-9F14-4065-B44A-08D265BA2CF5"
                >No, Discard</button>
                <button
                    className="modal-primary-button red-btn-bg"
                    onClick={this.onLogout}
                    disabled={this.state.saving}
                    data-test-auto="32FE58D3-FB91-4F86-98BA-29D93FBB5762"
                >Yes, Log Out</button>
            </Modal.Footer>
        </Modal>
    }
}
export default WithLogout(Logout);