import React from 'react';
import { Link } from 'react-router-dom';
import { YearAndMonth } from '../../common/YearAndMonthComponent'
import { CheckBoxComponent } from '../../common/CheckBoxComponent';
import { ExtensionCompanySettings } from 'src/Core/ViewModels/Extension/ExtensionComponentModels';

interface IRetentionSettingsProps {
    companySettings: ExtensionCompanySettings;
    handleRetentionDays(val: number, type: string): void;
    handleOverrideChange: (value: boolean) => void;
}

const RetentionSettings: React.FC<IRetentionSettingsProps> = ({
    companySettings,
    handleRetentionDays,
    handleOverrideChange
}) => {

    const {
        retentionSettingsModel:{
            retentionPeriod,
            allowOverride,
            attestRetentionPeriod
        }
    } = companySettings;

    return (
        <div className="company-settings">
            <div id="divRetentionSettings" className="new-main-content">
                <h3 className="dis-inline">Retention Policy</h3>
                <Link to={'#'}
                    className="help-icon marL05"
                    data-placement="right"
                    data-toggle="tooltip"
                    title="Defines the duration for retaining the documents delivered to the taxpayer. Report information will be retained until the CPA firm requests them to be removed.">
                    <i className="fas fa-question-circle"></i>
                </Link>
                <div className="row settings-subheading">
                    Default
                </div>
                <div className="row">
                    <div className="col-sm-3 col-md-3 col-lg-3 marL20 padT07">Automatically delete documents</div>
                    <div className="col-sm-3 col-md-3 col-lg-3">
                        <YearAndMonth id="DefaultRetention" controlValue={retentionPeriod} onControlValueChange={(value) => handleRetentionDays(value, "retentionPeriod")} componentEnabled={true} years={7} />
                    </div>
                    <div className="col-sm-2 col-md-2 col-lg-2 padT07">after the delivery date</div>
                </div>
                <div className="row settings-subheading">
                    Attest Clients
                </div>
                <div className="row marB15">
                    <div className="col-sm-3 col-md-3 col-lg-3 marL20 padT07">Automatically delete documents</div>
                    <div className="col-sm-3 col-md-3 col-lg-3">
                        <YearAndMonth id="AttestRetention" controlValue={attestRetentionPeriod} onControlValueChange={(value) => handleRetentionDays(value, "attestRetentionPeriod")} componentEnabled={true} years={7} />
                    </div>
                    <div className="col-sm-2 col-md-2 col-lg-2 padT07">after the delivery date</div>
                </div>
                <div className="row">
                    <div className="settings-checkbox marL20 col-sm-4 col-md-4 col-lg-4" style={{ display: "flex" }}>
                        <CheckBoxComponent
                            id={"cbOverrideRetention"}
                            text={"Allow any user to override before or after delivery"}
                            checked={allowOverride}
                            onChange={(e) => handleOverrideChange(e.target.checked)}
                            datatestAuto={"754F3E87-4F74-47C9-B5AD-DE48519E1A43"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RetentionSettings;
