import React from 'react';
import * as Bootstrap from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import * as TaxDocument from '../common/TaxReturn';
import { CheckBoxComponent } from '../common/CheckBoxComponent'
import { YearAndMonth } from '../common/YearAndMonthComponent'
import { ExtensionCompanySettings } from 'src/Core/ViewModels/Extension/ExtensionComponentModels';

interface IRetentionComponentProps {
    show: boolean,
    onCancel: (event: any) => void,
    taxReturn: TaxDocument.ITaxReturn,
    companySettings: ExtensionCompanySettings | undefined;
    onSave: (id: number, isAttested: boolean, retentionPeriod: number) => void,
}

interface IRetentionComponentState {
    isAttested: boolean;
    retentionPeriod: number;
    loading: boolean,
    message: string,
    disableSave: boolean
}

const msg = {
    saving: 'Saving Retention Period...',
    loading: 'Loading, please wait...'
}

export class RetentionComponent extends React.Component<IRetentionComponentProps, IRetentionComponentState>
{
    constructor(props: IRetentionComponentProps) {
        super(props);
        this.state = {
            isAttested: false,
            retentionPeriod: 0,
            loading: true,
            message: msg.loading,
            disableSave: false
        }
        this.handleRetentionPeriodChange = this.handleRetentionPeriodChange.bind(this);
        this.handleAttestClientChange = this.handleAttestClientChange.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    UNSAFE_componentWillReceiveProps(props: IRetentionComponentProps) {
        if (props.taxReturn && props.taxReturn.documentSettings) {
            let retentionPeriod = props.taxReturn.documentSettings.documentRetentionSetting.retentionPeriod;
            let taxDocument: TaxDocument.ITaxReturn = props.taxReturn;
            let isAttested: boolean = false;
            if (TaxDocument.isIndividual(taxDocument) || TaxDocument.isMutual(taxDocument)) {
                isAttested = taxDocument.taxpayer.additionalInfo.isAttested;
            }
            else if (TaxDocument.isPartnership(taxDocument)) {
                isAttested = taxDocument.partnership.additionalInfo.isAttested;
            }
            this.setState({
                retentionPeriod: retentionPeriod,
                loading: false,
                disableSave: retentionPeriod == 0,
                isAttested: isAttested
            });
        }
        else {
            this.setState({ loading: true });
        }

        if (!props.show) {
            this.setState({ message: msg.loading });
        }
    }

    private handleRetentionPeriodChange = (value: number) => {
        if (value) {
            this.setState({ disableSave: false }, () => {
                this.setState({ retentionPeriod: value })
            });
        }
        else {
            this.setState({ disableSave: true });
        }
    }

    private handleAttestClientChange(event: any) {
        const isChecked = event.target.checked;
        this.setState({
            isAttested: isChecked,
            retentionPeriod: isChecked ?
                this.props.companySettings.retentionSettingsModel.attestRetentionPeriod : this.props.companySettings.retentionSettingsModel.retentionPeriod
        });
    }

    private onSave = (id: number, isAttested: boolean, retentionPeriod: number) => {
        this.setState({ message: msg.saving },
            () => {
                this.props.onSave(id, isAttested, retentionPeriod);
            }
        );
    }

    private onCancel = (e: any) => {
        this.props.onCancel(e);
    }

    public render() {

        let modalBody = !this.state.loading ? <div> <div className="row">
            <div className="col-xs-7 col-sm-9" style={{ display: "flex" }}>
                <div>
                    <CheckBoxComponent id="chkAttestClient" checked={this.state.isAttested} onChange={this.handleAttestClientChange} text="Attest Client" />
                </div>
            </div>
        </div >
            <br />
            <div className="row">
                <div className="col-xs-2 col-md-2 col-lg-2 padT07">Delete</div>
                <div className="col-xs-6 col-md-6 col-lg-6 padR00 counter-textbox">
                    <YearAndMonth
                        id="DocRetention"
                        controlValue={this.state.retentionPeriod}
                        onControlValueChange={this.handleRetentionPeriodChange}
                        componentEnabled={this.props.companySettings && this.props.companySettings.retentionSettingsModel.allowOverride}
                        years={7}
                    />
                </div>
                <div className="col-xs-4 col-md-4 col-lg-4 padT07">after the delivery date</div> </div>
        </div> : <LoadingOverlay style={{ height: 100, position: 'relative' }}>
            <Loader loading={this.props.show} text={this.state.message} />
        </LoadingOverlay>

        return (
            <Modal show={this.props.show} onHide={this.onCancel} className="rentention-modal">
                <Modal.Header closeButton>
                    <Modal.Title><span className='text-secondary fas fa-clock' style={{ color: 'grey', marginRight: '5px' }}>
                    </span>Modify Retention Period</Modal.Title>
                </Modal.Header>

                <div className="modal-body">
                    {modalBody}
                </div>

                <Modal.Footer>
                    <Bootstrap.Button
                        variant="default"
                        onClick={this.onCancel}
                        data-test-auto="010E9025-BB27-4200-936F-F95C4B244310">
                        <i className='fas fa-times'></i>Close
                    </Bootstrap.Button>
                    <Bootstrap.Button
                        variant='info'
                        onClick={() => { this.onSave(this.props.taxReturn.id, this.state.isAttested, this.state.retentionPeriod) }}
                        data-test-auto="0602AC29-3EE2-46A7-BF1F-C5D311C58F63"
                        disabled={this.state.disableSave}
                    >
                        <i className='fas fa-save'></i>Save
                    </Bootstrap.Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
