import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../../';
import { actionTypes } from '../../ActionTypes';
import { StatusType, NotificationAction } from '../../common/NotificationStore';
import { handleResponse } from '../../Library';
import {
	RecycleGroupedExtensionsState, RecycleGroupedExtensionsTableModel,
	unloadedRecycleGroupedExtensionsStoreState, RequestRecycleGroupedExtensionsAction,
	ReceiveRecycleGroupedExtensionsAction, RequestRecycleGroupedExtensionsPagesAction,
	ReceiveRecycleGroupedExtensionsPagesAction
} from '../../../Core/ViewModels/GroupExtensions/StoreModels';
import * as Constants from '../../../components/helper/Constants';
import { LoggerFactory } from '../../../Logger/LoggerFactory';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';

type KnownAction = RequestRecycleGroupedExtensionsAction
	| ReceiveRecycleGroupedExtensionsAction
	| RequestRecycleGroupedExtensionsPagesAction
	| ReceiveRecycleGroupedExtensionsPagesAction
	| NotificationAction;

type DispatchAction = RequestRecycleGroupedExtensionsAction
	| ReceiveRecycleGroupedExtensionsAction

export const actionCreators = {
	requestRecycleGroupedExtensions: (query: string, reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
		// Only load data if it's something we don't already have (and are not already loading)
		let state = getState();
		if (reload || query !== state.recycleGroupedExtensions.query) {

			let page = state.recycleGroupedExtensionsPages[query];
			if (!reload && page) {
				dispatch({
					type: actionTypes.RECEIVE_RECYCLE_GROUPED_EXTENSIONS,
					query: query,
					table: page.recycleGroupedExtensionsTableModel
				});

				return;
			}

			const fetchTask = fetch(`${API_BASE_URL}api/RecycleGroupedExtensions/GetRecycledGroupedExtensionsAsync` + query, {
				method: 'GET',
				credentials: 'include'
			})
				.then(handleResponse)
				.then(response => response as Promise<RecycleGroupedExtensionsTableModel>)
				.then(data => {
					dispatch({ type: actionTypes.RECEIVE_RECYCLE_GROUPED_EXTENSIONS, query: query, table: data });
					dispatch({ type: actionTypes.RECEIVE_RECYCLE_GROUPED_EXTENSIONS_PAGES, query: query, table: data, totalRowCount: data.count });
				})
				.catch((error) => {
					dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error })
					logger.trackError(`requestRecycleGroupedExtensions failed for query: ${query}, with error ${error.message}`);
				});
			addTask(fetchTask);
			dispatch({ type: actionTypes.REQUEST_RECYCLE_GROUPED_EXTENSIONS, query: query });
			dispatch({ type: actionTypes.REQUEST_RECYCLE_GROUPED_EXTENSIONS_PAGES, query: query });
		}
	},
	deleteGroupedExtensions: (returnIds: number[], groupIds: number[], callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const formData = new FormData();
		formData.append('documentsId', JSON.stringify(returnIds));
		formData.append('groupId', JSON.stringify(groupIds));

		const fetchTask = fetch(`${API_BASE_URL}api/RecycleGroupedExtensions/DeleteExtensions`, {
			method: 'POST',
			credentials: 'include',
			headers: {
				'Accept': 'application/json'
			},
			body: formData
		})
			.then(handleResponse)
			.then(() => {
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: Constants.RecycleGroupedExtensionConstants.SuccessMessages.Delete,
					statusType: StatusType.Success
				});

				if (callback) {
					callback();
				}
			})
			.catch((error) => {
				dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });
				logger.trackError(`deleteGroupedExtensions failed for returnIds: ${returnIds}, with error ${error.message}`);
			});
		addTask(fetchTask);
	},
	restoreGroupedExtensions: (returnIds: number[], callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		let fetchTask = fetch(`${API_BASE_URL}api/RecycleGroupedExtensions/RestoreExtensions`, {
			method: 'POST',
			credentials: 'include',
			headers: {
				'Accept': 'application/json, text/plain,*/*',
				'Content-Type': 'application/json',
				'traditional': 'true'
			},
			body: JSON.stringify(returnIds)
		}).then(handleResponse)
			.then(() => {
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: Constants.RecycleGroupedExtensionConstants.SuccessMessages.Restore,
					statusType: StatusType.Success
				});

				if (callback) {
					callback();
				}
			})
			.catch((error) => {
				dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });
				logger.trackError(`restoreGroupedExtensions failed for returnIds: ${returnIds}, with error ${error.message}`);
			});
		addTask(fetchTask);
	}
}
export const reducer: Reducer<RecycleGroupedExtensionsState> = (state: RecycleGroupedExtensionsState = unloadedRecycleGroupedExtensionsStoreState, incomingAction: Action) => {
	const action = incomingAction as DispatchAction;
	switch (action.type) {
		case actionTypes.REQUEST_RECYCLE_GROUPED_EXTENSIONS:
			return ({
				...unloadedRecycleGroupedExtensionsStoreState,
				query: action.query,
				loading: true
			}) as RecycleGroupedExtensionsState;
		case actionTypes.RECEIVE_RECYCLE_GROUPED_EXTENSIONS:
			return {
				query: action.query,
				recycleGroupedExtensionsTableModel: action.table,
				totalRowCount: action.table.count,
				loading: false
			} as RecycleGroupedExtensionsState;
		default:
			// The following line guarantees that every action in the KnownAction union has been covered by a case above
			const exhaustiveCheck: never = action;
	}
	return state || unloadedRecycleGroupedExtensionsStoreState;
}