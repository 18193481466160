import { DocumentUploadData } from "src/components/common/ProcessReturnModal/AdditionalEsignDocuments/AdditionalEsignDocument";
import { UploadtaxReturnConstants } from "src/components/helper/Constants";
import { getFileExtension, validateIndividualFileSize } from "src/components/helper/Validations";
import { VenusNotifier } from "src/components/helper/VenusNotifier";
import { API_BASE_URL } from "src/utils/contants";

export const PdfProperties = {
    PageHeight: 792,
    PageWidth: 612,
    DefaultScale: 1
}

export const generateBase64 = (url: string): Promise<any> => {

    return new Promise(function (resolve, reject) {
        fetch(url, {
            method: 'GET',
            headers: new Headers({})
        })
            .then(resp => resp.blob())
            .then((blob => {
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                let base64Data: any;
                reader.onloadend = function () {
                    base64Data = extractBase64(reader.result);
                    resolve(base64Data);
                }
            }), (error: string) => {
                reject(error);
            });
    });
}

const extractBase64 = (dataUrl: any) => {
    const index = dataUrl.indexOf('base64,');
    if (index == -1) {
        return null;
    }
    return dataUrl.substring(index + 7);
}