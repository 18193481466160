import React from 'react';
import { SignatureFormSelectionType } from '../../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { IPdfDocumentFacade } from '../../../../Core/Utilities/PdfDocumentFacade';
import * as CompanyStore from '../../../../store/company/CompanyStore';
import * as UserSettingStore from '../../../../store/userManagement/UserSettingStore';
import { ISignatureDelegation } from '../../../navigation/profile/MySettings';
import {
    IFormGroupViewProps, IFormGroupViewState,
    ISignatureControlsDictionary, PdfPageSignatureControls, IBookmarkSection, IPageItem, ProcessReturnTabs, getAllEroSigners, EfileInfoView, ICONS
} from '../ProcessReturnModels';
import { EfileSignatureControlPanel, ISigner } from '../../../common/ProcessReturnModal/RightSidebar/EfileSignatureControlPanel';
import { PdfPage } from '../PdfPage';
import { BookmarksPane } from '../BookmarksPane';
import { EROSignaturePanel } from '../RightSidebar/EROSignaturePanel';
import { IEfileSignControlBoxItem } from '../RightSidebar/EfileSignControl';
import * as UserSignatureStore from '../../../../store/common/UserSignatureStore';
import { INVALID_PAGE } from '../CustomPagination/CustomPagination';
import {
    EngagementType, SignatureGroupType, DocumentGroups, SelectOptions, ISignatureControl, IVoucher,
    SignatureControlRole, SignatureControlTypes, ITaxingAuthority, IEFile, IVoucherFormBase, CustomType,
    initialVoucher, isMutual, isIndividual, getVoucherTypes,
    ITaxReturn, IFormBase, VoucherTypes, VoucherNo, VoucherMode, IGroup, SignatureType,
    VoucherProperty, IEFileProperty, IGroupFormBase, initialGroupFormBase, TaxFormType, ITaxReturnGroup
} from '../../../common/TaxReturn';
import { IUserProfile } from '../../../navigation/profile/ProfileObjects';
import { EsignHelpTextConstant, EfileTabConstats, TabEFileConstants, VoucherTabConstants } from './../../../helper/Constants';
import { isStrictReviewExtesnion, GetSignatureControl, isReviewExtesnion, dragStartSignatureControl, isEsignExtension } from './../SignatureControls/SignatureHelper';
import { Guid } from '../../../../Core/Utilities/Guid';
import { cloneTaxReturn, getAuthorityData, isBusinessReturn } from '../../../helper/HelperFunctions';
import { VenusNotifier } from '../../../helper/VenusNotifier';
import ToggleSwitch from '../../ToggleSwitch';
import { Col, Row, Accordion, Card } from 'react-bootstrap';
import moment from "moment";
import { ValidateWebsite } from '../../../helper/Validations';
import { IVoucherState } from '../../../../store/ProcessReturn/voucher/VoucherStore';
import { VoucherEntry } from '../Voucher/VoucherEntry';
import { VoucherScript } from '../Voucher/VoucherScript';
import { PDFJSStatic } from 'pdfjs-dist';
import { cloneDeep } from 'lodash';
import { ExtensionCompanySettings } from '../../../../Core/ViewModels/Extension/ExtensionComponentModels';
import { logClientEvent, logger } from 'src/components/helper/LoggerHelper';
import AwesomePDFViewer from '../PdfViewer/AwesomePDFViewer';
import { PdfProperties } from '../PdfViewer/PdfHelper';
import PageMoveTo from '../PdfViewer/PageMoveTo';
import * as TaxDocument from 'src/components/common/TaxReturn';
import { IAuthState } from 'src/store/auth/reducer';
import { UploadFunctions } from "@sssuite-js-packages/file-utility";
import { DocumentEventLog } from 'src/components/helper/LogEventConstants';
import { filterTaxFilingDates } from 'src/components/helper/VoucherNameFilter';

const PDFJS: PDFJSStatic = require('pdfjs-dist');

export interface IEFileViewProps extends IFormGroupViewProps {
    isLoading?: boolean;
    userSettings: UserSettingStore.UserSettings;
    isEsign: boolean;
    enableEsignatureForBusinessReturns: boolean;
    company: CompanyStore.ICompanyData;
    userProfile: IUserProfile;
    signatureControls: ISignatureControlsDictionary;
    signatureFormSelectionType: SignatureFormSelectionType;
    userSignatures: UserSignatureStore.IUserSignatures;
    removeSignatureControl(control: ISignatureControl, pageNo: number): void;
    addSignatureControl(control: ISignatureControl, pageNo: number): void;
    updateEROSignatureStamp(userId: number): void;
    replaceSignatureControl(oldControl: ISignatureControl, newControl: ISignatureControl, pageNo: number): void;
    getVoucherUploadLink: (taxReturn: ITaxReturn, callback: (data: any) => void) => void;
    saveTaxingAuthority: (taxingAuthority: ITaxingAuthority, callback: () => void, callback2?: () => void) => void;
    requestCustomAuthority: (value: boolean) => void;
    voucherStore: IVoucherState;
    reGroupForms: (pages: number[], forms: IGroupFormBase[], destinationGroup: DocumentGroups) => void;
    extensionCompanySettings: ExtensionCompanySettings;
    handleEfileAddedToggle: (isFileEdited: boolean) => void;
    isFileEdited: boolean;
    removeDuplicateForm: (duplicateForm: IFormBase) => void;
    duplicatePages: number[];
    auth: IAuthState;
}

export interface IEFileViewState extends IFormGroupViewState {
    eroSigners: IEROSigner[];
    selectedEROSigner: IEROSigner;
    signer: ISigner[];
    selectedSigner: ISigner;
    focusedGroup: SignatureGroupType;
    matchedForm: IGroupFormBase;
    isDoNotDisplayDatesEnabled: boolean;
    voucher: IVoucher;
    isPaymentUrlSame: boolean;
    masterPaymentUrl: string;
    formName: string;
    signatureExpand: boolean;
    voucherExpand: boolean;
    selectedGroup: DocumentGroups;
    addedVoucher: IVoucher;
    currentVoucherClone: IVoucher;
    isControlAdded: boolean;
    scale: number;
    toolTipControl: TaxDocument.IEfileDocumentControl;
    showToolTipPopup: boolean;
}

export interface IEROSigner {
    value: number;
    label: string;
    isEnableSignatureDelegation: boolean;
    eroImage: string;
    disabled?: boolean;
}

let signatureDelegationUsersInitialState: ISignatureDelegation = {
    delegate: [],
    userId: 0
}

const NO_INDEX = -1;
const INVALID_INDEX: number = -1;
const isEqual = require("react-fast-compare");

export class TabEFile extends React.Component<IEFileViewProps, IEFileViewState>{
    constructor(props: IEFileViewProps) {
        super(props);
        this.state = {
            currentPage: INVALID_PAGE,
            zoomEnabled: false,
            actionPanelShow: false,
            signer: [],

            selectedSigner: {
                value: 0,
                label: "",
                signatureRole: SignatureControlRole.None
            },

            eroSigners: [],
            selectedEROSigner: {
                value: 0,
                label: "",
                isEnableSignatureDelegation: true,
                eroImage: ""
            },
            focusedGroup: SignatureGroupType.None,
            matchedForm: initialGroupFormBase,
            isDoNotDisplayDatesEnabled: true,
            voucher: initialVoucher,
            isPaymentUrlSame: false,
            masterPaymentUrl: "",
            formName: "",
            signatureExpand: true,
            voucherExpand: false,
            selectedGroup: DocumentGroups.None,
            addedVoucher: initialVoucher,
            currentVoucherClone: initialVoucher,
            isControlAdded: false,
            scale: PdfProperties.DefaultScale,
            toolTipControl: TaxDocument.initialControl,
            showToolTipPopup: false
        };
        this.page = {} as PdfPage;
    }
    private page: PdfPage;

    componentDidMount() {
        if (this.props && this.props.pages) {
            this.setState({
                currentPage: this.props.pages[0],
                isDoNotDisplayDatesEnabled: this.props.taxReturn.documentSettings.documentDisplaySetting.isDoNotDisplayDatesEnabled != null ?
                    this.props.taxReturn.documentSettings.documentDisplaySetting.isDoNotDisplayDatesEnabled :
                    this.props.extensionCompanySettings && this.props.extensionCompanySettings.signatureSettingsModel ?
                        this.props.extensionCompanySettings.signatureSettingsModel.isDoNotDisplayDatesEnabled :
                        this.state.isDoNotDisplayDatesEnabled
            });
            this.onPageSelect(this.props.pages[0]);
        }
    }

    public checkIfDateControlsExist(taxReturn: ITaxReturn): boolean {
        let dateControls: any = [];
        if (taxReturn && taxReturn.formGroups) {
            let index = taxReturn.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
            if (taxReturn.formGroups[index]) {

                taxReturn.formGroups[index].forms.map((f: IFormBase) => {
                    let form: IEFile = f as IEFile;
                    dateControls = form.signatureControls.filter(x => x.type === SignatureControlTypes.Date);
                });
            }
        }
        return dateControls.length > 0 ? true : false;
    }

    public checkIfEROControlsExist(taxReturn: ITaxReturn): boolean {
        let eroControls: any = [];
        if (taxReturn && taxReturn.formGroups) {
            let index = taxReturn.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
            if (taxReturn.formGroups[index]) {
                taxReturn.formGroups[index].forms.map((f: IFormBase) => {
                    let form: IEFile = f as IEFile;
                    eroControls = form.signatureControls.filter(x => x.signatureControlRole === SignatureControlRole.ERO);
                });
            }
        }
        return eroControls.length > 0 ? true : false;
    }

    shouldComponentUpdate(nextProps: IEFileViewProps, nextState: IEFileViewState) {
        return ((nextProps.pages !== this.props.pages) ||
            (this.state.currentPage === INVALID_PAGE) ||
            (!isEqual(this.state, nextState)));
    }

    componentWillMount() {
        this.setDropdownValues();
    }

    componentWillReceiveProps(nextProps: IEFileViewProps) {
        if (this.props.userSignatures !== nextProps.userSignatures)
            this.setEroSignerDropdownValues(nextProps);

        if (nextProps.pages && nextProps.pages.length != this.props.pages.length) {
            this.onPageSelect(nextProps.pages[0]);
        }
    }

    validateFilingData = (destinationGroup: DocumentGroups, forms: IFormBase) => {
        let isValid = true;
        switch (destinationGroup) {
            case DocumentGroups.Vouchers:
                isValid = this.validateVoucher(forms as IVoucher);
                break;
            case DocumentGroups.EFile:
                isValid = this.validateEFile(forms as IEFile);
                break;
        }
        return isValid;
    }

    private validateEFile(eFile: IEFile) {
        if (!!eFile) {
            if (!eFile.authorityID || eFile.authorityID === 0) {
                VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.AuthorityWarning, "");
                return false;
            }
            if (!eFile.formName || !eFile.formName.trim()) {
                VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.FormNameWarning, "");
                return false;
            }
            return true;
        }
        return false;
    }

    private onUpdateAddedEfile = (efile: IEFile, currentEFileClone: IEFile) => {
        let addedefileIndex: number = INVALID_INDEX;
        let efileIndex = this.props.taxReturn.formGroups
            .findIndex(x => x.group == DocumentGroups.EFile);
        addedefileIndex = (this.props.taxReturn.formGroups[efileIndex].forms).findIndex(x => x.pageNo[0] == currentEFileClone.pageNo[0]);
        if (addedefileIndex > INVALID_INDEX) {
            {
                var clone = cloneTaxReturn(this.props.taxReturn);
                clone.formGroups[efileIndex].forms[addedefileIndex] = { ...efile } as IEFile;
                this.props.updateTaxDocument(clone);
                VenusNotifier.Success(EfileTabConstats.UpdatedAuthority.FilingFormUpdateSuccess, "Success");
            }
        }
    }
    private onUpdateFilingForm = (callback?: () => void) => {
        let formBase = { ...this.state.matchedForm }
        let destinationGroup = formBase.voucherEnable ? DocumentGroups.Vouchers : formBase.signatureEnable ? DocumentGroups.EFile : DocumentGroups.TaxReturns;
        let isValid = this.validateFilingData(destinationGroup, formBase);

        let isFileEdited: boolean = this.props.isFileEdited;
        let isBusinessRet = isBusinessReturn(this.props.taxReturn);
        let isStrictReview = isStrictReviewExtesnion(this.props.extensionCompanySettings, isBusinessRet);

        if (isValid) {
            if (isReviewExtesnion(this.props.extensionCompanySettings, isBusinessRet) &&
                !isStrictReview && formBase.signatureEnable) {
                isFileEdited = true;
            }

            const controls: ISignatureControl[] = this.getControls(SignatureType[SignatureType.ESignWhenAllIncludedReturnsAllowed]);

            if (destinationGroup === this.state.selectedGroup) {
                switch (this.state.selectedGroup) {
                    case DocumentGroups.EFile: {
                        let efile = { ...formBase } as IEFile;

                        efile.signatureControls = controls;

                        const currentEFileClone = efile;
                        this.onUpdateAddedEfile(efile, currentEFileClone);
                        this.setState({
                            signatureExpand: efile.signatureEnable
                        });
                        break;
                    }
                    case DocumentGroups.Vouchers:
                        {
                            let voucher = { ...formBase } as IVoucher;

                            voucher.signatureControls = controls;

                            const taxingAuthority: any = this.props.getAllTaxingAuthorities.find(x => x.Id == voucher.authorityID);
                            this.onUpdateAddedVoucher(voucher, this.state.currentVoucherClone, taxingAuthority, EfileTabConstats.UpdatedAuthority.FilingFormUpdateSuccess);
                            this.setState({
                                voucherExpand: voucher.voucherEnable
                            });
                            break;
                        }
                }
            }
            else {
                if (destinationGroup === DocumentGroups.EFile) {
                    let efile = { ...formBase } as IEFile;
                    efile.signatureControls = controls;

                    this.props.reGroupForms(formBase.pageNo, [efile as IGroupFormBase], destinationGroup);
                }
                else if (destinationGroup === DocumentGroups.Vouchers) {
                    let voucher = { ...formBase } as IVoucher;
                    voucher.signatureControls = controls;

                    this.props.reGroupForms(formBase.pageNo, [voucher as IGroupFormBase], destinationGroup);
                }
                else {
                    this.props.reGroupForms(formBase.pageNo, [formBase], destinationGroup);
                }
                this.setState({
                    signatureExpand: formBase.signatureEnable,
                    voucherExpand: formBase.voucherEnable
                });
            }

            if (isReviewExtesnion(this.props.extensionCompanySettings, isBusinessRet)
                && !isStrictReview) {
                this.props.handleEfileAddedToggle(isFileEdited);
            }
            else if (isEsignExtension(this.props.extensionCompanySettings, isBusinessRet) && formBase.signatureEnable) {
                this.props.handleEfileAddedToggle(true);
            }
            if (callback) {
                callback();
            }
        }
    }

    private setDropdownValues = () => {

        var signer = [];
        let selectedSigner = { ...this.state.selectedSigner };
        if (isMutual(this.props.taxReturn)) {
            if (this.props.taxReturn.taxpayer && this.props.taxReturn.spouse) {
                signer.push({
                    value: this.props.taxReturn.taxpayer.id, label: this.props.taxReturn.taxpayer.name, signatureRole: SignatureControlRole.Taxpayer
                    , disabled: this.props.taxReturn.taxpayer.isDeceased
                });
                signer.push({
                    value: this.props.taxReturn.spouse.id, label: this.props.taxReturn.spouse.name, signatureRole: SignatureControlRole.Spouse
                    , disabled: this.props.taxReturn.spouse.isDeceased
                });
                selectedSigner = signer[0].disabled ? signer[1] : signer[0]
            }
        }
        else if (isIndividual(this.props.taxReturn)) {
            this.props.taxReturn.taxpayer &&
                signer.push({
                    value: this.props.taxReturn.taxpayer.id, label: this.props.taxReturn.taxpayer.name, signatureRole: SignatureControlRole.Taxpayer
                    , disabled: this.props.taxReturn.taxpayer.isDeceased
                });
            selectedSigner = signer[0]
        }
        else {
            signer.push({ value: this.props.taxReturn.partnerId, label: "Signer", signatureRole: SignatureControlRole.PartnerShip });
        }
        //const selectedSigner = { ...this.state.selectedSigner };
        //if (selectedSigner.signatureRole == SignatureControlRole.Taxpayer && selectedSigner.disabled) {

        //}

        this.setEroSignerDropdownValues(this.props);
        const focusedGroup = signer.length > 0 ? SignatureGroupType.Esign : (this.state.eroSigners.length > 0 ? SignatureGroupType.Stamp : SignatureGroupType.None)

        this.setState({
            signer: signer,
            selectedSigner: selectedSigner.value > 0 ? selectedSigner : signer[0],
            focusedGroup: focusedGroup
        });
    }

    private setEroSignerDropdownValues = (nextprops: IEFileViewProps) => {
        const eroSigners: IEROSigner[] = getAllEroSigners(this.props.company,
            this.props.userProfile,
            this.props.taxReturn,
            this.props.userSettings,
            nextprops.userSignatures,
            this.props.auth
        );

        const selectedEROId = this.props.taxReturn.documentSettings.documentSignatureSetting.signatureStampUser ?
            this.props.taxReturn.documentSettings.documentSignatureSetting.signatureStampUser.userId
            : this.state.selectedEROSigner.value;
        let selectedEROSigner = eroSigners.find(x => x.value == selectedEROId) as IEROSigner;
        selectedEROSigner = selectedEROSigner ? selectedEROSigner : eroSigners.find(x => x.value === 0) as IEROSigner;

        this.setState({
            eroSigners: eroSigners,
            selectedEROSigner: selectedEROSigner,
        });
    }

    private handleEROSignerChange = (selectedOption: IEROSigner) => {
        if (selectedOption && selectedOption.eroImage !== "") {
            this.setState({
                selectedEROSigner: {
                    value: selectedOption.value,
                    label: selectedOption.label,
                    isEnableSignatureDelegation: selectedOption.isEnableSignatureDelegation,
                    eroImage: selectedOption.eroImage
                }
            }, () => {
                this.props.updateEROSignatureStamp(selectedOption.value);
            })
        }
    }

    private getSortedbookmarks(bookmarks: IBookmarkSection[]): IBookmarkSection[] {
        let tmpBookmarks: IBookmarkSection[] = [];
        for (var i = 0; i <= bookmarks.length - 1; i++) {
            tmpBookmarks.push({
                heading: bookmarks[i].heading,
                pages: bookmarks[i].pages.sort((first: IPageItem, second: IPageItem) => (first.pageNo - second.pageNo)).sort((first: IPageItem, second: IPageItem) =>
                    first.pageTitle.localeCompare(second.pageTitle))
                    .reduce((result: any, e: IPageItem) => {
                        e.pageTitle === 'Federal' ? result.unshift(e) : result.push(e);
                        return result;
                    }, [])
            });
        }
        return tmpBookmarks;
    }

    private onToggleEfileDateSettings = (event: any) => {
        let taxReturn = cloneTaxReturn(this.props.taxReturn);
        const eFileInfo = new EfileInfoView(taxReturn);
        if (event.currentTarget.checked) {
            eFileInfo.removeDateControls();
        }

        this.setState({
            isDoNotDisplayDatesEnabled: event.currentTarget.checked
        }, () => {
            taxReturn.documentSettings.documentDisplaySetting.isDoNotDisplayDatesEnabled = this.state.isDoNotDisplayDatesEnabled;
            this.props.updateTaxDocument(taxReturn);
        });
    }

    private validateVoucher(voucher: IVoucher) {

        if (!voucher.authorityID) {
            VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.AuthorityWarning, "");
            return false;
        }
        if (voucher.paymentType === VoucherTypes.None || voucher.voucherNo === VoucherNo.None || !voucher.paymentType) {
            VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.PaymentTypeWarning, "");
            return false;
        }
        if (!voucher.dueDate || !moment(voucher.dueDate).isValid()) {
            VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.DueDateWarning, "");
            return false;
        }
        if (Number.isNaN(voucher.amount)) {
            VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.AmountEmptyWarning, "");
            return false;
        }
        if (!voucher.formName || voucher.formName.trim() === "") {
            VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.FormNameWarning, "");
            return false;
        }
        if ((!voucher.customType || voucher.customType == CustomType.Select) && voucher.vocherMode !== VoucherMode.Recognized) {
            VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.CustomTypeWarning, "");
            return false;
        }
        if (voucher.customType && voucher.customType == CustomType.OnlinePaymentRequired) {
            if (!voucher.onlinePaymentUri || voucher.onlinePaymentUri === "") {
                VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.OnlinePaymentUrlWarning, "");
                return false;
            }
            else if (!ValidateWebsite(voucher.onlinePaymentUri.trim())) {
                VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.OnlinePaymentValidUrlWarning, "");
                return false;
            }
        }
        if (voucher.customType && voucher.customType === CustomType.AddCustom) {
            if (!voucher.customText || voucher.customText.trim() === "") {
                VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.CustomTextWarning, "");
                return false;
            }
        }
        return true;
    }

    public setBookmarkForManuallyAddedVoucher = (authorityID: number, formName: string) => {
        var authorities: ITaxingAuthority[] = this.props.getAllTaxingAuthorities;
        for (let i in authorities) {
            if (authorities[i].Id === authorityID) {
                return authorities[i].AuthorityName + ' ' + formName;
            }
        }
        return formName;
    }

    private getVoucherUploadLinkCallback = (uploadData: any) => {
        let voucherScript = new VoucherScript(this.state.addedVoucher, this.props.taxReturn);
        voucherScript.prepareVoucher(this.state.addedVoucher)
            .then((blob: any): void => {
                this.onVoucherPrepareSuccess(blob, uploadData);
            }, this.onVoucherPrepareError);
    }

    private onVoucherPrepareSuccess = (blob: any, uploadData: any): void => {
        PDFJS.getDocument(URL.createObjectURL(blob)).then((pdf) => {
            let documentFacade: IPdfDocumentFacade =
                (this.props.pdfDocuments[this.props.docId].document as IPdfDocumentFacade);
            documentFacade.append(pdf, uploadData.fileGuid);
            let tempVoucher: IVoucher = { ...this.state.addedVoucher };
            let voucher = this.props.taxReturn.formGroups.find((value) => value.group === DocumentGroups.Vouchers);
            tempVoucher.pageNo = [documentFacade.getLastPage()];
            tempVoucher.bookmark = tempVoucher.customType == CustomType.None ? tempVoucher.formName :
                this.setBookmarkForManuallyAddedVoucher(tempVoucher.authorityID, tempVoucher.formName)

            if (!voucher) {
                voucher = {} as IGroup;
                voucher.group = DocumentGroups.Vouchers;
                voucher.forms = [] as IFormBase[];
                voucher.forms.push(tempVoucher);
                this.props.taxReturn.formGroups.push(voucher);
            }
            else {
                this.props.taxReturn.formGroups.map((value: any) => {
                    if (value.group === DocumentGroups.Vouchers) {
                        value.forms.push(tempVoucher);
                    }
                });
            }
            this.props.updateTaxDocument(this.props.taxReturn);

            let uploadHelperFunctions = new UploadFunctions();
            uploadHelperFunctions.uploadFile(blob, uploadData, tempVoucher.formName);

            this.onPageSelect(tempVoucher.pageNo[0]);
        });
    }

    private onVoucherPrepareError = (error: any) => {
        VenusNotifier.Error(VoucherTabConstants.AddVoucher.voucherPdfPrepareMessageFailed, "Error");
        this.getVoucherDetails(this.state.currentPage);
    }

    private onUpdateAddedVoucher = (voucher: IVoucher, currentVoucherClone: IVoucher, taxingAuthority: ITaxingAuthority, message?: string) => {
        if (!this.validateVoucher(voucher)) {
            return;
        }
        voucher.onlinePaymentUri = voucher.customType !== CustomType.None ? voucher.onlinePaymentUri : "";
        let addedVoucherIndex: number = INVALID_INDEX;
        let voucherIndex = this.props.taxReturn.formGroups
            .findIndex(x => x.group == DocumentGroups.Vouchers);
        addedVoucherIndex = (this.props.taxReturn.formGroups[voucherIndex].forms as IVoucherFormBase[]).findIndex(x => x.pageNo[0] == currentVoucherClone.pageNo[0] && x.voucherNo == currentVoucherClone.voucherNo);

        if (addedVoucherIndex > INVALID_INDEX) {
            if (this.state.voucher.formName != voucher.formName &&
                this.state.voucher.amount == voucher.amount &&
                this.state.voucher.customType == voucher.customType &&
                this.state.voucher.authorityID == voucher.authorityID &&
                this.state.voucher.dueDate == voucher.dueDate &&
                this.state.voucher.voucherNo == voucher.voucherNo) {
                var clone = cloneTaxReturn(this.props.taxReturn);
                clone.formGroups[voucherIndex].forms[addedVoucherIndex] = { ...voucher } as IVoucher;
                clone.formGroups[voucherIndex].forms[addedVoucherIndex].bookmark = voucher.vocherMode === VoucherMode.CustomVoucher ? this.setBookmarkForManuallyAddedVoucher(voucher.authorityID, voucher.formName) : voucher.formName;
                this.setState({ voucher: voucher });
                this.props.updateTaxDocument(clone);
                VenusNotifier.Success(VoucherTabConstants.VoucherUpdateSuccess, "Success");
            }
            else {
                if (!!voucher.customType &&
                    voucher.vocherMode === VoucherMode.CustomVoucher) {
                    let slicedVoucher = this.props.taxReturn.formGroups[voucherIndex].forms.splice(addedVoucherIndex, 1);

                    if (slicedVoucher && !!slicedVoucher[0].pageNo)
                        this.onPageDelete(slicedVoucher[0].pageNo[0]);

                    if (!voucher.authorityImage || !voucher.authorityName) {
                        let authorityData: { name: string, image: string } =
                            getAuthorityData(voucher.authorityID, this.props.getAllTaxingAuthorities);

                        let editedVoucherState: IEFileViewState = Object.assign({}, this.state);
                        editedVoucherState.voucher = voucher;
                        editedVoucherState.voucher.authorityImage = authorityData.image;
                        editedVoucherState.voucher.authorityName = authorityData.name;

                        this.setState(editedVoucherState);
                    }

                    this.props.getVoucherUploadLink(this.props.taxReturn, this.getVoucherUploadLinkCallback);
                    this.setState({ addedVoucher: { ...voucher }, voucher: voucher });
                    message ? VenusNotifier.Success(message, "Success") :
                        VenusNotifier.Success(VoucherTabConstants.VoucherUpdateSuccess, "Success");
                } else {

                    var clone = { ...this.props.taxReturn };
                    clone.formGroups[voucherIndex].forms[addedVoucherIndex] = { ...voucher } as IFormBase;

                    this.setState({
                        voucher: voucher,
                        addedVoucher: voucher
                    }, () => this.props.updateTaxDocument(clone));
                    message ? VenusNotifier.Success(message, "Success") :
                        VenusNotifier.Success(VoucherTabConstants.VoucherUpdateSuccess, "Success");
                }
            }
            if (taxingAuthority && taxingAuthority.CustomAuthority && voucher.customType === CustomType.OnlinePaymentRequired) {
                taxingAuthority.PrefixStateCode = false;
                taxingAuthority.PaymentURL = voucher.onlinePaymentUri;
                this.props.saveTaxingAuthority(taxingAuthority, this.fetchCustomAuthority);
            }
        }

    }

    fetchCustomAuthority = () => {
        this.props.requestCustomAuthority(true);
    }

    getVoucherDetails = (page: number, voucherNo: number = -1) => {
        let taxingAuthority: any;
        let paymentUrlInfo: any;
        let editedUrl: string = '';
        let voucherIndex = this.props.taxReturn.formGroups
            .findIndex(x => x.group == DocumentGroups.Vouchers);

        if (voucherIndex != -1 && !!page) {
            let voucherForms: IVoucherFormBase[] = this.props.taxReturn.formGroups[voucherIndex].forms as IVoucherFormBase[];
            this.setState({
                voucher: voucherNo != -1 ? voucherForms.find(x => x.pageNo[0] === page && x.voucherNo === voucherNo) as IVoucher :
                    voucherForms.find(x => x.pageNo[0] === page) as IVoucher,
                formName: voucherNo != -1 ? voucherForms.find(x => x.pageNo[0] === page && x.voucherNo === voucherNo)!.formName :
                    voucherForms.find(x => x.pageNo[0] === page)!.formName
            }, () => {
                taxingAuthority = this.props.getAllTaxingAuthorities.find(x => x.Id == this.state.voucher.authorityID);
                const voucherDefaultDates = this.props.voucherStore.voucherDefaultDates
                    .filter(x => x.authorityId === this.state.voucher.authorityID &&
                        x.taxYear === this.props.taxReturn.taxYear &&
                        x.returnType === Number(EngagementType[this.props.taxReturn.engagementType]));
                
                const paymentUrlInfo: any = filterTaxFilingDates(voucherDefaultDates, this.state.voucher.formName, this.state.voucher.paymentType)
                if (paymentUrlInfo) {
                    if (this.state.voucher.onlinePaymentUri && this.state.voucher.onlinePaymentUri.trim().substr(-1) === '/') {
                        editedUrl = this.state.voucher.onlinePaymentUri.trim().slice(0, -1);
                    }
                    else {
                        editedUrl = this.state.voucher.onlinePaymentUri;
                    }
                    if (paymentUrlInfo.paymentURL && paymentUrlInfo.paymentURL.trim().substr(-1) === '/') {
                        paymentUrlInfo.paymentURL = paymentUrlInfo.paymentURL.trim().slice(0, -1);
                    }
                    if (paymentUrlInfo.esPaymentURL && paymentUrlInfo.esPaymentURL.trim().substr(-1) === '/') {
                        paymentUrlInfo.esPaymentURL = paymentUrlInfo.esPaymentURL.trim().slice(0, -1);
                    }

                    this.setState({
                        isPaymentUrlSame: taxingAuthority?.CustomAuthority ? true : this.state.voucher.paymentType === VoucherTypes.PaymentVoucher ?
                            paymentUrlInfo.paymentURL === null || paymentUrlInfo.paymentURL === '' ? true : editedUrl === paymentUrlInfo.paymentURL
                            :
                            paymentUrlInfo.esPaymentURL === null || paymentUrlInfo.esPaymentURL === '' ? true : editedUrl === paymentUrlInfo.esPaymentURL,
                        masterPaymentUrl: taxingAuthority?.CustomAuthority ? "" : this.state.voucher.paymentType === VoucherTypes.PaymentVoucher ? paymentUrlInfo.paymentURL : paymentUrlInfo.esPaymentURL
                    });
                }
                else {
                    this.setState({
                        isPaymentUrlSame: true
                    });
                }
            });
        }
    }

    toggleSignatureExpand = () => {
        this.setState({
            signatureExpand: !this.state.signatureExpand,
        }, () => {
            if (this.state.signatureExpand) {
                this.setState({
                    voucherExpand: false
                })
            }
        })
    }

    toggleVoucherExpand = () => {
        this.setState({
            voucherExpand: !this.state.voucherExpand,
        }, () => {
            if (this.state.voucherExpand) {
                this.setState({
                    signatureExpand: false
                })
            }
        })
    }

    onFilingVoucherDataUpdate = (propertyType: VoucherProperty, value: any, pageNumber: number) => {

        let voucher = this.state.matchedForm as IVoucher;
        if (voucher.pageNo[0] == pageNumber) {
            switch (propertyType) {
                case VoucherProperty.authorityID:
                    {
                        voucher.authorityID = value;
                    }
                    break;
                case VoucherProperty.voucherTypes:
                    {
                        voucher.voucherNo = Number(value);
                        voucher.paymentType = getVoucherTypes(value);
                    }
                    break;
                case VoucherProperty.dueDate:
                    {
                        if (value === "") {
                            voucher.dueDate = undefined;
                        } else {
                            voucher.dueDate = value;
                        }
                    }
                    break;
                case VoucherProperty.amount:
                    {
                        voucher.amount = isNaN(value) ? 0 : value;
                    }
                    break;
                case VoucherProperty.formName:
                    {
                        voucher.formName = value;
                    }
                    break;
                case VoucherProperty.customType:
                    {
                        voucher.customType = value;
                    }
                    break;
                case VoucherProperty.customText:
                    {
                        voucher.customText = value;
                    }
                    break;
                case VoucherProperty.onlinePaymentUri:
                    {
                        voucher.onlinePaymentUri = value;
                    }
                    break;
                case VoucherProperty.isMasterPaymentUrl:
                    {
                        voucher.isMasterPaymentUrl = value;
                    }
                    break;
                case VoucherProperty.isFormNameUpdated:
                    {
                        voucher.isFormNameUpdated = value;
                    }
                    break;
            }

        }
        this.setState({ matchedForm: voucher });
    }

    onFilingEFileDataUpdate = (propertyType: IEFileProperty, value: any, pageNumber: number) => {
        let eFile = { ...this.state.matchedForm as IEFile }
        if (eFile.pageNo[0] == pageNumber) {
            switch (propertyType) {
                case IEFileProperty.authorityID:
                    {
                        eFile.authorityID = +value;
                    }
                    break;
                case IEFileProperty.formName:
                    {
                        eFile.formName = value;
                    }
                    break;
            }
        }
        this.setState({ matchedForm: eFile });
    }

    onResetFilingEFileData = () => {
        this.setState({ matchedForm: initialGroupFormBase });
    }

    getActiveKey = (isSignatureEnabled: boolean, isVoucherEnabled: boolean): any => {

        let activeKey: any = "-1";
        if (this.state.signatureExpand) {
            activeKey = "0";
        }
        else if (this.state.voucherExpand) {
            activeKey = "1";
        }

        return (activeKey === "-1" ? "" : activeKey);
    }

    createLeftPanel = (duplicatePages: number[]) => {
        const {
            pdfDocuments,
            bookmarks
        } = this.props;
        return <BookmarksPane
            title="Pages"
            docId={this.props.docId}
            pdfDocuments={pdfDocuments}
            sections={bookmarks}
            onPageDelete={this.onPageDelete}
            onPageSelect={this.onPageSelect}
            getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
            tabType={ProcessReturnTabs.EFile}
            taxReturn={this.props.taxReturn}
            onUpdateFilingForm={this.onUpdateFilingForm}
            voucherStore={this.props.voucherStore}
            matchedForm={this.state.matchedForm}
            onEditFilingForm={this.onEditFilingForm}
            handleSignatureChange={this.handleSignatureChange}
            handleVoucherChange={this.handleVoucherChange}
            onFilingEFileDataUpdate={this.onFilingEFileDataUpdate}
            onFilingVoucherDataUpdate={this.onFilingVoucherDataUpdate}
            onResetFilingEFileData={this.onResetFilingEFileData}
            extensionCompanySettings={this.props.extensionCompanySettings}
            removeDuplicateForm={this.props.removeDuplicateForm}
            duplicatePages={duplicatePages}
        />
    };

    createRightPanel = (
        duplicatePage: boolean,
        enableSignatureOption: boolean
    ) => {
        const {
            taxReturn,
            extensionCompanySettings: {
                signatureSettingsModel: {
                    useSignatureStamp
                }
            },
        } = this.props;
        let enableWholeSignaturePanel = true;

        let isSignatureEnabled: boolean = this.getDocumentSignatureStatus(this.state.currentPage);

        let isVoucherEnabled: boolean = this.getVoucherStatus(this.state.currentPage);

        let signatureIcon = this.state.signatureExpand ? "fas fa-minus" : "fas fa-plus";

        let activeKeyValue: any = this.getActiveKey(isSignatureEnabled, isVoucherEnabled);

        let voucherIcon = this.state.signatureExpand && this.state.voucherExpand && activeKeyValue == "0" ? "fas fa-plus"
            : this.state.voucherExpand ? "fas fa-minus" : "fas fa-plus";

        const disableVoucher = !isVoucherEnabled ? "disable-panel" : "";


        const disableWholeSignaturePanel = ((taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType === SignatureType.ESignWhenAllIncludedReturnsAllowed ||
            useSignatureStamp)
            && (isSignatureEnabled || isVoucherEnabled))
            ? ""
            : "disable-panel";

        const engagementType = Number(EngagementType[this.props.taxReturn.engagementType]);

        const ControlBoxItems: IEfileSignControlBoxItem[] = [];

        if (engagementType === EngagementType.E1040) {
            ControlBoxItems.push(
                {
                    caption: 'Add Signature Field',
                    icon: 'fas fa-signature',
                    controlType: SignatureControlTypes.Signature,
                    helpText: EsignHelpTextConstant.ClientSignature
                },
                {
                    caption: 'Add Date Signed Field',
                    icon: 'fas fa-calendar-alt',
                    controlType: SignatureControlTypes.Date,
                    helpText: EsignHelpTextConstant.SignatureDate
                });
        }
        else {
            ControlBoxItems.push(
                {
                    caption: 'Add Signature Field',
                    icon: 'fas fa-signature',
                    controlType: SignatureControlTypes.Signature,
                    helpText: EsignHelpTextConstant.ClientSignature
                },
                {
                    caption: 'Add Date Signed Field',
                    icon: 'fas fa-calendar-alt',
                    controlType: SignatureControlTypes.Date,
                    helpText: EsignHelpTextConstant.SignatureDate
                },
                {
                    caption: 'Add Name & Title Field',
                    icon: 'fas fa-address-card',
                    controlType: SignatureControlTypes.NameAndTitle,
                    helpText: EsignHelpTextConstant.NameAndTitle
                });
        }

        ControlBoxItems.push(
            {
                caption: 'Add Initials Field',
                icon: 'fas fa-font',
                controlType: SignatureControlTypes.Initial,
                helpText: EsignHelpTextConstant.Initial
            },
            {
                caption: 'Add Company Field',
                icon: 'fas fa-building',
                controlType: SignatureControlTypes.Company,
                helpText: EsignHelpTextConstant.Company
            }
        );


        return <Accordion activeKey={activeKeyValue} style={{ marginRight: "1px", marginTop: "10px" }}>
            {
                !duplicatePage && enableWholeSignaturePanel && <Card className={disableWholeSignaturePanel}>
                    <Accordion.Toggle
                        style={{ padding: "10px" }}
                        as={Card.Header}
                        variant="link"
                        eventKey="0">
                        <Card.Title as="h4"
                            onClick={() => this.toggleSignatureExpand()}>
                            <i className={signatureIcon}></i><span> Signatures</span>

                        </Card.Title>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <div>
                            <Card.Body style={{ padding: 10 }}>
                                {enableSignatureOption &&
                                    <EfileSignatureControlPanel
                                        title="e-Signatures"
                                        selectedSigner={this.state.selectedSigner}
                                        signer={this.state.signer}
                                        signerChangeHandler={this.signerChange}
                                        isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
                                        ControlBoxItems={ControlBoxItems}
                                        onSignatureControlFocus={this.onSignatureControlFocus}
                                        dragStart={this.dragStartSignatureControl}
                                        dragEnd={this.dragEnd}
                                        keyPrefix={"efile-right-panel-control-"}
                                    />
                                }
                                {
                                    (useSignatureStamp) &&
                                    <div>
                                        <EROSignaturePanel
                                            title="Signature Stamp"
                                            selectedEROSigner={this.state.selectedEROSigner}
                                            eroSigner={this.state.eroSigners}
                                            onEROSignerChange={this.handleEROSignerChange}
                                            isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
                                            onSignatureControlFocus={this.onSignatureControlFocus}
                                            dragStart={this.dragStartSignatureControl}
                                            dragEnd={this.dragEnd}
                                        />
                                    </div>
                                }
                                {
                                    enableSignatureOption && <Row style={{ paddingBottom: "8px", marginTop: "20px" }}>
                                        <Col sm={8}>
                                            {TabEFileConstants.Info.DoNotInsertDates}
                                        </Col>
                                        <Col sm={4}>
                                            <ToggleSwitch switched={this.state.isDoNotDisplayDatesEnabled} handleChange={this.onToggleEfileDateSettings} />
                                        </Col>
                                    </Row>
                                }
                            </Card.Body>
                        </div>
                    </Accordion.Collapse>
                </Card>
            }

            {
                !duplicatePage &&

                <Card className={disableVoucher} style={{ marginTop: "10px" }}>
                    <Accordion.Toggle
                        style={{ padding: "10px" }}
                        as={Card.Header}
                        variant="link"
                        eventKey="1">
                        <Card.Title as="h4"
                            onClick={() => this.toggleVoucherExpand()}>
                            <i className={voucherIcon}></i> <span>  Vouchers </span>
                        </Card.Title>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <div>
                            <Card.Body style={{ padding: 10 }}>
                                {this.state.voucher &&
                                    <VoucherEntry
                                        onUpdateAddedVoucher={this.onUpdateAddedVoucher}
                                        taxingAuthorities={this.props.getAllTaxingAuthorities}
                                        voucherDefaultDate={this.props.voucherStore.voucherDefaultDates}
                                        voucher={this.state.voucher ? this.state.voucher : initialVoucher}
                                        voucherStore={this.props.voucherStore}
                                        taxReturn={this.props.taxReturn}
                                        isPaymentUrlSame={this.state.isPaymentUrlSame}
                                        masterPaymentUrl={this.state.masterPaymentUrl}
                                    />
                                }
                            </Card.Body>
                        </div>
                    </Accordion.Collapse>
                </Card>

            }

        </Accordion>
    };

    createMoveTo = (duplicatePage: boolean) => {
        return <PageMoveTo
            hideMoveTo={duplicatePage}
            documentGroup={DocumentGroups.EFile}
            reGroupPreview={(destinationGroup: DocumentGroups) => {
                this.props.reGroupPreview([this.state.currentPage], destinationGroup)
                this.setState({ currentPage: this.props.pages[0] });
            }}
        />
    }

    showControlPropertyPopup = (control: TaxDocument.IEfileDocumentControl) => {
        this.setState({
            toolTipControl: control,
            showToolTipPopup: true
        })
    }

    public render() {
        const {
            taxReturn,
            duplicatePages
        } = this.props;


        const duplicatePage = duplicatePages.find(x => x === this.state.currentPage) ? true : false;

        let enableSignatureOption: boolean = taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType === SignatureType.ESignWhenAllIncludedReturnsAllowed;


        let signatureType: any = taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType;

        signatureType = (typeof signatureType == "number") ? SignatureType[signatureType] : signatureType;

        let eFileIndex = this.props.taxReturn.formGroups
            .findIndex(x => x.group == DocumentGroups.EFile);


        if (eFileIndex > -1) {
            let eFileForms: IEFile[] = this.props.taxReturn.formGroups[eFileIndex].forms as IEFile[];
        }

        return (
            <AwesomePDFViewer
                pdfDocument={this.props.pdfDocuments[this.props.docId].document as IPdfDocumentFacade}
                leftPanel={this.createLeftPanel(duplicatePages)}
                rightPanel={this.createRightPanel(duplicatePage, enableSignatureOption)}
                currentPage={this.state.currentPage}
                pages={this.props.pages}
                enableMoveTo={true}
                moveToElement={this.createMoveTo(duplicatePage)}
                signatureControls={enableSignatureOption ? this.getSignatureControls() : this.getEroSignatureControls()}
                isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
                isIndividual={isIndividual(this.props.taxReturn)}
                keyPrefix={"efile-control-"}
                onControlRemove={this.onControlRemove}
                onControlUpdate={this.onControlUpdate}
                scale={this.state.scale}
                onScaleChange={(s) => this.setState({ scale: s })}
                onCurrentPageChange={(page) => this.onPageSelect(page)}
                showControlPropertyPopup={this.showControlPropertyPopup}
            />
        );

    }

    private getDocumentGroup = (pageNo: number): DocumentGroups => {
        let voucher = this.props.taxReturn.formGroups.find((value) => value.group === DocumentGroups.Vouchers);
        let efile = this.props.taxReturn.formGroups.find(x => x.group === DocumentGroups.EFile);
        if (voucher && voucher.forms.find(j => j.pageNo[0] === pageNo)) {
            return DocumentGroups.Vouchers;
        }
        else if (efile && efile.forms.find(e => e.pageNo[0] === pageNo)) {
            return DocumentGroups.EFile;
        }
        else {
            return DocumentGroups.TaxReturns;
        }

    }

    private getDocumentSignatureStatus = (pageNo: number): boolean => {

        let signatureStatus: boolean = false;
        let efile = this.props.taxReturn.formGroups.find(x => x.group === DocumentGroups.EFile);
        let voucherData = this.getVouchers(pageNo, this.props.taxReturn);
        if (voucherData) {
            signatureStatus = voucherData.signatureEnable;
        }
        else if (efile && efile.forms.find(e => e.pageNo[0] === pageNo)) {
            let efileData = efile.forms.find(e => e.pageNo[0] === pageNo) as IEFile;
            efileData.signatureEnable = efileData.signatureEnable ? efileData.signatureEnable : true;
            signatureStatus = efileData.signatureEnable;
        }

        return signatureStatus;
    }

    private getVouchers = (pageNo: number, taxReturn: ITaxReturn) => {

        let voucher = taxReturn.formGroups.find((value) => value.group === DocumentGroups.Vouchers);
        if (voucher && voucher.forms.find(j => j.pageNo[0] === pageNo)) {
            let voucherData = voucher.forms.find(j => j.pageNo[0] === pageNo) as IVoucher;
            return voucherData;
        }
        return undefined;
    }

    private getVoucherStatus = (pageNo: number): boolean => {

        let voucherStatus: boolean = false;
        let voucherData = this.getVouchers(pageNo, this.props.taxReturn);
        if (voucherData) {
            voucherStatus = voucherData.voucherEnable;
            if (!voucherData.voucherEnable) {
                voucherStatus = voucherData.pageNo.length ? true : false;
            }
        }

        return voucherStatus;
    }

    private setSelectedPageData = (group: DocumentGroups, pageNumber: number, voucherNo: number = -1) => {

        if (group === DocumentGroups.Vouchers) {
            this.getVoucherDetails(pageNumber, voucherNo);
        }
        if (this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType === SignatureType.ESignWhenAllIncludedReturnsAllowed ||
            this.props.extensionCompanySettings.signatureSettingsModel.useSignatureStamp) {
            if (group === DocumentGroups.TaxReturns) {
                this.setState({
                    voucherExpand: false,
                    signatureExpand: false,
                });
            }
            else {
                this.setState({
                    signatureExpand: true,
                    voucherExpand: false
                });
            }
        }
        else {
            if (group === DocumentGroups.Vouchers) {
                const voucherEnable: boolean = this.getVoucherStatus(pageNumber);
                const signatureEnabled: boolean = this.getDocumentSignatureStatus(pageNumber);
                this.setState({
                    voucherExpand: voucherEnable,
                    signatureExpand: signatureEnabled,
                });
            }
            else if (group === DocumentGroups.TaxReturns) {
                this.setState({
                    voucherExpand: false,
                    signatureExpand: false,
                });
            }
            else {
                this.setState({
                    signatureExpand: false,
                    voucherExpand: false
                });
            }
        }
    }

    private onPageSelect = (page: number, voucherNo: number = -1) => {
        if (!!page) {
            const group = this.getDocumentGroup(page);
            this.setState({
                currentPage: page,
                selectedGroup: group
            });
            this.setSelectedPageData(group, page, voucherNo);
        }
    }

    private handleSignatureChange = (status: boolean, pageNumber: number) => {
        if (this.state.matchedForm.pageNo[0] == pageNumber) {
            if (this.state.matchedForm.voucherEnable) {
                const voucher = cloneDeep(this.state.matchedForm) as IVoucher;
                voucher.signatureEnable = Boolean(Number(status));
                voucher.signatureControls = [];
                this.setState({
                    matchedForm: voucher
                });
            } else if (Boolean(Number(status))) {
                const form = cloneDeep(this.state.matchedForm) as IEFile;
                const efile: IEFile = {
                    signatureEnable: Boolean(Number(status)),
                    voucherEnable: false,
                    authorityID: form.authorityID,
                    bookmark: form.bookmark,
                    formName: form.formName,
                    formType: TaxFormType.EFile,
                    signatureControls: [],
                    id: form.id,
                    pageNo: form.pageNo,
                };
                this.setState({
                    matchedForm: efile
                });
            } else {
                const form = cloneDeep(this.state.matchedForm) as ITaxReturnGroup;
                const taxreturn: ITaxReturnGroup = {
                    signatureEnable: false,
                    voucherEnable: false,
                    bookmark: form.bookmark,
                    formName: form.formName,
                    formType: TaxFormType.TaxReturn,
                    signatureControls: [],
                    id: form.id,
                    pageNo: form.pageNo,
                };
                this.setState({
                    matchedForm: taxreturn
                });
            }
        }
    }


    private handleVoucherChange = (status: boolean, pageNumber: number) => {
        if (Boolean(Number(status))) {
            const forms = cloneDeep(this.state.matchedForm) as IVoucher;

            if (forms.pageNo[0] == pageNumber) {
                forms.voucherEnable = Boolean(Number(status));
                forms.signatureControls = [];
                forms.vocherMode = VoucherMode.Recognized;
                forms.formType = TaxFormType.Voucher;
            }
            this.setState({
                matchedForm: forms
            });
        } else if (this.state.matchedForm.signatureEnable) {
            const form = cloneDeep(this.state.matchedForm) as IEFile;

            const efile: IEFile = {
                signatureEnable: true,
                voucherEnable: false,
                authorityID: form.authorityID,
                bookmark: form.bookmark,
                formName: form.formName,
                formType: TaxFormType.EFile,
                signatureControls: [],
                id: form.id,
                pageNo: form.pageNo,
            };
            this.setState({
                matchedForm: efile
            });
        } else {
            const form = cloneDeep(this.state.matchedForm) as ITaxReturnGroup;
            const taxreturn: ITaxReturnGroup = {
                signatureEnable: false,
                voucherEnable: false,
                bookmark: form.bookmark,
                formName: form.formName,
                formType: TaxFormType.TaxReturn,
                signatureControls: [],
                id: form.id,
                pageNo: form.pageNo,
            };
            this.setState({
                matchedForm: taxreturn
            });
        }
    }

    private onEditFilingForm = (page: number) => {
        if (!!page) {
            const group = this.getDocumentGroup(page);
            let tempGroup = cloneDeep(this.props.taxReturn.formGroups);
            let tempFile = tempGroup.find(x => x.group == group)?.forms.find(y => y.pageNo[0] === page) as IGroupFormBase;
            tempFile.signatureEnable = group === DocumentGroups.EFile ? true
                : group === DocumentGroups.Vouchers ? tempFile.signatureEnable : false;
            tempFile.voucherEnable = group === DocumentGroups.Vouchers ? true : false;

            if (group == DocumentGroups.TaxReturns) {
                this.setState({
                    signatureExpand: tempFile.signatureEnable,
                    voucherExpand: tempFile.voucherEnable
                });
            }

            this.setState({
                currentPage: page,
                selectedGroup: group,
                matchedForm: tempFile,
                currentVoucherClone: cloneDeep(tempFile) as IVoucher
            });
        }
    }

    private onPageDelete = (page: number) => {
        this.props.deletePages([page]);
    }

    private signerChange = (selectedOption: ISigner) => {
        if (selectedOption) {

            this.setState({
                selectedSigner: {
                    value: selectedOption.value,
                    label: selectedOption.label,
                    signatureRole: selectedOption.signatureRole
                }
            })
        }
    }

    private onSignatureControlFocus = (focusedGroup: SignatureGroupType) => {
        this.setState({
            focusedGroup: focusedGroup
        });
    }

    private getSignatureControls = (): PdfPageSignatureControls => {
        let signatureControls: ISignatureControl[] = [];
        let signatureType: any = this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType;
        signatureType = (typeof signatureType == "number") ? SignatureType[signatureType] : signatureType;

        signatureControls = this.filterSignatureControls(this.props.signatureControls[this.state.currentPage], signatureType);

        return {
            signatureControls: signatureControls,
            eroSigner: { id: this.state.selectedEROSigner.value, signPath: this.state.selectedEROSigner.eroImage },
            signer: { id: this.state.selectedSigner ? this.state.selectedSigner.value : 0, role: this.state.selectedSigner ? this.state.selectedSigner.signatureRole : SignatureControlRole.None },
            focusedGroup: this.state.focusedGroup
        } as PdfPageSignatureControls;
    }

    private filterSignatureControls = (signatureControls: ISignatureControl[], signatureType: any):
        ISignatureControl[] => {
        return GetSignatureControl(
            signatureControls,
            signatureType,
            this.props.userSettings);
    }

    private getEroSignatureControls = (): PdfPageSignatureControls => {
        let signatureControls: ISignatureControl[] = [];
        let signatureType: any = this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType;
        signatureType = (typeof signatureType == "number") ? SignatureType[signatureType] : signatureType;

        signatureControls = this.filterSignatureControls(this.props.signatureControls[this.state.currentPage], signatureType);

        return {
            signatureControls: signatureControls,
            eroSigner: { id: this.state.selectedEROSigner.value, signPath: this.state.selectedEROSigner.eroImage },
            signer: { id: this.state.selectedSigner ? this.state.selectedSigner.value : 0, role: this.state.selectedSigner ? this.state.selectedSigner.signatureRole : SignatureControlRole.None },
            focusedGroup: this.state.focusedGroup
        } as PdfPageSignatureControls;
    }
    private getControls = (signatureType: any): ISignatureControl[] => {
        let signatureControls: ISignatureControl[] = [];
        const controlls: ISignatureControl[] = this.props.signatureControls[this.state.currentPage] ?? [];
        signatureControls = this.filterSignatureControls(controlls, signatureType);
        return signatureControls;
    }

    private dragStartSignatureControl = (event: any, controlType: SignatureControlTypes, signatureRole: SignatureControlRole) => {
        try {
            logger.trackTrace(`dragStartSignatureControl started for document: ${this.props.taxReturn.documentGuid}, SignatureControlTypes : ${controlType} and SignatureControlRole : ${signatureRole}`);
            dragStartSignatureControl(
                event, controlType, signatureRole,
                this.state.selectedEROSigner.label,
                this.state.currentPage,
                this.state.scale,
                this.addSignatureControl)
            logger.trackTrace(`dragStartSignatureControl ended for document: ${this.props.taxReturn.documentGuid}, SignatureControlTypes : ${controlType} and SignatureControlRole : ${signatureRole}`);
        }
        catch (error) {
            logger.trackError(`SignatureControlDragandDropError: dragStartSignatureControl throw error for document: ${this.props.taxReturn.documentGuid}, SignatureControlTypes : ${controlType} and SignatureControlRole : ${signatureRole}, error: ${error}`);
        }
    }

    private dragEnd = (event: any) => {
        try {
            logger.trackTrace(`dragEnd started for document: ${this.props.taxReturn.documentGuid}`);
            let element = document.getElementById("dragging-element");
            if (element) {
                element.remove();
            }
            logger.trackTrace(`dragEnd ended for document: ${this.props.taxReturn.documentGuid}`);
        }
        catch (error) {
            logger.trackError(`SignatureControlDragandDropError: dragEnd throw error for document: ${this.props.taxReturn.documentGuid}, error : ${error}`);
        }
    }

    private addSignatureControl = (type: SignatureControlTypes, role: SignatureControlRole, left: number, top: number) => {
        try {
            logger.trackTrace(`addSignatureControl started for document: ${this.props.taxReturn.documentGuid} , SignatureControlTypes: ${type}, SignatureControlRole: ${role}, clientX : ${left}, clientY : ${top}`);
            let _self = this;
            const { currentPage } = this.state;

            let control: TaxDocument.IEfileDocumentControl = {} as TaxDocument.IEfileDocumentControl;
            control.controlGuid = Guid.newGuid().toString();
            control.left = left;
            control.top = top;
            control.type = type;
            control.signatureControlRole = role;
            control.required = true;
            control.tooltip = "";
            _self.props.addSignatureControl(control, currentPage);

            if (control.type == TaxDocument.SignatureControlTypes.Text) {
                this.setState({
                    toolTipControl: control,
                    showToolTipPopup: true
                });
            }

            logClientEvent(
                DocumentEventLog.SignatureControlAdded,
                {
                    Count: 1,
                    CustomEventType: DocumentEventLog.Name,
                    DocumentGuid: this.props.taxReturn.documentGuid,
                    PageNumber: currentPage,
                    SignatureControlTypes: type,
                    SignatureControlRole: role
                });

            logger.trackTrace(`addSignatureControl ended for document: ${this.props.taxReturn.documentGuid}, SignatureControlTypes: ${type}, SignatureControlRole: ${role}, clientX : ${left}, clientY : ${top}`);
        }
        catch (error) {
            logger.trackError(`SignatureControlDragandDropError: addSignatureControl throw error for document: ${this.props.taxReturn.documentGuid}, SignatureControlTypes: ${type}, SignatureControlRole: ${role}, clientX : ${left}, clientY : ${top}, error : ${error}`);
        }
    }

    private adjustSamePositionControl = (control: ISignatureControl): ISignatureControl => {
        const signatureControls = this.props.signatureControls[this.state.currentPage];
        const index = signatureControls.findIndex(x => x.controlGuid === control.controlGuid);
        if (index !== NO_INDEX) {
            control.left = control.left + 5;
            control.top = control.top + 5;
            return this.adjustSamePositionControl(control);
        }
        else {
            return control;
        }
    }

    private onControlRemove = (control: ISignatureControl) => {
        this.props.removeSignatureControl(control, this.state.currentPage);
    }

    private onControlUpdate = (oldControl: ISignatureControl, newControl: ISignatureControl) => {
        this.props.replaceSignatureControl(oldControl, newControl, this.state.currentPage);
    }
};

//=============================================================================