import React from  'react';
import * as BootStrap from 'react-bootstrap';
import * as bootbox from 'bootbox';
import { FormLabel, Popover } from 'react-bootstrap';
import { ICustomColumn, IColumnValues } from '../GeneralSettings';
import { EditColumnValue } from '../GeneralSettingsComponents/EditColumnValue';
import { VenusNotifier } from '../../helper/VenusNotifier';
import * as Constants from '../../helper/Constants';
import { OverlayLoader } from '../../helper/OverlayLoader';
import { RBACKeys } from 'src/components/helper/RbacConstants';

interface ICustomizeDeliveryReportsProps {
    overlayLoading: boolean;
    overlayMessage: string;
    customColumn: ICustomColumn;
    HandleCustomizeDeliveredreportsEditButton: (event: any) => void;
    updateCustomColumn(customColumn: ICustomColumn): void;
    updateCustomColumnValue(customColumnValue: IColumnValues): void;
    addCustomColumnValue(customColumnValue: IColumnValues): void;
    deleteCustomColumnValue(customColumnValue: IColumnValues): void;
    deleteCustomColumnHeader: (customColumn: ICustomColumn) => void;
}

interface ICustomizeDeliveredReportsState {
    disableUpdateButton: boolean;
    disableAddButton: boolean;
    intialCustomColumnHeader: string;
    selectedId: number;
    selectedValue: string;
    showEditCustomColumnValuePopup: boolean;
    customColumnValue: IColumnValues;
    newCustomColumnValue: IColumnValues;
}

export const initialCustomColumnValues: IColumnValues = {
    customColumn: 0,
    id: 0,
    inUseCount: 0,
    value: "",
}

export class CustomizeDeliveryReports extends React.Component<ICustomizeDeliveryReportsProps, ICustomizeDeliveredReportsState>{
    constructor(props:any) {
        super(props);
        this.state = {
            disableUpdateButton: true,
            disableAddButton: true,
            intialCustomColumnHeader: "",
            selectedId: 0,
            selectedValue: "",
            showEditCustomColumnValuePopup: false,
            customColumnValue: initialCustomColumnValues,
            newCustomColumnValue: initialCustomColumnValues
        }

        ///////////////////////// Custom column name functions//////////////////
        this.onCustomColumnNameChange = this.onCustomColumnNameChange.bind(this);
        this.updateCustomColumnName = this.updateCustomColumnName.bind(this);

        ///////////////////////// Add Custom column value  functions//////////////////
        this.onNewCustomColumnValueChange = this.onNewCustomColumnValueChange.bind(this);
        this.addCustomColumnValue = this.addCustomColumnValue.bind(this);

        ///////////////////////// Edit Custom column value  functions//////////////////
        this.openCustomColumnValueEditPopup = this.openCustomColumnValueEditPopup.bind(this);
        this.onNewColumnValueChange = this.onNewColumnValueChange.bind(this);
        this.updateCustomColumnValue = this.updateCustomColumnValue.bind(this);
        this.onCancelEditColumnValuePopup = this.onCancelEditColumnValuePopup.bind(this);

        ///////////////////////// Delete Custom column value  functions//////////////////
        this.deleteCustomColumnValue = this.deleteCustomColumnValue.bind(this);
        this.proceedToDeleteColumnValue = this.proceedToDeleteColumnValue.bind(this);
    }

    componentDidMount() {
        this.setState({
            intialCustomColumnHeader: this.props.customColumn.header,
            newCustomColumnValue: {
                customColumn: 0,
                id: 0,
                inUseCount: 0,
                value: "",
            },
        })
    }

    componentWillReceiveProps() {
        this.setState({
            newCustomColumnValue: {
                customColumn: 0,
                id: 0,
                inUseCount: 0,
                value: "",
            },
            intialCustomColumnHeader: this.props.customColumn.header,
            disableUpdateButton: true,
            disableAddButton: true,
        })
    }

    ///////////////////////// Custom column name start//////////////////
    onCustomColumnNameChange(event: any) {
        let temp = this.props.customColumn;
        temp.header = event.target.value;
        this.setState({
            disableUpdateButton: ((this.state.intialCustomColumnHeader === temp.header && temp.id != 0) || temp.header.trim() === "") ? true : false,
        })
    }

    updateCustomColumnName() {
        if (this.props.customColumn.header.length > 20) {
            VenusNotifier.Warning(Constants.CustomColumnConstants.ValidationMessages.CustomColumnLength, null);
        } else {
            this.props.updateCustomColumn(this.props.customColumn);
        }
    }

    deleteCustomColumnHeader = () => {
        bootbox.confirm({
            message: Constants.CustomColumnConstants.WarningMessage.DeletingCustomColumnHeader,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> No',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Yes',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    this.props.deleteCustomColumnHeader(this.props.customColumn);
                }
            }
        });
    }
    ///////////////////////// Custom column name end//////////////////

    ///////////////////////// Add Custom column value  start//////////////////
    onNewCustomColumnValueChange(event: any) {
        let temp = this.state.newCustomColumnValue;
        temp.customColumn = this.props.customColumn.id;
        temp.value = event.target.value;
        this.setState({
            newCustomColumnValue: temp,
            disableAddButton: (event.target.value.trim() != "" || temp.value.trim() != "") ? false : true
        })
    }

    addCustomColumnValue() {
        if (this.props.customColumn.header.length === 0) {
            VenusNotifier.Warning(Constants.CustomColumnConstants.ValidationMessages.CustomColumnAddValidation, null);
        }
        else if (this.props.customColumn.id === 0) {
            VenusNotifier.Warning(Constants.CustomColumnConstants.ValidationMessages.CustomColumnSaveValidation, null);
        }
        else if (this.state.newCustomColumnValue.value.length >100) {
            VenusNotifier.Warning(Constants.CustomColumnConstants.StatusMessage.CustomColumnValueLength, null);
        }
        else {
            if (this.ColumnValueAlreadyExistsValidation(this.state.newCustomColumnValue.value)) {
                this.props.addCustomColumnValue(this.state.newCustomColumnValue);
            }
        }
    }
    ///////////////////////// Add Custom column value end//////////////////

    ///////////////////////// Delete Custom column value  start//////////////////
    deleteCustomColumnValue(customColumnValue: IColumnValues) {
        this.setState({
            customColumnValue: customColumnValue
        })
    }

    proceedToDeleteColumnValue() {
        this.props.deleteCustomColumnValue(this.state.customColumnValue)
        document.body.click();
    }
    ///////////////////////// Delete Custom column value end//////////////////

    ///////////////////////// Edit Custom column value  start//////////////////
    openCustomColumnValueEditPopup(customColumnValue: IColumnValues) {
        this.setState({
            showEditCustomColumnValuePopup: true,
            selectedValue: customColumnValue.value,
            customColumnValue: {
                customColumn: customColumnValue.customColumn,
                id: customColumnValue.id,
                inUseCount: customColumnValue.inUseCount,
                value: "",
            }
        })
    }

    onCancelEditColumnValuePopup() {
        this.setState({
            showEditCustomColumnValuePopup: false,
        })
    }

    onNewColumnValueChange(event: any) {
        let temp = this.state.customColumnValue;
        temp.value = event.target.value;
        this.setState({ customColumnValue: temp })
    }

    updateCustomColumnValue() {
        if (this.state.customColumnValue.value.trim() == "") {
            VenusNotifier.Warning(Constants.CustomColumnConstants.ValidationMessages.CustomColumnValueWarning, null);
            return false;
        }
        if (this.ColumnValueAlreadyExistsValidation(this.state.customColumnValue.value)) {
            this.props.updateCustomColumnValue(this.state.customColumnValue);
            this.setState({ showEditCustomColumnValuePopup: false })
        }
    }
    ///////////////////////// Edit Custom column value end//////////////////

    public closePopHover() {
        document.body.click();
    }

    ColumnValueAlreadyExistsValidation(columnValue: string) {
        let isValid: boolean = true;
        this.props.customColumn?.customColumnValues?.map((list, index) => {
            if (list.value == columnValue.trim()) {
                VenusNotifier.Warning(Constants.CustomColumnConstants.ValidationMessages.CustomColumnValueAlreadyExist, null);
                isValid = false;
            }
        });
        return isValid;
    }
    public render() {
        const hideDeleteBtn = {
            display: "none"
        }
        const deleteBtnStyle = {
            paddingTop: "6px",
            paddingBottom: "6px"
        }
        const displayDeleteBtn = this.props.customColumn.id > 0 ? deleteBtnStyle : hideDeleteBtn;

        const popover = (
            <Popover id="popover-basic" title="Are you sure?">
                <Popover.Title as="h3" style={{ fontSize: '14px' }}>{`Are you sure?`}</Popover.Title>
                <Popover.Content>
                    <button
                        className="btn btn-only btn-success"
                        onClick={this.proceedToDeleteColumnValue}
                        title="Yes"
                        data-test-auto="2813EEBF-E0EF-47A2-8441-4C3C6129EF69"
                        style={{ marginLeft: "5px" }}>
                        Yes
                    </button>
                    <button
                        onClick={this.closePopHover}
                        title="No"
                        data-test-auto="1C4AA039-5A68-4864-AC82-FC0CF21E7BFA"
                        className="btn btn-only btn-danger"
                        style={{ marginLeft: "5px" }}>
                            No
                    </button>
                </Popover.Content>
            </Popover>
        );

        return <div className="row new-main-content">
            {//<OverlayLoader show={this.props.overlayLoading} text={this.props.overlayMessage} />
            }
            <div className="col-lg-6">
                <h3>Customize Delivered Report</h3>
            </div>
            <div className="col-lg-12">
                <div className="marL20">
                    <div className="row">
                        <div className="col-lg-1" style={{ paddingBottom: "20px", display: "flex", marginRight:"5%" }}>
                            <div style={{ paddingTop: "12px" }}>
                                <FormLabel>Column Name: </FormLabel>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div style={{ paddingTop: "5px", paddingBottom: '5px' }}>
                                <input className="form-control input-sm"
                                    type="text"
                                    value={this.props.customColumn.header}
                                    onChange={this.onCustomColumnNameChange}
                                    data-test-auto="1400D443-287E-44F2-9D3D-90BE6F5B08A7">
                                </input>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div style={{ paddingTop: "5px", paddingBottom: '5px' }}>
                                <button
                                    data-resource-id={RBACKeys.Settings.settings}
                                    disabled={this.state.disableUpdateButton}
                                    onClick={this.updateCustomColumnName}
                                    title="Update"
                                    data-test-auto="FFECCAD1-6707-4910-9564-F2F2214C2709"
                                    className="btn btn-sm btn-white"
                                    style={{ paddingTop: "6px", paddingBottom: "6px" }}>
                                    <i className="fas fa-edit" style={{ paddingTop: "6px", paddingBottom: "6px" }}></i>
                                    Update
                                </button>
                                <button
                                    data-resource-id={RBACKeys.Settings.settings}
                                    onClick={this.deleteCustomColumnHeader}
                                    title="Delete"
                                    className="btn btn-sm btn-white"
                                    style={displayDeleteBtn}>
                                    <i className="fa fa-trash-alt text-danger" style={{ paddingTop: "6px", paddingBottom: "6px" }}></i>
                                    Delete
                                </button>
                                <a href="javascript:" className="help-icon navbar-link" style={{ marginLeft: "25px", verticalAlign: "middle" }}
                                    data-toggle="tooltip" data-placement="right" title="Enter a name for the column header.  You are limited to 20 characters that will display in the header.  The entire name will display in the tooltip. ">
                                    <i className="fas fa-question-circle" style={{ marginLeft: "-20px" }}></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="marL20">

                    <div className="row" style={{ marginRight: "-25px" }}>
                        <b><FormLabel style={{ fontSize: "15px" }}>Values</FormLabel></b>
                    </div>
                </div>
                <hr className="marT0" />
            </div>
            <div className="col-lg-12">
                <div className="marL20">
                    <div className="row">
                        <div className="col-lg-1" style={{ paddingTop: "12px", marginRight: "5%" }}>
                            <FormLabel>Column Value: </FormLabel>
                        </div>
                        <div className="col-lg-3" style={{ paddingTop: "5px", paddingBottom: '5px' }}>
                            <input className="form-control input-sm"
                                type="text"
                                value={this.state.newCustomColumnValue.value}
                                placeholder="Column Value"
                                onChange={this.onNewCustomColumnValueChange}
                                data-test-auto="E1581D86-39E6-4EDF-8964-99F2A6F78EF1">
                            </input>
                        </div>
                        <div className="col-lg-3" style={{ paddingTop: "5px", paddingBottom: '5px' }}>
                            <button
                                data-resource-id={RBACKeys.Settings.settings}
                                style={{ padding: "8px 12px" }}
                                onClick={this.addCustomColumnValue}
                                title="Add"
                                data-test-auto="FFECCAD1-6707-4910-9564-F2F2214C2709"
                                disabled={this.state.disableAddButton}
                                className="btn btn-success btn-lg">
                                <span className="fa fa-plus"></span>
                            </button>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-lg-6">
                            <div className="list-group" style={{
                                height: "150px", overflowY: "scroll",
                                borderBottom: "#ebebec 1px solid", borderTop: "#ebebec 1px solid"
                            }}>
                                <div>
                                    <OverlayLoader show={this.props.overlayLoading} text={this.props.overlayMessage} />
                                    {
                                        this.props.customColumn?.customColumnValues?.map((value, index) => {
                                            return <li className="list-group-item" key={index}>
                                                {value.value}
                                                <a href='javascript:' className="list-group-item-action navbar-link" style={{ pointerEvents: value.inUseCount > 0 ? "none" : "auto" }}>
                                                    <BootStrap.OverlayTrigger rootClose trigger="click" placement="right" overlay={popover} >
                                                        <span className={value.inUseCount > 0 ? "fas fa-trash-alt text-muted pull-right"
                                                            : "fas fa-trash-alt text-danger pull-right"}
                                                            style={{ marginLeft: "5px" }}
                                                            // disabled={value.inUseCount > 0 ? true : false}
                                                            onClick={() => { this.deleteCustomColumnValue(value) }} data-resource-id={RBACKeys.Settings.settings}></span>
                                                    </BootStrap.OverlayTrigger>
                                                </a>
                                                <a href='javascript:' className="list-group-item-action navbar-link">
                                                    <span className="fas fa-edit pull-right" style={{ marginLeft: "15px" }}
                                                        onClick={() => { this.openCustomColumnValueEditPopup(value) }}
                                                        data-resource-id={RBACKeys.Settings.settings}></span>
                                                </a>
                                                <span className="badge pull-right">{value.inUseCount > 0 ? value.inUseCount : "unused"}</span>
                                            </li>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                <EditColumnValue
                    showState={this.state.showEditCustomColumnValuePopup}
                    onHide={this.onCancelEditColumnValuePopup}
                    onNewColumnValueChange={this.onNewColumnValueChange}
                    onSaveButtonClick={this.updateCustomColumnValue}
                    columnValues={this.state.customColumnValue}
                    selectListItem={this.state.selectedValue}
                />
            }
        </div>
    }
}

export default CustomizeDeliveryReports;