import * as React from 'react';
import moment from 'moment';
import * as ReactDOM from 'react-dom';
import MaskedInput from "react-text-mask"
// Import the default style
import 'rc-datepicker/lib/style.css';

import { DatePicker, DatePickerInput } from 'rc-datepicker';

const isEqual = require("react-fast-compare");

export interface CustomDatePickerProps {
    value?: Date;
    onChange?: (date?: any) => void;
    disabled?: boolean;
    calendarContainer?: any;
    calendarPlacement?: string;
    // todo: Minimum and maximum date validation is not working while entering manually
    minDate?: Date;
    maxDate?: Date;
    clearFilter?: any;
    customClassName?: string;
    sideMargin?: boolean;
    isPrefilled?: boolean;
}

export interface CustomDatePickerState {
    value: Date | string | undefined;
    inputDate: string;
    calendarState: boolean;
    cachedPrevProp: CustomDatePickerProps;

}

const DATE_FORMAT: string = "MM/DD/YYYY";

export class CustomDatePicker extends React.Component<CustomDatePickerProps, CustomDatePickerState> {


    constructor(props: any) {
        super(props);

        this.state = {
            value: undefined,
            inputDate: "",
            calendarState: false,
            cachedPrevProp: {} as CustomDatePickerProps
        }


        this.calendarRender = this.calendarRender.bind(this);
        this.onChangeCustom = this.onChangeCustom.bind(this);
        this.changeInput = this.changeInput.bind(this);
        this.clearDate = this.clearDate.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.clearDateAsync = this.clearDateAsync.bind(this);

    }

    static getDerivedStateFromProps(nextProps: CustomDatePickerProps, prevState: CustomDatePickerState) {
        const date = nextProps.value && moment(nextProps.value);
        const value = nextProps.value && date && date.isValid() ? date.format('YYYY-MM-DD[T]HH:mm:ss.SSS') : undefined;
        let tempdate = value !== undefined ? moment(value).format("MM/DD/YYYY") : "";
        let prevProp = prevState.cachedPrevProp;

        if (!isEqual(prevProp, nextProps)) {
            return {
                value: value != prevState.value ? value : prevState.value,
                inputDate: value != prevState.value ? tempdate : prevState.inputDate,
                cachedPrevProp: nextProps
            }
        }

        return null;
    }

    private handleChange = (value: any, formatedValue: any) => {
        this.setState({ value: value }, () => {
            if (this.props.onChange) {
                this.props.onChange(value && new Date(value));
            }
        });
    }

    changeInput(e: React.ChangeEvent<HTMLInputElement>) {
        let dateVal = e.target.value;

        if (!this.state.calendarState) {
            this.setState({ calendarState: true });
        }

        if (dateVal.length === 10 && moment(dateVal).isValid()) {
            dateVal = dateVal.replace(/\//g, '-');

            let formattedDate = moment(dateVal,"MM-DD-YYYY").format("MM/DD/YYYY");

            this.setState({ value: formattedDate, inputDate: dateVal, calendarState: false }, () => {
                if (this.props.onChange) {
                    this.props.onChange(formattedDate);

                }
            });
		}
		else {
			this.setState({ inputDate: dateVal });
        }


    }


    onChangeCustom(date: Date) {
        let formattedDate = moment(date.toString()).format("MM/DD/YYYY");

        date.setHours(0, 0, 0, 0);
        this.setState({ value: date, inputDate: formattedDate, calendarState: false }, () => {
            if (this.props.onChange) {
                this.props.onChange(formattedDate);
            }
        });

    };

    clearDate() {
        this.setState({ value: undefined, inputDate: "" },
            () => {
                this.props.clearFilter ? this.props.clearFilter() : "";
                if (this.props.onChange) {
                    this.props.onChange(undefined);
                }
            });
    }

    clearDateAsync() {
        setTimeout(this.clearDate, 0);
    }

    handleBlur() {

        this.setState({ calendarState: false });

    }


    private calendarRender = (DatePickerComp: any) => {
        let MaskComp = MaskedInput as any;
        return (<div className={(this.props.disabled && this.props.customClassName) || (this.props.isPrefilled && this.props.customClassName) ? "calendarContainer " + this.props.customClassName
                : "calendarContainer"} onBlur={this.handleBlur}>
            <div className="calendarClass">
                <MaskComp
                    mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                    placeholder={DATE_FORMAT}
                    type="text"
                    value={this.state.inputDate}
                    onClick={() => {
                        this.setState({ calendarState: true })
                    }}
                    className={(this.props.disabled && this.props.customClassName) || (this.props.isPrefilled && this.props.customClassName) ? "inputClass form-control height34p" + this.props.customClassName : "inputClass form-control height34p"}
                    onKeyUp={null}
                    onChange={this.changeInput}
                />
                <span
                    className="minusClass"
                    onClick={this.clearDate}
                >
                    x
          </span>
            </div>
            {this.state.calendarState ? (
                <div className="calendarInput" id="dateId"
                    onMouseDown={(e) => {
                        e.preventDefault();
                    }}
					style={this.props.sideMargin !== undefined ? { left: "-4px" } : {}}
				    >
                    <DatePickerComp
                        value={this.state.value}
                        disabled={this.props.disabled}
                        onChange={this.onChangeCustom}
                        className='group-input dateClass'
                        minDate={this.props.minDate}
                        maxDate={this.props.maxDate}
                    />
                </div>
            ) : (
                ""
            )}
        </div>)

    }



    public render() {

        const DatePickerPart = DatePicker as any;
        return (

            <div className='control-pane' >
                <div className='control-section'>
                    <div className='datepicker-control-section' id="datepicker_input">
                        {this.calendarRender(DatePickerPart)}
                    </div>
                </div>
            </div>
        );
    }
}


export default CustomDatePicker;
