import React from  'react';
import Scrollbars from 'react-custom-scrollbars';
import { LoadingOverlay, Loader } from 'react-overlay-loader';

interface TabContentContainerMiddlePanelProps {
    isContentEmpty?: boolean;
    onAction?: any;
    disableScroll?: boolean;
}

interface TabContentContainerProps {
    isBusy?: boolean;
    enableScroll?: boolean;
}

interface TabContentContainerRightPanelProps {
    disableScroll?: boolean;
    children?: any;
}

export class TabContentContainer extends React.Component<TabContentContainerProps, {}> {

    constructor(props: TabContentContainerProps) {
        super(props);
    }

    private createMainContent = () => {
        if (this.props.enableScroll) {
            return (<Scrollbars renderTrackHorizontal={this.renderTrackHorizontal}>
                {this.props.children}
            </Scrollbars>);
        }
        else {
            return (this.props.children);
        }
    }

    renderTrackHorizontal() {
        return <div style={{ display: 'none' }}  />
    
    }

    public render() {
        return (<LoadingOverlay style={{ height: "100%" }}>
            <div className="tab-content-container row h100">
                {this.createMainContent()}
            </div>
            <Loader loading={this.props.isBusy} />
        </LoadingOverlay>);
    }
}

export class TabContentContainerLeftPanel extends React.Component<{}, {}> {

    constructor(props:any) {
        super(props);
    }

    public render() {
        return (<div className="tab-content-container-left-panel col-lg-2 col-sm-3 col-xs-12 h100">
            <div className={"pr-right-pannel"}>
                <div className={"scroll"}>
                    <Scrollbars>
                        {this.props.children}
                    </Scrollbars>
                </div>
            </div>
        </div>);
    }
}

export class TabContentContainerMiddlePanel extends React.Component<TabContentContainerMiddlePanelProps, {}> {

    constructor(props: TabContentContainerMiddlePanelProps) {
        super(props);
    }

    private createContent = () => {
        if (this.props.isContentEmpty) {
            return (<div className="tab-content-container-middle-panel-no-content col-lg-7 col-sm-6 col-xs-12 h100">
                <div className="clearfix tab-no-content-message">
                    <h1 className="float-left">No forms found !!</h1>
                </div>
            </div>);
        }
        else {


            if (this.props.disableScroll) {
                return (<div className="tab-content-container-middle-panel col-lg-7 col-sm-6 col-xs-12 h100">
                    <div className={"pr-center-pannel"}>
                        <div className={"scroll"} style={{ overflow: "hidden" }}>
                                {this.props.children}
                        </div>
                    </div>
                </div>);
            }
            else {
                return (<div className="tab-content-container-middle-panel col-lg-7 col-sm-6 col-xs-12 h100">
                    <div className={"pr-center-pannel"}>
                        <div className={"scroll"}>
                            <Scrollbars>
                                {this.props.children}
                            </Scrollbars>
                        </div>
                    </div>
                </div>);
            }         
        }
    }

    public render() {
        return this.createContent();
    }
}

export class TabContentContainerRightPanel extends React.Component<TabContentContainerRightPanelProps, {}> {

    constructor(props: TabContentContainerRightPanelProps) {
        super(props);
    }

    public render() {
        return (<div className="tab-content-container-right-panel col-lg-3 col-sm-3 col-xs-12 h100">
            <div className={"pr-left-pannel"}>
                <div className={"scroll"}>
                    <Scrollbars>
                        {this.props.children}
                    </Scrollbars>
                </div>
            </div>
        </div>);
    }
}
