import React from 'react';
import { IPdfDocumentFacade } from '../../../../Core/Utilities/PdfDocumentFacade';
import * as TaxDocument from '../../../common/TaxReturn';
import { TaxpayerSidebar } from '../RightSidebar/TaxpayerSidebar';
import { PartnershipSidebar } from '../RightSidebar/PartnershipSidebar';
import { ClientInfoProperty } from '../../ClientInfoEnum';
import { IFormGroupViewProps, IFormGroupViewState } from '../ProcessReturnModels';
import { PdfPage } from '../PdfPage';
import { BookmarksPane } from '../BookmarksPane';
import {
    DocumentGroups, IEFile, SignatureControlRole, initailTaxpayerModel, ITaxReturn, ITaxingAuthority, TaxFormType, IPrefilledClientInfo
} from '../../../common/TaxReturn';
import { Guid } from 'guid-typescript';
import * as TaxRetrun from '../../TaxReturn';
import { IUserModel } from 'src/Core/ViewModels/User/UserViewModel';
import { INVALID_PAGE } from '../CustomPagination/CustomPagination';
import { ITaxpayer } from '../../../common/TaxClient';
import { ICountryState } from '../../../../Core/ViewModels/Company/CompanyViewModel';
import { ClientReturnInfo } from '../RightSidebar/ClientReturnInfo';
import { PdfProperties } from '../PdfViewer/PdfHelper';
import AwesomePDFViewer from '../PdfViewer/AwesomePDFViewer';
import { IDropdown } from 'src/Core/Common/Dropdown';

export interface ITabClientProps extends IFormGroupViewProps {
    allSignerList: IUserModel[],
    userList: IUserModel[],
    authorities: TaxRetrun.ITaxingAuthority[],
    getAllTaxingAuthorities: TaxRetrun.ITaxingAuthority[],
    states: ICountryState[],
    checkDeceased: (isMutual: boolean, taxpayerDeceased: boolean, spouseDeceased: boolean) => void
    getVoucherUploadLink: (taxReturn: ITaxReturn, callback: (data: any) => void) => void;
    requestEroSignaturePath: (userId: number) => void;
    isLoading?: boolean;
    prefilledClientInfo?: IPrefilledClientInfo;
    resetPrefillInfo?: (clientInfoProperty: ClientInfoProperty) => void;
    officeLocation:IDropdown[]
}


export interface ITabClientStates extends IFormGroupViewState {
    selectedEngagement: any,
    spouse: ITaxpayer,
    isJointReturn: boolean,
    clientId: string,
    partenID: string,
    taxYear: number,
    isClientRefundPaymentDueEnabled: boolean,
    expandTaxpayerPanel: boolean;
    expandSpousePanel: boolean;
    expandReturnInformation: boolean;
    expandRefundPaymentInformation: boolean;
    expandPartnershipPanel: boolean;
    selectedAuthority: number,

    amount: number,
    isPayment: boolean,
    isRefund: boolean,
    overPayAppliedAmount: number,
    netRefund: number,
    showPaymentDueAlert: boolean,
    paymentDueAlertMessage: string,
    enableUpdate: boolean,
    scale: number,
    locationId:number,
}

export enum ClientInfoRightSidePanelType {
    ReturnInformationPanel,
    TaxperInformationPanel,
    SpouseInformationPanel,
    PartnershipInformationPanel,
    RefundPaymentInfoPanel
}

interface SelectOptions {
    value: string,
    label: string,
}

const isEqual = require("react-fast-compare");
let dateMoment = require('moment');

export class TabClientInfo extends React.Component<ITabClientProps, ITabClientStates>{
    constructor(props: ITabClientProps) {
        super(props);
        this.state = {
            currentPage: INVALID_PAGE,
            actionPanelShow: true,
            zoomEnabled: false,
            isJointReturn: false,
            selectedEngagement: 0,
            spouse: { ...initailTaxpayerModel, clientGuid: Guid.create().toString() },
            clientId: "",
            partenID: "",
            taxYear: 0,
            isClientRefundPaymentDueEnabled: false,
            expandReturnInformation: true,
            expandRefundPaymentInformation: true,
            expandTaxpayerPanel: true,
            expandSpousePanel: true,
            expandPartnershipPanel: true,
            selectedAuthority: 0,
            amount: 0,
            isPayment: true,
            overPayAppliedAmount: 0,
            isRefund: false,
            netRefund: 0,
            showPaymentDueAlert: false,
            paymentDueAlertMessage: "",
            enableUpdate: false,
            scale: PdfProperties.DefaultScale,
            locationId:0,
        };
        let years = [];
        for (let i = 2014; i <= new Date().getFullYear(); i++) {
            years.push({ label: i.toString(), value: i });
        }

        this.page = {} as PdfPage;
        this.onZoom = this.onZoom.bind(this);
        this.onZoomRestore = this.onZoomRestore.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);

        this.handleClientFieldChange = this.handleClientFieldChange.bind(this);
    }

    public engagementChange = (selectedOption: any) => {
        this.setState({ selectedEngagement: selectedOption })
    }

    public onChangeMJFCheckbox = (event: any) => {
        this.setState({ isJointReturn: event.target.checked });
        if (event.currentTarget.checked == true) {
            let tempTaxReturn = { ...this.props.taxReturn } as TaxRetrun.IMarriedJointTaxReturn
            if (!tempTaxReturn.spouse) {
                tempTaxReturn.spouse = this.state.spouse;
            }
            let tempEvent = event.target.checked;
            this.props.updateTaxDocument(tempTaxReturn, () => {
                this.props.checkDeceased(tempEvent, tempTaxReturn.taxpayer.isDeceased, tempTaxReturn.spouse ? tempTaxReturn.spouse.isDeceased : false);
            });

        }
        else {
            let tempTaxReturn = { ...this.props.taxReturn } as TaxRetrun.IMarriedJointTaxReturn
            tempTaxReturn.spouse = undefined;

            let tempFormsGorup: TaxDocument.IMarriedJointTaxReturn = { ...this.props.taxReturn as TaxDocument.IMarriedJointTaxReturn };
            let formIndex = -1;
            formIndex = tempFormsGorup.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
            if (formIndex > -1) {
                let efileForms: IEFile[] = tempFormsGorup.formGroups[formIndex].forms as IEFile[];
                efileForms.map(efileForm => {
                    efileForm.signatureControls = efileForm.signatureControls.filter(x => x.signatureControlRole != SignatureControlRole.Spouse);
                })
                tempFormsGorup.formGroups[formIndex].forms = efileForms;
                tempFormsGorup.spouse = tempTaxReturn.spouse;
                this.props.updateTaxDocument(tempFormsGorup, () => {
                    this.props.checkDeceased(false, tempTaxReturn.taxpayer.isDeceased, tempTaxReturn.spouse ? tempTaxReturn.spouse.isDeceased : false);
                });

            }
            else {
                tempFormsGorup.spouse = tempTaxReturn.spouse;
                this.props.updateTaxDocument(tempFormsGorup, () => {
                    this.props.checkDeceased(false, tempTaxReturn.taxpayer.isDeceased, tempTaxReturn.spouse ? tempTaxReturn.spouse.isDeceased : false);
                });
            }
        }
    }

    private handleClientFieldChange = (item: TaxDocument.ClientReturn, value: any) => {
        let taxReturn: TaxRetrun.ITaxReturn = { ...this.props.taxReturn };
        taxReturn.isModified = true
        if (item === TaxDocument.ClientReturn.ClientId) {
            this.setState({ clientId: value });
            taxReturn.clientId = value;

        } else if (item === TaxDocument.ClientReturn.Signer) {
            let selectedSignerDetail = this.props.allSignerList.filter(x => x.id == parseInt(value))[0]
            this.setState({ partenID: value });
            taxReturn.partnerId = parseInt(value);
            taxReturn.partner.userId = parseInt(value);
            taxReturn.partner.firstName = selectedSignerDetail ? selectedSignerDetail.firstName : "";
            taxReturn.partner.lastName = selectedSignerDetail ? selectedSignerDetail.lastName : "";
            taxReturn.partner.email = selectedSignerDetail ? selectedSignerDetail.emailAddress : "";
            this.props.requestEroSignaturePath(taxReturn.partnerId);
        } else if (item === TaxDocument.ClientReturn.TaxYear) {
            this.setState({ taxYear: value as number });
            taxReturn.taxYear = value;
        }
        else if(item===TaxDocument.ClientReturn.OfficeLocation){            
            this.setState({locationId:value as number})
            taxReturn.location.locationId=value as number
            const locationIndex=this.props.officeLocation.findIndex(x=>x.value==value)
            taxReturn.location.locationName=this.props.officeLocation[locationIndex]?.name 
            this.props.taxReturn.documentSettings.deliverySettings.manualAddressId = value as number;        
        }
        this.props.updateTaxDocument(taxReturn);
    }

    public setBookmark = (authorityID: number, formName: string) => {
        var authorities: ITaxingAuthority[] = this.props.getAllTaxingAuthorities;
        for (let i in authorities) {
            if (authorities[i].Id === authorityID) {
                return authorities[i].AuthorityName + ' ' + formName;
            }
        }
        return formName;
    }

    private page: PdfPage;

    componentWillMount() {
        this.setState({
            clientId: this.props.taxReturn.clientId,
            partenID: this.props.taxReturn.partnerId?.toString(),
            taxYear: this.props.taxReturn.taxYear
        });
    }
    componentDidMount() {
        this.setState({
            isJointReturn: TaxRetrun.isMutual(this.props.taxReturn),
            currentPage: this.props.pages[0]
        });
        if (TaxRetrun.isMutual(this.props.taxReturn)) {
            this.setState({ spouse: this.props.taxReturn.spouse }, () => {
                var mutualTaxreturn = this.props.taxReturn as TaxRetrun.IMarriedJointTaxReturn;
                this.props.checkDeceased(true, mutualTaxreturn.taxpayer.isDeceased, mutualTaxreturn.spouse.isDeceased);
            });
        }
    }

    componentWillReceiveProps(nextProps: ITabClientProps) {
        if (nextProps.pages && nextProps.pages.length != this.props.pages.length) {
            this.onPageSelect(nextProps.pages[0]);
        }
        this.setState({partenID : nextProps.taxReturn.partnerId?.toString()} )
    }

    shouldComponentUpdate(nextProps: ITabClientProps, nextState: ITabClientStates) {
        return ((nextProps.pages !== this.props.pages) ||
            (this.state.currentPage === INVALID_PAGE) ||
            (!isEqual(this.state, nextState)));
    }

    onPanelToggle = (panelType: ClientInfoRightSidePanelType) => {
        switch (panelType) {
            case ClientInfoRightSidePanelType.ReturnInformationPanel:
                this.setState({
                    expandReturnInformation: !this.state.expandReturnInformation,
                });
                break;
            case ClientInfoRightSidePanelType.TaxperInformationPanel:
                this.setState({
                    expandTaxpayerPanel: !this.state.expandTaxpayerPanel
                });
                break;
            case ClientInfoRightSidePanelType.SpouseInformationPanel:
                this.setState({
                    expandSpousePanel: !this.state.expandSpousePanel
                });
                break;
            case ClientInfoRightSidePanelType.PartnershipInformationPanel:
                this.setState({
                    expandPartnershipPanel: !this.state.expandPartnershipPanel
                });
                break;
            case ClientInfoRightSidePanelType.RefundPaymentInfoPanel:
                this.setState({
                    expandRefundPaymentInformation: !this.state.expandRefundPaymentInformation
                });
                break;
        }

    }

    getPrefilledClassName = (clientInfoProperty: ClientInfoProperty): string => {
        let isPrefilled = false;
        if (this.props.prefilledClientInfo) {
            switch (clientInfoProperty) {
                case ClientInfoProperty.partnerName:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.partnerName];
                    break;
                case ClientInfoProperty.partnerEin:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.partnerEin];
                    break;
                case ClientInfoProperty.partnerEmail:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.partnerEmail];
                    break;
                case ClientInfoProperty.partnerCountryCode:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.partnerCountryCode];
                    break;
                case ClientInfoProperty.partnerMobileNumber:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.partnerMobileNumber];
                    break;
                case ClientInfoProperty.taxpayerName:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.taxpayerName];
                    break;
                case ClientInfoProperty.taxpayerDateOfBirth:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.taxpayerDateOfBirth];
                    break;
                case ClientInfoProperty.taxpayerEmail:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.taxpayerEmail];
                    break;
                case ClientInfoProperty.taxpayerSSN:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.taxpayerSSN];
                    break;
                case ClientInfoProperty.taxpayerCountryCode:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.taxpayerCountryCode];
                    break;
                case ClientInfoProperty.taxpayerMobileNumber:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.taxpayerMobileNumber];
                    break;
                case ClientInfoProperty.spouseName:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.spouseName];
                    break;
                case ClientInfoProperty.spouseDateOfBirth:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.spouseDateOfBirth];
                    break;
                case ClientInfoProperty.spouseEmail:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.spouseEmail];
                    break;
                case ClientInfoProperty.spouseCountryCode:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.spouseCountryCode];
                    break;
                case ClientInfoProperty.spouseMobileNumber:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.spouseMobileNumber];
                    break;
                case ClientInfoProperty.spouseSSN:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.spouseSSN];
                    break;
                case ClientInfoProperty.eroPartnerId:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.eroPartnerId];
                    break;
                case ClientInfoProperty.officeLocationId:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.officeLocationId];

            }
        }
        return isPrefilled ? "prefilled-field" : "";
    }

    createRightPanel = () => {
        return <div className={this.state.actionPanelShow ? "panel-expand expand" : "panel-collapse collapse"}>
            <ClientReturnInfo
                clientId={this.state.clientId}
                handleChange={this.handleClientFieldChange}
                selectedEngagement={this.props.taxReturn.engagementType}
                selectedTaxYear={this.state.taxYear}
                taxReturn={this.props.taxReturn}
                isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
                isMarriedJointly={this.state.isJointReturn}
                onChangeMJFCheckbox={(e) => this.onChangeMJFCheckbox(e)}
                partnerId={this.state.partenID}
                expandReturnInformation={this.state.expandReturnInformation}
                onPanelToggle={this.onPanelToggle}
                prefilledClientInfo={this.props.prefilledClientInfo}
                getPrefilledClassName={this.getPrefilledClassName}
                resetPrefillInfo={this.props.resetPrefillInfo}
            ></ClientReturnInfo>
            {
                TaxDocument.isMutual(this.props.taxReturn) ?
                    <div>
                        <TaxpayerSidebar
                            title="Taxpayer"
                            model={this.props.taxReturn.taxpayer}
                            handleValueChange={this.handleFieldChange}
                            states={this.props.states}
                            isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
                            expandTaxpayerPanel={this.state.expandTaxpayerPanel}
                            onPanelToggle={this.onPanelToggle}
                            expandSpousePanel={this.state.expandSpousePanel}
                            prefilledClientInfo={this.props.prefilledClientInfo}
                            resetPrefillInfo={this.props.resetPrefillInfo}
                            getPrefilledClassName={this.getPrefilledClassName}
                        />
                        <div style={{ paddingBottom: '15px' }}>
                            {this.state.isJointReturn ?
                                <TaxpayerSidebar
                                    title="Spouse"
                                    model={this.props.taxReturn.spouse}
                                    handleValueChange={this.handleFieldChange}
                                    states={this.props.states}
                                    isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
                                    expandTaxpayerPanel={this.state.expandTaxpayerPanel}
                                    onPanelToggle={this.onPanelToggle}
                                    expandSpousePanel={this.state.expandSpousePanel}
                                    prefilledClientInfo={this.props.prefilledClientInfo}
                                    resetPrefillInfo={this.props.resetPrefillInfo}
                                    getPrefilledClassName={this.getPrefilledClassName}
                                /> : <div></div>}

                        </div>
                    </div> : TaxDocument.isIndividual(this.props.taxReturn) ?

                        <TaxpayerSidebar
                            title="Taxpayer"
                            model={this.props.taxReturn.taxpayer}
                            handleValueChange={this.handleFieldChange}
                            states={this.props.states}
                            isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
                            expandTaxpayerPanel={this.state.expandTaxpayerPanel}
                            onPanelToggle={this.onPanelToggle}
                            expandSpousePanel={this.state.expandSpousePanel}
                            prefilledClientInfo={this.props.prefilledClientInfo}
                            resetPrefillInfo={this.props.resetPrefillInfo}
                            getPrefilledClassName = {this.getPrefilledClassName}
                        />
                        : TaxDocument.isPartnership(this.props.taxReturn) &&

                        <PartnershipSidebar
                            clientId={this.props.taxReturn.clientId}
                            model={this.props.taxReturn.partnership}
                            handleValueChange={this.handleFieldChange}
                            isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
                            expandPartnershipPanel={this.state.expandPartnershipPanel}
                            onPanelToggle={this.onPanelToggle}
                            prefilledClientInfo={this.props.prefilledClientInfo}
                            resetPrefillInfo={this.props.resetPrefillInfo}
                            getPrefilledClassName = {this.getPrefilledClassName}
                        />
            }
        </div>;
    };

    createLeftPanel = () => {
        return <BookmarksPane
            title="Pages"
            docId={this.props.docId}
            pdfDocuments={this.props.pdfDocuments}
            sections={this.props.bookmarks}
            onPageDelete={this.onPageDelete}
            onPageSelect={this.onPageSelect}
            tabType={this.props.tabType}
            getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
        />;
    };

    public render() {
        return (
            <AwesomePDFViewer
                pdfDocument={this.props.pdfDocuments[this.props.docId].document as IPdfDocumentFacade}
                leftPanel={this.createLeftPanel()}
                rightPanel={this.createRightPanel()}
                currentPage={this.state.currentPage}
                pages={this.props.pages}
                scale={this.state.scale}
                onScaleChange={(s) => this.setState({ scale: s })}
                onCurrentPageChange={this.onPaginationClick}
            />
        );

    }



    private handleFieldChange = (item: ClientInfoProperty, value: any) => {

        if (item === ClientInfoProperty.clientId) {
            this.props.taxReturn.isModified = true;
            this.props.taxReturn.clientId = value;
            return this.props.updateTaxDocument(this.props.taxReturn);
        }
        if (TaxDocument.isPartnership(this.props.taxReturn)) {
            this.props.taxReturn.partnership.isModified = true;
            switch (item) {
                case ClientInfoProperty.partnerName: this.props.taxReturn.partnership.name = value; break;
                case ClientInfoProperty.partnerEmail: this.props.taxReturn.partnership.email = value; break;
                case ClientInfoProperty.partnerEin: this.props.taxReturn.partnership.ssn = value.replace(/-/g, ''); break;
                case ClientInfoProperty.partnerMobileNumber: this.props.taxReturn.partnership.mobileNumber = value; break;
                case ClientInfoProperty.partnerCountryCode: this.props.taxReturn.partnership.countryCode = value; break;

            }
            return this.props.updateTaxDocument(this.props.taxReturn);
        }

        if (TaxDocument.isMutual(this.props.taxReturn)) {
            this.props.taxReturn.taxpayer.isModified = true;
            this.props.taxReturn.spouse.isModified = true;
            switch (item) {
                case ClientInfoProperty.taxpayerName: this.props.taxReturn.taxpayer.name = value; break;
                case ClientInfoProperty.taxpayerEmail: this.props.taxReturn.taxpayer.email = value; break;
                case ClientInfoProperty.taxpayerDateOfBirth: this.props.taxReturn.taxpayer.dob = value; break;
                case ClientInfoProperty.taxpayerAddress: this.props.taxReturn.taxpayer.address = value; break;
                case ClientInfoProperty.taxpayerCity: this.props.taxReturn.taxpayer.city = value; break;
                case ClientInfoProperty.taxpayerState: this.props.taxReturn.taxpayer.state = value; break;
                case ClientInfoProperty.taxpayerZip: this.props.taxReturn.taxpayer.zip = value; break;
                case ClientInfoProperty.taxpayerSSN: this.props.taxReturn.taxpayer.ssn = value.replace(/-/g, ''); break;
                case ClientInfoProperty.taxpayerDeceased: this.props.taxReturn.taxpayer.isDeceased = value; this.props.checkDeceased(true, this.props.taxReturn.taxpayer.isDeceased,
                    this.props.taxReturn.spouse.isDeceased); break;
                case ClientInfoProperty.taxpayerMobileNumber: this.props.taxReturn.taxpayer.mobileNumber = value; break;
                case ClientInfoProperty.taxpayerCountryCode: this.props.taxReturn.taxpayer.countryCode = value; break;
                //Spouse
                case ClientInfoProperty.spouseName: this.props.taxReturn.spouse.name = value; break;
                case ClientInfoProperty.spouseEmail: this.props.taxReturn.spouse.email = value; break;
                case ClientInfoProperty.spouseDateOfBirth: this.props.taxReturn.spouse.dob = value; break;
                case ClientInfoProperty.spouseAddress: this.props.taxReturn.spouse.address = value; break;
                case ClientInfoProperty.spouseCity: this.props.taxReturn.spouse.city = value; break;
                case ClientInfoProperty.spouseState: this.props.taxReturn.spouse.state = value; break;
                case ClientInfoProperty.spouseZip: this.props.taxReturn.spouse.zip = value; break;
                case ClientInfoProperty.spouseSSN: this.props.taxReturn.spouse.ssn = value.replace(/-/g, ''); break;
                case ClientInfoProperty.spouseDeceased: this.props.taxReturn.spouse.isDeceased = value; this.props.checkDeceased(true, this.props.taxReturn.taxpayer.isDeceased,
                    this.props.taxReturn.spouse.isDeceased); break;
                case ClientInfoProperty.spouseMobileNumber: this.props.taxReturn.spouse.mobileNumber = value; break;
                case ClientInfoProperty.spouseCountryCode: this.props.taxReturn.spouse.countryCode = value; break;
            }
            return this.props.updateTaxDocument(this.props.taxReturn);
        }

        if (TaxDocument.isIndividual(this.props.taxReturn)) {
            this.props.taxReturn.taxpayer.isModified = true;
            switch (item) {
                case ClientInfoProperty.taxpayerName: this.props.taxReturn.taxpayer.name = value; break;
                case ClientInfoProperty.taxpayerEmail: this.props.taxReturn.taxpayer.email = value; break;
                case ClientInfoProperty.taxpayerDateOfBirth: this.props.taxReturn.taxpayer.dob = value; break;
                case ClientInfoProperty.taxpayerAddress: this.props.taxReturn.taxpayer.address = value; break;
                case ClientInfoProperty.taxpayerCity: this.props.taxReturn.taxpayer.city = value; break;
                case ClientInfoProperty.taxpayerState: this.props.taxReturn.taxpayer.state = value; break;
                case ClientInfoProperty.taxpayerZip: this.props.taxReturn.taxpayer.zip = value; break;
                case ClientInfoProperty.taxpayerSSN: this.props.taxReturn.taxpayer.ssn = value.replace(/-/g, ''); break;
                case ClientInfoProperty.taxpayerDeceased: this.props.taxReturn.taxpayer.isDeceased = value; this.props.checkDeceased(false, this.props.taxReturn.taxpayer.isDeceased, false); break;
                case ClientInfoProperty.taxpayerMobileNumber: this.props.taxReturn.taxpayer.mobileNumber = value; break;
                case ClientInfoProperty.taxpayerCountryCode: this.props.taxReturn.taxpayer.countryCode = value; break;
            }
            return this.props.updateTaxDocument(this.props.taxReturn);
        }
    }

    private onPaginationClick = (pageNo: number) => {
        this.setState({ currentPage: this.props.pages[pageNo - 1] });
        this.getPageDetails(this.props.pages[pageNo - 1]);
    }

    private onPageSelect = (page: number, voucherNo?: number, isViewClientInfo?: boolean) => {
        this.getPageDetails(page, isViewClientInfo);
        this.setState({ currentPage: page, enableUpdate: false })
    }

    getPageDetails = (page: number, isViewClientInfo?: boolean) => {
        let matchedForms: any;
        var groupId = 0;

        this.props.taxReturn.formGroups.map((formGroups) => {
            let tmpGroupID = formGroups.group;
            formGroups.forms.map((forms) => {
                if (forms.pageNo.some(x => page == x)) {
                    matchedForms = forms;
                    groupId = tmpGroupID;
                }
            });
        });
        let engagementType: any = (typeof this.props.taxReturn.engagementType == "number") ? TaxRetrun.EngagementType[this.props.taxReturn.engagementType] : this.props.taxReturn.engagementType;
        //if (matchedForms.formName == 'ClientInfo' && isViewClientInfo) {
        //    this.setState({
        //        isClientRefundPaymentDueEnabled: false,
        //        expandReturnInformation: false,
        //        expandRefundPaymentInformation: false,
        //    });
        //    //if (engagementType == TaxRetrun.EngagementType[TaxRetrun.EngagementType.E1040]) {
        //    //    this.setState({
        //    //        expandTaxpayerPanel: true,
        //    //        expandSpousePanel: true,
        //    //    });
        //    //}
        //    //else {
        //    //    this.setState({
        //    //        expandPartnershipPanel: true
        //    //    });
        //    //}
        //} else {
        //    this.setState({
        //        isClientRefundPaymentDueEnabled: true,
        //        expandReturnInformation: false,
        //        expandRefundPaymentInformation: true,
        //        expandTaxpayerPanel: false,
        //        expandSpousePanel: false,
        //        expandPartnershipPanel: false,
        //    });
        //}
        if (groupId != DocumentGroups.Vouchers && groupId != DocumentGroups.TaxReturns) {
            this.setState({ isClientRefundPaymentDueEnabled: false })
        }
        switch (groupId) {
            case DocumentGroups.Vouchers://payment
                {
                    let voucherForm: TaxDocument.IVoucher = matchedForms as TaxDocument.IVoucher;

                    this.setState({
                        isPayment: true,
                        isRefund: false,
                        amount: voucherForm.amount,
                        overPayAppliedAmount: 0,
                        netRefund: voucherForm.amount,
                        selectedAuthority: voucherForm.authorityID
                    });
                }
                break;
            case DocumentGroups.TaxReturns://Returns
                {
                    let refund: TaxDocument.IRefund = matchedForms as TaxDocument.IRefund;
                    this.setState({
                        isPayment: false,
                        isRefund: true,
                        amount: refund.overPayment,
                        overPayAppliedAmount: refund.overPaymentApplied,
                        netRefund: refund.overPayment - refund.overPaymentApplied,
                        selectedAuthority: refund.authorityID
                    });
                }
                break;
        }
    }
    private onPageDelete = (page: number) => {
        this.props.deletePages([page]);
        this.setState({ isClientRefundPaymentDueEnabled: false })
        //this.props.reGroupPreview([page], DocumentGroups.Deleted);
    }

    private onZoom(enable: boolean) {
        this.setState({ zoomEnabled: enable });
    }

    private onZoomRestore() {

        if (this.page) {
            this.setState({ zoomEnabled: false }, this.page.onZoomRestore);
        }
    }
};
//=============================================================================