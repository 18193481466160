import { AuthenticationProvider } from '../Company/CompanySettingsViewModel';

let moment = require('moment');

const defaultDate = moment.utc("0001-01-01");
export interface IUserBaseModel {
    userId: number,
    firstName: string,
    lastName: string,
    email: string
}

export interface IUserModel {
    id: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
}

export interface IUserFilterOptions {
    filterName: string,
    filterEmailId: string,
    filterUserGroup: string,
    sortBy?: string,
    sortOrder?: string,
    offset: number
}

export interface IUserGroups {
    id: number;
    name: string;
    userId?: number;
    userGroups?: number;

}

export enum IGroupCategory {
    None,
    Departments = 1,
    Locations,
    Groups
}

export enum IUserGroup {
    None,
    SystemAdmin = 1,
    Staff,
    Partner,
    Signatures
}

export enum UserManagementProperty {
    firstName = 0,
    middleName,
    lastName,
    phoneNumber,
    extension,
    ptin,
    email,
    faxNumber,
    title,
    mobileNumber,
    countryCode,
    isMfaEnabled
}

export enum IChangePassword {
    No = 0,
    Yes = 1
}
export enum IPasswordChangeBy {
    None,
    IWillCreate = 1,
    UserWillCreate
}

export const initialUserModel: IUserModel = {
    id: 0,
    firstName: "",
    lastName: "",
    emailAddress: ""
}

export const initialUserBaseModel: IUserBaseModel = {
    userId: 0,
    firstName: '',
    lastName: '',
    email: ''
}

export interface IUserMetaData {
    readonlyFields: IUserReadonlyFields
}

export interface IUserReadonlyFields {
    providerData: IExternalAuthenticationProviderFields[]
}

export interface IExternalAuthenticationProviderFields {
    provider: AuthenticationProvider,
    fields: string[],
}

export enum ProductStatus {
    None = 0,
    Enabled = 1,
    Disabled = 2
}

export enum ProductType {
    Organizers = "Organizers",
    Extensions = "Extensions",
    Signatures = "Signatures",
    Returns = "Returns"
}

export enum Product {
    None = 0,
    Returns = 1,
    SignaturesOneOff = 2,
    SignaturesMailMerge = 3,
    Organizers = 4,
    Extensions = 5,
    Exchange = 6,
    //Not a product,client.
    SSSuitePortal = 1001,
}  