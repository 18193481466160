import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as InUseExtensionsStore from '../../store/reports/InUseExtensionsStore';
import * as FilterStore from '../../store/reports/FilterStore';
import * as TaxDocumentStore from '../../store/common/TaxDocumentStore';
import * as InUseExtensionsState from '../../store/reports/InUseExtensionsState';
import * as Component from './InUseExtensions';
import * as UserStore from '../../store/userManagement/UserStore';
import * as UserSettingStore from '../../store/userManagement/UserSettingStore';
import * as CompanyStore from '../../store/company/CompanyStore';
import * as UploadTaxReturnStore from '../../store/common/uploadTaxReturn';
import * as GroupedExtensionsStore from '../../store/groupedExtensions/GroupedExtensionsStore';
import * as TaxSoftwareStore from '../../store/SuiteCompany/TaxSoftwareStore';
import * as ExtensionUserSettingsStore from '../../store/userManagement/ExtensionUserSettingStore';
import * as UserOfficeLocationStore from '../../store/common/UserOfficeLoacationStore';

export default connect(
    (state: ApplicationState) => ({
        inUse: state.inUseExtensions,
        taxDocuments: state.taxDocuments,
        usersStore: state.usersStore,
        reportFilters: state.reportFilters,
        userSettings: state.userSettings,
        company: state.companyData,
        uploadTaxReturnStore: state.UploadTaxReturnStore,
        groupedExtensionsStore: state.groupedExtensionsStore,
        taxSoftwareData: state.taxSoftwareData,
        extensionUserSettings: state.extensionUserSettings,
        userOfficeLocation:state.userOfficeLocation
    }),
    {
        ...InUseExtensionsStore.actionCreators,
        ...TaxDocumentStore.actionCreators,
        ...UserStore.actionCreators,
        ...FilterStore.actionCreators,
        ...UserSettingStore.actionCreators,
        ...CompanyStore.actionCreators,
        ...UploadTaxReturnStore.actionCreators,
        ...GroupedExtensionsStore.actionCreators,
        ...TaxSoftwareStore.actionCreators,
        ...ExtensionUserSettingsStore.actionCreators,
        ...UserOfficeLocationStore.actionCreators
    },
)(Component.InUseExtensions as any);