import React from  'react';
import { Modal, Button, Row } from 'react-bootstrap';
import { ICustomColumn } from '../../settings/GeneralSettings';
import * as DeliveredReturnsState from '../../../store/reports/DeliveredReturnsState';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { prepareCustomColumnValues } from '../../helper/HelperFunctions';
import { VenusNotifier } from '../../helper/VenusNotifier';
import { DeliveredReturnsConstants } from '../../helper/Constants';
import { DropdownComponent } from '../../common/controls/DropdownComponent';

interface CustomColumnProps {
    show: boolean;
    onCancel: () => void;
    onCustomColumnSave: () => void;
    onCustomColumnRemove: () => void;
    onCustomColumnDropdownChange(selectedValue: any): void;
    customColumnDropdownValues: ICustomColumn;
    selectedDocuments: DeliveredReturnsState.ISelectedDocument[];
    selectedColumnValue: number;
    hideLoader: boolean;
}


interface CustomColumState {
    saving: boolean;
}

export class CustomColumn extends React.Component<CustomColumnProps, CustomColumState>
{
    constructor(props: any) {
        super(props);

        this.state = {
            saving: false,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: CustomColumnProps) {

        const saving = !nextProps.show || nextProps.hideLoader ? false : this.state.saving;
        this.setState({
            saving: saving,
        });

    }

    onCustomColumnRemove = () => {
        if (this.props.selectedColumnValue > 0) {
            this.setState({ saving: true },
                () => { this.props.onCustomColumnRemove(); });
        } else {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.SelectCustomColumnValue, null);
        }
    }

    onCustomColumnSave = () => {
        if (this.props.selectedColumnValue > 0) {
            this.setState({ saving: true },
                () => { this.props.onCustomColumnSave(); });
        } else {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.SelectCustomColumnValue, null);
        }
    }


    public render() {

        const isLoaded: boolean = (!this.props.customColumnDropdownValues ||
            !this.props.selectedDocuments.every((i) => i.isfullyLoaded)) ? false : true;

        const modalBody =
            <>
                <Row>
                    <label>Column Value:</label>
                </Row>
                <Row style={{ display: "block" }}>
                    <DropdownComponent
                        id="ddlCustomColumnValues"
                        selectedValue={this.props.selectedColumnValue}
                        onChange={this.props.onCustomColumnDropdownChange}
                        options={prepareCustomColumnValues(this.props.customColumnDropdownValues)}
                        data-test-auto="52A69016-2B50-4B88-84B4-3E82ED02586A"
                    />
                </Row>
            </>;

        return (
            <Modal show={this.props.show} onHide={this.props.onCancel} className="customcolumn-modal">
                <Modal.Header closeButton data-test-auto="E9FF957A-1DE3-4FBA-8DD9-987ADBB404BA">
                    <Modal.Title>
                        <span className='text-secondary fas fa-tags' style={{ color: 'grey', marginRight: '5px' }}>
                        </span>Set Column Value
                    </Modal.Title>
                </Modal.Header>
                <LoadingOverlay>
                    <Modal.Body>
                        <div className="modal-body">
                            {modalBody}
                        </div>
                    </Modal.Body>
                    <Loader loading={this.state.saving || !isLoaded} text={"Please wait..."} />
                </LoadingOverlay>
                <Modal.Footer>

                    <Button
                        variant='default'
                        data-test-auto="28A5846C-E7CE-420B-9E9F-D1BE18DE1C90"
                        onClick={this.props.onCancel}
                    ><i className="fas fa-times"></i>
                        Cancel
                    </Button>
                    <Button
                        variant='info'
                        data-test-auto="E4736B21-22E9-4526-A80A-690A6CF06217"
                        onClick={this.onCustomColumnRemove}
                        disabled={this.state.saving || !isLoaded}
                    > <i className='fas fa-minus'></i>
                        Remove

                    </Button>
                    <Button
                        variant='info'
                        data-test-auto="056DD1B3-F56D-439C-B5E0-8998A4BDDAC7"
                        onClick={this.onCustomColumnSave}
                        disabled={this.state.saving || !isLoaded}
                    > <i className='fas fa-plus'></i>Save

                    </Button>

                </Modal.Footer>
            </Modal>
        );
    }
}
