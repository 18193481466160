import { ITaxReturn, ClientTypes, IOfficeLocation } from './TaxReturn';
import { IUserBaseModel, IUserModel } from '../../Core/ViewModels/User/UserViewModel';
import { IUnlockReturnModel } from 'src/store/reports/DeliveredReturnsState';

export interface IDeliveredTaxRetunrs extends ITaxReturn {
    createdUser: IUserModel;
    deliveredBy: number;
    deliveredByUser: IUserBaseModel;
    lockedByUser: IUserModel;
}

export interface IDeliveredTaxDocument {
    customColumn: string;
    retentionPeriod: Date | undefined;
    document: IDeliveredTaxRetunrs;
    downloadsCount: number;
    lastReminderOn: Date | undefined;
    signedCount: number;
    lockType: number;
    clientGuid: string;
    isDocumentLocked: boolean;
    bulkDownloadCount: number;
    reviewedCount: number;
    recieverCount: number;
    returnStatus: number;
    officeLocation: IOfficeLocation;
    unlockReturnModelList: IUnlockReturnModel[];
    isUTELinked: boolean;
}

export interface ISigningOrder {
    documentId: number;
    signer: string;
    signedOn: Date;
    signerType: ClientTypes;
    order: number;
    signerStatus: SignerStatus;
}

export enum SignerStatus {
    NotSigned = 0,
    Signed = 1,
    Reviewed = 2,
}

export interface IScheduleReminderPayload {
    signingReminder: {
        enabled: boolean;
        reminderDays: number;
    };
    voucherReminder: {
        enabled: boolean;
        reminderDays: number;
    };
}

export enum IVoucherStatus {
    Valid = 0,
    SSRDelivered,
    TaxPayerDisabled,
    AllPaid,
    AllExpired,
    NoVoucher,
}
