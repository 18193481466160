import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../../';
import { actionTypes } from '../../ActionTypes';
import { RequestVoucherReminderReportPagesAction, ReceiveVoucherReminderReportPagesAction } from './../KnownTypes';
import { VoucherReminderReportDictionary, VoucherReminderReportState, VoucherReminderReportTableModel } from './VoucherReminderManagementState';
import { VoucherReminderReportModel } from '../../../components/common/VoucherReminderReport';
import {
    ReceiveTaxDocumentAction, ReceiveTaxDocumentsAction
} from '../../common/TaxDocumentStore';
import { API_BASE_URL } from 'src/utils/contants';


type KnownAction =
    RequestVoucherReminderReportPagesAction |
    ReceiveVoucherReminderReportPagesAction |
    ReceiveTaxDocumentAction |
    ReceiveTaxDocumentsAction;

type DispatchAction = KnownAction;

export const actionCreators = {
    requestVoucherReminderReport: (query: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (!getState().voucherReminderStatusPages[query]) {
            const fetchTask = fetch(`${API_BASE_URL}api/Reports/VoucherReminderManagement/GetReminderReport` + query, {
                method: 'GET',
                credentials: 'include'
            })
                .then(response => response.json() as Promise<VoucherReminderReportTableModel>)
                .then(data => {
                    dispatch({
                        type: actionTypes.RECEIVE_VOUCHER_REMINDER_REPORT_PAGES,
                        query: query,
                        table: data.reminders,
                        totalRowCount: data.count
                    });
                });
            addTask(fetchTask); // Ensure server-side prerendering waits for this to complete
            dispatch({ type: actionTypes.REQUEST_VOUCHER_REMINDER_REPORT_PAGES, query: query });
        }
    }
};


const unloadedState: VoucherReminderReportState = {
    voucherReminderReportTableModel: {},
    query: '?',
    loading: false,
    totalRowCount: 0
} as VoucherReminderReportState;

const unloadedRecycleTaxDocument: VoucherReminderReportModel = {
    
} as VoucherReminderReportModel;

export const reducer: Reducer<VoucherReminderReportDictionary> = (state: VoucherReminderReportDictionary = {}, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_VOUCHER_REMINDER_REPORT_PAGES:
            if (!state[action.query]) {
                let added = { ...unloadedState } as VoucherReminderReportState;
                added.loading = true;
                added.query = action.query;
                let item = {} as VoucherReminderReportDictionary;
                item[action.query] = added;

                return {
                    ...state,
                    ...item
                };
            } 
            break;
        case actionTypes.RECEIVE_VOUCHER_REMINDER_REPORT_PAGES:  
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (state[action.query]) {
                let changed = state[action.query] as VoucherReminderReportState;
                changed.loading = false;
                changed.voucherReminderReportTableModel.reminders = action.table;
                changed.voucherReminderReportTableModel.count = action.totalRowCount;
                changed.totalRowCount = action.totalRowCount;
                let item = {} as VoucherReminderReportDictionary;
                item[action.query] = changed;
                return {
                    ...state,
                    ...item
                };
            }
            break;
      
        default:
    }
    return state || { "?": unloadedState };
};

