import React, { useEffect, useState } from 'react';
import * as Bs from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { MyDefaults } from './MyDefaults';
import { IModalProps } from './ProfileObjects';
import { OverlayLoader } from '../../helper/OverlayLoader';
import {
    IExtensionUserSettings,
    initialExtensionUserSettings,
    actionCreators,
    DefaultDownloadInfoType,
    DefaultSenderInfoType
} from '../../../store/userManagement/ExtensionUserSettingStore';
import { MyNotifications } from './MyNotifications';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'src/store';
import { cloneDeep } from 'lodash';


export interface IUserDefaultSettingsStates {
    defaultSettings: IDefaultSettings;
    notificationSettings: INotificationSettings;
    signatureSettings: ISignatureSettings;
    enableSignerDeligation: boolean;
    useSignatureStamp: boolean;
    signatureDelegation: ISignatureDelegation;
    userId: number;
    mfaSettings:{
        isMfaEnabled:boolean
    }
}

export interface IDefaultSettings {
    reportFilter: string;
    enableLastLogin: boolean;
}
export interface INotificationSettings {
    lastPersonNotified: number;
    isReturnReady: boolean;
    isReturnAssigned: boolean;
    isApprovedForDelivery: boolean;
}
export interface ISignatureSettings {
    isAutoPlaceSignature: boolean;
    isEnableSignatureDelegation: boolean;
    signaturePath: string;
}

export interface DefaultMailingAddress {
    id: number;
    title: string;
}
export interface ISignatureDelegation {
    userId: number;
    delegate: number[];
}

let signatureDelegationUsersInitialState: ISignatureDelegation = {
    delegate: [],
    userId: 0
}

export const MySettings: React.FC<IModalProps> = ({
    showState,
    onHide
}) => {

    const dispatch = useDispatch();
    const userSettings = useSelector((state: ApplicationState) => state.extensionUserSettings);
    const [extensionUserSettings, setUserSettings] = useState<IExtensionUserSettings>({ ...initialExtensionUserSettings });
    const [saving, setSaving] = useState<boolean>(false);
    const extensionUsers = useSelector((state: ApplicationState) => state.usersStore.extensionUsers);
    const customColumnData = useSelector((state: ApplicationState) => state.customColumn.customColumn);
    const partnerUsers = useSelector((state: ApplicationState) => state.usersStore.partners);

    useEffect(() => {
        setUserSettings(cloneDeep(userSettings));
    }, [showState, userSettings]);

    const onDefaultDownloadChange = (event: any) => {
        extensionUserSettings.defaultSettings.download.downloadType = event as DefaultDownloadInfoType;
        setUserSettings({ ...extensionUserSettings });
    }

    const onPaymentNotificationChange = (notification: boolean) => {
        extensionUserSettings.notificationSettings.paymentNotification = notification;
        setUserSettings({ ...extensionUserSettings });
    }

    const submit = () => {
        setSaving(true);
        dispatch(actionCreators.saveExtensionUserSettings(extensionUserSettings, () => {
            dispatch(actionCreators.requestExtensionUserSettings(true));
            setSaving(false);
            onHide();
        }));
    }

    const onChangeEROStamp = (value: any) => {
        extensionUserSettings.defaultSettings.eroUser = value;
        setUserSettings({ ...extensionUserSettings });
    }

    const onChangeContactPerson = (value: any) => {
        extensionUserSettings.defaultSettings.contactPerson = value;
        setUserSettings({ ...extensionUserSettings });
    }

    const onChangeDefaultSender = (event: any) => {
        if (event.target.value == "company") {
            extensionUserSettings.defaultSettings.sender.senderType = DefaultSenderInfoType.Company;
            extensionUserSettings.defaultSettings.sender.senderId = 0;
        }
        else {
            extensionUserSettings.defaultSettings.sender.senderType = DefaultSenderInfoType.User
        }
        setUserSettings({ ...extensionUserSettings });
    }

    const onChangeSenderName = (value: any) => {
        extensionUserSettings.defaultSettings.sender.senderId = value;
        setUserSettings({ ...extensionUserSettings });
    }

    const onChangeNotifySigningEvents = (value: any) => {
        extensionUserSettings.defaultSettings.notifyUser = value;
        setUserSettings({ ...extensionUserSettings });
    }

    const onChangeCustomColumnValue = (value: any) => {
        extensionUserSettings.defaultSettings.customColumnValue = value;
        setUserSettings({ ...extensionUserSettings });
    }

    return <Modal show={showState} onHide={onHide} className="my-settings in" size="lg" enforceFocus={false}>
        <Modal.Header closeButton>
            <Modal.Title className="pull-left">
                My Settings
            </Modal.Title>
        </Modal.Header>
        {
            saving ? (<OverlayLoader
                show={showState}
                text={"Saving, please wait..."} />) : (!userSettings.loading ?

                    (<Modal.Body style={{ minHeight: '250px' }}>
                        <MyDefaults
                            DefaultSettings={extensionUserSettings.defaultSettings}
                            onChangeDefaultDownload={onDefaultDownloadChange}
                            onChangeEROStamp={onChangeEROStamp}
                            onChangeContactPerson={onChangeContactPerson}
                            onChangeDefaultSender={onChangeDefaultSender}
                            onChangeSenderName={onChangeSenderName}
                            onChangeNotifySigningEvents={onChangeNotifySigningEvents}
                            onChangeCustomColumnValue={onChangeCustomColumnValue}
                            extensionUsers={extensionUsers}
                            customColumnData={customColumnData}
                            partnerUsers={partnerUsers}
                        />
                        <MyNotifications
                            onPaymentNotificationChange={onPaymentNotificationChange}
                            paymentNotification={extensionUserSettings.notificationSettings.paymentNotification}
                        />
                    </Modal.Body>) :
                    (<OverlayLoader show={showState} text={"Loading, please wait..."} />)
            )
        }
        <Modal.Footer>
            <Bs.Button
                className="btn-white"
                variant='default'
                data-test-auto="6A473DC7-675C-40F0-9A65-710B47FE0CBC"
                onClick={onHide}
                disabled={userSettings.loading}>
                <i className='fas fa-times'></i>
                Cancel</Bs.Button>
            <Bs.Button
                variant='info'
                data-test-auto="92152B53-A40D-46B7-A39E-0DCDA1F9C1EA"
                onClick={submit}
                disabled={userSettings.loading}>
                <i className='fas fa-save'></i>
                Save & Close</Bs.Button>
        </Modal.Footer>
    </Modal>
}