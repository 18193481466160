import { ILogger } from "src/Logger/Logger";
import { actionTypes } from "../ActionTypes";
import { AppThunkAction } from '../';
import { NotificationAction } from '../common/NotificationStore';
import { LoggerFactory } from "src/Logger/LoggerFactory";
import { Action, Reducer } from "redux";

export interface LoggerStore {
    logger: ILogger;
}

export const defaultLoggerState: LoggerStore = {
    logger: null
}

export interface SetLoggerAction {
    type: actionTypes.SET_LOGGER;
    data: ILogger;
}

type KnownAction = SetLoggerAction |
    NotificationAction;

export const actionCreator = {

    setLogger: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const logger = new LoggerFactory().getTelemetryLogger();
        dispatch({ type: actionTypes.SET_LOGGER, data: logger });
    }

};

export const reducer: Reducer<LoggerStore> = (state: LoggerStore = defaultLoggerState, incomingAction: Action) => {
    const action = incomingAction as SetLoggerAction;
    switch (action.type) {
        case actionTypes.SET_LOGGER: {
            return { logger: action.data }
        }
        default: return defaultLoggerState;
    }
}