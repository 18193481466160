import React from  'react';
import { IPageItem } from './ProcessReturnModal/ProcessReturnModels';
import { VoucherPayment, VoucherEstimatedPayment } from '../svg/IconCollection';
const Icons = {
    "PaymentVoucher": VoucherPayment,
    "EstimatedVoucher": VoucherEstimatedPayment
};
export type iconType = "PaymentVoucher" | "EstimatedVoucher";
export const BookmarkIcon: React.FC<IPageItem> = (props) => {
    const TagName = Icons[(props.icon || 'PaymentVoucher') as iconType];
    return props.displayIconAsImage ?
        <TagName className="bookmark-icon-image" /> :
        <i className={"bookmark-icon " + props.icon + " " + props.color} />        
}