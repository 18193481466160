import { connect } from 'react-redux';
import { ApplicationState } from "./../../../store";
import { RecycleGroupedExtensions } from './RecycleGroupedExtensions';
import * as RecycleGroupedExtensionsStore from './../../../store/groupedExtensions/RecycleGroupedExtensions/RecycleGroupedExtensionsStore';
import * as FilterStore from './../../../store/reports/FilterStore';

export default connect(
    (state: ApplicationState) => ({
        recycleGroupedExtensions: state.recycleGroupedExtensions,
        reportFilters: state.reportFilters,
        pageTitle: "Recycle Bin",
    }),
    {
        ...RecycleGroupedExtensionsStore.actionCreators,
        ...FilterStore.actionCreators
    },
)(RecycleGroupedExtensions as any);