import * as React from "react";
import { Overlay, Popover } from 'react-bootstrap';
import { Button, Modal, OverlayTrigger } from 'react-bootstrap';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { Card, FormGroup, Col, FormControl, FormLabel } from 'react-bootstrap';
import Select from 'react-select';
import * as Helper from './../../helper/HelperFunctions';
import { ControllerInfo, ControllerInfoProperty, GroupInfo } from "../../../Core/ViewModels/GroupExtensions/ComponentModels";
import { IClientInfoProps, IEditClientInfoState } from "../../common/ClientInfo";
import { ClientInfoConstants } from "../../helper/Constants";
import { PhoneNumberComponent } from "../../common/PhoneNumberComponent";
import { TaxIdentificationNumber } from "../../common/controls/TaxIdentificationNumber";
import { isValidEmailAddress } from "../../helper/Validations";
import { Guid } from "../../../Core/Utilities/Guid";

interface IControllerInfoProps extends IClientInfoProps {
    updateControllerInfoData(data: ControllerInfo, callback?: () => void): void;
}

interface IEditControllerInfoState extends IEditClientInfoState {
    isDisabled: boolean;
}
const msg = {
    saving: 'Saving Controller Info ,Please Wait...',
    loading: 'Loading, Please Wait...'
}

export class ControllerInfoComponent extends React.Component<IControllerInfoProps, IEditControllerInfoState> {
    constructor(props: IControllerInfoProps) {
        super(props);
        this.state = {
            loadingMessage: undefined,
            taxPayerEmailAddress: "",
            spouseEmailAddress: "",
            isEmailUpdated: false,
            clientGUIDs: [],
            showClosePopover: false,
            isDisabled: false
        };
    }
    onPopupClose = () => {
        this.setState({
            loadingMessage: undefined,
            clientGUIDs: [],
            isEmailUpdated: false,
        }, () => {
            this.props.model && this.props.onCancel(this.props.model.id);
        })
    }

    private filterClientGUID = (clientGUID: string) => {
        let tempClientGUIDs: string[] = Object.assign([], this.state.clientGUIDs);
        if (this.state.clientGUIDs.length > 0) {
            if (tempClientGUIDs.indexOf(clientGUID) > -1) {
                tempClientGUIDs.splice(tempClientGUIDs.indexOf(clientGUID), 1);
            }
        }
        tempClientGUIDs.push(clientGUID);
        return tempClientGUIDs;
    }

    public render() {
        const popover = (
            <Popover id="controller-info-popover">
                <Popover.Title as="h3">Confirmation</Popover.Title>
                <Popover.Content>
                    {ClientInfoConstants.emailUpdatedConfirmation}
                    <div className="popover-footer">
                        <button
                            className="btn btn-only btn-success"
                            onClick={this.onConfirm}
                            title="OK"
                            data-test-auto="{E10A9D84-01C3-4477-A8AE-B1586CEBFFCD}"
                            style={{ marginLeft: "5px" }}>
                            Yes
                    </button>
                        <button
                            onClick={this.onCancle}
                            title="Cancel"
                            data-test-auto="{465295B5-62F0-4B6F-8BF6-A8D4CF5B576B}"
                            className="btn btn-only btn-danger"
                            style={{ marginLeft: "5px" }}>
                            No
                     </button>
                    </div>
                </Popover.Content>
            </Popover>
        );

        const isDataLoaded = this.props.isDeliveredReturn ? this.props.show && this.props.model &&
            Helper.isControllerInfoLoaded(this.props.model) && this.props.model.signedDetails && this.props.nextSignerDetails :
            this.props.show && this.props.model && Helper.isControllerInfoLoaded(this.props.model);

        let loadingMessage: string | undefined = !isDataLoaded && isDataLoaded != '' ? msg.loading : this.state.loadingMessage;


        return (<Modal className="edit-controller-info" show={this.props.show} onHide={this.onPopupClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <span className='modalIcon text-secondary fa fa-address-card'>
                    </span>Edit Controller Info
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoadingOverlay style={{ height: '100%' }}>
                    <div className='row'>
                        {
                            this.props.model &&
                            (<div className='col-sm-12' >
                                <Card>
                                    <Card.Header><b>Controller</b></Card.Header>
                                </Card>

                                <div className="row marL0  marT10">
                                    <div className='col-sm-12 col-md-12 col-xl-12'>
                                        <div className='row'>
                                            <div className='col-sm-2 col-md-2 col-xl-2 padT07 tpLabelFont'>
                                                Name<span className="fas fa-asterisk compulsory-star"></span>
                                            </div>
                                            <div className='col-sm-10 col-md-10 col-xl-10 marB10 padR25'>
                                                <input type='text'
                                                    className='form-control'
                                                    value={this.props.model.controllerInfo.name}
                                                    onChange={(event: any) => { this.handleFieldChange(ControllerInfoProperty.controllerName, event.target.value) }}
                                                    placeholder="Enter Name"
                                                    data-test-auto="{DD651E51-CC28-4992-84AE-49DDADF882D5}"
                                                >
                                                </input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row marL0">
                                    <div className='col-sm-12 col-md-12 col-xl-12'>
                                        <div className='row'>
                                            <div className='col-sm-2 col-md-2 col-xl-2 padT07 tpLabelFont'>
                                                Email<span className="fas fa-asterisk compulsory-star"></span>
                                            </div>
                                            <div className='col-sm-10 col-md-10 col-xl-10 marB10 padR25'>
                                                <input type='text'
                                                    className='form-control'
                                                    value={this.props.model.controllerInfo.emailAddress}
                                                    onChange={(event: any) => { this.handleFieldChange(ControllerInfoProperty.controllerEmail, event.target.value) }}
                                                    placeholder="Enter Email"
                                                    data-test-auto="{E13E6C20-2030-45FB-9197-72FD7418F123}"
                                                >
                                                </input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row marL0">
                                    <div className='col-sm-6 col-md-6 col-xl-6'>
                                        <div className='row'>
                                            <div className='col-sm-4 col-md-4 col-xl-4 padT07 tpLabelFont'>
                                                Country Code
                        </div>
                                            <div className='col-sm-8 col-md-8 col-xl-8 marB10'>
                                                <Select
                                                    name="form-field-edit-info-state-controller"
                                                    value={this.props.model.controllerInfo.countryCode}
                                                    onChange={(event: any) => { this.handleFieldChange(ControllerInfoProperty.controllerCountryCode, event ? event.value : "") }}
                                                    options={Helper.GetCountryCode()}
                                                    data-test-auto="D16A841D-A131-49AC-8C69-6D063912ABD3"
                                                    clearable={true}
                                                    searchable={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-6 col-md-6 col-xl-6'>
                                        <div className='row'>
                                            <div className='col-sm-4 col-md-4 col-xl-4 padT07 tpLabelFont'>
                                                Mobile
                        </div>
                                            <div className='col-sm-8 col-md-8 col-xl-8 marB10 padR25'>
                                                <PhoneNumberComponent
                                                    phoneNumber={this.props.model.controllerInfo.mobileNumber ? this.props.model.controllerInfo.mobileNumber : ""}
                                                    handleChangePhoneNumber={(value: string) => { this.handleFieldChange(ControllerInfoProperty.controllerMobileNumber, value) }}
                                                    disabled={false}
                                                >
                                                </PhoneNumberComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row marL0">
                                    <div className='col-sm-6 col-md-6 col-xl-6'>
                                        <div className='row'>
                                            <div className='col-sm-4 col-md-4 col-xl-4 padT07 tpLabelFont'>
                                                SSN
                        </div>
                                            <div className='col-sm-8 col-md-8 col-xl-8 marB10'>
                                                <TaxIdentificationNumber
                                                    value={this.props.model ? Helper.FilterSSN(this.props.model.controllerInfo.ssn) : ""}
                                                    onChange={(value: string) => { this.handleFieldChange(ControllerInfoProperty.controllerSSN, value) }}
                                                    placeholder="Enter SSN"
                                                    mask='000-00-0000'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        }
                    </div>
                    <Loader loading={loadingMessage} text={loadingMessage} />
                </LoadingOverlay>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="default"
                    className="btn-white"
                    onClick={this.onPopupClose} >
                    <i className="fas fa-times"
                        data-test-auto="{07C5DFE1-A006-4B5D-B802-9E9EEE10A904}" />Cancel
                    </Button>
                {(this.props.model && this.Validate() === true && this.props.isDeliveredReturn && this.state.isEmailUpdated) ?
                    <OverlayTrigger rootClose trigger="click" onEnter={() => document.body.click()} placement="top" overlay={popover} >
                        <Button
                            variant='info'
                            disabled={loadingMessage || loadingMessage !== undefined ? true : false}
                            onClick={() => { this.setState({ showClosePopover: false }) }}
                            data-test-auto="{2A9BF102-E098-4401-AA62-24F5D6D42ECA}" >
                            <i className='fas fa-save'></i>Save
                      </Button>
                    </OverlayTrigger>
                    : <Button
                        variant='info'
                        disabled={loadingMessage || loadingMessage !== undefined ? true : false}
                        onClick={this.onConfirm}
                        data-test-auto="{CEC259EC-2066-4C6C-9703-6ADA78C11E2A}">
                        <i className='fas fa-save'></i>Save
                    </Button>
                }
            </Modal.Footer>
        </Modal>)
    }
    private Validate() {
        const groupData: GroupInfo = this.props.model as GroupInfo;
        let isValid: boolean = true;
        if (groupData.controllerInfo !== undefined) {
            if (groupData.controllerInfo.emailAddress === "" || groupData.controllerInfo.emailAddress === undefined || groupData.controllerInfo.emailAddress === null || (!isValidEmailAddress(groupData.controllerInfo.emailAddress)))
                isValid = false;
        }
        return isValid;
    }

    private onConfirm = () => {
        const groupData: GroupInfo = this.props.model as GroupInfo;
        if (groupData) {
            if (Helper.validateControllerInfo(groupData.controllerInfo) && Helper.validateControllerEmail(groupData)) {
                if (this.state.isEmailUpdated) {
                    let userThis = this;
                    userThis.setState({ loadingMessage: msg.loading, isEmailUpdated: false, showClosePopover: false }, () => {
                        this.props.onSave(userThis.props.model.id, true, userThis.state.clientGUIDs, true, () => { userThis.setState({ loadingMessage: undefined, clientGUIDs: [] }) });
                    });
                    return true;
                }
                else {
                    let userThis = this;
                    userThis.setState({ loadingMessage: msg.loading, isEmailUpdated: false, showClosePopover: false }, () => {
                        this.props.onSave(userThis.props.model.id, false, userThis.state.clientGUIDs, true, () => { userThis.setState({ loadingMessage: undefined, clientGUIDs: [] }) });
                    });
                    return false;
                }
            }
        }
    }
    private handleFieldChange = (item: ControllerInfoProperty, value: any) => {
        switch (item) {
            case ControllerInfoProperty.controllerName: this.props.model.controllerInfo.name = value; break;
            case ControllerInfoProperty.controllerMobileNumber: this.props.model.controllerInfo.mobileNumber = value; break;
            case ControllerInfoProperty.controllerEmail: {
                this.props.model.controllerInfo.emailAddress = value || '';
                if (isValidEmailAddress(value)) {
                    this.props.model.controllerInfo.clientGuid = Guid.newGuid().toString();
                }
                this.setGUIDState(this.props.model.controllerInfo.clientGuid);
                break;
            }
            case ControllerInfoProperty.controllerSSN: this.props.model.controllerInfo.ssn = value.replace(/-/g, ''); break;
            case ControllerInfoProperty.controllerCountryCode: this.props.model.controllerInfo.countryCode = value; break;
        }
        this.props.updateControllerInfoData(this.props.model.controllerInfo);
    }
    private setGUIDState = (clientGuid: string) => {
        this.setState({
            isEmailUpdated: true,
            clientGUIDs: this.filterClientGUID(clientGuid)
        });
    }
    private onCancle = () => {
        let userThis = this;
        userThis.setState({ loadingMessage: msg.loading, isEmailUpdated: false, showClosePopover: false }, () => {
            this.props.onSave(userThis.props.model.id, false, userThis.state.clientGUIDs, true, () => { userThis.setState({ loadingMessage: undefined, clientGUIDs: [] }) });
        });
    }
}
export default ControllerInfoComponent;