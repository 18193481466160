import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { handleResponse } from '../Library';
import { actionTypes } from '../ActionTypes';
import { PDFJSStatic, PDFDocumentProxy } from 'pdfjs-dist';
import { IPdfDocumentFacade, PdfDocumentFacade, ISubDocument, IFacadeViewModel, IPageMap, SubDocType } from '../../Core/Utilities/PdfDocumentFacade';
import { ITaxReturn } from '../../components/common/TaxReturn';
import { TYPES } from '../../Startup/types';
import { container } from '../../Startup/inversify.config';
import { IFileClient } from '../../Core/Services/FileClient';
import { LoggerFactory } from '../../Logger/LoggerFactory';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';
import { getProcessResourceId } from 'src/components/helper/RbacConstants';

const PDFJS: PDFJSStatic = require('pdfjs-dist');
const pdfjsWorker = require('pdfjs-dist/build/pdf.worker.entry');
(PDFJS as any).GlobalWorkerOptions.workerSrc = pdfjsWorker;

export interface IPdfDocumentDictionary {
    [index: number]: {
        document?: IPdfDocumentFacade,
        isLoading: boolean,
        error: boolean,
        message: string
    };
}

interface RequestPdfAction {
    type: actionTypes.REQUEST_PDF_OBJECT;
    id: number;
}

interface ReceivePdfAction {
    type: actionTypes.RECEIVE_PDF_OBJECT;
    id: number;
    document: IPdfDocumentFacade;
}

interface ErrorPdfAction {
    type: actionTypes.ERROR_PDF_OBJECT;
    id: number;
    reason: string;
}
interface ReceiveSubDocPdfAction {
    type: actionTypes.RECEIVE_SUB_DOC_PDF_OBJECT;
    id: number;
    subDoc: ISubDocument
}

export interface ResetPdfAction {
    type: actionTypes.RESET_PDF_OBJECTS;
    id: number
}

type KnownAction =
    RequestPdfAction |
    ReceivePdfAction |
    ErrorPdfAction |
    ResetPdfAction |
    ReceiveSubDocPdfAction;

const fileClient = container.get<IFileClient>(TYPES.IFileClient);

export const actionCreators = {

    requestTaxReturnPdf: (taxReturn: ITaxReturn, reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        if (reload ||
            !state.pdfDocuments[taxReturn.id
            ] || !state.pdfDocuments[taxReturn.id].document) {
            let asyncHelper: number = 0;
            const fetchTask = fetch(`${API_BASE_URL}/api/Download/GetDocumentFacadeAsync?id=` + taxReturn.id, {
                credentials: 'include',
                headers: {
                    'X-Resource-Id': getProcessResourceId()
                }
            }).then(handleResponse)
                .then(response => response as Promise<IFacadeViewModel>)
                .then(documentFacade => {
                    let document: IPdfDocumentFacade = new PdfDocumentFacade();
                    documentFacade.subDocuments.forEach((doc: ISubDocument, index: number) => {
                        fileClient.download(doc.downloadUrl)
                            .then((data) => {
                                asyncHelper++;
                                const pdfDocument = data as PDFDocumentProxy;
                                if (doc.subDocType == SubDocType.Original && (doc.pageMap == undefined || doc.pageMap.length == 0)) {
                                    doc.pageMap = [];
                                    for (var i = 1; i <= pdfDocument.numPages; i++) {
                                        doc.pageMap.push({
                                            subDocPageNum: i,
                                            facadePageNum: i
                                        } as IPageMap);
                                    }
                                }

                                documentFacade.subDocuments[index] = {
                                    ...doc,
                                    document: pdfDocument
                                };

                                document.appendSubDocument(documentFacade.subDocuments[index]);
                                if (asyncHelper === documentFacade.subDocuments.length) {
                                    dispatch({ type: actionTypes.RECEIVE_PDF_OBJECT, id: taxReturn.id, document: document });
                                }
                            }, (error: string) => {
                                dispatch({ type: actionTypes.ERROR_PDF_OBJECT, id: taxReturn.id, reason: error });
                            });
                    });
                    //fileClient.download(documentUrl)
                    //    .then((data: any) => {
                    //        let document: IPdfDocumentFacade = new PdfDocumentFacade(data as PDFDocumentProxy, documentUrl);
                    //        dispatch({ type: actionTypes.RECEIVE_PDF_OBJECT, id: taxReturn.id, document: document });
                    //    }, (error: string) => {
                    //        dispatch({ type: actionTypes.ERROR_PDF_OBJECT, id: taxReturn.id, reason: error });
                    //    });
                })
                .catch(error => {
                    dispatch({ type: actionTypes.ERROR_PDF_OBJECT, id: taxReturn.id, reason: error });
                    logger.trackError(`requestTaxReturnPdf failed for taxReturn: ${taxReturn}, with error ${error.message}`);
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_PDF_OBJECT, id: taxReturn.id });
        }
    }
};

export const reducer: Reducer<IPdfDocumentDictionary> = (state: IPdfDocumentDictionary = {}, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_PDF_OBJECT:
            let cleanState: IPdfDocumentDictionary = { ...state };
            cleanState[action.id] = {
                document: undefined,
                isLoading: true,
                error: false,
                message: ''
            };
            return cleanState;

        case actionTypes.RECEIVE_PDF_OBJECT:
            let newState: IPdfDocumentDictionary = { ...state };
            let document: IPdfDocumentFacade;
            document = action.document;
            newState[action.id] = {
                document: document,
                isLoading: false,
                error: false,
                message: ''
            };
            return newState;
        case actionTypes.ERROR_PDF_OBJECT:
            let errorState: IPdfDocumentDictionary = { ...state };
            errorState[action.id] = {
                document: undefined,
                isLoading: false,
                error: true,
                message: action.reason
            };
            return errorState;
        case actionTypes.RESET_PDF_OBJECTS:
            let resetState: IPdfDocumentDictionary = { ...state };
            resetState[action.id] = {
                document: undefined,
                isLoading: false,
                error: false,
                message: ''
            };
            return resetState;
        default:
            return state || { 0: undefined };
    }
};
