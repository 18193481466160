import React from  'react';
import { RouteComponentProps } from 'react-router';
import * as Bootstrap from 'react-bootstrap';
import { LoadingOverlay, Loader } from 'react-overlay-loader';

import * as  IEmailMessageStoreState from '../../../../store/settings/EmailMessageStore';
import { EmailMessageList } from './EmailMessageList';
import { EmailMessageView } from './EmailMessageView';
import { initialWelcomeMessage, initialEmailMessage } from '../../../../Core/ViewModels/CompanySettings/ClientInstructionViewModel';
import { RBACKeys } from 'src/components/helper/RbacConstants';

interface IEmailMessageProps {
    heading: string;
    description: string;
    emailMessages: IEmailMessageStoreState.IEmailMessageDictionary;
    defaultMessage: number;
    selectedMessage: number;
    onListItemClick: (messageId: number) => void;
    typeId: number;
    onAddButtonClick: (defaultMessageId: number, selectedMessageId: number, typeId: number) => void;
    onEditButtonClick: (defaultMessageId: number, selectedMessageId: number, typeId: number) => void;
    onDeleteButtonClick: (selectedMessageId: number, defaultMessage: number) => void;
    showLoader: boolean;
    loaderText: string;
}


export class EmailMessage extends React.Component<IEmailMessageProps, {}> {
    constructor(props: IEmailMessageProps) {
        super(props);
    }

    public render() {
        return (<LoadingOverlay style={{ height: '100%' }}>
            <div className="row">
                <div style={{ marginLeft: 16, marginBottom: 10, fontSize: '14px' }}>
                    <h3 className="text-primary">{this.props.heading}</h3>
                    <i className="fas fa-info-circle"></i><span style={{ marginLeft: 5 }}>{this.props.description}</span>
                </div>
            </div>
            <div className="row" style={{ position: "relative" }}>
                <div className="col-lg-4 col-sm-6" style={{ marginLeft: 17 }}>
                    <div className="row" style={{ display: 'flow-root' }}>
                        <div className="pull-right">
                            <Bootstrap.Button
                                data-resource-id={RBACKeys.Settings.settings}
                                data-test-auto="6A7380BB-5831-4E8B-AE6C-C27D511C45EA"
                                variant="info"
                                className="btn btn-lg"
                                style={{ padding: 12, minWidth: 40 }}
                                onClick={() => this.props.onAddButtonClick(this.props.defaultMessage, this.props.selectedMessage, this.props.typeId)}
                                title="Add Message">
                                <span className="fas fa-plus" />
                            </Bootstrap.Button>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: 10, overflowY: 'auto', height: 207, border: 'rgb(221, 221, 221) 1px solid', fontSize: '14px' }}>
                        <div style={{ width: '100%' }}>
                            <EmailMessageList
                                emailMessages={this.props.emailMessages}
                                defaultMessage={this.props.defaultMessage}
                                selectedMessage={this.props.selectedMessage}
                                onListItemClick={this.props.onListItemClick}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-sm-6" style={{ marginLeft: 32 }}>
                    <div className="row" style={{ display: 'flow-root' }}>
                        <div className="pull-right">
                            <Bootstrap.Button
                                data-resource-id={RBACKeys.Settings.settings}
                                data-test-auto="C55C7FAC-3A7E-4926-8A98-7A9586AC8272"
                                variant="default"
                                className="btn btn-secondary btn-lg fas fa-edit"
                                style={{ padding: 12, minWidth: 40 }}
                                title="Edit Message"
                                onClick={() => this.props.onEditButtonClick(this.props.defaultMessage, this.props.selectedMessage, this.props.typeId)}
                            >
                            </Bootstrap.Button>
                            <Bootstrap.Button
                                data-resource-id={RBACKeys.Settings.settings}
                                data-test-auto="F9D09E38-1B12-4F2C-B0B5-F605F62E5206"
                                variant="default"
                                className="btn btn-secondary btn-lg fas fa-trash-alt"
                                title="Delete Message"
                                style={{ marginLeft: 5, padding: 12, minWidth: 40}}
                                onClick={() => this.props.onDeleteButtonClick(this.props.selectedMessage, this.props.defaultMessage)}
                            >
                            </Bootstrap.Button>
                        </div>
                    </div>
                    <div className="row text-message">
                        <EmailMessageView
                            selectedMessage={this.props.emailMessages[this.props.selectedMessage] ? this.props.emailMessages[this.props.selectedMessage].emailMessage : initialEmailMessage}
                        />
                    </div>
                </div>
            </div>
            <Loader loading={this.props.showLoader} />
        </LoadingOverlay>)
    }
}