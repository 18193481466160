import React from 'react';
import SSREXLogo from './../images/Extensions_Horz_RGB.png';
import { Extension } from './svg/LogoCollection';

const LoadingView: React.FC = () => (

    <div className="loading-screen-container">

        <img className="full-logo" src={SSREXLogo} alt={"extension"} />
        <div data-testid="loading-spinner">
            <div className="loading-text">
                <div className="dot-spin"></div>
            </div>
        </div>
    </div>
);

LoadingView.displayName = 'LoadingView'

export default LoadingView
