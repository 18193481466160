import React from  'react';
import { FormGroup, FormLabel, Col, FormControl } from 'react-bootstrap';
import Select from 'react-select';
import { ITaxingAuthority, IEFile, IEFileProperty, SelectOptions } from '../../TaxReturn'

export interface RegroupToEFileProps {
    eFile: IEFile
    authorities: SelectOptions[],
    pageNo: number,
    onEFileDataUpdate: (propertyType: IEFileProperty, value: any, pageNumber: number) => void,
}

export class RegroupToEFile extends React.Component<RegroupToEFileProps>
{
    constructor(props: RegroupToEFileProps) {
        super(props);
    }

    render() {


        return (
            <div className="panel-body">
                <FormGroup className="row">
                    <Col className="text-left form-label" sm={3} as={FormLabel}>
                        Taxing Authority
                        </Col>
                    <Col sm={7}>
                        <Select
                            name="VoucherRefundTaxingAuthority"
                            value={this.props.eFile.authorityID}
                            onChange={(event: any) => { this.props.onEFileDataUpdate(IEFileProperty.authorityID, event.value, this.props.pageNo) }}
                            options={this.props.authorities}
                            data-test-auto="B650E19A-252E-422C-A3FD-46469E451EA2"
                            clearable={false}
                            className="font-size-14"
                        >
                        </Select>
                    </Col>
                </FormGroup>
                <FormGroup className="row">
                    <Col className="text-left form-label" sm={3} as={FormLabel}>
                        Form Name
                        </Col>
                    <Col sm={7}>
                        <FormControl
                            type="text"
                            value={this.props.eFile.formName}
                            placeholder="Form Name"
                            onChange={(event: any) => { this.props.onEFileDataUpdate(IEFileProperty.formName, event.target.value, this.props.pageNo) }}
                            data-test-auto="2C099E8F-94CB-4457-97FC-5BBE3666BB88"
                        />
                    </Col>
                </FormGroup>
            </div>
        );

    }
}
