import { IOnehubAccessCodeModel } from '../../components/common/OnehubAccessCodeModal';
import { SUITE_API_BASE_URL } from 'src/utils/contants';
import { AppThunkAction } from '..';
import { handleResponse } from '../Library';
import { addTask } from 'domain-task';
export interface ReceiveOnehubAccessCodeAction {
    oneHubAccessCodeDetails: IOnehubAccessCodeModel[];
}

export interface GenerateOnehubAccesCodeAction {
    oneHubAccessCodeModel: IOnehubAccessCodeModel;
}

type KnownAction = ReceiveOnehubAccessCodeAction | GenerateOnehubAccesCodeAction;

export const actionCreators = {
    requestOnehubAccessCode:
        (
            primaryEmails: string[],
            callback?: (response: IOnehubAccessCodeModel[]) => void,
            resourceId: string = ''
        ): AppThunkAction<KnownAction> =>
        () => {
            const fetchTask = fetch(SUITE_API_BASE_URL + 'api/one-hub/access-code', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'X-Resource-Id': resourceId,
                    Accept: 'application/json, */*',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(primaryEmails),
            })
                .then(handleResponse)
                .then(response => response as Promise<IOnehubAccessCodeModel[]>)
                .then(data => {
                    if (callback) {
                        callback(data);
                    }
                });
            addTask(fetchTask);
        },

    generateOnehubAccessCode:
        (
            primaryEmailAddress: string,
            callBack?: (response: IOnehubAccessCodeModel) => void,
            resourceId: string = ''
        ): AppThunkAction<KnownAction> =>
        () => {
            const fetchTask = fetch(
                SUITE_API_BASE_URL + 'api/one-hub/generate-access-code?primaryEmail=' + primaryEmailAddress,
                {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'X-Resource-Id': resourceId,
                    },
                }
            )
                .then(handleResponse)
                .then(response => {
                    if (callBack) {
                        callBack(response);
                    }
                })
                .catch(error => {});
            addTask(fetchTask);
        },
};
