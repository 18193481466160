import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';

import { actionTypes } from '../ActionTypes';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { GetMyDownloadsList } from '../company/CompanyStore';
import { handleResponse } from '../Library';
import { DeleteRecycledExtensionConstants, DeliveredReturnsConstants, RestoreReturnConstants } from '../../components/helper/Constants';
import { BulkOperationQuery, BulkOperationQueryExt, RecycleBulkOperationQuery } from '../../Core/ViewModels/Common/BulkOperationQuery';
import { IColumnValues } from '../../components/settings/GeneralSettings';
import * as Helper from '../../components/helper/HelperFunctions';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';
import { DefaultDownloadInfoType } from '../userManagement/ExtensionUserSettingStore';
import { IScheduleReminderPayload } from 'src/components/common/DeliveredTaxReturns';


type AllKnownAction =
    GetMyDownloadsList |    
    NotificationAction;

export const actionCreators = {

    sendRemindersBulk: (query:BulkOperationQuery,resourceId: string, callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
       const fetchTask = fetch(`${API_BASE_URL}api/BulkOperations/SendReminderAsync`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(query),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'X-Resource-Id': resourceId
            },
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.BulkOperationMessage.SendReminderWaitProcessing,
                    statusType: StatusType.Success
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.BulkOperationMessage.SendReminderError,
                    statusType: StatusType.Error
                });
                logger.trackError(`sendRemindersBulk failed for query: ${query}, with error ${error.message}`);
                if (callback) {
                    callback();
                }
            });
        addTask(fetchTask);
    },

    sendScheduledRemindersBulk: (query:BulkOperationQuery,resourceId: string, autoReminderSettings: IScheduleReminderPayload, callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/BulkOperations/SendAutoReminderAsync`, {
             method: 'POST',
             credentials: 'include',
             body: JSON.stringify({
                filters: query,
                autoReminderSettings
            }),
             headers: {
                 'Accept': 'application/json, text/plain, */*',
                 'Content-Type': 'application/json; charset=utf-8',
                 'X-Resource-Id': resourceId
             },
         })
             .then(handleResponse)
             .then(() => {
                 dispatch({
                     type: actionTypes.NOTIFICATION,
                     statusMessage: DeliveredReturnsConstants.BulkOperationMessage.SendReminderWaitProcessing,
                     statusType: StatusType.Success
                 });
                 if (callback) {
                     callback();
                 }
             })
             .catch((error) => {
                 dispatch({
                     type: actionTypes.NOTIFICATION,
                     statusMessage: DeliveredReturnsConstants.BulkOperationMessage.SendReminderError,
                     statusType: StatusType.Error
                 });
                 logger.trackError(`sendRemindersBulk failed for query: ${query}, with error ${error.message}`);
                 if (callback) {
                     callback();
                 }
             });
         addTask(fetchTask);
     },

    customcolumnUpdateBulk: (query: BulkOperationQuery, customColumn: IColumnValues, apiEndPoint: string, callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {

        let endPoint: string = `${API_BASE_URL}api/BulkOperations/` + apiEndPoint;
        const fetchTask = fetch(endPoint, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                query: query,
                columnValue: customColumn
            }),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            },
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.BulkOperationMessage.Processing,
                    statusType: StatusType.Success
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.BulkOperationMessage.CustomColumnError,
                    statusType: StatusType.Error
                });
                logger.trackError(`customcolumnUpdateBulk failed for query: ${query}, with error ${error.message}`);
                if (callback) {
                    callback();
                }
            });
        addTask(fetchTask);
    },

    archiveDocumentsBulk: (query: BulkOperationQuery, callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(`${API_BASE_URL}api/BulkOperations/ArchiveDocumentsAsync`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(query),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            },
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.BulkOperationMessage.Processing,
                    statusType: StatusType.Success
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.BulkOperationMessage.ArchiveError,
                    statusType: StatusType.Error
                });
                logger.trackError(`archiveDocumentsBulk failed for query: ${query}, with error ${error.message}`);
                if (callback) {
                    callback();
                }
            });
        addTask(fetchTask);

    },

    deleteDocumentsBulk: (query: BulkOperationQuery, resourceId: string, callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {

        let fetchTask = fetch(`${API_BASE_URL}api/BulkOperations/DeleteDocumentsAsync`, {
            method: 'DELETE',
            credentials: 'include',
            body: JSON.stringify(query),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'X-Resource-Id': resourceId
            },
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.BulkOperationMessage.Processing,
                    statusType: StatusType.Success
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.BulkOperationMessage.DeleteReturnsError,
                    statusType: StatusType.Error
                });
                logger.trackError(`deleteDocumentsBulk failed for query: ${query}, with error ${error.message}`);
                if (callback) {
                    callback();
                }
            });
        addTask(fetchTask);

    },

    bulkReturnRestore: (query: RecycleBulkOperationQuery, callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        let fetchTask = fetch(`${API_BASE_URL}api/BulkOperations/BulkRestoreDocumentsAsync`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(query),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            },
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: RestoreReturnConstants.BulkOperationMessage.Processing,
                    statusType: StatusType.Success
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                if (error.statusText != null && error.statusText.trim() == "One or more return(s) expired") {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: RestoreReturnConstants.ReturnExpired.Restore,
                        statusType: StatusType.Warning
                    });
                    if (callback) {
                        callback();
                    }
                }
                else {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: RestoreReturnConstants.BulkOperationMessage.Error,
                        statusType: StatusType.Error
                    });
                    if (callback) {
                        callback();
                    }
                }
                logger.trackError(`bulkReturnRestore failed for query: ${query}, with error ${error.message}`);
            });
        addTask(fetchTask);

    },
    bulkExtensionDelete: (query: RecycleBulkOperationQuery, callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        let fetchTask = fetch(`${API_BASE_URL}api/BulkOperations/BulkDeleteRecycledExtensionsAsync`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(query),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            },
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeleteRecycledExtensionConstants.BulkDeleteOperationMessage.Processing,
                    statusType: StatusType.Success
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeleteRecycledExtensionConstants.BulkDeleteOperationMessage.Error,
                    statusType: StatusType.Error
                });
                if (callback) {
                    callback();
                }
                logger.trackError(`bulkExtensionDelete failed for query: ${query}, with error ${error.message}`);
            });
        addTask(fetchTask);

    },
    downloadEFileFormsBulk: (documentIds: number[], isBulkSelectionEnabled: boolean, query: BulkOperationQueryExt, selectedDownloadType: DefaultDownloadInfoType, resourceId: string, callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        let state = getState();

        let apiEndPoint = isBulkSelectionEnabled ? 'api/BulkOperations/DownloadEFileDocumentsWithBulkAsync' : 'api/BulkOperations/DownloadEFileDocumentsAsync';
        let body = isBulkSelectionEnabled ? JSON.stringify(query) : JSON.stringify({ query: query, documentIds: documentIds });

        let fetchTask = fetch(`${API_BASE_URL}${apiEndPoint}`, {
            method: 'POST',
            credentials: 'include',
            body: body,
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'X-Resource-Id': resourceId
            },
        })
            .then(handleResponse)
            .then((response: any) => {
               
                if (response.id == undefined) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: DeliveredReturnsConstants.ErrorMessage.UploadedESignedEfileFormsCanOnlyDownloadable,
                        statusType: StatusType.Error
                    });
                }
                else {
                    if ((!selectedDownloadType) && state.extensionUserSettings.defaultSettings.download.rememberDefaultDownload == true) {
                        selectedDownloadType = state.extensionUserSettings.defaultSettings.download.downloadType;
                    }
                    if (selectedDownloadType == DefaultDownloadInfoType.DownloadNow) {
                        if (Helper.SessionStore.isExists("DownloadNowIds")) {
                            let DownloadNowIds = Helper.SessionStore.get("DownloadNowIds");
                            var ids = JSON.parse(DownloadNowIds) as string[];
                            ids.push(response.id)
                            Helper.SessionStore.set("DownloadNowIds", JSON.stringify(ids));
                        }
                        else {
                            let ids = [response.id];
                            Helper.SessionStore.set("DownloadNowIds", JSON.stringify(ids));
                        }
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: DeliveredReturnsConstants.BulkOperationMessage.downloadEFileNowWaitProcessing,
                            statusType: StatusType.Success
                        });
                    }
                    else {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: DeliveredReturnsConstants.BulkOperationMessage.downloadEFileWaitProcessing,
                            statusType: StatusType.Success
                        });
                    }

                    if (selectedDownloadType != DefaultDownloadInfoType.DownloadNow) {
                        let data = state.companyData.myDownloads;
                        data.unshift(response);
                        dispatch({ type: actionTypes.RECEIVE_MY_DOWNLOADS_LIST, myDownloadsList: data });
                    }
                }
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.BulkOperationMessage.downloadEFileError,
                    statusType: StatusType.Error
                });
                logger.trackError(`downloadEFileFormsBulk failed for query: ${query}, with error ${error.message}`);
                if (callback) {
                    callback();
                }
            });
        addTask(fetchTask);
    },

    exportToExcelBulk: (query: BulkOperationQuery, callback: any, rbacKey: string): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        let fetchTask = fetch(`${API_BASE_URL}api/BulkOperations/ExportToExcelAsync`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(query),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'X-Resource-Id': rbacKey
            },
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.BulkOperationMessage.Processing,
                    statusType: StatusType.Success
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: DeliveredReturnsConstants.BulkOperationMessage.ExportToExcelError,
                    statusType: StatusType.Error
                });
                if (callback) {
                    callback();
                }
            });
        addTask(fetchTask);
    },

};