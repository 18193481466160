import React from  'react';
import { RouteComponentProps } from 'react-router';
import { /*Panel,*/ Row, Col, Button, Alert } from 'react-bootstrap';
import { TaxCaddyLookupResultModel, TaxCaddyLookupResult, initialTaxCaddyDeliveryViewModel } from '../../../common/TaxReturn';
import { RadioButtonComponent } from '../../../common/RadioButtonComponent';
import { OverlayLoader } from '../../../helper/OverlayLoader';
import { FinishProcessReturn } from '../../../helper/Constants';
import { ICompanySettings } from '../../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { getMaskedValue } from '../../../../components/helper/HelperFunctions';
import { TaxCaddyFull, SafeSendReturns } from '../../../svg/LogoCollection';
import * as CompanyStore from 'src/store/company/CompanyStore';

interface DeliverWithTaxCaddyProps {
    name: string;
    ssn: string;
    address: string;
    emailAddress: string;
    isAccepted: boolean;
    deliverWithTaxCaddy: () => void;
    cancelFinish: () => void;
    taxCaddyLookupResultModel: TaxCaddyLookupResultModel;
    deliverWithTaxcaddy: (taxCaddyLookupResult: TaxCaddyLookupResult) => void;
    companySetting: ICompanySettings;    
    companyData: CompanyStore.ICompanyData;
    requestCompanyLogo: (reload?: boolean, isLogoUpdate?: boolean) => void;
}

interface DeliverWithTaxCaddyState {
    taxCaddyLookupResult: TaxCaddyLookupResult;
}
export enum TaxCaddyLookup {
    Accept,
    Reject
}

export class DeliverWithTaxCaddy extends React.Component<DeliverWithTaxCaddyProps, DeliverWithTaxCaddyState> {
    constructor(props: DeliverWithTaxCaddyProps) {
        super(props);
        this.state = {
            taxCaddyLookupResult: {
                ...initialTaxCaddyDeliveryViewModel,
                isTaxCaddyLookupAccepted: this.props.isAccepted || this.props.companySetting.deliverySettingsModel.isByPassManualVerification
            }
        }
    }

    componentDidMount() {
        this.props.requestCompanyLogo(true, true);
    }

    componentWillUnmount() {
        this.props.requestCompanyLogo(true);
    }

    componentWillReceiveProps(nextProps: DeliverWithTaxCaddyProps) {
        if (this.props.isAccepted !== nextProps.isAccepted) {           
            this.setState({
                taxCaddyLookupResult: {
                    ...this.state.taxCaddyLookupResult,
                    isTaxCaddyLookupAccepted: nextProps.isAccepted || nextProps.companySetting.deliverySettingsModel.isByPassManualVerification
                }
            })           
        }   
        
    }

    onChangeTaxCaddyLookupAccepted = (taxCaddyLookup: TaxCaddyLookup) => {
        let temp = Object.assign({}, this.state.taxCaddyLookupResult);
        temp.isTaxCaddyLookupAccepted = taxCaddyLookup == TaxCaddyLookup.Accept ? true : false;
        this.setState({
            taxCaddyLookupResult: temp
        })
    }
    public render() {
        return <div>
            {
                //<Panel defaultExpanded>
                //    <Panel.Body>
                //        <OverlayLoader show={this.props.taxCaddyLookupResultModel ? false : true} text={"Loading..."} />
                //        <div>
                //            <Row className="marB15">
                //                <Col className="col-sm-12 col-lg-12">
                //                    <Col className="col-sm-6 col-lg-6" style={{ paddingLeft: 0, paddingRight: 0 }}>
                //                        <ul style={{ wordBreak: "break-word", paddingLeft: 0 }}>
                //                            <li className="custom-list-group-item ">
                //                                <img src={(this.props && this.props.companyData && this.props.companyData.companyLogoSetting) ? this.props.companyData.companyLogoSetting.logoPath : ''} style={{ height: '30px' }} />
                //                            </li>
                //                            <li className="custom-list-group-item ">
                //                                {this.props.name ? this.props.name : '\u00A0'}
                //                            </li>
                //                            <li className="custom-list-group-item ">
                //                                {this.props.ssn ? getMaskedValue(this.props.ssn, "000-00-0000") : '\u00A0'}
                //                            </li>
                //                            <li className="custom-list-group-item ">
                //                                {this.props.emailAddress ? this.props.emailAddress : '\u00A0'}
                //                            </li>
                //                            <li className="custom-list-group-item ">
                //                                {this.props.address ? this.props.address : '\u00A0'}
                //                            </li>
                //                        </ul>
                //                    </Col>
                //                    <Col className="col-sm-6 col-lg-6" style={{ paddingLeft: 0, paddingRight: 0 }}>
                //                        <ul style={{ wordBreak: "break-word" }}>
                //                            <li className="custom-list-group-item ">
                //                                <TaxCaddyFull height={30} />
                //                            </li>
                //                            <li className="custom-list-group-item ">
                //                                {this.props.taxCaddyLookupResultModel && this.props.taxCaddyLookupResultModel.resultObjectModel.taxpayerName ?
                //                                    this.props.taxCaddyLookupResultModel.resultObjectModel.taxpayerName : '\u00A0'}
                //                                {this.props.taxCaddyLookupResultModel && this.props.name === this.props.taxCaddyLookupResultModel.resultObjectModel.taxpayerName ?
                //                                    <span><i className='fa fa-check pull-right' style={{ color: "green" }}></i></span> :
                //                                    <span><i className='fa fa-exclamation pull-right' style={{ color: "#FFC107" }}></i></span>}

                //                            </li>
                //                            <li className="custom-list-group-item ">
                //                                {this.props.taxCaddyLookupResultModel && this.props.taxCaddyLookupResultModel.resultObjectModel.ssn ?
                //                                    this.props.taxCaddyLookupResultModel.resultObjectModel.ssn : '\u00A0'}
                //                                {this.props.taxCaddyLookupResultModel && this.props.taxCaddyLookupResultModel.resultObjectModel.ssn && this.props.ssn.replace(/[^0-9]/g, "").trim() === this.props.taxCaddyLookupResultModel.resultObjectModel.ssn.replace(/[^0-9]/g, "").trim()
                //                                    ? <span><i className='fa fa-check pull-right' style={{ color: "green" }}></i></span> :
                //                                    <span><i className='fa fa-exclamation pull-right' style={{ color: "#FFC107" }}></i></span>}
                //                            </li>
                //                            <li className="custom-list-group-item ">
                //                                {this.props.taxCaddyLookupResultModel && this.props.taxCaddyLookupResultModel && this.props.taxCaddyLookupResultModel.resultObjectModel.emailAddress ?
                //                                    this.props.taxCaddyLookupResultModel.resultObjectModel.emailAddress : '\u00A0'}
                //                                {this.props.taxCaddyLookupResultModel && this.props.emailAddress === this.props.taxCaddyLookupResultModel.resultObjectModel.emailAddress ?
                //                                    <span><i className='fa fa-check pull-right' style={{ color: "green" }}></i></span> :
                //                                    <span><i className='fa fa-exclamation pull-right' style={{ color: "#FFC107" }}></i></span>}
                //                            </li>
                //                            <li className="custom-list-group-item ">
                //                                {this.props.taxCaddyLookupResultModel && this.props.taxCaddyLookupResultModel.resultObjectModel.address ?
                //                                    this.props.taxCaddyLookupResultModel.resultObjectModel.address : '\u00A0'}
                //                                {this.props.taxCaddyLookupResultModel && this.props.address === this.props.taxCaddyLookupResultModel.resultObjectModel.address ?
                //                                    <span><i className='fa fa-check pull-right' style={{ color: "green" }}></i></span> :
                //                                    <span><i className='fa fa-exclamation pull-right' style={{ color: "#FFC107" }}></i></span>}
                //                            </li>
                //                        </ul>
                //                    </Col>
                //                </Col>
                //            </Row>
                //            {
                //                this.props.taxCaddyLookupResultModel && this.props.taxCaddyLookupResultModel.isSuccess ?
                //                    <div className="rdo-btn-group">
                //                        <RadioButtonComponent text="Accept"
                //                            id="accept"
                //                            checked={this.state.taxCaddyLookupResult.isTaxCaddyLookupAccepted}
                //                            onClick={() => { this.onChangeTaxCaddyLookupAccepted(TaxCaddyLookup.Accept) }}
                //                        />
                //                        <RadioButtonComponent text="Reject"
                //                            id="reject"
                //                            checked={!this.state.taxCaddyLookupResult.isTaxCaddyLookupAccepted}
                //                            onClick={() => { this.onChangeTaxCaddyLookupAccepted(TaxCaddyLookup.Reject) }}
                //                        />
                //                    </div> : null
                //            }
                //        </div>
                //        <div>
                //            {
                //                this.props.taxCaddyLookupResultModel && this.props.taxCaddyLookupResultModel.isSuccess ?
                //                    (this.state.taxCaddyLookupResult.isTaxCaddyLookupAccepted ?
                //                        <div>
                //                            <div className="info"><i className='fas fa-exclamation-circle' style={{ marginRight: '5px', color: '#666' }}></i>
                //                                {FinishProcessReturn.InfoMessage.TaxCaddyApprove}
                //                            </div>
                //                            {this.props.companySetting.deliverySettingsModel.isDirectDeliveryToTaxCaddy &&
                //                                <div className="text-warning">
                //                                    <i className='fas fa-exclamation-triangle' style={{ marginRight: '5px', paddingTop: '5px' }}></i>
                //                                    {FinishProcessReturn.InfoMessage.AttchmentNotDeliverToTaxCaddy}
                //                                </div>
                //                            }
                //                        </div> :
                //                        <div className="text-warning"><i className='fas fa-exclamation-triangle' style={{ marginRight: '5px' }}></i>
                //                            {FinishProcessReturn.InfoMessage.TaxCaddyReject}
                //                        </div>)
                //                    : this.props.taxCaddyLookupResultModel ? (<div className="text-warning">
                //                        <i className='fas fa-exclamation-triangle' style={{ marginRight: '5px' }}></i>
                //                        {this.props.taxCaddyLookupResultModel.result}{FinishProcessReturn.InfoMessage.NotDeliveredToTaxcaddy}
                //                    </div>) : null

                //            }
                //        </div>
                //        <hr />
                //        <div>
                //            <Col sm={12}>
                //                <Button type="button" variant="info" className="pull-right" data-test-auto="F73C4A7C-4C50-4118-B7FE-D8A12CFE67A8"
                //                    onClick={() => { this.props.deliverWithTaxcaddy(this.state.taxCaddyLookupResult) }}
                //                ><i className="fa fa-paper-plane text-secondary" style={{ paddingRight: "10px" }}></i>Send</Button>
                //                <Button type="button" className="pull-right marR05 btn-white btn-default" data-test-auto="06C5CC80-42F4-4407-B3A4-DDD83A511865"
                //                    onClick={this.props.cancelFinish}
                //                ><i className="fas fa-times" style={{ paddingRight: "10px" }}></i>Cancel</Button>
                //            </Col>
                //        </div>
                //    </Panel.Body>
                //</Panel>
            }</div>
    }
}

export default DeliverWithTaxCaddy;