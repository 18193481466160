import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../../';
import { actionTypes } from '../../ActionTypes';

import { StatusType, NotificationAction } from '../../common/NotificationStore';

import {
    RequestVoucherReminderStatusAction,
    ReceiveVoucherReminderStatusAction,
    RequestVoucherReminderReportPagesAction,
    ReceiveVoucherReminderReportPagesAction
} from "./../KnownTypes";
import { VoucherReminderReportState, VoucherReminderReportTableModel } from './VoucherReminderManagementState';
import { LoggerFactory } from '../../../Logger/LoggerFactory';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';

type KnownAction =
    RequestVoucherReminderStatusAction |
    ReceiveVoucherReminderStatusAction |
    RequestVoucherReminderReportPagesAction |
    ReceiveVoucherReminderReportPagesAction;

type AllKnownAction =
    RequestVoucherReminderStatusAction |
    ReceiveVoucherReminderStatusAction |
    RequestVoucherReminderReportPagesAction |
    ReceiveVoucherReminderReportPagesAction |
    NotificationAction;


export const actionCreators = {

    requestVoucherReminderReport: (query: string, reload: boolean = false): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        let state = getState();

        if (reload || query !== state.voucherReminderReport.query) {

            let page = state.voucherReminderStatusPages[query];
            if (!reload && page) {

                dispatch({ type: actionTypes.REQUEST_VOUCHER_REMINDER_REPORT, query: query });

                dispatch({
                    type: actionTypes.RECEIVE_VOUCHER_REMINDER_REPORT,
                    query: query,
                    table: page.voucherReminderReportTableModel                   
                });

                return;
            }
            const fetchTask = fetch(`${API_BASE_URL}api/Reports/VoucherReminderManagement/GetReminderReport` + query, {
                method: 'GET',
                credentials: 'include'
            })
                .then(function (response ) {
                     
                    return response.json() as Promise<VoucherReminderReportTableModel>
                })
                .then(function (data) {
                    dispatch({ type: actionTypes.RECEIVE_VOUCHER_REMINDER_REPORT, query: query, table: data });
                    dispatch({
                        type: actionTypes.RECEIVE_VOUCHER_REMINDER_REPORT_PAGES,
                        query: query,
                        table: data.reminders,
                        totalRowCount: data.count
                    });
                })
                .catch(function (error) {
                    logger.trackError(`requestVoucherReminderReport failed for query: ${query}, with error ${error.message}`);
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_VOUCHER_REMINDER_REPORT, query: query });
            dispatch({ type: actionTypes.REQUEST_VOUCHER_REMINDER_REPORT_PAGES, query: query });
        }
    },
    
    exportVoucherReminderReportAsExcel: (query: string, callback?: () => void): AppThunkAction<AllKnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/ExportToExcel/ExportExcelVoucherReminderStatus` + query, { credentials: 'include' })
            .then(response => {
                window.location.href = response.url;
                if (callback) {
                    callback();
                }
            }).catch((error) => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });
                logger.trackError(`exportVoucherReminderReportAsExcel failed for query: ${query}, with error ${error.message}`);
                if (callback) {
                    callback();
                }
            });
        addTask(fetchTask);
    },
};


const unloadedState: VoucherReminderReportState = {
    voucherReminderReportTableModel: {
        reminders: [],
        count: 0
    } as VoucherReminderReportTableModel,
    loading: true,
    query: '',
    totalRowCount: 0
} as VoucherReminderReportState;

export const reducer: Reducer<VoucherReminderReportState> = (state: VoucherReminderReportState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_VOUCHER_REMINDER_REPORT:
            return ({
                ...unloadedState,
                query: action.query,
                loading: true
            }) as VoucherReminderReportState;
        case actionTypes.RECEIVE_VOUCHER_REMINDER_REPORT:
            return {
                query: action.query,
                voucherReminderReportTableModel: action.table,
                totalRowCount: action.table.count,
                loading: false
            } as VoucherReminderReportState;
    }

    return state || unloadedState;
};

