import React from  'react';
import { Button, Modal, Col } from 'react-bootstrap';
import { VenusNotifier } from './../../../helper/VenusNotifier';
import { GroupExtensionConstants } from './../../../helper/Constants';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { GroupInfo, initialGroupInfo } from '../../../../Core/ViewModels/GroupExtensions/ComponentModels';

interface IEditGroupInfoModalProps {
    groupInfo: GroupInfo;
    groupInfoList: GroupInfo[];
    onGroupInfoChange: (event: any, callback: () => void, failureCallback: () => void) => void;
    showEditGroupInfo: boolean;
    onCancel: () => void;
}

interface IEditGroupInfoModalStates {
    saving: boolean;
    message: string;
    groupInfo: GroupInfo;
}
const messages = {
    saving: 'Submitting the changes...',
}

export class EditGroupInfoModal extends React.Component<IEditGroupInfoModalProps, IEditGroupInfoModalStates> {
    constructor(props: IEditGroupInfoModalProps) {
        super(props);

        this.state = {
            saving: false,
            message: '',
            groupInfo: Object.assign({}, initialGroupInfo),
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onGroupNameChange = this.onGroupNameChange.bind(this);
    }

    componentWillReceiveProps(nextProps: IEditGroupInfoModalProps) {
        const saving = !nextProps.showEditGroupInfo ? false : this.state.saving;
        this.setState({
            groupInfo: { ...nextProps.groupInfo },
            saving: saving
        });
    }

    private isGroupExists = (groupName: string) => {
        let isValid: boolean = true;
        this.props.groupInfoList.map((list, index) => {
            if (list.name.toLowerCase() == groupName.trim().toLowerCase() && list.id !== this.props.groupInfo.id) {
                this.setState({ saving: false, message: "" });
                VenusNotifier.Warning(GroupExtensionConstants.ValidationMessage.GroupConfigurationAlreadyExist, null);
                isValid = false;
            }
        });
        return isValid;
    }

    private onGroupNameChange = (event: any) => {
        const temp = this.state.groupInfo;
        temp.name = event.target.value;
        temp.isModified = true;
        this.setState({
            groupInfo: temp,
        });
    }

    private onSubmit = (e: any) => {
        e.preventDefault();
        if (!this.state.saving) {
            this.setState({ saving: true, message: messages.saving }, () => {
                if (this.state.groupInfo.name.trim() != "") {
                    if (this.isGroupExists(this.state.groupInfo.name)) {
                        this.props.onGroupInfoChange(this.state.groupInfo,
                            () => this.setState({
                                groupInfo: Object.assign({}, initialGroupInfo),
                                saving: false,
                                message: ""
                            }),
                            () => this.setState({
                                saving: false,
                                message: ""
                            })
                        );
                    }
                }
                else {
                    this.setState({ saving: false, message: "" });
                    VenusNotifier.Warning(GroupExtensionConstants.ValidationMessage.GroupConfigurationWarning, null);
                }
            });
        }
    }

    public render() {

        return <Modal
            className="group-configuration-modal"
            show={this.props.showEditGroupInfo}
            onHide={this.props.onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <span className='modalIcon text-secondary fa fa-user-check'>
                    </span>Group Configuration
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoadingOverlay style={{ height: '100%' }}>
                    <div className="row">
                        <Col sm={12} className="disp-flex">
                            <Col sm={4} className="marT5 marB05">
                                Current Group Name
                                </Col>
                            <Col sm={6} className="marT5 marB05">
                                {this.props.groupInfo ? this.props.groupInfo.name : ""}
                            </Col>
                        </Col>
                    </div>
                    <div>
                        <div className="row">
                            <Col sm={12} className="disp-flex">
                                <Col sm={4} className="marT5 marB05">
                                    New Group Name
                                </Col>
                                <Col sm={6}>
                                    <input
                                        className="form-control"
                                        placeholder="Type here to update Group Name"
                                        maxLength={200}
                                        value={this.state.groupInfo ? this.state.groupInfo.name : ''}
                                        onChange={this.onGroupNameChange}
                                    ></input>
                                </Col>
                            </Col>
                        </div>
                    </div>
                    <Loader loading={this.state.saving} text={this.state.message} />
                </LoadingOverlay>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="default"
                    className="btn-white"
                    disabled={this.state.saving}
                    onClick={this.props.onCancel}
                ><i className='fas fa-times'></i>Cancel</Button>
                <Button
                    variant="info"
                    disabled={this.state.saving}
                    onClick={this.onSubmit}
                ><i className='fas fa-save'></i>Save</Button>
            </Modal.Footer>
        </Modal>
    }
}