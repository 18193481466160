import React from  'react';
import { RBACKeys } from 'src/components/helper/RbacConstants';
import { ExtensionCompanySettings } from '../../../Core/ViewModels/Extension/ExtensionComponentModels';
import { CheckBoxComponent } from '../../common/CheckBoxComponent';

interface IKBA {
    companySettings: ExtensionCompanySettings;
    handleEnableKBA(event: any): void;
    handleEnableDelegateeKBA(event: any): void;
    disableESignature: boolean;
}
export class KnowledgeBasedAuthentication extends React.Component<IKBA> {
    constructor(props: any) {
        super(props);
    }

    public render() {
        return <div id="divKnowledgeBasedAuthentication" className="new-main-content">
            <h3>1040 Knowledge Based Authentication</h3>
            <div className="marL20">
                <CheckBoxComponent
                    id={"kbaAuthentication"}
                    text={"Use Knowledge-Based Authentication to initiate the Signing Process (Required by IRS)"}
                    onChange={this.props.handleEnableKBA}
                    checked={this.props.companySettings.signatureSettingsModel ?
                        this.props.companySettings.signatureSettingsModel.knowledgeBasedAuthentication : false}
                    datatestAuto={"7EBE4B14-EE9B-4B39-9DD8-75F66E67EE70"}
                    disabled={this.props.disableESignature}
                    resourceId={RBACKeys.Settings.settings}
                />
            </div>
            <div className="marL20">
                <CheckBoxComponent
                    id={"delegateeKbaAuthentication"}
                    text={"Use Knowledge-Based Authentication to initiate the Signing Process for delegated signers (Required by IRS)"}
                    onChange={this.props.handleEnableDelegateeKBA}
                    checked={this.props.companySettings.signatureSettingsModel ?
                        this.props.companySettings.signatureSettingsModel.enableDelegateeKbaAuthentication : false}
                    datatestAuto={"1A579285-FB0E-419D-B9CE-36398EB80103"}
                    disabled={
                        !this.props.companySettings.signatureSettingsModel.enableIndividualReturnSignerDelegation ||
                        this.props.disableESignature
                    }
                    resourceId={RBACKeys.Settings.settings}
                />
            </div>
        </div>;
    }
}

export default KnowledgeBasedAuthentication;