import * as bootbox from 'bootbox';
import 'isomorphic-fetch';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import * as TaxDocumentStore from '../../store/common/TaxDocumentStore';
import * as FilterStore from '../../store/reports/FilterStore';
import * as InUseExtensionsState from '../../store/reports/InUseExtensionsState';
import * as InUseExtensionsStore from '../../store/reports/InUseExtensionsStore';
import * as UserStore from '../../store/userManagement/UserStore';
import { IInUseTaxReturns } from '../common/InUseTaxReturns';
import { VenusNotifier } from '../helper/VenusNotifier';
import { IFilters, SortDirections, ReportFilterType } from './Filters';
import { InUseExtensionsHeader } from './InUseExtensions/InUseExtensionsHeader';
import { InUseExtensionsTable } from './InUseExtensions/InUseExtensionsTable';
import * as UserSettingStore from '../../store/userManagement/UserSettingStore';
import { DeliveredReturnsConstants, InUseExtensionsConstants, ReportFilterConstants, clearedFilter } from '../helper/Constants'
import * as CompanyStore from '../../store/company/CompanyStore';
import { IUserModel } from '../../Core/ViewModels/User/UserViewModel';
import * as UploadTaxReturnStore from '../../store/common/uploadTaxReturn';
import * as Helper from '../helper/HelperFunctions';
import { initialCompanySettings } from '../../Core/ViewModels/Company/CompanySettingsViewModel';
import * as GroupedExtensionsStore from '../../store/groupedExtensions/GroupedExtensionsStore';
import { GroupedExtensionStoreState } from '../../Core/ViewModels/GroupExtensions/StoreModels';
import { logClientEvent } from '../helper/LoggerHelper';
import { RBACKeys } from '../helper/RbacConstants';
import { initialSuiteTaxSoftwareSettings } from 'src/Core/ViewModels/Company/SuiteCompanySettings/SuiteTaxSoftwareComponentModel';
import * as TaxSoftwareStore from '../../store/SuiteCompany/TaxSoftwareStore'
import { SuiteTaxSoftwareSettingsStoreState } from 'src/Core/ViewModels/Company/SuiteCompanySettings/SuiteTaxSoftwareStoreModels';
import * as ExtensionUserSettingsStore from '../../store/userManagement/ExtensionUserSettingStore';
import { LogEventConstants } from '../helper/LogEventConstants';
import * as UserOfficeLocationStore from '../../store/common/UserOfficeLoacationStore';
import { IDocumentAccess } from '../common/TaxReturn';

let moment = require('moment');
const pageSize: number = 20;
const NO_INDEX = -1;

export interface INameValue {
    [index: string]: string
}

const successMsg: INameValue = {
    makeAvailableState: 'Extension unlocked successfully.'
    // uploadFormsState: 'Successfully uploaded the form for the extension'
}

const errorMsg: INameValue = {
    makeAvailableState: 'Failed to unlock Extension(s)'
    // uploadFormsState: 'Failed to upload the form for the extension'
}

type InUseExtensionsProps =
    {
        inUse: InUseExtensionsState.InUseExtensionsState;
        taxDocuments: TaxDocumentStore.ITaxDocumentDictionary;
        usersStore: UserStore.UsersStore;
        reportFilters: FilterStore.IFilterState;
        userSettings: UserSettingStore.UserSettings;
        company: CompanyStore.ICompanyData;
        uploadTaxReturnStore: UploadTaxReturnStore.IUploadTaxReturnState;
        groupedExtensionsStore: GroupedExtensionStoreState;
        taxSoftwareData: SuiteTaxSoftwareSettingsStoreState;
        extensionUserSettings: ExtensionUserSettingsStore.IExtensionUserSettings;
        userOfficeLocation:UserOfficeLocationStore.IUserOfficeLocationState
    }
    & typeof InUseExtensionsStore.actionCreators
    & typeof TaxDocumentStore.actionCreators
    & typeof UserStore.actionCreators
    & typeof FilterStore.actionCreators
    & typeof UserSettingStore.actionCreators
    & typeof CompanyStore.actionCreators
    & typeof UploadTaxReturnStore.actionCreators
    & typeof GroupedExtensionsStore.actionCreators
    & typeof TaxSoftwareStore.actionCreators
    & typeof ExtensionUserSettingsStore.actionCreators
    &typeof UserOfficeLocationStore.actionCreators
    & RouteComponentProps<{ page: string }>;

const pageTitle = "InUseExtension";
export class InUseExtensions extends React.Component<InUseExtensionsProps, InUseExtensionsState.InUseExtensionsPageState> {
    private proxyFilter = ReportFilterType[ReportFilterType.InUseExtension].toUpperCase() + ReportFilterConstants.DefaultFilterBuilder;
    private reportFilterType = ReportFilterType.InUseExtension;
    private taxYearList: number[] = [];
    constructor(props: InUseExtensionsProps) {
        super(props);
        this.state = {
            page: 1,
            pageSize: pageSize,
            filter:clearedFilter,
            selectedRows: [],
            searchString: "",
            sortName: "",
            sortOrder: "desc",
            saveFilterShow: false,
            filterClientId: "",
            filterTaxYear:0,
            filterInUseOn: undefined,
            filterDownloadedBy: "",
            filterEngagementType: 0,
            userId: 0,
            filterName: "",
            filterPartner: "",
            filterSentBy: "",
            filterStatus: [],
            filterOfficeLocation:"",
            signatureStatusList: [],
            selectedStatus: 0,
            //makeAvailable states
            makeAvailableState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined
            },
            refreshDelay: false,
            defaultGroup: '',
        };

        this.applyDefaultFilter = this.applyDefaultFilter.bind(this);
        this.onFilterSave = this.onFilterSave.bind(this);
        this.onFilterUpdate = this.onFilterUpdate.bind(this);
        this.onFilterDelete = this.onFilterDelete.bind(this);
        this.onSetDefaultFilter = this.onSetDefaultFilter.bind(this);
        this.onSetDefaultFilterSuccess = this.onSetDefaultFilterSuccess.bind(this);
        this.onRemoveDefaultFilter = this.onRemoveDefaultFilter.bind(this);
        this.onRemoveDefaultFilterSuccess = this.onRemoveDefaultFilterSuccess.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onSaveFilterShow = this.onSaveFilterShow.bind(this);
        this.onSaveFilterHide = this.onSaveFilterHide.bind(this);
        this.onRequestUserSettingsSuccess = this.onRequestUserSettingsSuccess.bind(this);
        //

        //Reload Table after Make Available
        this.onMakeAvailableRecall = this.onMakeAvailableRecall.bind(this);

        //Grid Operation Handlers
        this.onExportToExcel = this.onExportToExcel.bind(this);
        this.onRowSelect = this.onRowSelect.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onFilterNameChange = this.onFilterNameChange.bind(this);

        //MakeAvailable Event Handlers
        this.onMakeAvailableOpen = this.onMakeAvailableOpen.bind(this);
        this.onMakeAvailableSave = this.onMakeAvailableSave.bind(this);

        //Support Methods
        this.selectedDocumentIds = this.selectedDocumentIds.bind(this);
        this.selectedDocumentIdsAndLoadingStatus = this.selectedDocumentIdsAndLoadingStatus.bind(this);
        this.selectedDocuments = this.selectedDocuments.bind(this);
        this.lazyLoadSelected = this.lazyLoadSelected.bind(this);
        //  this.updateStates = this.updateStates.bind(this);
        this.getStoreItem = this.getStoreItem.bind(this);
        this.onUpdateCompletion = this.onUpdateCompletion.bind(this);
        this.onUpdateCompletionMultiple = this.onUpdateCompletionMultiple.bind(this);
        this.checkForPopupCompletion = this.checkForPopupCompletion.bind(this);
        this.buildQuery = this.buildQuery.bind(this);
        this.fetchInUseExtensions = this.fetchInUseExtensions.bind(this);
        this.loadInUseExtensions = this.loadInUseExtensions.bind(this);
        this.onPageReload = this.onPageReload.bind(this);
    }

    static getInUseStateFromProps(props: InUseExtensionsState.InUseExtensionsState, state: InUseExtensionsState.InUseExtensionsPageState) {
        return state;
    }

    private checkForPopupCompletion(taxDocuments: TaxDocumentStore.ITaxDocumentDictionary) {
        this.onUpdateCompletionMultiple(taxDocuments, "makeAvailableState", this.state.makeAvailableState);
    }

    OnMakeAvailable(rowIndex: number = NO_INDEX) {
    }

    componentWillMount() {
        this.props.requestAllUsers(true);
        this.props.requestAllPartners(true);
        this.props.getAllReportFilter(true, this.reportFilterType);
        this.props.requestUserSettings(true, this.onRequestUserSettingsSuccess);
        this.taxYearList = Helper.PrepareTaxYear().map(x => x.value);
        this.props.requestCompanySettings(false);
        this.props.requestGroupConfiguration(true);
        this.props.requestGroupAccess(true);
        this.props.requestExtensionUserSettings();
    }

    onRequestUserSettingsSuccess() {
        let defaultReportFilter = this.props.userSettings.settings.defaultSettings.reportFilter;
        if (!defaultReportFilter || defaultReportFilter == '') {
            this.loadInUseExtensions();
        }
    }

    componentWillReceiveProps(nextProps: InUseExtensionsProps) {
        //  this.updateStates(nextProps);
        this.checkForPopupCompletion(nextProps.taxDocuments);
    }

    //======================Grid Operation Starts here===============================

    private onExportToExcel() {

    }

    private onRowSelect(row: any, isSelected: any, e: any) {
        let newList = [...this.state.selectedRows];
        if (isSelected) {
            newList.push(row.rowIndex);
        } else {
            var index = newList.indexOf(row.rowIndex);
            if (index > -1) {
                newList.splice(index, 1);
            }
        }
        this.setState({ selectedRows: newList });
    }

    private onSelectAll(isSelected: any, rows: any, e: any) {
        let selectedRows: number[] = [];
        if (isSelected) {
            let count = pageSize;
            Array.from(Array(count).keys());
            selectedRows = Array.from(Array(count).keys());
        }
        this.setState({ selectedRows: selectedRows });
    }

    private onPageChange(page: number, sizePerPage: number) {
        this.setState({
            page: page,
            selectedRows: []
        }, this.loadInUseExtensions);
    }

    private onSortChange(sortName: string, sortOrder: string, column: number) {
        let temp = this.state.filter;
        temp.sort.column = sortName;
        temp.sort.direction = sortOrder == "asc" ? SortDirections.Ascending : SortDirections.Descending;
        Helper.SessionStore.set(this.proxyFilter, JSON.stringify(temp));
        this.setState({
            filter: temp,
            sortName: sortName,
            sortOrder: sortOrder == "asc" ? "asc" : "desc"
        }, () => this.fetchInUseExtensions(1, pageSize));
    }

    private onSearchChange(searchString: string) {
        let temp = this.state.filter;
        temp.searchText = searchString;
        this.setState({
            searchString: searchString,
            filter: temp

        }, () => this.fetchInUseExtensions(1, pageSize));
    }

    private onFilterNameChange(event: any) {
        let temp = this.state.filter;
        temp.name = event.target.value;
        this.setState({
            filter: temp
        })
    }

    //======================Grid Operation Ends here=================================


    //======================Filter Operation Starts here=================================

    private onFilterChange(dataField: any, filterType: ReportFilterType) {
        let newState: InUseExtensionsState.InUseExtensionsPageState = {
            filterName: "",
            filterClientId: "",
            filterPartner: "",
            filterSentBy: "",
            filterInUseOn: undefined,
            filterEngagementType: 0,
            filterDownloadedBy: "",
            filterTaxYear: 0,
            filterOfficeLocation:""           
        } as InUseExtensionsState.InUseExtensionsPageState;
        let filterStatus: any;
        let isClearFilter = true;  
        let filterOfficeLocation:any;
        var dict: { [columnName: string]: string; } = {};
        for (let field of Object.keys(dataField)) {
            var data = dataField[field.valueOf()].value ? dataField[field.valueOf()].value : dataField[field.valueOf()];
            if (isClearFilter && data !== "" && data !== "-1") {
                isClearFilter = false;
            }
            dict[field.valueOf().toString()] = data === "-1" ? "" : data;
            switch (field) {
                case 'taxDocumentName':
                    newState.filterName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'clientId':
                    newState.filterClientId = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'partner':
                    newState.filterPartner = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'DeliveredBy':
                    newState.filterSentBy = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'documentStatus':
                    filterStatus = dataField[field].value ? dataField[field].value : dataField[field];
                    filterStatus = (filterStatus == "-1") ? "" : filterStatus;
                    newState.filterStatus = filterStatus;
                    break;
                case 'uploadedOn':
                    newState.filterInUseOn = Helper.GetNumberOfDays(dataField[field].value ? dataField[field].value : dataField[field]);
                    break;
                case 'engagementType':
                    newState.filterEngagementType = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'taxYear':
                    newState.filterTaxYear = Number(this.taxYearList[dataField[field].value ? dataField[field].value : dataField[field]]);  
                    break;
                case 'officeLocation':
                    filterOfficeLocation = dataField[field].value ? dataField[field].value : dataField[field];
                    filterOfficeLocation = (filterOfficeLocation == "-1") ? "" : filterOfficeLocation;
                    newState.filterOfficeLocation = filterOfficeLocation;
                    break;
            }
        }

        let tempfilter: IFilters = { ...this.state.filter };
        tempfilter.fields = dict;
        tempfilter.filterType = filterType;
        if (isClearFilter) {
            Helper.SessionStore.remove(this.proxyFilter);
        } else {
            Helper.SessionStore.set(this.proxyFilter, JSON.stringify(tempfilter));
        }
        if (this.isFilterChanged(newState, filterStatus)) {
            this.setState({ ...newState, filter: tempfilter, page: 1, pageSize: pageSize, selectedRows: [] },
                () => {
                    let InUseExtensionsTable: any = this.refs.InUseExtensionsTable;
                    if (!InUseExtensionsTable.isAppliedFilter)
                        this.props.requestInUseExtensions(this.buildQuery(this.state.page, this.state.pageSize))
                })
        }
    }

    private isFilterChanged(newState: InUseExtensionsState.InUseExtensionsPageState, filterStatus: any): boolean {
        return (
            newState.filterName !== this.state.filterName ||
            newState.filterClientId !== this.state.filterClientId ||
            newState.filterPartner !== this.state.filterPartner ||
            newState.filterSentBy !== this.state.filterSentBy ||
            filterStatus !== this.state.filterStatus ||
            newState.filterInUseOn !== this.state.filterInUseOn ||
            newState.filterEngagementType !== this.state.filterEngagementType ||
            newState.filterTaxYear !== this.state.filterTaxYear ||
            newState.filterDownloadedBy !== this.state.filterDownloadedBy||
            newState.filterOfficeLocation!==this.state.filterOfficeLocation);
    }

    private applyDefaultFilter(filter: IFilters) {
        this.setState({ filter: filter },
            () => this.fetchInUseExtensions(1, pageSize))
    }

    onFilterSave(onApplyFilter: (filter: IFilters) => void) {
        if (this.state.filter.name.trim() != '') {
            let filter = this.props.reportFilters.filters.find(x => x.name == this.state.filter.name);
            if (filter) {
                VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.FilterNameAlreadyExists, null);
            }
            else {
                this.props.addReportFilter(this.state.filter.name, this.state.filter);
                onApplyFilter(this.state.filter);
                this.onSaveFilterHide();

                logClientEvent(
                    `${LogEventConstants.Common.Filter.SaveFilter} ${pageTitle}`,
                    { count: 1, page: pageTitle }
                );
            }
        }
        else {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.PleaseEnterFilterName, null);
        }
    }

    onSetDefaultFilterSuccess() {
        VenusNotifier.Success(DeliveredReturnsConstants.StatusMessage.SetDefaultFilterSuccess, null);
    }

    onFilterUpdate(name: string) {
        let filter = this.props.reportFilters.filters.find(x => x.name == name);
        if (!filter) {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.FilterDoesNotExists, null);
        }
        else {
            filter.fields = this.state.filter.fields;
            filter.name = name;
            filter.searchText = this.state.filter.searchText;
            filter.sort = this.state.filter.sort;
            filter.isDefaultFilter = this.state.filter.isDefaultFilter;
            filter.filterType = this.state.filter.filterType;

            this.props.updateReportFilter(name, filter);
            this.onSaveFilterHide();

            logClientEvent(
                `${LogEventConstants.Common.Filter.UpdateFilter} ${pageTitle}`,
                { count: 1, page: pageTitle }
            );

        }
    }

    onSetDefaultFilter(name: string, filterType: ReportFilterType) {
        this.props.addDefaultFilter(name, filterType, () => { this.onSetDefaultFilterSuccess(); });

        logClientEvent(
            `${LogEventConstants.Common.Filter.SetDefaultFilter} ${pageTitle}`,
            { count: 1, page: pageTitle }
        );
    }

    onRemoveDefaultFilterSuccess() {
        VenusNotifier.Success(DeliveredReturnsConstants.StatusMessage.RemoveDefaultFilterSuccess, null);
    }

    onRemoveDefaultFilter(name: string, filterType: ReportFilterType) {
        this.props.removeDefaultFilter(name, filterType, () => { this.onRemoveDefaultFilterSuccess(); });

        let InUseExtensionsTable: any = this.refs.InUseExtensionsTable;
        InUseExtensionsTable.refs.table.reset();
        InUseExtensionsTable.refs.Filters?.setState({ activeFilter: '', appliedFilters: [] });
        this.onPageReload;

        logClientEvent(
            `${LogEventConstants.Common.Filter.RemoveDefaultFilter} ${pageTitle}`,
            { count: 1, page: pageTitle }
        );
    }

    onFilterDelete(filterName: string, filterType: ReportFilterType) {
        this.props.deleteReportFilter(filterName, filterType);
        logClientEvent(`${LogEventConstants.Common.Filter.DeleteFilter} ${pageTitle}`,{ count: 1, page: pageTitle })
    }

    onSaveFilterHide() {
        this.setState({
            saveFilterShow: false
        });
    }

    onSaveFilterShow() {
        this.setState({
            saveFilterShow: true
        });
    }

    onMakeAvailableRecall() {
        this.setState({ selectedRows: [] }, this.onPageReload);
        let InUseExtensionsTable: any = this.refs.InUseExtensionsTable;

        InUseExtensionsTable.refs.table.setState({ sizePerPage: 20 })
    }

    private onPageReload() {
        this.setState(
            { refreshDelay: true, selectedRows: [] },
            () => {
                let query = this.buildQuery(this.state.page, this.state.pageSize);
                this.props.requestInUseExtensions(query, true, () => {
                    this.setState({
                        refreshDelay: false
                    });
                }, this.state.page > 1 ? this.reloadOnNoDataFound : undefined);
            });

        logClientEvent(
            `${LogEventConstants.Common.RefreshExtensions} ${pageTitle}`,
            { count: 1, page: pageTitle }
        );
    }

    reloadOnNoDataFound = () => {
        this.setState(prevState => ({ page: prevState.page - 1 }), () => {
            this.onPageReload();
        });
    }

    onClearFilter=()=>{
        this.setState({
            filter:clearedFilter})
    }

    //======================Filter Operation Ends here=================================

    //====================MakeAvailable Starts Here=================================

    private onMakeAvailableOpen(rowIndex: number = NO_INDEX) {
        let ids = this.onPopupOpen(rowIndex);
        if (ids.length == 0) {
            VenusNotifier.Warning(InUseExtensionsConstants.ValidationMessage.NoDocumentSelectedWarning, null);
            return;
        }
        if (this.state.selectedRows.length <= 1) {
            let documentIndex: number = this.state.selectedRows.length == 0 ? rowIndex : this.state.selectedRows[0];
            let lockedBy = this.props.inUse.InUseExtensionTableModel.inUseExtensionModel[documentIndex].lockedBy;
            let user = this.props.usersStore.allUsers.find(x => x.id == lockedBy) as IUserModel;
            const userName = user?.firstName ?? "user";
            let obj = this;
            bootbox.confirm({
                message: 'Please ensure ' + userName + ' is not currently working on these extensions before unlocking.',
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: 'btn-white btn-default'
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> Unlock Extension',
                        className: 'btn-info'
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        this.onMakeAvailableSave(ids);

                        logClientEvent(
                            `${LogEventConstants.Common.UnlockExtension} ${pageTitle}`,
                            { count: 1, page: pageTitle }
                        );
                    }
                    else {
                        this.setState({ selectedRows: [] });
                        let InUseExtensionsTable: any = this.refs.InUseExtensionsTable;
                        InUseExtensionsTable.refs.table.reset();
                    }
                }
            });
        }
        else {
            let obj = this;
            bootbox.confirm({
                message: 'Please ensure all users are not currently working on these extensions before unlocking.',
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: 'btn-white btn-default'
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> Unlock Extension',
                        className: 'btn-info'
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        this.onMakeAvailableSave(ids);
                        logClientEvent(
                            `${LogEventConstants.Common.UnlockMultiExtension} ${pageTitle}`,
                            { count: 1, page: pageTitle }
                        );

                    }
                    else {
                        this.setState({ selectedRows: [] });
                        let InUseExtensionsTable: any = this.refs.InUseExtensionsTable;
                        InUseExtensionsTable.refs.table.reset();
                    }
                }
            });
        }
    }

    private onMakeAvailableSave(ids: number[]) {
        this.props.makeAvailableTaxDocument(ids, this.onMakeAvailableRecall);
        this.setState({
            makeAvailableState: {
                show: false,
                selected: undefined,
                saveCheckIds: ids
            }
        });
    }

    //====================MakeAvailable Ends Here=================================

    buildQuery(page: number, pageSize: number) {        
        return '?PageNo=' + page
            + '&SortBy=' + this.state.sortName
            + '&SortOrder=' + this.state.sortOrder
            + '&SearchString=' + this.state.searchString
            + '&selectedTaxYear=' + this.state.filterTaxYear
            + '&PageSize=' + pageSize
            + '&filterName=' + encodeURIComponent(this.state.filterName)
            + '&filterClientId=' + encodeURIComponent(this.state.filterClientId)
            + '&filterPartner=' + encodeURIComponent(this.state.filterPartner)
            + '&filterStatus=' + this.state.filterStatus
            + '&filterInUseOn=' + (this.state.filterInUseOn || "")
            + '&filterEngagementType=' + this.state.filterEngagementType
            + '&filterDownloadedBy=' + this.state.filterDownloadedBy
            + '&filterSentBy=' + encodeURIComponent(this.state.filterSentBy)
            + '&filterOfficeLocation='+encodeURIComponent(this.state.filterOfficeLocation); 
    }

    loadInUseExtensions() {
        let queryString = this.buildQuery(this.state.page, this.state.pageSize);
        this.setState({ selectedRows: [] },
            () => this.props.requestInUseExtensions(queryString, undefined, undefined, this.state.page > 1 ? this.reloadOnNoDataFound : undefined));
    }

    fetchInUseExtensions(page: number, sizePerPage: number) {
        let queryString = this.buildQuery(page, sizePerPage);
        this.setState({ page: page, pageSize: sizePerPage, selectedRows: [] },
            () => { this.props.requestInUseExtensions(queryString, undefined, undefined, this.state.page > 1 ? this.reloadOnNoDataFound : undefined) });
    }

    private selectedDocumentIds() {
        let ids: number[] = [];
        for (var i in this.state.selectedRows) {
            let row = this.state.selectedRows[i];
            let selectedDocument = this.props.inUse.InUseExtensionTableModel.inUseExtensionModel[row];
            if (selectedDocument) {
                ids.push(selectedDocument.documentId);
            }
        }
        return ids;
    }

    private selectedDocumentIdsAndLoadingStatus(): InUseExtensionsState.ISelectedDocument[] {
        let selectedDocuments: InUseExtensionsState.ISelectedDocument[] = [];
        let ids = this.selectedDocumentIds();
        ids.map((id, index) => {
            selectedDocuments.push({ id: id, isfullyLoaded: this.props.taxDocuments[id].isFullyLoaded ? true : false });
        });
        return selectedDocuments;
    }

    private selectedDocuments() {
        let docs: IInUseTaxReturns[] = [];
        for (var i in this.state.selectedRows) {
            let row = this.state.selectedRows[i];
            let selectedDocument = this.props.inUse.InUseExtensionTableModel.inUseExtensionModel[row];
            if (selectedDocument) {
                docs.push(selectedDocument);
            }
        }
        return docs;
    }

    private lazyLoadSelected(ids: number[]) {
        ids.forEach((id, i) => {
            this.props.requestTaxDocument(id, false);
        });
    }

    private isLoaded(allReturns: TaxDocumentStore.ITaxDocumentDictionary, ids: number[]) {
        for (var i in ids) {
            if (allReturns[ids[i]] && allReturns[ids[i]].isLoading) {
                return false;
            }
        }
        return true;
    }

    private onPopupOpen(rowIndex: number) {
        let ids = [rowIndex];
        if (rowIndex !== NO_INDEX) {
            return [this.props.inUse.InUseExtensionTableModel.inUseExtensionModel[rowIndex].documentId];
        }
        if (this.state.selectedRows.length == 0) {

            return [];
        }
        return this.selectedDocumentIds();
    }

    private getStoreItem(index: number) {
        let id = this.props.inUse.InUseExtensionTableModel.inUseExtensionModel[index].documentId;
        return this.props.taxDocuments[id];
    }

    private onUpdateCompletion(taxDocuments: TaxDocumentStore.ITaxDocumentDictionary, stateKey: string, state: InUseExtensionsState.IPopupStateSingle) {
        if (state.saveCheckId &&
            this.isLoaded(taxDocuments, [state.saveCheckId])) {
            if (taxDocuments[state.saveCheckId] && taxDocuments[state.saveCheckId].error) {
                VenusNotifier.Error(errorMsg[stateKey], stateKey);
            } else {
                VenusNotifier.Success(successMsg[stateKey], stateKey);
            }
            this.setState((prevState) => ({
                ...prevState,
                [stateKey]: {
                    show: false,
                    model: undefined,
                    saveCheckId: undefined
                }
            }));
        }
    }

    private onUpdateCompletionMultiple(taxDocuments: TaxDocumentStore.ITaxDocumentDictionary, stateKey: string, state: InUseExtensionsState.IPopupStateMultiple) {
        if (state.saveCheckIds &&
            this.isLoaded(taxDocuments, state.saveCheckIds)) {
            let err = false;
            state.saveCheckIds.map((id, i) => {
                if (taxDocuments[id] && taxDocuments[id].error) {
                    this.props.requestTaxDocument(id, true);
                    err = true;
                }
            });
            if (err) {
                VenusNotifier.Error(errorMsg[stateKey], stateKey);
            } else {
                VenusNotifier.Success(successMsg[stateKey], stateKey);
            }
            this.setState((prevState) => ({
                ...prevState,
                [stateKey]: {
                    show: false,
                    model: undefined,
                    saveCheckId: undefined
                }
            }));
        }
    }
    private getDefaultFilter = (filters: IFilters[]): string | undefined => {
        let existsMasterFilter = filters.findIndex(x => x.isMasterFilter) > -1 ? true : false;
        let existsDefaultFilter = filters.findIndex(x => x.isDefaultFilter) > -1 ? true : false;

        if (existsMasterFilter) {
            let mName = filters.find(x => x.isMasterFilter);
            return mName ? mName.name : undefined;
        }
        else if (existsDefaultFilter) {
            let dName = filters.find(x => x.isDefaultFilter);
            return dName ? dName.name : undefined;
        }
        return undefined;
    }

    public render() {        
        return <div className='user-assignment-content'>

                <InUseExtensionsHeader
                    pageTitle="Locked Extensions"
                    selectedDocumentCount={this.selectedDocumentIds().length}
                    onMakeAvailable={this.onMakeAvailableOpen}
                    extensionUsers={this.props.usersStore.extensionUsers}
                    partners={this.props.usersStore.partners}
                    selectedERO={this.props.extensionUserSettings.defaultSettings ? this.props.extensionUserSettings.defaultSettings.eroUser : 0}
                    onPageReload={this.onPageReload}
                    company={this.props.company}
                    uploadTaxReturn={this.props.uploadTaxReturnStore}
                    getUploadLink={(url: string, callback: () => void) => {
                        this.props.getUploadLink(url, RBACKeys.lockedExtension.uploadButton, callback);
                    }}
                    processTaxReturn={this.props.processTaxReturn}
                    submitTaxReturn={(url: string, taxData: string, callback: () => void) => {
                        this.props.submitTaxReturn(url, taxData, RBACKeys.lockedExtension.uploadButton, callback);
                    }}
                    companySettings={this.props.company.companySettings ? this.props.company.companySettings : initialCompanySettings}
                    userDefaultSettings={this.props.userSettings.settings}
                    groupInfo={this.props.groupedExtensionsStore.groupInfo}
                    updateGroupInfoState={this.props.requestGroupConfiguration}
                    defaultGroup={this.state.defaultGroup ? this.state.defaultGroup : ''}
                    groupAccess={this.props.groupedExtensionsStore.groupAccess}
                    taxSoftwareData={this.props.taxSoftwareData.taxSoftwareSettings}
                    getTaxDocumentsAccessMaps={(ids: number[], callback?: (result: IDocumentAccess) => void ) => {
                        this.props.getTaxDocumentsAccessMaps(ids, true,RBACKeys.lockedExtension.uploadButton, callback)
                    }}
                />
                <br />
                {this.props.userOfficeLocation.userLocation.length>0 &&<InUseExtensionsTable
                    ref='InUseExtensionsTable'
                    onPageChange={this.onPageChange}
                    onSortChange={this.onSortChange}
                    onSearchChange={this.onSearchChange}
                    onFilterChange={this.onFilterChange}
                    pageNo={this.state.page}
                    totalRows={this.props.inUse.totalRowCount}
                    onExportToExcel={this.onExportToExcel}
                    onFilterNameChange={this.onFilterNameChange}
                    loadGrid={this.loadInUseExtensions}

                    onFilterSave={this.onFilterSave}
                    onFilterUpdate={this.onFilterUpdate}
                    onFilterDelete={this.onFilterDelete}
                    filterList={this.props.reportFilters.filters}
                    onSetDefaultFilter={this.onSetDefaultFilter}
                    onRemoveDefaultFilter={this.onRemoveDefaultFilter}
                    onSaveFilterHide={this.onSaveFilterHide}
                    onSaveFilterShow={this.onSaveFilterShow}
                    currentFilter={this.state.filter}
                    onMakeAvailable={this.onMakeAvailableOpen}
                    defaultFilter={this.getDefaultFilter(this.props.reportFilters.filters)}
                    isLoading={this.props.inUse.loading}
                    pageSize={pageSize}
                    onRowSelect={this.onRowSelect}
                    onSelectAll={this.onSelectAll}
                    selectedRows={this.state.selectedRows}
                    inUse={this.props.inUse}
                    saveFilterShow={this.state.saveFilterShow}
                    taxYearList={this.taxYearList}
                    proxyFilter={this.proxyFilter}
                    filterType={this.reportFilterType}
                    onPageReload={this.onPageReload}
                    refreshDelay={this.state.refreshDelay}
                    userLocation={this.props.userOfficeLocation.userLocation}
                    clearFilter={this.onClearFilter}
                />
    }
            </div>;
    }
};