import React from  'react';
import * as ReacrDOM from 'react-dom';
import { Link } from 'react-router-dom';

export class ClientInstructionHeader extends React.Component<{}, {}>
{
    constructor(props:any) {
        super(props);
    }
    public render() {
        return (
            <div>
                <h3 className="page-title">Client Instructions</h3>
                <hr />
                <div>
                    <div className="marB15" style={{ fontSize: '14px' }}>Below are the instructions for each step of the process. Default instructions are provided, which you may change to more effectively communicate with your clients.
                    <Link to={'#'} className="help-icon" data-placement="right" data-toggle="tooltip"
                            title="Each step of the process includes its own set of instructions. You can edit the saved instruction messages for each step below. Based on the settings below, users can pick from the various messages defined here.">
                            <i className="fas fa-question-circle"></i>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}