import React from  'react';
export interface SvgImageProps {
    height: number;
    width: number;
    style?: React.CSSProperties;
    className?: string;
};

export const LoginSession: React.FC<SvgImageProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} style={props.style} className={props.className} viewBox="0 0 300 300">
        <path fill="#FFFFFF" d="M132.5,160c44.112,0,80-35.888,80-80s-35.888-80-80-80s-80,35.888-80,80S88.388,160,132.5,160z" />
        <path fill="#FFFFFF" d="M297.199,221.809l-54.191-93.964c-3.766-6.523-10.515-10.418-18.055-10.418 c-7.538,0-14.286,3.895-18.051,10.421l-17.276,29.955l-4.402-0.207c-1.358-0.063-2.577-0.095-3.724-0.095h-11.321l-1.987,0.913 c-11.318,5.2-23.326,7.837-35.691,7.837c-12.315,0-24.329-2.639-35.708-7.844l-1.981-0.906H83.5C37.458,157.5,0,194.958,0,241 v22.749C0,283.738,16.262,300,36.25,300h192.499C248.738,300,265,283.738,265,263.749v-10.687h14.146 c7.529,0,14.274-3.896,18.044-10.423C300.957,236.116,300.96,228.328,297.199,221.809z" />
        <path fill="#4D4D4D" d="M132.5,150c38.664,0,70-31.336,70-70s-31.336-70-70-70s-70,31.336-70,70S93.836,150,132.5,150z" />
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="224.5654" y1="217.7676" x2="225.3447" y2="217.7676">
            <stop offset="0" style={{ stopColor: "#F7C001" }} />
            <stop offset="1" style={{ stopColor: "#E77A17" }} />
        </linearGradient>
        <path fill="url(#SVGID_1_)" d="M224.955,217.378c-0.215,0-0.39,0.175-0.39,0.39c0,0.214,0.175,0.389,0.39,0.389 s0.39-0.175,0.39-0.389C225.345,217.553,225.17,217.378,224.955,217.378L224.955,217.378z" />
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="159.9102" y1="185.2441" x2="290" y2="185.2441">
            <stop offset="0" style={{ stopColor: "#F7C001" }} />
            <stop offset="1" style={{ stopColor: "#E77A17" }} />
        </linearGradient>
        <path fill="url(#SVGID_2_)" stroke="#FBB03B" stroke-width="3" d="M288.536,226.805c4.169,7.226-1.063,16.258-9.391,16.258H170.763 c-8.342,0-13.551-9.046-9.39-16.258l54.191-93.961c4.171-7.229,14.617-7.216,18.782,0L288.536,226.805L288.536,226.805z M224.955,207.378c-5.737,0-10.39,4.651-10.39,10.39c0,5.737,4.652,10.389,10.39,10.389s10.39-4.651,10.39-10.389 C235.345,212.029,230.692,207.378,224.955,207.378z M215.092,170.034l1.675,30.716c0.079,1.438,1.268,2.563,2.706,2.563h10.964 c1.44,0,2.628-1.125,2.707-2.563l1.675-30.716c0.085-1.553-1.151-2.857-2.706-2.857h-14.314 C216.243,167.177,215.007,168.481,215.092,170.034L215.092,170.034z" />
        <path fill="#808080" d="M170.763,252.396c-7.288,0-13.816-3.771-17.464-10.086c-3.648-6.318-3.652-13.857-0.01-20.17l31.465-54.556 c-1.079-0.051-2.162-0.084-3.254-0.084h-9.134c-12.141,5.578-25.648,8.75-39.866,8.75c-14.219,0-27.672-3.172-39.867-8.75H83.5 C42.922,167.5,10,200.422,10,241v22.749C10,278.242,21.758,290,36.25,290h192.499C243.242,290,255,278.242,255,263.749v-11.354 H170.763z" />
    </svg>;
}

export const LoginHistory: React.FC<SvgImageProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} style={props.style} className={props.className} viewBox="0 0 300 300">
        <path fill="#FFFFFF" d="M132.5,160c44.111,0,80-35.888,80-80s-35.889-80-80-80c-44.112,0-80,35.888-80,80S88.388,160,132.5,160z" />
        <path fill="#4D4D4D" d="M132.5,150c38.664,0,70-31.336,70-70s-31.336-70-70-70s-70,31.336-70,70S93.836,150,132.5,150z" />
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="480.5654" y1="-263.7676" x2="481.3447" y2="-263.7676" gradientTransform="matrix(1 0 0 -1 -256 -46)">
            <stop offset="0" style={{ stopColor: "#F7C001" }} />
            <stop offset="1" style={{ stopColor: "#E77A17" }} />
        </linearGradient>
        <path fill="url(#SVGID_1_)" d="M224.955,217.378c-0.215,0-0.391,0.175-0.391,0.39s0.176,0.39,0.391,0.39s0.391-0.175,0.391-0.39 S225.17,217.378,224.955,217.378L224.955,217.378z" />
        <path fill="#FFFFFF" d="M36.251,300c-14.598,0-27.707-8.689-33.397-22.139C0.96,273.385,0,268.637,0,263.749V241 c0-46.042,37.458-83.5,83.5-83.5h11.271l2.247,1.006c11.319,5.139,23.256,7.744,35.482,7.744c12.226,0,24.163-2.605,35.479-7.743 l2.207-0.999l2.18-0.008h9.134c46.042,0,83.5,37.458,83.5,83.5v22.749c0,4.89-0.96,9.638-2.854,14.113 C256.456,291.311,243.348,300,228.75,300H36.251z" />
        <path fill="#808080" d="M181.5,167.5h-9.133h-0.001c-0.084,0.038-0.169,0.074-0.253,0.112c-12.129,5.507-25.493,8.638-39.613,8.638 s-27.485-3.131-39.613-8.638c-0.084-0.038-0.169-0.074-0.253-0.112h-0.001H83.5C42.922,167.5,10,200.422,10,241v22.749 c0,3.623,0.735,7.075,2.063,10.216C16.05,283.387,25.381,290,36.25,290h0.001h192.498h0.001c10.869,0,20.2-6.613,24.187-16.035 c1.329-3.141,2.063-6.593,2.063-10.216V241C255,200.422,222.078,167.5,181.5,167.5z" />
        <path fill="#FFFFFF" d="M232.183,284.391c-15.447,0-30.563-5.334-42.565-15.019c-3.432-2.771-5.534-6.886-5.769-11.292 c-0.234-4.414,1.424-8.738,4.549-11.864l2.626-2.626c2.96-2.958,6.891-4.586,11.069-4.586c3.494,0,6.923,1.193,9.653,3.361 c5.858,4.648,12.925,7.105,20.437,7.105c4.496,0,8.785-0.907,12.692-2.547c-0.485,0.045-0.977,0.067-1.472,0.067l0,0 c-3.448,0-6.847-1.167-9.571-3.285l-19.109-14.863v-15.598c-0.889,0.18-1.804,0.272-2.736,0.272h-33.891 c-7.571,0-13.73-6.159-13.73-13.73v-33.891c0-7.575,6.172-13.738,13.757-13.738c3.645,0,7.083,1.43,9.681,4.027l5.096,5.096 c11.41-8.131,25.052-12.525,39.284-12.525c37.326,0,67.749,30.365,67.817,67.689c0.033,18.081-7.013,35.121-19.841,47.982 c-12.827,12.859-29.848,19.949-47.927,19.963H232.183z M255.271,216.142c3.287,2.556,5.384,6.241,5.9,10.375 c0.285,2.285,0.07,4.555-0.611,6.684c2.871-4.882,4.519-10.565,4.519-16.627c0-11.94-6.395-22.416-15.939-28.182 c0.328,1.258,0.503,2.577,0.503,3.936v19.437L255.271,216.142z" />
        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="174.3652" y1="216.5732" x2="290" y2="216.5732">
            <stop offset="0" style={{ stopColor: "#F7C001" }} />
            <stop offset="1" style={{ stopColor: "#E77A17" }} />
        </linearGradient>
        <path fill="url(#SVGID_2_)" stroke="#FBB03B" stroke-width="3" d="M290,216.464c0.059,31.855-25.92,57.903-57.775,57.927 c-13.759,0.01-26.396-4.786-36.327-12.801c-2.583-2.085-2.776-5.955-0.429-8.303l2.626-2.626c2.008-2.007,5.212-2.228,7.436-0.463 c7.316,5.806,16.576,9.272,26.652,9.272c23.711,0,42.896-19.189,42.896-42.897c0-23.711-19.189-42.897-42.896-42.897 c-11.381,0-21.717,4.424-29.392,11.641l11.833,11.833c2.35,2.351,0.687,6.368-2.638,6.368h-33.891c-2.062,0-3.73-1.669-3.73-3.73 v-33.891c0-3.324,4.018-4.988,6.368-2.639l11.51,11.511c10.38-9.92,24.447-16.013,39.939-16.013 C264.078,158.756,289.941,184.582,290,216.464z M247.823,234.831l2.29-2.944c1.897-2.439,1.458-5.955-0.981-7.852l-9.489-7.381 v-24.327c0-3.091-2.505-5.595-5.595-5.595h-3.73c-3.09,0-5.595,2.504-5.595,5.595v31.625l15.249,11.86 C242.41,237.709,245.926,237.271,247.823,234.831z" />
    </svg>;
}

export const SessionTimeout: React.FC<SvgImageProps> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} style={props.style} className={props.className} viewBox="0 19 192 192">
        <path fill="#F7C001" d="M-323-93" />
        <path fill="#F8F8F8" d="M96,207c-50.729,0-92.001-41.271-92.001-92c0-50.729,41.272-92,92.001-92s92.001,41.271,92.001,92 C188.001,165.729,146.729,207,96,207L96,207z M75.27,66.749C56.591,74.805,43.484,93.4,43.484,115 c0,28.957,23.558,52.516,52.516,52.516c28.959,0,52.517-23.559,52.517-52.516c0-21.6-13.107-40.195-31.786-48.251 c0.198,1,0.302,2.033,0.302,3.09v39.601l16.607,12.083c3.438,2.483,5.704,6.193,6.349,10.423c0.633,4.152-0.378,8.317-2.844,11.729 l-6.078,8.374c-3.028,4.113-7.813,6.53-12.846,6.53c-3.364,0-6.586-1.042-9.314-3.016l-27.51-19.993 c-4.025-2.986-6.43-7.758-6.43-12.764V69.839C74.967,68.782,75.071,67.749,75.27,66.749L75.27,66.749z" />
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="16" y1="115" x2="176" y2="115">
            <stop offset="0" style={{ stopColor: "#F7C001" }} />
            <stop offset="1" style={{ stopColor: "#E77A17" }} />
        </linearGradient>
        <path fill="url(#SVGID_1_)" d="M96,35c-44.193,0-80,35.807-80,80s35.807,80,80,80s80-35.807,80-80S140.193,35,96,35z M96,179.516 c-35.646,0-64.516-28.87-64.516-64.516c0-35.645,28.87-64.516,64.516-64.516S160.517,79.355,160.517,115 C160.517,150.646,131.646,179.516,96,179.516z M115.936,145.839l-27.388-19.902c-1.001-0.744-1.58-1.904-1.58-3.13V69.839 c0-2.13,1.741-3.871,3.871-3.871h10.322c2.13,0,3.871,1.741,3.871,3.871v45.71l21.548,15.677c1.742,1.259,2.098,3.678,0.84,5.42 L121.355,145C120.096,146.71,117.677,147.097,115.936,145.839L115.936,145.839z" />
    </svg>;
}

export const AccessDenied: React.FC<SvgImageProps> = (props) => {
    return <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 200}
    height={props.height || 200} 
    style={props.style} 
    className={props.className}
    fill="none"
    viewBox="0 0 200 200"
    >
    <circle cx="100" cy="100" r="100" fill="#E6EBF0"></circle>
    <path
      fill="#B4C3D3"
      fillRule="evenodd"
      stroke="#B4C3D3"
      strokeWidth="3"
      d="M121.382 80.957a29.676 29.676 0 016.497 9.786A29.59 29.59 0 01125 118.39a29.592 29.592 0 01-41.774 7.531 29.555 29.555 0 01-10.778-14.555 29.818 29.818 0 01-1.36-12.93 1.504 1.504 0 112.989.35 26.815 26.815 0 001.221 11.617 26.628 26.628 0 005.657 9.508c.242-.327.522-.652.83-.96l35.928-35.928c.251-.251.512-.483.777-.691a26.55 26.55 0 00-8.025-5.086 26.784 26.784 0 00-19.995 0 26.386 26.386 0 00-8.791 5.838 1.505 1.505 0 01-2.128-2.127 29.392 29.392 0 019.786-6.498 29.776 29.776 0 0122.259 0 29.678 29.678 0 019.786 6.498zm-.784 3.553a26.569 26.569 0 01-1.342 36.154 26.437 26.437 0 01-8.791 5.836 26.756 26.756 0 01-19.995 0 26.626 26.626 0 01-7.38-4.524c.313-.235.624-.505.92-.8l35.928-35.929c.238-.238.46-.486.66-.737z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#B4C3D3"
      d="M154.848 59.189a86.865 86.865 0 01-49.325-14.326 9.807 9.807 0 00-10.951 0A86.466 86.466 0 0150.249 59.19a9.758 9.758 0 00-6.084 2.41 9.742 9.742 0 00-3.188 5.71c-3.046 17.357-7.99 75.513 56.179 94.721a10.07 10.07 0 002.891.411c.978.001 1.951-.139 2.888-.415 75.79-22.696 55.109-99.968 54.893-100.745a2.877 2.877 0 00-2.98-2.092zm-1.417 42.857c-5.129 27.313-22.68 45.647-52.159 54.475-.8.238-1.653.238-2.453 0-19.318-5.805-63.075-25.978-52.155-88.22a4.023 4.023 0 013.82-3.35 92.303 92.303 0 0047.338-15.318 3.976 3.976 0 014.451 0 90.733 90.733 0 0050.507 15.388 97.877 97.877 0 01.651 37.025z"
    ></path>
  </svg>;
}

export const FeatureDisabledIcon: React.FC<SvgImageProps> = (props) => {
    return <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 200}
    height={props.height || 200} 
    style={props.style} 
    className={props.className}
    fill="none"
    viewBox="0 0 200 200"
    >
    <circle cx="100" cy="100" r="100" fill="#E6EBF0"></circle>
    <path
      fill="#B4C3D3"
      d="M76.552 72.227c.351-.72.403-1.55.144-2.31a3.019 3.019 0 00-1.523-1.735 2.997 2.997 0 00-2.3-.144c-.755.26-1.376.81-1.727 1.53L42.382 128.57c-1.203 2.461-4.411 9.004 1.204 13.5 8.382 6.696 32.164 10.048 55.954 10.044 23.791-.004 47.57-3.348 55.936-10.044 5.614-4.496 2.406-11.043 1.203-13.5l-37.411-76.775a22.032 22.032 0 00-8.103-9.015 21.897 21.897 0 00-11.625-3.342c-4.11 0-8.137 1.158-11.624 3.342a22.035 22.035 0 00-8.103 9.014 3.035 3.035 0 00-.146 2.31 3.018 3.018 0 001.521 1.735 2.997 2.997 0 002.299.147 3.014 3.014 0 001.728-1.529 15.997 15.997 0 015.883-6.549 15.897 15.897 0 0116.885 0 15.991 15.991 0 015.883 6.55l37.435 76.771c2.093 4.294 1.431 5.31.425 6.115-14.631 11.716-89.744 11.716-104.375 0-1.002-.805-1.664-1.817.434-6.115l28.767-59z"
    ></path>
    <path
      fill="#B4C3D3"
      d="M104.225 104.464V76.757c0-.933-.445-1.828-1.237-2.487-.793-.66-1.867-1.03-2.988-1.03-1.12 0-2.195.37-2.988 1.03-.792.66-1.237 1.554-1.237 2.487v27.707c0 .933.445 1.827 1.237 2.487.793.66 1.867 1.03 2.988 1.03s2.195-.37 2.988-1.03c.792-.66 1.237-1.554 1.237-2.487zM93.897 122.066c0 1.114.358 2.203 1.028 3.129a6.016 6.016 0 002.738 2.075 6.573 6.573 0 003.526.322 6.28 6.28 0 003.125-1.541 5.511 5.511 0 001.671-2.883 5.234 5.234 0 00-.345-3.255 5.75 5.75 0 00-2.246-2.529 6.466 6.466 0 00-3.389-.952c-1.62 0-3.172.593-4.317 1.65-1.146 1.056-1.79 2.489-1.791 3.984z"
    ></path>
  </svg>;
}

export const ScreenAccessDenied: React.FC<SvgImageProps> = (props) => {
    return <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 200}
    height={props.height || 200} 
    style={props.style} 
    className={props.className}
    fill="none"
    viewBox="0 0 200 200"
  >
    <circle cx="100" cy="100" r="100" fill="#E6EBF0"></circle>
    <path
      fill="#B4C3D3"
      d="M132.974 47.887H67.026c-10.571.004-19.135 8.492-19.139 18.97v65.362c0 1.45 1.187 2.626 2.65 2.626 1.463 0 2.65-1.176 2.65-2.626V79.287h93.626v52.932c.004 7.574-6.19 13.713-13.832 13.716H67.026c-2.403 0-4.763-.623-6.85-1.8a2.662 2.662 0 00-3.544 1.208 2.614 2.614 0 00.922 3.345 19.288 19.288 0 009.472 2.486h65.948c10.571 0 19.139-8.492 19.139-18.969V66.856c-.004-10.477-8.572-18.965-19.139-18.969zm13.839 18.97v7.178H53.187v-7.179c-.004-7.574 6.19-13.713 13.832-13.716h65.955c7.642-.004 13.836 6.135 13.839 13.71v.006z"
    ></path>
    <path
      fill="#B4C3D3"
      d="M80.285 60.094a3.286 3.286 0 10-.007 6.573 3.286 3.286 0 003.29-3.283v-.007a3.284 3.284 0 00-3.283-3.283zM67.61 60.094c-2.075-.004-3.756 1.467-3.76 3.283-.004 1.815 1.677 3.286 3.752 3.29 2.075.003 3.755-1.468 3.76-3.283v-.007c-.004-1.812-1.681-3.28-3.752-3.283zM94.37 60.094a3.286 3.286 0 10-.007 6.573 3.286 3.286 0 00.007-6.573zM86.671 122.693c.372.396.813.711 1.3.926a3.805 3.805 0 001.534.325c.526 0 1.047-.111 1.534-.325.486-.215.927-.53 1.299-.926l7.709-8.193 7.714 8.193a3.884 3.884 0 002.804 1.143 3.9 3.9 0 002.764-1.248 4.397 4.397 0 001.174-2.938 4.42 4.42 0 00-1.076-2.98l-7.708-8.192 7.708-8.193a4.409 4.409 0 001.127-2.996 4.4 4.4 0 00-1.173-2.977 3.894 3.894 0 00-2.8-1.25 3.884 3.884 0 00-2.82 1.195l-7.714 8.193-7.71-8.193a4.014 4.014 0 00-1.3-.954 3.804 3.804 0 00-3.1-.027c-.494.214-.942.531-1.318.931-.376.4-.674.876-.875 1.4a4.495 4.495 0 00.028 3.296c.21.52.516.99.9 1.382l7.687 8.193-7.709 8.192a4.295 4.295 0 00-.865 1.385 4.493 4.493 0 00.012 3.26c.203.516.5.984.874 1.378z"
    ></path>
  </svg>;
}