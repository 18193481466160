let moment = require('moment');
import * as bootbox from 'bootbox';
import React from  'react';
import * as ReactDom from 'react-dom';
import {
    BootstrapTable, TableHeaderColumn, TextFilter, CustomFilter, SelectFilter,
    DateFilter, FilterComparator, SelectFilterOptionsType, CustomSelectProps,
    ToolBarProps, CustomFilterParameters, Options
} from 'react-bootstrap-table';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { DeliveredReturnsTableConstants, DeliveredReturnsConstants } from './../../../helper/Constants';
import { VenusNotifier } from './../../../helper/VenusNotifier';
import * as Helper from './../../../helper/HelperFunctions';
import { CheckBoxSize, CheckBoxComponent } from './../../../common/CheckBoxComponent';
import { SendGroupedExtensionsActions } from './SendGroupedExtensionsActions';
import { ExtensionsTable } from './../IndividualExtensionsComponents/ExtensionsTable';
import { ITaxReturn } from './../../../common/TaxReturn';
import Filters, { IFilters, ReportFilterType } from '../../../reports/Filters';
import { SendGroupedExtensionsStoreState } from '../../../../Core/ViewModels/GroupExtensions/StoreModels';
const isEqual = require("react-fast-compare");

export interface SendGroupedExtensionsTableProps {
    groupedReturns: SendGroupedExtensionsStoreState;
    isLoading: boolean;
    saveFilterShow: boolean;
    onPageChange: any;
    onSortChange: any;
    onSearchChange: any;
    onFilterChange: any;
    pageNo: number;
    pageSize: number;
    totalRows: number;
    onExportToExcel(onExportToExcelComplete: () => void): void;
    onFilterNameChange(event: any): void;
    loadGrid(): void;

    onFilterSave(onApplyFilter: (filter: IFilters) => void): void;
    onFilterUpdate(filterName: string): void;
    onFilterDelete(filterName: string, filterType: ReportFilterType): any;
    filterList: IFilters[],
    currentFilter: IFilters,
    onSetDefaultFilter(name: string, filterType: ReportFilterType): void;
    onRemoveDefaultFilter(name: string, filterType: ReportFilterType): void;
    defaultFilter: string | undefined;
    onSaveFilterShow(): void;
    onSaveFilterHide(): void;
    proxyFilter: string;
    filterType: ReportFilterType;

    onProcessGroupOpen: (rowIndex: number) => void;
    onEditControllerInfoOpen: (rowIndex: number) => void;
    onGroupLevelRowDoubleClick: (rowIndex: number) => void;

    onPageReload: () => void;
    refreshDelay?: boolean;
    clearSelection: () => void;
    onClearFilter: () => void;

    onRowSelect: (row: any, isSelected: any, e: any) => void;
    onSelectAll: (isSelected: any, rows: any, e: any) => void;
    selectedRows: number[];

    onDocumentSelect: (Ids: number[]) => void;
    onDocumentUnSelect: (Ids: number[]) => void;

    selectedDocuments: number[];
    onEditClientInfoOpen: (taxDocumentId: number) => void;
    onDeleteTaxReturnOpen: (taxDocumentId: number) => void
    onChangeStatusOpen: (taxDocumentId: number) => void;
    onReportProblemOpen: (taxDocumentId: number) => void;
    onReturnLevelRowDoubleClick: (rowIndex: number, groupId: number) => void;

    onDeleteGroupOpen: (rowIndex: number) => void;
    onSetAccessOpen: (rowIndex: number) => void;
    onReportGroupProblemOpen: (groupId: number) => void;

    onProcessReturnOpen: (taxDocumentId: number, groupId: number) => void;
    onReprocessReturnOpen: (taxDocumentId: number) => void;
    onRecallReturnOpen: (taxDocumentId: number) => void;
    OnEditGroupInfoOpen: (groupId: number) => void;
}

export interface SendGroupedExtensionsTableStates {
    isHiddenExportExcel: boolean;
    hideLink: boolean;
    expandedRows: number[];
}

export const DaysRangeOptionsList = {
    One_Day: '24 Hours',
    Two_Days: '2 Days',
    Three_Days: '3 Days',
    Five_Days: '5 Days',
    Seven_Days: '1 Week',
    Fifteen_Days: '15 Days',
    Thirty_Days: '30 Days'
};


export const TaxSoftwareList = {
    CCH: 'CCH',
    GoSystem: 'GoSystem',
    Lacerte: 'Lacerte',
    UltraTax: 'UltraTax',
}

export const SetAccessList = {
    1: 'Everyone',
    2: 'Restricted',
}

const NO_INDEX = -1;

export class SendGroupedExtensionsTable extends React.Component<SendGroupedExtensionsTableProps, SendGroupedExtensionsTableStates> {
    public refClearSearchBtn: any;
    public filterChanged: boolean = false;
    public isAppiedFilter: boolean = false;

    constructor(props: SendGroupedExtensionsTableProps) {
        super(props);
        this.state = {
            hideLink: true,
            isHiddenExportExcel: false,
            expandedRows: []
        };

        this.onFilterChange = this.onFilterChange.bind(this);
    }

    shouldComponentUpdate(nextProps: SendGroupedExtensionsTableProps, nextState: SendGroupedExtensionsTableStates) {
        return (
            !isEqual(this.props.groupedReturns.sendGroupedExtensionsTableModel.sendGroupedExtensions, nextProps.groupedReturns.sendGroupedExtensionsTableModel.sendGroupedExtensions)
            || (this.props.isLoading !== nextProps.isLoading)
            || this.props.saveFilterShow !== nextProps.saveFilterShow
            || !isEqual(this.state, nextState)
            || isEqual(this.props.selectedRows, nextProps.selectedRows)
            || !isEqual(this.props.defaultFilter, nextProps.defaultFilter)
            || !isEqual(this.props.currentFilter, nextProps.currentFilter)
        );
    }

    renderShowsTotal(start: number, to: number, total: number) {
        return (
            <p>
                Showing {start} to {to} of {total} entries
            </p>
        );
    }

    private setNoContent() {
        if (this.props.isLoading) {
            return (<LoadingOverlay style={{ height: '400px' }}>
                <Loader loading={this.props.isLoading} />
            </LoadingOverlay>)
        } else {
            return DeliveredReturnsTableConstants.OtherMessage.NoReturnsFound
        }
    }

    private defaultType(cell: any, row: any) {
        return <div title={cell} className="ellipsis">{cell}</div>;
    }

    private onFilterSaveClick = (filterName: string) => {

        if (!Helper.validateFilter(this.props.currentFilter)) {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.FilterFieldsAreEmpty, null);
            return;
        }

        let temThis = this;
        if (typeof filterName == 'undefined' || filterName.length == 0 || filterName == this.props.proxyFilter) {
            this.props.onSaveFilterShow();
        }
        else {
            bootbox.dialog({
                title: DeliveredReturnsTableConstants.Title.FilterSaveUpdate,
                message: DeliveredReturnsTableConstants.OtherMessage.DoYouWantToCreateNewFilterOrUpdateTheCurrent,
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i>' + DeliveredReturnsTableConstants.ControlLabel.NoUpdateTheCurrent,
                        className: 'btn-white',
                        callback: function () { temThis.props.onFilterUpdate(filterName) }
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i>' + DeliveredReturnsTableConstants.ControlLabel.YesCreateNew,
                        className: 'btn-info',
                        callback: function () { temThis.props.onSaveFilterShow(); }
                    }
                }
            });
        }
    }

    createCustomCheckbox = (props: CustomSelectProps): any => {
        return (<CheckBoxComponent size={CheckBoxSize.sm}
            id={"send-grouped-returns-checkbox-" + props.rowIndex}
            indeterminate={props.indeterminate}
            checked={props.checked}
            disabled={props.disabled}
            onChange={(e: any) => props.onChange(e, props.rowIndex)}
            ref={(input: any) => {
                if (input) {
                    input.indeterminate = props.indeterminate;
                }
            }}
            text={""}
            className="" />);
    }

    private getSendGroupedReturnsAction = (cell: any, row: any) => {
        return <SendGroupedExtensionsActions
            rowIndex={row.rowIndex}
            groupId={row.groupId as number}
            onProcessGroupOpen={this.props.onProcessGroupOpen}
            onEditControllerInfoOpen={this.props.onEditControllerInfoOpen}
            onDeleteGroupOpen={this.props.onDeleteGroupOpen}
            onSetAccessOpen={this.props.onSetAccessOpen}
            onReportProblemOpen={this.props.onReportGroupProblemOpen}
            OnEditGroupInfoOpen={this.props.OnEditGroupInfoOpen}
        />;
    }

    private createCustomToolBar = (props: ToolBarProps) => {
        return (
            <div className="col-sm-12 col-md-12 col-lg-12">
                {
                    <div className="col-sm-3 col-md-3 col-lg-1 nopadding">
                        <div className="btn-only">
                            <span className='fas' style={{ padding: '2px 0', textShadow: '0px 1px 0px rgba(255,255,255,.5)', fontSize: '12px' }}>
                            </span>
                        </div>
                        {/* 
							<Button
								onClick={this.props.onPageReload}
								title="Refresh"
								disabled={this.props.refreshDelay}
								className="btn-white btn-only marR10"
								style={{ paddingLeft: '12px' }}
								data-test-auto="11BC25B0-F325-4170-AFA9-598384364972">
								<span className={(this.props.refreshDelay ? "fa-spin " : " ") + 'fas fa-redo-alt fa-btn-icon'}
									style={{ padding: '2px 0', textShadow: '0px 1px 0px rgba(255,255,255,.5)', fontSize: '12px' }}>
								</span>
							</Button>
						*/}
                    </div>
                }
            </div>
        );
    }

    private getColumns = (): any[] => {
        return [
            {
                header: '',
                key: 'index',
                isKey: true,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: true,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'index', style: { font: 'bold' } } as TextFilter

            },
            {
                header: 'Group Name',
                key: 'groupName',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'Group Name', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Tax Software',
                key: 'taxSoftware',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: false,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: { type: 'SelectFilter', placeholder: 'Select Tax Software', options: TaxSoftwareList } as SelectFilter
            },

            {
                header: 'Created By',
                key: 'createdBy',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'Created By', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Date',
                key: 'createdOn',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: { type: 'SelectFilter', placeholder: 'Select Created Date Limit', options: DaysRangeOptionsList } as SelectFilter
            },
            {
                header: 'Set Access',
                key: 'setAccess',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: { type: 'SelectFilter', placeholder: 'Select Set Access', options: SetAccessList } as SelectFilter
            },

            {
                header: 'Action',
                key: 'button', // String-based value accessors!
                isKey: false,
                dataFormat: this.getSendGroupedReturnsAction,
                columnClassName: 'button-cell',
                dataSort: false,
                toolTip: false,
                isHidden: false,
                width: '145px',
                expandable: false,
                filter: { type: 'TextFilter', placeholder: '', style: { display: 'none' } } as TextFilter
            },
        ];
    }

    private onFilterChange(dataField: any) {
        if (!this.filterChanged && !this.isAppiedFilter) {
            this.filterChanged = true;
            this.props.onFilterChange(dataField, this.props.filterType);
            this.filterChanged = false;
        }
    }
    public onClearFilter = (clearAll?: boolean) => {
        this.filterChanged = true;
        (this.refs.groupName as TableHeaderColumn).cleanFiltered();
        (this.refs.taxSoftware as TableHeaderColumn).cleanFiltered();
        (this.refs.createdBy as TableHeaderColumn).cleanFiltered();
        (this.refs.createdOn as TableHeaderColumn).cleanFiltered();
        (this.refs.setAccess as TableHeaderColumn).cleanFiltered();
        this.filterChanged = false;
        this.props.onClearFilter();
    }

    public onLoadSelectedFilter = (filter?: IFilters) => {
        this.isAppiedFilter = true;
        this.onClearFilter();
        if (filter) {
            this.filterChanged = true;

            for (let field of Object.keys(filter.fields)) {
                switch (field) {
                    case 'groupName':
                        (this.refs.groupName as TableHeaderColumn).applyFilter(filter.fields[field]);
                        break;
                    case 'taxSoftware':
                        (this.refs.taxSoftware as TableHeaderColumn).applyFilter(filter.fields[field]);
                        break;
                    case 'createdBy':
                        (this.refs.createdBy as TableHeaderColumn).applyFilter(filter.fields[field]);
                        break;
                    case 'createdOn':
                        let createdOnFilter: any = filter.fields[field];
                        if (createdOnFilter) {
                            (this.refs.createdOn as TableHeaderColumn).applyFilter(createdOnFilter);
                        }
                        break;
                    case 'setAccess':
                        (this.refs.setAccess as TableHeaderColumn).applyFilter(filter.fields[field]);
                        break;

                }
                this.filterChanged = false;
            }

            this.isAppiedFilter = false;
            this.onFilterChange(filter.fields);
            (this.refs.table as BootstrapTable).handleSearch(filter.searchText);
        }
    }

    expandComponent = (row: any) => {
        const pageSize: number = 20;
        const paddingBottom = row.taxReturns && row.taxReturns.length > pageSize ? '0px' : '10px';
        return <div style={{ paddingTop: '10px', paddingBottom: paddingBottom, overflow: 'auto' }}>
            <ExtensionsTable
                isLoading={false}
                onProcessReturnOpen={this.props.onProcessReturnOpen}
                taxReturns={row.taxReturns ? row.taxReturns as ITaxReturn[] : []}
                groupId={row.groupId as number}
                onDocumentSelect={this.props.onDocumentSelect}
                onDocumentUnSelect={this.props.onDocumentUnSelect}
                selectedDocuments={this.props.selectedDocuments}
                onEditClientInfoOpen={this.props.onEditClientInfoOpen}
                onDeleteTaxReturnOpen={this.props.onDeleteTaxReturnOpen}
                onChangeStatusOpen={this.props.onChangeStatusOpen}
                onReportProblemOpen={this.props.onReportProblemOpen}
                pageSize={pageSize}
                onReprocessReturnOpen={this.props.onReprocessReturnOpen}
                onRecallReturnOpen={this.props.onRecallReturnOpen}
                onReturnLevelRowDoubleClick={this.props.onReturnLevelRowDoubleClick}
            />
        </div>
    }

    public render() {
        let columns = this.getColumns();
        const options: Options = {
            onSortChange: this.props.onSortChange,
            onPageChange: this.props.onPageChange,
            sizePerPage: this.props.pageSize,
            onSearchChange: this.props.onSearchChange,
            page: this.props.pageNo,
            paginationShowsTotal: this.renderShowsTotal,
            onRowDoubleClick:
                (row: any) => {
                    this.props.onGroupLevelRowDoubleClick(row.rowIndex);
                },
            onFilterChange: this.onFilterChange,
            noDataText: this.setNoContent(),
            toolBar: this.createCustomToolBar,
            expandBy: 'column',
            //clearSearch: true,
            //clearSearchBtn: this.createCustomClearSearchBtn,
            //searchField: this.createCustomSearchField,
            nextPage: <span className="fa fa-angle-right" />,
            prePage: <span className="fa fa-angle-left" />,
            firstPage: <span className="fa fa-angle-double-left" />,
            lastPage: <span className="fa fa-angle-double-right" />
        };

        const pagination = true;

        let selected: number[] = [];

        if (this.props.selectedRows.length > 0) {
            for (var i in this.props.selectedRows) {
                let rowIndex = this.props.selectedRows[i];
                selected.push(((this.props.pageNo - 1) * this.props.pageSize) + rowIndex);
            }
        }

        let selectRowProp: any = {
            mode: 'checkbox',
            //clickToSelect: true,
            onSelect: this.props.onRowSelect,
            onSelectAll: this.props.onSelectAll,
            selected: selected,
            customComponent: this.createCustomCheckbox,
            className: 'row-selected',
            clickToExpand: true

        };

        const searchEnable = true;

        let data: any = [];

        if (this.props.groupedReturns.sendGroupedExtensionsTableModel.sendGroupedExtensions) {
            data = this.props.groupedReturns.sendGroupedExtensionsTableModel.sendGroupedExtensions &&
                this.props.groupedReturns.sendGroupedExtensionsTableModel.sendGroupedExtensions.map((model: any, index: any) => {
                    return {
                        groupId: model.groupId,
                        groupName: model.groupName,
                        taxSoftware: model.taxSoftware,
                        createdBy: model.createdBy,
                        createdOn: moment(model.createdOn).format('MM/DD/YYYY'),
                        button: 'Actions',
                        index: ((this.props.pageNo - 1) * this.props.pageSize) + index,
                        setAccess: model.setAccess,
                        rowIndex: index,
                        taxReturns: model.taxReturns
                    }
                });
        }



        return <div className="pos-relative">

            <Filters ref='Filters'
                onFilterNameChange={this.props.onFilterNameChange}
                onFilterSave={this.props.onFilterSave}
                onClearFilter={this.onClearFilter}
                onExportToExcel={this.props.onExportToExcel}
                filterList={this.props.filterList}
                onSetDefaultFilter={this.props.onSetDefaultFilter}
                onRemoveDefaultFilter={this.props.onRemoveDefaultFilter}
                onFilterDelete={this.props.onFilterDelete}
                show={this.props.saveFilterShow}
                onFilterSaveClick={this.onFilterSaveClick}
                onSaveFilterHide={this.props.onSaveFilterHide}
                onLoadSelectedFilter={this.onLoadSelectedFilter}
                defaultFilter={this.props.defaultFilter}
                loadGrid={this.props.loadGrid}
                showExportExcel={this.state.isHiddenExportExcel}
                proxyFilter={this.props.proxyFilter}
                onPageReload={this.props.onPageReload}
            />
            <div data-test-auto="E2373DCE-29EB-4FA6-B881-3246E125B918">
                <BootstrapTable
                    ref='table'
                    data={data}
                    remote={true}
                    fetchInfo={{ dataTotalSize: this.props.totalRows }}
                    options={options}
                    striped
                    hover={true}
                    pagination={pagination}
                    selectRow={selectRowProp}
                    search={searchEnable}
                    expandableRow={() => { return true; }}
                    expandComponent={this.expandComponent}
                >
                    {columns.map((value: any, index: any) => {
                        return <TableHeaderColumn
                            key={index}
                            ref={value.key}
                            isKey={value.isKey}
                            dataField={value.key}
                            hidden={value.isHidden}
                            width={value.width}
                            dataFormat={value.dataFormat}
                            columnClassName={value.columnClassName}
                            columnTitle={value.toolTip}
                            filter={value.filter}
                            dataSort={value.dataSort}
                            expandable={value.expandable}
                        > {
                                (value.width === 'auto') ?
                                    <span title={value.header} className="table-text-sort">{value.header}</span> :
                                    value.header
                            }

                        </TableHeaderColumn>;
                    })}
                </BootstrapTable>
            </div>
        </div>
    }
}