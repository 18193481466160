import * as bootbox from 'bootbox';
import React from  'react';
import { Button, FormControl, Modal, ProgressBar } from 'react-bootstrap';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { NavLink } from 'react-bootstrap';
import { SasContainer, TaxReturnUploadData, UploadStatus } from '../../../../Core/ViewModels/Common/UploadDocumentViewModel';
import { IUserModel } from '../../../../Core/ViewModels/User/UserViewModel';
import * as UploadTaxReturnStore from '../../../../store/common/uploadTaxReturn';
import { UploadtaxReturnConstants, ValidationContants } from '../../../helper/Constants';
import { getFileSize, GetFileMagicNumber } from '../../../helper/HelperFunctions';
import { getFileExtension, validateFileType, validatePdfFileContent, validateFileSize, NullandEmptyCheck } from '../../../helper/Validations';
import { VenusNotifier } from '../../../helper/VenusNotifier';
import { ITaxReturn } from '../../TaxReturn';
import { UploadedDocumentsTable } from '../../UploadDocument/UploadedDocumentsTable';
import { DocumentUploadDropzoneComponent } from '../DocumentUploadDropzoneComponent';
import { ButtonFormatter } from './UploadCommonFormatters';
import { GroupExtensionsUploadProps, initialGroupInfo } from '../../../../Core/ViewModels/GroupExtensions/ComponentModels';
import DatalistComponent from '../../controls/DatalistComponent';
import { logClientEvent } from '../../../helper/LoggerHelper';
import { API_BASE_URL } from 'src/utils/contants';
import { UploadFunctions, isFileExist } from "@sssuite-js-packages/file-utility";
import { LogEventConstants } from 'src/components/helper/LogEventConstants';
import { IAccessingUsersAndUserGroups, IUserDataFromDB, IUserGroupDataFromDB } from '../../SetAccess/SetAccessCommonModal.model';
import SetAccessCommonModal from '../../SetAccess/SetAccessCommonModal';
import { IReturnAccessSettingsModel } from 'src/Core/ViewModels/Company/CompanySettingsViewModel';
export interface ProSystemUploadModalProps extends GroupExtensionsUploadProps {
    onShow: boolean;
    onHide(): void;
    EROs: IUserModel[];
    routing?: boolean;
    processTaxReturn: (url: string, callback: () => void) => void;
    getUploadLink: (url: string, callback?: (data?: UploadTaxReturnStore.IUploadTaxReturnState) => void) => void;
    submitTaxReturn: (url: string, taxData: string, callback: () => void) => void;
    onPageReload: () => void;
    returnAccessSettings: IReturnAccessSettingsModel;
    availableUsers: IUserDataFromDB[];
    selectedUsers: IUserDataFromDB[];
    availableUserGroups: IUserGroupDataFromDB[];
    selectedUserGroups: IUserGroupDataFromDB[];
}

export interface ProSystemUploadModalState {
    TaxReturnUploadData: TaxReturnUploadData[];
    gridColumnDisable: boolean;
    showSetAccess: boolean;
    sasContainer: SasContainer[];
    config: {
        dropzoneSelector: string,
        iconFiletypes: ['.pdf'],
        showFiletypeIcon: boolean,
        postUrl: string
    };
    setAccessAccessingUsers: IUserModel[];
    setAccessCurrentUsers: IUserModel[];
    taxReturnIndex: number;
    taxReturns: ITaxReturn;
    inProgress: boolean;
    availableUsers: IUserDataFromDB[];
    selectedUsers: IUserDataFromDB[];
    availableUserGroups: IUserGroupDataFromDB[];
    selectedUserGroups: IUserGroupDataFromDB[];
}

const MaxFileLimitPerUpload: number = 30;

export class ProSystemUploadDocumentModal extends React.Component<ProSystemUploadModalProps, ProSystemUploadModalState> {

    constructor(props: ProSystemUploadModalProps) {
        super(props);
        this.state = {
            TaxReturnUploadData: [],
            gridColumnDisable: true,
            showSetAccess: false,
            sasContainer: [],
            config: {
                dropzoneSelector: 'div.filepicker',
                iconFiletypes: ['.pdf'],
                showFiletypeIcon: true,
                postUrl: "/api/"
            },
            setAccessAccessingUsers: [],
            setAccessCurrentUsers: [],
            taxReturnIndex: 0,
            taxReturns: {} as ITaxReturn,
            inProgress: false,
            availableUsers: [],
            selectedUsers: [],
            availableUserGroups: [],
            selectedUserGroups: []
        }
        this.deleteReturn = this.deleteReturn.bind(this);
        this.onSetAccess = this.onSetAccess.bind(this);
        this.onSetAccessHide = this.onSetAccessHide.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleUploaderClose = this.handleUploaderClose.bind(this);
        this.validateTaxReturnUploadData = this.validateTaxReturnUploadData.bind(this);

        this.setAcessShow = this.setAcessShow.bind(this);
        this.onSetAccessSave = this.onSetAccessSave.bind(this);

        this.uploadProgressCallback = this.uploadProgressCallback.bind(this);
    }
    componentWillReceiveProps(nextProps: ProSystemUploadModalProps) {
        if(nextProps.availableUsers.length>0){
            const { availableUsers, selectedUsers, availableUserGroups, selectedUserGroups } = nextProps;
            this.setState({
                availableUsers,
                selectedUsers,
                availableUserGroups,
                selectedUserGroups
            });
        }
        
    }

    private onGroupChange = (row: any, value: any) => {
        var _gridData = [...this.state.TaxReturnUploadData];
        _gridData.map((gridData, index) => {
            if (gridData.rowIndex == row.rowIndex) {
                gridData.groupInfo.name = value;
                var group = this.props.groups.find((e) => e.name === value);
                if (group)
                    gridData.groupInfo.id = group.id;
                else
                    gridData.groupInfo.id = 0;

                var access = this.props.groupAccess.find((e) => e.groupId === gridData.groupInfo.id);
                if (access)
                    gridData.setAccess = "Restricted";
                else
                    gridData.setAccess = "Everyone";

            }
        });

        this.setState({ TaxReturnUploadData: _gridData });
    }

    private datalistFormat = (cell: any, row: any) => {
        return <DatalistComponent
            id='ddlUTUploadGroup'
            options={
                this.props.groups?.map((option) => {
                    return {
                        value: option.id,
                        label: option.name,
                    };
                }) ?? []
            }
            onChange={(val) => this.onGroupChange(row, val)}
            selectedValue={cell}
            disabled={row.gridRowDisable}
        />
    }

    private groupAlreadyExistsValidation(groupName: string) {
        let isValid: boolean = true;
        this.props.groups.map((list, index) => {
            if (list.name == groupName.trim()) {
                isValid = false;
                return isValid;
            }
        });
        return isValid;
    }

    private onClientIdChange = (row: any, event: any) => {
        var _gridData = [...this.state.TaxReturnUploadData];
        _gridData.map((value, index) => {
            if (value.rowIndex == row.rowIndex) {
                value.clientId = event.target.value
            }
        });

        this.setState({ TaxReturnUploadData: _gridData });
    }

    private onEROChange = (row: any, value: any) => {
        var _gridData = [...this.state.TaxReturnUploadData];
        _gridData.map((gridData, index) => {
            if (gridData.rowIndex == row.rowIndex) {
                gridData.partnerId = value
            }
        });

        this.setState({ TaxReturnUploadData: _gridData });
    }

    //===========================================================================
    // Bootstrap Table Formatters start
    //===========================================================================

    defaultType(cell: any, row: any) {
        return cell;
    }

    statusFormatter(cell: any, row: any) {
        return (<span>{UploadStatus[cell].toString()}</span>);
    }

    buttonFunction(cell: any, row: any) {
        return (
            <ButtonFormatter deleteReturn={() => this.deleteReturn(row)}
                disabled={(row.progressBar != undefined) && (row.progressBar != 100) ? true : false}
                data-test-auto="B07622E7-FEB7-4759-BA19-4DF7D79BE7E9" />
        );
    }

    progressbar(cell: any, row: any) {
        return <ProgressBar striped
            variant={(cell != undefined) && (cell != 100) ? "warning" : "success"}
            now={cell} />
    }

    textBox(cell: any, row: any) {
        return <FormControl type="text" disabled={row.gridRowDisable}
            value={cell}
            onChange={this.onClientIdChange.bind(this, row)}
            data-test-auto="8E584D37-7EF3-4D2A-8CC8-E21FA295F6EB" />
    }

    linkFormat(cell: any, row: any) {
        const disableLink = NullandEmptyCheck(row.groupInfo.name) ? "disable-link" : "";
        return <NavLink href="javascript:"
            className={disableLink}
            style={{ textDecoration: "underline" }} onClick={this.onSetAccess.bind(this, row)}
            data-test-auto="7FDF51B6-41F6-4E58-9A03-C52D36983AF0">{cell}</NavLink>
    }

    //===========================================================================
    // Bootstrap Table Formatters End
    //===========================================================================

    uploadCommittCallBack = (fileToUpload: any) => {
        if (this.state.TaxReturnUploadData.length > 0) {
            let tempGridData = this.state.TaxReturnUploadData;
            tempGridData.map((tempGridDataValue, index) => {
                if (tempGridDataValue.name == fileToUpload.fileName) {
                    tempGridDataValue.progressBar = 100;
                    tempGridDataValue.status = UploadStatus.Uploaded;
                    tempGridDataValue.gridRowDisable = false;
                }
            });
            this.setState({
                TaxReturnUploadData: tempGridData
            }, () => {
                if (!this.isUploadOnGoing()) {
                    this.setState({ gridColumnDisable: false });
                }
            });
        }
    }

    uploadFailureCallback = (fileToUpload: any) => {
    // On failure of retry , Error view and pop up notification need to be discussed with team, and will be implemented in next sprit.
    }

    onSubmitTaxreturns = () => {
        const tempUploadObj = this.state.TaxReturnUploadData;
        var newGroup = tempUploadObj.find((a) => a.groupInfo.id === 0);
        if (this.validateTaxReturnUploadData(this.state.TaxReturnUploadData)) {
            this.setState({
                inProgress: true
            }, () => {
                this.props.submitTaxReturn(
                    `${API_BASE_URL}/api/Upload/SubmitTaxReturnsAync`,
                    JSON.stringify(this.state.TaxReturnUploadData), () => {
                        this.UploadConfirmation();
                        this.props.onPageReload();
                        this.handleClose();
                        this.setState({
                            inProgress: false
                        });
                        if (newGroup)
                            this.props.updateGroupInfoState(true);
                    });
                logClientEvent(`ProSystem ${LogEventConstants.Common.Upload}`,
                        { count: tempUploadObj.length }
                    );
            });
        }
    }



    private UploadConfirmation() {
        let message: string = "<h5 class='marT0'>" + UploadtaxReturnConstants.ReturnSubmitSuccess + ":</h5><ul class='marL30'>";
        this.state.TaxReturnUploadData.map((value, index) => {
            message = message + "<li>" + value.clientId + "</li>";
        });
        message = message + "</ul>";
        bootbox.alert({
            message: message,
            className: 'in',
            buttons: {
                ok: {
                    label: '<i class="fas fa-check"></i> OK',
                    className: 'btn-info'
                }
            },
            callback() { }
        });
    }

    deleteReturn(row: any) {
        let _self = this;
        bootbox.confirm({
            message: UploadtaxReturnConstants.DeleteFileMessage,
            className: 'in',
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> OK',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    var _gridData = [..._self.state.TaxReturnUploadData];
                    _gridData = _gridData.filter(i => i.rowIndex != row.rowIndex);
                    //Update row index
                    for (let i = 0; i < _gridData.length; i++) {
                        _gridData[i].rowIndex = i;
                        _gridData[i].number = i + 1;
                    }
                    let _tempgridData = _gridData;

                    let _uploadData = [..._self.state.sasContainer];
                    _uploadData = _uploadData.filter(i => i.sasGuid != row.sasGuid);

                    let _tempUploadData = _uploadData;
                    if (_gridData.length == 0) {
                        _self.setState({ gridColumnDisable: true });
                    }
                    _self.setState({ TaxReturnUploadData: _tempgridData, sasContainer: _tempUploadData }, () => _self.forceUpdate());
                }
            }
        });
    }

    private reset = () => {
        let tempUploadData: SasContainer[] = [];
        let tempGridData: TaxReturnUploadData[] = [];
        this.state.sasContainer.slice(0, this.state.sasContainer.length);
        this.setState({ sasContainer: tempUploadData, TaxReturnUploadData: tempGridData });
        this.setState({ gridColumnDisable: true })
    }

    private handleClose() {
        this.reset();
        this.props.onHide();
    }

    private isUploadOnGoing = (): boolean => {
        let isuploading: boolean = false;
        this.state.TaxReturnUploadData.map((value) => {
            if (value.progressBar == undefined ? 0 : value.progressBar < 100) {
                isuploading = true; return;
            }
        });
        return isuploading;
    }

    private handleUploaderClose() {
        let _self = this;
        if (this.state.TaxReturnUploadData.length == 0) {
            _self.handleClose();
            return;
        }
        if (this.isUploadOnGoing()) {
            bootbox.alert({
                message: UploadtaxReturnConstants.AbortingUploadWarning,
                buttons: {
                    ok: {
                        label: '<i class="fas fa-check"></i> OK',
                        className: 'btn-info'
                    }
                }
            });
        }
        else {
            bootbox.confirm({
                message: UploadtaxReturnConstants.CloseConfirmationMessage,
                className: 'in',
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: 'btn-white btn-default'
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> OK',
                        className: 'btn-info'
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        _self.handleClose();
                        _self.forceUpdate();
                    }
                }
            });
        }
    }

    private validateTaxReturnUploadData = (uploadData: TaxReturnUploadData[]): boolean => {
        let valid: boolean = true;
        for (var i = 0; i < uploadData.length; i++) {
            if (uploadData[i].clientId === undefined || uploadData[i].clientId!.trim() === "") {
                VenusNotifier.Warning("Please enter client Id to submit", "");
                valid = false;
                break;
            }
        }
        return valid;
    }

    //===========================================================================
    // Set Access End 
    //===========================================================================

    setAcessShow() {
        this.setState({ showSetAccess: true })
    }

    onSetAccessHide() {
        this.setState({
            showSetAccess: false
        })
    }

    onSetAccess(taxReturn: any, event: any) {
        let currentDoc = this.state.TaxReturnUploadData.find(x=>x.rowIndex==taxReturn.rowIndex)
        
        const allUsers = [...this.state.selectedUsers, ...this.state.availableUsers];
        let selectedUser: IUserDataFromDB[] = [];
        currentDoc.documentAccess.users.forEach((userId) => {
            let user = allUsers.find((x) => x.id == userId);
            if (user) {
                selectedUser.push(user);
            }
        });
        let availableUsers: IUserDataFromDB[] = [];
        allUsers.forEach((user) => {
            let isSelectedUser = selectedUser.find((x) => x.id == user.id);
            if (!isSelectedUser) {
                availableUsers.push(user);
            }
        });
        const allUserGroups = [...this.state.selectedUserGroups, ...this.state.availableUserGroups];
        let selectedUserGroups: IUserGroupDataFromDB[] = [];
        currentDoc.documentAccess.userGroups.forEach((userGroupId) => {
            let userGroup = allUserGroups.find((x) => x.id == userGroupId);
            if (userGroup) {
                selectedUserGroups.push(userGroup);
            }
        });
        let availableUserGroups: IUserGroupDataFromDB[] = [];
        allUserGroups.forEach((userGroup) => {
            let isSelectedUserGroup = selectedUserGroups.find((x) => x.id == userGroup.id);
            if (!isSelectedUserGroup) {
                availableUserGroups.push(userGroup);
            }
        });
        this.setState({
            taxReturnIndex: taxReturn.rowIndex,
            showSetAccess: true,
            selectedUsers: selectedUser,
            selectedUserGroups: selectedUserGroups,
            availableUsers: availableUsers,
            availableUserGroups:availableUserGroups
        });
    }

    private onSetAccessSave = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const index = this.state.taxReturnIndex;
        const { selectedUsers, selectedUserGroups, availableUsers, availableUserGroups } = accessingUsersAndUserGroups;
        this.state.TaxReturnUploadData.map((value) => {
            if (value.rowIndex == index) {
                let isAllUsers = false;
                let isAllUserGroups = false;
                if (
                    (selectedUsers.length > 0 && selectedUsers.indexOf(0) !== -1) ||
                    availableUsers.length === 0 ||
                    (availableUsers.length === 1 && availableUsers[0] === 0)
                ) {
                    isAllUsers = true;
                }

                if (
                    (selectedUserGroups.length > 0 && selectedUserGroups.indexOf(0) !== -1) ||
                    availableUserGroups.length === 0 ||
                    (availableUserGroups.length === 1 && availableUserGroups[0] === 0)
                ) {
                    isAllUserGroups = true;
                }

                if (isAllUsers && isAllUserGroups) {
                    value.setAccess = "Everyone";
                } else {
                    value.setAccess = "Restricted";
                }

                value.documentAccess = {
                    documents: [],
                    userGroups: accessingUsersAndUserGroups.selectedUserGroups,
                    users: accessingUsersAndUserGroups.selectedUsers
                };
            }
        });
        this.setState({
            showSetAccess: false,
            selectedUsers: this.getSelectedUser(accessingUsersAndUserGroups),
            availableUsers: this.getAvailableUser(accessingUsersAndUserGroups),
            selectedUserGroups: this.getSelectedUserGroups(accessingUsersAndUserGroups),
            availableUserGroups: this.getAvailableUserGroups(accessingUsersAndUserGroups)
        });
    }

    getSelectedUser = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUsers = [...this.state.selectedUsers, ...this.state.availableUsers];
        let selectedUser: IUserDataFromDB[] = [];
        accessingUsersAndUserGroups.selectedUsers.forEach((userId) => {
            let user = allUsers.find((x) => x.id == userId);
            if (user) {
                selectedUser.push(user);
            }
        });
        return selectedUser;
    };

    getAvailableUser = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUsers = [...this.state.selectedUsers, ...this.state.availableUsers];
        let availableUser: IUserDataFromDB[] = [];
        accessingUsersAndUserGroups.availableUsers.forEach((userId) => {
            let user = allUsers.find((x) => x.id == userId);
            if (user) {
                availableUser.push(user);
            }
        });
        return availableUser;
    };

    getSelectedUserGroups = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUserGroups = [...this.state.selectedUserGroups, ...this.state.availableUserGroups];
        let selectedUserGroups: IUserGroupDataFromDB[] = [];
        accessingUsersAndUserGroups.selectedUserGroups.forEach((userGroupId) => {
            let userGroup = allUserGroups.find((x) => x.id == userGroupId);
            if (userGroup) {
                selectedUserGroups.push(userGroup);
            }
        });
        return selectedUserGroups;
    };

    getAvailableUserGroups = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUserGroups = [...this.state.selectedUserGroups, ...this.state.availableUserGroups];
        let availableUserGroups: IUserGroupDataFromDB[] = [];
        accessingUsersAndUserGroups.availableUserGroups.forEach((userGroupId) => {
            let userGroup = allUserGroups.find((x) => x.id == userGroupId);
            if (userGroup) {
                availableUserGroups.push(userGroup);
            }
        });
        return availableUserGroups;
    };

    //===========================================================================
    // Set Access End 
    //===========================================================================

    djsConfig = {
        uploadMultiple: true,
        addRemoveLinks: true,
        acceptedFiles: "application/pdf",
        headers: { 'Access-Control-Allow-Origin': '*', "x-ms-blob-type": "BlockBlob" },
        previewsContainer: false,
        autoProcessQueue: false,
        autoDiscover: false
    }

    eventHandlers = {

        init: () => {
            this.reset();
        },
        addedfiles: (files: any) => {

            try {

                let _self = this;

                _self.validateFileCount(files);

                let tmpTaxReturnUploadData: TaxReturnUploadData[] = _self.convertToModel(files);

                tmpTaxReturnUploadData = _self.handleDuplicates(tmpTaxReturnUploadData);
                if (tmpTaxReturnUploadData.length > 0) {
                    _self.validateFileContent(tmpTaxReturnUploadData).then((result) => {

                        _self.setState({
                            TaxReturnUploadData: _self.state.TaxReturnUploadData.concat(result),
                            gridColumnDisable: true
                        }, () => {

                            _self.getUploadLink();

                        });
                    });
                }

            }
            catch (error) {
                VenusNotifier.Warning(error.message, null);
            }
        }
    }


    private validateFileCount = (uploadedFiles: any) => {

        if (uploadedFiles.length + this.state.TaxReturnUploadData.length > MaxFileLimitPerUpload) {

            throw new Error("You cannot upload more than " + MaxFileLimitPerUpload + " files at a time. Please choose less.");
        }
    }


    private convertToModel = (uploadedFiles: any): TaxReturnUploadData[] => {

        let tmpTaxReturnUploadData: TaxReturnUploadData[] = [];

        for (let i = 0; i < uploadedFiles.length; i++) {

            let uploadedFile = uploadedFiles[i];

            if (validateFileSize(uploadedFile)) {

                let tmpFileName = uploadedFiles[i].name;
                let fileExtension = getFileExtension(tmpFileName);

                // Validate file extension

                if (validateFileType(fileExtension)) {

                    tmpTaxReturnUploadData.push({
                        name: tmpFileName, clientName: "clname",
                        clientId: tmpFileName.replace("." + fileExtension, ''),
                        number: (tmpTaxReturnUploadData.length +
                            this.state.TaxReturnUploadData.length) + 1,
                        progressBar: 0, 
                        setAccess: !(
                            this.props.returnAccessSettings != undefined &&
                            this.props.returnAccessSettings!.isSetAccessToEveryone
                        )
                            ? "Restricted"
                            : "Everyone", 
                        size: getFileSize(uploadedFile.size),
                        status: UploadStatus.Wait, sasGuid: "", gridRowDisable: true,
                        documentAccess: { documents: [], 
                            userGroups: this.state.selectedUserGroups.map((group) => {
                                return group.id;
                            }),
                            users: this.state.selectedUsers.map((user) => {
                                return user.id;
                            })
                        },
                        rowIndex: (tmpTaxReturnUploadData.length +
                            this.state.TaxReturnUploadData.length) + 1,
                        file: uploadedFile,
                        groupInfo: { ...initialGroupInfo, name: this.props.defaultGroup },
                    });
                }
            }
        }

        return tmpTaxReturnUploadData;
    }

    private validateFileContent = (tmpTaxReturnUploadData: TaxReturnUploadData[]): Promise<any> => {
        var promise: any = null;
        let _tmpTaxReturnUploadData: TaxReturnUploadData[] = Object.assign({}, tmpTaxReturnUploadData);
        for (let i = 0; i < tmpTaxReturnUploadData.length; i++) {
            let uploadedFile = tmpTaxReturnUploadData[i].file;
            promise = new Promise((resolve) => {
                GetFileMagicNumber(uploadedFile).then((result) => {
                    // Validate file content
                    if (!validatePdfFileContent(result)) {
                        let index = tmpTaxReturnUploadData.findIndex(x => x.name == _tmpTaxReturnUploadData[i].name)
                        tmpTaxReturnUploadData.splice(index, 1)
                        VenusNotifier.Warning(ValidationContants.PdfFileValidation, null);
                    }
                    resolve(result)
                });
            });
        }
        return promise.then(() => { return tmpTaxReturnUploadData })
    }

    private handleDuplicates = (taxReturnUploadData: TaxReturnUploadData[]): TaxReturnUploadData[] => {

        for (let i = 0; i < taxReturnUploadData.length; i++) {

            let file = taxReturnUploadData[i];
            let fileName: string = file.name || "";
            let fileExtension = getFileExtension(fileName);

            let filecount = 1;
            while (isFileExist(fileName, this.state.TaxReturnUploadData)) {
                fileName = file.name || "";
                fileName = fileName.replace("." + fileExtension, '');
                fileName = fileName + " (" + filecount + ")." + fileExtension;
                filecount++;
            }
            taxReturnUploadData[i].name = fileName;
        }

        return taxReturnUploadData;
    }


    private getUploadLink = () => {

        let _self = this;

        let uploadHelperFunctions = new UploadFunctions();

        this.state.TaxReturnUploadData.filter(x => x.status == UploadStatus.Wait).forEach((file: TaxReturnUploadData, index: number) => {

            file.status = UploadStatus.Initiating;

            this.props.getUploadLink(`${API_BASE_URL}/api/Upload/GetTaxReturnUploadLinkAsync`, (data?: UploadTaxReturnStore.IUploadTaxReturnState) => {

                try {

                    if (data) {

                        file.sasGuid = data ? data.guid : "";
                        file.status = UploadStatus.Uploading;

                        _self.setState({ TaxReturnUploadData: _self.state.TaxReturnUploadData },
                            () => uploadHelperFunctions.uploadFile(file.file, data, file.name ? file.name : "",
                                _self.uploadProgressCallback, _self.uploadCommittCallBack, _self.uploadFailureCallback));
                    } else {
                        throw new Error("Upload link not found !!");
                    }

                } catch (error) {
                    _self.state.TaxReturnUploadData.splice(index, 1);
                    _self.setState({ TaxReturnUploadData: _self.state.TaxReturnUploadData });
                }

            });

        });

    }


    public uploadProgressCallback(percent: number, fileToUpload: any) {
        let tempGridData = this.state.TaxReturnUploadData;
        tempGridData.map((tempGridDataValue, index) => {
            if (tempGridDataValue.sasGuid == fileToUpload.fileGUID) {
                tempGridDataValue.progressBar = percent - 10;
            }
        });
        this.setState({
            TaxReturnUploadData: tempGridData
        });
    }

    public render() {

        const columns = [
            {
                header: '#',
                key: 'number',
                isKey: true,
                dataFormat: this.defaultType,
                width: '30px',
                columnClassName: ''
            },
            {
                header: 'Name',
                key: 'name',
                isKey: false,
                dataFormat: this.defaultType,
                width: 'auto',
                columnClassName: 'word-Visible'
            },
            {
                header: 'Progress',
                key: 'progressBar',
                isKey: false,
                dataFormat: this.progressbar,
                width: 'auto',
                columnClassName: ''
            },
            {
                header: 'Size',
                key: 'size',
                isKey: false,
                dataFormat: this.defaultType,
                width: 'auto',
                columnClassName: ''
            },
            {
                header: 'Status',
                key: 'status',
                isKey: false,
                dataFormat: this.statusFormatter,
                width: 'auto',
                columnClassName: ''
            },
            {
                header: <span>Client ID<span className='asterisk-error'>*</span></span>,
                key: 'clientId',
                isKey: false,
                dataFormat: this.textBox.bind(this),
                width: '240px',
                columnClassName: ''
            },
            {
                header: 'Set Access',
                key: 'setAccess',
                isKey: false,
                dataFormat: this.linkFormat.bind(this),
                width: 'auto',
                columnClassName: ''
            },
            {
                header: 'Remove',
                key: 'button',
                isKey: false,
                dataFormat: this.buttonFunction.bind(this),
                width: 'auto',
                columnClassName: ''
            }
        ];


        return <div>

            <Modal className="upload-doc-modal in" show={this.props.onShow} onHide={this.handleUploaderClose} enforceFocus={false}>
                <Modal.Header closeButton data-test-auto="9DFD99E6-5A5C-4A9A-B40F-975AB2D109B5">
                    <Modal.Title> Upload Document
                        {/*<br /> <div className="uploadpopupinfo">*/}
                        {/*    <i className="fas fa-info-circle"></i><span style={{ marginLeft: 5 }}>{UploadtaxReturnConstants.GroupedReturnNote}</span>*/}
                        {/*</div>*/}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LoadingOverlay >
                        <div className="row">
                            <div className="col-sm-2 text-center">
                                <DocumentUploadDropzoneComponent
                                    componentConfig={this.state.config}
                                    djsConfig={this.djsConfig}
                                    eventHandlers={this.eventHandlers}
                                    autoTestId={"66CC0BE6-4C69-4D54-8179-0B736D384559"}
                                />
                            </div>
                            <div className="col-sm-10 uploaded-documents-table-container overflowVisible" id="uploadDocument">
                                <UploadedDocumentsTable
                                    column={columns}
                                    data={this.state.TaxReturnUploadData}
                                    bodyContainerClass={"overflowVisible"}
                                />
                            </div>
                        </div>
                        <Loader loading={this.state.inProgress} text="File upload in progress..." />
                    </LoadingOverlay>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" onClick={this.handleUploaderClose}
                        data-test-auto="B1C823D3-8D5E-4B9B-9D59-11F3A0D9BEED">
                        <i className='fas fa-times'></i>Close</Button>
                    <Button variant="info" onClick={this.onSubmitTaxreturns}
                        disabled={this.state.gridColumnDisable}
                        data-test-auto="5D0B9B03-68DE-47E7-8681-6EDBD54E005B">
                        <i className='fas fa-save'></i>Submit</Button>
                </Modal.Footer>
            </Modal>

            {this.state.showSetAccess &&
                (
                    <SetAccessCommonModal
                        onCancel={this.onSetAccessHide}
                        onApplyAccess={this.onSetAccessSave}
                        availableUsers={this.state.availableUsers}
                        selectedUsers={this.state.selectedUsers}
                        availableUserGroups={this.state.availableUserGroups}
                        selectedUserGroups={this.state.selectedUserGroups}
                        returnAccessSettings={this.props.returnAccessSettings}
                        isDragAndDropDisabled={true}
                        hide={this.onSetAccessHide}
                    />
                )}
        </div>
    }
}