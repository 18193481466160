import React from 'react';
import {
    IVoucher, ITaxReturn, CustomType, ITaxingAuthority,
    DocumentGroups, VoucherNo, VoucherTypes, VoucherMode, initialVoucher,
    IFormBase, IVoucherFormBase, IGroup, StateUSA, getAllStateImages, EngagementType,
    SignatureControlRole, SignatureGroupType, SignatureControlTypes, ISignatureControl, SignatureType, isIndividual
} from '../../../common/TaxReturn';
import { IPdfDocumentFacade } from '../../../../Core/Utilities/PdfDocumentFacade';
import * as PdfStore from '../../../../store/pdf/PdfStore';
import { PdfPage } from '../PdfPage';
import { BookmarksPane } from '../BookmarksPane';
import {IBookmarkSection, IFormGroupViewState,
    IFormGroupViewProps, ISignatureControlsDictionary, PdfPageSignatureControls, getAllEroSigners
} from '../ProcessReturnModels';
import { INVALID_PAGE } from '../CustomPagination/CustomPagination';
import { AddedVoucherList } from '../LeftSidebar/AddedVoucherList';
import { RecognizedVoucherList } from '../LeftSidebar/RecognizedVoucher';
import { VoucherScript } from '../Voucher/VoucherScript';
import { PDFJSStatic } from 'pdfjs-dist';
import { VenusNotifier } from '../../../helper/VenusNotifier';
import { VoucherTabConstants } from '../../../helper/Constants';
import { IVoucherState } from '../../../../store/ProcessReturn/voucher/VoucherStore';
import { VoucherEntry } from '../Voucher/VoucherEntry';
import moment from "moment";
import { ValidateWebsite } from '../../../helper/Validations';
import { cloneTaxReturn } from '../../../helper/HelperFunctions';

import { IEROSigner } from './TabEFile';
import * as UserSettingStore from '../../../../store/userManagement/UserSettingStore';
import { GetSignatureControl, dragStartSignatureControl } from './../SignatureControls/SignatureHelper';
import { EROSignaturePanel } from '../RightSidebar/EROSignaturePanel';
import * as CompanyStore from '../../../../store/company/CompanyStore';
import * as UserSignatureStore from '../../../../store/common/UserSignatureStore';
import { Guid } from '../../../../Core/Utilities/Guid';
import { IUserProfile } from '../../../navigation/profile/ProfileObjects';
import { ExtensionCompanySettings } from '../../../../Core/ViewModels/Extension/ExtensionComponentModels';
import AwesomePDFViewer from '../PdfViewer/AwesomePDFViewer';
import { PdfProperties } from '../PdfViewer/PdfHelper';
import PageMoveTo from '../PdfViewer/PageMoveTo';
import { IAuthState } from 'src/store/auth/reducer';
import { UploadFunctions } from "@sssuite-js-packages/file-utility";
import { logClientEvent } from 'src/components/helper/LoggerHelper';
import { DocumentEventLog } from 'src/components/helper/LogEventConstants';
import { filterTaxFilingDates } from 'src/components/helper/VoucherNameFilter';

const PDFJS: PDFJSStatic = require('pdfjs-dist');
const INVALID_INDEX: number = -1;

export interface IformGroupViewProps extends IFormGroupViewProps {
    bookmarks: IBookmarkSection[];
    pages: number[];
    reGroupPreview: (pages: number[], destinationGroup: DocumentGroups, sourcegroup?: DocumentGroups) => void;
    taxReturn: ITaxReturn;
    docId: number;
    pdfDocuments: PdfStore.IPdfDocumentDictionary;
    deletePages: (pages: number[]) => void;
    renderTrigger: string;
    isAssignedToLoggedinUser: boolean;
    getAllTaxingAuthorities: ITaxingAuthority[];
    isEnableInvoice: boolean;
    updateTaxDocument: (taxDocument: ITaxReturn) => void;
    getVoucherUploadLink: (taxReturn: ITaxReturn, callback: (data: any) => void) => void;
    voucherStore: IVoucherState;
    getMultiVoucherUploadLink: (taxReturn: ITaxReturn, cbVoucher: IVoucher, callback: (data: any, voucher: IVoucher) => void) => void;
    saveTaxingAuthority: (taxingAuthority: ITaxingAuthority, callback: () => void, callback2?: () => void) => void;
    requestCustomAuthority: (value: boolean) => void;

    removeSignatureControl(control: ISignatureControl, pageNo: number): void;
    addSignatureControl(control: ISignatureControl, pageNo: number, group: DocumentGroups): void;
    updateEROSignatureStamp(userId: number): void;
    replaceSignatureControl(oldControl: ISignatureControl, newControl: ISignatureControl, pageNo: number): void;
    signatureControls: ISignatureControlsDictionary;
    userSettings: UserSettingStore.UserSettings;
    company: CompanyStore.ICompanyData;
    userProfile: IUserProfile;
    userSignatures: UserSignatureStore.IUserSignatures;
    isLoading?: boolean;
    extensionCompanySettings: ExtensionCompanySettings;
    duplicatePages: number[];
    auth: IAuthState
}

interface IformGroupViewState extends IFormGroupViewState {
    currentPage: number;
    zoomEnabled: boolean;
    voucher: IVoucher;
    showLoader: boolean;
    addedVoucher: IVoucher;
    formName: string;
    isVoucherEdit: boolean;
    addedVoucherList: IVoucher[];
    isVocuherDetailsEnabled: boolean;
    masterPaymentUrl: string;
    isPaymentUrlSame: boolean;
    //  pages: number[];

    eroSigners: IEROSigner[];
    selectedEROSigner: IEROSigner;
    focusedGroup: SignatureGroupType;
    scale: number;
}

const isEqual = require("react-fast-compare");
export class TabTransmittals extends React.Component<IformGroupViewProps, IformGroupViewState>{
    constructor(props: IformGroupViewProps) {
        super(props);
        this.state = {
            currentPage: INVALID_PAGE,
            zoomEnabled: false,
            voucher: initialVoucher,
            showLoader: false,
            addedVoucher: initialVoucher,
            formName: "",
            isVoucherEdit: false,
            isVocuherDetailsEnabled: false,
            addedVoucherList: [],
            masterPaymentUrl: "",
            isPaymentUrlSame: false,
            actionPanelShow: true,
            // pages: [],

            eroSigners: [],
            selectedEROSigner: {
                value: 0,
                label: "",
                isEnableSignatureDelegation: true,
                eroImage: ""
            },
            focusedGroup: SignatureGroupType.None,
            scale: PdfProperties.DefaultScale
        };
        this.page = {} as PdfPage;
        this.onPaginationClick = this.onPaginationClick.bind(this);
        this.onZoom = this.onZoom.bind(this);
        this.onZoomRestore = this.onZoomRestore.bind(this);
        this.onReGroupPreview = this.onReGroupPreview.bind(this);
        this.onPageDelete = this.onPageDelete.bind(this);

    }
    private page: PdfPage;

    onReGroupPreview(pages: number[], destinationGroup: DocumentGroups) {
        this.setState({ currentPage: INVALID_PAGE }, () => { this.props.reGroupPreview(pages, destinationGroup) });
    }

    componentWillReceiveProps(nextProps: IformGroupViewProps) {
        if (this.props.userSignatures !== nextProps.userSignatures)
            this.setEroSignerDropdownValues(nextProps);

        if (nextProps.pages && nextProps.pages.length != this.props.pages.length) {
            this.onPageSelect(nextProps.pages[0]);
        }
    }

    shouldComponentUpdate(nextProps: IformGroupViewProps, nextState: IFormGroupViewState) {
        return ((nextProps.pages !== this.props.pages) ||
            (this.state.currentPage === INVALID_PAGE) ||
            (!isEqual(this.state, nextState)));
    }

    componentDidMount() {
        this.props && this.props.pages && this.setState({ currentPage: this.props.pages[0] });
    }

    componentWillMount() {
        this.setEroSignerDropdownValues(this.props);
    }

    public setBookmarkForManuallyAddedVoucher = (authorityID: number, formName: string) => {
        var authorities: ITaxingAuthority[] = this.props.getAllTaxingAuthorities;
        for (let i in authorities) {
            if (authorities[i].Id === authorityID) {
                return authorities[i].AuthorityName + ' ' + formName;
            }
        }
        return formName;
    }

    getVoucherDetails = (page: number, voucherNo: number = -1) => {
        let taxingAuthority: any;
        let paymentUrlInfo: any;
        let editedUrl: string = '';
        let voucherIndex = this.props.taxReturn.formGroups.findIndex(x => x.group == DocumentGroups.Vouchers);

        if (voucherIndex != -1) {
            let voucherForms: IVoucherFormBase[] = this.props.taxReturn.formGroups[voucherIndex].forms as IVoucherFormBase[];
            this.setState({
                voucher: voucherNo != -1 ? voucherForms.find(x => x.pageNo[0] === page && x.voucherNo === voucherNo) as IVoucher :
                    voucherForms.find(x => x.pageNo[0] === page) as IVoucher,
                formName: voucherNo != -1 ? voucherForms.find(x => x.pageNo[0] === page && x.voucherNo === voucherNo)!.formName :
                    voucherForms.find(x => x.pageNo[0] === page)!.formName
            }, () => {
                taxingAuthority = this.props.getAllTaxingAuthorities.find(x => x.Id == this.state.voucher.authorityID);
                const voucherDefaultDates = this.props.voucherStore.voucherDefaultDates
                    .filter(x => x.authorityId === this.state.voucher.authorityID &&
                        x.taxYear === this.props.taxReturn.taxYear &&
                        x.returnType === Number(EngagementType[this.props.taxReturn.engagementType]));
                const paymentUrlInfo: any = filterTaxFilingDates(voucherDefaultDates, this.state.formName, this.state.voucher.paymentType)
                if (paymentUrlInfo) {
                    if (this.state.voucher.onlinePaymentUri && this.state.voucher.onlinePaymentUri.trim().substr(-1) === '/') {
                        editedUrl = this.state.voucher.onlinePaymentUri.trim().slice(0, -1);
                    }
                    else {
                        editedUrl = this.state.voucher.onlinePaymentUri;
                    }
                    if (paymentUrlInfo.paymentURL && paymentUrlInfo.paymentURL.trim().substr(-1) === '/') {
                        paymentUrlInfo.paymentURL = paymentUrlInfo.paymentURL.trim().slice(0, -1);
                    }
                    if (paymentUrlInfo.esPaymentURL && paymentUrlInfo.esPaymentURL.trim().substr(-1) === '/') {
                        paymentUrlInfo.esPaymentURL = paymentUrlInfo.esPaymentURL.trim().slice(0, -1);
                    }
                    this.setState({
                        isPaymentUrlSame: taxingAuthority.CustomAuthority ? true : this.state.voucher.paymentType === VoucherTypes.PaymentVoucher ?
                            paymentUrlInfo.paymentURL === null || paymentUrlInfo.paymentURL === '' ? true : editedUrl === paymentUrlInfo.paymentURL
                            :
                            paymentUrlInfo.esPaymentURL === null || paymentUrlInfo.esPaymentURL === '' ? true : editedUrl === paymentUrlInfo.esPaymentURL,
                        masterPaymentUrl: taxingAuthority.CustomAuthority ? "" : this.state.voucher.paymentType === VoucherTypes.PaymentVoucher ? paymentUrlInfo.paymentURL : paymentUrlInfo.esPaymentURL
                    });
                }
                else {
                    this.setState({
                        isPaymentUrlSame: true
                    });
                }
            });
        }
    }

    private onVoucherPrepareSuccess = (blob: any, uploadData: any): void => {
        PDFJS.getDocument(URL.createObjectURL(blob)).then((pdf) => {
            let documentFacade: IPdfDocumentFacade =
                (this.props.pdfDocuments[this.props.docId].document as IPdfDocumentFacade);
            documentFacade.append(pdf, uploadData.fileGuid);
            let tempVoucher: IVoucher = { ...this.state.addedVoucher };
            let voucher = this.props.taxReturn.formGroups.find((value) => value.group === DocumentGroups.Vouchers);
            tempVoucher.pageNo = [documentFacade.getLastPage()];
            tempVoucher.bookmark = tempVoucher.customType == CustomType.None ? tempVoucher.formName :
                this.setBookmarkForManuallyAddedVoucher(tempVoucher.authorityID, tempVoucher.formName)

            if (!voucher) {
                voucher = {} as IGroup;
                voucher.group = DocumentGroups.Vouchers;
                voucher.forms = [] as IFormBase[];
                voucher.forms.push(tempVoucher);
                this.props.taxReturn.formGroups.push(voucher);
            }
            else {
                this.props.taxReturn.formGroups.map((value: any) => {
                    if (value.group === DocumentGroups.Vouchers) {
                        value.forms.push(tempVoucher);
                    }
                });
            }
            this.props.updateTaxDocument(this.props.taxReturn);

            let uploadHelperFunctions = new UploadFunctions();
            uploadHelperFunctions.uploadFile(blob, uploadData, tempVoucher.formName);

            //this.getVoucherDetails(this.state.currentPage);
            this.getVoucherDetails(tempVoucher.pageNo[0]);
            this.setState({
                showLoader: false,
                isVoucherEdit: false
            });

        });
    }

    private onVoucherPrepareError = (error: any) => {
        VenusNotifier.Error(VoucherTabConstants.AddVoucher.voucherPdfPrepareMessageFailed, "Error");

        this.setState({
            showLoader: false,
            isVoucherEdit: false
        });

        this.getVoucherDetails(this.state.currentPage);
    }

    private getVoucherUploadLinkCallback = (uploadData: any) => {
        let voucherScript = new VoucherScript(this.state.addedVoucher, this.props.taxReturn);
        voucherScript.prepareVoucher(this.state.addedVoucher)
            .then((blob: any): void => {
                this.onVoucherPrepareSuccess(blob, uploadData);
            }, this.onVoucherPrepareError);
    }


    private removeVoucherForms = (taxReturn: ITaxReturn, page: number) => {
        if (taxReturn && taxReturn.formGroups) {

            let index = taxReturn.formGroups.findIndex(x => x.group == DocumentGroups.Vouchers);
            if (taxReturn.formGroups[index]) {

                let formIndex = taxReturn.formGroups[index].forms.findIndex((form) => form.pageNo.some(x => x === page));

                if (formIndex != -1) {
                    taxReturn.formGroups[index].forms.splice(formIndex, 1);
                }
            }
        }
    }

    private onVoucherDelete = (voucher: IVoucher) => {
        this.removeVoucherForms(this.props.taxReturn, voucher.pageNo[0]);
        this.props.updateTaxDocument(this.props.taxReturn);
        this.setState({ voucher: initialVoucher });
    }

    private onAddVoucher = (voucher: IVoucher) => {
        if (!this.validateVoucher(voucher)) {
            return;
        }
        this.setState({ addedVoucher: voucher, isVocuherDetailsEnabled: false });
        this.props.getVoucherUploadLink(this.props.taxReturn, this.getVoucherUploadLinkCallback);
    }

    private getSelectedVoucher = (voucher: IVoucher) => {

        let taxingAuthority: any = this.props.getAllTaxingAuthorities.find(x => x.Id == voucher.authorityID);
        let voucherDefaultDates: any = this.props.voucherStore.voucherDefaultDates
            .filter(x => x.authorityId === voucher.authorityID &&
                x.taxYear === this.props.taxReturn.taxYear &&
                x.returnType === Number(EngagementType[this.props.taxReturn.engagementType]));
        const paymentUrlInfo: any = filterTaxFilingDates(voucherDefaultDates, voucher.formName, voucher.paymentType)
        if (paymentUrlInfo) {
            if (voucher.onlinePaymentUri && voucher.onlinePaymentUri.trim().substr(-1) === '/') {
                voucher.onlinePaymentUri = voucher.onlinePaymentUri.trim().slice(0, -1);
            }
            if (paymentUrlInfo.paymentURL && paymentUrlInfo.paymentURL.trim().substr(-1) === '/') {
                paymentUrlInfo.paymentURL = paymentUrlInfo.paymentURL.trim().slice(0, -1);
            }
            if (paymentUrlInfo.esPaymentURL && paymentUrlInfo.esPaymentURL.trim().substr(-1) === '/') {
                paymentUrlInfo.esPaymentURL = paymentUrlInfo.esPaymentURL.trim().slice(0, -1);
            }
            this.setState({
                voucher: voucher, isVocuherDetailsEnabled: true,
                isPaymentUrlSame: taxingAuthority.CustomAuthority ? true : voucher.paymentType === VoucherTypes.PaymentVoucher ?
                    paymentUrlInfo.paymentURL === null || paymentUrlInfo.paymentURL === '' ? true : voucher.onlinePaymentUri === paymentUrlInfo.paymentURL
                    :
                    paymentUrlInfo.esPaymentURL === null || paymentUrlInfo.esPaymentURL === '' ? true : voucher.onlinePaymentUri === paymentUrlInfo.esPaymentURL,
                masterPaymentUrl: taxingAuthority.CustomAuthority ? "" : voucher.paymentType === VoucherTypes.PaymentVoucher ? paymentUrlInfo.paymentURL : paymentUrlInfo.esPaymentURL
            });
        }
        else {
            this.setState({
                voucher: voucher,
                isVocuherDetailsEnabled: true,
                isPaymentUrlSame: true,
            });
        }
    }

    private validateVoucher = (voucher: IVoucher) => {

        if (voucher.authorityID === 0) {
            VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.AuthorityWarning, "");
            return false;
        }
        if (voucher.paymentType === VoucherTypes.None || voucher.voucherNo === VoucherNo.None) {
            VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.PaymentTypeWarning, "");
            return false;
        }
        if (!voucher.dueDate || !moment(voucher.dueDate).isValid()) {
            VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.DueDateWarning, "");
            return false;
        }
        if (Number.isNaN(voucher.amount)) {
            VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.AmountEmptyWarning, "");
            return false;
        }
        if (!voucher.formName || voucher.formName.trim() === "") {
            VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.FormNameWarning, "");
            return false;
        }
        if ((voucher.customType === CustomType.None || voucher.customType === CustomType.Select) && voucher.vocherMode !== VoucherMode.Recognized) {
            VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.CustomTypeWarning, "");
            return false;
        }
        if (voucher.customType && voucher.customType == CustomType.OnlinePaymentRequired) {
            if (!voucher.onlinePaymentUri || voucher.onlinePaymentUri === "") {
                VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.OnlinePaymentUrlWarning, "");
                return false;
            }
            else if (!ValidateWebsite(voucher.onlinePaymentUri.trim())) {
                VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.OnlinePaymentValidUrlWarning, "");
                return false;
            }
        }
        if (voucher.customType && voucher.customType === CustomType.AddCustom) {
            if (!voucher.customText || voucher.customText.trim() === "") {
                VenusNotifier.Warning(VoucherTabConstants.AddedVoucher.CustomTextWarning, "");
                return false;
            }
        }
        return true;
    }

    private getAuthorityData(authorityId: number): { name: string, image: string } {
        const authorities: ITaxingAuthority[] = this.props.getAllTaxingAuthorities;
        let voucherAuthority: ITaxingAuthority = authorities[authorities.findIndex(x => x.Id === authorityId)];
        let stateImage: { imageName: string, stateUSA: StateUSA } | undefined;
        let stateAbbreviation: any = voucherAuthority.Abbreviation
        stateImage = getAllStateImages().find(img => img.stateUSA === StateUSA[stateAbbreviation]);
        return {
            name: voucherAuthority.AuthorityName,
            image: !!stateImage ? stateImage.imageName : "no-image.png"
        };
    }

    private UpdateZeroAmountBookMark = (voucher: IVoucher) => {
        const SEPARATOR = "###";
        this.props.bookmarks.map((bookMark) => {
            bookMark.pages.map((page: any) => {
                if (page.pageNo == voucher.pageNo && page.voucherNo == voucher.voucherNo) {
                    page.pageTitle = page.pageTitle + SEPARATOR + '$' + voucher.amount + '.00'
                }
            })
        });

    }

    private onUpdateAddedVoucher = (voucher: IVoucher, currentVoucherClone: IVoucher) => {
        if (!this.validateVoucher(voucher)) {
            return;
        }

        let addedVoucherIndex: number = INVALID_INDEX;
        let voucherIndex = this.props.taxReturn.formGroups.findIndex(x => x.group == DocumentGroups.Vouchers);

        addedVoucherIndex = (this.props.taxReturn.formGroups[voucherIndex].forms as IVoucherFormBase[]).findIndex(x => x.pageNo[0] == currentVoucherClone.pageNo[0] && x.voucherNo == currentVoucherClone.voucherNo);

        if (addedVoucherIndex > INVALID_INDEX) {
            if (this.state.voucher.formName != voucher.formName &&
                this.state.voucher.amount == voucher.amount &&
                this.state.voucher.customType == voucher.customType &&
                this.state.voucher.authorityID == voucher.authorityID &&
                this.state.voucher.dueDate == voucher.dueDate &&
                this.state.voucher.voucherNo == voucher.voucherNo) {
                var clone = cloneTaxReturn(this.props.taxReturn);
                clone.formGroups[voucherIndex].forms[addedVoucherIndex] = { ...voucher } as IVoucher;
                clone.formGroups[voucherIndex].forms[addedVoucherIndex].bookmark = voucher.customType == CustomType.None ? voucher.formName :
                    this.setBookmarkForManuallyAddedVoucher(voucher.authorityID, voucher.formName);
                this.setState({ addedVoucher: { ...voucher }, voucher: voucher });
                this.props.updateTaxDocument(clone);
                VenusNotifier.Success(VoucherTabConstants.VoucherUpdateSuccess, "Success");
            }
            else {
                if (!!voucher.customType && voucher.vocherMode === VoucherMode.CustomVoucher) {
                    let slicedVoucher = this.props.taxReturn.formGroups[voucherIndex].forms.splice(addedVoucherIndex, 1);

                    if (!!slicedVoucher && slicedVoucher[0].pageNo)
                        this.onPageDelete(slicedVoucher[0].pageNo[0]);

                    if (!voucher.authorityImage || !voucher.authorityName) {
                        let authorityData: { name: string, image: string } = this.getAuthorityData(voucher.authorityID);

                        let editedVoucherState: IformGroupViewState = Object.assign({}, this.state);
                        editedVoucherState.voucher = voucher;
                        editedVoucherState.voucher.authorityImage = authorityData.image;
                        editedVoucherState.voucher.authorityName = authorityData.name;

                        this.setState(editedVoucherState);
                    }
                    this.setState({ addedVoucher: { ...voucher }, voucher: voucher });
                    this.props.getVoucherUploadLink(this.props.taxReturn, this.getVoucherUploadLinkCallback);
                    VenusNotifier.Success(VoucherTabConstants.VoucherUpdateSuccess, "Success");
                }
                else {

                    var clone = { ...this.props.taxReturn };
                    clone.formGroups[voucherIndex].forms[addedVoucherIndex] = { ...voucher } as IFormBase;
                    if (voucher.amount == 0) {
                        this.UpdateZeroAmountBookMark(voucher);
                    }
                    this.setState({
                        isVoucherEdit: false,
                        voucher: voucher,
                        addedVoucher: voucher
                    }, () => this.props.updateTaxDocument(clone));
                    VenusNotifier.Success(VoucherTabConstants.VoucherUpdateSuccess, "Success");
                }
            }
        }

    }

    private onAddMultipleVouchers = (vouchers: IVoucher[]) => {
        vouchers.forEach((el, idx) => {
            if (el.amount > 0) {
                const authorities: ITaxingAuthority[] = this.props.getAllTaxingAuthorities;
                let taxingAuthority: ITaxingAuthority = authorities[authorities.findIndex(x => x.Id == el.authorityID)]
                if (taxingAuthority && taxingAuthority.CustomAuthority && el.customType === CustomType.OnlinePaymentRequired) {
                    taxingAuthority.PrefixStateCode = false;
                    taxingAuthority.PaymentURL = el.onlinePaymentUri;
                    this.props.saveTaxingAuthority(taxingAuthority, this.fetchCustomAuthority);
                }
                this.props.getMultiVoucherUploadLink(this.props.taxReturn, el,
                    this.getMultipleVoucherUploadLinkCallback);
                logClientEvent(
                    DocumentEventLog.ManuallyAddedVouchers,
                    {
                        Count: 1,
                        CustomEventType: DocumentEventLog.Name,
                        DocumentGuid: this.props.taxReturn.documentGuid
                    });
            }
        });
    }

    fetchCustomAuthority = () => {
        this.props.requestCustomAuthority(true);
    }

    private getMultipleVoucherUploadLinkCallback = (uploadData: any, voucher: IVoucher) => {
        let voucherScript = new VoucherScript(voucher, this.props.taxReturn);
        voucherScript.prepareVoucher(voucher)
            .then((blob: any): void => {
                this.onMultipleVoucherPrepareSuccess(blob, uploadData, voucher);
            }, this.onVoucherPrepareError);
    }

    private onMultipleVoucherPrepareSuccess = (blob: any, uploadData: any, callbackVoucher: IVoucher): void => {
        PDFJS.getDocument(URL.createObjectURL(blob)).then((pdf) => {
            let documentFacade: IPdfDocumentFacade =
                (this.props.pdfDocuments[this.props.docId].document as IPdfDocumentFacade);
            documentFacade.append(pdf, uploadData.fileGuid);
            let tempVoucher: IVoucher = { ...callbackVoucher };
            let voucher = this.props.taxReturn.formGroups.find((value) => value.group === DocumentGroups.Vouchers);
            tempVoucher.pageNo = [documentFacade.getLastPage()];
            tempVoucher.bookmark = tempVoucher.customType == CustomType.None ? tempVoucher.formName :
                this.setBookmarkForManuallyAddedVoucher(tempVoucher.authorityID, tempVoucher.formName)

            if (!voucher) {
                voucher = {} as IGroup;
                voucher.group = DocumentGroups.Vouchers;
                voucher.forms = [] as IFormBase[];
                voucher.forms.push(tempVoucher);
                this.props.taxReturn.formGroups.push(voucher);
            }
            else {
                this.props.taxReturn.formGroups.map((value: any) => {
                    if (value.group === DocumentGroups.Vouchers) {
                        value.forms.push(tempVoucher);
                    }
                });
            }
            this.props.updateTaxDocument(this.props.taxReturn);

            let uploadHelperFunctions = new UploadFunctions();

            uploadHelperFunctions.uploadFile(blob, uploadData, tempVoucher.formName);

            this.getVoucherDetails(tempVoucher.pageNo[0]);

            this.setState({
                showLoader: false,
                isVoucherEdit: false
            });

        });
    }

    createLeftPanel = (duplicatePages: number[]) => <>
        <BookmarksPane
            title="Pages"
            docId={this.props.docId}
            pdfDocuments={this.props.pdfDocuments}
            sections={this.props.bookmarks}
            onPageDelete={this.onPageDelete}
            onPageSelect={this.onPageSelect}
            getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
            duplicatePages={duplicatePages}
        />
        <AddedVoucherList
            getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
            onAddVoucher={this.onAddVoucher}
            populateVoucherPanel={this.getSelectedVoucher}
            taxReturn={this.props.taxReturn}
            voucherStore={this.props.voucherStore}
            onVoucherDelete={this.onVoucherDelete}
            addedVoucherList={this.state.addedVoucherList}
            onAddMultipeVouchers={this.onAddMultipleVouchers}
        />
        <RecognizedVoucherList
            getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
            populateVoucherPanel={this.getSelectedVoucher}
            taxReturn={this.props.taxReturn}

        />
    </>;

    createRightPanel = (duplicatePage: boolean) => <>
        {(this.props.userSettings.settings.useSignatureStamp) &&
            !duplicatePage &&
            <div>
                <EROSignaturePanel
                    title="Signature Stamp"
                    selectedEROSigner={this.state.selectedEROSigner}
                    eroSigner={this.state.eroSigners}
                    onEROSignerChange={this.handleEROSignerChange}
                    isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
                    onSignatureControlFocus={this.onSignatureControlFocus}
                    dragStart={this.dragStartSignatureControl}
                    dragEnd={this.dragEnd}
                />
            </div>
        }

        {
            (!(this.state.voucher.authorityID === 0) &&
                this.state.isVocuherDetailsEnabled === true) &&
            !duplicatePage &&
            <div style={{ paddingBottom: 10 }}>
                <VoucherEntry
                    onUpdateAddedVoucher={this.onUpdateAddedVoucher}
                    taxingAuthorities={this.props.getAllTaxingAuthorities}
                    voucherDefaultDate={this.props.voucherStore.voucherDefaultDates}
                    voucher={this.state.voucher ? this.state.voucher : initialVoucher}
                    voucherStore={this.props.voucherStore}
                    taxReturn={this.props.taxReturn}
                    isPaymentUrlSame={this.state.isPaymentUrlSame}
                    masterPaymentUrl={this.state.masterPaymentUrl}
                />
            </div>
        }
    </>;

    createMoveTo = (duplicatePage: boolean) => {
        return <PageMoveTo
            hideMoveTo={duplicatePage}
            documentGroup={DocumentGroups.Transmittals}
            reGroupPreview={(destinationGroup: DocumentGroups) => {
                this.props.reGroupPreview([this.state.currentPage], destinationGroup)
                this.setState({ currentPage: this.props.pages[0] });
            }}
        />
    }

    public render() {

        const { duplicatePages } = this.props;

        const duplicatePage = duplicatePages.find(x => x === this.state.voucher.pageNo[0]) ? true : false;

        return (
            <AwesomePDFViewer
                pdfDocument={this.props.pdfDocuments[this.props.docId].document as IPdfDocumentFacade}
                leftPanel={this.createLeftPanel(duplicatePages)}
                rightPanel={this.createRightPanel(duplicatePage)}
                currentPage={this.state.currentPage}
                pages={this.props.pages}
                enableMoveTo={true}
                moveToElement={this.createMoveTo(duplicatePage)}
                signatureControls={this.getEroSignatureControls()}
                isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
                isIndividual={isIndividual(this.props.taxReturn)}
                keyPrefix={"transmittals-control-"}
                onControlRemove={this.onControlRemove}
                onControlUpdate={this.onControlUpdate}
                scale={this.state.scale}
                onScaleChange={(s) => this.setState({ scale: s })}
                onCurrentPageChange={(page) => this.setState({ currentPage: page })}
            />
        );
    }

    private onPaginationClick = (pageNo: number) => {
        this.setState({ currentPage: this.props.pages[pageNo - 1] });
    }

    private onZoom(enable: boolean) {
        this.setState({ zoomEnabled: enable });
    }

    private onZoomRestore() {
        if (this.page) {
            this.setState({ zoomEnabled: false }, this.page.onZoomRestore);
        }
    }

    private onPageSelect = (page: number) => {
        this.setState({ currentPage: page });
    }

    private onPageDelete = (page: number) => {
        //TODO: move the page to deleted group
        this.props.deletePages([page]);

    }

    private onControlRemove = (control: ISignatureControl) => {
        this.props.removeSignatureControl(control, this.state.currentPage);
    }

    private onControlUpdate = (oldControl: ISignatureControl, newControl: ISignatureControl) => {
        this.props.replaceSignatureControl(oldControl, newControl, this.state.currentPage);
    }

    private adjustSamePositionControl = (control: ISignatureControl): ISignatureControl => {
        const signatureControls = this.props.signatureControls[this.state.currentPage];
        if (signatureControls) {
            const index = signatureControls.findIndex(x => x.controlGuid === control.controlGuid);
            if (index !== INVALID_INDEX) {
                control.left = control.left + 5;
                control.top = control.top + 5;
                return this.adjustSamePositionControl(control);
            }
            else {
                return control;
            }
        }
        return control;
    }

    private addSignatureControl = (type: SignatureControlTypes, role: SignatureControlRole, left: number, top: number) => {
        let _self = this;
        const { currentPage } = this.state;
        let control: ISignatureControl = {} as ISignatureControl;
        control.controlGuid = Guid.newGuid().toString();
        control.left = left;
        control.top = top;
        control.type = type;
        control.signatureControlRole = role;
        _self.props.addSignatureControl(control, currentPage, DocumentGroups.TaxReturns);
        logClientEvent(
            DocumentEventLog.SignatureControlAdded,
            {
                Count: 1,
                CustomEventType: DocumentEventLog.Name,
                DocumentGuid: this.props.taxReturn.documentGuid,
                PageNumber: currentPage,
                DocumentGroup: DocumentGroups.TaxReturns,
                SignatureControlTypes: type,
                SignatureControlRole : role
            });
    }

    private filterSignatureControls = (signatureControls: ISignatureControl[], signatureType: any):
        ISignatureControl[] => {
        return GetSignatureControl(
            signatureControls,
            signatureType,
            this.props.userSettings);
    }

    private getEroSignatureControls = (): PdfPageSignatureControls => {
        let signatureControls: ISignatureControl[] = [];
        let signatureType: any = this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType;
        signatureType = (typeof signatureType == "number") ? SignatureType[signatureType] : signatureType;
        signatureControls = this.filterSignatureControls(this.props.signatureControls[this.state.currentPage], signatureType);
        return {
            signatureControls: signatureControls,
            eroSigner: { id: this.state.selectedEROSigner.value, signPath: this.state.selectedEROSigner.eroImage },
            signer: {},
            focusedGroup: SignatureGroupType.Stamp
        } as PdfPageSignatureControls;
    }

    private dragStartSignatureControl = (event: any, controlType: SignatureControlTypes, signatureRole: SignatureControlRole) => {
        dragStartSignatureControl(
            event, controlType, signatureRole,
            this.state.selectedEROSigner.label,
            this.state.currentPage,
            this.state.scale,
            this.addSignatureControl);
    }

    private dragEnd = (event: any) => {
        let element = document.getElementById("dragging-element");
        if (element) {
            element.remove();
        }
    }

     private dropSignatureControl = (event: any) => {
         let controlType = event.dataTransfer.getData('controlType');
         let signatureRole = event.dataTransfer.getData('signatureRole');
         if (controlType && signatureRole) {
             controlType = parseInt(controlType);
             signatureRole = parseInt(signatureRole);
             if (!Number.isNaN(controlType) && !Number.isNaN(signatureRole)) {
                 this.addSignatureControl(controlType, signatureRole, event.clientX, event.clientY);
             }
         }
     }
    private handleEROSignerChange = (selectedOption: IEROSigner) => {
        if (selectedOption && selectedOption.eroImage !== "") {
            this.setState({
                selectedEROSigner: {
                    value: selectedOption.value,
                    label: selectedOption.label,
                    isEnableSignatureDelegation: selectedOption.isEnableSignatureDelegation,
                    eroImage: selectedOption.eroImage
                }
            }, () => {
                this.props.updateEROSignatureStamp(selectedOption.value);
            })
        }
    }

    private onSignatureControlFocus = (focusedGroup: SignatureGroupType) => {
        this.setState({
            focusedGroup: focusedGroup
        });
    }

    private setEroSignerDropdownValues = (nextprops: IformGroupViewProps) => {
        const eroSigners: IEROSigner[] = getAllEroSigners(this.props.company,
            this.props.userProfile,
            this.props.taxReturn,
            this.props.userSettings,
            nextprops.userSignatures,
            this.props.auth
        );

        const selectedEROId = this.props.taxReturn.documentSettings.documentSignatureSetting.signatureStampUser ?
            this.props.taxReturn.documentSettings.documentSignatureSetting.signatureStampUser.userId
            : this.state.selectedEROSigner.value;
        let selectedEROSigner = eroSigners.find(x => x.value == selectedEROId) as IEROSigner;
        selectedEROSigner = selectedEROSigner ? selectedEROSigner : eroSigners.find(x => x.value === 0) as IEROSigner;

        this.setState({
            eroSigners: eroSigners,
            selectedEROSigner: selectedEROSigner,
        });
    }
};