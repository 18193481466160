export const EngagementList: any = {
    1: '1040',
    2: '1065',
    3: '1120',
    4: '1120S',
    5: '1041',
    7: '990/T',
    8: '990PF',
    9: '990EZ'
};

export const SignatureStatusList: any = {
    1: 'E-SIGNED',
    2: 'MANUALLY SIGNED',
    3: 'UPLOADED',
    4: 'AWAITING E-SIGN',
    5: 'AWAITING UPLOAD',
    8: 'SIGNED AND E-SIGNED',
    17: 'ALTERNATE DELIVERY',
    //19: 'PRINTED AND UPLOADED',
    20: "REVIEWED",
    21: 'AWAITING REVIEW',
    22: 'PARTIALLY SIGNED',
    23: "PARTIALLY REVIEWED",
};

export const SignatureStatusOptionsList = [
    { label: 'E-SIGNED', value: 1 },
    { label: 'MANUALLY SIGNED', value: 2 },
    { label: 'UPLOADED', value: 3 },
    { label: 'AWAITING E-SIGN', value: 4 },
    { label: 'AWAITING UPLOAD', value: 5 },
    { label: 'SIGNED AND E-SIGNED', value: 8 },
    { label: 'ALTERNATE DELIVERY', value: 17 },
    //{ label: 'PRINTED AND UPLOADED', value: 19 },
    { label: 'REVIEWED', value: 20 },
    { label: 'AWAITING REVIEW', value: 21 },
    { label: 'PARTIALLY SIGNED', value: 22 },
    { label: "PARTIALLY REVIEWED", value: 23 },
];
