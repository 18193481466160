import { EngagementType } from './../../../components/common/TaxReturn';

export interface IK1DeliveredTaxDocument {
    id: number;
    name: string;
    engagementType: EngagementType;
    taxYear: number;
    clientId: string;
    partnerFirstName: string;
    partnerLastName: string;
    deliveredDate: Date;
    deliveredRatio : number;
    downloadedRatio: number;
}

export interface IK1DeliveredStatusState {
    K1DeliveredStatus: IK1DeliveredStatusViewModel;
    K1Partners: IK1PartnerViewModel;
    K1DeliveredStatusQuery: string;
    K1PartnersQuery: string;
    K1DeliveredStatusLoading: boolean;
    K1PartnersLoading: boolean;
}

export interface IK1DeliveredStatusViewModel {
    count: number;
    documents: IK1DeliveredTaxDocument[];
}

export enum K1ReportStatus {
    None = 0,
    Updated,
    Confirmed,
    Pending
}

export enum EntityType {
    None = 0,
    Individual,
    PartnerShip,
    Corporation,
    Estate,
    Trust,
    DisregardedEntity,
    ExemptOrganization,
    ForeignGovernment,
    Nominee
}

export interface IK1Partner {
    id: number;
    name: string;
    PIN: string;
    address: string;
    email: string;
    entityType: EntityType;
    k1Status: K1ReportStatus;
    isK1Decline: boolean;
    k1DownloadDate: Date | undefined;
    createdOn: Date | undefined;
    modifiedOn: Date | undefined;
    shareHolderId: number;
    financialYearEnd: Date | undefined;
}

export interface IK1PartnerViewModel {
    count: number;
    k1Partners: IK1Partner[];
}

export const unloadedState: IK1DeliveredStatusState = {
    K1DeliveredStatus: {
        count: 0,
        documents:[]
    },
    K1DeliveredStatusLoading: false,
    K1DeliveredStatusQuery: '',
    K1Partners: {
        count: 0,
        k1Partners:[]
    },
    K1PartnersQuery: '',
    K1PartnersLoading: false
} as IK1DeliveredStatusState