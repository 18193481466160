import React from  'react';
import { Button, Modal, FormGroup, FormLabel, FormControl, Col, Alert } from 'react-bootstrap';
import { RadioButtonComponent } from './../../common/RadioButtonComponent';
import { DropdownComponent } from './../../common/controls/DropdownComponent';
import { GroupInfo, GroupOptions } from '../../../Core/ViewModels/GroupExtensions/ComponentModels';

interface IGroupConfigurationBodyProps {
    options: GroupOptions;
    groupInfo: GroupInfo[];
    onDropdownChange: (value: number) => void;
    selectedValue: number;
    groupName: string;
    onGroupNameChange: (event: any) => void;
    loadGroup: (groupInfo: GroupInfo[]) => GroupInfo[];
    onOptionsClick: (mode: GroupOptions) => void;
    hideUnGroupReturn: boolean;
}

export class GroupConfigurationBody extends React.Component<IGroupConfigurationBodyProps, {}> {
    constructor(props: IGroupConfigurationBodyProps) {
        super(props);
    }


    public render() {
        const addtoExistingBody =
            <div className="row marT20 marB15" hidden={this.props.options != GroupOptions.AddtoExisting}>
                <Col sm={12} className="disp-flex">
                    <Col sm={3} className="marT5 marB05">
                        Group Name
                    </Col>

                    <Col sm={6}>
                        <DropdownComponent
                            id="ddlGroupInfoValues"
                            selectedValue={this.props.selectedValue}
                            onChange={this.props.onDropdownChange}
                            options={this.props.loadGroup}
                        />

                    </Col>
                </Col>
            </div >

        const addNewGroup =
            <div className="row marT20 marB15" hidden={this.props.options != GroupOptions.AddNew}>
                <Col sm={12} className="disp-flex">
                    <Col sm={3} className="marT5 marB05">
                        Group Name
                    </Col>

                    <Col sm={6}>
                        <input
                            className="form-control"
                            placeholder="Type here to add new Group"
                            maxLength={200}
                            value={this.props.groupName}
                            onChange={this.props.onGroupNameChange}
                        ></input>

                    </Col>
                </Col>
            </div >

        const unGroupReturnBody =
            <div className="row marT20 marB15" hidden={this.props.options != GroupOptions.UnGroup}>

            </div >;

        return <div>
            <div className="row">
                <Col sm={12} className="disp-flex">
                    <Col sm={4}>

                        <RadioButtonComponent
                            id={"CreateNew"}
                            text={"Create New Group"}
                            checked={this.props.options == GroupOptions.AddNew}
                            onClick={() => { this.props.onOptionsClick(GroupOptions.AddNew) }}
                            value={GroupOptions.AddNew}
                        />

                    </Col>

                    <Col sm={4}>
                        <RadioButtonComponent
                            id={"Add"}
                            text={"Add to Existing group"}
                            checked={this.props.options == GroupOptions.AddtoExisting}
                            onClick={() => { this.props.onOptionsClick(GroupOptions.AddtoExisting) }}
                            value={GroupOptions.AddtoExisting}
                        />

                    </Col>
                    <Col sm={4} hidden={this.props.hideUnGroupReturn}>

                        <RadioButtonComponent
                            id={"UnGroup"}
                            text={"UnGroup Return(s)"}
                            checked={this.props.options == GroupOptions.UnGroup}
                            onClick={() => { this.props.onOptionsClick(GroupOptions.UnGroup) }}
                            value={GroupOptions.UnGroup}
                        />

                    </Col>
                </Col>
            </div>
            {addNewGroup}
            {addtoExistingBody}
            {unGroupReturnBody}
        </div>;
    }

}
