import React from  'react';

export interface DatalistComponentProps {
    selectedValue?: string;
    options: DatalistOptions[];
    onChange: (selectedValue: any) => void;
    clearable?: boolean;
    disabled?: boolean;
    customPlaceHolder?: string;
    id: string;
    className?: string;
}

export interface DatalistOptions {
    value: number;
    label: string;
}

const DatalistComponent: React.FC<DatalistComponentProps> =
    ({ onChange, selectedValue, id, disabled, customPlaceHolder, options, className }) => {

        const onInternalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(event.target.value);
        };

        return <div>
            <input
                type="text" list={id}
                onChange={onInternalChange}
                defaultValue={selectedValue}
                className={`form-control group-input ${className}`}
                disabled={disabled}
                placeholder={customPlaceHolder && customPlaceHolder.length > 0 ? customPlaceHolder : "Select..."} />
            <datalist id={id}>
                {
                    options.map((value) => {
                        return <option key={value.value} value={value.label} />;
                    })
                }
            </datalist>
        </div>;
    };

export default DatalistComponent;
