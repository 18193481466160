import React from  'react';
import { RouteComponentProps } from 'react-router';
import { Modal, Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IClientMessage, initialClientMessage } from '../../../../Core/ViewModels/CompanySettings/ClientInstructionViewModel';
import { VenusNotifier } from '../../../helper/VenusNotifier';
import * as Constants from '../../../helper/Constants';
import { OverlayLoader } from '../../../helper/OverlayLoader';
import { IClientMessageDictionary } from '../../../../store/settings/ClientMessageStore';
import { VariableList } from '../../ClientInstructions/VariableList';
import { validateName } from '../../../../components/helper/HelperFunctions';

interface IAddMessageClientInstructionProps {
    showState: boolean;
    onHide: () => void;
    submitButtonClick: (message: IClientMessage, isSetDefault: boolean, isToggled: boolean) => void;
    buttonText: string;
    selectedMessageDetail: IClientMessage;
    title: string;
    defaultMessageId: number;
    showLoader: boolean;
    loaderText: string;
    isUpdate: boolean;
    clientMessages: IClientMessageDictionary;
    isGroupSetting?: boolean;
}

interface IClientInstructionState {
    clientMessage: IClientMessage
    alert: string;
    message: string;
    isSetDefault: boolean;
    isToggled: boolean;
    showAlert: boolean;
}

export class ClientMessagePopUp extends React.Component<IAddMessageClientInstructionProps, IClientInstructionState>{
    constructor(props: IAddMessageClientInstructionProps) {
        super(props);
        this.state = {
            clientMessage: initialClientMessage,
            alert: "warning",
            message: "Client Message",
            isSetDefault: false,
            isToggled: false,
            showAlert: true
        }
    }

    componentWillReceiveProps(nextProps: IAddMessageClientInstructionProps) {
        this.setState({
            clientMessage: Object.assign({}, nextProps.selectedMessageDetail),
            alert: "warning",
            message: "Client Message",
            isSetDefault: false,
            isToggled: false,
            showAlert: true
        })
    }


    private onCancelButtonClick = () => {
        this.props.onHide()
        this.setState({
            clientMessage: initialClientMessage,
            alert: "warning",
            message: "Client Message",
            isSetDefault: false,
            isToggled: false,
            showAlert: true
        })
    }

    private onAddButtonClick = () => {
        if (validateName(this.state.clientMessage.name)) {
            this.setState({ alert: "danger", message: Constants.Common.InvalidCharacters, showAlert: false })
            return false;
        }
        if (this.state.clientMessage.name.trim() == "") {
            this.setState({ alert: "danger", message: Constants.ClientInstructionConstants.ValidateName, showAlert: false })
            return false;
        }
        else if (this.state.clientMessage.text.trim() == "") {
            this.setState({ alert: "danger", message: Constants.ClientInstructionConstants.ValidateText, showAlert: false })
            return false;
        }
        if (this.checkDuplicateExistance(this.state.clientMessage) > 0) {
            this.setState({ alert: "danger", message: Constants.SavedMessageConstants.SaveMessageNameError, showAlert: false })
            return false;
        }
        this.props.submitButtonClick(this.state.clientMessage, this.state.isSetDefault, this.state.isToggled)
        this.setState({ clientMessage: initialClientMessage })
    }

    private checkDuplicateExistance = (messageDetail: IClientMessage) => {
        let count: number = 0;
        Object.keys(this.props.clientMessages).map((key, index) => {
            if (this.props.clientMessages[parseInt(key)].clientMessage.name.trim().toLowerCase() == messageDetail.name.trim().toLowerCase() && this.props.clientMessages[parseInt(key)].clientMessage.id != messageDetail.id)
                count++;
        })
        return count;
    }

    private onNameChange = (event: any) => {
        let message = Object.assign({}, this.state.clientMessage);
        message.name = event.target.value;
        this.setState({ clientMessage: message })
    }

    private onTextChange = (event: any) => {
        let message = Object.assign({}, this.state.clientMessage);
        message.text = event.target.value;
        this.setState({ clientMessage: message })
    }

    private onSetDefaultClick = (isTrue: boolean) => {
        if (isTrue == true) {
            this.setState({ alert: "warning", message: Constants.ClientInstructionConstants.SetDefaultMessageWarning, showAlert: false })
        }
        else {
            this.setState({ alert: "warning", message: Constants.ClientInstructionConstants.RemoveDefaultMessageWarning, showAlert: false })
        }
        this.setState({
            isSetDefault: isTrue,
            isToggled: true
        })
    }

    public render() {
        let toggleState: boolean = this.props.defaultMessageId == this.props.selectedMessageDetail.id && this.props.isUpdate == true && this.state.isToggled == false ?
            true : this.state.isSetDefault == true ? true : false
        return (
            <div style={{ position: 'relative' }}>
                <Modal show={this.props.showState} onHide={this.onCancelButtonClick}
                    className="edit-client-instruction-modal">
                    <OverlayLoader show={this.props.showLoader} text={this.props.loaderText} />
                    <Modal.Header closeButton data-test-auto="B78F5348-542E-4817-8ADC-AD86EB823A8E">
                        <Modal.Title>
                            <span
                                className='fas fa-envelope'
                                style={{ color: 'grey', marginRight: '5px' }}>
                            </span>{this.props.title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Alert variant={this.state.alert} hidden={this.state.showAlert}><i className='fas fa-exclamation-triangle' style={{ marginRight: '5px', margin: '0px' }}>
                        </i>{this.state.message}</Alert>
                        <div className="form-group">
                            <label>Name</label>
                            <div className="pull-right">
                                <label style={{ marginTop: 8, marginRight: 10 }}>Default</label>
                                <label className="switch">
                                    <input type="checkbox"
                                        checked={toggleState}
                                        disabled={this.props.defaultMessageId == this.props.selectedMessageDetail.id && this.props.isUpdate == true ? true : false}
                                        onChange={(event) => this.onSetDefaultClick(event.target.checked)}
                                    ></input>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <input type="text"
                                data-test-auto="5452C72B-6022-4A82-B506-BD9CE10AE5A2"
                                className="form-control"
                                placeholder="Instruction name"
                                maxLength={200}
                                value={this.state.clientMessage.name}
                                onChange={this.onNameChange}
                                data-lpignore="true">
                            </input>
                        </div>
                        <div className="form-group">
                            <label>Text</label>
                            <textarea
                                data-test-auto="336F5633-BAF3-45F2-81A0-1A942CF89498"
                                className="form-control"
                                placeholder="Please enter instruction text"
                                rows={5}
                                value={this.state.clientMessage.text}
                                onChange={this.onTextChange}
                            />
                        </div>
                        <VariableList isGroupSetting={this.props.isGroupSetting} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            data-test-auto="D4AAD3E8-3159-414B-9FFE-892D775A5932"
                            variant='default'
                            onClick={this.onCancelButtonClick}>
                            <i className='fas fa-times'></i>Cancel
                        </Button>
                        <Button
                            variant='info'
                            data-test-auto="88DA6AB5-82A6-4E2E-8A93-21EB22AE53CB"
                            onClick={this.onAddButtonClick}>
                            <i className='fas fa-save'></i>{this.props.buttonText}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}