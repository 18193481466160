import React from 'react'
import { RouteComponentProps } from 'react-router'
import { FeatureDisabledIcon } from './svg/CustomGraphics'

export class FeatureDisabled extends React.Component<RouteComponentProps<{}>, {}> {
    public render() {
        return (
            <div className='accessdenied-body' style={{ height: 'calc(100vh - 85px)' }} data-testid="feature-disabled">
                <div>
                    <FeatureDisabledIcon width={200} height={200} />
                </div>
                <div>
                    <div className="accessdenied-text-header">Feature Disabled</div>
                    <div className='accessdenied-body' >
                        <div className="accessdenied-text-body">We are sorry, but this feature is currently not available for your use.</div>
                        <div className="accessdenied-text-body">Please contact your System Administrator for more information.</div>
                    </div>
                </div>
            </div>
        )
    }
}