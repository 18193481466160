import React from  'react';
import * as Validation from '../helper/Validations';
import { phoneNumberDisplay } from '../helper/HelperFunctions';

interface PhoneNumberComponentProps {
    phoneNumber: string;
    handleChangePhoneNumber(value: string): void;
    disabled: boolean;
    onBlur?: (value: any) => void;
    className?: string 
}
interface PhoneNumberComponentState {
    phoneNumberState: string;
}
export class PhoneNumberComponent extends React.Component<PhoneNumberComponentProps, PhoneNumberComponentState> {
    constructor(props:any) {
        super(props);
        this.state = {
            phoneNumberState: ""
        }
    }
    componentDidMount() {
        this.phoneNumberDisplayValue();
    }

    componentDidUpdate(prevProps: PhoneNumberComponentProps, prevState: PhoneNumberComponentState) {
        if (this.props.phoneNumber != prevProps.phoneNumber) {
            this.phoneNumberDisplayValue();
        }
    }

    onBlur = (value: any) => {
        if (this.props.onBlur) {
            this.props.onBlur(value)
        }
    }
    onChangePhoneNumber = (event: any) => {
        let value = event.target.value;
        let length = value.length;
        let phoneNumber: string = "";
        if (value !== "") {
            if (Validation.ValidateTenDigitphoneNumber(value)) {
                if (length <= 5) {
                    if (length > 1) {
                        phoneNumber = value.replace("(", "");
                        phoneNumber = phoneNumber.replace(")", "");
                        if ((this.state.phoneNumberState.includes(")") && !value.includes(")")) || this.state.phoneNumberState.includes("(") && !value.includes("("))
                            phoneNumber = value
                        else if (length > 3)
                            phoneNumber = "(" + phoneNumber + ")";
                        else
                            phoneNumber = value
                    }
                    else {
                        if (length < this.state.phoneNumberState.length && length > 1)
                            phoneNumber = value
                        else {
                            phoneNumber = !value.includes("(") ? "(" : "";
                            phoneNumber = phoneNumber != "" ? (phoneNumber + value + ")") : "";
                        }
                    }
                    this.setState({ phoneNumberState: phoneNumber });
                }
                if (length >= 6 && length <= 12) {
                    if (length == 6 && !value.includes(" "))
                        phoneNumber = this.state.phoneNumberState + " " + value.substring(6, 5)
                    else
                        phoneNumber = value;
                    this.setState({ phoneNumberState: phoneNumber });
                }
                if (length >= 10) {
                    if (length == 10 && !value.includes("-"))
                        phoneNumber = this.state.phoneNumberState + "-" + event.target.value.substring(10, 9)
                    else
                        phoneNumber = value;
                    this.setState({ phoneNumberState: phoneNumber });
                }
                phoneNumber = this.replaceBrackets(value);
                this.props.handleChangePhoneNumber(phoneNumber);
            }

        }
        else {
            phoneNumber = value;
            this.setState({ phoneNumberState: phoneNumber });
            this.props.handleChangePhoneNumber(phoneNumber);
        }

    }

    replaceBrackets(value: string): string {
        let res = value.replace("(", "");
        res = res.replace(")", "");
        res = res.replace(" ", "");
        res = res.replace("-", "");
        return res;
    }

    phoneNumberDisplayValue = () => {
        let phoneNumber = this.props.phoneNumber ?? "";
        let phoneNumberDisplayvalue: any = phoneNumberDisplay(phoneNumber);
        this.setState({ phoneNumberState: phoneNumberDisplayvalue });

    }
    render() {
        return (
            <input type="text"
                data-lpignore="true"
                data-test-auto="F22C7D00-409F-48E3-AD55-5341EF451C9B"
                className={`form-control padL08 ${this.props.className}`}
                placeholder="( ) -"
                value={this.state.phoneNumberState}
                onChange={this.onChangePhoneNumber}
                onBlur={this.onBlur}
                disabled={this.props.disabled}
                style={{ height: "36px", paddingLeft: "2px", paddingRight: "2px" }}
                data-testid="phoneNumberInput"
            >
            </input>
        );
    }
}
export default PhoneNumberComponent;