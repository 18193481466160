import React from  'react';
import { Modal, Alert, Form, Button, Row, Col } from 'react-bootstrap';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { ITaxReturn, isMutual, isIndividual, isPartnership } from '../common/TaxReturn';
import { FiShare } from 'react-icons/fi';
import { ScreenShareConstants } from '../helper/Constants';
import { getIndividualOrPartnerClientGuid } from '../helper/HelperFunctions';

interface ScreenShareProps {
    show: boolean;
    onCancel(): void;
    clientId: string;
    taxReturn: ITaxReturn;
    otp: string;
    startScreenShare(clientGuid: string): void;
}

export class ScreenShare extends React.Component<ScreenShareProps, {}> {
    constructor(props:any) {
        super(props);

    }

    public render() {
        const { taxReturn } = this.props;
        return <Modal show={this.props.show} onHide={this.props.onCancel}>
            <Modal.Header closeButton onClick={this.props.onCancel} data-test-auto="87391A52-20BC-4F34-9C97-07A549DC4BFA">
                <Modal.Title>
                    <FiShare className='modalIcon' />
                    Start Screen Sharing Session
                </Modal.Title>
            </Modal.Header>
            <Form>
                <Modal.Body>
                    <LoadingOverlay style={{ minHeight: 100 }}>
                        <Alert variant="info" >
                            <i className='fa fa-info-circle' style={{ marginRight: '5px' }} />{ScreenShareConstants.screenShareInfo}
                        </Alert>
                        {
                            taxReturn &&
                            <div>
                                <Row>
                                    <Col className="col-xs-2 col-md-2 col-lg-2 padT07 tpLabelFont">ClientId :</Col>
                                    <Col className="col-xs-10 col-md-10 col-lg-10 padT07">{taxReturn.clientId}</Col>
                                </Row>
                                {
                                    taxReturn && isMutual(taxReturn) ?
                                        (<div>
                                            <Row>
                                                <Col className="col-xs-2 col-md-2 col-lg-2 padT07 tpLabelFont">Taxpayer :</Col>
                                                <Col className="col-xs-10 col-md-10 col-lg-10 padT07">{taxReturn.taxpayer && taxReturn.taxpayer.email}</Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-xs-2 col-md-2 col-lg-2 padT07 tpLabelFont">Spouse :</Col>
                                                <Col className="col-xs-10 col-md-10 col-lg-10 padT07">{taxReturn.spouse && taxReturn.spouse.email}</Col>
                                            </Row>
                                        </div>)
                                        : taxReturn && isIndividual(taxReturn) ?
                                            (<Row>
                                                <Col className="col-xs-2 col-md-2 col-lg-2 padT07 tpLabelFont">Taxpayer :</Col>
                                                <Col className="col-xs-10 col-md-10 col-lg-10 padT07">{taxReturn.taxpayer && taxReturn.taxpayer.email}</Col>
                                            </Row>)
                                            : taxReturn && isPartnership(taxReturn) &&
                                            (<Row>
                                                <Col className="col-xs-2 col-md-2 col-lg-2 padT07 tpLabelFont">Partner :</Col>
                                                <Col className="col-xs-10 col-md-10 col-lg-10 padT07">{taxReturn.partnership && taxReturn.partnership.email}</Col>
                                            </Row>)
                                }
                                <Row>
                                    <Col className="col-xs-2 col-md-2 col-lg-2 padT07 tpLabelFont">Code :</Col>
                                    <Col className="col-xs-10 col-md-10 col-lg-10 padT07">{this.props.otp ? this.props.otp : <Loader loading={true} />}</Col>
                                </Row>
                            </div>
                        }
                        <Loader loading={(taxReturn === undefined)} />
                    </LoadingOverlay>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onCancel}
                        className="btn btn-white"
                        variant="default"
                        data-test-auto="2207AF56-B19F-4474-8641-0660A531585E">
                        <i className='fas fa-times'></i>
                        Cancel
                    </Button>
                    <Button onClick={() => { this.props.startScreenShare(this.props.clientId ? this.props.clientId : getIndividualOrPartnerClientGuid(taxReturn)); }}
                        variant="info"
                        data-test-auto="8FF516D1-5465-4DC1-9CE0-D87070F73235">
                        <i className='fas fa-download'></i>
                        Start
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    }
}