import { IDocumentSettings, ITaxReturn } from "../../../components/common/TaxReturn";
import { Guid } from "../../Utilities/Guid";
import { ISubDocument } from "../../Utilities/PdfDocumentFacade";

export interface GroupInfo {
    id: number;
    name: string;
    createdOn: Date;
    createdBy: number;
    isDeleted: boolean;
    controllerInfo: ControllerInfo;
    isModified?: boolean;
}

export const initialGroupInfo: GroupInfo = {
    id: 0,
    name: "",
    createdBy: 0,
    createdOn: new Date(),
    isDeleted: false,
    controllerInfo: {
        clientGuid: Guid.newGuid(),
        countryCode: "",
        emailAddress: "",
        groupId: 0,
        mobileNumber: "",
        name: "",
        ssn: "",
    },
    isModified: false,
};

export interface ControllerInfo {
    groupId: number;
    name: string;
    emailAddress: string;
    ssn: string;
    clientGuid: Guid;
    mobileNumber: string;
    countryCode: string;
}

export interface GroupAccess {
    groupId: number;
    userId: number;
}

export interface GroupExtensionsUploadProps {
    groups: GroupInfo[];
    defaultGroup: string;
    groupAccess: GroupAccess[];
    updateGroupInfoState: (reload: boolean) => void;
}

export interface GroupExtenionsUploadData {
    groupInfo: GroupInfo;
}

export enum GroupOptions {
    AddNew,
    AddtoExisting,
    UnGroup,
}
export interface SendGroupedReturnsModel {
    groupId: number;
    groupName: string;
    taxSoftware: string;
    createdBy: string;
    createdOn: Date;
    status: string;
    setAccess: string;
    taxReturns: ITaxReturn[];
}

export interface PopupState {
    show: boolean;
}

export interface GroupConfigurationStateMultiple extends PopupState {
    selected: ITaxReturn[] | undefined;
    saveCheckIds: number[] | undefined;

}

export interface GroupReturnPopupState extends PopupState {
    groupId: number | undefined;
}

export enum SetAccessType {
    None = 0,
    Everyone = 1,
    Restricted = 2,
}

export interface PopupStateSingle extends PopupState {
    model: SendGroupedReturnsModel | undefined;
    saveCheckId: number | undefined;
}

export interface PopupStateMultiple extends PopupState {
    selected: SendGroupedReturnsModel[] | undefined;
    saveCheckIds: number[] | undefined;
    remove: boolean;
}

export interface PopupStateTaxReturnSingle extends PopupState {
    model: ITaxReturn | undefined;
    saveCheckId: number | undefined;
}

export interface PopupStateGroupController extends PopupState {
    model: GroupInfo | undefined;
    saveCheckId: number | undefined;
}

export interface PopupStateGroupInfo extends PopupState {
    model: GroupInfo | undefined;
}

export interface GroupedReturnsModel {
    groupInfo: GroupInfo;
    taxReturns: ITaxReturn[];
}

export const initialGroupedReturnsModel: GroupedReturnsModel = {
    groupInfo: initialGroupInfo,
    taxReturns: [],
};

export enum ControllerInfoProperty {
    controllerSSN,
    controllerName,
    controllerEmail,
    controllerMobileNumber,
    controllerCountryCode,
}

export interface SelectOptions {
    value: any;
    label: string;
}

export interface GroupedTaxDocumentDictionary {
    [index: number]: {
        taxReturn: ITaxReturn;
        isLoading: boolean;
        error: boolean;
        message: string;
        isFullyLoaded: boolean;
        fileName?: string;
    };
}

export interface HeaderGroupAccess {
    groupId: number;
    userId: number[];
}

export interface ProcessGroupedTaxDocumentViewModel {
    taxDocument: ITaxReturn;
    parts: ISubDocument[];
    isK1Replaced: boolean;
    isK1Restored: boolean;
    isMFJChanged: boolean;
    clientType?: ClientTypes;
    groupInfo?: GroupInfo;
    taxDocumentsForDelivery?: SelectedDocumentForDeliveryViewModel[];
}

export interface SelectedDocumentForDeliveryViewModel {
    taxDocumentId: number;
    documentSettings: IDocumentSettings;
}
