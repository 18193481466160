import React from  'react';
import 'isomorphic-fetch';
import { Link } from 'react-router-dom';
import { ButtonGroup, DropdownButton } from 'react-bootstrap';

export interface SendGroupedExtensionsActionsProps {
    rowIndex: number;
    groupId: number;
    onProcessGroupOpen: (rowIndex: number) => void;
    onEditControllerInfoOpen: (rowIndex: number) => void;
    onDeleteGroupOpen: (rowIndex: number) => void;
    onSetAccessOpen: (rowIndex: number) => void;
    onReportProblemOpen: (groupId: number) => void;
    OnEditGroupInfoOpen: (groupId: number) => void;
}

export class SendGroupedExtensionsActions extends React.Component<SendGroupedExtensionsActionsProps, {}> {

    private createPrimaryActions = () => {

        return (<div>
            <button title={"Process Group"} type="button" className="btn-white"
                onClick={(e) => this.props.onProcessGroupOpen(this.props.rowIndex)}>
                <i className='fa fa-rocket text-violet'></i>
            </button>
            <button title={"Edit Controller Info"} type="button" className="btn-white"
                onClick={() => this.props.onEditControllerInfoOpen(this.props.groupId)}>
                <i className='fas fa-address-card text-success'></i>
            </button>
            <button title={"Delete"} type="button" className="btn-white"
                onClick={(e) => this.props.onDeleteGroupOpen(this.props.rowIndex)}>
                <i className="text-danger fas fa-times" />
            </button>

            {this.createSecondaryActions()}</div>);
    }

    private createSecondaryActions = () => {
        return (<ButtonGroup className="btn-group-vertical" title={"More.."}>
            <DropdownButton
                size="sm"
                title={<span className="fa fa-ellipsis-h" style={{ verticalAlign: "inherit" }}></span>}
                id="process-return-dropdown"
                alignRight data-test-auto="2d0d51d7-5f0f-43b2-96bb-13f1fd93d09d">
                <li key="EditGroup" title="Edit Group Name">
                    <Link to={"#"} onClick={(e: any) => this.props.OnEditGroupInfoOpen(this.props.groupId)}
                        data-test-auto="6BE84819-B396-4C68-9556-124BC688CFF2">
                        <i className='fas fa-edit ddl-icon'></i>Edit Group Name</Link>
                </li>
                <li key="SetAccess" title="Set Access">
                    <Link to={"#"} onClick={(e: any) => this.props.onSetAccessOpen(this.props.rowIndex)}
                        data-test-auto="F3D71FAB-5614-45F9-A62B-DE14330EA301">
                        <i className='fas fa-key ddl-icon'></i>Set Access</Link>
                </li>
                <li key="ReportProblem" title="Report a Problem">
                    <Link to={"#"} onClick={(e: any) => this.props.onReportProblemOpen(this.props.groupId)}
                        data-test-auto="5E72448C-C121-4B50-83C1-878BBC0C41D4">
                        <i className='fas fa-bullhorn ddl-icon'></i>Report a Problem</Link>
                </li>

            </DropdownButton>
        </ButtonGroup>);
    }

    public render() {

        return <div className="button-group">{this.createPrimaryActions()}</div>;
    }
}