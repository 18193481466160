import React, { useEffect } from 'react'
import { signoutRedirectCallback } from './userService'
import LoadingView from '../components/LoadingView'
import history from '../routes/history'

function SignoutOidc() {
    useEffect(() => {
        async function signoutAsync() {
            await signoutRedirectCallback()
            history.push('/login')
        }
        signoutAsync()
    }, [])

    return <LoadingView />
}

export default SignoutOidc
