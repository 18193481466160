import React from  'react';
import { RouteComponentProps } from 'react-router';
import 'isomorphic-fetch';
import { SignatureFormSelection } from './SignaturteComponents/SignatureFormSelection';
import { KnowledgeBasedAuthentication } from './SignaturteComponents/KnowledgeBasedAuthentication';
import { EROSignatures } from './SignaturteComponents/EROSignatures';
import { AdditionalDocumentType } from './SignaturteComponents/AdditionalDocumentType';
import { EsignatureForBusinessReturns } from './SignaturteComponents/ESignatureForBusinessReturns';
import { SigningReminderNotification } from './SignaturteComponents/SigningReminderNotification';
import { ICompanySettings, SignatureFormSelectionType, initialCompanySettings } from '../../Core/ViewModels/Company/CompanySettingsViewModel';
import * as CompanyStore from '../../store/company/CompanyStore';
import * as ExtensionCompanyStore from '../../store/settings/ExtensionCompanySettingsStore';
import * as MailingReturnAddressStore from "../../store/common/MailingReturnAddressStore";
import { OverlayLoader } from '../helper/OverlayLoader';
import * as UserSettingsStore from '../../store/userManagement/UserSettingStore';
import * as AdditionalDocumentTypeStore from '../../store/common/AdditionalDocumentTypeStore';
import { EfileFormSettings } from './SignaturteComponents/EfileTabSettings';
import { ExtensionSettingsStoreState } from '../../Core/ViewModels/Extension/ExtensionStoreModels';
import {
    ExtensionCompanySettings,
    initialExtensionCompanySettings,
    SignatureSettingsModel
} from '../../Core/ViewModels/Extension/ExtensionComponentModels';

import { SignerDelegation } from './SignaturteComponents/SignerDelegation';
import { logClientEvent } from '../helper/LoggerHelper';
import SettingsHeader from '../common/settings/SettingsHeader';
import { LogEventConstants } from '../helper/LogEventConstants';

interface ISigntaureSettingsState {
    disableESignature: boolean;
    showManualAddress: boolean;
    documentValues: IDocumentTypeValue[];
    showState: boolean;
    extensionCompanySettings: ExtensionCompanySettings;
    disableAllowPlaceSignOn1040: boolean;
    disableAllowPlaceSignOnBusiness: boolean;
}

export interface IDocumentTypeValue {
    id: number;
    value: string;
    inUseCount: number;
}

type SignatureSettingsProps =
    {
        mailingReturnAddress: MailingReturnAddressStore.IMailingReturnAddressStoreState;
        additionalDocumentTypeData: AdditionalDocumentTypeStore.IDocumentTypeState;
        extensionCompanyStore: ExtensionSettingsStoreState;
        settings: UserSettingsStore.UserSettings;
    }
    & typeof CompanyStore.actionCreators
    & typeof MailingReturnAddressStore.actionCreators
    & typeof UserSettingsStore.actionCreators
    & typeof AdditionalDocumentTypeStore.actionCreators
    & typeof ExtensionCompanyStore.ActionCreators
    & RouteComponentProps<{ page: string }>;

const pageTitle = "SignatureSetting";
export class Signatures extends React.Component<SignatureSettingsProps, ISigntaureSettingsState> {
    constructor(props: any) {
        super(props);
        this.state = {
            showState: false,
            showManualAddress: false,
            disableESignature: false,
            documentValues: [],
            extensionCompanySettings: initialExtensionCompanySettings,
            disableAllowPlaceSignOn1040: false,
            disableAllowPlaceSignOnBusiness: false
        };

        this.handleApplyButton = this.handleApplyButton.bind(this);
        this.handleResetButton = this.handleResetButton.bind(this);
        this.handleEnableKBA = this.handleEnableKBA.bind(this);
        this.handleSignerDeligation = this.handleSignerDeligation.bind(this);
        this.handleUseSignatureStamp = this.handleUseSignatureStamp.bind(this);
        this.handleSettingSigningReminderDays = this.handleSettingSigningReminderDays.bind(this);
        this.handleSigningReminderReminder = this.handleSigningReminderReminder.bind(this);
        this.openDocumentTypePopup = this.openDocumentTypePopup.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    componentWillMount() {
        this.props.requestTaxingAuthorities();
        this.props.requestCompanySettings(true);
        this.props.requestMailingReturnAddressList();
        this.props.requestCompanyProfile();
        this.props.requestAdditionalDocumentType(true);
        this.props.requestExtensionCompanySettings();

        logClientEvent(
                `${LogEventConstants.Common.Settings.Open} ${pageTitle}`,
                { count: 1, page: pageTitle }
            );
    }

    componentWillReceiveProps(nextprops: SignatureSettingsProps) {

        if (nextprops.extensionCompanyStore &&
            nextprops.extensionCompanyStore.companySettings != undefined) {
            let signatureSettingsModel = nextprops.extensionCompanyStore.companySettings.signatureSettingsModel;
            this.setState({
                disableESignature:
                    (nextprops.extensionCompanyStore.companySettings.signatureSettingsModel ?
                    !this.GetEnableESignatureStatus(nextprops.extensionCompanyStore.companySettings.signatureSettingsModel)
                        : false),
                disableAllowPlaceSignOn1040: signatureSettingsModel.signatureFormSelectionTypeFor1040 == SignatureFormSelectionType.ESignWhenAllIncludedReturnsAllowed,
                disableAllowPlaceSignOnBusiness: signatureSettingsModel.signatureFormSelectionTypeForBusiness == SignatureFormSelectionType.ESignWhenAllIncludedReturnsAllowed ,
            });
        }
    }

    GetEnableESignatureStatus = (signatureSettingsModel: SignatureSettingsModel): boolean => {
        let isEnabled =
            (
                signatureSettingsModel.
                signatureFormSelectionTypeFor1040 === SignatureFormSelectionType.ESignWhenAllIncludedReturnsAllowed
                ||
                (signatureSettingsModel.
                    signatureFormSelectionTypeFor1040 === SignatureFormSelectionType.ReviewOnly &&
                    signatureSettingsModel.allowUserPlaceSignOn1040 == true)
                    );
        return isEnabled;
    }
    //////////////////////////////// Signature settings start /////////////////////////////////////////////////////////
    handleApplyButton() {
        const temp = this.props.extensionCompanyStore.companySettings;
        const userSettings = this.props.settings.settings;
        userSettings.useSignatureStamp = temp.signatureSettingsModel.useSignatureStamp;
        this.props.updateUserSettings(userSettings);
        this.props.updateExtensionCompanySettings(temp);

        logClientEvent(
                `${LogEventConstants.Common.Settings.Update} ${pageTitle}`,
                { count: 1, page: pageTitle }
            );
    }

    handleResetButton() {
        this.props.requestCompanySettings(true);
    }
    //////////////////////////////// Signature settings end/////////////////////////////////////////////////////////

    //////////////////////////////// Signature form selection start /////////////////////////////////////////////////////////    
    OnChangeSignatureFormSelectionTypeFor1040 = (signatureFormSelectionType: SignatureFormSelectionType) => {
        const temp = this.props.extensionCompanyStore.companySettings;
        temp.signatureSettingsModel.signatureFormSelectionTypeFor1040 = signatureFormSelectionType;

        //If ESign When All IncludedReturns Allowed
        if (signatureFormSelectionType == SignatureFormSelectionType.ESignWhenAllIncludedReturnsAllowed) {
            temp.signatureSettingsModel.allowUserPlaceSignOn1040 = false;
            this.setState({
                extensionCompanySettings: temp,
                disableESignature: !this.GetEnableESignatureStatus(temp.signatureSettingsModel),
                //Setting disableAllowPlaceSignOn1040 true so it cannot be chnaged
                disableAllowPlaceSignOn1040: true
                
            });
        }
        //If ReviewOnly
        else if (signatureFormSelectionType == SignatureFormSelectionType.ReviewOnly) {
            this.setState({
                extensionCompanySettings: temp,
                disableESignature: !this.GetEnableESignatureStatus(temp.signatureSettingsModel),
                disableAllowPlaceSignOn1040: false
            });
        }
    }


    OnChangeSignatureFormSelectionTypeForBusiness = (signatureFormSelectionType: SignatureFormSelectionType) => {
        const temp = this.props.extensionCompanyStore.companySettings;
        temp.signatureSettingsModel.signatureFormSelectionTypeForBusiness = signatureFormSelectionType;

        //If ESign When All IncludedReturns Allowed
        if (signatureFormSelectionType == SignatureFormSelectionType.ESignWhenAllIncludedReturnsAllowed) {
            temp.signatureSettingsModel.allowUserPlaceSignOnBusiness = false;
            this.setState({
                extensionCompanySettings: temp,
                //Setting disableAllowPlaceSignOn1040 true so it cannot be changed
                disableAllowPlaceSignOnBusiness: true,
                
            });
        }
        //If ReviewOnly
        else if (signatureFormSelectionType == SignatureFormSelectionType.ReviewOnly) {
            temp.signatureSettingsModel.signatureFormSelectionTypeForBusiness = signatureFormSelectionType;
            this.setState({
                extensionCompanySettings: temp,
                disableAllowPlaceSignOnBusiness: false
            });
        }
    }
    handleAllowPlaceSignOnBusiness = (checked: boolean) => {
        const temp = this.props.extensionCompanyStore.companySettings;
        temp.signatureSettingsModel.allowUserPlaceSignOnBusiness = checked;
        this.setState({ extensionCompanySettings: temp });
    }
    //////////////////////////////// Signature form selection end /////////////////////////////////////////////////////////

    /////////////////////////////////////////Knowledge Based Authentication start///////////////////////////////////////////
    handleEnableKBA(e: any) {
        const temp = this.props.extensionCompanyStore.companySettings;
        temp.signatureSettingsModel.knowledgeBasedAuthentication = e.currentTarget.checked;
        this.setState({
            extensionCompanySettings: temp,
        });
    }

    handleEnableDelegateeKBA = (e: any) => {
        const temp = this.props.extensionCompanyStore.companySettings;
        temp.signatureSettingsModel.enableDelegateeKbaAuthentication = e.currentTarget.checked
        this.setState({
            extensionCompanySettings: temp
        })
    }

    /////////////////////////////////////////Knowledge Based Authentication end///////////////////////////////////////////

    /////////////////////////////////////////ERO Signatures start //////////////////////////////////
    handleUseSignatureStamp(e: any) {
        const temp = this.props.extensionCompanyStore.companySettings;
        temp.signatureSettingsModel.useSignatureStamp = e.currentTarget.checked;
        //if (e.currentTarget.checked == false) {
        //    temp.signatureSettingsModel.enableSignerDeligation = false;
        //}
        this.setState({
            extensionCompanySettings: temp
        })
    }

    handleSignerDeligation(e: any) {
        //const temp = this.props.extensionCompanyStore.companySettings;
        //temp.signatureSettingsModel.enableSignerDeligation = e.currentTarget.checked;
        //if (e.currentTarget.checked == true) {
        //    temp.signatureSettingsModel.useSignatureStamp = true;
        //}
        //this.setState({
        //    extensionCompanySettings: temp,
        //});
    }
    ///////////////////C//////////////////////ERO Signatures end //////////////////////////////////

    /////////////////////////////////////////Esignature For Business Returns start //////////////////////////////////
    //handleEsignatureForBusinessReturn(e: any) {
    //    const temp = this.props.extensionCompanyStore.companySettings;
    //    temp.signatureSettingsModel.enableEsignatureForBusinessReturns = e.currentTarget.checked;
    //    this.setState({
    //        extensionCompanySettings: temp,
    //    });
    //}
    /////////////////////////////////////////Esignature For Business Returns end //////////////////////////////////
    /////////////////////////////////////////Signing Reminder starts //////////////////////////////////
    handleSigningReminderReminder(event: any) {
        const temp = this.props.extensionCompanyStore.companySettings;
        temp.notificationSettingsModel.signingReminderNotificationSettings.enableSigningReminder = event.currentTarget.checked;
        this.setState({
            extensionCompanySettings: temp,
        });
    }

    handleSettingSigningReminderDays(event: any) {
        if (event && event.value > 0) {
            const temp = this.props.extensionCompanyStore.companySettings;
            temp.notificationSettingsModel.signingReminderNotificationSettings.noOfDaysForSigningReminder = parseInt(event.value);
            this.setState({
                extensionCompanySettings: temp,
            });
        }
    }
    /////////////////////////////////////////Signing Reminder end //////////////////////////////////
    openDocumentTypePopup() {
        this.setState({
            showState: true,
        });
    }

    onHide() {
        this.setState({
            showState: false,
        });
    }

    onToggleEfileDateSettings = (event: any) => {
        const temp = this.props.extensionCompanyStore.companySettings;
        temp.signatureSettingsModel.isDoNotDisplayDatesEnabled = event.currentTarget.checked;
        this.setState({
            extensionCompanySettings: temp,
        });
    }
    onToggleEfileNameAndTitleSettings = (event: any) => {
        const temp = this.props.extensionCompanyStore.companySettings;
        temp.signatureSettingsModel.isDoNotDisplayNameAndTitleEnabled = event.currentTarget.checked;
        this.setState({
            extensionCompanySettings: temp,
        });
    }
    
    handleEntityReturnSignerDelegation = (e: any) => {
        const temp = this.props.extensionCompanyStore.companySettings;
        temp.signatureSettingsModel.enableEntityReturnSignerDelegation = e.currentTarget.checked;
        this.setState({ extensionCompanySettings: temp });
    }

    handleIndividualReturnSignerDelegation = (e: any) => {
        const temp = this.props.extensionCompanyStore.companySettings;
        temp.signatureSettingsModel.enableIndividualReturnSignerDelegation = e.currentTarget.checked;
        if (e.currentTarget.checked) {
            temp.signatureSettingsModel.enableDelegateeKbaAuthentication = true;
        } else {
            temp.signatureSettingsModel.enableDelegateeKbaAuthentication = false;
        }
        this.setState({ extensionCompanySettings: temp });
    }

    handleAllowUserPlaceSignOn1040 = (checked: boolean) => {
        const temp = this.props.extensionCompanyStore.companySettings;
        temp.signatureSettingsModel.allowUserPlaceSignOn1040 = checked;
        this.setState({ extensionCompanySettings: temp, disableESignature: !this.GetEnableESignatureStatus(temp.signatureSettingsModel) });
    }

    public render() {
        const extensionCompanySettings = this.props.extensionCompanyStore?.companySettings ??
            initialExtensionCompanySettings;

        return <div id="divSignature" className='company-settings font14'>
            <SettingsHeader
                handleApplyButton={this.handleApplyButton}
                header="E-Signatures"
                showPopOver={false} />
            
            <OverlayLoader
                show={this.props.extensionCompanyStore.loading}
                text={"Loading..."} />
            {this.props.extensionCompanyStore && this.props.extensionCompanyStore.companySettings ?
                <div>
                    <SignatureFormSelection
                        companySettings={extensionCompanySettings}
                        disableAllowPlaceSignOn1040={this.state.disableAllowPlaceSignOn1040}
                        handleAllowUserPlaceSignOn1040={this.handleAllowUserPlaceSignOn1040}
                        OnChangeSignatureFormSelectionTypeFor1040={this.OnChangeSignatureFormSelectionTypeFor1040}
                    />
                    <hr />
                    <KnowledgeBasedAuthentication
                        companySettings={extensionCompanySettings}
                        handleEnableKBA={this.handleEnableKBA}
                        disableESignature={this.state.disableESignature}
                        handleEnableDelegateeKBA={this.handleEnableDelegateeKBA}
                    />
                    <hr />
                    <EROSignatures
                        companySettings={extensionCompanySettings}
                        handleUseSignatureStamp={this.handleUseSignatureStamp}
                        handleSignerDeligation={this.handleSignerDeligation}
                    />
                    <AdditionalDocumentType
                        overlayLoading={this.props.additionalDocumentTypeData.isLoading}
                        overlayMessage={this.props.additionalDocumentTypeData.message}
                        documentType={this.props.additionalDocumentTypeData.documentType}
                        HandleCustomizeDeliveredreportsEditButton={this.openDocumentTypePopup}
                        updateDocumentTypeValue={this.props.updateAdditionalDocumentTypeValue}
                        addDocumentTypeValue={this.props.addAdditionalDocumentTypeValue}
                        deleteDocumentTypeValue={this.props.deleteAdditionalDocumentTypeValue}
                    />

                    <hr />

                    <SignerDelegation
                        companySettings={this.props.extensionCompanyStore.companySettings}
                        handleEntityReturnSignerDelegation={this.handleEntityReturnSignerDelegation}
                        handleIndividualReturnSignerDelegation={this.handleIndividualReturnSignerDelegation}
                    />
                    <hr />

                    <EsignatureForBusinessReturns
                        companySettings={extensionCompanySettings}
                        disableAllowPlaceSignOnBusiness={this.state.disableAllowPlaceSignOnBusiness}
                        handleAllowPlaceSignOnBusiness={this.handleAllowPlaceSignOnBusiness}
                        OnChangeSignatureFormSelectionTypeForBusiness={this.OnChangeSignatureFormSelectionTypeForBusiness}
                    />
                    <hr />
                    <SigningReminderNotification
                        companySettings={extensionCompanySettings}
                        handleSettingSigningReminderDays={this.handleSettingSigningReminderDays}
                        handleSigningReminderReminder={this.handleSigningReminderReminder}
                    />
                    <hr />
                    <EfileFormSettings
                        companySettings={extensionCompanySettings}
                        onToggleEfileDateSettings={this.onToggleEfileDateSettings}
                        onToggleEfileNameAndTitleSettings={this.onToggleEfileNameAndTitleSettings} />
                    <hr />
                </div> : ""
            }
        </div>;
    }
}
