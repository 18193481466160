import * as React from "react";
import { CustomDatePicker } from '../../common/controls/CustomDatePicker';
type CustomDateFilterState = {
    selectedDate: Date | undefined;
}
export class CustomDateFilter extends React.Component<any, CustomDateFilterState> {
    statusFilter: any;
    

    constructor(props: any) {

        super(props);       

        this.state = {
            selectedDate: undefined
        }
    }

    componentDidMount() {
        this.props.onRef && this.props.onRef(this)
    }

    setDateFilterRef = (stausFilterRef: any) => {
        this.statusFilter = stausFilterRef;
    };

    public applyFilter = (value: any) => {
        this.setState({
            selectedDate: value
        }, () => { this.props.filterHandler(this.state.selectedDate); });
    }

    //clear the calendar date value
    public cleanFiltered = () => {
       
        this.setState({
            selectedDate: undefined
        }, () => { this.props.filterHandler(); });
    }

    private onStatusChange = (date?: Date) => {  
      
        this.state.selectedDate != date && this.setState({ selectedDate: date }, () => {
            this.props.filterHandler(date, 'CustomFilter')
        });
    }
    
    public render() {

        return (<CustomDatePicker
            value={this.state.selectedDate}
            ref={(instance: any) => { this.setDateFilterRef(instance); }}
            onChange={this.onStatusChange}
            calendarContainer={this.props.calendarContainer}
            calendarPlacement={this.props.calendarPlacement}
            clearFilter={this.cleanFiltered}
            
        />);
    }
}
