import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { ITaxReturn } from '../../components/common/TaxReturn';
import {
	RequestAttachmentsAction, ReceiveAttachmentsAction,
	RequestDownloadAttachmentsAction, ReceiveDownloadAttachmentsAction
} from '../reports/KnownTypes';
import { AttachmentsState, AttachmentsTableModel } from './AttachmentsState';
import { handleResponse, extractDocumentIds, handleBlob,handleBlobwithFileName } from '../Library';
import { DisplayDownloadFile } from '../../components/common/DisplayDownloadFile';
import * as Constants from '../../components/helper/Constants';
import { initialUserProfile } from '../../components/navigation/profile/ProfileObjects';
import { API_BASE_URL } from 'src/utils/contants';
import { getProcessResourceId } from 'src/components/helper/RbacConstants';


type KnownAction = RequestAttachmentsAction
	| ReceiveAttachmentsAction | RequestDownloadAttachmentsAction | ReceiveDownloadAttachmentsAction;

export const actionCreators = {
	requestAttachments: (taxReturn: ITaxReturn, fileName: string, file: any, processData: (data: AttachmentsTableModel, fileName: string, file: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		// Only load data if it's something we don't already have (and are not already loading)
		const resourceId = getProcessResourceId();
		const fetchTask = fetch(`${API_BASE_URL}api/ProcessReturn/GetUploadSasUrlAsync/?documentGuid=` + taxReturn.documentGuid + '&fileName=' + escape(fileName) + '&taxYear=' + taxReturn.taxYear + '&uploadType=' + "attachment", 
		{ 
			credentials: 'include',
			headers : { 
				'X-Resource-Id': resourceId
			 }
		}
		).then((resp) => resp.json())
			.then(data => {
				processData(data, fileName, file)

			});
		addTask(fetchTask); // Ensure server-side prerendering waits for this to complete
	},
	previewAttachments: (taxReturn: ITaxReturn, fileName: string, documentId: number, attachmentId: number, isArchivedReturn: boolean, callback: (url: string, fileName: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const fetchTask = fetch(`${API_BASE_URL}api/Download/PreviewAttachmentAsync`, {
			method: 'POST',
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				attachmentName: fileName,
				taxYear: taxReturn.taxYear,
				documentGuid: taxReturn.documentGuid,
				documentId: documentId,
				attachmentId: attachmentId,
				isArchivedReturn: isArchivedReturn
			})
		}).then((resp) => resp.json())
			.then((data) => {
				callback(data, fileName);
			})
		addTask(fetchTask);
	},
	downloadAllAttachments: (taxReturn: ITaxReturn, isArchivedReturn: boolean, clientName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const formData = new FormData();
		let taxReturnData = JSON.stringify(taxReturn);
		formData.append('taxDocument', taxReturnData);
		formData.append('isArchivedReturn', '' + JSON.stringify(isArchivedReturn));
		formData.append('clientName', clientName);
		let options: any = {
			method: 'POST',
			credentials: 'include',
			headers: {
				'Accept': 'application/json'
			},
			body: formData,

		};
		fetch(`${API_BASE_URL}api/Download/GetDownloadAllAttachmentsAsync`, options).then(handleBlobwithFileName)
			.then((data) => {
				let displayDownloadFile = new DisplayDownloadFile();
				displayDownloadFile.showFile(data.blob, data.filename);
			})
	},
};

const unloadedState: AttachmentsState = {
	AttachmentsTableModel: {
		AttachmentsModel: [],
	} as AttachmentsTableModel,
	isAssignedToLoggedinUser: true,
	taxReturn: '',
	documentId: 0,
	userProfile: initialUserProfile,
	saveAttachments: () => void (0),
	attachments: []
} as AttachmentsState;

export const reducer: Reducer<AttachmentsState> = (state: AttachmentsState = unloadedState, incomingAction: Action) => {
	return state || unloadedState;
}

