export interface ITaxSoftwareSettings {
    taxSoftware: TaxSoftwareType[];
}

export enum TaxSoftwareType {
    None = "None",
    ProSystems = "ProSystems",
    UltraTax = "UltraTax",
    GoSystem = "GoSystem",
    UltraTaxM18 = "UltraTaxM18",
    Lacerte = "Lacerte",
    Drake = "Drake",
}

export const initialSuiteTaxSoftwareSettings: ITaxSoftwareSettings = {
    taxSoftware: [TaxSoftwareType.ProSystems]
}