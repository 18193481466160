import React from  'react';
import { Col } from 'react-bootstrap';
import { DropTarget } from 'react-drag-drop-container';
import * as PdfStore from '../../../store/pdf/PdfStore';
import * as TaxDocument from '../../common/TaxReturn';
import { GroupCard } from './GroupCard';
import { GroupPages } from './GroupPages';
import { GroupToolBar } from './GroupToolBar/GroupToolBar';
import { PagePreview } from './PagePreview';
import Scrollbars from 'react-custom-scrollbars';
import { GroupTabConstants } from '../../helper/Constants'
import * as bootbox from 'bootbox';
import { enumToNumbers, isK1ShareHolder } from '../../helper/HelperFunctions';
import { IBookmarkSection } from '../ProcessReturnModal/ProcessReturnModels';
import { cloneDeep } from 'lodash';

export interface IGroupsViewProps {
    docId: number;
    formGroups: TaxDocument.IGroup[];
    pdfDocuments: PdfStore.IPdfDocumentDictionary;
    engagementType: TaxDocument.EngagementType;
    deletePages: (pages: number[]) => void;
    onPreviewPage: (group: TaxDocument.DocumentGroups, activePage: number) => void;
    reGroupPreview: (pages: number[], destinationGroup: TaxDocument.DocumentGroups) => void;
    renderTrigger: string;
    efileBookmarks: IBookmarkSection[];
    duplicatePages: number[];
};
export interface groupDictionary {
    [index: number]: {
        label: string,
        icon: string,
        color: string
    }
}
interface IGroupViewState {
    thumbnails: string[];
    preview?: number; //PageNumber or undefined
    previewTitle: string;
    activeGroup: TaxDocument.DocumentGroups;
    selectedPages: ISelectedPagesDictionary;
    orderedGroupType: TaxDocument.TabOrder;
};

export interface ISelectedPagesDictionary {
    [pageNo: number]: {
        source: TaxDocument.DocumentGroups,
    };
}

export class GroupsView extends React.Component<IGroupsViewProps, IGroupViewState> {
    constructor(props: IGroupsViewProps) {
        super(props);

        this.state = {
            thumbnails: [],
            preview: undefined,
            activeGroup: TaxDocument.DocumentGroups.None,
            previewTitle: 'Title',
            orderedGroupType: TaxDocument.TabOrder.None,
            selectedPages: []
        };

        this.groupData = {};
        this.groupData[TaxDocument.DocumentGroups.None] = {
            label: 'View All',
            icon: 'fas fa-folder',
            color: 'grey'
        };
        this.groupData[TaxDocument.DocumentGroups.Transmittals] = {
            label: 'Transmittals',
            icon: 'fas fa-list-alt',
            color: '#FFC400'
        };
        this.groupData[TaxDocument.DocumentGroups.EFile] = {
            label: 'Filing Forms',
            icon: 'fas fa-pen-nib',
            color: '#F1AF5E'
        };

        this.groupData[TaxDocument.DocumentGroups.Deleted] = {
            label: 'Deleted',
            icon: 'fas fa-trash',
            color: '#595959'
        };

        this.onCardClick = this.onCardClick.bind(this);
        this.onDeletePage = this.onDeletePage.bind(this);
        this.handlePageDrop = this.handlePageDrop.bind(this);
    }


    private groupData: groupDictionary;

    public render() {
        let cards: any[] = [];
        const documentGroupVerticalTabKeys = enumToNumbers(TaxDocument.DocumentGroupVerticalTab);

        const formGroupsKeys = this.props.formGroups.map(x => x.group);

        const emptyTabs = documentGroupVerticalTabKeys.filter((item) => formGroupsKeys.indexOf(item) < 0);

        const groups = cloneDeep(this.props.formGroups);

        groups.sort((a, b) => (a.group > b.group) ? 1 : -1);

        groups.map((group, i) => {
            {
                if (group.group === TaxDocument.DocumentGroups.Deleted) {
                    cards = this.getDeletedGroup(group, cards);
                }
                else {
                    cards = this.getDefaultGroup(group, cards);
                }
            }
        });

        if (cards[TaxDocument.DocumentGroups.Deleted]) {
            emptyTabs.splice(emptyTabs.indexOf(TaxDocument.DocumentGroups.Deleted), 1);
        }

        const index = formGroupsKeys.findIndex((x) =>
            x === TaxDocument.DocumentGroups.Vouchers ||
            x === TaxDocument.DocumentGroups.TaxReturns);

        for(let i = 0; i < emptyTabs.length; i++) {
            const enumNumber = emptyTabs[i];
            if (!(index > -1 && enumNumber === TaxDocument.DocumentGroups.EFile)) {
                cards[enumNumber] = {
                    type: enumNumber,
                    label: this.groupData[enumNumber].label,
                    icon: this.groupData[enumNumber].icon,
                    color: this.groupData[enumNumber].color,
                    onClick: this.onCardClick,
                    model: null,
                };
            }
        }
        // Re-ordering based on Process Return Tab order
        //let taxreturnIndex = TaxDocument.DocumentGroupVerticalTab.TaxReturns;
        //let efileIndex = TaxDocument.DocumentGroupVerticalTab.EFile;         
        //    cards.splice(efileIndex, 0, cards.splice(taxreturnIndex, 1)[0]);        

        cards[0] == undefined && cards.splice(0, 1);

        if (cards.length === 0) return false;

        let activeGroup = cards[cards.findIndex((card) => card === undefined ?
            false : card.type === this.state.activeGroup)];

        return <div style={{ marginTop: 10, display: 'flex' }}>
            <Col sm={3} className="grouping-targets" style={{ height: "calc(100vh - 190px)" }}>

                <div className={"scroll"}>
                    <Scrollbars >
                        <GroupCard
                            color={'grey'}
                            icon={'fas fa-folder'}
                            label={'View All'}
                            onClick={this.onCardClick}
                            type={TaxDocument.DocumentGroups.None}
                            active={this.state.activeGroup === TaxDocument.DocumentGroups.None}
                            accepts={['page']}
                        />
                        {cards.map((card, i) => {
                            return <DropTarget
                                highlightClassName="highlighted"
                                dropData={card.type}
                                targetKey={"groups"}
                                key={"highlightedGroup" + i}>
                                <GroupCard
                                    key={'groupCard' + i}
                                    color={card.color}
                                    icon={card.icon}
                                    label={card.label}
                                    onClick={card.onClick}
                                    type={card.type}
                                    active={this.state.activeGroup === card.type}
                                    accepts={['page']}
                                >
                                </GroupCard>
                            </DropTarget>
                        })}
                    </Scrollbars>
                </div>

            </Col>

            <Col sm={9}>

                {Object.keys(this.state.selectedPages).length > 0 && <div className="group-view-hedear color-change-animation">
                    <GroupToolBar onClearSelection={this.handleClearSelection} onDelete={this.onDelete}
                        selectedPages={this.state.selectedPages}
                        engagementType={this.props.engagementType}
                        onMove={this.onMove} />

                </div>}

                <Scrollbars style={{ height: '70vh' }} autoHide>

                    {this.state.activeGroup === TaxDocument.DocumentGroups.None || activeGroup === undefined ?
                        cards.map((card, i) => {
                            return <GroupPages
                                documentGroup={this.state.activeGroup}
                                key={'group-pages' + i}
                                color={card.color}
                                icon={card.icon}
                                label={card.label}
                                group={card.model}
                                groupIcons={this.groupData}
                                selectedPages={this.state.selectedPages}
                                docId={this.props.docId}
                                pdfDocuments={this.props.pdfDocuments}
                                onPreviewPage={this.props.onPreviewPage}
                                onDeletePage={this.onDeletePage}
                                onDropPage={this.handlePageDrop}
                                onSelectPage={this.onSelectPage}
                                onUnSelectPage={this.onUnSelectPage}
                                efileBookmarks={this.props.efileBookmarks}
                                duplicatePages={this.props.duplicatePages}
                            />
                        }) :
                        <GroupPages
                            documentGroup={this.state.activeGroup}
                            color={activeGroup.color}
                            icon={activeGroup.icon}
                            label={activeGroup.label}
                            group={activeGroup.model}
                            docId={this.props.docId}
                            pdfDocuments={this.props.pdfDocuments}
                            groupIcons={this.groupData}
                            selectedPages={this.state.selectedPages}
                            onPreviewPage={this.props.onPreviewPage}
                            onDeletePage={this.onDeletePage}
                            onDropPage={this.handlePageDrop}
                            onSelectPage={this.onSelectPage}
                            onUnSelectPage={this.onUnSelectPage}
                            efileBookmarks={this.props.efileBookmarks}
                            duplicatePages={this.props.duplicatePages}
                        />
                    }

                </Scrollbars>
            </Col>
        </div>
    }


    private getDeletedGroup = (group: TaxDocument.IGroup, cards: any[]) => {
        if (cards[TaxDocument.DocumentGroups.Deleted]) {
            cards[TaxDocument.DocumentGroups.Deleted].model.forms = cards[TaxDocument.DocumentGroups.Deleted].model.forms.concat(group.forms);
        }
        else {
            cards[TaxDocument.DocumentGroups.Deleted] = {
                type: TaxDocument.DocumentGroups.Deleted,
                label: this.groupData[TaxDocument.DocumentGroups.Deleted].label,
                icon: this.groupData[TaxDocument.DocumentGroups.Deleted].icon,
                color: this.groupData[TaxDocument.DocumentGroups.Deleted].color,
                onClick: this.onCardClick,
            }
            let deletedGroup: TaxDocument.IGroup = {
                group: TaxDocument.DocumentGroups.Deleted,
                forms: group.forms
            }

            cards[TaxDocument.DocumentGroups.Deleted].model = deletedGroup;
        }
        return cards;
    }

    private getDefaultGroup = (group: TaxDocument.IGroup, cards: any[]) => {
        let documentGroups = group.group === TaxDocument.DocumentGroups.Vouchers || group.group === TaxDocument.DocumentGroups.TaxReturns ? TaxDocument.DocumentGroups.EFile : group.group;
        group.group = documentGroups;
        if (!cards[documentGroups] ||
            (cards[documentGroups] && cards[documentGroups].type !== TaxDocument.DocumentGroups.EFile)) {
            cards[documentGroups] = {
                type: documentGroups,
                label: this.groupData[documentGroups].label,
                icon: this.groupData[documentGroups].icon,
                color: this.groupData[documentGroups].color,
                onClick: this.onCardClick
            }
        }

        if (cards[documentGroups].type === TaxDocument.DocumentGroups.EFile) {
            cards[documentGroups].model ? cards[documentGroups].model.forms.push(...group.forms) : cards[documentGroups].model = group;
        }
        else {
            cards[documentGroups] ? cards[documentGroups].model = group : undefined;
        }
        return cards;
    }

    private onCardClick(type: TaxDocument.DocumentGroups) {
        //TODO: dispatch event to the right side children
        const engagementType = +TaxDocument.EngagementType[this.props.engagementType];
        if (type === TaxDocument.DocumentGroups.Transmittals) {
            this.setState({ orderedGroupType: TaxDocument.TabOrder.Transmittals });
        }
        else if (type === TaxDocument.DocumentGroups.TaxReturns) {
            this.setState({ orderedGroupType: TaxDocument.TabOrder.TaxReturns });
        }
        else if (type === TaxDocument.DocumentGroups.EFile) {
            this.setState({ orderedGroupType: TaxDocument.TabOrder.EFile });
        }
        else if (type === TaxDocument.DocumentGroups.Vouchers) {
            this.setState({ orderedGroupType: TaxDocument.TabOrder.Vouchers });
        }
        else if (type === TaxDocument.DocumentGroups.Deleted) {
            this.setState({ orderedGroupType: TaxDocument.TabOrder.Invoice });
        }
        this.setState({ activeGroup: type, selectedPages: [] });
    }

    private onDeletePage(page: number) {
        let _self = this;
        bootbox.confirm({
            message: GroupTabConstants.Page.DeleteConfirm,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> OK',
                    className: 'btn-success'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    _self.props.reGroupPreview([page], TaxDocument.DocumentGroups.Deleted)
                    Object.keys(_self.state.selectedPages).find(x => parseInt(x) === page) && _self.onUnSelectPage(page);
                }
            }
        });
    }


    /* Group Tab Select/Unselect/Drop Events */

    private getSelectedPages = (): number[] => {
        return Object.keys(this.state.selectedPages).map(x => parseInt(x));
    }

    private onSelectPage = (pageNo: number, documentGroup: TaxDocument.DocumentGroups) => {
        this.state.selectedPages[pageNo] = {
            source: documentGroup
        }
        this.setState({ selectedPages: this.state.selectedPages });
    }

    private onUnSelectPage = (pageNo: number) => {
        delete this.state.selectedPages[pageNo];
        this.setState({ selectedPages: this.state.selectedPages });
    }

    private handlePageDrop(item: any) {

        if (this.state.selectedPages[item.dragData.pageNo]) {
            this.props.reGroupPreview(this.getSelectedPages(), item.dropData);
        }
        else {
            this.props.reGroupPreview([item.dragData.pageNo], item.dropData);
        }

        this.handleClearSelection();
    }

    /* End */


    /* Group Tab Move/Clear Selection/Delete */

    private onMove = (destinationGroup: TaxDocument.DocumentGroups) => {
        this.props.reGroupPreview(this.getSelectedPages(), destinationGroup);
        this.handleClearSelection();
    }

    private onDelete = () => {
        let pageThis = this;
        bootbox.confirm({
            message: GroupTabConstants.Page.DeleteConfirm,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> OK',
                    className: 'btn-success'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    pageThis.props.reGroupPreview(pageThis.getSelectedPages(), TaxDocument.DocumentGroups.Deleted);
                    pageThis.handleClearSelection();
                }
            }
        });
    }


    private handleClearSelection = () => {
        this.setState({ selectedPages: [] });
    }

    /* End */

};
//=============================================================================