import React from 'react'
import { useSelector } from 'react-redux'
import { Route, useHistory } from 'react-router-dom'
import { FeatureDisabled } from 'src/components/FeatureDisabled'
import LoadingView from 'src/components/LoadingView'
import Unauthorized, { UnauthorizedType } from '../components/Unauthorized'
import { isOnlySignatureUser, isScreenPermisionDisabled } from '../oidcClient/userRoles'
import { ApplicationState } from '../store'

type Props = {
    component?: React.ComponentType<any>
    path: string | string[]
    exact?: boolean | undefined
    sensitive?: boolean | undefined
    strict?: boolean | undefined
}

const UserBlockedRoute: React.FC<Props> = ({ component, path, ...rest }) => {
    const user = useSelector((state: ApplicationState) => state.auth.user);
    const rbacStore = useSelector((state: ApplicationState) => state.rbacStore);
    let isCompanyExtensionProductEnabled = useSelector((state: ApplicationState) => state.postAuthDataStore.extensionEnabled);
    const history = useHistory();
    let isLoading = useSelector((state: ApplicationState) => state.postAuthDataStore.loading);

    //Company Level setting disable for all user.
    if (isLoading || !rbacStore.isExtensionDataLoaded) {
        return <LoadingView />
    }
    else if (!isCompanyExtensionProductEnabled) {

        return <Route component={FeatureDisabled} />
    } else if (isOnlySignatureUser(user)) {

        return <Route component={(props) =>
            <Unauthorized type={UnauthorizedType.AccessDenied} {...props} />} {...rest} />

    } else if (!rbacStore.isExtensionEnabled) { //User level permision disable for single user.

        return <Route component={(props) => <Unauthorized type={UnauthorizedType.AccessDenied} {...props} />} {...rest} />

    } else if (isScreenPermisionDisabled(rbacStore.leftMenus, history.push)) { //screen access denied permision and navigate to active route

        return <Route component={(props) => <Unauthorized type={UnauthorizedType.ScreenAccessDenied} {...props} />} {...rest} />

    } else {
        return <Route component={component} path={path} {...rest} />
    }
}

UserBlockedRoute.displayName = 'UserBlockedRoute'

export default UserBlockedRoute
