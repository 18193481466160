import React from  'react';
import { Modal, Button } from 'react-bootstrap';
import { CheckBoxComponent } from '../../../components/common/CheckBoxComponent';

interface ISignerTooltipProps {
    control: any;
    initialControl: any;
    showState: boolean;
    onHide: () => void;
    submitButtonClick: (control: any) => void;
}

interface ISignerTooltipState {
    control: any;
}

export class SignerTooltipPopup extends React.Component<ISignerTooltipProps, ISignerTooltipState>{
    constructor(props: ISignerTooltipProps) {
        super(props);
        this.state = {
            control: props.initialControl
        }
    }

    componentWillReceiveProps(nextProps: ISignerTooltipProps) {
        this.setState({
            control: nextProps.control
        });
    }

    private onCancelButtonClick = () => {
        this.props.onHide();
        this.setState({
            control: this.props.initialControl
        });
    }

    private onSaveButtonClick = () => {
        this.props.submitButtonClick(this.state.control);
        this.setState({
            control: this.props.initialControl
        });
    }

    private onToolTipChange = (event: any) => {
        let tempControl = Object.assign({}, this.state.control);
        tempControl.tooltip = event.target.value;
        this.setState({ control: tempControl });
    }

    private onRequiredChange = (event: any) => {
        let tempControl = Object.assign({}, this.state.control);
        tempControl.required = event.target.checked;
        this.setState({ control: tempControl });
    }

    public render() {
        return (
            <div style={{ position: 'relative' }}>
                <Modal className="report-change-status-modal edit-adddoc-modal-dialog" show={this.props.showState} onHide={this.onCancelButtonClick}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <span
                                className='fas fa-edit'
                                style={{ color: 'grey', marginRight: '5px' }}>
                            </span>Tool Tip
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label>Add Text</label>
                            <i className="fa fa-question-circle" style={{ color: '#DAA520', marginLeft: '10px' }} title={"This text will help the signer to enter the necessary details"}></i>
                        </div>
                        <div className="form-group">
                            <input type="text"
                                data-test-auto="F64CD7BB-408E-4194-ABC4-C2A55B4EF4DA"
                                className="form-control"
                                placeholder="Tooltip text"
                                maxLength={200}
                                value={this.state.control.tooltip ? this.state.control.tooltip : ""}
                                onChange={this.onToolTipChange}>
                            </input>
                        </div>
                        <div className="form-group">
                            <CheckBoxComponent
                                id={"isToolTipRequired"}
                                text={"Required"}
                                checked={this.state.control.required}
                                onChange={this.onRequiredChange}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            data-test-auto="7A73152B-7425-4B5A-852F-7126A10F200D"
                            variant='default'
                            onClick={this.onCancelButtonClick} >
                            <i className='glyphicon glyphicon-remove'></i>Cancel
                        </Button>
                        <Button
                            variant='info'
                            onClick={this.onSaveButtonClick}
                            data-test-auto="58D8BFE5-4906-4314-BBF3-C9E173A2A4B2">
                            <i className='glyphicon glyphicon-floppy-disk'></i>Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}