import React from 'react';
import { RouteComponentProps } from 'react-router';
import * as CompanyAssignmentsStore from '../../store/assignments/CompanyAssignmentsStore';
import * as TaxDocumentStore from '../../store/common/TaxDocumentStore';
import * as UserStore from '../../store/userManagement/UserStore';
import * as PdfStore from '../../store/pdf/PdfStore';
import * as CompanyStore from '../../store/company/CompanyStore';
import * as UserProfileStore from '../../store/userManagement/UserProfileStore';
import * as UserSettingStore from 'src/store/userManagement/UserSettingStore';
import * as MailingReturnAddressStore from "../../store/common/MailingReturnAddressStore";
import * as SavedMessageStore from '../../store/settings/SavedMessageStore';
import * as UserSignatureStore from '../../store/common/UserSignatureStore';
import { IAssignmentsState } from '../../store/assignments/AssignmentsState';
import { Assignments } from './Assignments';
import { IDocumentAccess, IDocumentAccessSaveModel, IOfficeLocation, ITaxReturn } from '../common/TaxReturn';
import { IUserProfile } from '../navigation/profile/ProfileObjects';
import * as AttachmentsStore from '../../store/ProcessReturn/AttachmentsStore'
import * as AttachmentsState from '../../store/ProcessReturn/AttachmentsState'
import * as UploadStore from '../../store/common/Upload';
import * as DeliveredReturnsStore from '../../store/reports/DeliveredReturnsStore';
import * as DeliveredReturnsState from '../../store/reports/DeliveredReturnsState';
import * as DownloadStore from '../../store/common/Download';
import * as VoucherStore from '../../store/ProcessReturn/voucher/VoucherStore';
import * as UploadTaxReturnStore from '../../store/common/uploadTaxReturn';
import * as BusinessReturnsStore from '../../store/settings/BusinessReturnsStore';
import { ICustomAuthorityState } from '../../store/common/CustomAuthorityStore';
import * as CustomAuthorityStore from '../../store/common/CustomAuthorityStore';
import * as FilterStore from '../../store/reports/FilterStore';
import * as AdditionalEsignDocumentStore from '../../store/ProcessReturn/AdditionalEsignDocuments/AdditionalEsignDocumentStore';
import * as AdditionalDocumentTypeStore from '../../store/common/AdditionalDocumentTypeStore';
import * as CustomColumnStore from '../../store/common/CustomColumnStore';
import { ExtensionSettingsStoreState } from '../../Core/ViewModels/Extension/ExtensionStoreModels';
import * as ExtensionCompanyStore from '../../store/settings/ExtensionCompanySettingsStore';
import { initialExtensionCompanySettings } from '../../Core/ViewModels/Extension/ExtensionComponentModels';
import * as GroupedExtensionsStore from '../../store/groupedExtensions/GroupedExtensionsStore';
import { GroupedExtensionStoreState } from '../../Core/ViewModels/GroupExtensions/StoreModels';
import { IEventTelemetry } from '@microsoft/applicationinsights-web';
import { logClientEvent } from '../helper/LoggerHelper';
import { BulkOperationQueryExt } from '../../Core/ViewModels/Common/BulkOperationQuery';
import { ReportFilterType } from '../reports/Filters';
import * as BulkOperationsStore from '../../store/common/BulkOperationsStore';
import { DownloadTypeOption } from '../reports/DownloadTypeOption';
import * as ExtensionUserSettingsStore from '../../store/userManagement/ExtensionUserSettingStore';
import { RBACKeys } from '../helper/RbacConstants';
import { IColumnValues } from '../settings/GeneralSettings';
import { IReportProblemDetails } from '../common/ReportProblem/ReportProblemModel';
import { DefaultDownloadInfoType } from '../../store/userManagement/ExtensionUserSettingStore';
import * as TaxSoftwareStore from '../../store/SuiteCompany/TaxSoftwareStore'
import { SuiteTaxSoftwareSettingsStoreState } from 'src/Core/ViewModels/Company/SuiteCompanySettings/SuiteTaxSoftwareStoreModels';
import { IAuthState } from 'src/store/auth/reducer';
import * as OfficeLocationStore from '../../store/common/OfficeLocationStore';
import * as UserOfficeLocationStore from '../../store/common/UserOfficeLoacationStore';


type ExtensionInProcessProps =
    {
        assignments: IAssignmentsState;
        profile: IUserProfile;
        taxDocuments: TaxDocumentStore.ITaxDocumentDictionary;
        usersStore: UserStore.UsersStore;
        pdfDocuments: PdfStore.IPdfDocumentDictionary;
        company: CompanyStore.ICompanyData;
        attachments: AttachmentsState.AttachmentsState;
        uploadStore: UploadStore.IUploadState;
        userSettings: UserSettingStore.UserSettings;
        mailingReturnAddresses: MailingReturnAddressStore.IMailingReturnAddressStoreState;
        savedMessages: SavedMessageStore.SavedMessageStoreState;
        deliveredReturns: DeliveredReturnsState.DeliveredReturnsState;
        downloadStore: DownloadStore.IDownloadState;
        userSignatures: UserSignatureStore.IUserSignatures;
        voucherStore: VoucherStore.IVoucherState
        uploadTaxReturnStore: UploadTaxReturnStore.IUploadTaxReturnState;
        businessReturnInstructions: BusinessReturnsStore.IBusinessReturnsState;
        customAuthorities: ICustomAuthorityState;
        reportFilters: FilterStore.IFilterState;
        additionalEsignDocument: AdditionalEsignDocumentStore.IBlobFile;
        additionalDocumentTypeData: AdditionalDocumentTypeStore.IDocumentTypeState;
        customColumnState: CustomColumnStore.ICustomColumnState;
        extensionCompanyData: ExtensionSettingsStoreState;
        groupedExtensionsStore: GroupedExtensionStoreState;
        extensionUserSettings: ExtensionUserSettingsStore.IExtensionUserSettings; 
        taxSoftwareData: SuiteTaxSoftwareSettingsStoreState;
        auth: IAuthState;
        officeLocation: OfficeLocationStore.IOfficeLocationState;
        userOfficeLocation: UserOfficeLocationStore.IUserOfficeLocationState
    }
    & typeof CompanyAssignmentsStore.actionCreators
    & typeof UserProfileStore.actionCreators
    & typeof TaxDocumentStore.actionCreators
    & typeof FilterStore.actionCreators
    & typeof UserStore.actionCreators
    & typeof PdfStore.actionCreators
    & typeof CompanyStore.actionCreators
    & typeof UserSettingStore.actionCreators
    & typeof MailingReturnAddressStore.actionCreators
    & typeof AttachmentsStore.actionCreators
    & typeof UploadStore.actionCreators
    & typeof SavedMessageStore.actionCreators
    & typeof DeliveredReturnsStore.actionCreators
    & typeof DownloadStore.actionCreators
    & typeof UserSignatureStore.actionCreators
    & typeof VoucherStore.actionCreators
    & typeof UploadTaxReturnStore.actionCreators
    & typeof BusinessReturnsStore.actionCreators
    & typeof CustomAuthorityStore.actionCreators
    & typeof AdditionalEsignDocumentStore.actionCreators
    & typeof AdditionalDocumentTypeStore.actionCreators
    & typeof CustomColumnStore.actionCreators
    & typeof ExtensionCompanyStore.ActionCreators
    & typeof GroupedExtensionsStore.actionCreators
    & typeof ExtensionUserSettingsStore.actionCreators
    & typeof BulkOperationsStore.actionCreators
    & typeof TaxSoftwareStore.actionCreators
    & typeof OfficeLocationStore.actionCreators
    & typeof UserOfficeLocationStore.actionCreators
    & RouteComponentProps<{}>;

interface ExtensionInProcessState {
    query: string;
    defaultERO: number;
    isK1Restored: boolean;
    defaultGroup: string;
    selectedDownloadType: DefaultDownloadInfoType;
    bulkOpnQuery: BulkOperationQueryExt;
    showDownloadTypeOption: boolean;
    loadSpinner: boolean,
    selectedDefaultDownloadType: boolean,
}

export class ExtensionInProcess extends React.Component<{} & ExtensionInProcessProps, ExtensionInProcessState> {
    constructor(props: ExtensionInProcessProps) {
        super(props);
        this.state = {
            query: '',
            defaultERO: 0,
            isK1Restored: false,
            defaultGroup: '',
            selectedDownloadType: DefaultDownloadInfoType.PromptMeEveryTime,
            bulkOpnQuery: {
                sortBy: "", sortOrder: "", searchText: "", assignedTo: "", clientId: "", customColumn: "", engagementType: 0, downloadedBy: "",
                isArchived: false, name: "", partner: "", reportType: ReportFilterType.SendExtension, sentBy: "", officeLocation: "",
                status: "", taxYear: "", unSelectedIds: [], documentFilingType: 0, quickReportStatus: '',
                groupName: '',
                taxSoftware: '',
                createdBy: '',
                globalSearch: '',
                setAccess: "None",
                returnStatus: '',
                isDownloadNow:false
            },
            showDownloadTypeOption: false,
            loadSpinner: false,
            selectedDefaultDownloadType: false,
        };
        this.assignedUser = this.assignedUser.bind(this);
        this.requestAssignments = this.requestAssignments.bind(this);
    }
    componentWillMount() {
        this.props.requestUserSettings(true);
        this.props.requestCompanySignatureUploadLink();
        this.props.requestCustomColumn(true);
        this.props.requestExtensionCompanySettings();
        this.props.requestGroupConfiguration(true);
        this.props.requestGroupAccess(true);
        this.props.requestExtensionUserSettings(true);
        this.props.requestAllPartners(true);
        this.props.requestMailingReturnAddressList();
    }

    componentWillReceiveProps(nextProps: ExtensionInProcessProps) {
        nextProps.userSettings.settings && nextProps.extensionUserSettings?.defaultSettings &&
            this.setState({
                defaultERO: nextProps.extensionUserSettings.defaultSettings.eroUser,
                defaultGroup: ''
            });

        nextProps.userSettings.settings && nextProps.extensionUserSettings?.defaultSettings?.download &&
            this.setState({
                selectedDownloadType: nextProps.extensionUserSettings?.defaultSettings?.download?.downloadType
            });

        nextProps.downloadStore &&
            this.setState({
                isK1Restored: nextProps.downloadStore.isK1Restored
            });
    }

    public render() {
        return <div className="company-assignment-content">
          {this.props.userOfficeLocation.userLocation.length > 0 &&  
          <Assignments
                auth={this.props.auth}
                title={"Extensions In-Process"}
                id={'company'}
                key={'company'}
                automationId={'8F7A9B08-EC83-4C7F-9C17-D91C499DA9E8'}
                currentPage={this.props.assignments}
                taxDocuments={this.props.taxDocuments}
                users={this.props.usersStore.allUsers}
                partners={this.props.usersStore.partners}
                requestAssignments={this.requestAssignments}
                assignedUser={this.assignedUser}
                requestTaxDocument={this.props.requestTaxDocument}
                updateTaxDocument={this.props.updateTaxDocument}
                deleteTaxDocument={this.props.deleteTaxDocument}
                requestUsersList={this.props.requestAllUsers}
                saveTaxDocument={(taxReturn: ITaxReturn, customColumn?: IColumnValues, callback?: (data?: any) => void, emailCallback?: (id: number, isMailSent: boolean) => void, isMailSent?: boolean) => {
                    this.props.saveTaxDocument(taxReturn, RBACKeys.extensionInProgress.editButton, customColumn, callback, emailCallback, isMailSent);
                }}
                assignTaxDocument={this.props.AssignTaxDocument}
                changeStatusTaxDocument={this.props.ChangeStatusTaxDocument}
                getTaxDocumentsAccessMaps={(ids: number[], resourceId:string, callback?: (result: IDocumentAccess) => void ) => {
                    this.props.getTaxDocumentsAccessMaps(ids, true,resourceId, callback)
                }}
                setTaxDocumentsAccessMaps={this.props.setTaxDocumentsAccessMaps}
                requestTaxDocumentClientTracking={(id: number) => {
                    this.props.requestTaxDocumentClientTracking(id, RBACKeys.extensionInProgress.reprocessButton);
                }}
                reportTaxDocumentProblem={(problemDetails: IReportProblemDetails, callback?: () => void) => {
                    this.props.reportTaxDocumentProblem(problemDetails, RBACKeys.extensionInProgress.reportAProblem, callback);
                }}
                onExportToExcel={this.onExportToExcel}
                profile={this.props.profile}
                pdfDocuments={this.props.pdfDocuments}
                company={this.props.company}
                requestCompanySettings={this.props.requestCompanySettings}
                requestPdfDocument={this.props.requestTaxReturnPdf}
                requestUserProfile={this.props.requestUserProfile}
                requestTaxingAuthorities={this.props.requestTaxingAuthorities}
                defaultERO={this.state.defaultERO ? this.state.defaultERO : 0}
                sendToERO={this.props.sendToERO}
                sendForReview={this.props.sendForReview}
                approveForDelivery={this.props.approveForDelivery}
                deliverToClient={this.props.deliverToClient}
                requestAttachments={this.props.requestAttachments}
                getK1InstructionFileName={this.props.getK1InstructionFileName}
                deleteK1Instruction={this.props.deleteK1Instruction}
                uploadK1Instruction={this.props.uploadK1Instruction}
                mailingReturnAddressList={
                    this.props.mailingReturnAddresses === undefined ? [] : this.props.mailingReturnAddresses.data
                }
                userSettings={this.props.userSettings}
                requestDelegatedSigners={this.props.requestDelegatedSigners}
                generateTaxPayerView={(taxDocument: ITaxReturn) => {
                    this.props.generateTaxpayerView(taxDocument, RBACKeys.extensionInProgress.deliveryButton)
                }}
                savedMessages={this.props.savedMessages.data}
                getAllSavedMessages={this.props.requestSavedMessages}
                downloadUserSignature={this.props.requestDownloadPath}
                userSignatures={this.props.userSignatures}
                saveProcessReturnInfo={this.props.saveProcessReturnInfo}
                getVoucherUploadLink={this.props.requestVoucherUploadLink}
                getMultiVoucherUploadLink={this.props.requestMultiVoucherUploadLink}
                getTaxCaddyLookupDetails={this.props.getTaxCaddyLookupDetails}
                voucherStore={this.props.voucherStore}
                requestVoucherDueDates={this.props.requestVoucherDueDates}
                requestPaymentUrls={this.props.requestPaymentUrls}
                requestVoucherFormNames={this.props.requestVoucherFormNames}
                isK1Restored={this.state.isK1Restored}
                restoreK1Instruction={this.props.restoreK1Instruction}
                getUploadLink={(url: string, callback?: () => void) => {
                    this.props.getUploadLink(url, RBACKeys.extensionInProgress.uploadButton, callback);
                }}
                processTaxReturn={this.props.processTaxReturn}
                submitTaxReturn={(url: string, taxData: string, callback: () => void) => {
                    this.props.submitTaxReturn(url, taxData, RBACKeys.extensionInProgress.uploadButton, callback);
                }}
                getAllTaxingAuthorities={this.props.company.taxingAuthorities}
                submitRecallTaxReturn={this.props.submitRecallTaxReturn}
                businessInstructionStore={this.props.businessReturnInstructions}
                updateDocumentInitialStatus={this.props.updateDocumentInitialStatus}
                resetTaxDocument={this.props.resetTaxDocument}
                previewAttachments={this.props.previewAttachments}
                requestDocumentStatus={this.props.requestDocumentStatus}
                reprocessTaxDocument={this.props.reprocessTaxDocument}
                checkDuplicateTaxReturn={this.props.checkDuplicateTaxReturn}
                saveTaxingAuthority={this.props.SaveTaxingAuthority}
                requestCustomAuthority={this.props.requestCustomAuthority}
                requestTaxingAuthority={this.props.requestTaxingAuthorities}
                updateDocumentGroupOrder={this.props.updateDocumentGroupOrder}
                reportFilters={this.props.reportFilters}
                getAllReportFilter={this.props.getAllReportFilter}
                addReportFilter={this.props.addReportFilter}
                updateReportFilter={this.props.updateReportFilter}
                deleteReportFilter={this.props.deleteReportFilter}
                addDefaultFilter={this.props.addDefaultFilter}
                removeDefaultFilter={this.props.removeDefaultFilter}
                getAddtionalEsignDocumentUploadLink={this.props.getAddtionalEsignDocumentUploadLink}
                deleteUploadedAdditionalDocumentBlobFile={this.props.deleteUploadedAdditionalDocumentBlobFile}
                deleteAdditionalDocument={this.props.deleteAdditionalDocument}
                getAddtionalEsignDocumentDownLoadLink={this.props.getAddtionalEsignDocumentDownLoadLink}
                convertDocToPdfArtifactAsync={this.props.convertDocToPdfArtifactAsync}
                requestAdditionalDocumentType={this.props.requestAdditionalDocumentType}
                additionalDocumentTypeData={this.props.additionalDocumentTypeData}
                requestCompanyLogo={this.props.requestCompanyLogo}
                customColumnData={this.props.customColumnState.customColumn}
                extensionCompanySettings={this.props.extensionCompanyData.companySettings}
                addGroup={this.props.addGroup}
                mapExtensionstoGroup={this.props.mapExtensionstoGroup}
                groupInfo={this.props.groupedExtensionsStore.groupInfo}
                updateGroupInfoState={this.props.requestGroupConfiguration}
                defaultGroup={this.state.defaultGroup ? this.state.defaultGroup : ''}
                groupAccess={this.props.groupedExtensionsStore.groupAccess}
                loadSpinner={this.state.loadSpinner}
                extensionUsers={this.props.usersStore.extensionUsers}
                taxSoftwareData={this.props.taxSoftwareData}
                requestSuiteTaxSoftwareSettings={this.props.requestSuiteTaxSoftwareSettings}
                userLocation={this.props.userOfficeLocation.userLocation}
                officeLocation={this.props.officeLocation.locationDropdown}
            />
            }
            <DownloadTypeOption
                show={this.state.showDownloadTypeOption}
                onCancel={this.onDownloadTypeCancel}
                selectedDownloadType={this.state.selectedDownloadType}
                onDownloadOptionChange={this.onDownloadOptionChange}
                onDownloadOptionSave={this.onDownloadOptionSave}
                useDefaultDownloadType={this.useDefaultDownloadType}
                selectedDefaultDownloadType={this.state.selectedDefaultDownloadType}
            />

        </div>;
    }
    onDownloadTypeCancel = () => {
        let downloadType = this.props.extensionUserSettings?.defaultSettings?.download?.downloadType || DefaultDownloadInfoType.PromptMeEveryTime;
        this.setState({
            selectedDownloadType: downloadType,
            showDownloadTypeOption: false,
            loadSpinner: false
        });
    }
    onDownloadOptionChange = (value: any) => {
        if (this.state.selectedDownloadType == DefaultDownloadInfoType.DownloadNow) {
            this.setState({ selectedDownloadType: DefaultDownloadInfoType.DownloadToMyDownloads });
        }
        else {
            this.setState({ selectedDownloadType: DefaultDownloadInfoType.DownloadNow });
        }
    }

    onDownloadOptionSave = (value: any) => {
        var settings = this.props.extensionUserSettings;
        if (this.state.selectedDefaultDownloadType == true) {

            settings.defaultSettings.download.rememberDefaultDownload = true;
            settings.defaultSettings.download.downloadType = this.state.selectedDownloadType;
            this.props.saveExtensionUserSettings(settings);
        }
        this.setState({ showDownloadTypeOption: false });

        let downloadType = this.props.extensionUserSettings?.defaultSettings?.download?.downloadType || DefaultDownloadInfoType.PromptMeEveryTime;
        this.onExportToExcel(() => {
            this.setState({
                loadSpinner: false,
                selectedDownloadType: downloadType
            });
        });
    }
    useDefaultDownloadType = (event: any) => {
        if (this.state.selectedDefaultDownloadType == false) {
            this.setState({ selectedDefaultDownloadType: true });
        }
        else {
            this.setState({ selectedDefaultDownloadType: false });
        }
    }

    public requestAssignments(query: string, reload: boolean = false, callback?: () => void, reloadOnNoDataFound?: () => void) {
        logClientEvent(
            "RequestAssignments",
            { count: 1 });

        this.setState(
            { query: query },
            () => this.props.requestCompanyAssignments(query, true, callback, reloadOnNoDataFound));
    }

    public assignedUser(selectedAssignments: ITaxReturn[]) {
        if (selectedAssignments.length == 1) {
            return selectedAssignments[0].assignTo;
        }
        return 0;
    }

    onExportToExcel = (callback?: any, bulkOpnQuery?: BulkOperationQueryExt) => {
        if (bulkOpnQuery != undefined) {
            this.setState({ bulkOpnQuery }, () => this.postExportExcel(callback));
        } else {
            this.postExportExcel(callback);
        }
    }

    postExportExcel = (callback?: any) => {
        if (this.state.selectedDownloadType == DefaultDownloadInfoType.DownloadNow) {
            this.props.exportAssignmentsAsExcel(this.state.query, callback);
        }
        else if (this.state.selectedDownloadType == DefaultDownloadInfoType.DownloadToMyDownloads) {
            this.props.exportToExcelBulk(this.state.bulkOpnQuery, callback, RBACKeys.extensionInProgress.exportButton);
        }
        else {
            if (callback) {
                callback();
            }
            this.setState({
                selectedDownloadType: DefaultDownloadInfoType.DownloadToMyDownloads,
                showDownloadTypeOption: true,
                loadSpinner: true,
                selectedDefaultDownloadType: false
            });
        }
    }
}
export default ExtensionInProcess;