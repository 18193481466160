import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Component from './ExtensionInProcess';
import * as UserProfileStore from '../../store/userManagement/UserProfileStore';
import * as CompanyAssignmentsStore from '../../store/assignments/CompanyAssignmentsStore';
import * as TaxDocumentStore from '../../store/common/TaxDocumentStore';
import * as UserStore from '../../store/userManagement/UserStore';
import * as PdfStore from '../../store/pdf/PdfStore';
import * as CompanyStore from '../../store/company/CompanyStore';
import * as UserSettingStore from '../../store/userManagement/UserSettingStore';
import * as MailingReturnAddressStore from "../../store/common/MailingReturnAddressStore";
import * as Upload from '../../store/common/Upload';
import * as SavedMessageStore from '../../store/settings/SavedMessageStore';
import * as UploadStore from '../../store/common/Upload';
import * as DeliveredReturnsStore from '../../store/reports/DeliveredReturnsStore';
import * as AttachmentsStore from '../../store/ProcessReturn/AttachmentsStore';
import * as DownloadStore from '../../store/common/Download';
import * as UserSignatureStore from '../../store/common/UserSignatureStore';
import * as VoucherStore from '../../store/ProcessReturn/voucher/VoucherStore';
import * as UploadTaxReturnStore from '../../store/common/uploadTaxReturn';
import * as BusinessReturnsStore from '../../store/settings/BusinessReturnsStore';
import * as CustomAuthorityStore from '../../store/common/CustomAuthorityStore';
import * as FilterStore from '../../store/reports/FilterStore';
import * as AdditionalEsignDocumentStore from '../../store/ProcessReturn/AdditionalEsignDocuments/AdditionalEsignDocumentStore';
import * as AdditionalDocumentTypeStore from '../../store/common/AdditionalDocumentTypeStore';
import * as CustomColumnStore from '../../store/common/CustomColumnStore';
import * as ExtensionCompanyStore from '../../store/settings/ExtensionCompanySettingsStore';
import * as GroupedExtensionsStore from '../../store/groupedExtensions/GroupedExtensionsStore';
import * as BulkOperationsStore from '../../store/common/BulkOperationsStore';
import * as ExtensionUserSettingsStore from '../../store/userManagement/ExtensionUserSettingStore';
import * as TaxSoftwareStore from '../../store/SuiteCompany/TaxSoftwareStore'
import * as OfficeLocationStore from '../../store/common/OfficeLocationStore';
import * as UserOfficeLocationStore from '../../store/common/UserOfficeLoacationStore'


export default connect(
    (state: ApplicationState) => ({
        assignments: state.companyAssignments,
        profile: state.userProfile,
        taxDocuments: state.taxDocuments,
        usersStore: state.usersStore,
        company: state.companyData,
        pdfDocuments: state.pdfDocuments,
        attachments: state.attachments,
        userSettings: state.userSettings,
        uploadStore: state.UploadStore,
        savedMessages: state.savedMessage,
        mailingReturnAddresses: state.mailingReturnAddresses,
        deliveredReturns: state.deliveredReturns,
        downloadStore: state.DownloadStore,
        userSignatures: state.userSignatures,
        voucherStore: state.voucherStore,
        uploadTaxReturnStore: state.UploadTaxReturnStore,
        businessReturnInstructions: state.businessReturnInstructions,
        customAuthorities: state.customAuthority,
        reportFilters: state.reportFilters,
        additionalEsignDocument: state.additionalEsignDocument,
        additionalDocumentTypeData: state.additionalDocumentType,
        customColumnState: state.customColumn,
        extensionCompanyData: state.extensionCompanyData,
        groupedExtensionsStore: state.groupedExtensionsStore,
        extensionUserSettings: state.extensionUserSettings,
        taxSoftwareData: state.taxSoftwareData,
        officeLocation: state.officeLocation,
        auth:state.auth,
        userOfficeLocation:state.userOfficeLocation,
    }),
    {
        ...CompanyAssignmentsStore.actionCreators,
        ...UserProfileStore.actionCreators,
        ...TaxDocumentStore.actionCreators,
        ...FilterStore.actionCreators,
        ...UserStore.actionCreators,
        ...CompanyStore.actionCreators,
        ...PdfStore.actionCreators,
        ...AttachmentsStore.actionCreators,
        ...UserSettingStore.actionCreators,
        ...MailingReturnAddressStore.actionCreators,
        ...Upload.actionCreators,
        ...UploadStore.actionCreators,
        ...SavedMessageStore.actionCreators,
        ...DeliveredReturnsStore.actionCreators,
        ...DownloadStore.actionCreators,
        ...UserSignatureStore.actionCreators,
        ...VoucherStore.actionCreators,
        ...UploadTaxReturnStore.actionCreators,
        ...BusinessReturnsStore.actionCreators,
        ...CustomAuthorityStore.actionCreators,
        ...AdditionalEsignDocumentStore.actionCreators,
        ...AdditionalDocumentTypeStore.actionCreators,
        ...CustomColumnStore.actionCreators,
        ...ExtensionCompanyStore.ActionCreators,
        ...GroupedExtensionsStore.actionCreators,
        ...ExtensionUserSettingsStore.actionCreators,
        ...BulkOperationsStore.actionCreators,
        ...TaxSoftwareStore.actionCreators,
        ...OfficeLocationStore.actionCreators,
        ...UserOfficeLocationStore.actionCreators
    },
)(Component.ExtensionInProcess as any);
