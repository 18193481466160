import * as React from 'react'
import { Modal, Button, Col } from 'react-bootstrap';
import { signoutRedirect } from '../oidcClient/userService';
import { LogoutCause } from '../store/auth/reducer';
import { UserAutoLogoutConstants, UserAutoLogoutMessageConstants } from './helper/Constants';

interface UserAutoLogoutModalProps {
    openModal: boolean;
    logoutCause: LogoutCause;
}

export const UserAutoLogoutModal: React.FC<UserAutoLogoutModalProps> = props => {
    const getModalHeaderContent = (logoutCause: LogoutCause) => {
        // The below switch case will be implemented later if needed.
        // switch (logoutCause) {
        //     case LogoutCause.UserPermissionChange: return UserAutoLogoutConstants.UserRoleChanged;
        //     case LogoutCause.UserDelete: return UserAutoLogoutConstants.AccessRemoved;
        //     case LogoutCause.ProductTierChange: return UserAutoLogoutConstants.ProductTierChange;
        //     case LogoutCause.CompanyDisable: return UserAutoLogoutConstants.CompanyDisable;
        // }
        return UserAutoLogoutConstants.PermissionChanged;
    }

    const getModalBodyContent = (logoutCause: LogoutCause) => {
        // The below switch case will be implemented later if needed.
        // switch (logoutCause) {
        //     case LogoutCause.UserPermissionChange: return UserAutoLogoutMessageConstants.UserRoleChanged;
        //     case LogoutCause.UserDelete: return UserAutoLogoutMessageConstants.AccessRemoved;
        //     case LogoutCause.ProductTierChange: return UserAutoLogoutMessageConstants.ProductTierChange;
        //     case LogoutCause.CompanyDisable: return UserAutoLogoutMessageConstants.CompanyDisable;
        // }
        return UserAutoLogoutMessageConstants.PermissionChanged;
    }

    const getModalFooterContent = (logoutCause: LogoutCause) => {
        // The below switch case will be implemented later if needed.
        // switch (logoutCause) {
        //     case LogoutCause.UserPermissionChange: return 'Login';
        //     case LogoutCause.UserDelete: return 'Close';
        //     case LogoutCause.ProductTierChange:
        //     case LogoutCause.CompanyDisable: return 'Login';
        // }
        return "Login";
    }
    const modalHeaderContent = getModalHeaderContent(props.logoutCause);
    const modalBodyContent = getModalBodyContent(props.logoutCause);
    const modalFooterContent = getModalFooterContent(props.logoutCause);

    return (

        <div className="margin-tbl-20">
            <Modal show={props.openModal} onHide={signoutRedirect} className="rolechange-notification-modal in" size="sm" enforceFocus={false}>
                <Modal.Header>
                    <Modal.Title className='pull-left'>
                        <h3>{modalHeaderContent}</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <Col sm={12}>
                            <div className="user-autologout-modal-content container">
                                {modalBodyContent}
                            </div>
                        </Col>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={signoutRedirect}>
                        {modalFooterContent}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};


