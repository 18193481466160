import React from 'react';
import * as Bs from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
    SignatureStatus,
    ITaxReturn,
    ClientTypes,
    isMutual,
    isIndividual,
    isPartnership,
    DocumentStatus,
} from '../../common/TaxReturn';
import { IDeliveredTaxDocument } from '../../../components/common/DeliveredTaxReturns';
import { RBACKeys } from 'src/components/helper/RbacConstants';

interface DeliveredReportDropdownPros {
    pageId: string;
    title: string;
    rowIndex: number;
    signatureStatus: string;
    onEditClientInfoOpen: (rowIndex: number) => void;
    onDownloadEfileFormsOpen: (rowIndex: number) => void;
    onDownloadReturnOpen: (rowIndex: number) => void;
    onClientTrackingOpen: (rowIndex: number) => void;
    onTaxpayerView: (rowIndex: number) => void;
    onRecallReturnOpen: (rowIndex: number) => void;
    onVoucherReminderOpen: (rowIndex: number) => void;
    onReportProblemOpen: (rowIndex: number) => void;
    onDeleteTaxReturnOpen: (rowIndex: number) => void;
    onResendAccessLinkOpen: (rowIndex: number) => void;
    onViewAccessCodeOpen: (rowIndex: number) => void;
    onRetentionPolicyOpen: (rowIndex: number) => void;
    onRemoveReport: (event: any) => void;
    onUnlockReturnOpen: (rowIndex: number) => void;
    onUploadFormsOpen: (rowIndex: number) => void;
    onClientViewClick: (rowIndex: number) => void;
    onClientViewLinkClick: (rowIndex: number, clientType: ClientTypes) => void;
    taxReturn: ITaxReturn;
    deliveredReturnData: IDeliveredTaxDocument[];
    onRestoreReturnOpen: (rowIndex: number) => void;
    onScreenShareViewClick: (rowIndex: number) => void;
    onScreenShareOpen: (clientId: string, rowIndex: number) => void;
    showScreenShareSubMenu: boolean;
    isArchived: boolean;
    id: number;
    clientInfo: ITaxReturn | undefined;
}

export class DeliveredReportAction extends React.Component<DeliveredReportDropdownPros, {}> {
    constructor(props: any) {
        super(props);
    }

    private createPrimaryActions = () => {
        return (
            <div>
                <button
                    title={'Edit Client Info'}
                    type="button"
                    className="btn-white btn-xs"
                    data-resource-id={RBACKeys[this.props.pageId].editClientInfo}
                    onClick={() => this.props.onEditClientInfoOpen(this.props.rowIndex)}
                >
                    <i className="fas fa-address-card text-success"></i>
                </button>
                {this.createSecondaryActions()}
            </div>
        );
    };

    private createSecondaryActions = () => {
        let signatureStatus: any = SignatureStatus[SignatureStatus.ManuallySigned];
        let signatureStatusUploaded: any = SignatureStatus[SignatureStatus.Uploaded];
        let signatureStatusESigned: any = SignatureStatus[SignatureStatus.ESigned];
        let signatureStatusPrinted: any = SignatureStatus[SignatureStatus.AlternateDelivery];
        let taxReturnModel: any = this.props.taxReturn;
        let clientInfo: any = this.props.clientInfo;

        let deceasedCount = 0;

        if (taxReturnModel && taxReturnModel.taxpayer && isMutual(taxReturnModel)) {
            if (taxReturnModel.taxpayer.isDeceased) {
                deceasedCount += 1;
            }
            if (taxReturnModel.spouse && taxReturnModel.spouse.isDeceased) {
                deceasedCount += 1;
            }
        }

        let ClientInfoModelsli: any = <div className="marL10">Loading...Initial</div>;
        {
            if (clientInfo && this.props.id == clientInfo.id) {
                clientInfo.taxpayer && clientInfo.spouse
                    ? (ClientInfoModelsli = (
                          <>
                              {clientInfo.taxpayer.email && (deceasedCount == 2 || !clientInfo.taxpayer.isDeceased) ? (
                                  <li>
                                      <Link
                                          to={'#'}
                                          onClick={() =>
                                              this.props.onClientViewLinkClick(
                                                  this.props.rowIndex,
                                                  ClientTypes.Taxpayer
                                              )
                                          }
                                          data-test-auto="82B47EBD-B661-44D5-87EC-6A953572BB0C"
                                      >
                                          <span className="marL05 marR05">
                                              {clientInfo.taxpayer.name + ' (' + clientInfo.taxpayer.email + ') '}
                                          </span>
                                      </Link>
                                  </li>
                              ) : null}
                              {clientInfo.spouse.email && (deceasedCount == 2 || !clientInfo.spouse.isDeceased) ? (
                                  <li>
                                      <Link
                                          to={'#'}
                                          onClick={() =>
                                              this.props.onClientViewLinkClick(this.props.rowIndex, ClientTypes.Spouse)
                                          }
                                          data-test-auto="42ACE989-6786-41EB-B494-D0EBD738E0DB"
                                      >
                                          <span className="marL05 marR05">
                                              {clientInfo.spouse.name + ' (' + clientInfo.spouse.email + ') '}
                                          </span>
                                      </Link>
                                  </li>
                              ) : null}
                          </>
                      ))
                    : isIndividual(clientInfo)
                    ? (ClientInfoModelsli = (
                          <>
                              {
                                  <li>
                                      <Link
                                          to={'#'}
                                          onClick={() =>
                                              this.props.onClientViewLinkClick(
                                                  this.props.rowIndex,
                                                  ClientTypes.Taxpayer
                                              )
                                          }
                                          data-test-auto="BF4D23E8-206A-4A2C-8D99-A6E872025BEC"
                                      >
                                          <span className="marL05 marR05">
                                              {clientInfo.taxpayer.name + ' (' + taxReturnModel.taxpayer.email + ') '}
                                          </span>
                                      </Link>
                                  </li>
                              }
                          </>
                      ))
                    : clientInfo.partnership && clientInfo.partnership.email && isPartnership(clientInfo)
                    ? (ClientInfoModelsli = (
                          <>
                              <li>
                                  <Link
                                      to={'#'}
                                      onClick={() =>
                                          this.props.onClientViewLinkClick(this.props.rowIndex, ClientTypes.PartnerShip)
                                      }
                                      data-test-auto="CBAADAF2-9C74-48B0-8D7E-CBC55BA61766"
                                  >
                                      <span className="marL05 marR05">
                                          {clientInfo.partnership.name + ' (' + clientInfo.partnership.email + ') '}
                                      </span>
                                  </Link>
                              </li>
                          </>
                      ))
                    : '';
            } else {
                ClientInfoModelsli = <div className="marL10">Loading...</div>;
            }
        }

        let ClientInfoModelScreenShare = <div className="marL10">Loading...</div>;
        {
            taxReturnModel && taxReturnModel.taxpayer && isMutual(taxReturnModel)
                ? (ClientInfoModelScreenShare = (
                      <div>
                          {taxReturnModel.taxpayer && !taxReturnModel.taxpayer.isDeceased ? (
                              <li>
                                  <Link
                                      to={'#'}
                                      onClick={() =>
                                          this.props.onScreenShareOpen(
                                              taxReturnModel.taxpayer.clientGuid,
                                              this.props.rowIndex
                                          )
                                      }
                                      data-test-auto="82B47EBD-B661-44D5-87EC-6A953572BB0C"
                                  >
                                      <span className="marL05 marR05">
                                          {taxReturnModel.taxpayer.name + ' (' + taxReturnModel.taxpayer.email + ') '}
                                      </span>
                                  </Link>
                              </li>
                          ) : null}
                          {taxReturnModel.spouse && !taxReturnModel.spouse.isDeceased ? (
                              <li>
                                  <Link
                                      to={'#'}
                                      onClick={() =>
                                          this.props.onScreenShareOpen(
                                              taxReturnModel.spouse.clientGuid,
                                              this.props.rowIndex
                                          )
                                      }
                                      data-test-auto="42ACE989-6786-41EB-B494-D0EBD738E0DB"
                                  >
                                      <span className="marL05 marR05">
                                          {taxReturnModel.spouse.name + ' (' + taxReturnModel.spouse.email + ') '}
                                      </span>
                                  </Link>
                              </li>
                          ) : null}
                      </div>
                  ))
                : taxReturnModel &&
                  taxReturnModel.taxpayer &&
                  taxReturnModel.taxpayer.email &&
                  isIndividual(taxReturnModel)
                ? (ClientInfoModelScreenShare = (
                      <div>
                          {
                              <li>
                                  <Link
                                      to={'#'}
                                      onClick={() =>
                                          this.props.onScreenShareOpen(
                                              taxReturnModel.taxpayer.clientGuid,
                                              this.props.rowIndex
                                          )
                                      }
                                      data-test-auto="BF4D23E8-206A-4A2C-8D99-A6E872025BEC"
                                  >
                                      <span className="marL05 marR05">
                                          {taxReturnModel.taxpayer.name + ' (' + taxReturnModel.taxpayer.email + ') '}
                                      </span>
                                  </Link>
                              </li>
                          }
                      </div>
                  ))
                : taxReturnModel &&
                  taxReturnModel.partnership &&
                  taxReturnModel.partnership.email &&
                  isPartnership(taxReturnModel)
                ? (ClientInfoModelScreenShare = (
                      <div>
                          <li>
                              <Link
                                  to={'#'}
                                  onClick={() =>
                                      this.props.onScreenShareOpen(
                                          taxReturnModel.partnership.clientGuid,
                                          this.props.rowIndex
                                      )
                                  }
                                  data-test-auto="CBAADAF2-9C74-48B0-8D7E-CBC55BA61766"
                              >
                                  <span className="marL05 marR05">
                                      {taxReturnModel.partnership.name + ' (' + taxReturnModel.partnership.email + ') '}
                                  </span>
                              </Link>
                          </li>
                      </div>
                  ))
                : null;
        }

        return (
            <Bs.ButtonGroup className="btn-group-vertical" title={'More..'}>
                <Bs.DropdownButton
                    size="sm"
                    title={<span className="fa fa-ellipsis-h" style={{ verticalAlign: 'inherit' }}></span>}
                    id="delivered-return-dropdown"
                    alignRight
                    data-test-auto="53B48F5B-1E2C-416D-8283-19E553B5900B"
                >
                    {this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !==
                        DocumentStatus.DELIVEREDTOTAXCADDY &&
                    this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !==
                        DocumentStatus.DOWNLOADPDF ? (
                        <li
                            className="dropdown-submenu dropdown-menu-right dropdown-submenu-right"
                            title="Client View"
                            data-resource-id={RBACKeys[this.props.pageId].clientView}
                            onMouseEnter={() => this.props.onClientViewClick(this.props.rowIndex)}
                        >
                            <Link to={'#'} data-test-auto="2498F069-A899-4392-B96A-AD8DAFAF0D99" className="link">
                                <i className="fas fa-eye ddl-icon"></i>Client View
                            </Link>
                            {ClientInfoModelsli ? (
                                <ul className="dropdown-menu dropdown-menu-ul">{ClientInfoModelsli}</ul>
                            ) : (
                                <div className="marL10">Loading...</div>
                            )}
                        </li>
                    ) : (
                        ''
                    )}

                    {this.props.signatureStatus != signatureStatusPrinted &&
                        (this.props.signatureStatus == signatureStatus ? (
                            <li
                                title="Upload Forms"
                                onClick={() => this.props.onUploadFormsOpen(this.props.rowIndex)}
                                data-resource-id={RBACKeys[this.props.pageId].uploadForms}
                            >
                                <Link to={'#'} data-test-auto="0FD60540-B537-4E21-B5C6-DBB298A61792" className="link">
                                    <i className="fas fa-file-upload ddl-icon"></i>Upload Forms
                                </Link>
                            </li>
                        ) : this.props.signatureStatus != SignatureStatus[SignatureStatus.AwaitingUpload] &&
                          this.props.signatureStatus != SignatureStatus[SignatureStatus.AwaitingESign] &&
                          this.props.signatureStatus != SignatureStatus[SignatureStatus.DeliveredToTaxCaddy] &&
                          this.props.signatureStatus != SignatureStatus[SignatureStatus.AwaitingReview] &&
                          this.props.signatureStatus != SignatureStatus[SignatureStatus.PartiallyReviewed] &&
                          this.props.signatureStatus != SignatureStatus[SignatureStatus.Reviewed] &&
                          this.props.signatureStatus != SignatureStatus[SignatureStatus.PartiallySigned] ? (
                            <li
                                title="Download e-file Forms"
                                data-resource-id={RBACKeys[this.props.pageId].downloadEFileFormsGrid}
                                onClick={() => this.props.onDownloadEfileFormsOpen(this.props.rowIndex)}
                            >
                                <Link to={'#'} data-test-auto="4C16B288-E85D-44FA-8941-A4D2F07022B7" className="link">
                                    <i className="fas fa-file-signature ddl-icon"></i>Download e-file Forms
                                </Link>
                            </li>
                        ) : (
                            ''
                        ))}
                    {this.props.deliveredReturnData[this.props.rowIndex].isDocumentLocked ? (
                        <li title="Unlock" onClick={() => this.props.onUnlockReturnOpen(this.props.rowIndex)}>
                            <Link to={'#'}>
                                <i className="fas fa-unlock ddl-icon"></i>Unlock
                            </Link>
                        </li>
                    ) : (
                        ''
                    )}

                    {this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !==
                        DocumentStatus.DELIVEREDTOTAXCADDY &&
                    this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !==
                        DocumentStatus.DOWNLOADPDF ? (
                        <li
                            title="Resend Access Link"
                            data-resource-id={RBACKeys[this.props.pageId].resendAccessLink}
                            onClick={() => this.props.onResendAccessLinkOpen(this.props.rowIndex)}
                        >
                            <Link to={'#'} data-test-auto="9EBB29A8-2B5A-45CA-82F8-4498FA18D7F0" className="link">
                                <i className="fas fa-link ddl-icon"></i>Resend Access Link
                            </Link>
                        </li>
                    ) : (
                        ''
                    )}
                    {this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !==
                        DocumentStatus.DELIVEREDTOTAXCADDY &&
                    this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !==
                        DocumentStatus.DOWNLOADPDF ? (
                        <li
                            title="Recall Return"
                            data-resource-id={RBACKeys[this.props.pageId].recallReturn}
                            onClick={() => this.props.onRecallReturnOpen(this.props.rowIndex)}
                        >
                            <Link to={'#'} data-test-auto="D4716DFB-8359-4BE1-8C66-1825BF0C3D3F" className="link">
                                <i className="fa fa-retweet ddl-icon"></i>Recall Return
                            </Link>
                        </li>
                    ) : (
                        ''
                    )}
                    {this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus ===
                    DocumentStatus.DOWNLOADPDF ? (
                        <li
                            title="Reprocess Return"
                            data-resource-id={RBACKeys[this.props.pageId].recallReturn}
                            onClick={() => this.props.onRecallReturnOpen(this.props.rowIndex)}
                        >
                            <Link to={'#'} data-test-auto="A8FB7580-85F9-4B73-92FB-D88351EBFD68" className="link">
                                <i className="fa fa-retweet ddl-icon"></i>Reprocess Return
                            </Link>
                        </li>
                    ) : (
                        ''
                    )}
                    {this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !==
                        DocumentStatus.DELIVEREDTOTAXCADDY &&
                    this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !==
                        DocumentStatus.DOWNLOADPDF ? (
                        <li
                            title="Send Reminder"
                            onClick={() => this.props.onVoucherReminderOpen(this.props.rowIndex)}
                            data-resource-id={RBACKeys[this.props.pageId].automatedReminders}
                        >
                            <Link to={'#'} data-test-auto="31B64CC4-F86D-4334-B144-DBF5A4E83FEC" className="link">
                                <i className="fas fa-paper-plane ddl-icon"></i>Send Reminder
                            </Link>
                        </li>
                    ) : (
                        ''
                    )}
                    {this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !==
                        DocumentStatus.DELIVEREDTOTAXCADDY &&
                    this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !==
                        DocumentStatus.DOWNLOADPDF ? (
                        <li
                            title="View Access Code"
                            data-resource-id={RBACKeys[this.props.pageId].viewAccessCode}
                            onClick={() => this.props.onViewAccessCodeOpen(this.props.rowIndex)}
                        >
                            <Link to={'#'} data-test-auto="139D6AF7-5987-4672-AB6D-D71506B01CBD" className="link">
                                <i className="fas fa-user-lock ddl-icon"></i>View Access Code
                            </Link>
                        </li>
                    ) : (
                        ''
                    )}
                    {this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !==
                    DocumentStatus.DELIVEREDTOTAXCADDY ? (
                        <li
                            title="Modify Retention Policy"
                            data-resource-id={RBACKeys[this.props.pageId].retentionPolicy}
                            onClick={() => this.props.onRetentionPolicyOpen(this.props.rowIndex)}
                        >
                            <Link to={'#'} data-test-auto="D1A92FFE-A8E6-4F89-BFEE-D800DABF4DAF" className="link">
                                <i className="fas fa-magnet ddl-icon"></i>Retention Policy
                            </Link>
                        </li>
                    ) : (
                        ''
                    )}
                    <li
                        title="Report a Problem"
                        onClick={() => this.props.onReportProblemOpen(this.props.rowIndex)}
                        data-resource-id={RBACKeys[this.props.pageId].reportAProblem}
                    >
                        <Link to={'#'} data-test-auto="DACDF3C2-7F85-4817-B0F0-75452219F158" className="link">
                            <i className="fas fa-bullhorn ddl-icon"></i>Report a Problem
                        </Link>
                    </li>

                    <li
                        title="Client Tracking"
                        data-resource-id={RBACKeys[this.props.pageId].clientTracking}
                        onClick={() => this.props.onClientTrackingOpen(this.props.rowIndex)}
                    >
                        <Link to={'#'} data-test-auto="28BAB651-1B87-4E56-AC3A-78A5EFB8FF04" className="link">
                            <i className="fas fa-user-clock ddl-icon"></i>Client Tracking
                        </Link>
                    </li>
                    <li
                        title="Download Returns"
                        data-resource-id={RBACKeys[this.props.pageId].downloadReturns}
                        onClick={() => this.props.onDownloadReturnOpen(this.props.rowIndex)}
                    >
                        <Link to={'#'} data-test-auto="10423BBB-194E-448D-A03D-487D5C98B73B" className="link">
                            <i className="fas fa-file-download ddl-icon"></i>Download Returns
                        </Link>
                    </li>
                    {/*(this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !== DocumentStatus.DELIVEREDTOTAXCADDY &&
                    this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !== DocumentStatus.DOWNLOADPDF) ?
                    <li className="dropdown-submenu dropdown-menu-right dropdown-submenu-right" title="Request Screen Share">
                        <Link to={"#"} onClick={() => this.props.onScreenShareViewClick(this.props.rowIndex)} data-test-auto="991D6283-1C58-4F47-9082-B8F2959E2013">
                            <i className='fas fa-desktop ddl-icon'></i>Screen Share</Link>
                        {
                            this.props.showScreenShareSubMenu &&
                            <ul className="dropdown-menu dropdown-menu-ul">
                                {ClientInfoModelScreenShare}
                            </ul>
                        }
                    </li> : ''*/}
                    {this.props.isArchived ? (
                        <li
                            title="Restore Return"
                            data-resource-id={RBACKeys[this.props.pageId].restoreReturnGrid}
                            onClick={() => this.props.onRestoreReturnOpen(this.props.rowIndex)}
                        >
                            <Link to={'#'} data-test-auto="59381856-4CA6-4A69-B036-E3EE14226FE3" className="link">
                                <i className="fas fa-sync-alt ddl-icon"></i>Restore Return
                            </Link>
                        </li>
                    ) : (
                        ''
                    )}
                    <li title="Delete Return" data-resource-id={RBACKeys[this.props.pageId].deleteReturn}>
                        <Link
                            to={'#'}
                            onClick={() => this.props.onDeleteTaxReturnOpen(this.props.rowIndex)}
                            className="link"
                            data-test-auto="8E85187D-D9F3-4F07-AF47-F8F4E0F49755"
                        >
                            <i className="text-danger fas fa-times ddl-icon" />
                            Delete Return
                        </Link>
                    </li>
                </Bs.DropdownButton>
            </Bs.ButtonGroup>
        );
    };

    render() {
        return <div className="button-group">{this.createPrimaryActions()}</div>;
    }
}
