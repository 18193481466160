import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { ICustomColumn, IColumnValues } from '../../components/settings/GeneralSettings';
import { actionTypes } from '../ActionTypes';
import { handleResponse } from '../Library';
import * as Notification from '../common/NotificationStore';
import { StatusType } from '../common/NotificationStore';
import * as Constants from '../../components/helper/Constants';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';

export interface ICustomColumnState {
    customColumn: ICustomColumn,
    isLoading: boolean;
    message: string;
}

export interface RequestCustomColumnSettingsAction {
    type: actionTypes.REQUEST_CUSTOM_COLUMN_SETTINGS;
    message: string;
}

export interface ReceiveCustomColumnSettingsAction {
    type: actionTypes.RECEIVE_CUSTOM_COLUMN_SETTINGS;
    customColumn: ICustomColumn
}

export interface requestAddCustomColumnValue {
    type: actionTypes.REQUEST_ADD_CUSTOM_COLUMN_VALUE
}
export interface updateCustomColumn {
    type: actionTypes.REQUEST_UPDATE_CUSTOM_COLUMN
}
export interface receiveAddedCustomColumnValue {
    type: actionTypes.RECEIVE_ADDED_CUSTOM_COLUMN_VALUE,
    customColumnValue: IColumnValues
}

export interface requestUpdateCustomColumnValue {
    type: actionTypes.REQUEST_UPDATE_CUSTOM_COLUMN_VALUE,
    message: string;
}

export interface receiveUpdatedCustomColumnValue {
    type: actionTypes.RECEIVE_UPDATED_CUSTOM_COLUMN_VALUE,
    customColumn: IColumnValues,
}

export interface deleteCustomColumnValues {
    type: actionTypes.DELETE_CUSTOM_COLUMN_VALUES,
    id: number
}

export interface ErrorCustomColumnAction {
    type: actionTypes.ERROR_CUSTOM_COLUMN;
    reason: string;
}

export interface DeleteCustomColumnHeader {
    type: actionTypes.DELETE_CUSTOM_COLUMN_HEADER,
}

type KnownAction =
    RequestCustomColumnSettingsAction |
    ReceiveCustomColumnSettingsAction |
    requestAddCustomColumnValue |
    receiveAddedCustomColumnValue |
    requestUpdateCustomColumnValue |
    receiveUpdatedCustomColumnValue |
    deleteCustomColumnValues |
    updateCustomColumn |
    Notification.NotificationAction |
    ErrorCustomColumnAction |
    DeleteCustomColumnHeader;

export const actionCreators = {
    requestCustomColumn: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (reload || (getState().customColumn.customColumn.customColumnValues.length == 0)) {
            const fetchTask = fetch(`${API_BASE_URL}api/Common/CustomColumnSettings/GetCustomColumnSettings`, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(response => response as Promise<ICustomColumn>)
                .then(data => {
                    dispatch({
                        type: actionTypes.RECEIVE_CUSTOM_COLUMN_SETTINGS,
                        customColumn: data
                    });
                })
                .catch(function (error) {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error.statusText, statusType: Notification.StatusType.Error })
                    dispatch({ type: actionTypes.ERROR_CUSTOM_COLUMN, reason: error.message })
                    logger.trackError(`requestCustomColumn failed with error ${error.message}`);
                });
            addTask(fetchTask); // Ensure server-side prerendering waits for this to complete
        }
    },

    updateCustomColumn: (customColumn: ICustomColumn): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/Common/CustomColumnSettings/UpdateCustomColumnHeader/`, {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(customColumn),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(handleResponse)
            .then((id) => {
                //When company setup is done, there is no custom column
                if (customColumn.id == 0) {
                    customColumn.id = id;
                }
                dispatch({
                    type: actionTypes.RECEIVE_CUSTOM_COLUMN_SETTINGS,
                    customColumn: customColumn
                });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.CustomColumnConstants.StatusMessage.CustomColumnNameUpdateSuccess,
                    statusType: Notification.StatusType.Success
                });
            })
            .catch(function (error) {
                dispatch({
                    type: actionTypes.ERROR_CUSTOM_COLUMN,
                    reason: error.message
                })
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.CustomColumnConstants.StatusMessage.CustomColumnNameUpdateError,
                    statusType: Notification.StatusType.Error
                })
                logger.trackError(`updateCustomColumn failed for customColumn: ${customColumn}, with error ${error.message}`);
            })
        addTask(fetchTask);
        dispatch({
            type: actionTypes.REQUEST_CUSTOM_COLUMN_SETTINGS,
            message: Constants.CustomColumnConstants.OverlayMessage.UpdatingCustomColumnName
        });
    },

    addCustomColumnValue: (customColumnValue: IColumnValues): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/Common/CustomColumnSettings/`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(customColumnValue)
        })
            .then(handleResponse)
            .then((id) => {
                customColumnValue.id = id;
                dispatch({
                    type: actionTypes.RECEIVE_ADDED_CUSTOM_COLUMN_VALUE,
                    customColumnValue: customColumnValue
                })
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.CustomColumnConstants.StatusMessage.CustomColumnValueAddSuccess,
                    statusType: Notification.StatusType.Success
                })
            })
            .catch(function (error) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.CustomColumnConstants.StatusMessage.CustomColumnValueAddError,
                    statusType: Notification.StatusType.Error
                });
                dispatch({
                    type: actionTypes.ERROR_CUSTOM_COLUMN,
                    reason: error.message
                });
                logger.trackError(`addCustomColumnValue failed for customColumnValue: ${customColumnValue}, with error ${error.message}`);
            })
        addTask(fetchTask);
        dispatch({
            type: actionTypes.REQUEST_CUSTOM_COLUMN_SETTINGS,
            message: Constants.CustomColumnConstants.OverlayMessage.AddingCustomColumnValue
        });
    },

    updateCustomColumnValue: (customColumnValue: IColumnValues, showNotification: boolean = true, resourceId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/Common/CustomColumnSettings/`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'X-Resource-Id': resourceId
            },
            body: JSON.stringify(customColumnValue)
        })
            .then(handleResponse)
            .then((id) => {
                dispatch({
                    type: actionTypes.RECEIVE_UPDATED_CUSTOM_COLUMN_VALUE,
                    customColumn: customColumnValue
                });
                if (showNotification) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.CustomColumnConstants.StatusMessage.CustomColumnValueUpdateSuccess,
                        statusType: Notification.StatusType.Success
                    });
                }
            })
            .catch(function (error) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.CustomColumnConstants.StatusMessage.CustomColumnValueUpdateError,
                    statusType: Notification.StatusType.Error
                });
                dispatch({
                    type: actionTypes.ERROR_CUSTOM_COLUMN,
                    reason: error.message
                });
                logger.trackError(`updateCustomColumnValue failed for customColumnValue: ${customColumnValue}, with error ${error.message}`);
            })
        addTask(fetchTask);
        dispatch({
            type: actionTypes.REQUEST_CUSTOM_COLUMN_SETTINGS,
            message: Constants.CustomColumnConstants.OverlayMessage.UpdatingCustomColumnValue
        });
    },

    deleteCustomColumnValue: (customColumnValue: IColumnValues): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/Common/CustomColumnSettings/`, {
            method: 'Delete',
            credentials: 'include',
            body: JSON.stringify(customColumnValue),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.DELETE_CUSTOM_COLUMN_VALUES,
                    id: customColumnValue.id
                })
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.CustomColumnConstants.StatusMessage.CustomColumnValueDeleteSuccess,
                    statusType: Notification.StatusType.Success
                });
            })
            .catch(function (error) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.CustomColumnConstants.StatusMessage.CustomColumnValueDeleteError,
                    statusType: StatusType.Error
                })
                dispatch({
                    type: actionTypes.ERROR_CUSTOM_COLUMN,
                    reason: error.message
                });
                logger.trackError(`deleteCustomColumnValue failed for customColumnValue: ${customColumnValue}, with error ${error.message}`);
            })
        addTask(fetchTask);
        dispatch({
            type: actionTypes.REQUEST_CUSTOM_COLUMN_SETTINGS,
            message: Constants.CustomColumnConstants.OverlayMessage.DeletingCustomColumnValue
        });
    },
    deleteCustomColumnHeader: (customColumn: ICustomColumn): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/Common/CustomColumnSettings/DeleteCustomColumnHeader/`, {
            method: 'DELETE',
            credentials: 'include',
            body: JSON.stringify(customColumn),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.DELETE_CUSTOM_COLUMN_HEADER
                });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.CustomColumnConstants.StatusMessage.CustomColumnHeaderDeleteSuccess,
                    statusType: Notification.StatusType.Success
                });
            })
            .catch(function (error) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.CustomColumnConstants.StatusMessage.CustomColumnHeaderDeleteError,
                    statusType: StatusType.Error
                });
                logger.trackError(`deleteCustomColumnHeader failed for customColumn: ${customColumn}, with error ${error.message}`);
            })
        addTask(fetchTask);
    }

};

const unloadedState: ICustomColumnState = {
    customColumn: { customColumnValues: [], id: 0, header: "" }, isLoading: false, message: "",
} as ICustomColumnState;

export const reducer: Reducer<ICustomColumnState> = (state: ICustomColumnState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_CUSTOM_COLUMN_SETTINGS:
            let cleanSettings: ICustomColumnState = { ...state };
            cleanSettings.isLoading = true;
            cleanSettings.message = action.message;
            return cleanSettings;

        case actionTypes.RECEIVE_CUSTOM_COLUMN_SETTINGS:
            return {
                ...state,
                customColumn: action.customColumn,
                isLoading: false,
                error: false,
                message: "",
            } as ICustomColumnState;

        case actionTypes.RECEIVE_ADDED_CUSTOM_COLUMN_VALUE:
            let added: ICustomColumnState = { ...state }
            added.customColumn.customColumnValues.push(action.customColumnValue);
            added.isLoading = false;
            return added;

        case actionTypes.RECEIVE_UPDATED_CUSTOM_COLUMN_VALUE:
            let updated: ICustomColumnState = { ...state }
            let updatedIndex = updated.customColumn.customColumnValues.findIndex(x => x.id == action.customColumn.id);
            updated.customColumn.customColumnValues[updatedIndex] = action.customColumn;
            updated.isLoading = false;
            updated.message = "";
            return updated;

        case actionTypes.DELETE_CUSTOM_COLUMN_VALUES:
            let received: ICustomColumnState = { ...state }
            let index = received.customColumn.customColumnValues.findIndex(x => x.id == action.id);
            if (index != -1)
                received.customColumn.customColumnValues.splice(index, 1);
            received.isLoading = false;
            return received;

        case actionTypes.ERROR_CUSTOM_COLUMN:
            return {
                customColumn: Object.assign({}, state.customColumn),
                isLoading: false,
                message: "",
            } as ICustomColumnState;

        case actionTypes.DELETE_CUSTOM_COLUMN_HEADER:
            return {
                customColumn: { customColumnValues: [], id: 0, header: "" },
                isLoading: false,
                message: "",
            } as ICustomColumnState;
    }
    return state || unloadedState;
};