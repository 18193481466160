import React from  'react';
import * as Bootstrap from 'react-bootstrap';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { IColumnValues } from '..//GeneralSettings';

interface EditColumnValueProps {
    showState: boolean;
    onHide: (event: any) => void;
    selectListItem: string;
    onNewColumnValueChange: (event: any) => void;
    onSaveButtonClick: (event: any) => void;
    columnValues: IColumnValues;
}

export class EditColumnValue extends React.Component<EditColumnValueProps, {}>{
    constructor(props: any) {
        super(props);
    }
    public render() {
        return (
            <Modal show={this.props.showState} onHide={this.props.onHide} className="edit-column-value-modal">
                <Modal.Header closeButton data-test-auto="B8306827-11F9-4C76-A5CB-29B91B07C165">
                    <Modal.Title>
                        <span className="fas fa-edit"
                            style={{ color: "grey", marginRight: "5px" }}>
                        </span>
                        Edit Column Value
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="marL10">
                            <Form>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2">
                                        Old Value:
                            </Form.Label>
                                    <Col sm="10">
                                        <input className="form-control input-sm"
                                            type="text"
                                            value={this.props.selectListItem}
                                            disabled
                                            data-test-auto="BACE91D0-C00E-458D-9992-3CEB62ABB5E7">
                                        </input>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2">
                                        New Value:
                            </Form.Label>
                                    <Col sm="10">
                                        <input className="form-control input-sm"
                                            onChange={this.props.onNewColumnValueChange}
                                            value={this.props.columnValues.value}
                                            type="text"
                                            data-test-auto="85A47B0B-2152-4B58-A5EE-F2663F53734F">
                                        </input>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Bootstrap.Button
                        onClick={this.props.onHide}
                        variant='default'
                        data-test-auto="C20DF8CC-F4AA-4E8D-B710-75DB7EEC6146">
                        <i className="fas fa-times btn-white"></i>Cancel
                    </Bootstrap.Button>
                    <Bootstrap.Button
                        variant='info'
                        onClick={this.props.onSaveButtonClick}
                        data-test-auto="3BAB1674-CFFD-466E-B34E-18BF07B654C0">
                        <i className="fas fa-save"></i>Save
                    </Bootstrap.Button>
                </Modal.Footer>
            </Modal>
        )
    }
}