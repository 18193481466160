import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../../';
import { ReceiveK1DeliveredStatusDocumentsAction, ReceiveK1PartnersAction, RequestK1DeliveredStatusDocumentsAction, RequestK1PartnersAction, ClearK1PartnersAction } from './K1DeliveredStatusKnownTypes';
import { IK1DeliveredStatusViewModel, IK1PartnerViewModel, IK1DeliveredStatusState, unloadedState } from './K1DeliveredStatusState';
import { handleResponse } from './../../Library';
import { actionTypes } from './../../ActionTypes'
import { StatusType, NotificationAction } from './../../common/NotificationStore';
import { LoggerFactory } from '../../../Logger/LoggerFactory';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';

type KnownActions =
    ReceiveK1DeliveredStatusDocumentsAction |
    ReceiveK1PartnersAction |
    RequestK1DeliveredStatusDocumentsAction |
    RequestK1PartnersAction |
    NotificationAction | 
    ClearK1PartnersAction;


export const actionCreator = {
    requestK1DeliveredStatusDocuments: (query: string, callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.REQUEST_K1_DELIVEREDSTATUS_DOCUMENTS,
            k1DeliveredStatusTableLoading: true,
            query: query
        });
        const fetchTask = fetch(`${API_BASE_URL}api/Reports/K1DeliveredStatus/GetK1DeliveredStatusDocument` + query, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as IK1DeliveredStatusViewModel)
            .then(data => {
                dispatch({
                    type: actionTypes.RECEIVE_K1_DELIVEREDSTATUS_DOCUMENTS,
                    query: query,
                    k1DeliveredStatusTableLoading: false,
                    k1DeliveredStatusTable: data
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: error,
                    statusType: StatusType.Error
                });
                logger.trackError(`requestK1DeliveredStatusDocuments failed for query: ${query}, with error ${error.message}`);
                if (callback) {
                    callback();
                }
            });
        addTask(fetchTask);
    },
    requestK1Partners: (query: string): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.CLEAR_K1_PARTNERS, k1PartnerTable: {
                count: 0,
                k1Partners: []
            }
        });
        dispatch({
            type: actionTypes.REQUEST_K1_PARTNERS,
            k1PartnerTableLoading: true,
            query: query
        });
        const fetchTask = fetch(`${API_BASE_URL}api/Reports/K1DeliveredStatus/GetK1Partners` + query, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as IK1PartnerViewModel)
            .then(data => {
                dispatch({
                    type: actionTypes.RECEIVE_K1_PARTNERS,
                    query: query,
                    k1PartnerTableLoading: false,
                    k1PartnerTable: data
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: error,
                    statusType: StatusType.Error
                });
                logger.trackError(`requestK1Partners failed for query: ${query}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },
    exportK1DeliveredReturnsAsExcel: (query: string, callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/ExportToExcel/K1DeliveredStatus` + query, { credentials: 'include' })
            .then(response => {
                window.location.href = response.url;
                if (callback) {
                    callback();
                }
            }).catch((error) => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });
                logger.trackError(`exportK1DeliveredReturnsAsExcel failed for query: ${query}, with error ${error.message}`);
                if (callback) {
                    callback();
                }
            });
        addTask(fetchTask);
    },
    exportK1PartnersAsExcel: (query: string, callback : () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/ExportToExcel/K1Partners` + query, { credentials: 'include' })
            .then(response => {
                callback();
                window.location.href = response.url;
            }).catch((error) => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error });
                logger.trackError(`exportK1PartnersAsExcel failed for query: ${query}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },
    clearK1Partner: (): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.CLEAR_K1_PARTNERS, k1PartnerTable: {
                count: 0,
                k1Partners: []
            }
        });
    },
    sendReminderToPartner: (ids: number[], documentId: number, callback? : () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let query = "?documentId=" + documentId + "&ids=" + JSON.stringify(ids);
        const fetchTask = fetch(`${API_BASE_URL}api/TaxDocumentMailEvent/SendReminderToPartner/` + query, {
            method: 'POST',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(() => {
                if (callback)
                    callback();
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: error,
                    statusType: StatusType.Error
                });
                logger.trackError(`sendReminderToPartner failed for ids: ${ids}, with error ${error.message}`);
            });
        addTask(fetchTask);
    }
} 

export const reducer: Reducer<IK1DeliveredStatusState> = (state: IK1DeliveredStatusState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownActions;
    switch (action.type) {
        case actionTypes.REQUEST_K1_DELIVEREDSTATUS_DOCUMENTS:
            return {
                ...unloadedState,
                K1DeliveredStatusLoading: action.k1DeliveredStatusTableLoading,
                K1DeliveredStatusQuery: action.query
            } as IK1DeliveredStatusState
        case actionTypes.RECEIVE_K1_DELIVEREDSTATUS_DOCUMENTS:
            return {
                ...unloadedState,
                K1DeliveredStatus: action.k1DeliveredStatusTable,
                K1DeliveredStatusLoading: action.k1DeliveredStatusTableLoading,
                K1DeliveredStatusQuery: action.query
            } as IK1DeliveredStatusState
        case actionTypes.REQUEST_K1_PARTNERS:
            return {
                ...state,
                K1PartnersQuery: action.query,
                K1PartnersLoading: action.k1PartnerTableLoading
            } as IK1DeliveredStatusState
        case actionTypes.RECEIVE_K1_PARTNERS:
            return {
                ...state,
                K1Partners: action.k1PartnerTable,
                K1PartnersLoading: action.k1PartnerTableLoading,
                K1PartnersQuery: action.query
            } as IK1DeliveredStatusState
        case actionTypes.CLEAR_K1_PARTNERS:
            return {
                ...state,
                K1Partners: action.k1PartnerTable
            } as IK1DeliveredStatusState
    }
    return state || unloadedState;
}