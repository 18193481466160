import React from 'react';
import { addTask } from 'domain-task';
import { RouteComponentProps } from 'react-router';
import * as signalR from '@microsoft/signalr';
import * as CompanyStore from '../store/company/CompanyStore';
import * as CustomColumnStore from '../store/common/CustomColumnStore';
import * as DeliveredReturnsStore from '../store/reports/DeliveredReturnsStore';
import { IUserProfile } from '../components/navigation/profile/ProfileObjects';
import * as TaxDocumentStore from '../store/common/TaxDocumentStore';
import { VenusNotifier } from '../components/helper/VenusNotifier';
import { DocumentStatus, ITaxReturn } from '../components/common/TaxReturn';
import { handleResponse } from '../store/Library';
import { DeliveredReturnsState } from '../store/reports/DeliveredReturnsState';
import { API_BASE_URL } from 'src/utils/contants';
import store from '../store';
import { LogoutCause } from '../store/auth/reducer';
import { userAutoLogout } from '../store/auth/actions';
import { setAutoLogout } from '../oidcClient/helpers';
import * as RecycleReturnsState from '../store/reports/RecycleReturns/RecycleReturnsState';
import * as RecycleReturnsStore from '../store/reports/RecycleReturns/RecycleReturnsStore';

const isEqual = require("react-fast-compare");
//const apiBaseUrl: any = 'http://localhost:7071/';
//const apiBaseUrl: any = 'https://signalrhubapptest.azurewebsites.net/';
const bearerPrefix: string = 'Bearer ';

export interface ISignalRState {
    apiBaseUrl: any
};
type SignalRProps =
    {
        profile: IUserProfile,
        companyData: CompanyStore.ICompanyData,
        taxDocuments: TaxDocumentStore.ITaxDocumentDictionary,
        deliveredReturns: DeliveredReturnsState,
        customColumn: CustomColumnStore.ICustomColumnState,
        requestTaxDocument: (id: number, force: boolean) => void,
        getMyDownloadsListAsync: () => void;
        recycleReturns: RecycleReturnsState.RecycleReturnsState;
    }
    & typeof CompanyStore.actionCreators
    & typeof TaxDocumentStore.actionCreators
    & typeof DeliveredReturnsStore.actionCreators
    & typeof CustomColumnStore.actionCreators
    & typeof RecycleReturnsStore.actionCreators
    & RouteComponentProps<{}>

export interface INotificationMessage {
    documentId: number;
    documentGuid: string;
    companyId: number;
    notificationType: NotificationType;
    data: any;
}
export interface INotificationMetaData {
    clientId: string
}
export enum NotificationType {
    None = "None",
    DeliverySuccess = "DeliverySuccess",
    DeliveryFailed = "DeliveryFailed",
    RecognizerCompleted = "RecognizerCompleted",
    RecognizerFailed = "RecognizerFailed",
    DocumentRecalled = "DocumentRecalled",
    DocumentDeleted = "DocumentDeleted",
    BulkArchiveSuccess = "BulkArchiveSuccess",
    BulkArchiveError = "BulkArchiveError",
    BulkSendReminderSuccess = "BulkSendReminderSuccess",
    BulkSendReminderError = "BulkSendReminderError",
    BulkSaveCustomColumnSuccess = "BulkSaveCustomColumnSuccess",
    BulkSaveCustomColumnError = "BulkSaveCustomColumnError",
    BulkRemoveCustomColumnSuccess = "BulkRemoveCustomColumnSuccess",
    BulkRemoveCustomColumnError = "BulkRemoveCustomColumnError",
    BulkDownloadEFileSuccess = "DownloadEFileSuccess",
    BulkDownloadEFileError = "DownloadEFileError",
    BulkExportToExcelSuccess = "ExportToExcelSuccess",
    BulkExportToExcelError = "ExportToExcelError",
    BulkDeleteRecycledExtensionsSuccess = "DeleteRecycledExtensionsSuccess",
    BulkDeleteRecycledExtensionsError = "DeleteRecycledExtensionsError"
}
export const NotificationConstants = {
    RecognizerCompleted: "Recognizer Completed",
    RecognizerFailed: "Recognizer Failed",
    DeliverySuccess: "delivered successfully",
    DeliveryFailed: "delivery failed"
}


export interface IUserAutoLogoutNotificationMessage {
    users: number[];
    logoutCause: LogoutCause;
    companyId: number;
}

export class SignalRWebSocket extends React.Component<SignalRProps, ISignalRState> {

    private user: string = '';
    private group: string = '';
    private token: any = '';
    constructor(props: any) {
        super(props);
        this.state = {
            apiBaseUrl: ''
        };
    }


    componentDidMount() {
        this.getToken();
    }
    private getToken() {
        const user = store.getState().auth.user;
        this.token = user.access_token;
        this.getWebSocketUrl();
    }

    private getWebSocketGroup = (companyId: number) => {
        var group = "00000000" + companyId;
        return 'ext' + group.substr(group.length - 8);
    }

    private signalRConnectionInit = () => {
        let fetchTask = fetch(`${this.state.apiBaseUrl}/signalr/negotiate`, {
            method: 'POST',
            headers: {
                'x-ms-signalr-userid': this.user,
                'Authorization': bearerPrefix + this.token,
                'ClientType': "SSREX-CPA-UI"
            }
        }).then(handleResponse)
            .then((info: any) => {
                info.accessToken = info.accessToken || info.accessKey;
                info.url = info.url || info.endpoint;

                const options = {
                    accessTokenFactory: () => info.accessToken
                };

                const connection = new signalR.HubConnectionBuilder()
                    .withUrl(info.url, options)
                    .configureLogging(signalR.LogLevel.Information)
                    .build();
                console.log(connection);
                connection.on('DocumentStatusUpdated', this.processDocumentData);
                connection.on('UserPrivilegesChanged', this.notifyUserPrivilegeChange);

                connection.onclose(() => {
                    console.log('disconnected');
                    this.startConnection(connection);
                });
                this.startConnection(connection);

            }).catch((reason) => {
                console.log(reason);
            })
        addTask(fetchTask);
    }

    private startConnection = (connection: any) => {
        const _self: any = this;
        console.log('connecting...');
        connection.start()
            .then(function () {
                console.log('connected!');
                _self.addGroup();
                connection.invoke('getConnectionId')
                    .then(function (connectionId: any) {
                        // Send the connectionId to controller
                    });
            })
            .catch(function (err: any) {
                console.error(err);
                setTimeout(_self.startConnection, 5000, connection);
            });
    }

    private getCookieValue = (key: string) => {
        const b: any = document.cookie.match('(^|[^;]+)\\s*' + key + '\\s*=\\s*([^;]+)');
        return b ? b.pop() : '';
    }

    private addGroup = () => {
        const fetchTask = fetch(`${this.state.apiBaseUrl}/signalr/AddToGroup`, {
            method: 'POST',
            headers: {
                'x-ms-signalr-userid': this.user,
                'Authorization': bearerPrefix + this.token,
                'ClientType': "SSREX-CPA-UI"
            },
            body: JSON.stringify({
                recipient: this.user,
                groupname: this.group
            })
        }).then((resp: any) => {
            if (resp.status == 200) {
                console.log("User added to the group successfully")
            }
        });
        addTask(fetchTask);
    }

    private removeGroup = () => {
        const fetchTask = fetch(`${this.state.apiBaseUrl}/signalr/RemoveFromGroup`, {
            method: 'POST',
            headers: {
                'x-ms-signalr-userid': this.user,
                'Authorization': bearerPrefix + this.token,
                'ClientType': "SSREX-CPA-UI"
            },
            body: JSON.stringify({
                recipient: this.user,
                groupname: this.group
            })
        }).then((resp: any) => {
            if (resp.status == 200) {
                console.log("User removed from group successfully")
            }
        });
        addTask(fetchTask);
    }

    private getWebSocketUrl = () => {
        const fetchTask = fetch(`${API_BASE_URL}api/WebSocket/GetWebSocketConnectionUrl`, {
            method: 'GET',
            credentials: 'include',

        }).then(handleResponse)
            .then((resp: any) => {
                this.setState({ apiBaseUrl: resp },
                    () => {
                        if (this.state.apiBaseUrl) {
                            const user = store.getState().auth.user;
                            this.user = user.profile.company_id + '_' + user.profile.user_id;
                            this.group = this.getWebSocketGroup(user.profile.company_id);
                            this.signalRConnectionInit();
                        }
                    });
            });
        addTask(fetchTask);
    }
    notifyUserPrivilegeChange = (notificationMessage: IUserAutoLogoutNotificationMessage): void => {
        if (notificationMessage.users.includes(this.props.profile.userId)) {
            //force setting userAutoLogout cookie
            setAutoLogout("1");
            store.dispatch(userAutoLogout(notificationMessage.logoutCause));
        }
    }
    private processDocumentData = (notificationMessage: INotificationMessage) => {
        //let taxDocument: ITaxReturn;       
        let metaData: INotificationMetaData;
        console.log(notificationMessage);
        switch (notificationMessage.notificationType) {
            case NotificationType.DeliverySuccess:
                //taxDocument = this.props.taxDocuments[notificationMessage.documentId].taxReturn;
                this.props.notifyTaxDocument(notificationMessage.documentId, DocumentStatus.DELIVERED);
                // VenusNotifier.Success(taxDocument.clientId + " " + NotificationConstants.DeliverySuccess  , "Success");
                break
            case NotificationType.DeliveryFailed:
                //taxDocument = this.props.taxDocuments[notificationMessage.documentId].taxReturn;
                this.props.notifyTaxDocument(notificationMessage.documentId, DocumentStatus.ERROR);
                // VenusNotifier.Error(taxDocument.clientId + " " + NotificationConstants.DeliveryFailed , "Error");
                break
            case NotificationType.RecognizerCompleted:
                this.props.notifyTaxDocument(notificationMessage.documentId, DocumentStatus.READY);
                if (notificationMessage.data) {
                    metaData = JSON.parse(notificationMessage.data);
                    //  VenusNotifier.Success(NotificationConstants.RecognizerCompleted + " " + metaData.clientId, "Success");
                }
                break;
            case NotificationType.RecognizerFailed:
                this.props.notifyTaxDocument(notificationMessage.documentId, DocumentStatus.ERROR);
                if (notificationMessage.data) {
                    metaData = JSON.parse(notificationMessage.data);
                    // VenusNotifier.Error(NotificationConstants.RecognizerFailed + " " + metaData.clientId, "Error");
                }
                break;

            case NotificationType.BulkArchiveSuccess:
            case NotificationType.BulkSendReminderSuccess:
                this.props.updateDeliveredReturns();
                break;

            case NotificationType.BulkSaveCustomColumnSuccess:
            case NotificationType.BulkRemoveCustomColumnSuccess:
                this.props.updateDeliveredReturns();
                this.props.requestCustomColumn(true);
                break;

            case NotificationType.BulkArchiveError:
            case NotificationType.BulkSendReminderError:
            case NotificationType.BulkSaveCustomColumnError:
            case NotificationType.BulkRemoveCustomColumnError:
                this.props.updateDeliveredReturns();
                let message = this.getNotificationMessage(notificationMessage.notificationType);
                VenusNotifier.Error(message, null);
                break;
            case NotificationType.BulkDownloadEFileSuccess:
            case NotificationType.BulkDownloadEFileError:
            case NotificationType.BulkExportToExcelSuccess:
            case NotificationType.BulkExportToExcelError:
                this.props.getMyDownloadsListAsync();
                break;
            case NotificationType.BulkDeleteRecycledExtensionsSuccess:
                this.props.updateRecycledExtensions();
                break;
        }
    }

    getNotificationMessage = (notificationType: NotificationType): string => {
        switch (notificationType) {
            case NotificationType.BulkArchiveError: return "Failed to archive the selected document(s)!";
            case NotificationType.BulkSendReminderError: return "Failed to send reminder for the selected document(s)!";
            case NotificationType.BulkSaveCustomColumnError: return "Failed to update custom column for the selected document(s)!";
            case NotificationType.BulkRemoveCustomColumnError: return "Failed to remove custom column for the selected document(s)!";
            case NotificationType.BulkDeleteRecycledExtensionsError: return "Failed to delete selected recycled document(s)!";
            default: return "";
        }
    }

    public render() {
        return (<div />);
    }

}
export default SignalRWebSocket;