import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { IDocumentTypeValue } from '../../components/settings/Signatures';
import { actionTypes } from '../ActionTypes';
import { handleResponse } from '../Library';
import * as Notification from '../common/NotificationStore';
import { StatusType } from '../common/NotificationStore';
import * as Constants from '../../components/helper/Constants';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';

export interface IDocumentTypeState {
    documentType: IDocumentTypeValue[],
    isLoading: boolean;
    message: string;
}

export interface RequestAdditionlDoumentTypeSettingsAction {
    type: actionTypes.REQUEST_DOCUMENT_TYPE_SETTINGS;
    message: string;
}

export interface ReceiveAdditionalDocumentTypeSettingsAction {
    type: actionTypes.RECEIVE_DOCUMENT_TYPE_SETTINGS;
    allDocumentTypes: IDocumentTypeValue[]
}

export interface ReceiveAddedAdditionalDocumentTypeValue {
    type: actionTypes.RECEIVE_ADDED_DOCUMENT_TYPE_VALUE,
    documentTypeValue: IDocumentTypeValue
}

export interface ReceiveUpdatedAdditionalDocumentTypeValue {
    type: actionTypes.RECEIVE_UPDATED_DOCUMENT_TYPE_VALUE,
    documentType: IDocumentTypeValue,
}

export interface DeleteAdditionalDocumentTypeValues {
    type: actionTypes.DELETE_DOCUMENT_TYPE_VALUES,
    id: number
}

export interface ErrorDocumentTypeAction {
    type: actionTypes.ERROR_DOCUMENT_TYPE;
    reason: string;
}

type KnownAction =
    RequestAdditionlDoumentTypeSettingsAction |
    ReceiveAdditionalDocumentTypeSettingsAction |
    ReceiveAddedAdditionalDocumentTypeValue |
    ReceiveUpdatedAdditionalDocumentTypeValue |
    DeleteAdditionalDocumentTypeValues |
    Notification.NotificationAction |
    ErrorDocumentTypeAction;

export const actionCreators = {

    requestAdditionalDocumentType: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (reload || (getState().additionalDocumentType.documentType.length == 0)) {
            const fetchTask = fetch(`${API_BASE_URL}api/Common/AdditionalDocumentTypeSettings/GetDocumentTypeSettingsAsync`, {
                method: 'GET',
                credentials: 'include'
            })
                .then(response => response.json() as Promise<IDocumentTypeValue[]>)
                .then(data => {
                    dispatch({
                        type: actionTypes.RECEIVE_DOCUMENT_TYPE_SETTINGS,
                        allDocumentTypes: data
                    });
                })
                .catch(function (error) {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error.statusText, statusType: Notification.StatusType.Error })
                    dispatch({ type: actionTypes.ERROR_DOCUMENT_TYPE, reason: error.message })
                    logger.trackError(`requestAdditionalDocumentType failed with error ${error.message}`);
                });
            addTask(fetchTask); // Ensure server-side prerendering waits for this to complete
        }
    },

    addAdditionalDocumentTypeValue: (documentTypeValue: IDocumentTypeValue): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/Common/AdditionalDocumentTypeSettings/`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(documentTypeValue)
        })
            .then(handleResponse)
            .then((id) => {
                documentTypeValue.id = id;
                dispatch({
                    type: actionTypes.RECEIVE_ADDED_DOCUMENT_TYPE_VALUE,
                    documentTypeValue: documentTypeValue
                })
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.DocumentTypeConstants.StatusMessage.DocumentTypeAddSuccess,
                    statusType: Notification.StatusType.Success
                })
            })
            .catch(function (error) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.DocumentTypeConstants.StatusMessage.DocumentTypeValueAddError,
                    statusType: Notification.StatusType.Error
                });
                dispatch({
                    type: actionTypes.ERROR_DOCUMENT_TYPE,
                    reason: error.message
                });
                logger.trackError(`addAdditionalDocumentTypeValue failed for documentTypeValue: ${documentTypeValue}, with error ${error.message}`);
            })
        addTask(fetchTask);
        dispatch({
            type: actionTypes.REQUEST_DOCUMENT_TYPE_SETTINGS,
            message: Constants.DocumentTypeConstants.OverlayMessage.AddingDocumentTypeValue
        });
    },

    updateAdditionalDocumentTypeValue: (documentTypeValue: IDocumentTypeValue, showNotification: boolean = true): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/Common/AdditionalDocumentTypeSettings/`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(documentTypeValue)
        })
            .then(handleResponse)
            .then((id) => {
                dispatch({
                    type: actionTypes.RECEIVE_UPDATED_DOCUMENT_TYPE_VALUE,
                    documentType: documentTypeValue
                });
                if (showNotification) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.DocumentTypeConstants.StatusMessage.DocumentTypeUpdateSuccess,
                        statusType: Notification.StatusType.Success
                    });
                }
            })
            .catch(function (error) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.DocumentTypeConstants.StatusMessage.DocumentTypeValueUpdateError,
                    statusType: Notification.StatusType.Error
                });
                dispatch({
                    type: actionTypes.ERROR_DOCUMENT_TYPE,
                    reason: error.message
                });
                logger.trackError(`updateAdditionalDocumentTypeValue failed for documentTypeValue: ${documentTypeValue}, with error ${error.message}`);
            })
        addTask(fetchTask);
        dispatch({
            type: actionTypes.REQUEST_DOCUMENT_TYPE_SETTINGS,
            message: Constants.DocumentTypeConstants.OverlayMessage.UpdatingDocumentTypeValue
        });
    },

    deleteAdditionalDocumentTypeValue: (documentTypeValue: IDocumentTypeValue): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/Common/AdditionalDocumentTypeSettings/`, {
            method: 'Delete',
            credentials: 'include',
            body: JSON.stringify(documentTypeValue),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.DELETE_DOCUMENT_TYPE_VALUES,
                    id: documentTypeValue.id
                })
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.DocumentTypeConstants.StatusMessage.DocumentTypeValueDeleteSuccess,
                    statusType: Notification.StatusType.Success
                });
            })
            .catch(function (error) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.DocumentTypeConstants.StatusMessage.DocumentTypeValueDeleteError,
                    statusType: StatusType.Error
                })
                dispatch({
                    type: actionTypes.ERROR_DOCUMENT_TYPE,
                    reason: error.message
                });
                logger.trackError(`deleteAdditionalDocumentTypeValue failed for documentTypeValue: ${documentTypeValue}, with error ${error.message}`);
            })
        addTask(fetchTask);
        dispatch({
            type: actionTypes.REQUEST_DOCUMENT_TYPE_SETTINGS,
            message: Constants.DocumentTypeConstants.OverlayMessage.DeletingDocumentTypeValue
        });
    },
}

const unloadedState: IDocumentTypeState = {
    documentType: [], isLoading: false, message: "",
} as IDocumentTypeState;

export const reducer: Reducer<IDocumentTypeState> = (state: IDocumentTypeState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_DOCUMENT_TYPE_SETTINGS:
            let cleanSettings: IDocumentTypeState = { ...state };
            cleanSettings.isLoading = true;
            cleanSettings.message = action.message;
            return cleanSettings;

        case actionTypes.RECEIVE_DOCUMENT_TYPE_SETTINGS:
            return {
                ...state,
                documentType: action.allDocumentTypes,
                isLoading: false,
                error: false,
                message: "",
            } as IDocumentTypeState;

        case actionTypes.RECEIVE_ADDED_DOCUMENT_TYPE_VALUE:
            let added: IDocumentTypeState = { ...state }
            added.documentType.push(action.documentTypeValue);
            added.isLoading = false;
            return added;

        case actionTypes.RECEIVE_UPDATED_DOCUMENT_TYPE_VALUE:
            let updated: IDocumentTypeState = { ...state }
            let updatedIndex = updated.documentType.findIndex(x => x.id == action.documentType.id);
            updated.documentType[updatedIndex] = action.documentType;
            updated.isLoading = false;
            updated.message = "";
            return updated;

        case actionTypes.DELETE_DOCUMENT_TYPE_VALUES:
            let received: IDocumentTypeState = { ...state }
            let index = received.documentType.findIndex(x => x.id == action.id);
            if (index != -1)
                received.documentType.splice(index, 1);
            received.isLoading = false;
            return received;

        case actionTypes.ERROR_DOCUMENT_TYPE:
            return {
                documentType: Object.assign({}, state.documentType),
                isLoading: false,
                message: "",
            } as IDocumentTypeState;

    }
    return state || unloadedState;
};