import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../../';
import { IVoucherFormName } from '../../../Core/ViewModels/Voucher/VoucherFormNameViewModel';
import { ITaxReturn, VoucherNo } from '../../../components/common/TaxReturn';
import { StatusType, NotificationAction } from '../../common/NotificationStore';
import { handleResponse } from '../../Library';
import { IVoucherTaxFilingDate } from '../../../Core/ViewModels/Voucher/VoucherDefaultDateViewModel';
import {
    IPaymentUrlInfo
} from '../../../Core/ViewModels/Voucher/PaymentUrlInfoViewModel';
import { actionTypes } from '../../ActionTypes';
import { LoggerFactory } from '../../../Logger/LoggerFactory';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';
import { getProcessResourceId } from 'src/components/helper/RbacConstants';

export interface IVoucherState {
    voucherDefaultDates: IVoucherTaxFilingDate[];
    paymentUrls: IPaymentUrlInfo[];
    voucherFormNames: IVoucherFormName[];
}

interface ReceiveVoucherDueDateListAction {
    type: actionTypes.RECEIVE_VOUCHER_DUE_DATE_LIST;
    dueDates: IVoucherTaxFilingDate[];
}

interface ReceivePaymentUrlInfoListAction {
    type: actionTypes.RECEIVE_PAYMENT_URL_INFO_LIST,
    paymentUrls: IPaymentUrlInfo[];
}

interface ReceiveVoucherFormNameListAction {
    voucherFormNames: IVoucherFormName[];
    type: actionTypes.RECEIVE_VOUCHER_FORM_NAME_LIST;
}

type DispatchAction = ReceiveVoucherDueDateListAction | ReceivePaymentUrlInfoListAction | ReceiveVoucherFormNameListAction;

type KnownAction =
    NotificationAction |
    DispatchAction;

export const actionCreators = {
    requestVoucherDueDates: (taxReturn: ITaxReturn, authorityId?: number, voucherNo?: VoucherNo):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            let url: string = `${API_BASE_URL}api/Voucher/GetTaxFilingDatesAsync?taxYear=` +
                taxReturn.taxYear + '&engagementType=' + taxReturn.engagementType;
            if (authorityId) {
                url = url.concat('&authorityId=' + authorityId);
            }
            if (voucherNo) {
                url = url.concat('&voucherNo=' + VoucherNo);
            }
            const fetchTask = fetch(url, {
                credentials: 'include',
                headers: {
                    'X-Resource-Id': getProcessResourceId()
                }
            })
                .then(handleResponse)
                .then(response => response as Promise<IVoucherTaxFilingDate[]>)
                .then(data => {
                    dispatch({
                        type: actionTypes.RECEIVE_VOUCHER_DUE_DATE_LIST, dueDates: data
                    });
                }).catch(function (error) {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: error.statusText,
                        statusType: StatusType.Error
                    });
                    logger.trackError(`requestVoucherDueDates failed for taxReturn: ${taxReturn}, with error ${error.message}`);
                });
            addTask(fetchTask);
        },

    requestPaymentUrls: (taxReturn: ITaxReturn):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            const fetchTask = fetch(`${API_BASE_URL}api/Payment/GetPaymentURLsAsync?engagementType=`
                + taxReturn.engagementType, {
                credentials: 'include',
                headers: {
                    'X-Resource-Id': getProcessResourceId()
                }
            })
                .then(handleResponse)
                .then(response => response as Promise<IPaymentUrlInfo[]>)
                .then(data => {
                    dispatch({
                        type: actionTypes.RECEIVE_PAYMENT_URL_INFO_LIST, paymentUrls: data
                    });
                }).catch(function (error) {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: error.statusText,
                        statusType: StatusType.Error
                    });
                    logger.trackError(`requestPaymentUrls failed for taxReturn: ${taxReturn}, with error ${error.message}`);
                });
            addTask(fetchTask); // Ensure server-side prerendering waits for this to complete

        },

    requestVoucherFormNames: (taxReturn: ITaxReturn):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            const fetchTask = fetch(`${API_BASE_URL}api/Voucher/GetVoucherFormNameAsync?engagementType=`
                + taxReturn.engagementType, {
                credentials: 'include',
                headers: {
                    'X-Resource-Id': getProcessResourceId()
                }
            })
                .then(handleResponse)
                .then(response => response as Promise<IVoucherFormName[]>)
                .then(data => {
                    dispatch({
                        type: actionTypes.RECEIVE_VOUCHER_FORM_NAME_LIST, voucherFormNames: data
                    });
                }).catch(function (error) {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: error.statusText,
                        statusType: StatusType.Error
                    });
                    logger.trackError(`requestVoucherFormNames failed for taxReturn: ${taxReturn}, with error ${error.message}`);
                });
            addTask(fetchTask);

        }
}

const unloadedState: IVoucherState =
    {
        voucherDefaultDates: [],
        paymentUrls: [],
        voucherFormNames: []
    } as IVoucherState;

export const reducer: Reducer<IVoucherState> = (previousState: IVoucherState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.RECEIVE_VOUCHER_DUE_DATE_LIST:
            let receivedState: IVoucherState = { ...previousState };
            receivedState.voucherDefaultDates = action.dueDates;
            return receivedState;
        case actionTypes.RECEIVE_PAYMENT_URL_INFO_LIST:
            let receivedPaymentURLState: IVoucherState = { ...previousState };
            receivedPaymentURLState.paymentUrls = action.paymentUrls;
            return receivedPaymentURLState;
        case actionTypes.RECEIVE_VOUCHER_FORM_NAME_LIST:
            let receivedFormNameState: IVoucherState = { ...previousState };
            receivedFormNameState.voucherFormNames = action.voucherFormNames;
            return receivedFormNameState;
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }
    return previousState || unloadedState;
};