import React from  'react';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { IUserModel } from '../../../Core/ViewModels/User/UserViewModel';
import { IDocumentAccess } from '../../common/TaxReturn';
import { MultiFileReturnUploadRecalledDocumentModal } from './TaxSoftwareTypeUpload/MultiFileReturns/MultiFileReturnUploadRecalledDocumentModal';
import { ProSystemUploadRecalledDocumentModal } from './TaxSoftwareTypeUpload/ProSystemUploadRecalledDocumentModal';
import { TaxSoftwareType } from 'src/Core/ViewModels/Company/SuiteCompanySettings/SuiteTaxSoftwareComponentModel';
import { IReturnAccessSettingsModel } from 'src/Core/ViewModels/Company/CompanySettingsViewModel';
import { IUserDataFromDB, IUserGroupDataFromDB } from '../SetAccess/SetAccessCommonModal.model';


interface UploadModalProps {
    taxSoftwareType: TaxSoftwareType
    documentKey: string;
    onShow: boolean;
    onHide(): void;
    users: IUserModel[];
    partners: IUserModel[];
    userData?: any;
    selectedERO: number;
    getUploadLink: (url: string, callback?: () => void) => void;
    processTaxReturn: (url: string, callback: () => void) => void;
    submitTaxReturn: (url: string, taxData: string, callback: () => void) => void;
    uploadConfirmation: (documentKey: string, clientId: string) => void;
    isRecalled?: boolean;
    returnAccessSettings:IReturnAccessSettingsModel;
    getTaxDocumentsAccessMaps: (ids: number[], callback?: (result: IDocumentAccess) => void ) => void;
}
export interface UploadModelstate {
    EROs: IUserModel[];
    defaultERO: number;
    availableUsers: IUserDataFromDB[];
    selectedUsers: IUserDataFromDB[];
    availableUserGroups: IUserGroupDataFromDB[];
    selectedUserGroups: IUserGroupDataFromDB[];
}

export interface TaxReturnUploadData {
    number?: number;
    clientName?: string;
    name?: string;
    progressBar?: number;
    size?: string;
    status?: string;
    clientId?: string;
    partnerId?: number;
    setAccess?: string;
    sasGuid?: string;
    gridRowDisable: boolean;
    documentAccess: IDocumentAccess;
}

export class UploadRecalledDocumentModal extends React.Component<UploadModalProps, UploadModelstate> {
    constructor(props: UploadModalProps) {
        super(props);
        this.state = {
            defaultERO: 0,
            EROs: [],
            availableUsers: [],
            selectedUsers: [],
            availableUserGroups: [],
            selectedUserGroups: []
        }
        this.handleClose = this.handleClose.bind(this);
    }
    componentWillMount() {
        this.props.getTaxDocumentsAccessMaps([],(result: IDocumentAccess) => {
            this.setState({
                availableUserGroups: result.availableUserGroups,
                availableUsers: result.availableUsers,
                selectedUserGroups: result.selectedUserGroups,
                selectedUsers: result.selectedUsers
            });
        });
    }

    componentWillReceiveProps(nextProps: UploadModalProps) {
        if (nextProps.users && nextProps.users.length > 0) {
            this.setState({ EROs: this.props.partners, defaultERO: this.props.selectedERO });
        }
    }


    private handleClose() {
        this.props.onHide();
    }

    public render() {
        return <div>
            <ProSystemUploadRecalledDocumentModal
                onShow={this.props.onShow && this.props.taxSoftwareType == TaxSoftwareType.ProSystems}
                onHide={this.handleClose}
                documentKey={this.props.documentKey}
                EROs={this.state.EROs}
                getUploadLink={this.props.getUploadLink}
                processTaxReturn={this.props.processTaxReturn}
                submitTaxReturn={this.props.submitTaxReturn}
                uploadConfirmation={this.props.uploadConfirmation}
                isRecalled={this.props.isRecalled}
                returnAccessSettings={this.props.returnAccessSettings}
                availableUsers={this.state.availableUsers}
                selectedUsers={this.state.selectedUsers}
                selectedUserGroups={this.state.selectedUserGroups}
                availableUserGroups={this.state.availableUserGroups}
            />

            <MultiFileReturnUploadRecalledDocumentModal
                onShow={this.props.onShow && this.props.taxSoftwareType != TaxSoftwareType.ProSystems}
                onHide={this.handleClose}
                EROs={this.state.EROs}
                documentKey={this.props.documentKey}
                getUploadLink={this.props.getUploadLink}
                submitTaxReturn={this.props.submitTaxReturn}
                uploadConfirmation={this.props.uploadConfirmation}
                isRecalled={this.props.isRecalled}
                taxSoftware={this.props.taxSoftwareType}
                returnAccessSettings={this.props.returnAccessSettings}
                availableUsers={this.state.availableUsers}
                selectedUsers={this.state.selectedUsers}
                selectedUserGroups={this.state.selectedUserGroups}
                availableUserGroups={this.state.availableUserGroups}
            />

        </div >
    }
}
export default UploadRecalledDocumentModal;
