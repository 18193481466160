import * as React from 'react';
import { Modal, Row, Col, Button, Tabs, Tab } from 'react-bootstrap';
import { ClientTypes, ITaxReturn } from '../TaxReturn';
import DirectDocumentAccessCode from './DirectDocuments/DirectDocumentAccessCode';
import { IOnehubAccessCodeModel } from '../OnehubAccessCodeModal';
import OnehubAccessCode from './Onehub/OnehubAccessCode';
import './view-access-code.style.scss';
let moment = require('moment');

interface IViewAccessCodeProps {
    taxReturn: ITaxReturn;
    oneHubAccessModel: IOnehubAccessCodeModel[];
    onCancel(): void;
    show: boolean;
    onGenerateOTP(documentId: number, clientType: ClientTypes, clientGUID: string, callback: () => void): void;
    onGenerateOnehubAccessCode(primaryEmailAddress: string, callback: () => void): void;
}

interface IViewAccessCodeState {
    key: string | null;
}

export class ViewAccessCode extends React.Component<IViewAccessCodeProps, IViewAccessCodeState> {
    constructor(props: IViewAccessCodeProps) {
        super(props);
        this.state = {
            key: 'direct_document',
        };
        this.getDifferenceInDate = this.getDifferenceInDate.bind(this);
    }
    UNSAFE_componentWillReceiveProps(props: IViewAccessCodeProps) {
        if (!props.show) {
            this.setState({ key: 'direct_document' });
        }
    }

    componentWillUnmount() {
        this.setState({ key: 'direct_document' });
    }

    private onCancel = () => {
        this.props.onCancel();
    };

    private handleTabSelect = (activeKey: string | null) => {
        this.setState({ key: activeKey });
    };

    private getDifferenceInDate(createdDate: Date) {
        let date1: any = new Date();
        let date2: any = moment.utc(createdDate).toDate();
        let minute = (date1.getTime() - date2.getTime()) / 60000;
        return minute;
    }

    public render() {
        return (
            <div>
                <Modal className="individual-view-access-code-modal" show={this.props.show} onHide={this.onCancel}>
                    <Modal.Header
                        closeButton
                        onClick={this.onCancel}
                        data-test-auto="C98D7824-8C63-4A74-95E6-4D3C73B08677"
                    >
                        <Modal.Title className="view-acces-code-header">Access Code</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Tabs
                            className="custom-tab"
                            fill
                            id="direct_document"
                            activeKey={this.state.key}
                            onSelect={key => this.handleTabSelect(key)}
                        >
                            <Tab
                                eventKey="direct_document"
                                title={
                                    <div>
                                        <span className="view-acces-code-tab">Direct Document</span>
                                    </div>
                                }
                                style={{ overflowX: 'hidden' }}
                            >
                                <div style={{ marginTop: '20px' }}>
                                    <DirectDocumentAccessCode
                                        taxReturn={this.props.taxReturn}
                                        show={this.props.show}
                                        onGenerateOTP={this.props.onGenerateOTP}
                                        getDifferenceInDate={this.getDifferenceInDate}
                                    />
                                </div>
                            </Tab>
                            {this.props.oneHubAccessModel?.length > 0 && (
                                <Tab
                                    eventKey="onehub_access"
                                    title={
                                        <div>
                                            <span className="view-acces-code-tab">Client Portal</span>
                                        </div>
                                    }
                                    style={{ overflowX: 'hidden' }}
                                >
                                    <OnehubAccessCode
                                        taxReturn={this.props.taxReturn}
                                        oneHubAccessModel={this.props.oneHubAccessModel}
                                        show={this.props.show}
                                        onGenerateOnehubAccessCode={this.props.onGenerateOnehubAccessCode}
                                    />
                                </Tab>
                            )}
                        </Tabs>
                    </Modal.Body>
                    <Modal.Footer>
                        <Row style={{ display: 'contents' }}>
                            <Col sm={4}>
                                <Button
                                    data-test-auto="DAE73B5C-9114-46EB-BDE7-0EC8BA0D4B0D"
                                    onClick={this.onCancel}
                                    className="close-button"
                                >
                                    <span className="close-button-text">Close</span>
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default ViewAccessCode;
