let moment = require('moment');
import React from  'react';
import * as ReactDom from 'react-dom';
import { RecycleGroupedExtensionsState } from '../../../Core/ViewModels/GroupExtensions/StoreModels';
import { IFilters, ReportFilterType } from '../../reports/Filters';
import {
	BootstrapTable, TableHeaderColumn, TextFilter, CustomFilter, SelectFilter,
	DateFilter, FilterComparator, SelectFilterOptionsType, CustomSelectProps,
	ToolBarProps, CustomFilterParameters, Options
} from 'react-bootstrap-table';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { DeliveredReturnsTableConstants } from '../../helper/Constants';
import { CheckBoxSize, CheckBoxComponent } from '../../common/CheckBoxComponent';
import { ITaxReturn } from '../../../components/common/TaxReturn';
import { RecycleTaxReturnExtensionsTable } from '../RecycleGroupedExtensions/RecycleTaxReturnExtensionsTable';
const isEqual = require("react-fast-compare");

export interface RecycleGroupedExtensionsTableProps {
	groupedExtensions: RecycleGroupedExtensionsState
	isLoading: boolean,
	onPageChange: any,
	onSortChange: any,
	onSearchChange: any,
	onFilterChange: any,
	pageNo: number,
	pageSize: number,
	totalRows: number;
	currentFilter: IFilters,
	filterType: ReportFilterType;

	onPageReload: () => void;
	refreshDelay?: boolean;
	clearSelection: () => void;

	onRowSelect: (row: any, isSelected: any, e: any) => void;
	onSelectAll: (isSelected: any, rows: any, e: any) => void;
	selectedRows: number[];

	onDocumentSelect: (Ids: number[]) => void;
	onDocumentUnSelect: (Ids: number[]) => void;

	selectedDocumentsId: number[];

}

export interface RecycleGroupedExtensionsTableStates {
	hideLink: boolean;
	expandedRows: number[];
}

export const DaysRangeOptionsList = {
	One_Day: '24 Hours',
	Two_Days: '2 Days',
	Three_Days: '3 Days',
	Five_Days: '5 Days',
	Seven_Days: '1 Week',
	Fifteen_Days: '15 Days',
	Thirty_Days: '30 Days'
};


export const TaxSoftwareList = {
	CCH: 'CCH',
	GoSystem: 'GoSystem',
	Lacerte: 'Lacerte',
	UltraTax: 'UltraTax',
}

export const SetAccessList = {
	1: 'Everyone',
	2: 'Restricted',
}
export class RecycleGroupedExtensionsTable extends React.Component<RecycleGroupedExtensionsTableProps, RecycleGroupedExtensionsTableStates> {
	public refClearSearchBtn: any;
	public filterChanged: boolean = false;
	public isAppiedFilter: boolean = false;

	constructor(props: RecycleGroupedExtensionsTableProps) {
		super(props);
		this.state = {
			hideLink: true,
			expandedRows: []
		};
	}

	shouldComponentUpdate(nextProps: RecycleGroupedExtensionsTableProps, nextState: RecycleGroupedExtensionsTableStates) {
		return (
			!isEqual(this.props.groupedExtensions.recycleGroupedExtensionsTableModel.recycleGroupedExtensions, nextProps.groupedExtensions.recycleGroupedExtensionsTableModel.recycleGroupedExtensions)
			|| (this.props.isLoading !== nextProps.isLoading)
			|| !isEqual(this.state, nextState)
			|| isEqual(this.props.selectedRows, nextProps.selectedRows)
			|| !isEqual(this.props.currentFilter, nextProps.currentFilter)
		);
	}


	private defaultType(cell: any, row: any) {
		return <div title={cell} className="ellipsis">{cell}</div>;
	}

	private getColumns = (): any[] => {
		return [
			{
				header: '',
				key: 'index',
				isKey: true,
				dataFormat: this.defaultType,
				columnClassName: '',
				dataSort: true,
				toolTip: false,
				isHidden: true,
				width: 'auto',
				filter: { type: 'TextFilter', placeholder: 'index', style: { font: 'bold' } } as TextFilter

			},
			{
				header: 'Group Name',
				key: 'groupName',
				isKey: false,
				dataFormat: this.defaultType,
				columnClassName: '',
				dataSort: true,
				toolTip: false,
				isHidden: false,
				width: 'auto',
				filter: { type: 'TextFilter', placeholder: 'Group Name', style: { font: 'bold' } } as TextFilter
			},
			{
				header: 'Tax Software',
				key: 'taxSoftware',
				isKey: false,
				dataFormat: this.defaultType,
				columnClassName: '',
				dataSort: false,
				toolTip: false,
				isHidden: false,
				width: 'auto',
				filter: { type: 'SelectFilter', placeholder: 'Select Tax Software', options: TaxSoftwareList } as SelectFilter
			},

			{
				header: 'Created By',
				key: 'createdBy',
				isKey: false,
				dataFormat: this.defaultType,
				columnClassName: '',
				dataSort: true,
				toolTip: false,
				isHidden: false,
				width: 'auto',
				filter: { type: 'TextFilter', placeholder: 'Created By', style: { font: 'bold' } } as TextFilter
			},
			{
				header: 'Date',
				key: 'createdOn',
				isKey: false,
				dataFormat: this.defaultType,
				columnClassName: '',
				dataSort: true,
				toolTip: false,
				isHidden: false,
				width: 'auto',
				filter: { type: 'SelectFilter', placeholder: 'Select Created Date Limit', options: DaysRangeOptionsList } as SelectFilter
			},
			{
				header: 'Set Access',
				key: 'setAccess',
				isKey: false,
				dataFormat: this.defaultType,
				columnClassName: '',
				dataSort: true,
				toolTip: false,
				isHidden: false,
				width: 'auto',
				filter: { type: 'SelectFilter', placeholder: 'Select Set Access', options: SetAccessList } as SelectFilter
			}
		];
	}

	renderShowsTotal(start: number, to: number, total: number) {
		return (
			<p>
				Showing {start} to {to} of {total} entries
			</p>
		);
	}

	private onFilterChange = (dataField: any) => {
		if (!this.filterChanged && !this.isAppiedFilter) {
			this.filterChanged = true;
			this.props.onFilterChange(dataField, this.props.filterType);
			this.filterChanged = false;
		}
	}

	private setNoContent() {
		if (this.props.isLoading) {
			return (<LoadingOverlay style={{ height: '400px' }}>
				<Loader loading={this.props.isLoading} />
			</LoadingOverlay>)
		} else {
			return DeliveredReturnsTableConstants.OtherMessage.NoReturnsFound
		}
	}

	private createCustomToolBar = (props: ToolBarProps) => {
		return (
			<div></div>
		);
	}

	createCustomCheckbox = (props: CustomSelectProps): any => {
		return (<CheckBoxComponent size={CheckBoxSize.sm}
			id={"recycle-grouped-returns-checkbox-" + props.rowIndex}
			indeterminate={props.indeterminate}
			checked={props.checked}
			disabled={props.disabled}
			onChange={(e: any) => props.onChange(e, props.rowIndex)}
			ref={(input: any) => {
				if (input) {
					input.indeterminate = props.indeterminate;
				}
			}}
			text={""}
			className="" />);
	}

	expandComponent = (row: any) => {
		const pageSize: number = 20;
		const paddingBottom = row.taxReturns && row.taxReturns.length > pageSize ? '0px' : '10px';
		return <div style={{ paddingTop: '10px', paddingBottom: paddingBottom, overflow: 'auto' }}>
			<RecycleTaxReturnExtensionsTable isLoading={false}
				taxReturns={row.taxReturns ? row.taxReturns as ITaxReturn[] : []}
				groupId={row.groupId as number}
				onDocumentSelect={this.props.onDocumentSelect}
				onDocumentUnSelect={this.props.onDocumentUnSelect}
				selectedDocumentsId={this.props.selectedDocumentsId}
				pageSize={pageSize}
			/>
		</div>
	}

	public render() {
		let columns = this.getColumns();

		const options = {
			onSortChange: this.props.onSortChange,
			onPageChange: this.props.onPageChange,
			sizePerPage: this.props.pageSize,
			onSearchChange: this.props.onSearchChange,
			page: this.props.pageNo,
			paginationShowsTotal: this.renderShowsTotal,
			onRowDoubleClick: function (row: any) {
			},
			onFilterChange: this.onFilterChange,
			noDataText: this.setNoContent(),
			toolBar: this.createCustomToolBar,
			nextPage: <span className="fa fa-angle-right" />,
			prePage: <span className="fa fa-angle-left" />,
			firstPage: <span className="fa fa-angle-double-left" />,
			lastPage: <span className="fa fa-angle-double-right" />,
		};

		const pagination = true;

		let selected: number[] = [];

		if (this.props.selectedRows.length > 0) {
			for (var i in this.props.selectedRows) {
				let rowIndex = this.props.selectedRows[i];
				selected.push(((this.props.pageNo - 1) * this.props.pageSize) + rowIndex);
			}
		}

		let selectRowProp: any = {
			mode: 'checkbox',
			onSelect: this.props.onRowSelect,
			onSelectAll: this.props.onSelectAll,
			selected: selected,
			customComponent: this.createCustomCheckbox,
			className: 'row-selected',
			clickToExpand: true

		};

		const searchEnable = true;


		let data: any = []

		if (this.props.groupedExtensions.recycleGroupedExtensionsTableModel.recycleGroupedExtensions) {
			data = this.props.groupedExtensions.recycleGroupedExtensionsTableModel.recycleGroupedExtensions &&
				this.props.groupedExtensions.recycleGroupedExtensionsTableModel.recycleGroupedExtensions.map((model, index) => {
					return {
						groupId: model.groupId,
						groupName: model.groupName,
						taxSoftware: model.taxSoftware,
						createdBy: model.createdBy,
						createdOn: moment(model.createdOn).format('MM/DD/YYYY'),
						button: 'Actions',
						index: ((this.props.pageNo - 1) * this.props.pageSize) + index,
						setAccess: model.setAccess,
						rowIndex: index,
						taxReturns: model.taxReturns
					}
				});

		}


		return <div className="pos-relative">

			<div data-test-auto="E2373DCE-29EB-4FA6-B881-3246E125B918">
				<BootstrapTable
					ref='table'
					data={data}
					remote={true}
					fetchInfo={{ dataTotalSize: this.props.totalRows }}
					options={options}
					striped
					hover={true}
					pagination={pagination}
					selectRow={selectRowProp}
					search={searchEnable}
					expandableRow={() => { return true; }}
					expandComponent={this.expandComponent}
				>
					{columns.map((value: any, index: any) => {
						return <TableHeaderColumn
							key={index}
							ref={value.key}
							isKey={value.isKey}
							dataField={value.key}
							hidden={value.isHidden}
							width={value.width}
							dataFormat={value.dataFormat}
							columnClassName={value.columnClassName}
							columnTitle={value.toolTip}
							filter={value.filter}
							dataSort={value.dataSort}
							expandable={value.expandable}
						> {
								(value.width === 'auto') ?
									<span title={value.header} className="table-text-sort">{value.header}</span> :
									value.header
							}

						</TableHeaderColumn>;
					})}
				</BootstrapTable>
			</div>
		</div>
	}
}
export default RecycleGroupedExtensionsTable;
