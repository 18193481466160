import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';

import { AppThunkAction } from '../';
import { actionTypes } from '../ActionTypes';
import { MessageDetails } from '../../Core/ViewModels/CompanySettings/SavedMessageViewModel';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { ExtensionCompanySettings } from '../../Core/ViewModels/Extension/ExtensionComponentModels';
import * as Constants from '../../../src/components/helper/Constants';
import * as ExtensionCompanyStore from '../settings/ExtensionCompanySettingsStore';
import { handleResponse } from '../Library';
import { LoggerFactory } from '../../Logger/LoggerFactory';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';

export interface ISavedMessageDictionary {
    [index: number]: SavedMessageState;
}

export interface SavedMessageStoreState {
    data: ISavedMessageDictionary;
    isLoading: boolean;
}

export interface SavedMessageState {
    messageDetails: MessageDetails;
}

interface RequestMessasgeListAction {
    type: actionTypes.REQUEST_SAVED_MESSAGES;
}

interface ReceiveMessageListAction {
    type: actionTypes.RECEIVE_SAVED_MESSAGES;
    messageDetails: MessageDetails;
    messageId: number;
}

interface DeleteMessageDetail {
    type: actionTypes.DELETE_SAVED_MESSAGE;
    messageDetail: MessageDetails;
}

interface HideLoadingSavedMessage {
    type: actionTypes.HIDE_LOADING_SAVED_MESSAGE;
}

type KnownAction =
    RequestMessasgeListAction |
    ReceiveMessageListAction |
    DeleteMessageDetail |
    NotificationAction |
    HideLoadingSavedMessage;

export const actionCreators = {
    requestSavedMessages: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (reload || !Object.keys(getState().savedMessage.data).length) {
            const fetchTask = fetch(`${API_BASE_URL}api/SavedMessage/GetAllMessages`, {
                method: 'GET',
                credentials: 'include'
            })
                .then(response => response.json() as Promise<MessageDetails[]>)
                .then((data) => {
                    dispatch({
                        type: actionTypes.HIDE_LOADING_SAVED_MESSAGE
                    })
                    data.map((value, index) => {
                        dispatch({
                            type: actionTypes.RECEIVE_SAVED_MESSAGES, messageDetails: value,
                            messageId: value.id
                        });
                    });
                }).catch(function (error) {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error
                    });
                    logger.trackError(`requestSavedMessages failed with error ${error.message}`);
                }); addTask(fetchTask);

            dispatch({ type: actionTypes.REQUEST_SAVED_MESSAGES });
        }
    },

    saveMessageDetail: (message: MessageDetails, extensionCompanySettings: ExtensionCompanySettings, isSetDefault: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/SavedMessage`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(message),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(handleResponse)
            .then((newId) => {
                let messageDetail = Object.assign({}, message);
                messageDetail.id = newId
                if (isSetDefault) {
                    extensionCompanySettings.defaultSettings.savedMessage = newId;
                    let action: any = ExtensionCompanyStore.ActionCreators.updateExtensionCompanySettings(extensionCompanySettings);
                    dispatch(action);
                    dispatch({
                        type: actionTypes.RECEIVE_SAVED_MESSAGES, messageDetails: messageDetail, messageId: newId
                    });
                }
                else {
                    dispatch({
                        type: actionTypes.RECEIVE_SAVED_MESSAGES, messageDetails: messageDetail, messageId: newId
                    });
                }
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.SavedMessageConstants.SaveMessageSuccess, statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error
                });
                logger.trackError(`saveMessageDetail failed for message: ${message}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    updateMessageDetail: (message: MessageDetails): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/SavedMessage`, {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(message),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.RECEIVE_SAVED_MESSAGES, messageDetails: message, messageId: message.id
                });
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.SavedMessageConstants.UpdateMessageSuccess,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error
                });
                logger.trackError(`updateMessageDetail failed for message: ${message}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    deleteMessageDetail: (message: MessageDetails): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/SavedMessage`, {
            method: 'DELETE',
            credentials: 'include',
            body: JSON.stringify(message),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.DELETE_SAVED_MESSAGE, messageDetail: message
                });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.SavedMessageConstants.DeleteMessageSuccess,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error
                });
                logger.trackError(`deleteMessageDetail failed for message: ${message}, with error ${error.message}`);
            });
        addTask(fetchTask);
    }
}

const savedMessageDic: ISavedMessageDictionary = [];

const unloadedState: SavedMessageStoreState =
    {
        data: { ...savedMessageDic },
        isLoading: true
    } as SavedMessageStoreState;

export const reducer: Reducer<SavedMessageStoreState> = (state: SavedMessageStoreState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_SAVED_MESSAGES:
            return {
                ...unloadedState
            } as SavedMessageStoreState;
        case actionTypes.RECEIVE_SAVED_MESSAGES:
            let received: SavedMessageStoreState = { ...state };
            received.data[action.messageId] = { messageDetails: action.messageDetails }
            received.isLoading = false;
            return received;
        case actionTypes.DELETE_SAVED_MESSAGE:
            let deleteMessage: SavedMessageStoreState = { ...state }
            if (deleteMessage.data[action.messageDetail.id]) {
                delete deleteMessage.data[action.messageDetail.id];
                deleteMessage.isLoading = false;
            }
            return deleteMessage;
        case actionTypes.HIDE_LOADING_SAVED_MESSAGE:
            return { ...unloadedState, isLoading: false };
    }
    return state || unloadedState;
};