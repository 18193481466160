import React from  'react';
import * as  ReactDOM from 'react-dom';
import * as Bootstrap from 'react-bootstrap';
import { IClientMessage } from '../../../../Core/ViewModels/CompanySettings/ClientInstructionViewModel';

interface IClientMessageViewProps {
    selectedMessage: IClientMessage;
}

export class ClientMessageView extends React.Component<IClientMessageViewProps, {}>{
    constructor(props: IClientMessageViewProps) {
        super(props);
    }

    public render() {
        return (
            <div className="col-lg-12 col-sm-12" style={{ width: '-webkit-fill-available', lineHeight: '3em', padding: '0px' }}>
                <div ><h4>{this.props.selectedMessage.name}</h4></div>
                <textarea rows={7} value={this.props.selectedMessage.text} className="form-control" disabled={true} />
             </div>
        )
    }
}