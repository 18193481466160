import { actionTypes } from "src/store/ActionTypes";
import { ExtensionCompanySettings, initialExtensionCompanySettings } from "./ExtensionComponentModels";

export interface ExtensionSettingsStoreState {
    companySettings: ExtensionCompanySettings;
    loading: boolean;
}

export const unloadedExtensionSettingsStoreState: ExtensionSettingsStoreState = {
    companySettings: initialExtensionCompanySettings,
    loading: false,
};

export interface ExtensionCompanySettingsLoaderAction {
    type: actionTypes.EXTENSION_COMPANY_SETTINGS_LOADER;
    loading: boolean;
}

export interface ReceiveExtensionCompanySettings {
    type: actionTypes.RECEIVE_EXTENSION_COMPANY_SETTINGS;
    data: ExtensionCompanySettings;
}

export interface UpdateExtensionCompanySettings {
    type: actionTypes.UPDATE_EXTENSION_COMPANY_SETTINGS;
    update: ExtensionCompanySettings;
}
