import React from 'react';
import { RouteComponentProps } from 'react-router';
import 'isomorphic-fetch';
import { CustomizeDeliveryReports } from './GeneralSettingsComponents/CustomizeDeliveredReports';
import * as CustomColumnStore from '../../store/common/CustomColumnStore';
import { logClientEvent } from '../helper/LoggerHelper';
import { RBACKeys } from '../helper/RbacConstants';
import { PrintToPDFSetting } from './GeneralSettingsComponents/PrintToPDFSetting';
import { ICompanySettings, TransmittalDownloadOption, VoucherDownloadOption } from 'src/Core/ViewModels/Company/CompanySettingsViewModel';
import { ExtensionCompanySettings, initialExtensionCompanySettings } from 'src/Core/ViewModels/Extension/ExtensionComponentModels';
import * as ExtensionStoreModels from 'src/Core/ViewModels/Extension/ExtensionStoreModels';

import * as ExtensionCompanySettingsStore from 'src/store/settings/ExtensionCompanySettingsStore';
import { ReturnsDownloadOptions } from './GeneralSettingsComponents/ReturnsDownloadOptions';
import SettingsHeader from '../common/settings/SettingsHeader';
import OverlayLoader from '../helper/OverlayLoader';
import { LogEventConstants } from '../helper/LogEventConstants';


type CompanySettingsProps =
    {
        customColumnData: CustomColumnStore.ICustomColumnState,
        extensionCompanyData: ExtensionStoreModels.ExtensionSettingsStoreState,
    }
    & typeof CustomColumnStore.actionCreators
    & typeof ExtensionCompanySettingsStore.ActionCreators
    & RouteComponentProps<{}>;

interface IGeneralSettingsState {
    showState: boolean;
    columnValues: IColumnValues;
    customColumn: ICustomColumn;
    newColumnValue: string;
    cuastomColumnTitleCurrentValue: string;
    disableCustomColumnDeleteButton: boolean;
    disableCustomColumnEditButton: boolean;
    extensionCompanySettings: ExtensionCompanySettings;

}

export interface IColumnValues
{
    id: number;
    value: string;
    customColumn: number;
    inUseCount: number;
}

export interface ICustomColumn
{
    id: number;
    header: string;
    customColumnValues: IColumnValues[];
}

const pageTitle = "GeneralSetting";
export class GeneralSettings extends React.Component<CompanySettingsProps, IGeneralSettingsState> {
    constructor(props: any) {
        super(props);
        this.state = {

            showState: false,
            columnValues: {
                id: 0,
                customColumn: 0,
                value: "",
                inUseCount: 0,
            },
            customColumn: {
                header: "",
                id: 0,
                customColumnValues: []
            },

            newColumnValue: "",

            cuastomColumnTitleCurrentValue: "",
            disableCustomColumnDeleteButton: false,
            disableCustomColumnEditButton: false,
            extensionCompanySettings: initialExtensionCompanySettings,

        }
        ///////////////////////////// Custom column popup/////////////////////////
        this.OpenCustomColumnPopup = this.OpenCustomColumnPopup.bind(this);
        this.onHide = this.onHide.bind(this);

    }

    componentWillMount() {
        this.props.requestCustomColumn(true);
        this.props.requestExtensionCompanySettings();
        logClientEvent(
            `${LogEventConstants.Common.Settings.Open} ${pageTitle}`,
            { count: 1, page: pageTitle }
        );
    }
    private OnDeleteCustomColumnHeader = (customColumn: ICustomColumn) =>
    {
        this.props.deleteCustomColumnHeader(customColumn);
        this.logUpdateSettings();

    }
    OpenCustomColumnPopup()
    {
        this.setState({
            showState: true
        })
    }

    onHide()
    {
        this.setState({
            showState: false,
        })
    }
    updateCustomColumn = (customColumn: ICustomColumn) =>
    {
        this.props.updateCustomColumn(customColumn);
        this.logUpdateSettings();
    }
    updateCustomColumnValue = (customColumnValue: IColumnValues, showNotification: boolean = true) =>
    {
        this.props.updateCustomColumnValue(customColumnValue, showNotification, RBACKeys.Settings.settings);
        this.logUpdateSettings();
    }

    addCustomColumnValue = (customColumnValue: IColumnValues) =>
    {
        this.props.addCustomColumnValue(customColumnValue);
        this.logUpdateSettings();
    }
    deleteCustomColumnValue = (customColumnValue: IColumnValues) =>
    {
        this.props.deleteCustomColumnValue(customColumnValue);
        this.logUpdateSettings();
    }
    logUpdateSettings = () =>
    {
        logClientEvent(
            `${LogEventConstants.Common.Settings.Update} ${pageTitle}`,
            { count: 1, page: pageTitle }
        );

    }


    handleApplyButton = (event?: any, resourceId?: string) =>
    {

        const temp = this.props.extensionCompanyData.companySettings;
        this.props.updateExtensionCompanySettings(temp);

        logClientEvent(
            `${LogEventConstants.Common.Settings.Update} ${pageTitle}`,
            { count: 1, page: pageTitle }
        );
    }

    ///////////////////////////// Returns Download Options start ////////////////////////
    handleIncludeInTaxReturnPDF = () =>
    {
        const temp = this.props.extensionCompanyData.companySettings;
        temp.deliverySettingsModel.transmittalsDownloadOptions = TransmittalDownloadOption.IncludeTransmittalInTaxReturnPDF;
        this.setState({
            extensionCompanySettings: temp
        })
    }

    handleCreateSeperatePDF = () =>
    {
        const temp = this.props.extensionCompanyData.companySettings;
        temp.deliverySettingsModel.transmittalsDownloadOptions = TransmittalDownloadOption.CreateSeparateTransmittalPDF;
        this.setState({
            extensionCompanySettings: temp
        })
    }

    handleSeperateByQuarterlyDueDates = () =>
    {
        const temp = this.props.extensionCompanyData.companySettings;
        temp.deliverySettingsModel.vouchersDownloadOptions = VoucherDownloadOption.SeparateByQuarterlyDueDates;
        this.setState({
            extensionCompanySettings: temp
        })
    }

    handleGroupInSinglePDF = () =>
    {
        const temp = this.props.extensionCompanyData.companySettings;
        temp.deliverySettingsModel.vouchersDownloadOptions = VoucherDownloadOption.GroupInSinglePDF;
        this.setState({
            extensionCompanySettings: temp
        })
    }
    render()
    {
        const { companySettings, loading } = this.props.extensionCompanyData;

        return <div id="divGeneralSettings" className='company-settings'>
            <OverlayLoader show={companySettings && loading} text={"Loading..."} />
            <SettingsHeader
                handleApplyButton={this.handleApplyButton}
                header="General"
                showPopOver={false}
            />
            
            <div>
                {
                    this.props.extensionCompanyData.companySettings != undefined ?
                        <div>
                            <ReturnsDownloadOptions
                                companySettings={this.props.extensionCompanyData.companySettings as ExtensionCompanySettings}
                                handleCreateSeperatePDF={this.handleCreateSeperatePDF}
                                handleGroupInSinglePDF={this.handleGroupInSinglePDF}
                                handleIncludeInTaxReturnPDF={this.handleIncludeInTaxReturnPDF}
                                handleSeperateByQuarterlyDueDates={this.handleSeperateByQuarterlyDueDates}
                            />
                            <hr />
                        </div>
                        : ""
                }
            </div>
            <div>
                {
                    this.props.customColumnData?.customColumn != undefined ?
                        <CustomizeDeliveryReports
                            overlayLoading={this.props.customColumnData.isLoading}
                            overlayMessage={this.props.customColumnData.message}
                            customColumn={this.props.customColumnData.customColumn}
                            HandleCustomizeDeliveredreportsEditButton={this.OpenCustomColumnPopup}
                            updateCustomColumn={this.updateCustomColumn}
                            updateCustomColumnValue={this.updateCustomColumnValue}
                            addCustomColumnValue={this.addCustomColumnValue}
                            deleteCustomColumnValue={this.deleteCustomColumnValue}
                            deleteCustomColumnHeader={this.OnDeleteCustomColumnHeader}
                        /> : ""
                }
            </div>
            <hr />
            <div>
                <PrintToPDFSetting />

            </div>
        </div>;

    }
}