import React from  'react';
import { RBACKeys } from 'src/components/helper/RbacConstants';
import { ExtensionCompanySettings } from '../../../Core/ViewModels/Extension/ExtensionComponentModels';
import { CheckBoxComponent } from '../../common/CheckBoxComponent';
import { TabEFileConstants } from '../../helper/Constants'

interface IEfileFormSettings {
    companySettings: ExtensionCompanySettings;
    onToggleEfileDateSettings(event: any): void;
    onToggleEfileNameAndTitleSettings(event: any): void;
}
export class EfileFormSettings extends React.Component<IEfileFormSettings> {
    constructor(props:any) {
        super(props);
    }


    public render() {
        return <div className="new-main-content">
            <h3>E-File forms Settings</h3>
            <div className="marL20">
                <CheckBoxComponent
                    id={"EfileDateControl"}
                    text={TabEFileConstants.Info.DoNotInsertDates}
                    onChange={this.props.onToggleEfileDateSettings}
                    checked={this.props.companySettings.signatureSettingsModel ?
                        this.props.companySettings.signatureSettingsModel.isDoNotDisplayDatesEnabled : true}
                    datatestAuto={"B80BF7B7-DF1E-4E3E-8A4A-FA940DC0693F"}
                    resourceId={RBACKeys.Settings.settings}
                />
                <CheckBoxComponent
                    id={"EfileNameTitleControl"}
                    text={TabEFileConstants.Info.DoNotInsertNameAndTitle}
                    onChange={this.props.onToggleEfileNameAndTitleSettings}
                    checked={this.props.companySettings.signatureSettingsModel ?
                        this.props.companySettings.signatureSettingsModel.isDoNotDisplayNameAndTitleEnabled : true}
                    datatestAuto={"6FD8F5CC-05B7-4B0E-A894-B730227DC58B"}
                    resourceId={RBACKeys.Settings.settings}
                />
            </div>
        </div>;
    }
}

export default EfileFormSettings