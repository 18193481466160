import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { IFilters, ReportFilterType } from '../../components/reports/Filters';
import { actionTypes } from '../ActionTypes';
import { handleResponse } from '../Library';
import * as Notification from '../common/NotificationStore';
import { ReportFilterConstants } from '../../components/helper/Constants';
import { SessionStore } from '../../components/helper/HelperFunctions';
import { LoggerFactory } from '../../Logger/LoggerFactory';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';

export interface IFilterState {
    filters: IFilters[],
    isLoading: boolean;
}

export interface RequestReportFilterAction {
    type: actionTypes.REQUEST_REPORT_FILTER;
}

export interface ReceiveReportFilterAction {
    type: actionTypes.RECEIVE_REPORT_FILTER,
    filters: IFilters[],
    filterType: ReportFilterType
}
export interface SaveReportFilterAction {
    type: actionTypes.SAVE_REPORT_FILTER,
    filter: IFilters
}
export interface UpdateReportFilterAction {
    type: actionTypes.UPDATE_REPORT_FILTER,
    filter: IFilters
}
export interface DeleteReportFilterAction {
    type: actionTypes.DELETE_REPORT_FILTER;
    filterType: ReportFilterType
    name: string,
}
export interface AddDefaultFilter {
    type: actionTypes.ADD_DEFAULT_FILTER,
    name: string
}
export interface RemoveDefaultFilter {
    type: actionTypes.REMOVE_DEFAULT_FILTER,
    name: string
}

type KnownAction = RequestReportFilterAction | ReceiveReportFilterAction | SaveReportFilterAction | UpdateReportFilterAction
    | DeleteReportFilterAction | AddDefaultFilter | RemoveDefaultFilter | Notification.NotificationAction;

type DispatchActions = RequestReportFilterAction | ReceiveReportFilterAction | UpdateReportFilterAction | SaveReportFilterAction
    | DeleteReportFilterAction | AddDefaultFilter | RemoveDefaultFilter;


export const actionCreators = {
    getAllReportFilter: (reload: boolean = false, filterType: ReportFilterType): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (reload || !(getState().reportFilters)) {
            const fetchTask = fetch(`${API_BASE_URL}api/Filters/` + filterType, {
                method: 'GET',
                credentials: 'include'
            })
                .then(response => response.json() as Promise<IFilters[]>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_REPORT_FILTER, filters: data, filterType: filterType });
                })
                .catch(function (error) {
                    dispatch({ type: actionTypes.NOTIFICATION, statusType: Notification.StatusType.Error, statusMessage: ReportFilterConstants.StatusMessage.GetAllErrorMessage });
                    logger.trackError(`getAllReportFilter failed with error ${error.message}`);
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_REPORT_FILTER });
        }
    },

    addReportFilter: (name: string, filter: IFilters, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        filter.isDefaultFilter = false;
        let options: any = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(filter)
        };

        const fetchTask = fetch(`${API_BASE_URL}api/Filters`, options)
            .then(handleResponse)
            .then(() => {
                filter.name = name;
                dispatch({ type: actionTypes.SAVE_REPORT_FILTER, filter: filter });
                dispatch({ type: actionTypes.NOTIFICATION, statusType: Notification.StatusType.Success, statusMessage: ReportFilterConstants.StatusMessage.SavedSuccessMessage });
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusType: Notification.StatusType.Error, statusMessage: ReportFilterConstants.StatusMessage.SavedErrorMessage });
                logger.trackError(`addReportFilter failed for name: ${name}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    updateReportFilter: (name: string, filter: IFilters, callback?: (name: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let options: any = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(filter)
        };

        const fetchTask = fetch(`${API_BASE_URL}api/Filters`, options)
            .then(handleResponse)
            .then(() => {
                filter.name = name;
                dispatch({ type: actionTypes.UPDATE_REPORT_FILTER, filter: filter });
                dispatch({ type: actionTypes.NOTIFICATION, statusType: Notification.StatusType.Success, statusMessage: ReportFilterConstants.StatusMessage.UpdateSuccessMessage });
                if (callback) {
                    callback(name);
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusType: Notification.StatusType.Error, statusMessage: ReportFilterConstants.StatusMessage.UpdateErrorMessage });
                logger.trackError(`updateReportFilter failed for name: ${name}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    deleteReportFilter: (name: string, filterType: ReportFilterType, callback?: (name: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(`${API_BASE_URL}api/Filters`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body:JSON.stringify({
                name,
                filterType
            })
        })
            .then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.DELETE_REPORT_FILTER, name: name, filterType: filterType });
                dispatch({ type: actionTypes.NOTIFICATION, statusType: Notification.StatusType.Success, statusMessage: ReportFilterConstants.StatusMessage.DeleteSuccessMessage });
                if (callback) {
                    callback(name);
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusType: Notification.StatusType.Error, statusMessage: ReportFilterConstants.StatusMessage.DeleteErrorMessage });
                logger.trackError(`deleteReportFilter failed for name: ${name}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    addDefaultFilter: (name: string, filterType: ReportFilterType, callback?: (name: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let options: any = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body:JSON.stringify({
                name,
                filterType
            })
        };
        const fetchTask = fetch(`${API_BASE_URL}api/Filters/adddefaultfilter`, options)
            .then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.ADD_DEFAULT_FILTER, name: name });
                if (callback) {
                    callback(name);
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusType: Notification.StatusType.Error, statusMessage: ReportFilterConstants.StatusMessage.UpdateErrorMessage });
                logger.trackError(`addDefaultFilter failed for name: ${name}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },
    removeDefaultFilter: (name: string, filterType: ReportFilterType, callback?: (name: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let options: any = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body:JSON.stringify({
                name,
                filterType
            })
        };
        const fetchTask = fetch(`${API_BASE_URL}api/Filters/removedefaultfilter`, options)
            .then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.REMOVE_DEFAULT_FILTER, name: name });
                if (callback) {
                    callback(name);
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusType: Notification.StatusType.Error, statusMessage: ReportFilterConstants.StatusMessage.UpdateErrorMessage });
                logger.trackError(`removeDefaultFilter failed for name: ${name}, with error ${error.message}`);
            });
        addTask(fetchTask);
    }
};


const unloadstate: IFilterState = { filters: [], isLoading: false } as IFilterState;

let convertDate = (data: any): any => {
    if (data && data != "") {
        return new Date(data);
    } else { return data; }
}

let JSONParserDateConverter = (key: any, value: any): any => {
    if (key == 'lastReminder') {
        return convertDate(value);
    }
    else {
        return value;
    }
}
let getProxyFilterName = (filterType: ReportFilterType): string => {

    return ReportFilterType[filterType].toUpperCase() + ReportFilterConstants.DefaultFilterBuilder;
}
export const reducer: Reducer<IFilterState> = (state: IFilterState = unloadstate, incomingAction: Action) => {
    const action = incomingAction as DispatchActions;
    switch (action.type) {
        case actionTypes.REQUEST_REPORT_FILTER:
            let cleanReport: IFilterState = { ...state };
            cleanReport.filters = [];
            return cleanReport;
        case actionTypes.RECEIVE_REPORT_FILTER:
            let receiveFilterName = getProxyFilterName(action.filterType);

            let receiveFilters: IFilters[] = action.filters;
            receiveFilters.map((item: IFilters) => {
                item.isMasterFilter = false;
            });
            if (SessionStore.isExists(receiveFilterName)) {
                try {
                    let defaultFilter = JSON.parse(SessionStore.get(receiveFilterName));
                    defaultFilter.name = receiveFilterName;
                    defaultFilter.isMasterFilter = true;
                    receiveFilters = receiveFilters.concat(defaultFilter);
                } catch (exp) {
                    SessionStore.remove(receiveFilterName);
                }
            }
            return {
                filters: receiveFilters,
                isLoading: false,
            } as IFilterState;

        case actionTypes.SAVE_REPORT_FILTER:
            let saveReport: IFilterState = { ...state };
            saveReport.filters.push(action.filter);
            saveReport.isLoading = false;

            saveReport.filters.forEach((value: IFilters) => {
                value.isMasterFilter = false;
            });
            return saveReport;

        case actionTypes.UPDATE_REPORT_FILTER:
            let updated: IFilterState = { ...state };
            updated.filters = updated.filters.map((item, i) => {
                return item.name == action.filter.name ? action.filter : item;
            });
            updated.filters.forEach((value: IFilters) => {
                value.isMasterFilter = false;
            });
            updated.isLoading = false;
            return updated;

        case actionTypes.DELETE_REPORT_FILTER:
            let deleteReport: IFilterState = {...state, filters:[...state.filters] };
            deleteReport.filters.splice(deleteReport.filters.findIndex(x => x.name == action.name), 1);
            deleteReport.filters.forEach((value: IFilters) => {
                value.isMasterFilter = false;
            });
            return deleteReport;

        case actionTypes.ADD_DEFAULT_FILTER:
            let addedDefautFilter: IFilterState = { ...state };
            addedDefautFilter.filters.forEach((item: IFilters) => {
                item.isDefaultFilter = false;
                item.isMasterFilter = false;
                if (item.name == action.name) {
                    item.isDefaultFilter = true;
                }
            });
            return addedDefautFilter;
        case actionTypes.REMOVE_DEFAULT_FILTER:
            let allFilters: IFilterState = { ...state };
            allFilters.filters.map((item, i) => {
                item.isDefaultFilter = false;
                item.isMasterFilter = false;
            });
            return allFilters;
        default:

            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }
    return state || unloadstate;
};