import React from  'react';
import { /*Panel,*/ Col, Row, Button, FormLabel } from 'react-bootstrap';
import { Card, Accordion } from 'react-bootstrap';
import { IUserModel } from '../../../../Core/ViewModels/User/UserViewModel';
import * as TaxDocument from '../../../common/TaxReturn';

interface IApproveForDelivery {
    allUsersList: IUserModel[],
    cancelFinish: () => void;
    approveForDelivery: () => void;
    selectedUser: number
    onChangeUser(event: any): void;
}

export class ApproveForDelivery extends React.Component<IApproveForDelivery, {}> {
    public render() {
        return <div className="padT04">
            {
                <Accordion defaultActiveKey={"0"} style={{ height: "200px" }}>
                    <Card className="marR20 border-radius-04">
                            <Card.Body>
                        <Row className="marB15">
                            <Col sm={12}>
                            </Col>
                        </Row>
                        <Row className="marB15">
                            <Col className="text-left" sm={12} as={FormLabel}>
                                Please select a person
                    </Col>
                        </Row>
                        <Row className="marB15">
                            <Col sm={12}>
                                <select className="form-control"
                                    id="ddl"
                                    value={this.props.selectedUser}
                                    onChange={this.props.onChangeUser}
                                    data-test-auto="0F977C9E-557E-4D36-B2D3-20946C82426A">
                                    <option key={0} value={0}>---Select---</option>
                                    {this.props.allUsersList.map((user, index) => {
                                        return <option key={index} value={user.id}>{user.firstName + " " + user.lastName}</option>
                                    })}
                                </select>
                            </Col>
                        </Row>
                        <div>
                            <Col sm={12}>
                                <Button type="button" variant="info" className="pull-right" data-test-auto="195D89FF-87E4-4A26-8F01-D8F58B237077"
                                    onClick={this.props.approveForDelivery}
                                ><i className="fa fa-check text-secondary" style={{ paddingRight: "10px" }}></i> Approve</Button>
                                    <Button type="button" variant="light" className="pull-right marR05" data-test-auto="200E180C-30FE-411A-928F-8D23819501C1"
                                    onClick={this.props.cancelFinish}
                                ><i className="glyphicon glyphicon-remove" style={{ paddingRight: "10px" }}></i>Cancel</Button>
                            </Col>
                        </div>
                            </Card.Body>
                    </Card>
                </Accordion>
            }
            </div>
    }
}

export default ApproveForDelivery;