export const LogEventConstants = {
    Common: {
        Filter:
        {
            ApplySpecificFilter: "Apply SpecificFilter",
            SaveFilter: "Save Filter",
            UpdateFilter: "Update Filter",
            DeleteFilter: "Delete Filter",
            SetDefaultFilter: "Set DefaultFilter",
            RemoveDefaultFilter: "Remove DefaultFilter",
            FilterChange: "Filter Change",
            ClearFilter: "Clear Filter"
        },
        ExportExcle: "Export Excle",
        RefreshExtensions: "Refresh Extensions",
        AssignMultiExtensionsToUser: "Assign MultiExtensionsToUser",
        SetAccessMultiExtensions: "SetAccess MultiExtensions",
        EditClientInfo: "Edit ClientInfo",
        SendEmail: "Send Email",
        ArchiveExtension: "Archive Extension",
        RestoreExtension: "Restore Extension",
        ExtensionMultiStatusUpdate: "Extension MultiStatusUpdate",
        SendMultiReminder: "Send MultiReminder",
        ResendAccessLink: "Resend AccessLink",
        RequestClientTracking: "Request ClientTracking",
        RecallExtension: "Recall Extension",
        UpdateVoucherReminder: "Update VoucherReminder",
        ViewAccessCode: "View AccessCode",
        UpdateExtensionRetention: "Update ExtensionRetention",
        DownloadExtensions: "Download  Extensions",
        DownloadEsign: "Download Esign",
        OpenClientView: "Open ClientView",
        DeleteExtension: "Delete Extension",
        DeleteMultiExtensions: "Delete MultiExtensions",
        UnlockExtension: "Unlock Extension",
        UnlockMultiExtension: "Unlock MultiExtension",
        UserLogout: "User Logout",
        RecallUpload: "Recall Upload",
        Upload: "Upload",
        SendExtensiontoERO: "Send ExtensiontoERO",
        DeliverProcessExtension: "Deliver ProcessExtension",
        RefreshRecycleBin: "Refresh RecycleBin",
        ReportProblem: "Report Problem",
        ProcessReturn: {
            OpenProcessExtension: "Open Extension Process",
            CloseProcessExtension: "Close Extension Process"
        },
        Settings: {
            Open: "Open",
            Update: "Update",
            ReportColumnUpdated: "Report ColumnUpdated"
        }
    }
};

export const DocumentEventLog = {
    Name : "Extension Document Event",
    UploadPopup : "Upload Popup",
    Open : "Open",
    Close : "Close",
    DocumentUploadSuccess : "Source Document Upload Completed Successfully",
    DocumentInitialStatusUpdateSuccess : "Source Document Initial Status Update Completed Successfully",
    PageAddedToGroup : "Page Added To Group",
    ManuallyAddedVouchers : "Manually Added Vouchers",
    ChangedEROSignature : "Changed ERO Signature",
    SignatureControlAdded : "Signature Control Added",
    AdditionalEsignDocumentAdded : "Additional Esign Document Added",
    AdditionalEsignDocumentDeleted : "Additional Esign Document Deleted",
    AdditionalDocumentSignatureControlAdded : "Signature Control Added To Additional Document",
    AddNewClientInCM: "Add New Client In Client Management",
    UpdateClientInCM: "Update Client In Client Management",
    SaveProcessReturnInfo: "Save Process Return Info",
    SendExtensionToERO: "Send Extension To ERO",
    SendExtensionForReview: "Send Extension For Review",
    SendExtensionApproveForDelivery: "Send Extension Approve For Delivery",
    DeliverProcessExtensionToClient: "Deliver Process Extension To Client",
    DeliverExtesionWithTaxCaddy: "Deliver Extesion With Tax Caddy",
    GenerateTaxpayerView: "Generate Taxpayer View",
    SendExtensionForPaperDelivery: "Send Extension For Paper Delivery"
};