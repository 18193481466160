import React from 'react';
import { Card, Accordion, Button } from 'react-bootstrap'
import { LoadingOverlay, Loader } from 'react-overlay-loader';


export interface IBookMarksTreeProps {
    sectionTitle?: any;
    bookMarksLoading?: boolean;
    bodyContent: any;
    headerContent?: any;
    isNoBodyContent: boolean;
}

export class BookmarkTree extends React.Component<IBookMarksTreeProps, {}> {
    constructor(props: IBookMarksTreeProps) {
        super(props);
    }

    public render() {
        let _self = this;
        return <div className="bookmarksPane" style={{ display: "initial" }}>
            {
                <Accordion defaultActiveKey="0" style={{ marginRight: "1px" }}>
                    <Card>
                        <Card.Header style={{ padding: "10px" }}>
                            <Card.Title as="h4">
                                <Accordion.Toggle className="acordian-toggle-anchor" as={Button} variant="link" eventKey="0">
                                    {this.props.sectionTitle}
                                </Accordion.Toggle>
                            </Card.Title>
                            <span className="pull-right">
                                {
                                    _self.props.headerContent && _self.props.headerContent
                                }
                            </span>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body style={{ padding: 0 }}>
                                <LoadingOverlay style={{ height: '100%' }}>
                                    {
                                        _self.props.isNoBodyContent == true ?
                                            <div className="textalign-center">No Content</div> : _self.props.bodyContent
                                    }
                                    <Loader loading={_self.props.bookMarksLoading ? true : false} />
                                </LoadingOverlay>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            }
        </div>
    }
}