import './LocationMimatchActionModal';
import React from "react";
import { Modal, Container, Button,Alert } from "react-bootstrap";
import CloseIcon from "src/images/CloseIcon";
import { ILocationMismatchActionModalProps } from "./LocationMismatchAction.model"
import "src/assets/custom/LocationMismatchActionModal.css"

const LocationMismatchActionModal: React.FC<ILocationMismatchActionModalProps> = (props) => {
    return(
<Modal show={props.show} onHide={props.onCancel} className="location-mismatch-modal">
<Modal.Header>
                <div>
                    <Modal.Title>Attention</Modal.Title>
                </div>
                <div data-test-auto="3AE0C223-EB6A-4F04-8BBC-72FD908CB985" className="closeIcon" onClick={props.onCancel}>
                    <CloseIcon />
                </div>
            </Modal.Header>
            <Modal.Body className="show-grid">
                <div className="warning-alter">
                    <p>
                        By changing Office Location to {props.location} you will no longer have access to it as you are not
                        assigned to that office. Press Continue to change office location or Cancel to keep the current office
                        location.
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button data-test-auto="AF335F34-D26C-456E-A6DC-8F1665834793" variant="secondary" onClick={props.onCancel}>
                    Cancel
                </Button>
                <Button onClick={props.onContinue} data-test-auto="62ECF9B0-E98D-4A3A-8961-3F8D0DDA84D3" variant="primary">
                    Continue
                </Button>
            </Modal.Footer>
           </Modal>

    );
}

export default LocationMismatchActionModal;