import React from  'react';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { IUserModel } from '../../Core/ViewModels/User/UserViewModel';
import { IDocumentAccess } from '../common/TaxReturn';
import { ProSystemUploadDocumentModal } from './UploadDocument/TaxSoftwareTypeUpload/ProSystemUploadDocumentModal';
import { MultiFileUploadDocumentModal } from './UploadDocument/TaxSoftwareTypeUpload/MultiFileReturns/MultiFileReturnUploadDocumentModal';
import { GroupAccess, GroupInfo } from '../../Core/ViewModels/GroupExtensions/ComponentModels';
import { TaxSoftwareType } from 'src/Core/ViewModels/Company/SuiteCompanySettings/SuiteTaxSoftwareComponentModel';
import { IReturnAccessSettingsModel } from 'src/Core/ViewModels/Company/CompanySettingsViewModel';
import { IUserDataFromDB, IUserGroupDataFromDB } from './SetAccess/SetAccessCommonModal.model';

interface UploadModalProps {
    taxSoftware: TaxSoftwareType;
    onShow: boolean;
    onHide(): void;
    extensionUsers: IUserModel[];
    partners: IUserModel[];
    selectedERO: number;
    isRedirect?: boolean;
    processTaxReturn: (url: string, callback: () => void) => void;
    getUploadLink: (url: string, callback?: () => void) => void;
    submitTaxReturn: (url: string, taxData: string, callback: () => void) => void;
    onPageReload: () => void;
    defaultGroup?: string;
    groupInfo?: GroupInfo[];
    groupAccess?: GroupAccess[];
    updateGroupInfoState: (reload: boolean) => void;
    returnAccessSettings:IReturnAccessSettingsModel;
    getTaxDocumentsAccessMaps: (ids: number[], callback?: (result: IDocumentAccess) => void ) => void;
}
export interface UploadModelstate {
    EROs: IUserModel[];
    defaultERO: number;
    availableUsers: IUserDataFromDB[];
    selectedUsers: IUserDataFromDB[];
    availableUserGroups: IUserGroupDataFromDB[];
    selectedUserGroups: IUserGroupDataFromDB[];
}

export interface TaxReturnUploadData {
    number?: number;
    clientName?: string;
    name?: string;
    progressBar?: number;
    size?: string;
    status?: string;
    clientId?: string;
    partnerId?: number;
    setAccess?: string;
    sasGuid?: string;
    gridRowDisable: boolean;
    documentAccess: IDocumentAccess;
}

export class UploadDocumentModal extends React.Component<UploadModalProps, UploadModelstate> {
    constructor(props: UploadModalProps) {
        super(props);
        this.state = {
            defaultERO: 0,
            EROs: [],
            availableUsers: [],
            selectedUsers: [],
            availableUserGroups: [],
            selectedUserGroups: []
        };
    }
    componentWillMount() {
        this.props.getTaxDocumentsAccessMaps([],(result: IDocumentAccess) => {
            this.setState({
                availableUserGroups: result.availableUserGroups,
                availableUsers: result.availableUsers,
                selectedUserGroups: result.selectedUserGroups,
                selectedUsers: result.selectedUsers
            });
        });
    }

    componentWillReceiveProps(nextProps: UploadModalProps) {
        if (nextProps.extensionUsers && nextProps.extensionUsers.length > 0) {
            this.setState({ EROs: nextProps.partners, defaultERO: this.props.selectedERO});
        }
    }

    public render() {
        return <div>
            <ProSystemUploadDocumentModal
                onShow={this.props.onShow
                    && this.props.taxSoftware == TaxSoftwareType.ProSystems
                }
                onHide={this.handleClose}
                EROs={this.state.EROs}
                routing={this.props.isRedirect}
                getUploadLink={this.props.getUploadLink}
                processTaxReturn={this.props.processTaxReturn}
                submitTaxReturn={this.props.submitTaxReturn}
                onPageReload={this.props.onPageReload}
                defaultGroup={this.props.defaultGroup ?? ""}
                groupAccess={this.props.groupAccess ?? []}
                groups={this.props.groupInfo ?? []}
                updateGroupInfoState={this.props.updateGroupInfoState}
                returnAccessSettings={this.props.returnAccessSettings}
                availableUsers={this.state.availableUsers}
                selectedUsers={this.state.selectedUsers}
                selectedUserGroups={this.state.selectedUserGroups}
                availableUserGroups={this.state.availableUserGroups}
            />

            <MultiFileUploadDocumentModal
                onShow={this.props.onShow
                    && this.props.taxSoftware != TaxSoftwareType.ProSystems
                }
                onHide={this.handleClose}
                defaultERO={this.state.defaultERO}
                EROs={this.state.EROs}
                availableUsers={this.state.availableUsers}
                selectedUsers={this.state.selectedUsers}
                selectedUserGroups={this.state.selectedUserGroups}
                availableUserGroups={this.state.availableUserGroups}
                getUploadLink={this.props.getUploadLink}
                submitTaxReturn={this.props.submitTaxReturn}
                onPageReload={this.props.onPageReload}
                taxSoftware={this.props.taxSoftware}
                defaultGroup={this.props.defaultGroup ?? ""}
                groupAccess={this.props.groupAccess ?? []}
                groups={this.props.groupInfo ?? []}
                updateGroupInfoState={this.props.updateGroupInfoState}
                returnAccessSettings={this.props.returnAccessSettings}
            />

        </div >;
    }

    private validateTaxReturnUploadData = (uploadData: TaxReturnUploadData[]): boolean => {
        let tempPartnerId: number = 0;
        let valid: boolean = true;
        uploadData.map((value) => {
            tempPartnerId = value.partnerId == undefined ? 0 : value.partnerId;
            if (tempPartnerId < 1) {
                valid = false;
                return;
            }
        });
        return valid;
    }

    private handleClose = () => {
        this.props.onHide();
    }

}
export default UploadDocumentModal;
