import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../ActionTypes';
import { NotificationAction } from '../common/NotificationStore';
import { ITaxReturn } from '../../components/common/TaxReturn';
import { handleResponse } from '../Library';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';

interface SetVoucherDueDateReminderAction {
    type: actionTypes.SET_VOUCHER_DUE_DATE_REMINDER;
}

export interface ErrorSetReminderAction {
    type: actionTypes.ERROR_SET_REMINDER;
    message: string;
}

export interface IReminderData {
    id: number,
    isLoading: boolean,
    error: boolean,
    message: string
}

type KnownAction = SetVoucherDueDateReminderAction | ErrorSetReminderAction | NotificationAction;

export const actionCreators = {
    setVoucherDueDateReminder: (taxReturn: ITaxReturn, resourceId: string, callback?: (reminderData: IReminderData) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const formData = new FormData();
        let taxReturnData = JSON.stringify(taxReturn);
        formData.append('taxDocument', taxReturnData);
        let options: any = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'X-Resource-Id': resourceId
            },
            body: formData
        };
        const fetchTask = fetch(`${API_BASE_URL}api/Reminder/SetVoucherDueDateReminder`, options)
            .then(handleResponse)
            .then(() => {
                let reminderData: IReminderData = { id: taxReturn.id, isLoading: false, error: false, message: '' };
                callback && callback(reminderData);
            })
            .catch(error => {
                logger.trackError(`setVoucherDueDateReminder failed for taxReturn: ${taxReturn}, with error ${error.message}`);
                let reminderData: IReminderData = { id: taxReturn.id, isLoading: false, error: false, message: error };
                callback && callback(reminderData);
            });
        addTask(fetchTask);

    }
};

const unloadedState: IReminderData = {} as IReminderData;

export const reducer: Reducer<IReminderData> = (state: IReminderData = unloadedState, incomingAction: Action) => {

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.SET_VOUCHER_DUE_DATE_REMINDER:
            let reminderState: IReminderData = { ...state };
            reminderState.isLoading = false;
            reminderState.error = false;
            reminderState.message = '';
            break;
        case actionTypes.ERROR_SET_REMINDER:
            let errorState: IReminderData = { ...state };
            errorState.isLoading = false;
            errorState.error = true;
            errorState.message = action.message;
            break;
        default:
            break;
    }
    return state || unloadedState;
};

