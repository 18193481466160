import React from  'react';
import { RouteComponentProps } from 'react-router';
import { Modal, Col, Button, FormControl, FormGroup, Alert, Row } from 'react-bootstrap';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import {
    ITaxReturn, isIndividual, isMutual, isPartnership, SignatureStatus, DocumentStatus,
    EngagementType, ClientTypes, SignatureType, KBAMode, initialTaxReturnState,
} from './TaxReturn';
import { VenusNotifier } from '../helper/VenusNotifier';
import { ClientInfoValidation, ReportProblemConstants, UploadtaxReturnConstants, ValidationContants } from '../helper/Constants';
import {
    IReportProblemDetails, ReportProblemProperty, ReportProblemTypes, SignatureStatusOptionsList,
    initialState, ProductTypes, TaxreturnType, ReportedStep, DocumentStatusType, SelectOptions
} from './ReportProblem/ReportProblemModel';

import { getProductTypes, getTaxSoftwares, isClientInfoLoaded, PrepareTaxYear, splitName } from '../helper/HelperFunctions';
import { ICompanySettings } from '../../Core/ViewModels/Company/CompanySettingsViewModel';
import { IUserProfile } from '../navigation/profile/ProfileObjects';
import { isValidEmailAddress } from '../helper/Validations';
import { DropdownComponent } from './../common/controls/DropdownComponent';
import Select from 'react-select';
import PhoneNumberComponent from './PhoneNumberComponent';
import { TaxpayerDetails } from './ReportProblem/TaxpayerDetails';
import { SpouseDetails } from './ReportProblem/SpouseDetails';
import { PartnerDetails } from './ReportProblem/PartnerDetails';
import { logClientEvent } from '../helper/LoggerHelper';
import { ITaxSoftwareSettings } from 'src/Core/ViewModels/Company/SuiteCompanySettings/SuiteTaxSoftwareComponentModel';
import { LogEventConstants } from '../helper/LogEventConstants';

interface ReportProblemModalProps {
    show: boolean;
    onCancel: () => void;
    model: ITaxReturn;
    onReportProblemSave: (id: number, problemDetails: IReportProblemDetails, callback?: () => void) => void;
    loggedInCPA: IUserProfile;
    companyName: string;
    reportProblemType: ReportProblemTypes;
    problemStep?: ReportedStep;
    companySettings?: ICompanySettings;
    taxSoftwareSettings?: ITaxSoftwareSettings
    isGroup?: boolean;
}

interface ReportProblemModalState {
    saving: boolean;
    showLoader: boolean;
    problemState: IReportProblemDetails;
}

export class ReportProblemModal extends React.Component<ReportProblemModalProps, ReportProblemModalState> {

    constructor(props: ReportProblemModalProps) {
        super(props);

        this.state = {
            saving: false,
            showLoader: false,
            problemState: initialState,
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps: ReportProblemModalProps) {
        const reportProblem = { ...this.state.problemState };
        reportProblem.cpaPhoneNumber = !this.props.loggedInCPA ||
            !this.props.loggedInCPA.mobileNumber ? "" : this.props.loggedInCPA.mobileNumber;

        reportProblem.cpaEmail = !this.props.loggedInCPA ? "" : this.props.loggedInCPA.emailAddress;
        if (!this.props.isGroup)
        {
            reportProblem.productType = ProductTypes.SafeSendExtensions;
        }

        this.setState({
            saving: false,
            showLoader: nextProps.model === undefined ? true : false,
            problemState: reportProblem,
        });

        if (nextProps.model && nextProps.reportProblemType == ReportProblemTypes.ReturnSpecific) {
            reportProblem.productType = ProductTypes.SafeSendExtensions;
            reportProblem.documentId = nextProps.model.id;
            reportProblem.taxYear = nextProps.model.taxYear;
            reportProblem.taxSoftware = nextProps.model.taxSoftware;
            reportProblem.clientId = nextProps.model.clientId;
            reportProblem.returnType = nextProps.model.engagementType.toString();
            reportProblem.status = this.props.problemStep == ReportedStep.Assignments ?
                nextProps.model.documentStatus.toString() : nextProps.model.signatureStatus.toString();

            this.setState({
                problemState: reportProblem,
            });

            if (isMutual(nextProps.model)) {
                reportProblem.taxreturnType = TaxreturnType.MarriedJointTaxReturn;
                reportProblem.taxpayerName = !nextProps.model.taxpayer ? "" : nextProps.model.taxpayer.name;
                reportProblem.taxpayerEmail = !nextProps.model.taxpayer ? "" : nextProps.model.taxpayer.email;
                reportProblem.spouseName = !nextProps.model.spouse ? "" : nextProps.model.spouse.name;
                reportProblem.spouseEmail = !nextProps.model.spouse ? "" : nextProps.model.spouse.email;
                this.setState({
                    problemState: reportProblem,
                });
            } else if (isIndividual(nextProps.model)) {
                reportProblem.taxreturnType = TaxreturnType.IndividualTaxReturn;
                reportProblem.taxpayerName = !nextProps.model.taxpayer ? "" : nextProps.model.taxpayer.name;
                reportProblem.taxpayerEmail = !nextProps.model.taxpayer ? "" : nextProps.model.taxpayer.email;
                this.setState({
                    problemState: reportProblem,
                });
            } else if (isPartnership(nextProps.model)) {
                reportProblem.taxreturnType = TaxreturnType.CorporateTaxReturn;
                reportProblem.partnershipName = !nextProps.model.partnership ? "" : nextProps.model.partnership.name;
                reportProblem.partnershipEmail = !nextProps.model.partnership ? "" : nextProps.model.partnership.email;
                this.setState({
                    problemState: reportProblem,
                });
            }
        }
    }

    public render() {
        const isDataLoaded = this.props.reportProblemType == ReportProblemTypes.ReturnSpecific &&
            isClientInfoLoaded(this.props.model);

        const loadingMessage: string = !isDataLoaded ? UploadtaxReturnConstants.OverlayMessage.ApplicationLoading : "";

        const engType = [];
        for (const type in EngagementType) {
            if (isNaN(Number(type))) {
                if ((type !== EngagementType[EngagementType.None])) {
                    engType.push({ value: type.toString(), label: type.slice(1) });
                }
            }
        }

        const docStatus: SelectOptions[] = DocumentStatusType;
        const sigStatus: SelectOptions[] = SignatureStatusOptionsList;

        return <div>
            <Modal className="report-problem-modal" show={this.props.show} onHide={this.handleClose} data-testid="report-problem-modal">
                <LoadingOverlay>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <span className="fas fa-bullhorn" style={{ color: 'grey', marginRight: '7px' }} />
                            Report a Problem{getTaxClientName(this.props.model)}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.model && this.props.model.id ? <Alert variant='info' >
                            <i className='fas fa-file' style={{ marginRight: '5px' }}>
                            </i><b>{this.props.model.clientId}</b>
                        </Alert> : false}

                        <Row className="report-problem-field margin-bottom-20">
                            <Col sm={2} >
                                <div className="font700">Product Type</div>
                            </Col>
                            <Col sm={4}>
                                <Select
                                    name="form-field-prodType"
                                    value={"SafeSendExtensions"}
                                    placeholder="Select Product Type..."
                                    onChange={(event: any) => {
                                        this.handleFieldChange(ReportProblemProperty.productType, event.value);
                                    }}
                                    options={getProductTypes()}
                                    disabled={true}
                                    clearable={false}
                                />
                            </Col>

                        </Row>

                        <Row className="report-problem-field margin-bottom-20">
                            <Col sm={2} >
                                <div className="font700">CPA Firm</div>
                            </Col>
                            <Col sm={4}>
                                <FormControl
                                    type="text"
                                    placeholder="Enter Company Name"
                                    value={this.props.companyName}
                                    disabled={true}
                                />
                            </Col>

                            <Col sm={2}>
                                <div className="font700">Logged-In User</div>
                            </Col>
                            <Col sm={4}>
                                <FormControl
                                    type="text"
                                    placeholder="Enter Logged-In CPA Username"
                                    value={this.props.loggedInCPA != undefined ?
                                        this.props.loggedInCPA.firstName + " " + this.props.loggedInCPA.lastName : ""}
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                        {
                            (this.props.reportProblemType == ReportProblemTypes.ReturnSpecific ||
                                this.state.problemState.productType == ProductTypes.SafeSendExtensions) &&
                            <Row className="report-problem-field margin-bottom-20">
                                    <Col sm={2} >
                                        <div className="font700 ">Tax Software</div>
                                    </Col>
                                    <Col sm={4}>
                                        <DropdownComponent
                                            id="form-field-taxSoftware"
                                            className=""
                                            selectedValue={this.state.problemState.taxSoftware}
                                            customPlaceHolder="Select Tax Software..."
                                            onChange={(event: any) => {
                                                this.handleFieldChange(ReportProblemProperty.taxSoftware, event)
                                            }}
                                            options={getTaxSoftwares()}
                                            disabled={
                                                this.props.reportProblemType == ReportProblemTypes.ReturnSpecific
                                            }
                                            clearable={true}
                                        />
                                    </Col>
                            </Row>
                        }
                        <Row className="report-problem-field margin-bottom-20">

                            <Col sm={2} >
                                <div className="font700">Phone</div>
                            </Col>
                            <Col sm={4}>
                                <PhoneNumberComponent
                                    phoneNumber={this.state.problemState.cpaPhoneNumber}
                                    handleChangePhoneNumber={(value: string) => {
                                        this.handleFieldChange(ReportProblemProperty.cpaPhoneNumber, value);
                                    }}
                                    disabled={false}
                                />
                            </Col>

                            <Col sm={2} >
                                <div className="font700">Email</div>
                            </Col>
                            <Col sm={4}>
                                <FormControl
                                    type="text"
                                    placeholder="Enter Logged-In CPA Email"
                                    value={this.state.problemState.cpaEmail}
                                    onChange={(event: any) => {
                                        this.handleFieldChange(ReportProblemProperty.cpaEmail, event.target.value);
                                    }}
                                />
                            </Col>
                        </Row>
                        {(this.props.reportProblemType == ReportProblemTypes.ReturnSpecific ||
                            this.state.problemState.productType == ProductTypes.SafeSendExtensions) &&
                            <div>
                            <Row className="report-problem-field margin-bottom-20">

                                    <Col sm={2} >
                                        <div className="font700">Return Type</div>
                                    </Col>
                                    <Col sm={4}>
                                        <DropdownComponent
                                            id="form-field-returnType"
                                            selectedValue={this.state.problemState.returnType}
                                            customPlaceHolder="Select Return Type..."
                                            onChange={(event: any) => {
                                                this.handleFieldChange(ReportProblemProperty.returnType, event);
                                            }}
                                            options={engType}
                                            disabled={this.props.reportProblemType == ReportProblemTypes.ReturnSpecific}
                                            clearable={true}
                                        />
                                    </Col>

                                    <Col sm={2} >
                                        <div className="font700">Tax year</div>
                                    </Col>
                                    <Col sm={4}>
                                        <DropdownComponent
                                            id="form-field-taxYear"
                                            selectedValue={this.state.problemState.taxYear}
                                            customPlaceHolder="Select Tax year..."
                                            onChange={(event: any) => {
                                                this.handleFieldChange(ReportProblemProperty.taxYear, event);
                                            }}
                                            options={PrepareTaxYear()}
                                            disabled={this.props.reportProblemType == ReportProblemTypes.ReturnSpecific}
                                            clearable={true}
                                        />
                                    </Col>
                                </Row>

                            <Row className="report-problem-field margin-bottom-20">

                                    <Col sm={2} >
                                        <div className="font700">Client Id</div>
                                    </Col>
                                    <Col sm={4}>
                                        <FormControl
                                            type="text"
                                            value={this.state.problemState.clientId}
                                            placeholder="Enter Client Id"
                                            onChange={(event: any) => {
                                                this.handleFieldChange(
                                                    ReportProblemProperty.clientId,
                                                    event.target.value);
                                            }}
                                            onBlur={(event: any) => {
                                                this.handleFieldChange(
                                                    ReportProblemProperty.clientId,
                                                    event.target.value);
                                            }}
                                            disabled={this.props.reportProblemType == ReportProblemTypes.ReturnSpecific}
                                        />
                                    </Col>

                                    <Col sm={2} >
                                        <div className="font700">Status</div>
                                    </Col>
                                    <Col sm={4}>
                                        {this.props.reportProblemType == ReportProblemTypes.ReturnSpecific ?
                                            <DropdownComponent
                                                id="form-field-status"
                                                selectedValue={this.state.problemState.status}
                                                onChange={(event: any) => {
                                                    this.handleFieldChange(ReportProblemProperty.status, event)
                                                }}
                                                customPlaceHolder="Select Status..."
                                                options={
                                                    this.props.problemStep == ReportedStep.Assignments ?
                                                        docStatus : sigStatus
                                                }
                                                disabled={
                                                    this.props.reportProblemType == ReportProblemTypes.ReturnSpecific
                                                }
                                                clearable={true}
                                            />
                                            :
                                            <select className="form-control Select-control report-problem-status"
                                                value={this.state.problemState.status}
                                                onChange={(event: any) => {
                                                    this.handleFieldChange(ReportProblemProperty.status,
                                                        event.target.value)
                                                }}
                                                placeholder="Select Status..."
                                            >
                                                <option value="" disabled={true} hidden={true}>
                                                    {"Select Status..."}
                                                </option>
                                                <optgroup label="Document Status">
                                                    {
                                                        docStatus.map((status: SelectOptions, index: number) => {
                                                            return <option key={index} value={status.value}>
                                                                {status.label}
                                                            </option>
                                                        })
                                                    }
                                                </optgroup>

                                                <optgroup label="Signature Status">
                                                    {
                                                        sigStatus.map((status: SelectOptions, index: number) => {
                                                            return <option key={index} value={status.value}>
                                                                {status.label}
                                                            </option>;
                                                        })
                                                    }
                                                </optgroup>
                                            </select>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        }
                        {this.props.reportProblemType == ReportProblemTypes.ReturnSpecific
                            && this.props.model && isMutual(this.props.model) ?
                            (<div>
                                <TaxpayerDetails
                                    taxpayerName={this.state.problemState.taxpayerName}
                                    taxpayerEmail={this.state.problemState.taxpayerEmail}
                                    handleValueChange={this.handleFieldChange}
                                    disabled={this.props.reportProblemType == ReportProblemTypes.ReturnSpecific}
                                />

                                <SpouseDetails
                                    spouseName={this.state.problemState.spouseName}
                                    spouseEmail={this.state.problemState.spouseEmail}
                                    handleValueChange={this.handleFieldChange}
                                    disabled={this.props.reportProblemType == ReportProblemTypes.ReturnSpecific}
                                />
                            </div>)
                            : this.props.model && isIndividual(this.props.model) ?
                                (<div>
                                    <TaxpayerDetails
                                        taxpayerName={this.state.problemState.taxpayerName}
                                        taxpayerEmail={this.state.problemState.taxpayerEmail}
                                        handleValueChange={this.handleFieldChange}
                                        disabled={this.props.reportProblemType == ReportProblemTypes.ReturnSpecific}
                                    />
                                </div>)
                                : this.props.model && isPartnership(this.props.model) &&
                                (<div>
                                    <PartnerDetails
                                        partnerName={this.state.problemState.partnershipName}
                                        partnerEmail={this.state.problemState.partnershipEmail}
                                        handleValueChange={this.handleFieldChange}
                                        disabled={this.props.reportProblemType == ReportProblemTypes.ReturnSpecific}
                                    />
                                </div>)
                        }

                        {(this.state.problemState.productType == ProductTypes.SafeSendExtensions
                            && this.props.reportProblemType == ReportProblemTypes.Generic
                            && (this.state.problemState.returnType != "" && this.state.problemState.returnType != null
                                && this.state.problemState.returnType != EngagementType[EngagementType.E1040]
                                && this.state.problemState.returnType != EngagementType[EngagementType.E1040NR])) &&
                            (<div>
                                <PartnerDetails
                                    partnerName={this.state.problemState.partnershipName}
                                    partnerEmail={this.state.problemState.partnershipEmail}
                                    handleValueChange={this.handleFieldChange}
                                    disabled={false}
                                />
                            </div>)
                        }

                        {(this.state.problemState.productType == ProductTypes.SafeSendExtensions
                            && this.props.reportProblemType == ReportProblemTypes.Generic
                            && this.state.problemState.returnType == EngagementType[EngagementType.E1040]) &&
                            (<div>
                                <TaxpayerDetails
                                    taxpayerName={this.state.problemState.taxpayerName}
                                    taxpayerEmail={this.state.problemState.taxpayerEmail}
                                    handleValueChange={this.handleFieldChange}
                                    disabled={false}
                                />

                                <SpouseDetails
                                    spouseName={this.state.problemState.spouseName}
                                    spouseEmail={this.state.problemState.spouseEmail}
                                    handleValueChange={this.handleFieldChange}
                                    disabled={false}
                                />
                            </div>)
                        }

                        {(this.state.problemState.productType == ProductTypes.SafeSendExtensions
                            && this.props.reportProblemType == ReportProblemTypes.Generic
                            && this.state.problemState.returnType == EngagementType[EngagementType.E1040NR]) &&
                            (<div>
                                <TaxpayerDetails
                                    taxpayerName={this.state.problemState.taxpayerName}
                                    taxpayerEmail={this.state.problemState.taxpayerEmail}
                                    handleValueChange={this.handleFieldChange}
                                    disabled={false}
                                />
                            </div>)
                        }

                        <Row className="report-problem-field margin-bottom-20">

                            <Col sm={2} >
                                <div className="font700">Description</div>
                            </Col>

                            <FormGroup>
                                <Col sm={12}>
                                    <FormControl
                                        value={this.state.problemState.description}
                                        id="report-problem-text"
                                        style={{ height: "150px", marginTop: "10px" }}
                                        as="textarea"
                                        onChange={(event: any) => {
                                            this.handleFieldChange(
                                                ReportProblemProperty.description,
                                                event.target.value);
                                        }} />
                                </Col>
                            </FormGroup>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="default"
                            data-test-auto='1137347C-947C-4D8A-ABA7-0DE9D70DB526'
                            onClick={this.handleClose}
                            className="btn-white" >
                            <i className='fas fa-times'></i>Cancel
                        </Button>
                        <Button
                            data-test-auto='E0F1216B-0B82-456E-8EEA-57302D25A818'
                            onClick={this.onSubmit}
                            variant="info">
                            <i className='fas fa-check'></i>Submit
                        </Button>
                    </Modal.Footer>
                    <Loader loading={
                        this.state.showLoader
                        || (this.props.reportProblemType == ReportProblemTypes.ReturnSpecific && !isDataLoaded)}
                        text={
                            !isDataLoaded ?
                                loadingMessage :
                                ReportProblemConstants.OverlayMessage.SubmittingIssue}
                    />
                </LoadingOverlay>
            </Modal>
        </div>;
    }

    private handleFieldChange = (item: ReportProblemProperty, value: any) => {

        const reportProblem = { ...this.state.problemState };

        switch (item) {
            case ReportProblemProperty.productType:
                reportProblem.productType = value;

                if (this.props.problemStep == undefined && this.props.companySettings &&
                    this.props.taxSoftwareSettings.taxSoftware.length === 1) {
                    reportProblem.taxSoftware = this.props.taxSoftwareSettings.taxSoftware[0];
                }

                this.setState({
                    problemState: reportProblem,
                });
                break;
            case ReportProblemProperty.clientId:
                reportProblem.clientId = value;
                this.setState({
                    problemState: reportProblem,
                });
                break;
            case ReportProblemProperty.returnType:
                reportProblem.returnType = value;
                this.setState({
                    problemState: reportProblem,
                });
                break;
            case ReportProblemProperty.taxYear:
                reportProblem.taxYear = value;
                this.setState({
                    problemState: reportProblem,
                });
                break;
            case ReportProblemProperty.taxSoftware:
                reportProblem.taxSoftware = value;
                this.setState({
                    problemState: reportProblem,
                });
                break;
            case ReportProblemProperty.cpaEmail:

                reportProblem.cpaEmail = value;
                this.setState({
                    problemState: reportProblem,
                });
                break;
            case ReportProblemProperty.cpaPhoneNumber:
                reportProblem.cpaPhoneNumber = value;
                this.setState({
                    problemState: reportProblem,
                });
                break;
            case ReportProblemProperty.status:
                reportProblem.status = value;
                this.setState({
                    problemState: reportProblem,
                });
                break;
            case ReportProblemProperty.partnerName:
                reportProblem.partnershipName = value;
                this.setState({
                    problemState: reportProblem,
                });
                break;
            case ReportProblemProperty.partnerEmail: {
                reportProblem.partnershipEmail = value;
                this.setState({
                    problemState: reportProblem,
                });
                break;
            }
            case ReportProblemProperty.taxpayerName:
                reportProblem.taxpayerName = value;
                this.setState({
                    problemState: reportProblem,
                });
                break;
            case ReportProblemProperty.taxpayerEmail: {
                reportProblem.taxpayerEmail = value;
                this.setState({
                    problemState: reportProblem,
                });
                break;
            }
            case ReportProblemProperty.spouseName:
                reportProblem.spouseName = value;
                this.setState({
                    problemState: reportProblem,
                });
                break;
            case ReportProblemProperty.spouseEmail: {
                reportProblem.spouseEmail = value;
                this.setState({
                    problemState: reportProblem,
                });
                break;
            }
            case ReportProblemProperty.description:
                reportProblem.description = value;
                this.setState({
                    problemState: reportProblem,
                });
                break;

        }
    }

    private handleClose = () => {
        this.setState({
            problemState: initialState,
        }, () => {
            this.props.onCancel();
        });
    }

    private onSubmit() {
        if (this.state.problemState.description.trim() === "") {

            VenusNotifier.Warning("Please enter problem description to report.", "Report a Problem");

            return;
        } else if (!isValidEmailAddress(this.state.problemState.cpaEmail)) {

            VenusNotifier.Warning(ClientInfoValidation.inCorrectEmail, "");

            return;
        } else if (this.state.problemState.cpaPhoneNumber.trim() !== "") {
            if (this.state.problemState.cpaPhoneNumber.length < 10 ||
                /^[0-9]{1,10}$/.test(this.state.problemState.cpaPhoneNumber) === false) {

                VenusNotifier.Warning(ValidationContants.PhoneNumberLengthWarning, "");

                return;
            }
        }

        if (!this.state.saving) {
            this.setState({
                saving: true,
                showLoader: true,
            },
                () => {
                    this.props.onReportProblemSave(
                        this.state.problemState.documentId,
                        this.state.problemState,
                        () => {
                            this.setState({
                                problemState: initialState,
                            });
                        });

                    let propertyData = (this.state.problemState.documentId == null || this.state.problemState.documentId < 0) ?
                        ({ description: this.state.problemState.description }) :
                        ({ documentId: this.state.problemState.documentId, description: this.state.problemState.description });
                    logClientEvent(`${LogEventConstants.Common.ReportProblem}`, propertyData);

                });
        }

    };
}

function getTaxClientName(model: ITaxReturn) {
    if (!model) {
        return " ~ No Tax-Return!";
    }
    if (isIndividual(model)) {
        return " with " + splitName(model.taxpayer.name);
    }
    if (isMutual(model)) {
        return " with " + splitName(model.taxpayer.name);
    }
    if (isPartnership(model)) {
        return " with " + model.partnership.name;
    }
    return "";
}