import React, { useEffect, useState } from 'react'
import { 
    ISetAccessModalProps,
    IUserData, 
    IUserDataFromDB, 
    IUserGroupData,
    IUserGroupDataFromDB} 
from './SetAccessCommonModal.model'
import { Loader, LoadingOverlay } from "react-overlay-loader";
import { Button, Modal } from 'react-bootstrap';
import BlueInfoIcon from 'src/components/svg/BlueInfoIcon';
import DragAndDrop from '../DragAndDrop/DragAndDrop';
import "./set-access-modal.css"
import ModalCloseIcon from 'src/components/svg/ModelCloseIcon';



const SetAccessCommonModal:React.FC<ISetAccessModalProps> = (props) => {
    const {availableUsers,selectedUsers,availableUserGroups,selectedUserGroups}=props;
    const [availableUsersForDnD,setAvailableUsersForDnD]=useState<IUserData[]>([]);
    const [selectedUsersForDnD,setSelectedUserForDnD]=useState<IUserData[]>([]);
    const [availableUserGroupsForDnD,setAvailableUserGroupsForDnD]=useState<IUserGroupData[]>([]);
    const [selectedUserGroupsForDnD,setSelectedUserGroupsForDnD]=useState<IUserGroupData[]>([]);

    const [availableUsersForDnDInitial,setAvailableUsersForDnDInitial]=useState<IUserData[]>([]);
    const [selectedUsersForDnDInitial,setSelectedUserForDnDInitial]=useState<IUserData[]>([]);
    const [availableUserGroupsForDnDInitial,setAvailableUserGroupsForDnDInitial]=useState<IUserGroupData[]>([]);
    const [selectedUserGroupsForDnDInitial,setSelectedUserGroupsForDnDInitial]=useState<IUserGroupData[]>([]);

    const [isSetAccessSaving,setIsSetAccessSaving]=useState(false);
    const [loaderMessage,setLoaderMessage]=useState("");
    
    const [isNoUserAndUserGroup,setIsNoUserAndUserGroup]=useState(false)
    
    useEffect(()=>{
        setIsSetAccessSaving(true);
        setLoaderMessage("Loading,Please Wait...")
        initAllValues();
    },[])

    useEffect(()=>{
        if(!selectedUsersForDnD.length && !selectedUserGroupsForDnD.length){
            setIsNoUserAndUserGroup(true)
        }
        else{
            setIsNoUserAndUserGroup(false)
        }
    },[selectedUsersForDnD,selectedUserGroupsForDnD])

    const initAllValues =():void=>{
        initAvailableUSers(availableUsers);
        initSelectedUsers(selectedUsers);
        initAvailableUserGroups(availableUserGroups);
        initSelectedUserGroups(selectedUserGroups);
        setIsSetAccessSaving(false);
        setLoaderMessage("");
    }

    const initAvailableUSers=(availableUsers:IUserDataFromDB[])=>{
        const availableUsersForDnDTemp:IUserData[]=availableUsers.map((eachUser:IUserDataFromDB)=>{
            return {name:eachUser.name,title:eachUser.name,value:eachUser.id};
        });
        setAvailableUsersForDnD(availableUsersForDnDTemp);
        setAvailableUsersForDnDInitial(availableUsersForDnDTemp);
    }

    const initSelectedUsers = (selectedUsers:IUserDataFromDB[])=>{
        const selectedUserForDndTemp:IUserData[]=selectedUsers.map((eachUser:IUserDataFromDB)=>{
            return {name:eachUser.name,title:eachUser.name,value:eachUser.id};
        });
        setSelectedUserForDnD(selectedUserForDndTemp);
        setSelectedUserForDnDInitial(selectedUserForDndTemp);
    };

    const initAvailableUserGroups=(availableUserGroups:IUserGroupDataFromDB[])=>{
        const availableUserGroupsForDnDTemp:IUserGroupData[]=availableUserGroups.map((eachUserGroup:IUserGroupDataFromDB)=>{
            const name=<span
            style={{
                color:eachUserGroup.fontColor,
                backgroundColor:eachUserGroup.backgroundColor,
                border:`1px solid ${eachUserGroup.borderColor}`,
                padding:'0 8px',
                fontFamily:'Mulish',
                borderRadius:'4px'
            }}
            className="each-group-set-access"
        >
            {eachUserGroup.name}
        </span>            
        return {name,title:eachUserGroup.name,value:eachUserGroup.id };
        });
        setAvailableUserGroupsForDnD(availableUserGroupsForDnDTemp)
        setAvailableUserGroupsForDnDInitial(availableUserGroupsForDnDTemp)
    };

    const initSelectedUserGroups =(selectedUserGroups:IUserGroupDataFromDB[])=>{
        const selectedUserGroupsForDnDTemp:IUserGroupData[]=selectedUserGroups.map((eachUserGroup:IUserGroupDataFromDB)=>{
            const name=<span
                style={{
                    color:eachUserGroup.fontColor,
                    backgroundColor:eachUserGroup.backgroundColor,
                    border:`1px solid ${eachUserGroup.borderColor}`,
                    padding:'0 8px',
                    fontFamily:'Mulish',
                    borderRadius:'4px'
                }}
                className="each-group-set-access"
            >
                {eachUserGroup.name}
            </span>
    return { name, title: eachUserGroup.name, value: eachUserGroup.id };
  });
        setSelectedUserGroupsForDnD(selectedUserGroupsForDnDTemp);
        setSelectedUserGroupsForDnDInitial(selectedUserGroupsForDnDTemp);
    }

    const resetSetAccessData=()=>{
        setAvailableUsersForDnD(availableUsersForDnDInitial);
        setSelectedUserForDnD(selectedUsersForDnDInitial);
        setAvailableUserGroupsForDnD(availableUserGroupsForDnDInitial);
        setSelectedUserGroupsForDnD(selectedUserGroupsForDnDInitial)
    }

    const handleSetAccessSave=()=>{
        if(selectedUsersForDnD.length===0 && selectedUserGroupsForDnD.length===0){
            setIsNoUserAndUserGroup(true);
        }
        else{
            setIsNoUserAndUserGroup(false);
            setIsSetAccessSaving(true);
            setLoaderMessage("Submitting the access changes...");
            const accessingUserAndUserGroups={
                selectedUsers:selectedUsersForDnD.map((eachUser)=>eachUser.value),
                selectedUserGroups:selectedUserGroupsForDnD.map((eachUserGroup)=>eachUserGroup.value),
                availableUsers:availableUsersForDnD.map((eachUser)=>eachUser.value),
                availableUserGroups:availableUserGroupsForDnD.map((eachUserGroup)=>eachUserGroup.value)
            };
            props.onApplyAccess(accessingUserAndUserGroups);
        }
    }

    const numberOfSelectedUsersAndUserGroups=()=>{
        const numberOfSelectedUsers=selectedUsersForDnD.findIndex((eachUser)=>eachUser.value===0)!==-1
        ?"All"
        :availableUsersForDnD.length===0 || 
        (availableUsersForDnD.length===1 && availableUsersForDnD[0].value===0)
        ?"All"
        :selectedUsersForDnD.length
        const numberOfSelectedUserGroups=selectedUserGroupsForDnD.findIndex((eachUserGroup)=>eachUserGroup.value===0)!==-1
        ?"All"
        :availableUserGroupsForDnD.length===0||
        (availableUsersForDnD.length===1 && availableUserGroupsForDnD[0].value===0 )
        ?"All"
        :selectedUserGroupsForDnD.length

        const text=`${numberOfSelectedUsers} ${numberOfSelectedUsers===1?"User":"Users"} 
        and ${numberOfSelectedUserGroups} 
        ${numberOfSelectedUserGroups===1?"User Group":"User Groups"} selected`;

        return text;
    }



  return (
        <>
        <Modal show={true} onHide={props.hide} >
            <Modal.Header>
                <div>
                    <Modal.Title style={{color:'black',fontSize:'20px'}}>Set Access</Modal.Title>
                </div>
                <div data-test-auto="e2ab6c41-b6f5-4181-b38f-3455585b2141" className="closeIcon" onClick={props.hide}>
                    <ModalCloseIcon />
                </div>
            </Modal.Header>
            <Modal.Body className='set-access-body'>
                <LoadingOverlay>
                    <section className="selection-data">
                        <span>
                            <BlueInfoIcon />
                        </span>
                        <span>{numberOfSelectedUsersAndUserGroups()}</span>
                    </section>
                    <div className="usersDnD">
                        <DragAndDrop
                            sourceData={availableUsersForDnD}
                            setSourceData={setAvailableUsersForDnD}
                            destinationData={selectedUsersForDnD}
                            setDestinationData={setSelectedUserForDnD}
                            sourceDataInitial={availableUsersForDnDInitial}
                            destinationDataInitial={selectedUsersForDnDInitial}
                            sourceHeading="AVAILABLE USERS"
                            sourceHeadingInfoText="List of all users in company"
                            destinationHeading="USER WITH ACCESS RIGHTS"
                            destinationHeadingInfoText="List of selected users"
                            sourceWidth="350px"
                            sourceHeight="223px"
                            destinationWidth="350px"
                            destinationHeight="223px"
                            disableDragAndDrop={
                               ((props.returnAccessSettings.isSetAccessToIndividual ||
                                props.returnAccessSettings.isSetAccessToUserGroups)
                                && props.isDragAndDropDisabled) 
                            }
                            dndUniqueId="1"
                        />
                    </div>
                    <div className="userGroupsDnD">
                        <DragAndDrop
                            sourceData={availableUserGroupsForDnD}
                            setSourceData={setAvailableUserGroupsForDnD}
                            destinationData={selectedUserGroupsForDnD}
                            setDestinationData={setSelectedUserGroupsForDnD}
                            sourceDataInitial={availableUserGroupsForDnDInitial}
                            destinationDataInitial={selectedUserGroupsForDnDInitial}
                            sourceHeading="AVAILABLE USER GROUPS"
                            sourceHeadingInfoText="List of all groups in company"
                            destinationHeading="USER GROUPS WITH ACCESS RIGHTS"
                            destinationHeadingInfoText="List of selected groups"
                            sourceWidth="350px"
                            sourceHeight="184px"
                            destinationWidth="350px"
                            destinationHeight="184px"
                            disableDragAndDrop={
                                ((props.returnAccessSettings.isSetAccessToIndividual ||
                                props.returnAccessSettings.isSetAccessToUserGroups)
                                && props.isDragAndDropDisabled)
                            }
                            dndUniqueId="2"
                            disableDestinationItems={[1]}
                        />
                    </div>
                    <div className="errorMessage">
                        {isNoUserAndUserGroup ? "At least one user or user group is mandatory" : ""}
                    </div>
                    <Loader loading={isSetAccessSaving} text={loaderMessage} />
                </LoadingOverlay>
            </Modal.Body>
            <Modal.Footer className="set-access-footer">
                <div>
                    <button
                        data-test-auto="292778c2-6b42-4c6f-9a09-91cccdc872ea"
                        className="reset-button"
                        disabled={isSetAccessSaving}
                        onClick={resetSetAccessData}
                    >
                        <span className="resetText">Reset</span>
                    </button>
                    <Button
                        disabled={isSetAccessSaving}
                        data-test-auto="96af1953-27c9-47f5-aeae-5f3427ea379b"
                        className="cancel-button"
                        onClick={props.onCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={isSetAccessSaving}
                        data-test-auto="9ff5fabf-fb69-4a7e-b3dc-f2365b860dc9"
                        className="save-button"
                        onClick={handleSetAccessSave}
                    >
                        Save
                    </Button>
                </div>
            </Modal.Footer>
            </Modal>
        </>
    )
}

export default SetAccessCommonModal