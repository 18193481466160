import React from 'react';
import { Button } from 'react-bootstrap';
import { IUserDefaultSettingsStates } from '../navigation/profile/MySettings';
import { ICompanySettings } from '../../Core/ViewModels/Company/CompanySettingsViewModel';
import { RBACKeys } from '../helper/RbacConstants';
import { ITaxSoftwareSettings, TaxSoftwareType } from 'src/Core/ViewModels/Company/SuiteCompanySettings/SuiteTaxSoftwareComponentModel';

export interface UploadFabButtonProps {
    userDefaultSettings: IUserDefaultSettingsStates,
    companySettings: ICompanySettings,
    onUploadReturnsClick: (taxSoftware: TaxSoftwareType) => void;
    pageId?: string;
    taxSoftwareData: ITaxSoftwareSettings
}

const NO_INDEX = -1;

export class UploadFabButton extends React.Component<UploadFabButtonProps, {}> {

    constructor(props: UploadFabButtonProps) {
        super(props);
    }

    onMainBtnClick = (event: any, taxSoftware: TaxSoftwareType[]) => {
        if (taxSoftware.length == 1) {
            this.props.onUploadReturnsClick(taxSoftware[0]);
        }
    }

    onBtnClick = (taxSoftware: TaxSoftwareType) => {
        this.props.onUploadReturnsClick(taxSoftware);
    }

    public render() {
        const taxSoftwareList: TaxSoftwareType[] = this.props.taxSoftwareData.taxSoftware;

        return (<div
                style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                }}
            >

                {
                    taxSoftwareList && taxSoftwareList.length >= 1 && (

                        taxSoftwareList.map((value, index) => {
                            const style: React.CSSProperties = { position: "initial", marginLeft: '10px', fontSize: '14px' };
                            return (
                                value === TaxSoftwareType.ProSystems ?
                                    <div key={index.toString()} title="CCH" data-test-auto="93AFA737-631C-493E-80AD-67F3B5E81A41" className="cch-btn"
                                        data-resource-id={RBACKeys[this.props.pageId].uploadButton}
                                        onClick={() => { this.onBtnClick(value) }} style={style}>CCH</div> : (
                                        value === TaxSoftwareType.UltraTax ?
                                            <div title="UltraTax" data-test-auto="A0C11973-E03C-42F9-98B0-70CD9FB0FE4C" className="ut-btn"
                                                data-resource-id={RBACKeys[this.props.pageId].uploadButton}
                                                onClick={() => { this.onBtnClick(value) }} style={style}>UT</div> : (
                                                value === TaxSoftwareType.GoSystem ?
                                                    <div title="GoSystem" data-test-auto="8CD788EC-5A73-404D-AEB4-473D325DB7B6" className="gs-btn"
                                                        data-resource-id={RBACKeys[this.props.pageId].uploadButton}
                                                        onClick={() => { this.onBtnClick(value) }} style={style}>GS</div> : (
                                                        value === TaxSoftwareType.Lacerte ?
                                                            <div title="Lacerte" data-test-auto="B5ACAB24-F260-4125-A732-911E1B69187A" className="lt-btn"
                                                                data-resource-id={RBACKeys[this.props.pageId].uploadButton}
                                                                onClick={() => { this.onBtnClick(value) }} style={style}>LT</div> : ""
                                                    )
                                            )
                                    )

                            )
                        })
                    )
                }
            </div>);
    }
}