import React from  'react';
import Select from 'react-select';

export interface IDropdownComponentProps {
    selectedValue?: any;
    options: any;
    onChange?: (selectedValue: any) => void;
    clearable?: boolean;
    disabled?: boolean;
    customPlaceHolder?: any;
    id: string;
    onFocus?: (event: any) => void;
    className?: any;
}

export class DropdownComponent extends React.Component<IDropdownComponentProps, {}> {
    constructor(props: any) {
        super(props);
    }

    private onChange = (event: any) => {
        const selectedValue = event ? event.value : event;
        if (this.props.onChange) {
            this.props.onChange(selectedValue);
        }
    }

    public render() {
        const { clearable } = this.props;

        return <Select
            id={this.props.id}
            value={this.props.selectedValue}
            onChange={this.onChange}
            options={this.props.options}
            placeholder={this.props.customPlaceHolder && this.props.customPlaceHolder.length > 0 ? this.props.customPlaceHolder : "Select..."}
            clearable={clearable == undefined ? true : clearable}
            disabled={this.props.disabled ? this.props.disabled : false}
            data-toggle="dropdown" data-boundary="window"
            onFocus={this.props.onFocus}
            className={this.props.className}
        />;
    }
}

export default DropdownComponent;
