import React from 'react';
import { Carousel, Modal } from 'react-bootstrap';

export interface CarouselImage {
    source: string;
    caption: string;
    pageNo: number;
}
interface PageCarouselProps {
    open: boolean;
    images: CarouselImage[];
    currentImage: number;
    onClose: () => void;
}
interface PageCarouselState {
    images: any[];
    open: boolean;
    currentImage: number;
    title: string;
    fullScreen: boolean;
}
const theme = {
    thumbnail__active: {
        boxShadow: '0 0 0 2px #00D8FF',
    },
};

export class PageCarousel extends React.Component<PageCarouselProps, PageCarouselState> {
    constructor(props: PageCarouselProps) {
        super(props);
        this.state = {
            images: [],
            open: false,
            currentImage: 0,
            title: "",
            fullScreen: false,
        }
    }
    componentDidMount() {
        this.createImages(this.props);
    }
    componentWillReceiveProps(props: PageCarouselProps) {
        this.createImages(props);
    }
    gotoPrevious = () => {
        if (this.state.currentImage - 1 !== -1) {
            this.setState({
                currentImage: this.state.currentImage - 1,
                title: this.props.images[this.state.currentImage - 1].caption,
            });
        }
    }
    gotoNext = () => {
        if (this.state.currentImage + 1 !== this.state.images.length) {
            this.setState({
                currentImage: this.state.currentImage + 1,
                title: this.props.images[this.state.currentImage + 1].caption,
            });
        }
    }
    gotoImage = (index: number) => {
        this.setState({
            currentImage: index,
            title: this.props.images[index] && this.props.images[index].caption,
        });
    }
    createImages = (props: PageCarouselProps) => {
        this.setState(
            {
                images: props.images,
                open: props.open,
                title: props.images[props.currentImage] && props.images[props.currentImage].caption,
            },
            () => {
                props.images.sort((a: CarouselImage, b: CarouselImage) => a.pageNo - b.pageNo);

                this.gotoImage(props.currentImage);
            }
        );
    };

    enterFullScreen = () => {
        this.exitFullScreen()
        const elem = document.querySelector('.page-carousel-modal-dialog')
        if (elem && elem.requestFullscreen && document.fullscreenElement === null) {
            this.setState({ fullScreen: true })
            elem.requestFullscreen()
        }
    }

    exitFullScreen = () => {
        this.setState({ fullScreen: false })
        if (document.fullscreenElement) {
            document.exitFullscreen()
        }
    }

    render() {
        return this.state.images && this.state.images.length > 0 ? (
            <div>
                <Modal
                    animation={true}
                    show={this.state.open}
                    onHide={this.props.onClose}
                    centered
                    className="page-carousel-modal"
                    contentClassName="page-carousel-modal-content"
                    backdropClassName="page-carousel-modal-backdrop"
                    dialogClassName="page-carousel-modal-dialog"
                >
                    <div
                        onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                            if ((event.target as HTMLDivElement).className === 'slide active carousel-item') {
                                this.props.onClose()
                            }
                        }}
                        className="page-carousel-modal-overlay"
                    >
                        <Carousel
                            indicators={false}
                            controls={false}
                            interval={null}
                            activeIndex={this.state.currentImage || 0}
                            className="bootstrap-carousel"
                        >
                            {this.state.images.map((image: CarouselImage, index: number) => (
                                <Carousel.Item className="slide" key={image.caption + index}>
                                    <img src={image.source} alt={image.caption} />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                        {this.state.currentImage !== 0 && (
                            <span className="carousel-prev" title="Previous" onClick={this.gotoPrevious}>
                                <i className="fas fa-chevron-left"></i>
                            </span>
                        )}
                        {this.state.currentImage !== this.state.images.length - 1 && (
                            <span className="carousel-next" title="Next" onClick={this.gotoNext}>
                                <i className="fas fa-chevron-right"></i>
                            </span>
                        )}

                        {this.state.fullScreen ? (
                            <span
                                className="page-carousel-modal-overlay-fullscreen"
                                title="Exit fullscreen"
                                onClick={this.exitFullScreen}
                            >
                                <i className="fas fa-compress"></i>
                            </span>
                        ) : (
                            <span
                                className="page-carousel-modal-overlay-fullscreen"
                                title="Enter fullscreen"
                                onClick={this.enterFullScreen}
                            >
                                <i className="fas fa-expand"></i>
                            </span>
                        )}

                        <span title="Close (esc)" className="page-carousel-modal-overlay-close">
                            <i className="fas fa-times" onClick={this.props.onClose}></i>
                        </span>
                        <span className="page-carousel-modal-overlay-name">
                            {this.state.images[this.state.currentImage].caption || ''}
                        </span>
                        <span className="page-carousel-modal-overlay-page-number">
                            {this.state.currentImage + 1} of {this.state.images.length || 0}
                        </span>
                    </div>
                </Modal>
            </div>
        ) : (
            <div></div>
        )
    }
}
