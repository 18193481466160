import React from  'react';
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { IDocumentTypeValue } from '..//Signatures';

interface EditDocumentTypeValueProps {
    showState: boolean;
    onHide: (event: any) => void;
    selectListItem: string;
    onNewDocumentValueChange: (event: any) => void;
    onSaveButtonClick: (event: any) => void;
    documentTypeValue: IDocumentTypeValue;
}

export class EditDoumentTypeValue extends React.Component<EditDocumentTypeValueProps, {}>{
 
    public render() {
        return (
            <Modal id="EditAdditionalDocumentTypeValue" show={this.props.showState} onHide={this.props.onHide} className="edit-column-value-modal">
                <Modal.Header closeButton data-test-auto="B8306827-11F9-4C76-A5CB-29B91B07C165">
                    <Modal.Title>
                        <span className="fas fa-edit" style={{ color: "grey", marginRight: "5px" }}></span>Edit Document Value</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="row marL10 marB10">
                            <label className="col-lg-2">Old Value:</label>
                            <div className="col-lg-6">
                                <input className="form-control input-sm"
                                    type="text"
                                    value={this.props.selectListItem}
                                    disabled
                                    data-test-auto="BACE91D0-C00E-458D-9992-3CEB62ABB5E7">
                                </input>
                            </div>
                        </div>

                        <div className="row marL10 marB10">
                            <label className="col-lg-2">New Value:</label>
                            <div className="col-lg-6">
                                <input className="form-control input-sm"
                                    onChange={this.props.onNewDocumentValueChange}
                                    value={this.props.documentTypeValue.value}
                                    type="text"
                                    data-test-auto="85A47B0B-2152-4B58-A5EE-F2663F53734F">
                                </input>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant='default'
                        onClick={this.props.onHide}
                        data-test-auto="C20DF8CC-F4AA-4E8D-B710-75DB7EEC6146">
                        <i className="fas fa-times"></i>Cancel
                    </Button>
                    <Button
                        variant='info'
                        onClick={this.props.onSaveButtonClick}
                        data-test-auto="3BAB1674-CFFD-466E-B34E-18BF07B654C0">
                        <i className="fas fa-save"></i>Save
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
