import React from 'react';
import { Button, Dropdown, ButtonGroup } from 'react-bootstrap';
import { SaveFilters } from './SaveFilterModal';
import { VenusNotifier } from '../helper/VenusNotifier';
import { ReportFilterConstants, Common } from '../helper/Constants'
import * as bootbox from 'bootbox';
import { SessionStore } from '../helper/HelperFunctions';

interface IFilter {
    show: boolean
    onFilterNameChange: any
    onFilterSave(onApplyFilter: (filter: IFilters) => void): void;
    onExportToExcel(onExportToComplete: () => void): void;
    onClearFilter(): void;
    filterList: IFilters[];
    onSetDefaultFilter(name: string, filterType: ReportFilterType): void;
    onRemoveDefaultFilter(name: string, filterType: ReportFilterType): void;
    onFilterDelete(filterName: string, filterType: ReportFilterType): any;
    onFilterSaveClick(filterName: string): void;
    onSaveFilterHide(): void;
    onLoadSelectedFilter(filter?: IFilters): void;
    defaultFilter: string | undefined;
    loadGrid(): void;
    showExportExcel: boolean;
    OnOpenColumnSettings?: () => void;
    proxyFilter: string;
    onPageReload: () => void;
    refreshDelay?: boolean;
    loadSpinner?: boolean;
    exportRBACKey?: string;
}

export interface IFilters {
    name: string,
    sort: ISort,
    fields: { [id: string]: string; }
    searchText: string
    isDefaultFilter: boolean,
    filterType: ReportFilterType,
    isMasterFilter: boolean
}

export interface ISort {
    column: string,
    direction: SortDirections
}
export enum SortDirections {
    None = 0,
    Ascending = 1,
    Descending = 2
}

export interface FilterState {
    activeFilter: string,
    appliedFilters: string[],
    exportingState: boolean
}
export enum ReportFilterType {
    None = 0,
    SendExtension,
    InUseExtension,
    DeliveredReport,
    ArchivedReport,
    SendGroupedExtensions,
    RecycleReport
}
export function clearAllTemporaryFilter() {
    for (let item in ReportFilterType) {
        if (isNaN(Number(item))) {
            SessionStore.remove(item.toUpperCase() + ReportFilterConstants.DefaultFilterBuilder);
            SessionStore.remove(item.toUpperCase() + ReportFilterConstants.DefaultFilterBuilder + "RECENT");
        }
    }
}

export class Filters extends React.Component<IFilter, FilterState> {
    private proxyRecentFilter: any;
    constructor(props: any) {
        super(props);
        this.state = {
            activeFilter: '',
            appliedFilters: [],
            exportingState: false
        }
        this.onApplyFilter = this.onApplyFilter.bind(this);
        this.onClearFilter = this.onClearFilter.bind(this);
        this.onDeleteFilter = this.onDeleteFilter.bind(this);
        this.onApplyRecentFilter = this.onApplyRecentFilter.bind(this);
        this.getFilter = this.getFilter.bind(this);
        this.onApplySavedFilter = this.onApplySavedFilter.bind(this);
        this.onExportToComplete = this.onExportToComplete.bind(this);
    }
    componentWillReceiveProps(nextProps: IFilter) {
        {
            if (typeof nextProps.defaultFilter !== 'undefined' && nextProps.filterList.length > 0 &&
                nextProps.defaultFilter.length > 0 && this.state.appliedFilters.findIndex(x => x == nextProps.defaultFilter) == -1) {
                this.setState({ activeFilter: nextProps.defaultFilter, appliedFilters: [nextProps.defaultFilter] },
                    () => {
                        if (nextProps.defaultFilter) {
                            setTimeout(this.onSetDefaultExtensionFilter, 1000);
                        }
                    });
            }
            if (this.props.defaultFilter != nextProps.defaultFilter) {
                this.addRecentFilter(nextProps.defaultFilter as string);
            }
        }
    }

    onSetDefaultExtensionFilter = () => {
        const defaultFilter = this.getFilter(this.state.activeFilter);
        this.props.onLoadSelectedFilter(defaultFilter);
    }

    onApplySavedFilter(filter: IFilters) {
        let filterName = filter.name;
        this.addRecentFilter(filterName);
        if (this.state.activeFilter != filterName) {
            let appliedFilters: string[] = this.state.appliedFilters;
            let index = appliedFilters.indexOf(filterName);
            if (index != -1) {
                appliedFilters.splice(index, 1);
            }
            appliedFilters.push(filterName);
            this.setState({ activeFilter: filterName, appliedFilters: appliedFilters });
        }
        SessionStore.set(this.props.proxyFilter, JSON.stringify(this.getFilter(filterName)));
    }

    //Validation
    private isProxyFilter = (name: string): boolean => {
        return name === this.props.proxyFilter;
    }
    private addRecentFilter = (filterName: string): void => {
        if (filterName && filterName !== "" && !this.isProxyFilter(filterName)) {
            SessionStore.set(this.proxyRecentFilter, filterName);
        }
    }
    //Validation End

    onApplyFilter(filter: IFilters) {

        let filterName = filter.name;
        this.addRecentFilter(filterName);
        if (this.state.activeFilter != filterName) {
            let appliedFilters: string[] = this.state.appliedFilters;
            let index = appliedFilters.indexOf(filterName);
            if (index != -1) {
                appliedFilters.splice(index, 1);
            }
            appliedFilters.push(filterName);
            this.setState({ activeFilter: filterName, appliedFilters: appliedFilters }, () => { this.props.onLoadSelectedFilter(filter) });
        }
        SessionStore.set(this.props.proxyFilter, JSON.stringify(this.getFilter(filterName)));
    }

    getFilter(filterName: string) {
        return this.props.filterList.find(x => x.name == filterName);
    }

    onDeleteFilter(filterName: string, filterType: ReportFilterType) {
        let temp = this;
        bootbox.confirm({
            title: ReportFilterConstants.ControlsLabel.DeleteFilter,
            message: ReportFilterConstants.OtherMessage.DeleteFilter,
            className: 'in',
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i>' + Common.ButtonLabel.Cancel,
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="fas fa-times"></i>' + Common.ButtonLabel.Delete,
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {                    
                    let index = temp.state.appliedFilters.findIndex(x => x == filterName);
                    if (index != -1) {
                        let appliedFilters: string[] = temp.state.appliedFilters.splice(index, 1);
                        let activeFilter = temp.state.activeFilter == filterName ? '' : temp.state.activeFilter;
                        temp.setState({ activeFilter: activeFilter, appliedFilters: appliedFilters }, () => { temp.props.onFilterDelete(filterName, filterType) });

                        let recentappliedFilter: string[] = this.state.appliedFilters;
                        let recentfilterNames = recentappliedFilter[recentappliedFilter.length - 1];
                        if (filterName === recentfilterNames) {
                            let index = recentappliedFilter.indexOf(filterName);
                            recentappliedFilter.splice(index, 1);
                        }
                    }
                    else {
                        temp.props.onFilterDelete(filterName, filterType);
                    }
                }
            }
        });
    }

    onClearFilter() {
        this.setState({ activeFilter: '' }, 
        () => {
             this.props.onClearFilter()
        });
    }
    onSetDefaultFilter(name: string, filterType: ReportFilterType) {
        this.addRecentFilter(name);
        SessionStore.set(this.props.proxyFilter, JSON.stringify(this.getFilter(name)));

        this.props.onSetDefaultFilter(name, filterType);
    }

    onApplyRecentFilter() {
        if (!SessionStore.isExists(this.proxyRecentFilter)
            || this.props.filterList.findIndex(x => x.name == SessionStore.get(this.proxyRecentFilter)) == -1) {
            VenusNotifier.Error(ReportFilterConstants.ValidationMessage.NoRecentFilters, null);
        }
        else {
            let tempRecentFilterName = SessionStore.get(this.proxyRecentFilter);
            SessionStore.set(this.props.proxyFilter, JSON.stringify(this.getFilter(tempRecentFilterName)));

            let appliedFilters: string[] = [tempRecentFilterName];
            if (appliedFilters.length > 0) {
                let filterName = appliedFilters[appliedFilters.length - 1];
                if (filterName != this.state.activeFilter) {
                    this.setState({ activeFilter: filterName }, () => { this.props.onLoadSelectedFilter(this.getFilter(filterName)) });
                }
            }
        }
    }
    onExportToComplete = () => {
        this.setState({ exportingState: false });
    }

    public render() {
        this.proxyRecentFilter = this.props.proxyFilter + "RECENT";

        let appliedFilter = this.state.activeFilter !== this.props.proxyFilter && this.state.activeFilter.length > 0 ? <div className="dis-inline applied-filter">
            <span>{ReportFilterConstants.ControlsLabel.AppliedFilter}:</span> <strong> {this.state.activeFilter}</strong>
        </div > : null;
        return <div className="filters" data-testid="filters">
            <div className="btn-group marR05">
                <Button
                    onClick={this.props.onPageReload}
                    title="Refresh"
                    disabled={this.props.refreshDelay}
                    className="btn-white btn-only marR10"
                    style={{ paddingLeft: '12px' }}
                    data-test-auto="11BC25B0-F325-4170-AFA9-598384364972">
                    <span className={(this.props.refreshDelay ? "fa-spin " : " ") + 'fas fa-redo-alt fa-btn-icon'}
                        style={{ padding: '2px 0', textShadow: '0px 1px 0px rgba(255,255,255,.5)', fontSize: '12px' }}>
                    </span>
                </Button>
            </div>
            {
                this.props.OnOpenColumnSettings &&
                <Button className="btn-white btn-only marR05" type="button" title="Column Options" onClick={this.props.OnOpenColumnSettings}>
                    <span className="fas fa-sliders-h fa-lg"></span>
                </Button>
            }

            {appliedFilter}
            <div className="btn-group marR05">
                <Dropdown as={ButtonGroup}>
                    <Button variant="default" title={ReportFilterConstants.ControlsTitle.ApplyMostRecentFilter} onClick={this.onApplyRecentFilter}
                        className="filter-button-padding">
                        <span className="fas fa-filter"></span>
                    </Button>
                    <Dropdown.Toggle split variant="default" id="dropdown-split-basic"
                        className="filter-button-padding" data-test-auto="E1EC4A09-E72E-4E71-AA68-D9A596B28309">
                        <span className="fas fa-angle-down" title={ReportFilterConstants.ControlsTitle.ToggleDropdownMenu}></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item href='javascript:' className='font14 filter-dropdown-item-width' onClick={this.onApplyRecentFilter}>
                            <i className="fas fa-check-circle marR05"></i> <span>{ReportFilterConstants.ControlsLabel.ApplyRecentFilterBtn}</span>
                        </Dropdown.Item>
                        <Dropdown.Item href='javascript:' className='font14' onClick={() => { this.props.onFilterSaveClick(this.state.activeFilter) }}>
                            <i className="fas fa-save marR05" ></i> <span>{ReportFilterConstants.ControlsLabel.SaveCurrentFilterBtn}</span>
                        </Dropdown.Item>
                        <Dropdown.Item href='javascript:' className='font14' onClick={this.onClearFilter}>
                            <i className="fas fa-times-circle marR05" ></i> <span>{ReportFilterConstants.ControlsLabel.ClearFilter}</span>
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        {this.props.filterList.map((value, index) => {
                            let filterName = value.name.length <= 25 ? value.name : value.name.substring(0, 25) + '...';
                            let activeFilter = value.isDefaultFilter ? <i className="fas fa-check marL05" ></i> : null;
                            let setAsDefaultFilter = value.isDefaultFilter ?
                                <Dropdown.Item key={"filter-sub-menu" + index + "_2"} href='javascript:' onClick={() => this.props.onRemoveDefaultFilter(value.name, value.filterType)} data-test-auto="44FCD3DE-6611-4128-BC78-B0EFBB1DEC63">
                                    <span>{ReportFilterConstants.ControlsLabel.RemoveDefault}</span>
                                </Dropdown.Item>
                                :
                                <Dropdown.Item key={"filter-sub-menu" + index + "_2"} href='javascript:' onClick={() => this.onSetDefaultFilter(value.name, value.filterType)} data-test-auto="3b3651e2-4499-4530-b605-a0fa262f093a">
                                    <span>{ReportFilterConstants.ControlsLabel.SetDefault}</span>
                                </Dropdown.Item>;

                            let deleteFilter = value.isDefaultFilter ?
                                <Dropdown.Item key={"filter-sub-menu" + index + "_3"} href='javascript:' className="remove-cursor-pointer" data-test-auto="ABCB2673-BCC2-429A-8C25-E740A5FCD41D">
                                    <span>{ReportFilterConstants.ControlsLabel.Delete}</span>
                                </Dropdown.Item>
                                :
                                <Dropdown.Item key={"filter-sub-menu" + index + "_3"} href='javascript:' onClick={() => this.onDeleteFilter(value.name, value.filterType)} data-test-auto="0ded7caf-ab98-4029-a802-c12f63154f2d">
                                    <span>{ReportFilterConstants.ControlsLabel.Delete}</span>
                                </Dropdown.Item>
                                ;
                            if (value.name !== this.props.proxyFilter)
                                return <li key={"filter-menu" + index} className="dropdown-submenu dropdown-menu-right font14 filter-sub-button-padding">
                                    <a title={value.name}>{filterName} {activeFilter}</a>
                                    <ul className="dropdown-menu font14 dropdown-sub-menu">
                                        <Dropdown.Item className="filter-dropdown-sub-item-width" key={"filter-sub-menu" + index + "_1"} href='javascript:' onClick={() => this.onApplyFilter(value)} data-test-auto="0c5eeba6-5010-4ac2-b6dd-3a7f5ba210cb">
                                            <span>{ReportFilterConstants.ControlsLabel.Apply}</span>
                                        </Dropdown.Item>
                                        {setAsDefaultFilter}
                                        {deleteFilter}
                                    </ul>
                                </li>
                        })}
                    </Dropdown.Menu>
                </Dropdown>
                <SaveFilters
                    showState={this.props.show}
                    onHide={this.props.onSaveFilterHide}
                    data-test-auto="8822BA2D-A81A-4FE9-ABDB-929D5E1F236C"
                    formSubmit={() => { this.props.onFilterSave(this.onApplySavedFilter) }}
                    onFilterNameChange={this.props.onFilterNameChange}>
                </SaveFilters>
            </div>
            {
                this.props.showExportExcel == false ?
                    <Button
                        className="btn btn-default btn-filter marR05 btn-white"
                        title={ReportFilterConstants.ControlsLabel.ExportToExcel}
                        onClick={() => { this.setState({ exportingState: true }, () => { this.props.onExportToExcel(this.onExportToComplete); }) }}
                        disabled={this.state.exportingState}
                        {
                        ...(this.props.exportRBACKey && {
                            "data-resource-id": this.props.exportRBACKey
                        })
                        }
                        data-test-auto="D04B9868-CF8B-4832-B0F3-E36BE8F836F4">
                        <span data-testid="filters-export-button" className={(this.state.exportingState || this.props.loadSpinner) ? 'fas fa-spinner fa-spin text-info' : 'fas fa-file-export'} ></span>
                    </Button> : true
            }

        </div >
    }
}
export default Filters;