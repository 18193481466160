import React, { FC, useState } from "react";
import { ButtonToolbar, Dropdown, DropdownButton, Overlay, Popover } from "react-bootstrap";
import { PageToolBarConstants } from "src/components/helper/Constants";
import { DocumentGroups } from "../../TaxReturn";

interface PageMoveToProps {
    hideMoveTo?: boolean;
    documentGroup: DocumentGroups;
    reGroupPreview: (destinationGroup: DocumentGroups) => void;
}

const PageMoveTo: FC<PageMoveToProps> = (props) => {

    const { hideMoveTo, documentGroup, reGroupPreview } = props;

    const [showDeleteConfirmationPopover, setShowDeleteConfirmationPopover] = useState<boolean>(false);
    const [deletePopoverTarget, setDeletePopoverTarget] = useState<any>({});
    const [moveToToggle, setMoveToToggle] = useState<boolean>(false);

    const handleMoveToDelete = () => {
        reGroupPreview(DocumentGroups.Deleted);
        hideMoveToDeletePopover();
    };

    const hideMoveToDeletePopover = () => {
        setShowDeleteConfirmationPopover(false);
    };

    const attachMoveToDeletePopoverRef = (target: any) => {
        setDeletePopoverTarget(target);
    };

    const toggleMoveToDropDown = (eventKey: any, event: Object) => {
        if (eventKey === DocumentGroups.Deleted.toString()) {
            setMoveToToggle(true);
        }
        else {
            setMoveToToggle(!moveToToggle);
        }
    }

    const deleteConfirmationPopover = (
        <Popover
            id="popover-to-delete-confirm"
            style={{ fontSize: '14px' }}
            onClick={hideMoveToDeletePopover}>
            <Popover.Title
                as="h3"
                style={{ fontSize: '14px' }}>
                Confirm Delete
            </Popover.Title>
            <Popover.Content>
                {PageToolBarConstants.moveToDeleteConfirmation}
                <div className="popover-footer">
                    <button
                        className="btn btn-only btn-success"
                        onClick={handleMoveToDelete}
                        title="Yes"
                        style={{ marginLeft: "5px" }}>
                        Yes
                    </button>
                    <button
                        onClick={hideMoveToDeletePopover}
                        title="No"
                        className="btn btn-only btn-danger"
                        style={{ marginLeft: "5px" }}>
                        No
                    </button>
                </div>
            </Popover.Content>
        </Popover>);
    return <ButtonToolbar className="move-to-dropdown-wrapper"
        style={{ visibility: hideMoveTo ? 'hidden' : 'visible' }} >
        <DropdownButton
            show={moveToToggle}
            size="sm"
            title="Move To"
            id="move-to-dropdown"
            variant="warning"
            alignRight
            onToggle={toggleMoveToDropDown}
        >
            {
                documentGroup == DocumentGroups.Transmittals ? null :
                    <Dropdown.Item
                        eventKey={DocumentGroups.Transmittals.toString()}
                        onClick={() => { reGroupPreview(DocumentGroups.Transmittals) }}
                        href="#"
                        style={{ fontSize: '14px' }}
                        data-test-auto="1F6591BB-D38D-466C-A27D-B27ED31EC0F8">
                        <i
                            className='fas fa-file-alt ddl-icon'
                            style={{ marginLeft: '0px' }}></i>
                        Transmittal
                    </Dropdown.Item>
            }

            {
                documentGroup == DocumentGroups.EFile ? null :
                    <Dropdown.Item
                        eventKey={DocumentGroups.EFile.toString()}
                        onClick={() => { reGroupPreview(DocumentGroups.EFile) }}
                        href="#"
                        style={{ fontSize: '14px' }}
                        data-test-auto="C5EF0A27-33E6-413F-BF76-F2885EF478B1">
                        <i
                            className='fas fa-file-alt ddl-icon'
                            style={{ marginLeft: '0px' }}></i>
                        Filling Forms
                    </Dropdown.Item>
            }

            {
                documentGroup == DocumentGroups.Deleted ? null :
                    <div>
                        <Dropdown.Item
                            data-test-auto="9554BC70-D712-4FB3-A5B4-D656EA3727B5"
                            eventKey={DocumentGroups.Deleted.toString()}
                            onClick={(e: React.MouseEvent) => {
                                setShowDeleteConfirmationPopover(true);
                                e.stopPropagation();
                            }}
                            onSelect={toggleMoveToDropDown}
                            style={{ fontSize: '14px' }}
                            ref={attachMoveToDeletePopoverRef}
                            href="#">
                            <i className='fas fa-trash ddl-icon'
                                style={{ marginLeft: '0px' }}>
                            </i>
                            Delete
                        </Dropdown.Item>
                        <Overlay
                            rootClose={true}
                            onHide={hideMoveToDeletePopover}
                            target={deletePopoverTarget}
                            placement="left"
                            show={showDeleteConfirmationPopover}
                        >
                            {deleteConfirmationPopover}
                        </Overlay>
                    </div>
            }
        </DropdownButton>
    </ButtonToolbar>;
};

export default PageMoveTo;