import React from  'react';
import { NavLink } from 'react-bootstrap';
import { Modal, Button, FormControl, Table, ProgressBar, Alert } from 'react-bootstrap';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { DocumentUploadDropzoneComponent } from '../../DocumentUploadDropzoneComponent';
import { VenusNotifier } from '../../../../helper/VenusNotifier';
import { getFileExtension, validateFileType } from '../../../../helper/Validations';
import * as bootbox from 'bootbox';
import { getFileSize } from '../../../../helper/HelperFunctions';
import { ShowLoader, HideLoader } from '../../../../helper/Loader';
import { IUserModel } from '../../../../../Core/ViewModels/User/UserViewModel';
import { ButtonFormatter } from '.././UploadCommonFormatters';
import { SasContainer, UploadStatus, MultiFileTaxReturnUploadData } from '../../../../../Core/ViewModels/Common/UploadDocumentViewModel';
import { UploadtaxReturnConstants } from '../../../../helper/Constants';
import * as UploadTaxReturnStore from '../../../../../store/common/uploadTaxReturn';
import { ITaxReturn, initialTaxReturnState } from '../../../TaxReturn';
import { UploadCorrectedReturnWarning } from '../../../../helper/Constants';
import { initialGroupInfo } from '../../../../../Core/ViewModels/GroupExtensions/ComponentModels';
import { logClientEvent } from '../../../../helper/LoggerHelper';
import { API_BASE_URL } from 'src/utils/contants';
import { TaxSoftwareType } from 'src/Core/ViewModels/Company/SuiteCompanySettings/SuiteTaxSoftwareComponentModel';
import { UploadFunctions, isFileExist} from "@sssuite-js-packages/file-utility";
import { LogEventConstants } from 'src/components/helper/LogEventConstants';
import { IReturnAccessSettingsModel } from 'src/Core/ViewModels/Company/CompanySettingsViewModel';
import { IAccessingUsersAndUserGroups, IUserDataFromDB, IUserGroupDataFromDB } from 'src/components/common/SetAccess/SetAccessCommonModal.model';
import SetAccessCommonModal from 'src/components/common/SetAccess/SetAccessCommonModal';
import { unloadedRecycleGroupedExtensionsPagesStoreState } from 'src/Core/ViewModels/GroupExtensions/StoreModels';

interface MultiFileReturnUploadModalProps {
    onShow: boolean;
    onHide(): void;
    EROs: IUserModel[];
    documentKey: string;
    getUploadLink: (url: string, callback?: (data?: UploadTaxReturnStore.IUploadTaxReturnState) => void) => void;
    submitTaxReturn: (url: string, taxData: string, callback: () => void) => void;
    uploadConfirmation: (documentKey: string, clientId: string) => void;
    isRecalled?: boolean;
    taxSoftware: TaxSoftwareType;
    returnAccessSettings: IReturnAccessSettingsModel;
    availableUsers: IUserDataFromDB[];
    selectedUsers: IUserDataFromDB[];
    availableUserGroups: IUserGroupDataFromDB[];
    selectedUserGroups: IUserGroupDataFromDB[];
}

interface MultiFileReturnUploadModalState {
    multiFileReturnUploadData: MultiFileTaxReturnUploadData;
    submitDisable: boolean;
    showUpload: boolean;
    showSetAccess: boolean;
    sasContainer: SasContainer[];
    config: {
        dropzoneSelector: string;
        iconFiletypes: ['.pdf'];
        showFiletypeIcon: boolean;
        postUrl: string;
    };
    setAccessAccessingUsers: IUserModel[];
    setAccessCurrentUsers: IUserModel[];
    taxReturns: ITaxReturn;
    availableUsers: IUserDataFromDB[];
    selectedUsers: IUserDataFromDB[];
    availableUserGroups: IUserGroupDataFromDB[];
    selectedUserGroups: IUserGroupDataFromDB[];
}

export class MultiFileReturnUploadRecalledDocumentModal extends
    React.Component<MultiFileReturnUploadModalProps, MultiFileReturnUploadModalState> {

    constructor(props: MultiFileReturnUploadModalProps) {
        super(props);
        this.state = {
            multiFileReturnUploadData: {
                clientId: "",
                sasGuid: "",
                setAccess: "Everyone",
                clientName: "",
                partnerId: 0,
                documentAccess: { 
                    documents: [], 
                    userGroups: [],
                    users: []
                },
                subDocumentsUploadData: [],
                groupInfo: initialGroupInfo,
                taxSoftware: TaxSoftwareType.None,
            },
            submitDisable: true,
            showUpload: false,
            showSetAccess: false,
            sasContainer: [],
            config: {
                dropzoneSelector: 'div.filepicker',
                iconFiletypes: ['.pdf'],
                showFiletypeIcon: true,
                postUrl: "/api/",
            },
            setAccessAccessingUsers: [],
            setAccessCurrentUsers: [],
            taxReturns: initialTaxReturnState,
            availableUsers: [],
            selectedUsers: [],
            availableUserGroups: [],
            selectedUserGroups: []
        };

    }

    componentWillReceiveProps(nextProps: MultiFileReturnUploadModalProps) {

        const tempMultiFileReturnUploadData = this.state.multiFileReturnUploadData;

        tempMultiFileReturnUploadData.taxSoftware = nextProps.taxSoftware;

        this.setState({
            multiFileReturnUploadData: tempMultiFileReturnUploadData
        });

        if(nextProps.availableUsers.length>0){
            const { availableUsers, selectedUsers, availableUserGroups, selectedUserGroups } = nextProps;
            this.setState({
                availableUsers,
                selectedUsers,
                availableUserGroups,
                selectedUserGroups
            });
            const tempMultiFileReturnUploadData = this.state.multiFileReturnUploadData;
            tempMultiFileReturnUploadData.documentAccess={
                documents : [],
                userGroups: nextProps.selectedUserGroups.map((group) => {
                    return group.id;
                }),
                users: nextProps.selectedUsers.map((user) => {
                    return user.id;
                })
            }
            tempMultiFileReturnUploadData.setAccess = !(
                nextProps.returnAccessSettings != undefined &&
                nextProps.returnAccessSettings!.isSetAccessToEveryone
            )
                ? "Restricted"
                : "Everyone"
            this.setState({ multiFileReturnUploadData: tempMultiFileReturnUploadData });
        }
    }

    getMultiFileReturnDocumentGuid = () => {
        const tempMultiFileReturnUploadData = this.state.multiFileReturnUploadData;
        tempMultiFileReturnUploadData.sasGuid = this.props.documentKey;
        this.setState({
            multiFileReturnUploadData: tempMultiFileReturnUploadData,
        });
    }

    private onClientIdChange = (event: any) => {
        const tempMultiFileReturnUploadData = this.state.multiFileReturnUploadData;
        tempMultiFileReturnUploadData.clientId = event.target.value;
        this.setState({ multiFileReturnUploadData: tempMultiFileReturnUploadData });
    }


    private reset = () => {
        const tempMultiFileReturnUploadData = this.state.multiFileReturnUploadData;
        tempMultiFileReturnUploadData.clientId = "";
        tempMultiFileReturnUploadData.clientName = "";
        tempMultiFileReturnUploadData.sasGuid = "";
        tempMultiFileReturnUploadData.setAccess = !(
            this.props.returnAccessSettings != undefined &&
            this.props.returnAccessSettings!.isSetAccessToEveryone
        )
            ? "Restricted"
            : "Everyone",
        tempMultiFileReturnUploadData.documentAccess.users = [];
        tempMultiFileReturnUploadData.documentAccess.userGroups = [];
        tempMultiFileReturnUploadData.documentAccess.documents = [];
        tempMultiFileReturnUploadData.subDocumentsUploadData = [];

        this.state.sasContainer.slice(0, this.state.sasContainer.length);
        this.setState({
            sasContainer: [],
            multiFileReturnUploadData: tempMultiFileReturnUploadData,
            taxReturns: initialTaxReturnState,
            submitDisable: true,
        });
    }

    private handleClose = () => {
        this.reset();
        this.props.onHide();
    }

    private handleUploaderClose = () => {
        let _self = this;
        if (this.state.multiFileReturnUploadData.subDocumentsUploadData.length > 0) {
            bootbox.confirm({
                message: UploadtaxReturnConstants.CloseConfirmationMessage,
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: 'btn-white btn-default'
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> OK',
                        className: 'btn-info'
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        _self.handleClose();
                    }
                }
            });
        }
        else {
            _self.handleClose();
        }
    }
    //===========================================================================
    // Set Access Start 
    //===========================================================================

    setAcessShow = () => {
        this.setState({ showSetAccess: true })
    }

    onSetAccessHide = () => {
        this.setState({
            showSetAccess: false
        })
    }

    onSetAccess = (event: any) => {
        this.setState({
            showSetAccess: true
        });
    }

    getSetAccessLable = () => {
        const { selectedUsers, availableUsers, selectedUserGroups, availableUserGroups } = this.state;
        let isAllUsers = false;
        let isAllUserGroups = false;
        if ((selectedUsers.length > 0 && selectedUsers.some((x) => x.id == 0)) ||
        availableUsers.length === 0 ||
        (availableUsers.length === 1 && availableUsers.some((x) => x.id == 0))) {
            isAllUsers = true;
        } 
        if((selectedUserGroups.length > 0 && selectedUserGroups.some((x) => x.id == 0)) ||
        availableUserGroups.length === 0 ||
        (availableUserGroups.length === 1 &&  availableUserGroups.some((x) => x.id == 0))) {
            isAllUserGroups = true;
        }
        if (isAllUsers && isAllUserGroups) {
            return "Everyone";
        } else {
            return "Restricted";
        }
    };

    private onSetAccessSave = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const uploadingDocument: MultiFileTaxReturnUploadData = Object.assign({}, this.state.multiFileReturnUploadData);
        
        uploadingDocument.documentAccess = {
            documents: [],
            userGroups: accessingUsersAndUserGroups.selectedUserGroups,
            users: accessingUsersAndUserGroups.selectedUsers
        };
        uploadingDocument.setAccess = this.getSetAccessLable();

        this.setState({
            showSetAccess: false,
            selectedUsers: this.getSelectedUser(accessingUsersAndUserGroups),
            availableUsers: this.getAvailableUser(accessingUsersAndUserGroups),
            selectedUserGroups: this.getSelectedUserGroups(accessingUsersAndUserGroups),
            availableUserGroups: this.getAvailableUserGroups(accessingUsersAndUserGroups),
            multiFileReturnUploadData: uploadingDocument
        });
    }

    getSelectedUser = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUsers = [...this.state.selectedUsers, ...this.state.availableUsers];
        let selectedUser: IUserDataFromDB[] = [];
        accessingUsersAndUserGroups.selectedUsers.forEach((userId) => {
            let user = allUsers.find((x) => x.id == userId);
            if (user) {
                selectedUser.push(user);
            }
        });
        return selectedUser;
    };

    getAvailableUser = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUsers = [...this.state.selectedUsers, ...this.state.availableUsers];
        let availableUser: IUserDataFromDB[] = [];
        accessingUsersAndUserGroups.availableUsers.forEach((userId) => {
            let user = allUsers.find((x) => x.id == userId);
            if (user) {
                availableUser.push(user);
            }
        });
        return availableUser;
    };

    getSelectedUserGroups = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUserGroups = [...this.state.selectedUserGroups, ...this.state.availableUserGroups];
        let selectedUserGroups: IUserGroupDataFromDB[] = [];
        accessingUsersAndUserGroups.selectedUserGroups.forEach((userGroupId) => {
            let userGroup = allUserGroups.find((x) => x.id == userGroupId);
            if (userGroup) {
                selectedUserGroups.push(userGroup);
            }
        });
        return selectedUserGroups;
    };

    getAvailableUserGroups = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUserGroups = [...this.state.selectedUserGroups, ...this.state.availableUserGroups];
        let availableUserGroups: IUserGroupDataFromDB[] = [];
        accessingUsersAndUserGroups.availableUserGroups.forEach((userGroupId) => {
            let userGroup = allUserGroups.find((x) => x.id == userGroupId);
            if (userGroup) {
                availableUserGroups.push(userGroup);
            }
        });
        return availableUserGroups;
    };
    //===========================================================================
    // Set Access End 
    //===========================================================================

    OnSubmitTaxreturns = () => {
        if (this.validateTaxReturnUploadData()) {
            ShowLoader();
            const uploadData = this.state.multiFileReturnUploadData;
            const docKey = this.props.documentKey;
            this.props.submitTaxReturn(`${API_BASE_URL}/api/MultiFileReturnUpload/SubmitRecallMultiFileTaxReturnsAync`,
                JSON.stringify({ ...uploadData, taxSoftware: this.props.taxSoftware }), () => {
                    this.props.uploadConfirmation(docKey || "", uploadData.clientId || "");
                    this.UploadConfirmation();
                    this.handleClose();
                    HideLoader();
                });
            logClientEvent(
                    `${LogEventConstants.Common.Upload} ${TaxSoftwareType[this.props.taxSoftware]}`,
                    { count: 1 }
                );
        }
    }

    deleteReturn = (row: any) => {
        let _self = this;
        bootbox.confirm({
            message: UploadtaxReturnConstants.DeleteFileMessage,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> OK',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    var _subDocumentData = [..._self.state.multiFileReturnUploadData.subDocumentsUploadData];
                    _subDocumentData = _subDocumentData.filter(i => i.number != row.number);
                    //correct index
                    for (let i = row.number - 1; i < _subDocumentData.length; i++) {
                        _subDocumentData[i].number = i + 1;
                    }
                    let _tempgridData = _subDocumentData;

                    let _uploadData = [..._self.state.sasContainer];
                    _uploadData = _uploadData.filter(i => i.sasGuid != row.subDocumentGuid);

                    let _tempUploadData = _uploadData;

                    const tempMultiFileReturnUploadData = _self.state.multiFileReturnUploadData;
                    tempMultiFileReturnUploadData.subDocumentsUploadData = _tempgridData;
                    if (_subDocumentData.length == 0) {
                        _self.setState({ submitDisable: true })
                    }
                    _self.setState({
                        multiFileReturnUploadData: tempMultiFileReturnUploadData,
                        sasContainer: _tempUploadData
                    });
                }
            }
        });
    }

    private validateTaxReturnUploadData = (): boolean => {
        let uploaddata = this.state.multiFileReturnUploadData;
        if (uploaddata.subDocumentsUploadData.length < 1) {
            VenusNotifier.Warning("No files Uploaded", "");
            return false;
        }

        if (uploaddata.clientId == undefined || uploaddata.clientId == "") {
            VenusNotifier.Warning("Please enter client Id to submit", "");
            return false;
        }
        return true;
    }

    private UploadConfirmation = () => {
        let message: string = "<h5 class='marT0'> " + UploadtaxReturnConstants.ReturnSubmitSuccess + ":<h5> <br/><ul class='Modal-UploadUl'>";
        message = message + "<li>" + this.state.multiFileReturnUploadData.clientId + "</li>";
        message = message + "</ul>";
        bootbox.alert({
            message: message,
            buttons: {
                ok: {
                    label: '<i class="fas fa-check"></i> Ok',
                    className: 'btn-info'
                }
            },
            callback() { }
        });
    }

    //===========================================================================
    // File Upload Start 
    //===========================================================================

    djsConfig = {
        uploadMultiple: true,
        addRemoveLinks: true,
        acceptedFiles: "application/pdf",
        headers: { 'Access-Control-Allow-Origin': '*', "x-ms-blob-type": "BlockBlob" },
        previewsContainer: false,
        autoProcessQueue: false,
        autoDiscover: false
    }

    eventHandlers = {

        init: () => {
            this.reset();
            this.getMultiFileReturnDocumentGuid();
        },

        addedfiles: (files: any) => {
            let _self = this;
            let tempUploadData = this.state.multiFileReturnUploadData;
            let tempGridData = tempUploadData.subDocumentsUploadData;
            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                let fileName = file.name;
                let fileExtension = getFileExtension(fileName);
                let uploadHelperFunctions = new UploadFunctions();
                if (validateFileType(fileExtension)) {
                    let filecount = 1;

                    while (isFileExist(fileName, tempGridData)) {
                        fileName = file.name;
                        fileName = fileName.replace("." + fileExtension, '');
                        fileName = fileName + " - Copy (" + filecount + ")." + fileExtension;
                        filecount++;
                    }

                    tempGridData.push({
                        name: fileName, number: tempGridData.length + 1,
                        progressBar: 0, size: getFileSize(file.size),
                        status: UploadStatus.Uploading, subDocumentGuid: ''
                    });

                    _self.setState({ submitDisable: true, multiFileReturnUploadData: tempUploadData });

                    this.props.getUploadLink(`${API_BASE_URL}/api/MultiFileReturnUpload/GetReplacedMultiFileTaxReturnUploadLinkAsync?documentGuid=`
                        + this.state.multiFileReturnUploadData.sasGuid, (data?: UploadTaxReturnStore.IUploadTaxReturnState) => {

                            let index = tempGridData.findIndex(x => x.name === fileName);

                            try {
                                if (data) {
                                    tempGridData[index].subDocumentGuid = data.guid;

                                    _self.setState({ multiFileReturnUploadData: tempUploadData },
                                        () => uploadHelperFunctions.uploadFile(file, data, fileName,
                                            _self.uploadProgressCallback, _self.uploadCommittCallBack, _self.uploadFailureCallback));
                                } else {
                                    tempGridData.splice(index, 1);
                                    _self.setState({ multiFileReturnUploadData: tempUploadData, submitDisable: false });
                                }
                            } catch (err) {
                                tempGridData.splice(index, 1);
                                _self.setState({ multiFileReturnUploadData: tempUploadData, submitDisable: false });
                            }
                        });
                }
            }
        }
    }

    public uploadProgressCallback = (percent: number, fileToUpload: any) => {
        let tempUploadData = this.state.multiFileReturnUploadData;
        let tempGridData = tempUploadData.subDocumentsUploadData;
        tempGridData.map((tempGridDataValue, index) => {
            if (tempGridDataValue.subDocumentGuid == fileToUpload.fileGUID) {
                tempGridDataValue.progressBar = percent - 10;
            }
        });
        tempUploadData.subDocumentsUploadData = tempGridData;
        this.setState({
            multiFileReturnUploadData: tempUploadData
        });
    }

    uploadCommittCallBack = (fileToUpload: any) => {
        let tempUploadData = this.state.multiFileReturnUploadData;
        if (tempUploadData.subDocumentsUploadData.length > 0) {
            let tempGridData = tempUploadData.subDocumentsUploadData;
            tempGridData.map((tempGridDataValue, index) => {
                if (tempGridDataValue.name == fileToUpload.fileName) {
                    tempGridDataValue.progressBar = 100;
                    tempGridDataValue.status = UploadStatus.Uploaded;
                }
            });
            this.setState({
                multiFileReturnUploadData: tempUploadData
            }, () => {
                if (!this.isUploadOnGoing()) {
                    this.setState({ submitDisable: false });
                }
            });
        }
    }

    uploadFailureCallback = (fileToUpload: any) => {
    // On failure of retry , Error view and pop up notification need to be discussed with team, and will be implemented in next sprit.
    }
    private isUploadOnGoing = (): boolean => {
        let isuploading: boolean = false;
        this.state.multiFileReturnUploadData.subDocumentsUploadData.map((value) => {
            if (value.progressBar == undefined ? 0 : value.progressBar < 100) {
                isuploading = true; return;
            }
        });
        return isuploading;
    }

    //===========================================================================
    // File Upload End 
    //===========================================================================

    public render() {
        let uploadDataLength = this.state.multiFileReturnUploadData.subDocumentsUploadData.length;
        return <div>
            < Modal className="upload-doc-modal in" show={this.props.onShow} onHide={this.handleUploaderClose} enforceFocus={false}>
                <Modal.Header closeButton data-test-auto="9DFD99E6-5A5C-4A9A-B40F-975AB2D109B5">
                    <Modal.Title> Upload Documents </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-2 text-center">
                            <DocumentUploadDropzoneComponent
                                componentConfig={this.state.config}
                                djsConfig={this.djsConfig}
                                eventHandlers={this.eventHandlers}
                                autoTestId={"794D02AC-A0F0-43DB-B9E2-2F71A36415F0"}

                            />
                        </div>
                        <div className="col-sm-10">
                            <div className="row">
                                <div className="col-sm-2 col-md-2 col-xl-2">
                                    <div className="padT07 txt-ar text-bold">Client ID <span className='asterisk-error'>*</span></div>
                                </div>
                                <div className="col-sm-2 col-md-2 col-xl-2">
                                    <div className="pos_rel">
                                        <FormControl type="text"
                                            disabled={uploadDataLength < 1}
                                            value={this.state.multiFileReturnUploadData.clientId}
                                            onChange={this.onClientIdChange.bind(this)}
                                            data-test-auto="8E584D37-7EF3-4D2A-8CC8-E21FA295F6EB" />

                                    </div>
                                </div>

                                <div className="col-sm-2 col-md-2 col-xl-2">
                                    <div className="padT07 txt-ar text-bold">Set Access</div>
                                </div>
                                <div className="col-sm-2 col-md-2 col-xl-2">
                                    <div className="pos_rel padT07">
                                        <NavLink href="javascript:" onClick={this.onSetAccess.bind(this)}
                                            className="text-underline"
                                            data-test-auto="7FDF51B6-41F6-4E58-9A03-C52D36983AF0">
                                                {this.getSetAccessLable()}
                                        </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="padT07">
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th className="w40">#</th>
                                            <th>Included Files</th>
                                            <th>Upload Progress</th>
                                            <th>Size</th>
                                            <th>Status</th>
                                            <th className="w40"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {uploadDataLength > 0 ?
                                            this.state.multiFileReturnUploadData.subDocumentsUploadData.map((value) => {
                                                return <tr key={value.number}>
                                                    <th>{value.number}</th>
                                                    <th>{value.name}</th>
                                                    <th>
                                                        <ProgressBar striped
                                                            variant={(value.progressBar != undefined) && (value.progressBar != 100) ? "warning" : "success"}
                                                            now={value.progressBar} />
                                                    </th>
                                                    <th>{value.size}</th>
                                                    <th>{UploadStatus[value.status].toString()}</th>
                                                    <th>
                                                        <ButtonFormatter deleteReturn={() => this.deleteReturn(value)}
                                                            disabled={(value.progressBar != undefined) && (value.progressBar != 100) ? true : false}
                                                            data-test-auto="B07622E7-FEB7-4759-BA19-4DF7D79BE7E9" />
                                                    </th>
                                                </tr>
                                            }) :
                                            <tr>
                                                <th colSpan={8}>There is no data to display</th>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        this.props.isRecalled ? <Alert variant="warning" style={{ width: "100%", textAlign: "left" }}><i className='fas fa-info-circle'>
                        </i><span style={{ paddingLeft: "5px" }}>{UploadCorrectedReturnWarning.message}</span></Alert> : ""
                    }
                    <Button variant="default" onClick={this.handleUploaderClose}
                        data-test-auto="B1C823D3-8D5E-4B9B-9D59-11F3A0D9BEED">
                        <i className='fas fa-times'></i>
                        Cancel
                        </Button>
                    <Button variant="info" onClick={this.OnSubmitTaxreturns}
                        disabled={this.state.submitDisable || uploadDataLength < 1}
                        data-test-auto="5D0B9B03-68DE-47E7-8681-6EDBD54E005B">
                        <i className='fa fa-check'></i>
                        Submit
                        </Button>
                </Modal.Footer>
            </Modal >
            {this.state.showSetAccess &&
                (
                    <SetAccessCommonModal
                        onCancel={this.onSetAccessHide}
                        onApplyAccess={this.onSetAccessSave}
                        availableUsers={this.state.availableUsers}
                        selectedUsers={this.state.selectedUsers}
                        availableUserGroups={this.state.availableUserGroups}
                        selectedUserGroups={this.state.selectedUserGroups}
                        returnAccessSettings={this.props.returnAccessSettings}
                        isDragAndDropDisabled={true}
                        hide={this.onSetAccessHide}
                    />
                )}
        </div >
    }

}