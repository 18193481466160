import React from  'react';
import Select from 'react-select';
import { prepareStateDropdown, FilterSSN, GetCountryCode } from '../helper/HelperFunctions';
import { ClientInfoProperty } from './ClientInfoEnum';
import { CustomDatePicker } from './controls/CustomDatePicker';
import { ITaxpayer } from './TaxClient';
import { SignatureStatus, ITaxReturn, ClientTypesNumber, DocumentStatus } from './TaxReturn';
import { ICountryState } from '../../Core/ViewModels/Company/CompanyViewModel';
import { TaxIdentificationNumber } from '../common/controls/TaxIdentificationNumber';
import { VenusNotifier } from '../helper/VenusNotifier';
import { ClientInfoTab } from '../helper/Constants';
import { PhoneNumberComponent } from '../common/PhoneNumberComponent';
import { SignerStatus } from './DeliveredTaxReturns';
import { Card } from 'react-bootstrap';
let dateMoment = require('moment');

export interface ISpouseProp {
    model: ITaxpayer;
    handleValueChange(item: ClientInfoProperty, value: string): void;
    states: ICountryState[];
    taxReturn: ITaxReturn;
}

export interface ISpouseState {
    isDisabled: boolean;
    isDeliveredToTaxCaddy: boolean;
}


export class Spouse extends React.Component<ISpouseProp, ISpouseState> {
    constructor(props: ISpouseProp) {
        super(props);
        this.state = {
            isDisabled: false,
            isDeliveredToTaxCaddy: false,
        };
    }

    UNSAFE_componentWillReceiveProps(nextprops: ISpouseProp) {
        if (nextprops.taxReturn.signedDetails != undefined) {

            const spouse = this.props.taxReturn.signedDetails
                .find((x) => x.signerType === ClientTypesNumber.Spouse);

            const isDisabled: boolean = (this.props.taxReturn.signatureStatus === SignatureStatus.PartiallySigned &&
                this.props.taxReturn.signedDetails != undefined &&
                spouse &&
                (spouse.signerStatus == SignerStatus.Reviewed ||
                    spouse.signerStatus == SignerStatus.Signed) ?
                true :
                false)
                || (
                    this.props.taxReturn.signatureStatus === SignatureStatus.ESigned ||
                    this.props.taxReturn.signatureStatus === SignatureStatus.AwaitingReview ||
                    this.props.taxReturn.signatureStatus === SignatureStatus.PartiallyReviewed ||
                    this.props.taxReturn.signatureStatus === SignatureStatus.Reviewed
                );

            const isDeliveredToTaxCaddy: boolean = (nextprops.taxReturn.documentStatus ===
                DocumentStatus.DELIVEREDTOTAXCADDY);

            this.setState({ isDisabled, isDeliveredToTaxCaddy });
        }
    }

    public render() {
        if (!this.props.model) {
            return <div className='col-lg-12' ></div>;
        }
        return <div className='col-lg-12' >

            <Card className="margin-bottom-20">
                <Card.Header>
                    <Card.Title as="h4"><strong>Spouse</strong></Card.Title>
                </Card.Header>
            </Card>

            <div className="row marL0">
                <div className='col-sm-12 col-md-12 col-xl-12'>
                    <div className='row'>
                        <div className='col-sm-2 col-md-2 col-xl-2 padT07 tpLabelFont'>
                            Name <span className="fas fa-asterisk compulsory-star"></span>
                        </div>
                        <div className='col-sm-10 col-md-10 col-xl-10 marB10 padR25'>
                            <input type='text'
                                className='form-control'
                                value={this.props.model.name}
                                onChange={(event: any) => {
                                    this.props.handleValueChange(
                                        ClientInfoProperty.spouseName, event.target.value)
                                }}
                                placeholder="First Name"
                                data-test-auto="AC0D9968-4790-45C4-96D8-36A91CDC8205"
                                disabled={this.state.isDisabled || this.state.isDeliveredToTaxCaddy}
                            >
                            </input>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row marL0">
                <div className='col-sm-12 col-md-12 col-xl-12'>
                    <div className='row'>
                        <div className='col-sm-2 col-md-2 col-xl-2 padT07 tpLabelFont'>
                            Email <span className="fas fa-asterisk compulsory-star"></span>
                        </div>
                        <div className='col-sm-10 col-md-10 col-xl-10 marB10 padR25'>
                            <input type='text'
                                className='form-control'
                                value={this.props.model.email || ''}
                                onChange={(event: any) => {
                                    this.props.handleValueChange(
                                        ClientInfoProperty.spouseEmail, event.target.value);
                                }}
                                placeholder="Email"
                                data-test-auto="DEC0751D-2D77-48A8-A958-5124E7D7D6C4"
                                disabled={this.state.isDeliveredToTaxCaddy}
                            >
                            </input>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row marL0">
                <div className='col-sm-12 col-md-12 col-xl-12'>
                    <div className='row'>
                        <div className='col-sm-2 col-md-2 col-xl-2 padT07 tpLabelFont'>
                            Address
                        </div>
                        <div className='col-sm-10 col-md-10 col-xl-10 marB10 padR25'>
                            <input type='text'
                                className='form-control'
                                value={this.props.model.address}
                                onChange={(event: any) => {
                                    this.props.handleValueChange(
                                        ClientInfoProperty.spouseAddress, event.target.value);
                                }}
                                placeholder="Address"
                                data-test-auto="7E173A8F-EF54-4959-8828-CA8767884A5B"
                                disabled={this.state.isDisabled || this.state.isDeliveredToTaxCaddy}
                            >
                            </input>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row marL0">
                <div className='col-sm-6 col-md-6 col-xl-6'>
                    <div className='row'>
                        <div className='col-sm-4 col-md-4 col-xl-4 padT07 tpLabelFont'>
                            City
                        </div>
                        <div className='col-sm-8 col-md-8 col-xl-8 marB10'>
                            <input type='text'
                                className='form-control'
                                value={this.props.model.city}
                                onChange={(event: any) => {
                                    this.props.handleValueChange(
                                        ClientInfoProperty.spouseCity, event.target.value);
                                }}
                                placeholder="City"
                                data-test-auto="490B543F-D8FA-4FA4-A3D1-6755D41C0500"
                                disabled={this.state.isDisabled || this.state.isDeliveredToTaxCaddy}
                            >
                            </input>
                        </div>
                    </div>
                </div>
                <div className='col-sm-6 col-md-6 col-xl-6'>
                    <div className='row'>
                        <div className='col-sm-4 col-md-4 col-xl-4 padT07 tpLabelFont'>
                            State
                        </div>
                        <div className='col-sm-8 col-md-8 col-xl-8 marB10 padR25'>
                            <Select
                                name="form-field-edit-info-state-spouse"
                                value={this.props.model.state}
                                onChange={(event: any) => {
                                    this.props.handleValueChange(
                                        ClientInfoProperty.spouseState, event.value);
                                }}
                                options={prepareStateDropdown(this.props.states)}
                                data-test-auto="1F29729F-075F-422F-92F9-EC51033A44F5"
                                clearable={false}
                                disabled={this.state.isDisabled || this.state.isDeliveredToTaxCaddy}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row marL0">
                <div className='col-sm-6 col-md-6 col-xl-6'>
                    <div className='row'>
                        <div className='col-sm-4 col-md-4 col-xl-4 padT07 tpLabelFont'>
                            Zip
                        </div>
                        <div className='col-sm-8 col-md-8 col-xl-8 marB10'>
                            <input type='text'
                                className='form-control'
                                value={this.props.model.zip}
                                onChange={(event: any) => {
                                    this.props.handleValueChange(
                                        ClientInfoProperty.spouseZip, event.target.value);
                                }}
                                maxLength={5}
                                placeholder="Zip"
                                data-test-auto="F8EC44D1-A576-4B71-BE4B-C1A844D44E62"
                                disabled={this.state.isDisabled || this.state.isDeliveredToTaxCaddy}
                            >
                            </input>
                        </div>
                    </div>
                </div>
                <div className='col-sm-6 col-md-6 col-xl-6'>
                    <div className='row'>
                        <div className='col-sm-4 col-md-4 col-xl-4 padT07 tpLabelFont'>
                            DOB
                        </div>
                        <div className='col-sm-8 col-md-8 col-xl-8 marB10 padR25'>
                            {
                                <CustomDatePicker
                                    value={this.props.model.dob}
                                    onChange={this.handleChangeDoB}
                                    disabled={this.state.isDisabled || this.state.isDeliveredToTaxCaddy}
                                    maxDate={new Date()}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="row marL0">
                <div className='col-sm-6 col-md-6 col-xl-6'>
                    <div className='row'>
                        <div className='col-sm-4 col-md-4 col-xl-4 padT07 tpLabelFont'>
                            Country Code
                        </div>
                        <div className='col-sm-8 col-md-8 col-xl-8 marB10'>
                            <Select
                                name="form-field-edit-info-state-spouse"
                                value={this.props.model.countryCode}
                                onChange={(event: any) => {
                                    this.props.handleValueChange(
                                        ClientInfoProperty.spouseCountryCode, event ? event.value : "");
                                }}
                                options={GetCountryCode()}
                                data-test-auto="1F29729F-075F-422F-92F9-EC51033A44F5"
                                clearable={true}
                                searchable={true}
                                disabled={this.state.isDisabled || this.state.isDeliveredToTaxCaddy}
                            />
                        </div>
                    </div>
                </div>
                <div className='col-sm-6 col-md-6 col-xl-6'>
                    <div className='row'>
                        <div className='col-sm-4 col-md-4 col-xl-4 padT07 tpLabelFont'>
                            Mobile
                        </div>
                        <div className='col-sm-8 col-md-8 col-xl-8 marB10 padR25'>
                            <PhoneNumberComponent
                                phoneNumber={this.props.model.mobileNumber ? this.props.model.mobileNumber : ""}
                                handleChangePhoneNumber={(value: string) => {
                                    this.props.handleValueChange(
                                        ClientInfoProperty.spouseMobileNumber, value);
                                }}
                                disabled={this.state.isDisabled || this.state.isDeliveredToTaxCaddy}
                            >
                            </PhoneNumberComponent>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row marL0 justify-flex-end">
                <div className='col-sm-6 col-md-6 col-xl-6'>
                    <div className='row'>
                        <div className='col-sm-4 col-md-4 col-xl-4 padT07 tpLabelFont'>
                            SSN
                        </div>
                        <div className='col-sm-8 col-md-8 col-xl-8 marB10'>
                            <TaxIdentificationNumber
                                value={this.props.model ? FilterSSN(this.props.model.ssn) : ""}
                                onChange={(value: string) => {
                                    this.props.handleValueChange(
                                        ClientInfoProperty.spouseSSN, value);
                                }}
                                placeholder="SSN"
                                mask='000-00-0000'
                                disabled={this.state.isDisabled || this.state.isDeliveredToTaxCaddy}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>;
    }



    private handleChangeDoB = (event: any) => {
        let now = new Date();
        if (dateMoment(event) > now) {
            VenusNotifier.Warning(ClientInfoTab.AddedRefund.DOBWarning, null);
        } else {
            this.props.handleValueChange(ClientInfoProperty.spouseDateOfBirth, event);
        }
    }

}
export default Spouse;
