import { addTask } from 'domain-task'
import {  Reducer } from 'redux'
import { AppThunkAction } from '../'
import { actionTypes } from '../../store/ActionTypes'
import { IOTPState } from '../../components/navigation/profile/ProfileObjects'
import { StatusType, NotificationAction } from '../common/NotificationStore'
import { handleResponse } from '../Library'
import { OTPVerify } from '../../components/helper/Constants'
import { SUITE_API_BASE_URL } from '../../utils/contants'

export interface IOneTimePasswordState {
    otpState: IOTPState
    isVerifyLoading: boolean
}

interface ReceiveOneTimePasswordAction {
    type: actionTypes.RECEIVE_ONETIMEPASSWORD
    otpState: IOTPState
    isVerifyLoading: boolean
}

interface RequestOneTimePasswordAction {
    type: actionTypes.REQUEST_ONETIMEPASSWORD
    isVerifyLoading: boolean
}

interface ValidateOneTimePasswordAction {
    type: actionTypes.VALIDATE_ONETIMEPASSWORD
    otpState: IOTPState
    isVerifyLoading: boolean
}
const unloadedState: IOneTimePasswordState = {
    otpState: {
        validOTP: false,
        mfaOTPLength: 0,
    },
    isVerifyLoading: false,
}

type KnownAction =
    | RequestOneTimePasswordAction
    | ReceiveOneTimePasswordAction
    | ValidateOneTimePasswordAction
    | NotificationAction

export const actionCreators = {
    requestMFAOTPLength: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REQUEST_ONETIMEPASSWORD, isVerifyLoading: true })
        let fetchTask = fetch(SUITE_API_BASE_URL + 'api/helper/mfa-otp-length', {
            method: 'GET',
            credentials: 'include',
        })
            .then(handleResponse)
            .then(data => {
                dispatch({
                    type: actionTypes.RECEIVE_ONETIMEPASSWORD,
                    otpState: { ...data, mfaOTPLength: data },
                    isVerifyLoading: false,
                })
            })
            .catch(function (error) {
                const statusMessage: any = error.statusText
                if (typeof statusMessage === 'string') {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: statusMessage,
                        statusType: StatusType.Error,
                    })
                }
            })
        addTask(fetchTask)
    },

    saveOneTimePassword:
        (mobileNumber: string, countryCode: string,onResponse:()=>void): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            dispatch({ type: actionTypes.REQUEST_ONETIMEPASSWORD, isVerifyLoading: true })
            let fetchTask = fetch(SUITE_API_BASE_URL + 'api/otp/send-otp', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    RequestVerificationToken: (document.getElementById('RequestVerificationToken') as HTMLInputElement)
                        .value,
                },
                body: JSON.stringify({ mobileNumber: mobileNumber, countryCode: countryCode }),
            })
                .then(handleResponse)
                .then(response => {
                    dispatch({ type: actionTypes.REQUEST_ONETIMEPASSWORD, isVerifyLoading: false })
                        onResponse()
                })
                .catch(function (error) {
                    const statusMessage: any = error.statusText
                    if (typeof statusMessage === 'string') {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: statusMessage,
                            statusType: StatusType.Error,
                        })
                    }
                })
            addTask(fetchTask)
        },

    validateOTP:
        (otp: string, mobileNumber: string, callback: () => void,callback2:()=>void): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            dispatch({ type: actionTypes.REQUEST_ONETIMEPASSWORD, isVerifyLoading: true })

            let fetchTask = fetch(SUITE_API_BASE_URL + 'api/otp/verify-otp', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    RequestVerificationToken: (document.getElementById('RequestVerificationToken') as HTMLInputElement)
                        .value,
                },
                body: JSON.stringify({ otp: otp, mobileNumber: mobileNumber }),
            })
                .then(handleResponse)
                .then(data => {
                    dispatch({
                        type: actionTypes.VALIDATE_ONETIMEPASSWORD,
                        otpState: { ...data, validOTP: data },
                        isVerifyLoading: false,
                    })
                    callback2()
                    if (data) {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: OTPVerify.validOTP,
                            statusType: StatusType.Success,
                        })
                        callback()
                    } else {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: OTPVerify.InvalidOTP,
                            statusType: StatusType.Warning,
                        })
                    }
                })
                .catch(function (error) {
                    const statusMessage: any = error.statusText
                    if (typeof statusMessage === 'string') {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: statusMessage,
                            statusType: StatusType.Error,
                        })
                    }
                })
            addTask(fetchTask)
        },
}

export const reducer: Reducer<IOneTimePasswordState> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as KnownAction
    switch (action.type) {
        case actionTypes.REQUEST_ONETIMEPASSWORD:
            return {
                ...state,
                otpState: state.otpState,
                isVerifyLoading: action.isVerifyLoading,
            }
        case actionTypes.RECEIVE_ONETIMEPASSWORD:
            return {
                ...state,
                otpState: action.otpState,
                isVerifyLoading: false,
            }
        case actionTypes.VALIDATE_ONETIMEPASSWORD:
            let received: IOneTimePasswordState = { ...state }
            received.otpState.validOTP = action.otpState.validOTP
            return {
                ...received,
                isVerifyLoading: action.isVerifyLoading,
            }
    }
    return state
}
