import { actionTypes } from "../ActionTypes";
import * as Constants from '../../components/helper/Constants';
import { initialMFASetting, ISuiteMFASetting } from "../../Core/ViewModels/Company/CompanySettingsViewModel";
import { handleResponse } from "../Library";
import { NotificationAction, StatusType } from '../common/NotificationStore';
import { Reducer } from 'redux';
import { AppThunkAction } from "../index";
import { addTask } from 'domain-task';
import { SUITE_API_BASE_URL } from "src/utils/contants";

export interface RequestMFASettingAction {
    type: actionTypes.REQUEST_MFA_SETTING;
}

export interface ReceiveMFASettingAction {
    type: actionTypes.RECEIVE_MFA_SETTING;
    mfaSetting: ISuiteMFASetting;
}

type DispatchAction =
    RequestMFASettingAction |
    ReceiveMFASettingAction |
    NotificationAction

type KnownAction =
    DispatchAction

export const actionCreators = {
    requestMFASetting: (): AppThunkAction<KnownAction> => (dispatch) => {

        const fetchTask = fetch(SUITE_API_BASE_URL + '/api/mfa/mfa-setting', {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_MFA_SETTING, mfaSetting: data });
            })
            .catch(error => {
                if (error.status != 512) {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.CompanySettingsConstants.StatusMessage.CompanySettingsError,
                        statusType: StatusType.Error
                    })
                }
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_MFA_SETTING });
    },
}

export const reducer: Reducer<ISuiteMFASetting> = (state = initialMFASetting, incomingAction) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_MFA_SETTING:
            return state;
        case actionTypes.RECEIVE_MFA_SETTING:
            let mfaSetting = { ...state };
            mfaSetting.isMfaEnabled = action.mfaSetting.isMfaEnabled;
            mfaSetting.iSTPMfaEnabled = action.mfaSetting.iSTPMfaEnabled;
            mfaSetting.skipMfaForIP = action.mfaSetting.skipMfaForIP;
            mfaSetting.trustedDeviceExpiryDays = action.mfaSetting.trustedDeviceExpiryDays;
            mfaSetting.isAllowUsertoRememberTrustedDevice = action.mfaSetting.isAllowUsertoRememberTrustedDevice;
            return mfaSetting;

        default: return state;
    }
}
