import { Action, Reducer } from 'redux';
import { Product } from 'src/Core/ViewModels/User/UserViewModel';
import { SUITE_API_BASE_URL } from 'src/utils/contants';
import { AppThunkAction } from "../";
import { actionTypes } from "../ActionTypes";
import { NotificationAction } from '../common/NotificationStore';
import { handleResponse } from '../Library';
import { IMenuSection } from "@sssuite-component-ui/ss-suite-layout/dist/SideMenu/SideMenu";
import { ISectionData } from '@sssuite-component-ui/ss-suite-layout/dist/Header/NavigationWidget/NavigationWidget'
import { ResourceValidator } from '@sssuite-component-ui/resource-id-validator'
import { RBACPermission } from 'src/components/helper/RbacConstants';
import { RouteConstants } from 'src/components/helper/Constants';

let loaded = false;
export let validator: ResourceValidator;

export interface RBACStore {
    resourceList: string[];
    leftMenus: IMenuSection[];
    navigationMenus: ISectionData[];
    suiteUrl: string;
    isExtensionEnabled: boolean;
    extensionLoading: boolean;
    isExtensionDataLoaded: boolean;
}

export interface RequestResourceIdList {
    type: actionTypes.REQUEST_RESOURCE_ID_LIST;
}

export interface ReceiveResourceIdList {
    type: actionTypes.RECEIVE_RESOURCE_ID_LIST;
    payload: string[];
}

export interface CompleteResourceIdList {
    type: actionTypes.COMPLETE_RESOURCE_ID_LIST;
}

interface ReceiveLeftMenu {
    type: actionTypes.RECEIVE_LEFT_MENU;
    menus: IMenuSection[];
}

interface ReceiveNavigationMenu {
    type: actionTypes.RECEIVE_NAVIGATION_MENU;
    menus: ISectionData[];
}

interface ReceiveSuiteUrl {
    type: actionTypes.RECEIVE_SUITE_URL;
    url: string;
}

interface ReceiveExtensionEnabled {
    type: actionTypes.RECEIVE_EXTENSION_ENABLED;
    enabled: boolean;
}

type KnownAction = NotificationAction |
    RequestResourceIdList |
    ReceiveResourceIdList |
    CompleteResourceIdList |
    ReceiveLeftMenu |
    ReceiveNavigationMenu |
    ReceiveSuiteUrl |
    ReceiveExtensionEnabled;

export const actionCreators = {

    requestResourceIdList: (): AppThunkAction<KnownAction> => (dispatch) => 
    {
        !loaded && fetch(`${SUITE_API_BASE_URL}/api/resource/${Product.Extensions}`, { credentials: 'include' })
            .then(handleResponse)
            .then(json => json)
            .then(data => {
                validator = new ResourceValidator(data, 'disable', 'click', RBACPermission.operation);
                validator.start();
                dispatch({ type: actionTypes.RECEIVE_EXTENSION_ENABLED, enabled: !!data.length });
                loaded = true;
            })

    },

    getLeftMenus: (navigate: (route: string) => void): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`${SUITE_API_BASE_URL}/api/menu/product-left-menu/${Product.Extensions}`, { credentials: 'include' })
            .then(handleResponse)
            .then((data: IMenuSection[]) => {
                if (data.length > 0) {

                    const settingSection = data.find(section => section.items.some(menu => menu.caption && menu.caption.toLowerCase() === RouteConstants.SETTINGSCAPTION));
                    const removeSettings = settingSection.items.some(menu => menu.disabled);

                    if (removeSettings) {
                        data = data.filter(section => !section.items.some(menu => menu.caption && menu.caption.toLowerCase() === RouteConstants.SETTINGSCAPTION));
                    }
                }
                dispatch({ type: actionTypes.RECEIVE_LEFT_MENU, menus: data });
            })
    },



    getNavigationMenu: (): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`${SUITE_API_BASE_URL}/api/menu/widget-menu/${Product.Extensions}`, { credentials: 'include' })
            .then(handleResponse)
            .then((data: ISectionData[]) => {
                dispatch({ type: actionTypes.RECEIVE_NAVIGATION_MENU, menus: data });
            })
    },

    getSuiteUrl: (): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`${SUITE_API_BASE_URL}/api/helper/suite-url`, { credentials: 'include' })
            .then(response => response.text())
            .then((data: string) => {
                dispatch({ type: actionTypes.RECEIVE_SUITE_URL, url: data });
            })
    }
};

const unloadedState: RBACStore = {
    resourceList: [],
    leftMenus: [],
    navigationMenus: [],
    suiteUrl: "",
    isExtensionEnabled: false,
    extensionLoading: true,
    isExtensionDataLoaded: false
}

export const reducer: Reducer<RBACStore> = (state: RBACStore = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {

        case actionTypes.RECEIVE_RESOURCE_ID_LIST:
            const draftState = { ...state };
            draftState.resourceList = action.payload;
            return draftState;

        case actionTypes.RECEIVE_LEFT_MENU: {
            const draftState = { ...state };
            draftState.leftMenus = action.menus;
            return draftState;
        }

        case actionTypes.RECEIVE_NAVIGATION_MENU: {
            const draftState = { ...state };
            draftState.navigationMenus = action.menus;
            return draftState;
        }

        case actionTypes.RECEIVE_SUITE_URL: {
            const draftState = { ...state };
            draftState.suiteUrl = action.url;
            return draftState;
        }

        case actionTypes.RECEIVE_EXTENSION_ENABLED: {
            const draftState = { ...state };
            draftState.isExtensionEnabled = action.enabled;
            draftState.extensionLoading = false;
            draftState.isExtensionDataLoaded = true;
            return draftState;
        }
    }
    return state || unloadedState;
};