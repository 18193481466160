import React from 'react'
import { Router, Route, Switch } from 'react-router'
import { Provider } from 'react-redux'
import store from './store'
import AuthProvider from './oidcClient/AuthProvider'
import { Routes } from './routes'
import history from './routes/history'
import RestrictedAccess from './components/RestrictedAccess'

const App = () => {
    return (
        <Provider store={store}>
            <AuthProvider store={store}>
                <Router history={history}>
                    <Switch>
                        <Route exact path="/restricted-access" component={RestrictedAccess} />
                        <Route path="/" component={Routes} />
                    </Switch>

                </Router>
            </AuthProvider>
      </Provider>
    )   
}

export default App
