import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../ActionTypes';
import { ICompanySettings, initialCompanySettings, IPendoData } from '../../Core/ViewModels/Company/CompanySettingsViewModel';
import { ITaxingAuthority, DeliveryMode } from '../../components/common/TaxReturn';
import {
    ICompanyLogoSetting, IState, IAdmin, ICompany, ICompanyInfo, ICompanyWhiteLogoSetting,
    initialCompanyLogoSettings, initialAdminModel, initialCompanyModel, initialCompanyWhiteLogoSettings, initalMyDownloadsList
} from '../../Core/ViewModels/Company/CompanyViewModel';
import { handleResponse, handleBlob } from '../Library';
import { NotificationAction, StatusType } from '../common/NotificationStore';
import { push, RouterAction } from 'connected-react-router';
import * as Constants from '../../components/helper/Constants';
import { validateCompanySettings } from '../../components/helper/Validations';
import { IDefinedPasswordPolicy } from '../../Core/ViewModels/Common/DefinedPasswordPolicy';
import { ICompanyLedgerModel, BillingReceiptType } from '../../Core/ViewModels/Billing/CompanyLedgerModel';
import { DisplayDownloadFile } from '../../components/common/DisplayDownloadFile';
import { RequestExpireDocumentsCount, ReceiveExpireDocumentsCount, ErrorExpireTaxDocumentCountAction } from "../reports/KnownTypes";
import { IDownloadedZipFilesModel, StatusZipDownload } from '../../components/navigation/profile/MyDownload';
import * as Helper from '../../components/helper/HelperFunctions';
import { API_BASE_URL, SUITE_API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';

const isEqual = require("react-fast-compare");

export interface IAuthorityDictionary {
    [index: number]: ITaxingAuthority;
}

export interface ICompanyData { //TODO: should be a dictionary so that we can pick a user's details with 'userId' from this.
    //DataPart
    companySettings: ICompanySettings | undefined;
    taxingAuthorities: ITaxingAuthority[];
    companyProfile: ICompany;
    admins: IAdmin;
    companyLogoSetting: ICompanyLogoSetting;
    signatureUploadLink: string;
    logoUploadLink: string;
    authorityLookup: IAuthorityDictionary;
    definedPasswordPolicies: IDefinedPasswordPolicy[];
    ledgerData: ICompanyLedgerModel[];
    groupIDs: string[];
    myDownloads: IDownloadedZipFilesModel[];

    companyWhiteLogoSetting: ICompanyWhiteLogoSetting;
    whiteLogoUploadLink: string;


    //Meta infomation
    isLoading: boolean;
    error: boolean;
    message: string;

    //extra information
    documentToExpireCount: number;
    isDocumentStoreDirty: boolean;
    pendoData: IPendoData;
}

export interface ReceivePendoDataAction {
    type: actionTypes.RECEIVE_PENDO_DATA;
    pendoData: IPendoData;
}

export interface RequestCompanySettingsAction {
    type: actionTypes.REQUEST_COMPANY_SETTINGS;
    message: string;
}

export interface GetMyDownloadsList {
    type: actionTypes.RECEIVE_MY_DOWNLOADS_LIST;
    myDownloadsList: any[];
}

export interface ReceiveCompanySettingsAction {
    type: actionTypes.RECEIVE_COMPANY_SETTINGS;
    settings: ICompanySettings;
}

export interface UpdateCompanySettingsAction {
    type: actionTypes.UPDATE_COMPANY_SETTINGS;
    settings: ICompanySettings;
}

export interface ErrorCompanySettingsAction {
    type: actionTypes.ERROR_COMPANY_SETTINGS;
    reason: string;
}

export interface RequestTaxAuthoritiesAction {
    type: actionTypes.REQUEST_TAX_AUTHORITIES;
}

export interface ReceiveTaxAuthoritiesAction {
    type: actionTypes.RECEIVE_TAX_AUTHORITIES;
    taxingAuthorities: ITaxingAuthority[];
}

export interface ReceiveCompanyLogoAction {
    type: actionTypes.RECEIVE_COMPANY_LOGO;
    logoPath: string;
    isDefaultLogo: boolean;
}

export interface ReceiveCompanyProfileAction {
    type: actionTypes.RECEIVE_COMPANY_PROFILE;
    companyProfile: ICompany;
}

export interface ReceiveAdminProfileAction {
    type: actionTypes.RECEIVE_ADMIN_PROFILE;
    admins: IAdmin;
}

export interface RequestCompanySignatureUploadLinkAction {
    type: actionTypes.UPDATE_COMPANY_SIGNATURE;
    sasUrl: string;
}

export interface RequestCompanyLogoUploadLinkAction {
    type: actionTypes.UPDATE_COMPANY_LOGO;
    sasUrl: string;
}

export interface RequestCompanyWhiteLogoUplaodLinkAction {
    type: actionTypes.UPDATE_COMPANY_WHITE_LOGO;
    sasUrl: string;
    whiteLogoPath: string;
}

export interface ReceiveCompanyWhiteLogoAction {
    type: actionTypes.RECEIVE_COMPANY_WHITE_LOGO;
    whiteLogoPath: string;
}

export interface DeleteCompanyLogoAction {
    type: actionTypes.DELETE_COMPANY_LOGO;
}

export interface ValidateAzureADGroupAction {
    type: actionTypes.VALIDATE_AD_AZURE_GROUP;
    groupIDs: string[];
}

export interface RefreshTaxDocumentStore {
    type: actionTypes.REFRESH_TAX_DOCUMENT_STORE;
    isDirty: boolean;
}

interface ReceiveDefinedPasswordPolicyAction {
    type: actionTypes.RECEIVE_DEFINED_PASSWORD_POLICY;
    definedPasswordPolicies: IDefinedPasswordPolicy[];
}

interface ReceiveCompanyLedger {
    type: actionTypes.RECEIVE_COMPANY_LEDGER;
    ledgerData: ICompanyLedgerModel[];
}

type DispatchAction = RequestCompanySettingsAction |
    ReceiveCompanySettingsAction |
    ErrorCompanySettingsAction |
    RequestTaxAuthoritiesAction |
    ReceiveTaxAuthoritiesAction |
    ReceiveCompanyLogoAction |
    ReceiveCompanyProfileAction |
    ReceiveAdminProfileAction |
    RequestCompanySignatureUploadLinkAction |
    RequestCompanyLogoUploadLinkAction |
    NotificationAction |
    ReceiveDefinedPasswordPolicyAction |
    ReceiveCompanyLedger |
    RequestExpireDocumentsCount |
    ReceiveExpireDocumentsCount |
    ErrorExpireTaxDocumentCountAction |
    ValidateAzureADGroupAction |
    RefreshTaxDocumentStore |
    RequestCompanyWhiteLogoUplaodLinkAction |
    ReceiveCompanyWhiteLogoAction |
    GetMyDownloadsList |
    ReceivePendoDataAction;


type KnownAction =
    DispatchAction |
    UpdateCompanySettingsAction |
    RouterAction |
    RequestExpireDocumentsCount |
    ReceiveExpireDocumentsCount |
    ErrorExpireTaxDocumentCountAction;

export const actionCreators = {
    requestCompanySettings: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState().companyData;
        if (reload || !state.companySettings || isEqual(state.companySettings, initialCompanySettings)) {
            const fetchTask = fetch(`${API_BASE_URL}api/Company/CompanySettings/GetCompanySettings`, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(json => json as Promise<ICompanySettings>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_COMPANY_SETTINGS, settings: data });
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.CompanySettingsConstants.StatusMessage.CompanySettingsError,
                        statusType: StatusType.Error
                    })
                    dispatch({
                        type: actionTypes.ERROR_COMPANY_SETTINGS,
                        reason: error.message,
                    });
                    logger.trackError(`requestCompanySettings failed with error ${error.message}`);

                });
            addTask(fetchTask);
            dispatch({
                type: actionTypes.REQUEST_COMPANY_SETTINGS,
                message: Constants.CompanySettingsConstants.OverlayMessage.ApplicationLoading
            });
        }
    },

    updateCompanySettings: (companySettings: ICompanySettings, navigate: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (!validateCompanySettings(companySettings)) {
            dispatch({
                type: actionTypes.NOTIFICATION,
                statusMessage: Constants.CompanySettingsConstants.StatusMessage.UpdateCompanySettingsError,
                statusType: StatusType.Error
            });
        } else {

            const fetchTask = fetch(`${API_BASE_URL}api/Company/CompanySettings/`, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json, text/plain, *',
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify(companySettings)
            })
                .then(handleResponse)
                .then((response) => {
                    dispatch({ type: actionTypes.RECEIVE_COMPANY_SETTINGS, settings: companySettings });

                    //settings save and close
                    if (navigate) {
                        dispatch(push('/CompanyAssignments'));
                    }
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.CompanySettingsConstants.StatusMessage.UpdateCompanySettingsSuccess,
                        statusType: StatusType.Success
                    })
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.CompanySettingsConstants.StatusMessage.UpdateCompanySettingsError,
                        statusType: StatusType.Error
                    });
                    dispatch({
                        type: actionTypes.ERROR_COMPANY_SETTINGS,
                        reason: error.message,
                    });
                    logger.trackError(`updateCompanySettings failed for companySettings: ${companySettings}, with error ${error.message}`);
                })

            addTask(fetchTask);
            dispatch({
                type: actionTypes.REQUEST_COMPANY_SETTINGS,
                message: Constants.CompanySettingsConstants.OverlayMessage.UpdatingCompanySettings
            });
        }
    },

    requestTaxingAuthorities: (forceReload?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState().companyData;
        if (!state.taxingAuthorities || forceReload) {
            //TODO: read from server

            const fetchTask = fetch(`${API_BASE_URL}api/Common/GetTaxingAuthorityAsync`, { credentials: 'include' })
                .then(handleResponse)
                .then(json => json as Promise<ITaxingAuthority[]>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_TAX_AUTHORITIES, taxingAuthorities: data });
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.CompanySettingsConstants.StatusMessage.CompanySettingsError,
                        statusType: StatusType.Error
                    })
                    dispatch({
                        type: actionTypes.ERROR_COMPANY_SETTINGS,
                        reason: error.message,
                    });
                    logger.trackError(`requestTaxingAuthorities failed with error ${error.message}`);

                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_TAX_AUTHORITIES });
        }
    },

    requestCompanyLogo: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (reload) {
            const fetchTask = fetch(`${API_BASE_URL}/api/download/GetCompanyLogoLinkAsync`, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(json => json)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_COMPANY_LOGO, logoPath: data.logoPath, isDefaultLogo: data.isDefaultLogo });
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.CompanySettingsConstants.StatusMessage.CompanyLogoError,
                        statusType: StatusType.Error
                    })
                    logger.trackError(`requestCompanyLogo failed with error ${error.message}`);
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_COMPANY_SETTINGS, message: Constants.CompanySettingsConstants.OverlayMessage.ApplicationLoading });
        }
    },

    requestCompanyProfile: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState().companyData;
        if (reload || !state.companyProfile || state.companyProfile.companyInfo.companyName.length === 0) {
            const fetchTask = fetch(`${API_BASE_URL}api/Company/GetCompanyProfile`, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(json => json as Promise<IState>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_COMPANY_PROFILE, companyProfile: data.company });
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.CompanySettingsConstants.StatusMessage.CompanyProfileError,
                        statusType: StatusType.Error
                    })
                    logger.trackError(`requestCompanyProfile failed with error ${error.message}`);
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_COMPANY_SETTINGS, message: Constants.CompanySettingsConstants.OverlayMessage.ApplicationLoading });
        }
    },

    requestCompanySignatureUploadLink: (callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}/api/Upload/GetCompanySignatureUploadLinkAsync`, {
            method: 'GET',
            credentials: 'include'
        })
            .then((resp) => resp.json())
            .then(data => {
                dispatch({ type: actionTypes.UPDATE_COMPANY_SIGNATURE, sasUrl: data.sas });
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_COMPANY_SETTINGS, reason: error.message });

                dispatch({
                    type: actionTypes.NOTIFICATION, statusType: StatusType.Error,
                    statusMessage: Constants.CompanySettingsConstants.StatusMessage.UpdateCompanySettingsError
                });
                logger.trackError(`requestCompanySignatureUploadLink failed with error ${error.message}`);
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_COMPANY_SETTINGS, message: Constants.CompanySettingsConstants.OverlayMessage.ApplicationLoading });
    },

    requestCompanyLogoUploadLink: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}/api/Upload/GetCompanyLogoUploadLinkAsync`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(json => json)
            .then(data => {
                dispatch({ type: actionTypes.UPDATE_COMPANY_LOGO, sasUrl: data.sas });
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_COMPANY_SETTINGS, reason: error.message });

                dispatch({
                    type: actionTypes.NOTIFICATION, statusType: StatusType.Error,
                    statusMessage: Constants.CompanySettingsConstants.StatusMessage.UpdateCompanyLogoError
                });
                logger.trackError(`requestCompanyLogoUploadLink failed with error ${error.message}`);
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_COMPANY_SETTINGS, message: Constants.CompanySettingsConstants.OverlayMessage.ApplicationLoading });
    },

    generateBillingReceipt: (receiptType: BillingReceiptType, date: string, transactionId?: number, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fileName = 'Reciept.pdf';
        const fetchTask = fetch(`${API_BASE_URL}api/Billing/GetBillingReceipt/?receiptType=${receiptType}&transactionId=${transactionId}&date=${date}`, {
            method: 'GET',
            credentials: 'include'
        }).then(response => {
            const contentDisposition = response.headers.get("content-disposition");
            const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
            if (fileNameMatch && fileNameMatch.length > 1) {
                fileName = fileNameMatch[1];
            }
            return response;
        })
            .then(handleBlob)
            .then((data) => {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.showFile(data, fileName);

                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.statusText,
                    statusType: StatusType.Error
                });
                logger.trackError(`generateBillingReceipt failed for transactionId: ${transactionId}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    downloadAllBillingReceipts: (billNo: number, receiptType: BillingReceiptType): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const formData = new FormData();
        formData.append('billNo', JSON.stringify(billNo));
        formData.append('receiptType', JSON.stringify(receiptType));
        let fileName = 'Reciept.pdf';
        fetch(`${API_BASE_URL}api/Download/GetDownloadAllBillingReceiptAsync`, {
            method: 'POST',
            credentials: 'include',
            body: formData,
            headers: {
                'Accept': 'application/json'
            },
        }).then(response => {
            const contentDisposition = response.headers.get("content-disposition");
            const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
            if (fileNameMatch && fileNameMatch.length > 1) {
                fileName = fileNameMatch[1];
            }
            return response;
        })
            .then(handleBlob)
            .then((data) => {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.showFile(data, fileName);
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.statusText,
                    statusType: StatusType.Error
                });
                logger.trackError(`downloadAllBillingReceipts failed for billNo: ${billNo}, with error ${error.message}`);
            });
    },

    requestDocumentsToexpire: (retentionPeriod: number, attestRetentionPeriod: number, callback: (count: number) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(`${API_BASE_URL}api/TaxDocument/GetDocumentsToExpire/` + retentionPeriod + '/' + attestRetentionPeriod, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as Promise<number>)
            .then(data => {
                callback(data);

            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_EXPIRE_TAX_DOCUMENT_COUNT, message: error });
                logger.trackError(`requestDocumentsToexpire failed for retentionPeriod: ${retentionPeriod}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    updateRetentionForAllDocuments: (defaultDuration: number, attestDuration: number): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(`${API_BASE_URL}api/TaxDocument/UpdateRetentionPeriod/`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, *',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({ "defaultRetention": defaultDuration, "attestRetention": attestDuration })
        })
            .then(handleResponse)
            .then(() => {
                //dispatch({
                //    type: actionTypes.NOTIFICATION, statusMessage: Constants.CompanySettingsConstants.StatusMessage.UpdateRetentionForAllDocuments,
                //    statusType: StatusType.Success
                //});
            })
            .catch(error => {
                //dispatch({ type: actionTypes.ERROR_COMPANY_SETTINGS, reason: error.message });

                dispatch({
                    type: actionTypes.NOTIFICATION, statusType: StatusType.Error,
                    statusMessage: Constants.CompanySettingsConstants.StatusMessage.UpdateRetentionPeriodError
                });
                logger.trackError(`updateRetentionForAllDocuments failed for defaultDuration: ${defaultDuration}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    forceRefreshTaxDcoumentStore: (forceReload: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REFRESH_TAX_DOCUMENT_STORE, isDirty: forceReload });
    },

    requestCompanyWhiteLogo: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {

        if (reload || !getState().companyData.companyWhiteLogoSetting.isWhiteLogoDelted) {
            const fetchTask = fetch(`${API_BASE_URL}/api/download/GetCompanyWhiteLogoLinkAsync`, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then((data) => {

                    dispatch({ type: actionTypes.RECEIVE_COMPANY_WHITE_LOGO, whiteLogoPath: data });

                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.BrandingConstants.StatusMessage.UpdateCompanyWhiteLogoError,
                        statusType: StatusType.Error
                    })
                    logger.trackError(`requestCompanyWhiteLogo failed with error ${error.message}`);
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_COMPANY_SETTINGS, message: Constants.BrandingConstants.OverlayMessage.ApplicationLoading });
        }
    },

    requestCompanyWhiteLogoUploadLink: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}/api/Upload/GetCompanyWhiteLogoUploadAsync`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(json => json)
            .then(data => {
                dispatch({ type: actionTypes.UPDATE_COMPANY_WHITE_LOGO, sasUrl: data.sas, whiteLogoPath: data.whiteLogoPath });
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_COMPANY_SETTINGS, reason: error.message });

                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusType: StatusType.Error,
                    statusMessage: Constants.BrandingConstants.StatusMessage.UpdateCompanyWhiteLogoError
                });
                logger.trackError(`requestCompanyWhiteLogoUploadLink failed with error ${error.message}`);
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_COMPANY_SETTINGS, message: Constants.BrandingConstants.OverlayMessage.ApplicationLoading });
    },

    getMyDownloadsListAsync: (callback?: (result: any[]) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let options: any = {
            method: 'GET',
            credentials: 'include'
        };
        var fetchTask = fetch(`${API_BASE_URL}api/Download/GetMyDownloadsListAsync`, options)
            .then(handleResponse)

            .then(response => response as Promise<any[]>)
            .then(data => {
                if (data.length > 0 && Helper.SessionStore.isExists("DownloadNowIds")) {
                    let downloadNowIds = Helper.SessionStore.get("DownloadNowIds");
                    var ids = JSON.parse(downloadNowIds) as string[];
                    let updatedDownloadNowIds: string[] = [];
                    ids.forEach((id, i) => {
                        var downoadFile = data.find(i => i.id == id);
                        if (downoadFile != undefined) {
                            if (downoadFile.status != StatusZipDownload[StatusZipDownload.InProgress] && downoadFile.status != StatusZipDownload[StatusZipDownload.Error] && downoadFile.status != StatusZipDownload[StatusZipDownload.None]) {
                                if (downoadFile.status != StatusZipDownload[StatusZipDownload.DownloadNow]) {
                                    dispatch({
                                        type: actionTypes.NOTIFICATION,
                                        statusMessage: "File is ready for download",
                                        statusType: StatusType.Success
                                    });
                                }
                                let downoadAction: any = actionCreators.downloadReturnFromPopup(downoadFile.jobId, downoadFile.fileName, true, getDownloadSuccessDetails);
                                dispatch(downoadAction);
                            }
                            else {
                                updatedDownloadNowIds.push(id);
                            }
                            if (downoadFile.status == StatusZipDownload[StatusZipDownload.DownloadNow]) {
                                let myDownloads: any[] = data.filter(myDownload => myDownload.id != downoadFile.id);
                                data = myDownloads;
                            }
                        }
                    });

                    Helper.SessionStore.set("DownloadNowIds", JSON.stringify(updatedDownloadNowIds));
                }
                if (callback) {
                    callback(data);
                }
                dispatch({ type: actionTypes.RECEIVE_MY_DOWNLOADS_LIST, myDownloadsList: data })
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_COMPANY_SETTINGS, reason: error.message, });
            });
        addTask(fetchTask);
    },

    downloadReturnFromPopup: (jobId: string, fileName: string, isDownloadNow?: boolean, callback?: (url: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fetchTask = fetch(`${API_BASE_URL}/api/Download/DownloadReturnFromPopupAsync`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                jobId: jobId,
                fileName: fileName
            })
        })
            .then(handleResponse)
            .then(json => json)
            .then(data => {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.directDownload(data.sas, fileName);
                fetch(`${API_BASE_URL}/api/Download/UpdateStatusBulkDownLoadAsync`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        jobId: jobId,
                        fileName: fileName
                    })
                }).then((data) => {
                    if (callback) {
                        if (isDownloadNow != null && isDownloadNow) {
                            //wait for 3 seconds for dload to fetch
                            setTimeout(function () {
                                let deleteaction: any = actionCreators.deleteMyDownloads(jobId, isDownloadNow);
                                dispatch(deleteaction);
                            }, 3000);

                        }
                    }
                });
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to Download the Selected File",
                    statusType: StatusType.Error
                });
                logger.trackError(`downloadReturnFromPopup failed for jobId: ${jobId}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    deleteMyDownloads: (jobId: string, isDownloadNow?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        let fetchTask = fetch(`${API_BASE_URL}api/Download/DeleteMyDownloadAsync?downloadId=` + jobId, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(handleResponse)
            .then((response) => {

                let data: any = state.companyData.myDownloads;
                const newMyDownloadsList = data.filter((m: any) => m.jobId != jobId);

                dispatch({ type: actionTypes.RECEIVE_MY_DOWNLOADS_LIST, myDownloadsList: newMyDownloadsList });
                if (isDownloadNow == undefined || !isDownloadNow) {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: Constants.MyDownloadsConstants.DeleteMyDownload,
                        statusType: StatusType.Success
                    });
                }
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to Delete the Selected File",
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.ERROR_COMPANY_SETTINGS, reason: error.message, });
                logger.trackError(`deleteMyDownloads failed for jobId: ${jobId}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    clearAllMyDownloads: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        let fetchTask = fetch(`${API_BASE_URL}api/Download/ClearAllDownloadsAsync`, {
            method: 'DELETE',
            credentials: 'include'
        }).then(handleResponse)
            .then((response) => {

                let data = state.companyData.myDownloads;
                data = data.filter(function (download: any) {
                    return download.status == StatusZipDownload[StatusZipDownload.InProgress];
                });

                dispatch({ type: actionTypes.RECEIVE_MY_DOWNLOADS_LIST, myDownloadsList: data });

                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.MyDownloadsConstants.ClearAllMyDownloads,
                    statusType: StatusType.Success
                });

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to Clear your Downloads",
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.ERROR_COMPANY_SETTINGS, reason: error.message, });
                logger.trackError(`clearAllMyDownloads failed with error ${error.message}`);
            });
        addTask(fetchTask);
    },

    requestUsageLedgerData: (fromDate: Date, toDate: Date, deliveryMode: DeliveryMode, callback?: (data?: any) => void):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            let fetchTask = fetch(`${API_BASE_URL}api/Billing/GetUsageLedger/?fromDate=${fromDate.toLocaleDateString()}&toDate=${toDate.toLocaleDateString()}&deliveryMode=${deliveryMode}`, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(response => response as Promise<ICompanyLedgerModel[]>)
                .then(data => {
                    if (callback && data) {
                        callback(data);
                    }

                    dispatch({
                        type: actionTypes.RECEIVE_COMPANY_LEDGER, ledgerData: data
                    });
                }).catch(function (error) {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: error.statusText,
                        statusType: StatusType.Error
                    });
                    logger.trackError(`requestUsageLedgerData failed with error ${error.message}`);
                });
            addTask(fetchTask);
        },

    getWalkMeScript: (callback: (script: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}/api/Common/GetWalkMeScriptAsync`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(json => json)
            .then(data => {
                callback(data);
            })
            .catch(error => {
            });
        addTask(fetchTask);
    },

    requestPendoData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${SUITE_API_BASE_URL}/api/company/pendo-metadata`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(json => json)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_PENDO_DATA, pendoData: data?.value });
            })
            .catch(error => {
                console.log(error);
            });
        addTask(fetchTask);
    }
};

const unloadedState: ICompanyData = {
    companySettings: initialCompanySettings,
    myDownloads: initalMyDownloadsList,
    companyLogoSetting: initialCompanyLogoSettings,
    admins: initialAdminModel,
    companyProfile: initialCompanyModel,
    signatureUploadLink: '',
    logoUploadLink: '',
    documentToExpireCount: 0,
    isDocumentStoreDirty: false,
    companyWhiteLogoSetting: initialCompanyWhiteLogoSettings,
    pendoData: { isTestCompany: false, subscription: "" }
} as ICompanyData;

export const arrayToHash = (array: any[], id: string = 'userId') =>
    array.reduce((obj, item) => (obj[item[id]] = item, obj), {})

export const reducer: Reducer<ICompanyData> = (state: ICompanyData = unloadedState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_COMPANY_SETTINGS:
            var received = { ...state };
            var unLoadedState = { ...unloadedState } as ICompanyData;
            received.taxingAuthorities = state.taxingAuthorities ? [...state.taxingAuthorities] : state.taxingAuthorities;
            received.authorityLookup = state.authorityLookup ? { ...state.authorityLookup } : state.authorityLookup,
                received.companySettings = state.companySettings ? { ...state.companySettings } : state.companySettings,
                received.isLoading = true;
            received.error = false;
            received.message = action.message;
            received.companyLogoSetting = state.companyLogoSetting;
            received.companyWhiteLogoSetting = unLoadedState.companyWhiteLogoSetting;
            return received;

        case actionTypes.RECEIVE_COMPANY_SETTINGS:
            var received = { ...state };
            received.companySettings = action.settings;
            received.companySettings.isDefault = false;
            received.isLoading = false;
            received.error = false;
            received.message = Date();
            return received;
        case actionTypes.ERROR_COMPANY_SETTINGS:
            return {
                companySettings: Object.assign({}, state.companySettings),//{...state.companySettings}
                taxingAuthorities: state.taxingAuthorities ? [...state.taxingAuthorities] : state.taxingAuthorities,
                companyLogoSetting: state.companyLogoSetting,
                admins: state.admins,
                companyProfile: state.companyProfile,
                signatureUploadLink: state.signatureUploadLink,
                logoUploadLink: state.logoUploadLink,
                authorityLookup: state.authorityLookup ? { ...state.authorityLookup } : state.authorityLookup,
                isLoading: false,
                error: true,
                message: action.reason,
                myDownloads: state.myDownloads,
                pendoData: state.pendoData
            } as ICompanyData;

        case actionTypes.REQUEST_TAX_AUTHORITIES:
            return {
                companySettings: Object.assign({}, state.companySettings),
                companyLogoSetting: state.companyLogoSetting,
                admins: state.admins,
                companyProfile: state.companyProfile,
                signatureUploadLink: state.signatureUploadLink,
                logoUploadLink: state.logoUploadLink,
                taxingAuthorities: [],
                authorityLookup: {} as IAuthorityDictionary,
                isLoading: true,
                error: false,
                message: Date(),
                definedPasswordPolicies: [],
                ledgerData: [],
                groupIDs: [],
                documentToExpireCount: 0,
                isAttested: false,
                isDocumentStoreDirty: false,
                companyWhiteLogoSetting: state.companyWhiteLogoSetting,
                whiteLogoUploadLink: state.whiteLogoUploadLink,
                myDownloads: state.myDownloads,
                pendoData: state.pendoData
            } as ICompanyData;

        case actionTypes.RECEIVE_TAX_AUTHORITIES:
            return {
                companySettings: Object.assign({}, state.companySettings),
                companyLogoSetting: state.companyLogoSetting,
                admins: state.admins,
                companyProfile: state.companyProfile,
                signatureUploadLink: state.signatureUploadLink,
                logoUploadLink: state.logoUploadLink,
                taxingAuthorities: action.taxingAuthorities,
                authorityLookup: buildAuthorityDictionary(action.taxingAuthorities),
                isLoading: false,
                error: false,
                message: Date(),
                myDownloads: state.myDownloads,
                pendoData: state.pendoData
            } as ICompanyData;

        case actionTypes.RECEIVE_COMPANY_LOGO:
            var received = { ...state };
            received.companyLogoSetting.logoPath = action.logoPath;
            received.companyLogoSetting.isDefaultLogo = action.isDefaultLogo;
            received.isLoading = false;
            received.error = false;
            received.message = Date();
            return received;

        case actionTypes.RECEIVE_COMPANY_PROFILE:
            var received = { ...state };
            received.companyProfile = action.companyProfile;
            received.admins.adminId = action.companyProfile.companyInfo.adminUser;
            received.isLoading = false;
            received.error = false;
            received.message = Date();
            return received;

        case actionTypes.RECEIVE_ADMIN_PROFILE:
            var received = { ...state };
            received.admins.adminUsers = action.admins.adminUsers;
            received.admins.adminUsers.map((value, index) => {
                if (value.userId == received.companyProfile.companyInfo.adminUser) {
                    received.admins.currentAdmin = value;
                }
            });
            received.isLoading = false;
            received.error = false;
            received.message = Date();
            return received;

        case actionTypes.UPDATE_COMPANY_SIGNATURE:
            var received = { ...state };
            received.signatureUploadLink = action.sasUrl;
            received.isLoading = false;
            received.error = false;
            received.message = Date();
            return received;

        case actionTypes.UPDATE_COMPANY_LOGO:
            var received = { ...state };
            received.logoUploadLink = action.sasUrl;
            received.isLoading = false;
            received.error = false;
            received.message = Date();
            return received;

        case actionTypes.RECEIVE_DEFINED_PASSWORD_POLICY:
            var received = { ...state };
            received.definedPasswordPolicies = action.definedPasswordPolicies;
            return received;

        case actionTypes.RECEIVE_COMPANY_LEDGER:
            var received = { ...state };
            received.ledgerData = action.ledgerData;
            return received;

        case actionTypes.VALIDATE_AD_AZURE_GROUP:
            var received = { ...state };
            received.groupIDs = action.groupIDs;
            return received;

        case actionTypes.REFRESH_TAX_DOCUMENT_STORE:
            let companyState: ICompanyData = { ...state };
            companyState.isDocumentStoreDirty = action.isDirty;
            return companyState;

        case actionTypes.ERROR_EXPIRE_TAX_DOCUMENT_COUNT:
            let expireErrorState: ICompanyData = { ...state };
            expireErrorState.documentToExpireCount = 0;
            expireErrorState.error = true;
            expireErrorState.message = action.message;
            return expireErrorState;

        case actionTypes.UPDATE_COMPANY_WHITE_LOGO:
            var received = { ...state };
            received.whiteLogoUploadLink = action.sasUrl;
            received.companyWhiteLogoSetting.isWhiteLogoDelted = false;
            received.isLoading = false;
            received.error = false;
            received.message = Date();
            return received;

        case actionTypes.RECEIVE_COMPANY_WHITE_LOGO:
            var received: ICompanyData = { ...state };
            received.companyWhiteLogoSetting.whiteLogoPath = action.whiteLogoPath;
            received.isLoading = false;
            received.error = false;
            received.message = Date();
            return received;

        case actionTypes.RECEIVE_MY_DOWNLOADS_LIST:
            var received = { ...state };
            received.myDownloads = action.myDownloadsList
            return received;

        case actionTypes.RECEIVE_PENDO_DATA:
            var received = { ...state };
            received.pendoData = action.pendoData;
            return received;
    }

    return state || unloadedState;
};


function buildAuthorityDictionary(authorities: ITaxingAuthority[]): IAuthorityDictionary {
    let ret: IAuthorityDictionary = {} as IAuthorityDictionary;
    authorities.map((authority, i) => {
        ret[authority.Id] = authority;
    });

    return ret;
}

function getDownloadSuccessDetails(data: any) {
    //this Dummy callback Signalr Scenario
}