import React from  'react';
import { Modal } from 'react-bootstrap';
import { TinyMCEComponent } from './TinyMCE';
import { Link } from 'react-router-dom';
import * as  Bootstrap from 'react-bootstrap';
import { MessageDetails, initialMessageDetails } from '../../../Core/ViewModels/CompanySettings/SavedMessageViewModel';
import * as Constants from '../../helper/Constants';
import * as  SavedMessageStoreState from '../../../store/settings/SavedMessageStore';
import { OverlayLoader } from '../../helper/OverlayLoader';
import { CheckBoxComponent } from '../../common/CheckBoxComponent';
import { ReplaceHelperVariableForEditor, validateName } from '../../helper/HelperFunctions';
import { VariableList } from '../ClientInstructions/VariableList';
var htmlencode = require('htmlencode');

interface IAddMessageProps {
    onShow: boolean;
    onHide: () => void;
    SubmitButtonClick: (messageDetail: MessageDetails, isSetDefault: boolean, isToggled: boolean) => void;
    buttonText: string;
    selectedMessageDetail: MessageDetails;
    title: string;
    defaultMessageId: number;
    isUpdate: boolean;
    messageList: SavedMessageStoreState.SavedMessageStoreState;
    showLoader: boolean;
    loadertext: string;
}

interface IAddMessageState {
    messageDetail: MessageDetails;
    alert: string;
    message: string;
    isSetDefault: boolean;
    isToggled: boolean;
    showAlert: boolean;
}

export class MessagePopUp extends React.Component<IAddMessageProps, IAddMessageState> {

    constructor(props:any) {
        super(props);
        this.state = {
            messageDetail: initialMessageDetails,
            alert: "warning",
            message: Constants.SavedMessageConstants.SavedMessageWarning,
            isSetDefault: false,
            isToggled: false,
            showAlert: true
        }
    }

    componentWillReceiveProps(props: IAddMessageProps) {
        this.setState({
            messageDetail: Object.assign({}, props.selectedMessageDetail)
        })
        this.setState({
            alert: "warning",
            message: Constants.SavedMessageConstants.SavedMessageWarning,
            isSetDefault: false,
            isToggled: false,
            showAlert:true
        })
    }

    private checkDuplicateExistance = (messageDetail: MessageDetails) => {
        let count: number = 0;
        Object.keys(this.props.messageList.data).map((key, index) => {
            if (this.props.messageList.data[parseInt(key)].messageDetails.name.trim().toLowerCase() == messageDetail.name.trim().toLowerCase() && this.props.messageList.data[parseInt(key)].messageDetails.id != messageDetail.id)
                count++;
        })
        return count;
    }

    private onCancelButtonClick = () => {
        this.props.onHide()
        this.setState({
            messageDetail: initialMessageDetails,
            alert: "warning",
            message: Constants.SavedMessageConstants.SavedMessageWarning,
            isSetDefault: false,
            isToggled: false,
            showAlert: true
        })
    }

    private onAddButtonClick = () => {
        if (validateName(this.state.messageDetail.name)) {
            this.setState({ alert: "danger", message: Constants.Common.InvalidCharacters, showAlert: false })
            return false;
        }
        if (this.state.messageDetail.name.trim() == "") {
            this.setState({ alert: "danger", message: Constants.SavedMessageConstants.ValidateName, showAlert: false })
            return false;
        }
        else if (this.state.messageDetail.body == "" || this.state.messageDetail.body.replace(/\&nbsp;/g, '').replace(/<\/?p[^>]*>/g, '').trim() == "") {
            this.setState({ alert: "danger", message: Constants.SavedMessageConstants.ValidateBody, showAlert: false })
            return false;
        }

        if (this.checkDuplicateExistance(this.state.messageDetail) > 0) {
            this.setState({ alert: "danger", message: Constants.SavedMessageConstants.SaveMessageNameError, showAlert: false })
            return false;
        }
        this.props.SubmitButtonClick(this.state.messageDetail, this.state.isSetDefault, this.state.isToggled)
        this.setState({ messageDetail: initialMessageDetails })
    }

    private onMessageNameChange = (event: any) => {
        let _messageDetails = this.state.messageDetail
        _messageDetails.name = event.target.value
        this.setState({ messageDetail: _messageDetails })
    }

    private onMessageBodyChange = (event: any) => {
        let _messageDetails = this.state.messageDetail
        _messageDetails.body = event.target.getContent()
    }

    private onCheckboxChange = (event: any) => {
        let _messageDetails = this.state.messageDetail
        _messageDetails.isAllowToEdit = event.target.checked
        this.setState({ messageDetail: _messageDetails })
    }

    private onSetDefaultClick = (isTrue: boolean) => {
        if (isTrue == true) {
            this.setState({ alert: "warning", message: Constants.SavedMessageConstants.SetDefaultMessageWarning, showAlert: false })
        }
        else {
            this.setState({ alert: "warning", message: Constants.SavedMessageConstants.RemoveDefaultMessageWarning, showAlert: false })
        }
        this.setState({
            isSetDefault: isTrue,
            isToggled: true,
        })
    }

    public render() {
        let toggleState: boolean = this.props.defaultMessageId == this.props.selectedMessageDetail.id && this.props.isUpdate == true && this.state.isToggled == false ?
            true : this.state.isSetDefault == true ? true : false
        return (
            <div style={{ position: 'relative' }}>
                <Modal show={this.props.onShow} onHide={this.onCancelButtonClick}>
                    <OverlayLoader show={this.props.showLoader} text={this.props.loadertext} />
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <span
                                className='fas fa-envelope'
                                style={{ color: 'grey', marginRight: '5px' }}>
                            </span>{this.props.title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Bootstrap.Alert variant={this.state.alert} hidden={this.state.showAlert} ><i className='fas fa-exclamation-triangle' style={{ marginRight: '5px' }}>
                        </i>{this.state.message}</Bootstrap.Alert>
                        <div className="form-group">
                            <label>Name</label>
                            <Link to={'#'} className="help-icon marL05" data-placement="right" data-toggle="tooltip" title="Message name under which this message will be saved">
                                <i className="fas fa-question-circle"></i>
                            </Link>
                            <div className="pull-right">
                                <label style={{ marginTop: 8, marginRight: 10 }}>Default</label>
                                <label className="switch">
                                    <input type="checkbox"
                                        checked={toggleState}
                                        onChange={(event) => this.onSetDefaultClick(event.target.checked)}
                                    ></input>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <input type="text"
                                data-test-auto="23566D10-E795-45EB-BBDD-73842676A88C"
                                className="form-control"
                                placeholder="Message Name"
                                value={this.state.messageDetail.name}
                                onChange={this.onMessageNameChange}
                                data-lpignore="true">
                            </input>
                        </div>

                        <div className="form-group">
                            <label>Body</label>
                            <Link to={'#'} className="help-icon marL05" data-placement="right" data-toggle="tooltip" title="Actual text of this message">
                                <i className="fas fa-question-circle"></i>
                            </Link>
                            <TinyMCEComponent
                                messageBody={ReplaceHelperVariableForEditor(htmlencode.htmlDecode(this.state.messageDetail.body))}
                                changeStateTinymceBody={this.onMessageBodyChange}
                                readOnly={0}
                            />
                        </div>
                        <div className="form-group">
                            <CheckBoxComponent
                                id={"allowUserToEdit"}
                                text={"Allow users to edit before sending"}
                                checked={this.state.messageDetail.isAllowToEdit}
                                onChange={this.onCheckboxChange}
                            />
                        </div>
                        <VariableList />
                    </Modal.Body>
                    <Modal.Footer>
                        <Bootstrap.Button
                            variant="default"
                            data-test-auto="80FA18FD-ED80-4D83-94CC-4FE05B57661B"
                            onClick={this.onCancelButtonClick}>
                            <i className='fas fa-times'></i>Cancel
                                </Bootstrap.Button>
                        <Bootstrap.Button
                            variant="info"
                            data-test-auto="2E2752F0-5331-4641-A105-DF12DDEBD4F9"
                            onClick={this.onAddButtonClick}>
                            <i className='fas fa-save'></i>{this.props.buttonText}
                        </Bootstrap.Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}