import React from  'react';
import { Overlay, Popover } from 'react-bootstrap';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { IWindowUtilities } from '../../../../Core/Utilities/WindowUtilities';
import { container } from '../../../../Startup/inversify.config';
import { TYPES } from '../../../../Startup/types';
import { CustomWindowConstants } from '../../../helper/Constants';
import { DnR } from './react-DnR/DnR';
import { Link } from 'react-router-dom';
import { ITaxReturn } from '../../../common/TaxReturn';
import { RBACKeys } from 'src/components/helper/RbacConstants';

const paneStyle = {
    width: '80%',
    height: '50%',
    top: '25%',
    left: '10%',
    backgroundColor: 'white',
    zIndex: 1000,
    boxShadow: "rgba(0, 0, 0, 0.75) 0px 7px 26px",
}

const contentStyle = {
    top: 50
}
const titleStyle = {
    height: 50
}

export enum WindowState {
    None = 0,
    Normal = 1,
    Fullscreen = 2,
}

export enum WindowSizeToggleState {
    Maximized = 0,
    Restored = 1,
}

interface CustomWindowProps {
    order: number;
    title: string;
    titleIcon?: any;
    initialWidth?: number;
    initialHeight?: number;
    initialTop?: number;
    initialLeft?: number;
    disableMaximize?: boolean;
    disableMinimize?: boolean;
    disableFullscreen?: boolean;
    onClose?: any;
    onResize: () => void;
    alwaysFitToParent?: boolean;
    taxReturn: ITaxReturn;
    onReportProblemOpen: (id: number) => void;
}



interface CustomWindowState {
    windowState: WindowState;
    windowSizeToggleState: WindowSizeToggleState;
    showClosePopover: boolean;
}

const windowUtilities = container.get<IWindowUtilities>(TYPES.IWindowUtilities);

export class CustomWindow extends React.Component<CustomWindowProps, CustomWindowState> {
    private windowRef: HTMLInputElement;
    private closePopoverRef: any;

    constructor(props: CustomWindowProps) {
        super(props);
        this.state = {
            windowState: WindowState.Normal,
            windowSizeToggleState: WindowSizeToggleState.Restored,
            showClosePopover: false,
        }
        this.setWindowRef = this.setWindowRef.bind(this);
        this.handleClickOnWindow = this.handleClickOnWindow.bind(this);
    }

    componentDidMount() {
        //this.arrangeMinimizeBar(); // TODO will remove after sometime
        this.toggleOverlay();
        document.addEventListener('mousedown', this.handleClickOnWindow);
        window.addEventListener("resize", this.windowResized);
    }

    componentDidUpdate() {
        //this.arrangeMinimizeBar(); // TODO will remove after sometime
        this.toggleOverlay();
    }

    componentWillReceiveProps() {
        this.state.windowSizeToggleState != WindowSizeToggleState.Maximized && this.onMaximize();
    }

    private windowResized = () => {
        this.props.alwaysFitToParent && this.state.windowState != WindowState.Fullscreen && this.onMaximize();
    }

    private toggleOverlay() {

        if (document.querySelectorAll('[data-window-state="' + WindowState.Normal.toString() + '"]').length > 0) {
            document.getElementsByClassName("custom-modal-overlay")[0].removeAttribute("hidden");
        } else {
            let overlays: HTMLCollectionOf<Element> = document.getElementsByClassName("custom-modal-overlay");
            for (var i = 0; i < overlays.length; i++) {
                overlays[i].setAttribute("hidden", "true");
            }
        }
    }

    private arrangeMinimizeBar() {

        let minimizeBar: any = document.getElementsByClassName("bottom-bar");
        let width: number = minimizeBar[0].clientWidth;

        for (var i = 0, len = minimizeBar.length; i < len; i++) {
            minimizeBar[i].style["margin-left"] = i * width + "px";
        }
    }

    private onMaximize = () => {
        this.setState({ windowSizeToggleState: WindowSizeToggleState.Maximized, windowState: WindowState.Normal });
        (this.refs.dnr as any).maximize();
    }

    private onMinimize = () => {
        this.setState({ windowState: WindowState.None });
        (this.refs.dnr as any).minimize();
    }

    private onClose = () => {
        this.state.windowState == WindowState.Fullscreen && this.onExitFullscreen();
        this.props.onClose();
        this.setState({ windowState: WindowState.None, showClosePopover: false });
    }

    private onFullscreen = () => {
        windowUtilities.goFullScreen((this.refs.dnr as any).refs.frame);
        this.setState({ windowState: WindowState.Fullscreen });
    }

    private onExitFullscreen = () => {
        windowUtilities.exitFullScreen();
        this.setState({ windowState: WindowState.Normal });
    }

    private onRestore = () => {
        (this.refs.dnr as any).restore();
    }

    private toggleWindowSize = () => {

        if (this.state.windowState == WindowState.None) {
            this.setState({ windowState: WindowState.Normal });
            this.onRestore();
        }
        else {
            this.onMinimize();
        }
    }

    private createTitleBar = () => {

        const popover = (
            <Popover
                id="popover-basic"
                title="Confirm Close"
                style={{ fontSize: '14px' }}>
                <Popover.Title as="h3" style={{ fontSize: '14px' }}>{`Confirm Close`}</Popover.Title>
                <Popover.Content>
                    {CustomWindowConstants.closePopoverMessage}
                    <div className="popover-footer">
                        <button
                            className="btn btn-only btn-success"
                            onClick={this.onClose}
                            title="Yes"
                            data-test-auto="BCDBF6AB-57E0-4AB7-BAB2-580203E088C8"
                            style={{ marginLeft: "5px" }}>
                            Yes
                    </button>
                        <button
                            onClick={() => { this.setState({ showClosePopover: false }); }}
                            title="No"
                            data-test-auto="8AAB3A64-BD99-411A-9F63-E627D51F1F59"
                            className="btn btn-only btn-danger"
                            style={{ marginLeft: "5px" }}>
                            No
                    </button>
                        </div>
                </Popover.Content>
            </Popover>
        );

        return (<div className="custom-window-header">
            <div className="header-inner">
                <div><span className="fa fa-rocket"></span> <span className="title">
                    {this.props.title}
                </span></div>
                <div className="header-btn">
                    <button key="ReportProblem" title="Report a Problem" data-resource-id={RBACKeys.extensionInProgress.reportAProblem}>
                        <Link to={"#"} onClick={() => this.props.onReportProblemOpen(this.props.taxReturn.id)}
                            data-test-auto="29E669D6-2F74-4C0E-8119-B66694DA305F">
                            <i className='fas fa-bullhorn ddl-icon' style={{ margin: 'auto', color: '#167DAD' }}></i></Link>
                    </button>
                    {
                       (this.state.windowState == WindowState.Fullscreen) ?
                            (<button className="modal-header-button" title="Exit Full Screen" onClick={this.onExitFullscreen}>
                                <i className="fas fa-compress"></i>
                            </button>) :
                            (<button  className="modal-header-button"
                            title="Full Screen"
                                onClick={this.onFullscreen}>
                                <i className="fas fa-expand"></i>
                            </button>)
                    }
                    <button hidden={this.props.disableMinimize} disabled={this.state.windowState == WindowState.Fullscreen || this.state.windowSizeToggleState == WindowSizeToggleState.Maximized} type="button"
                        className="modal-header-button" onClick={this.onMinimize}>
                        <i className="fas fa-window-minimize"></i>
                    </button>
                    {
                        (this.state.windowSizeToggleState == WindowSizeToggleState.Maximized) ?
                            (<button hidden={this.props.disableMaximize} className="modal-header-button"
                                onClick={() => { this.setState({ windowSizeToggleState: WindowSizeToggleState.Restored }, () => { this.onRestore() }) }}>
                                <i className="fas fa-window-restore"></i>
                            </button>) :
                            (<button hidden={this.props.disableMaximize} disabled={this.state.windowState == WindowState.Fullscreen} className="modal-header-button" onClick={this.onMaximize}>
                                <i className="fas fa-window-maximize"></i>
                            </button>)
                    }  
                    <button className="modal-header-button"
                        onClick={() => {
                            this.setState((prevState) => { return { showClosePopover: !prevState.showClosePopover }; });
                        }}
                        ref={(reference) => { this.closePopoverRef = reference }}>
                        <i className="fas fa-times"></i>
                    </button>
                    <Overlay rootClose={true} placement="left"
                        onHide={() => { this.setState({ showClosePopover: false }) }}
                        target={this.closePopoverRef} show={this.state.showClosePopover}>
                        {popover}
                    </Overlay>
                </div>
            </div>
        </div>);

    }


    private createBottomBar = () => {
        return (<div id="bottombar" className="bottom-bar">
            <div onClick={this.toggleWindowSize} className="bottom-bar-item">
                <i hidden={this.state.windowState == WindowState.None} className="bottom-bar-item-title-icon far fa-dot-circle"></i>
                <div className="bottom-bar-item-title">
                    <span>{this.props.title}</span>
                </div>
            </div>
        </div>);
    }

    private createOverlay() {
        return (<div className="custom-modal-overlay" hidden={true}></div>);
    }

    private onResize = (event: any) => {
        const titleHeight: number = (this.refs as any).title ? (this.refs as any).title.offsetHeight : 0;
        const footerHeight: number = (this.refs.content as any) ? (this.refs.content as any).getElementsByClassName("modal-footer")[0].offsetHeight : 0;
        const bodyHeight: number = (this.refs.frame as any) ? (this.refs.frame as any).offsetHeight - (titleHeight + footerHeight) : 0;

        if ((this.refs.content as any)) {
            (this.refs.content as any).getElementsByClassName("modal-body")[0].style.height = bodyHeight + "px";
        }

        this.props.onResize();
    }

    private onTransform = (event: any) => {

        const titleHeight: number = (this.refs.title as any) && (this.refs.title as any).offsetHeight;
        const footerHeight: number = (this.refs.content as any) && (this.refs.content as any).getElementsByClassName("modal-footer")[0].offsetHeight;
        const bodyHeight: number = event.height - (titleHeight + footerHeight);
        (this.refs.content as any) && ((this.refs.content as any).getElementsByClassName("modal-body")[0].style.height = bodyHeight + "px");
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOnWindow);
        window.removeEventListener("resize", this.windowResized);
    }

    setWindowRef(node: any) {
        this.windowRef = node;
    }

    handleClickOnWindow(event: any) {
        if (event.target.hasAttribute("class") && event.target.getAttribute("class") === "custom-modal-overlay")
            return;
        if (this.windowRef && !this.windowRef.contains(event.target)) {
            (this.refs.dnr as any).refs.frame.style["z-index"] = 1000;
        } else {
            (this.refs.dnr as any).refs.frame.style["z-index"] = 1001;
        }
    }

    public render() {
        const { initialWidth, initialHeight, initialTop, initialLeft, order } = this.props;

        return (<div className="custom-modal" ref={this.setWindowRef} data-window-state={this.state.windowState}>

            <DnR ref='dnr' cursorRemap={(c: any) => c === 'move' ? 'default' : null}
                style={paneStyle} contentStyle={contentStyle}
                titleStyle={titleStyle} animate={false}
                initialWidth={initialWidth || (window.innerWidth * 0.95)}
                initialHeight={initialHeight || (window.innerHeight * 0.95)}
                initialTop={initialTop || (window.innerHeight * 0.01 * order)}
                initialLeft={initialLeft || (window.innerWidth * 0.015)}
                onResize={this.onResize} onTransform={this.onTransform} titleBar={""}>
                <div className="custom-window">
                    {this.createTitleBar()}
                    {this.props.children}
                </div>
            </DnR>

            {this.createOverlay()}
        </div>);
    }
}

export class CustomWindowFooter extends React.Component<{}, {}> {

    constructor(props:any) {
        super(props);
    }

    public render() {

        return (<div className="custom-window-footer">
            {this.props.children}
        </div>);
    }
}


interface CustomWindowBodyProps {
    isBusy: boolean; 
}

export class CustomWindowBody extends React.Component<CustomWindowBodyProps, {}> {

    constructor(props: CustomWindowBodyProps) {
        super(props);
    }

    public render() {

        return (<div className="custom-window-content">
            {/* <Scrollbars autoHide>*/}
                <LoadingOverlay style={{ height: '100%' }}>
                    {this.props.children}  
                    <Loader loading={this.props.isBusy} />
                </LoadingOverlay>              
            {/*</Scrollbars>*/}
        </div>);
    }
}
