import React from  'react';
import { Button, Modal, Alert } from 'react-bootstrap';
import { VenusNotifier } from './../../helper/VenusNotifier';
import { ITaxReturn } from './../../common/TaxReturn';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { GroupConfigurationBody } from './GroupConfigurationBody';
import * as Constants from './../../helper/Constants';
import { Guid } from './../../../Core/Utilities/Guid';
import { GroupInfo, GroupOptions, initialGroupInfo } from '../../../Core/ViewModels/GroupExtensions/ComponentModels';

interface IGroupConfigurationModalProps {
    showGroupConfiguration: boolean;
    selectedAssignments: ITaxReturn[];
    count: number;
    groupInfo: GroupInfo[];
    onCancel(event: any): void;
    onCreateNew: (groupInfo: GroupInfo) => void;
    onAddtoExisting: (groupId: number) => void;
    hideUnGroupReturn: boolean;
    UnMapGroupedReturns?: () => void;
}

interface IGroupConfigurationModalStates {
    saving: boolean;
    message: string;
    alertMsg: string;
    alertIcon: string;
    selectedValue: number;
    newGroupInfo: GroupInfo;
    options: GroupOptions;
}

const msg = {
    loading: 'Loading,Please Wait...',
    saving: 'Submitting the changes...',

}

export class GroupConfigurationModal extends React.Component<IGroupConfigurationModalProps, IGroupConfigurationModalStates> {

    constructor(props: IGroupConfigurationModalProps) {
        super(props);

        this.state = {
            saving: false,
            message: '',
            alertMsg: '',
            alertIcon: 'fa-info-circle',
            selectedValue: 0,
            newGroupInfo: Object.assign({}, initialGroupInfo),
            options: GroupOptions.AddNew
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.onAddGroupInfo = this.onAddGroupInfo.bind(this);
        this.onDropDownChange = this.onDropDownChange.bind(this);
        this.onGroupNameChange = this.onGroupNameChange.bind(this);
        this.prepareGroup = this.prepareGroup.bind(this);
        this.handleCreateNewGroup = this.handleCreateNewGroup.bind(this);
    }

    componentWillReceiveProps(nextProps: IGroupConfigurationModalProps) {
        const saving = !nextProps.showGroupConfiguration ? false : this.state.saving;
        this.setState({
            newGroupInfo: Object.assign({}, initialGroupInfo),
            saving: saving,
            options: GroupOptions.AddNew,
            selectedValue: 0,
        });
    }

    public render() {
        return <Modal
            className="group-configuration-modal"
            show={this.props.showGroupConfiguration}
            onHide={this.props.onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <span className='modalIcon text-secondary fa fa-user-check'>
                    </span>Group Configuration
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoadingOverlay style={{ height: '100%' }}>
                    <Alert variant="info" >
                        <div style={{ paddingBottom: 5 }}><i className="fas fa-info-circle"></i><span style={{ marginLeft: 5 }}>
                            {Constants.UploadtaxReturnConstants.GroupedReturnNote}
                        </span></div>
                        <i className='fas fa-info-circle'>
                        </i>{this.props.count > 1 ? " " + this.props.count + " Extensions selected." : " 1 Extension selected."}
                    </Alert>
                    {this.state.saving ?
                        <Alert
                            closeLabel={'remove'}
                            variant="warning"
                            dismissible
                            onClose={() => { this.setState({ alertMsg: '', saving: false }) }}>
                            <span
                                className={'fa ' + this.state.alertIcon}
                                style={{ marginRight: '5px' }}>
                            </span>{this.state.alertMsg}
                        </Alert>
                        : false}

                    <Loader loading={this.state.saving} text={this.state.message} />
                </LoadingOverlay>
                <GroupConfigurationBody
                    options={this.state.options}
                    groupInfo={this.props.groupInfo}
                    onDropdownChange={this.onDropDownChange}
                    groupName={this.state.newGroupInfo.name}
                    selectedValue={this.state.selectedValue}
                    onGroupNameChange={this.onGroupNameChange}
                    loadGroup={this.prepareGroup(this.props.groupInfo)}
                    onOptionsClick={this.handleCreateNewGroup}
                    hideUnGroupReturn={this.props.hideUnGroupReturn}
                />
            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant="default"
                    className="btn-white"
                    disabled={this.state.saving}
                    onClick={this.props.onCancel}
                ><i className='fas fa-times'></i>Cancel</Button>
                <Button
                    variant="info"
                    disabled={this.state.saving}
                    onClick={this.onSubmit}
                ><i className='fas fa-save'></i>Save</Button>
            </Modal.Footer>
        </Modal>
    }

    private onSubmit = (e: any) => {
        e.preventDefault();
        if (!this.state.saving) {
            this.setState({ saving: true, message: msg.saving }, () => {
                if (this.state.options == GroupOptions.AddNew) {
                    this.onAddGroupInfo();
                }
                else if (this.state.options == GroupOptions.AddtoExisting) {
                    this.onAddtoExisting();
                }
                else if (this.state.options == GroupOptions.UnGroup) {
                    this.props.UnMapGroupedReturns && this.props.UnMapGroupedReturns();
                }
            });
        }
    }

    private onDropDownChange = (value: number) => {
        this.setState({ selectedValue: value });
    }

    private onGroupNameChange = (event: any) => {
        let temp = this.state.newGroupInfo;
        temp.name = event.target.value;
        this.setState({
            newGroupInfo: temp,
        })
    }
    private onAddtoExisting = () => {
        if (this.state.selectedValue > 0) {
            this.props.onAddtoExisting(this.state.selectedValue);
        }
        else {
            this.setState({ saving: false, message: "" });
            VenusNotifier.Warning(Constants.GroupExtensionConstants.ValidationMessage.GroupConfigDropDownWarning, null);
        }
    }
    private onAddGroupInfo = () => {
        if (this.state.newGroupInfo.name.trim() != "") {
            if (this.isGroupExists(this.state.newGroupInfo.name)) {
                this.state.newGroupInfo.controllerInfo.clientGuid = Guid.newGuid();
                this.props.onCreateNew(this.state.newGroupInfo);
                initialGroupInfo.controllerInfo.clientGuid = Guid.newGuid();
                this.setState({
                    newGroupInfo: Object.assign({}, initialGroupInfo)
                });
            }
        }
        else {
            this.setState({ saving: false, message: "" });
            VenusNotifier.Warning(Constants.GroupExtensionConstants.ValidationMessage.GroupConfigurationWarning, null);
        }
    }



    private isGroupExists = (groupName: string) => {
        let isValid: boolean = true;
        const groupInfo = this.props.groupInfo.find(i => i.name.toLowerCase() == groupName.trim().toLowerCase());
        if (groupInfo) {
            this.setState({ saving: false, message: "" });
            VenusNotifier.Warning(Constants.GroupExtensionConstants.ValidationMessage.GroupConfigurationAlreadyExist, null);
            isValid = false;
        }
        return isValid;
    }

    private prepareGroup = (groupInfo: GroupInfo[]) => {
        var group: any = [];
        group.push({
            value: 0,
            label: "Select"
        })

        groupInfo.map((column: any) =>
            group.push({
                value: column.id,
                label: column.name
            }));

        return group;
    }

    private handleCreateNewGroup = (mode: GroupOptions) => {
        this.setState({
            options: mode
        });
    }
}
