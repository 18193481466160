import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../ActionTypes';
import { handleResponse } from '../Library';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { API_BASE_URL } from 'src/utils/contants';
import { logger } from 'src/components/helper/LoggerHelper';
import { getProcessResourceId } from 'src/components/helper/RbacConstants';

export interface IUserSignatures {
	[index: number]: {
		signatureUploadPath: string,
		signatureDownloadPath: string,
	}
}

interface ReceiveSignatureDownloadPath {
	type: actionTypes.RECEIVE_USER_DOWNLOAD_SIGNATURE;
	userId: number,
	path: string,
}

type KnownAction =
	DispatchAction |
	NotificationAction;

type DispatchAction = ReceiveSignatureDownloadPath;

export const actionCreators = {
	requestDownloadPath: (userId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
		let state = getState();
		if (!state.userSignatures[userId] || (state.userSignatures[userId] && state.userSignatures[userId].signatureDownloadPath == "")) {
			const fetchTask = fetch(`${API_BASE_URL}api/UserSettings/GetUserSignLinkAsync/` + userId, {
                method: 'GET',
                credentials: 'include',
				headers: {
					'Accept': 'application/json, text/plain, */*',
					'Content-Type': 'application/json; charset=utf-8',
					'X-Resource-Id': getProcessResourceId()
				}
			})
				.then(handleResponse)
				.then((data) => {
					dispatch({ type: actionTypes.RECEIVE_USER_DOWNLOAD_SIGNATURE, path: data.sas, userId: userId });
				}).catch(error => {
					dispatch({
						type: actionTypes.NOTIFICATION, statusMessage: error.statusText,
						statusType: StatusType.Error
					});
					logger.trackError(`processTaxReturn failed for userId: ${userId}, with error ${error.message}`);
				});
			addTask(fetchTask);
		}
	}
}

const unloadedState: IUserSignatures = {
	0: {
		signatureDownloadPath: "",
		signatureUploadPath: ""
	}
};

export const reducer: Reducer<IUserSignatures> = (state: IUserSignatures = unloadedState, incomingAction: Action) => {
	const action = incomingAction as DispatchAction;
	switch (action.type) {
		case actionTypes.RECEIVE_USER_DOWNLOAD_SIGNATURE:
			let newState: IUserSignatures = { ...state };
			if (newState[action.userId]) {
				newState[action.userId].signatureDownloadPath = action.path;
			}
			else {
				newState[action.userId] = {
					signatureDownloadPath: action.path,
					signatureUploadPath: "",
				};
			}
			return newState;
		default:
			//const exhaustiveCheck: never = action;
	}
	return state || unloadedState;
}